import { defineMessages } from 'react-intl'

export const LoadPaymentPageErrorContentMessages = defineMessages({
  body: {
    defaultMessage:
      'We were not able to load payment pages. Please refresh the page and if this issue persists please {contact_support}.',
    id: 'mVkK+vnE',
    description:
      'The description shown in a toast notification message shown when we encounter an error loading the payment page.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'f1qY+sxt',
    description: 'The text shown for the contact support link in the load payment page error toast notification.',
  },
  title: {
    defaultMessage: 'Unable to load payment method',
    id: 'm5Mzhbj9',
    description: 'The toast notification title shown when we encounter an error loading the payment page.',
  },
})
