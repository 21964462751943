import type { FunctionComponent } from 'react'
import { memo } from 'react'
import { useIntl } from 'react-intl'
import { Button } from '../../../../Button'
import { Modal } from '../../../../block/Modal'
import { ReallocateConfirmationModalMessages } from './ReallocateConfirmationModalMessages'

export interface ReallocateConfirmationModalProps {
  /** The value indicates whether reallocate request is in progress (waiting for response) */
  isReallocateInstancePending: boolean
  /** The callback executed when the user clicks the `Cancel` button. */
  onCancel: () => void
  /** The callback executed when the user clicks the `Reallocate` button. */
  onReallocateInstance: () => void
}

export const ReallocateConfirmationModal: FunctionComponent<ReallocateConfirmationModalProps> = memo(
  ({ isReallocateInstancePending, onCancel, onReallocateInstance }) => {
    const intl = useIntl()

    return (
      <Modal onClose={onCancel} title={intl.formatMessage(ReallocateConfirmationModalMessages.title)}>
        <h1 className="mb-8 text-3xl font-bold">{intl.formatMessage(ReallocateConfirmationModalMessages.title)}</h1>
        <p className="mb-8 font-normal">{intl.formatMessage(ReallocateConfirmationModalMessages.body)}</p>
        <div className="flex max-w-md gap-6">
          <Button
            type="button"
            variant="blue-outlined"
            onClick={onCancel}
            isFullWidth
            isDisabled={isReallocateInstancePending}
          >
            {intl.formatMessage(ReallocateConfirmationModalMessages.cancel)}
          </Button>
          <Button
            variant="green-filled-light"
            onClick={onReallocateInstance}
            isFullWidth
            isLoading={isReallocateInstancePending}
          >
            {intl.formatMessage(ReallocateConfirmationModalMessages.delete)}
          </Button>
        </div>
      </Modal>
    )
  },
)
