import { defineMessages } from 'react-intl'

export const AxiomInputFieldsMessages = defineMessages({
  apiKeyLabel: {
    defaultMessage: 'API Key',
    id: '5Kb8pKkl',
    description: 'The label for the Axiom API key input field.',
  },
  apiKeyMaxLengthText: {
    defaultMessage: 'API Key can be no longer than 1000 characters',
    id: 'VCGo8RoG',
    description: 'The max length text for the Axiom API key input field.',
  },
  apiKeyRequiredText: {
    defaultMessage: 'API Key is required',
    id: 'bhOmmAKu',
    description: 'The required text for the Axiom API key input field.',
  },
  datasetNameLabel: {
    defaultMessage: 'Dataset Name',
    id: 'EPU7SVda',
    description: 'The label for the Axiom dataset name input field.',
  },
  datasetNameMaxLengthText: {
    defaultMessage: 'Dataset Name can be no longer than 1000 characters',
    id: '3Nv4OVPz',
    description: 'The max length text for the Axiom Dataset Name input field.',
  },
  datasetNameRequiredText: {
    defaultMessage: 'Dataset Name is required',
    id: 'CNlAPiDo',
    description: 'The required text for the Axiom dataset name input field.',
  },
  hostLabel: {
    defaultMessage: 'Host',
    id: 'jaiUX/nn',
    description: 'The label for the Axiom host input field.',
  },
  hostMaxLengthText: {
    defaultMessage: 'Host can be no longer than 1000 characters',
    id: 'aWOskUEU',
    description: 'The max length text for the Axiom Host input field.',
  },
  hostRequiredText: {
    defaultMessage: 'Host is required',
    id: '/Lptd0oU',
    description: 'The required text for the Axiom host input field.',
  },
  hostValidationText: {
    defaultMessage: 'Cannot begin with http:// or https://',
    id: 'MPCz/Ifi',
    description: 'The validation text for the Axiom host input field.',
  },
})
