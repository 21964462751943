import { defineMessages } from 'react-intl'

export const UnableToAccessLogsErrorContentMessages = defineMessages({
  body: {
    defaultMessage:
      'We were unable to access your container logs at this time. If this issue persists please {contact_support}.',
    id: 'e9B1rQk6',
    description:
      'The description shown in a toast notification message shown when we are not able to access the streaming logs.',
  },
  title: {
    defaultMessage: 'Unable to Access Logs',
    id: 'Du8HUq5H',
    description: 'The toast notification title shown when we are not able to access the streaming logs.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'iAhs9Ywk',
    description: 'The text shown for the contact support link in the unable to access logs error toast notification.',
  },
})
