import { defineMessages } from 'react-intl'

export const LoadCreditDashboardNotInitializedErrorMessages = defineMessages({
  body: {
    defaultMessage:
      'We’re still setting up your organization. This should take less than a minute. If this issue persists {contact_support}.',
    id: 'a/Ip9rAt',
    description:
      'The description shown in a toast notification message shown when we encounter a not initialized error loading the billing credits dashboard.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'sLlWLCpB',
    description: 'The text shown for the contact support link in the load credits dashboard error toast notification.',
  },
  title: {
    defaultMessage: 'Error loading credits dashboard',
    id: 'lFcubX5t',
    description:
      'The toast notification title shown when we encounter a not initialized error loading the billing credits dashboard.',
  },
})
