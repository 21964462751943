import type { ResponseError } from '@saladtechnologies/openapi-cloud-portal-browser'
import { catchError, concat, delay, filter, from, mergeMap, of, switchMap } from 'rxjs'
import { UserAccountsAPI } from '../apiMethods'
import { changePassword, setChangePasswordRequestStatus } from '../features/changePassword/changePasswordSlice'
import { showToastNotification } from '../features/notifications/notificationsSlice'
import { RequestStatus } from '../models'
import {
  getChangePasswordGeneralErrorContent,
  getChangePasswordSucceededContent,
} from '../notifications/clientToastNotificationContent/account'
import { getChangePasswordInvalidCurrentPasswordErrorContent } from '../notifications/clientToastNotificationContent/account/getChangePasswordInvalidCurrentPasswordErrorContent'
import type { AppEpic } from '../store'

export const onChangePassword: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(changePassword.match),
    mergeMap((action) => {
      return concat(
        of(setChangePasswordRequestStatus({ requestStatus: RequestStatus.Loading })),
        from(
          UserAccountsAPI.changePassword({
            changePassword: {
              password: action.payload.currentPassword,
              newPassword: action.payload.newPassword,
            },
          }),
        ).pipe(
          mergeMap(() => {
            return concat(
              of(
                showToastNotification(getChangePasswordSucceededContent(intl)),
                setChangePasswordRequestStatus({ requestStatus: RequestStatus.Succeeded }),
              ),
              of(setChangePasswordRequestStatus({ requestStatus: RequestStatus.Idle })).pipe(delay(1)),
            )
          }),
          catchError((error: ResponseError) => {
            const requestStatusHandling$ = concat(
              of(setChangePasswordRequestStatus({ requestStatus: RequestStatus.Failed })),
              of(setChangePasswordRequestStatus({ requestStatus: RequestStatus.Idle })).pipe(delay(1)),
            )

            return of(error).pipe(
              switchMap(() =>
                from(error.response.json()).pipe(
                  switchMap((responseBody) => {
                    const isCurrentPasswordTypedIncorrect = JSON.stringify(responseBody).includes('Incorrect password')

                    return concat(
                      requestStatusHandling$,
                      isCurrentPasswordTypedIncorrect
                        ? of(showToastNotification(getChangePasswordInvalidCurrentPasswordErrorContent(intl)))
                        : of(showToastNotification(getChangePasswordGeneralErrorContent(intl))),
                    )
                  }),
                ),
              ),
              catchError(() => {
                return concat(
                  requestStatusHandling$,
                  of(showToastNotification(getChangePasswordGeneralErrorContent(intl))),
                )
              }),
            )
          }),
        ),
      )
    }),
  )
