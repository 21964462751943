import { defineMessages } from 'react-intl'

export const FailedToLoadRecipeMarketplaceMessages = defineMessages({
  message: {
    defaultMessage: 'Seems like we had a problem loading the recipe marketplace',
    id: 'bltqsP8E',
    description: 'The message shown to the user when we were unable to load the recipe marketplace.',
  },
  tryAgainButtonLabel: {
    defaultMessage: 'Try Again',
    id: 'lVdmCs1p',
    description: 'The label for the try again button.',
  },
})
