import { defineMessages } from 'react-intl'

export const TriangleBackgroundMessages = defineMessages({
  leftImageAltText: {
    defaultMessage: 'A triangle made of smaller triangles located in the top left of the page.',
    id: 'VgT6E+gN',
    description: 'The alt description for the Salad triangle image used in the background for the authentication page.',
  },
  rightImageAltText: {
    defaultMessage: 'A triangle made of smaller triangles located in the bottom right of the page.',
    id: 'OSpx3+yl',
    description: 'The alt description for the Salad triangle image used in the background for the authentication page.',
  },
})
