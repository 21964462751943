import type { FunctionComponent, ReactNode } from 'react'
import { Drawer } from './components/Drawer'

export interface ThreeColumnLayoutProps {
  /** The flag indicating if the left column is showing */
  isLeftColumnOpen: boolean
  /** The flag indicating if the main colum has layout styles removed */
  isMainColumnWithoutLayoutStyles?: boolean
  /** The flag indicating if the main colum has scroll styles removed */
  isMainColumnWithoutScrollStyles?: boolean
  /**
   * The flag indicating if the right column is a showcase sidebar. This will make the sidebar take up more width and
   * have a grey background.
   */
  isRightColumnShowcase?: boolean
  /** The left section of the page that can be toggled open/closed if the screen is less than 1024px. */
  LeftColumn: ReactNode
  /** The center section of the page that will always be displayed. */
  MainColumn: ReactNode
  /** The callback executed when the user closes the left drawer */
  onCloseLeftDrawer?: () => void
  /** The right section of the page that can be toggled open/closed if the screen is less than 1024px. */
  RightColumn?: ReactNode
}

export const ThreeColumnLayout: FunctionComponent<ThreeColumnLayoutProps> = ({
  isLeftColumnOpen,
  isMainColumnWithoutLayoutStyles,
  isMainColumnWithoutScrollStyles,
  isRightColumnShowcase,
  LeftColumn,
  MainColumn,
  onCloseLeftDrawer,
  RightColumn,
}) => {
  return (
    <main className="fixed flex w-full">
      <Drawer isOpen={isLeftColumnOpen} anchor="left" onCloseLeftDrawer={onCloseLeftDrawer}>
        {LeftColumn}
      </Drawer>

      {/* The tab index set here allows for the overflow content to be scrollable via the keyboard */}
      <article className={`h-screen ${isMainColumnWithoutScrollStyles ? '' : 'overflow-auto'} grow`} tabIndex={0}>
        <div className={`${isMainColumnWithoutLayoutStyles ? '' : 'mb-16 px-6 py-8'}`}>{MainColumn}</div>
      </article>

      {RightColumn && (
        <Drawer anchor="right" isShowcase={isRightColumnShowcase}>
          {RightColumn}
        </Drawer>
      )}
    </main>
  )
}
