import { defineMessages } from 'react-intl'

export const JobQueuesMainContentMessages = defineMessages({
  connectedContainerGroups: {
    defaultMessage:
      'Connected to {container_group_count, plural, =1 {1 Container Group} other {{container_group_count} Container Groups}}',
    id: 'JoRNoIX4',
    description: 'The details about how many container groups are connected to the job queue.',
  },
  createdDateAndTime: {
    defaultMessage: 'Created on {date} at {time}',
    id: '2DGNLou+',
    description: 'The date the job queue was created on.',
  },
  createJobQueueButtonLabel: {
    defaultMessage: 'Create New Job Queue',
    id: 'OgOL8rui',
    description: 'The label for the create new job queue button on the Job Queues page.',
  },
  learnMoreButtonLabel: {
    defaultMessage: 'Learn More',
    id: 'iGqfYlG+',
    description: 'The label for the learn more button on the Job Queues page.',
  },
  subtitle: {
    defaultMessage:
      'Allows you to send requests (jobs) to a static endpoint. Jobs are queued and distributed on a FIFO basis to healthy nodes that are ready to accept them. Jobs are automatically retried and results are returned.',
    id: 'n89xaxy4',
    description: 'The subtitle for the Job Queues page.',
  },
  title: {
    defaultMessage: 'Job Queues',
    id: '0PmUy0fi',
    description: 'The title for the Job Queues page.',
  },
})
