import type { FunctionComponent } from 'react'
import { useCallback, useEffect } from 'react'
import { useParams } from 'react-router'
import { LoadingPage } from '../../components/page/LoadingPage'
import { trackMixpanelPageViewedEvent } from '../../features/analytics/analyticsSlice'
import { editOrganizationDisplayName } from '../../features/editOrganization/editOrganizationSlice'
import { selectIsLeftColumnShowing } from '../../features/navigationBar/navigationBarSelectors'
import { setLeftColumnShowing } from '../../features/navigationBar/navigationBarSlice'
import { selectOrganizationDisplayName } from '../../features/organizations/organizationsSelectors'
import { getOrganization } from '../../features/organizations/organizationsSlice'
import { selectRequestStatus } from '../../features/requestStatus/requestStatusSelectors'
import { editOrganizationPageRoutePath } from '../../routes/routePaths'
import { useAppDispatch, useAppSelector } from '../../store'
import { EditOrganizationPage } from './EditOrganizationPage'

export const ConnectedEditOrganizationPage: FunctionComponent = () => {
  const { organizationName = '' } = useParams()
  const dispatch = useAppDispatch()
  const isEditOrganizationPending = useAppSelector(
    (state) => selectRequestStatus(state, 'editOrganization') === 'pending',
  )
  const isGetOrganizationInfoPending = useAppSelector(
    (state) => selectRequestStatus(state, 'getOrganization') === 'pending',
  )
  const isLeftColumnOpen = useAppSelector(selectIsLeftColumnShowing)
  const organizationDisplayName = useAppSelector((state) => selectOrganizationDisplayName(state, organizationName))

  const handleCloseLeftNavBar = useCallback(() => {
    dispatch(setLeftColumnShowing({ showing: false }))
  }, [dispatch])

  const onEditOrganizationDisplayName = useCallback(
    (newOrganizationDisplayName: string) => {
      if (organizationName) {
        dispatch(editOrganizationDisplayName({ organizationName, newOrganizationDisplayName }))
      }
    },
    [dispatch, organizationName],
  )

  useEffect(() => {
    dispatch(getOrganization({ organizationName }))
    dispatch(trackMixpanelPageViewedEvent({ path: editOrganizationPageRoutePath, organizationName }))
  }, [dispatch, organizationName])

  return isGetOrganizationInfoPending ? (
    <LoadingPage />
  ) : (
    <EditOrganizationPage
      isEditOrganizationPending={isEditOrganizationPending}
      isLeftColumnOpen={isLeftColumnOpen}
      onCloseLeftDrawer={handleCloseLeftNavBar}
      onEditOrganization={onEditOrganizationDisplayName}
      organizationDisplayName={organizationDisplayName as string}
      organizationName={organizationName as string}
    />
  )
}
