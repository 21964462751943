import type { FunctionComponent } from 'react'
import { useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'
import { selectRequestStatusIsPending } from '../../features/requestStatus/requestStatusSelectors'
import { confirmPasswordReset } from '../../features/resetPassword/resetPasswordSlice'
import { useAppDispatch, useAppSelector } from '../../store'
import { UpdatePassword } from './components/UpdatePassword'

export const ConnectedUpdatePasswordPage: FunctionComponent = () => {
  const [searchParam] = useSearchParams()
  const dispatch = useAppDispatch()
  const token = searchParam.get('token')
  const isConfirmPasswordResetPending = useAppSelector((state) =>
    selectRequestStatusIsPending(state, 'confirmPasswordReset'),
  )

  const onConfirmPasswordReset = useCallback(
    (password: string) => {
      if (token) {
        dispatch(confirmPasswordReset({ token, newPassword: password }))
      }
    },
    [dispatch, token],
  )

  return (
    <UpdatePassword
      onSubmit={({ password }) => onConfirmPasswordReset(password)}
      isSubmitPending={isConfirmPasswordResetPending}
    />
  )
}
