import type { ContainerGroupDetailsList } from '../../../../components/containerGroups/models'

export const filterContainerGroupsDeployments = (
  containerGroupsList: ContainerGroupDetailsList,
  selectedFilterByOption: string | undefined,
  filterByPreparingOption: string,
  filterByStoppedOption: string,
  filterByDeployingOption: string,
  filterByRunningOption: string,
  filterByFailedOption: string,
  query: string,
) =>
  containerGroupsList.filter((containerGroups) => {
    const filterByQuery =
      query.length > 0 ? containerGroups.displayName.toUpperCase().includes(query.toUpperCase()) : true
    const filterByOptions = (selectedFilter: string | undefined) => {
      switch (selectedFilter) {
        case filterByPreparingOption:
          // TODO: once the API has been updated, change pending to preparing and remove any
          return containerGroups.currentState.status === ('pending' as any)
        case filterByStoppedOption:
          return containerGroups.currentState.status === 'stopped'
        case filterByDeployingOption:
          // TODO: once the API has been updated, remove any
          return containerGroups.currentState.status === ('deploying' as any)
        case filterByRunningOption:
          return containerGroups.currentState.status === 'running'
        case filterByFailedOption:
          return containerGroups.currentState.status === 'failed'
        default:
          return true
      }
    }

    return filterByQuery && filterByOptions(selectedFilterByOption)
  })

export const sortContainerGroupsDeployments = (
  containerGroupsList: ContainerGroupDetailsList,
  selectedSortByOption: string | undefined,
  sortByAToZOption: string,
  sortByZToAOption: string,
  sortByStatusOption: string,
  sortByStatusReversedOption: string,
  sortByNewestOption: string,
  sortByOlderOption: string,
) => {
  // TODO: update pending to preparing once it has been updated in the api and remove any
  const statusOrder: any = {
    pending: 0,
    stopped: 1,
    deploying: 2,
    running: 3,
    failed: 4,
  }

  switch (selectedSortByOption) {
    case sortByAToZOption:
      containerGroupsList.sort((containerGroupA, containerGroupB) =>
        containerGroupA.displayName.localeCompare(containerGroupB.displayName),
      )
      break

    case sortByZToAOption:
      containerGroupsList
        .sort((containerGroupA, containerGroupB) =>
          containerGroupA.displayName.localeCompare(containerGroupB.displayName),
        )
        .reverse()
      break

    case sortByStatusOption:
      containerGroupsList.sort(
        (containerGroupA, containerGroupB) =>
          statusOrder[containerGroupA.currentState.status] - statusOrder[containerGroupB.currentState.status],
      )
      break

    case sortByStatusReversedOption:
      containerGroupsList.sort(
        (containerGroupA, containerGroupB) =>
          statusOrder[containerGroupB.currentState.status] - statusOrder[containerGroupA.currentState.status],
      )
      break

    case sortByNewestOption:
      containerGroupsList.sort(
        (containerGroupA, containerGroupB) =>
          containerGroupB.createTime.getTime() - containerGroupA.createTime.getTime(),
      )
      break

    case sortByOlderOption:
      containerGroupsList
        .sort(
          (containerGroupA, containerGroupB) =>
            containerGroupB.createTime.getTime() - containerGroupA.createTime.getTime(),
        )
        .reverse()
      break
  }

  return containerGroupsList
}
