import { defineMessages } from 'react-intl'

export const EditAWSElasticInputFieldsMessages = defineMessages({
  accessKeyIDLabel: {
    defaultMessage: 'Access Key ID',
    id: 'a272kZ5a',
    description: 'The label for the Amazon ECR access key ID input field.',
  },
  accessKeyIDPlaceholderText: {
    defaultMessage: 'Your Access Key ID',
    id: '8uYiaqsO',
    description: 'The placeholder text for the Amazon ECR access key ID input field.',
  },
  accessKeyIDRequiredText: {
    defaultMessage: 'Access Key ID is required',
    id: 'yH+edMlz',
    description: 'The required text for the Amazon ECR access key ID input field.',
  },
  needHelpText: {
    defaultMessage: 'Need help with setting up on Amazon ECR?',
    id: '8Th/t9Bz',
    description: 'The help text shown below the Amazon ECR input fields.',
  },
  secretAccessKeyLabel: {
    defaultMessage: 'Secret Access Key',
    id: '9PWGYzza',
    description: 'The label for the Amazon ECR secret access key input field.',
  },
  secretAccessKeyPlaceholderText: {
    defaultMessage: 'Your Secret Access Key',
    id: 'CZVpn23j',
    description: 'The placeholder text for the Amazon ECR secret access key input field.',
  },
  secretAccessKeyRequiredText: {
    defaultMessage: 'Secret Access Key is required',
    id: 'KjDxRxSb',
    description: 'The required text for the Amazon ECR secret access key input field.',
  },
})
