import { defineMessages } from 'react-intl'

export const EditJobQueueFormMessages = defineMessages({
  backToJobQueueDetails: {
    defaultMessage: 'Back to {job_queue_display_name}',
    id: 'v5SEcFMR',
    description: 'Back to Job Queue Details link',
  },
  descriptionFieldLabel: {
    defaultMessage: 'Description',
    id: 'Zf5fAE1U',
    description: 'The label for the description field on the Create Job Queue Page.',
  },
  descriptionFieldPlaceholder: {
    defaultMessage: 'Enter an optional description for your job queue',
    id: 'M94LnKKv',
    description: 'The placeholder text for the description field on the Create Job Queue Page.',
  },
  descriptionHelperText: {
    defaultMessage: 'Optional',
    id: '8U3xqxe2',
    description: 'The helper text for filling out the description input field.',
  },
  descriptionMaxCharacterErrorMessage: {
    defaultMessage: 'Description cannot exceed 500 characters',
    id: 'ft5zRgZK',
    description: 'The error message for when the description exceeds 500 characters.',
  },
  displayNameFieldLabel: {
    defaultMessage: 'Display Name',
    id: 'nlx/5Y2y',
    description: 'The label for the display name field on the Edit Job Queue Page.',
  },
  displayNameFieldPlaceholder: {
    defaultMessage: 'Enter a display name for your job queue',
    id: 'Kjz1Mfza',
    description: 'The placeholder text for the display name field on the Edit Job Queue Page.',
  },
  displayNameHelperText: {
    defaultMessage: 'Between 2 and 63 lowercase letters and numbers, hyphens allowed',
    id: 'YN4QcJLG',
    description: 'The placeholder text for the display name field on the Edit Job Queue Page.',
  },
  saveJobQueueButtonLabel: {
    defaultMessage: 'Save',
    id: 'YjCR0XQf',
    description: 'The button label for the the form submit button used to edit a job queue.',
  },
  slugNameDescription: {
    defaultMessage:
      'This is the permanent name for your deployment used in API calls. You can change the name used in the Portal by editiing the display name below.',
    id: 'Pe7LP71W',
    description: 'The description for the slug name section of the Edit Job Queue page.',
  },
  slugNameHeader: {
    defaultMessage: 'Job Queue Slug Name',
    id: 'GsDmlACB',
    description: 'The header for the slug name section of the Edit Job Queue page.',
  },
  title: {
    defaultMessage: 'Edit Job Queue',
    id: 'wiIzTzge',
    description: 'The title of the Edit Job Queue page.',
  },
})
