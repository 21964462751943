import { defineMessages } from 'react-intl'

export const LoadInvoiceDashboardErrorContentMessages = defineMessages({
  body: {
    defaultMessage:
      'We were unable to load the invoice dashboard for your organization. If this issue persists {contact_support}.',
    id: 'Q5N70J2c',
    description:
      'The description shown in a toast notification message shown when we encounter an error loading the billing invoice dashboard.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'B9Tzu0um',
    description: 'The text shown for the contact support link in the load invoice dashboard error toast notification.',
  },
  title: {
    defaultMessage: 'Error loading invoice dashboard',
    id: 'fQcCsbCr',
    description: 'The toast notification title shown when we encounter an error loading the billing invoice dashboard.',
  },
})
