import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { Breadcrumbs } from '../../../../components/block'
import type { VerticalProgressBarStep } from '../../../../components/block/VerticalProgressBarWithClick'
import { VerticalProgressBarWithClick } from '../../../../components/block/VerticalProgressBarWithClick'
import { WizardProgressBarMessages } from '../../messages'

interface WizardProgressBarProps {
  /** The organization display name. */
  organizationDisplayName: string
  /** The project display name. */
  projectDisplayName: string
  /** The steps for the progress bar. */
  steps: VerticalProgressBarStep[]
  /** Function to handle step click */
  onStepClick?: (stepIndex: number) => void
}

export const WizardProgressBar: FunctionComponent<WizardProgressBarProps> = ({
  onStepClick,
  organizationDisplayName,
  projectDisplayName,
  steps,
}) => {
  const intl = useIntl()

  return (
    <div className="h-48 p-4">
      <h3 className="mb-2 text-xs">{intl.formatMessage(WizardProgressBarMessages.header)}</h3>
      <div className="mb-7">
        <Breadcrumbs breadcrumbs={[organizationDisplayName, projectDisplayName]} />
      </div>
      <VerticalProgressBarWithClick
        stepsTitle="Configure and Deploy"
        steps={steps}
        onUpdateSteps={onStepClick ? (step) => onStepClick(steps.indexOf(step)) : undefined}
      />
    </div>
  )
}
