import type { FunctionComponent } from 'react'
import { LeftNavigationBar } from '../../components/LeftNavigationBar'
import type { ProjectSelectOptions } from '../../components/LeftNavigationBar/models'
import { ThreeColumnLayout } from '../../layouts/threeColumnLayout'
import { EditJobQueueForm } from './components/EditJobQueueForm'

export interface EditJobQueuePageProps {
  /** The current organization that the user is in. */
  currentOrganization: { name: string; displayName: string }
  /** The current project that the user is in. */
  currentProject: { name: string; displayName: string }
  /** The flag indicating that the request to delete the project is pending. */
  isDeleteProjectPending: boolean
  /** The flag indicating that the request to delete the project is successful. */
  isDeleteProjectSuccessful: boolean
  /** The flag indicating that the request to edit a job queue is pending. */
  isEditJobQueuePending: boolean
  /** The flag indicating if the left column is showing */
  isLeftColumnOpen: boolean
  /** The description of the job queue. */
  jobQueueDescription?: string | null
  /** The path for the Job Queue Details Page. */
  jobQueueDetailsPath: string
  /** The job queue display name. */
  jobQueueDisplayName: string
  /** The job queue name. */
  jobQueueName: string
  /** The callback executed when the user closes the left drawer */
  onCloseLeftDrawer: () => void
  /** The callback called when the user deletes the project. */
  onDeleteProject: (projectName: string) => void
  /** A callback that when executed edits a job queue. */
  onEditJobQueue: (displayName: string, description?: string) => void
  /**
   * The handler for when something new is selected in the project dropdown. If the current active project is selected,
   * nothing will happen.
   */
  onProjectChange: (projectName: string) => void
  /** An array of the current available projects. If empty, we will default to no select bar being displayed */
  projects: ProjectSelectOptions
}

export const EditJobQueuePage: FunctionComponent<EditJobQueuePageProps> = ({
  currentOrganization,
  currentProject,
  isDeleteProjectPending,
  isDeleteProjectSuccessful,
  isEditJobQueuePending,
  isLeftColumnOpen,
  jobQueueDescription,
  jobQueueDetailsPath,
  jobQueueDisplayName,
  jobQueueName,
  onCloseLeftDrawer,
  onDeleteProject,
  onEditJobQueue,
  onProjectChange,
  projects,
}) => (
  <ThreeColumnLayout
    isLeftColumnOpen={isLeftColumnOpen}
    LeftColumn={
      <LeftNavigationBar
        currentOrganization={currentOrganization}
        currentProject={currentProject}
        isDeleteProjectPending={isDeleteProjectPending}
        isDeleteProjectSuccessful={isDeleteProjectSuccessful}
        onDeleteProject={onDeleteProject}
        onProjectChange={onProjectChange}
        projects={projects}
      />
    }
    MainColumn={
      <EditJobQueueForm
        description={jobQueueDescription}
        displayName={jobQueueDisplayName}
        isEditJobQueuePending={isEditJobQueuePending}
        jobQueueDetailsPath={jobQueueDetailsPath}
        name={jobQueueName}
        onEditJobQueue={onEditJobQueue}
      />
    }
    onCloseLeftDrawer={onCloseLeftDrawer}
  />
)
