import mixpanel from 'mixpanel-browser'
import { catchError, concat, delay, filter, from, mergeMap, of, tap } from 'rxjs'
import { UserAccountsAPI } from '../apiMethods'
import { MIXPANEL_USER_PROJECT_TOKEN } from '../config'
import { setAuthenticatedState } from '../features/authentication/authenticationSlice'
import { logout, setLogoutRequestStatus } from '../features/logout/logoutSlice'
import { showToastNotification } from '../features/notifications/notificationsSlice'
import { RequestStatus } from '../models'
import type { AppEpic } from '../store'

export const onLogout: AppEpic = (action$, _state$) =>
  action$.pipe(
    filter(logout.match),
    mergeMap(() =>
      concat(
        of(setLogoutRequestStatus({ status: RequestStatus.Loading })),
        from(UserAccountsAPI.logout()).pipe(
          tap({
            next: () => {
              // Only reset the Mixpanel cookie on an explicit logout.
              if (MIXPANEL_USER_PROJECT_TOKEN !== undefined) {
                mixpanel.reset()
              }
            },
          }),
          mergeMap(() =>
            concat(
              of(
                setLogoutRequestStatus({ status: RequestStatus.Succeeded }),
                setAuthenticatedState({ authenticated: false }),
              ),
              of(setLogoutRequestStatus({ status: RequestStatus.Idle })).pipe(delay(1)),
            ),
          ),
          catchError((_error: unknown) => {
            const genericErrorMessage = 'We were unable to logout at this time. Please refresh the page and try again.'
            return concat(
              of(
                showToastNotification({
                  body: genericErrorMessage,
                  title: 'Unable to logout',
                  type: 'error',
                }),
                setLogoutRequestStatus({ status: RequestStatus.Failed }),
              ),
              of(setLogoutRequestStatus({ status: RequestStatus.Idle })).pipe(delay(1)),
            )
          }),
        ),
      ),
    ),
  )
