import type { WorkloadError } from '@saladtechnologies/openapi-cloud-portal-browser'
import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { formatToDateTime } from '../../../utils'
import { TableHeader } from '../../block/Table/TableHeader'
import type { WorkloadErrorsTableSortingMethod } from './models/WorkloadErrorsTableSortingMethod'
import { configureWorkloadErrorsTableHeader, getSortedWorkloadErrors } from './utils'

export interface WorkloadErrorsTableProps {
  /** List of workload errors */
  workloadErrors: WorkloadError[]
}

export const WorkloadErrorsTable: FunctionComponent<WorkloadErrorsTableProps> = ({ workloadErrors }) => {
  const intl = useIntl()
  const [sortingMethod, setSortingMethod] = useState<WorkloadErrorsTableSortingMethod>({
    allocatedAtAscendingOrder: true,
    column: 'detail',
    detailAscendingOrder: true,
    failedAtAscendingOrder: true,
    machineIdAscendingOrder: true,
    startedAtAscendingOrder: true,
  })

  const sortedWorkloadErrors = getSortedWorkloadErrors(workloadErrors, sortingMethod)

  const workloadErrorTableHeader = configureWorkloadErrorsTableHeader(intl, sortingMethod, setSortingMethod)

  return (
    <table className="w-full table-fixed">
      <TableHeader headers={workloadErrorTableHeader} />
      {workloadErrors.length > 0 && (
        <tbody>
          {sortedWorkloadErrors.map(({ allocatedAt, detail, failedAt, machineId, startedAt }) => (
            <tr key={machineId}>
              <td className={classNames('border-b border-b-neutral-30 px-4 py-9 text-center align-middle')}>
                <span className="mt-1">{detail}</span>
              </td>
              <td className={classNames('border-b border-b-neutral-30 px-4 py-9 text-center align-middle')}>
                <span className="mt-1">{machineId}</span>
              </td>
              <td className={classNames('border-b border-b-neutral-30 px-4 py-9 text-center align-middle')}>
                <span className="mt-1">{formatToDateTime(intl, failedAt)}</span>
              </td>
              <td className={classNames('border-b border-b-neutral-30 px-4 py-9 text-center align-middle')}>
                <span className="mt-1">{allocatedAt && formatToDateTime(intl, allocatedAt)}</span>
              </td>
              <td className={classNames('border-b border-b-neutral-30 px-4 py-9 text-center align-middle')}>
                <span className="mt-1">{startedAt && formatToDateTime(intl, startedAt)}</span>
              </td>
            </tr>
          ))}
        </tbody>
      )}
    </table>
  )
}
