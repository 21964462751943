import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import leftTrianglesUrl from '../assets/left-triangles.svg'
import rightTrianglesUrl from '../assets/right-triangles.svg'
import { TriangleBackgroundMessages } from '../messages'

export const TriangleBackgroundImages: FunctionComponent = () => {
  const intl = useIntl()

  return (
    <>
      <img
        src={leftTrianglesUrl}
        alt={intl.formatMessage(TriangleBackgroundMessages.leftImageAltText)}
        className="absolute left-0 top-0 h-[688px] w-[618px]"
      />
      <img
        src={rightTrianglesUrl}
        alt={intl.formatMessage(TriangleBackgroundMessages.rightImageAltText)}
        className="absolute bottom-0 right-0 hidden h-[688px] w-[618px] md:block"
      />
    </>
  )
}
