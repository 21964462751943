import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { Button } from '../../../../components/Button'
import { Tag } from '../../../../components/Tag'
import { Card } from '../../../../components/base/Card'
import { TeamMemberCardMessages } from '../../messages'

export interface TeamMemberCardProps {
  /** The current user's email. */
  currentUserEmail: string
  /** The email displayed in the team member card. */
  email: string
  /** The invitation information related to a team member who has been invited, but yet to accept the invitation. */
  invitation?: {
    id: string
    date: Date
  }
  /** The flag indicating if the member is the last member of the team. */
  isLastMember: boolean
  /** The flag indicating if the request to remove the member is pending. */
  isRemoveMemberPending?: boolean
  /** The flag indicating if the request to resend the invitation is pending. */
  isResendInvitationPending?: boolean
  /** The flag indicating if the request to revoke the invitation is pending. */
  isRevokeInvitationPending?: boolean
  /** The date on which the team member joined the team. */
  joinedDate?: Date
  /** The unique id of the team member. */
  memberId: string
  /**
   * A callback that when executed removes a member from a team.
   *
   * @param memberId The unique identifier of the team member.
   */
  onRemoveMember: (memberId: string) => void
  /**
   * A callback that when executed resends the invitation to the team member.
   *
   * @param invitationId The unique identifier of the invitation.
   */
  onResendInvitation: (invitationId: string) => void
  /**
   * A callback that when executed revokes the invitation sent for to the team member.
   *
   * @param invitationId The unique identifier of the invitation.
   */
  onRevokeInvitation: (invitationId: string) => void
}

export const TeamMemberCard: FunctionComponent<TeamMemberCardProps> = ({
  currentUserEmail,
  email,
  invitation,
  isLastMember,
  isRemoveMemberPending,
  isResendInvitationPending,
  isRevokeInvitationPending,
  joinedDate,
  memberId,
  onRemoveMember,
  onResendInvitation,
  onRevokeInvitation,
}) => {
  const intl = useIntl()
  const isMember = invitation === undefined

  const removeMemberButton = isLastMember ? null : (
    <Button variant="red-filled-light" onClick={() => onRemoveMember(memberId)} isLoading={isRemoveMemberPending}>
      {currentUserEmail === email
        ? intl.formatMessage(TeamMemberCardMessages.leaveTeam)
        : intl.formatMessage(TeamMemberCardMessages.removeMemberButtonLabel)}
    </Button>
  )

  return (
    <Card>
      <div className="flex flex-wrap items-center justify-between gap-3 sm:flex-nowrap">
        <div>
          <p
            className={classNames('mb-2 font-bold', {
              'break-all': email.length >= 20,
            })}
            data-testid="teamPageMemberCardEmail"
          >
            {email}
          </p>
          <div className="flex items-center">
            <Tag color={isMember ? 'green' : 'gray'}>
              {isMember
                ? intl.formatMessage(TeamMemberCardMessages.joined)
                : intl.formatMessage(TeamMemberCardMessages.invited)}
            </Tag>
            <p className="ml-2 text-xs text-neutral-70">
              {isMember ? (
                <>
                  {intl.formatMessage(TeamMemberCardMessages.joinedDate, {
                    joined_date: intl.formatDate(joinedDate, { dateStyle: 'long' }),
                  })}
                </>
              ) : (
                <>
                  {intl.formatMessage(TeamMemberCardMessages.invitationDate, {
                    invitation_date: intl.formatDate(invitation.date, { dateStyle: 'long' }),
                  })}
                </>
              )}
            </p>
          </div>
        </div>

        {isMember ? (
          removeMemberButton
        ) : (
          <div className="flex flex-wrap gap-4 sm:flex-nowrap">
            <Button
              variant="red-filled-light"
              onClick={() => onRevokeInvitation(invitation.id)}
              isLoading={isRevokeInvitationPending}
            >
              {intl.formatMessage(TeamMemberCardMessages.revokeInvitationButtonLabel)}
            </Button>
            <Button
              variant="green-filled-light"
              onClick={() => onResendInvitation(invitation.id)}
              isLoading={isResendInvitationPending}
            >
              {intl.formatMessage(TeamMemberCardMessages.resendInvitationButtonLabel)}
            </Button>
          </div>
        )}
      </div>
    </Card>
  )
}

TeamMemberCard.defaultProps = {
  invitation: undefined,
  isRemoveMemberPending: false,
  isResendInvitationPending: false,
  isRevokeInvitationPending: false,
  joinedDate: undefined,
}
