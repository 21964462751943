import type { FunctionComponent } from 'react'
import type { Control, FieldValues, UseFormTrigger } from 'react-hook-form'
import { Controller, useFieldArray } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Button } from '../../../../../components/Button'
import { TextField } from '../../../../../components/TextField'
import { Card } from '../../../../../components/base/Card'
import { SidePanelModal } from '../../../../../components/block/SidePanelModal'
import { HealthProbeHeadersSidePanelModalMessages } from '../../../messages'
import type { CreateContainerGroupValues, HealthProbeType } from '../../../models'

interface HealthProbeHeadersSidePanelModalProps {
  /** The control for the create container group react hook form. */
  control: Control<FieldValues, CreateContainerGroupValues>
  /** The ID for the command side panel modal form. */
  formID: string
  /** The name for the headers field. */
  headerFieldName: string
  /** The health probe the headers is being added to for the side panel modal. */
  healthProbe: HealthProbeType
  /** The flag indicating if the side panel is open. */
  isShown: boolean
  /** The callback executed to close the modal. */
  onClose: () => void
  /** The react hook form method that triggers validation for specified fields. */
  trigger: UseFormTrigger<FieldValues>
}

export const HealthProbeHeadersSidePanelModal: FunctionComponent<HealthProbeHeadersSidePanelModalProps> = ({
  control,
  formID,
  headerFieldName,
  healthProbe,
  isShown,
  onClose,
  trigger,
}) => {
  const { append, fields, remove } = useFieldArray({
    control,
    name: headerFieldName,
  })
  const intl = useIntl()

  const handleValidateFieldsBeforeClose = async (event?: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault()
    await trigger([headerFieldName]).then((isValid) => {
      if (isValid) {
        onClose()
      }
    })
  }

  const handleRemoveHeader = async (index: number) => {
    await trigger([headerFieldName]).then(() => {
      remove(index)
    })
  }

  return (
    <SidePanelModal
      CustomButton={
        <Button
          variant="green-outlined"
          onClick={() => handleValidateFieldsBeforeClose()}
          form={formID}
          isFullWidth
          type="button"
        >
          {intl.formatMessage(HealthProbeHeadersSidePanelModalMessages.backButtonLabel)}
        </Button>
      }
      isShown={isShown}
      isStacked
      onClose={() => handleValidateFieldsBeforeClose()}
      title={intl.formatMessage(HealthProbeHeadersSidePanelModalMessages.title, { health_probe: healthProbe })}
    >
      <form onSubmit={handleValidateFieldsBeforeClose} id={formID}>
        <div className="my-12 px-10 pb-12">
          <div className="mb-10">
            <h2 className="mb-1 text-3xl font-bold">
              {intl.formatMessage(HealthProbeHeadersSidePanelModalMessages.title, { health_probe: healthProbe })}
            </h2>
          </div>

          {fields.length === 0 && (
            <div className="mb-4">
              <Card>
                <div className="mb-5 flex justify-between">
                  <p className="text-lg font-bold">
                    {intl.formatMessage(HealthProbeHeadersSidePanelModalMessages.headerInputTitle, {
                      count: 1,
                    })}
                  </p>
                  <Button variant="red-outlined" onClick={() => {}} type="button" isDisabled>
                    {intl.formatMessage(HealthProbeHeadersSidePanelModalMessages.removeButtonLabel)}
                  </Button>
                </div>

                <TextField
                  label={intl.formatMessage(HealthProbeHeadersSidePanelModalMessages.headerNameFieldLabel)}
                  onChange={(value) => append({ name: value, value: '' })}
                  isFullWidth
                  type="text"
                />

                <TextField
                  label={intl.formatMessage(HealthProbeHeadersSidePanelModalMessages.headerValueFieldLabel)}
                  onChange={(value) => append({ name: '', value: value })}
                  isFullWidth
                  type="text"
                />
              </Card>
            </div>
          )}

          {fields.map((item, index) => (
            <div className="mb-4" key={item.id}>
              <Card>
                <div className="mb-5 flex justify-between">
                  <p className="text-lg font-bold">
                    {intl.formatMessage(HealthProbeHeadersSidePanelModalMessages.headerInputTitle, {
                      count: index + 1,
                    })}
                  </p>
                  <Button variant="red-outlined" onClick={() => handleRemoveHeader(index)} type="button">
                    {intl.formatMessage(HealthProbeHeadersSidePanelModalMessages.removeButtonLabel)}
                  </Button>
                </div>

                <Controller
                  name={`${headerFieldName}.${index}.name`}
                  control={control}
                  render={({ field, fieldState }) => {
                    return (
                      <TextField
                        {...field}
                        {...fieldState}
                        label={intl.formatMessage(HealthProbeHeadersSidePanelModalMessages.headerNameFieldLabel)}
                        helperText={fieldState.error?.message}
                        isFullWidth
                        type="text"
                      />
                    )
                  }}
                />

                <Controller
                  name={`${headerFieldName}.${index}.value`}
                  control={control}
                  render={({ field, fieldState }) => {
                    return (
                      <TextField
                        {...field}
                        {...fieldState}
                        label={intl.formatMessage(HealthProbeHeadersSidePanelModalMessages.headerValueFieldLabel)}
                        helperText={fieldState.error?.message}
                        isFullWidth
                        type="text"
                      />
                    )
                  }}
                />
              </Card>
            </div>
          ))}

          <Button
            variant="green-filled-light"
            onClick={() => append({ name: '', value: '' })}
            type="button"
            isDisabled={fields.length === 100}
          >
            {intl.formatMessage(HealthProbeHeadersSidePanelModalMessages.addHeadersButtonLabel)}
          </Button>
        </div>
      </form>
    </SidePanelModal>
  )
}
