import { defineMessages } from 'react-intl'

export const NewRelicInputFieldsMessages = defineMessages({
  hostLabel: {
    defaultMessage: 'Host',
    id: 'XTMN1+hA',
    description: 'The label for the New Relic host input field.',
  },
  hostHelperText: {
    defaultMessage: 'The default value is https://log-api.newrelic.com/log/v1',
    id: 'aweX5DFa',
    description: 'The helper text for the New Relic host input field.',
  },
  hostRequiredText: {
    defaultMessage: 'Host is required for New Relic. The default value is https://log-api.newrelic.com/log/v1',
    id: 'q5fs35DN',
    description: 'The required text for the New Relic host input field.',
  },
  hostMaxCharactersText: {
    defaultMessage: 'Host for New Relic can be no longer than 1000 characters',
    id: 'RgMu90GA',
    description: 'The max characters text for the New Relic host input field.',
  },
  ingestionKeyLabel: {
    defaultMessage: 'Ingestion Key',
    id: 'c1UJevd5',
    description: 'The label for the New Relic ingestion key input field.',
  },
  ingestionKeyRequiredText: {
    defaultMessage: 'Ingestion Key is required for New Relic',
    id: 'VYC4Bbxn',
    description: 'The required text for the New Relic ingestion key input field.',
  },
  ingestionKeyMaxCharactersText: {
    defaultMessage: 'Ingestion Key for New Relic can be no longer than 1000 characters',
    id: '7TsyrVid',
    description: 'The max characters text for the New Relic ingestion key input field.',
  },
})
