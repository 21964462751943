import { defineMessages } from 'react-intl'

export const LoadUsageDashboardErrorMessages = defineMessages({
  body: {
    defaultMessage:
      'We were unable to load the usage dashboard for your organization. If this issue persists {contact_support}.',
    id: 'RV21m7A4',
    description:
      'The description shown in a toast notification message shown when we encounter an error loading the billing usage dashboard.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'qll/XzTb',
    description: 'The text shown for the contact support link in the load usage dashboard error toast notification.',
  },
  title: {
    defaultMessage: 'Error loading usage dashboard',
    id: '2OCqRaCi',
    description: 'The toast notification title shown when we encounter an error loading the billing usage dashboard.',
  },
})
