import type { FunctionComponent } from 'react'
import { useCallback, useEffect } from 'react'
import { trackMixpanelPageViewedEvent } from '../../features/analytics/analyticsSlice'
import { createNewOrganizationOfCreateNewOrganizationAndProject } from '../../features/createNewOrganization/createNewOrganizationSlice'
import { selectIsLeftColumnShowing } from '../../features/navigationBar/navigationBarSelectors'
import { setLeftColumnShowing } from '../../features/navigationBar/navigationBarSlice'
import { selectRequestStatus } from '../../features/requestStatus/requestStatusSelectors'
import { createOrganizationPageRoutePath } from '../../routes/routePaths'
import { useAppDispatch, useAppSelector } from '../../store'
import { CreateOrganizationPage } from './CreateOrganizationPage'

export const ConnectedCreateNewOrganizationPage: FunctionComponent = () => {
  const dispatch = useAppDispatch()

  const isCreateOrganizationAndProjectPending = useAppSelector(
    (state) => selectRequestStatus(state, 'createOrganizationAndProject') === 'pending',
  )
  const isLeftColumnOpen = useAppSelector(selectIsLeftColumnShowing)

  const onCreateOrganization = useCallback(
    (organizationName: string, projectName: string, referralCode?: string) => {
      dispatch(createNewOrganizationOfCreateNewOrganizationAndProject({ organizationName, projectName, referralCode }))
    },
    [dispatch],
  )

  const handleCloseLeftNavBar = useCallback(() => {
    dispatch(setLeftColumnShowing({ showing: false }))
  }, [dispatch])

  useEffect(() => {
    dispatch(trackMixpanelPageViewedEvent({ path: createOrganizationPageRoutePath }))
  }, [dispatch])

  return (
    <CreateOrganizationPage
      isCreateOrganizationPending={isCreateOrganizationAndProjectPending}
      isLeftColumnOpen={isLeftColumnOpen}
      onCloseLeftDrawer={handleCloseLeftNavBar}
      onCreateOrganization={onCreateOrganization}
    />
  )
}
