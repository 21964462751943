import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { RequestStatus } from '../../models'

export interface LogoutState {
  logoutErrorMessage: string | undefined
  logoutRequestStatus: RequestStatus
}

const initialState: LogoutState = {
  logoutErrorMessage: undefined,
  logoutRequestStatus: RequestStatus.Idle,
}

export const logoutSlice = createSlice({
  name: 'logout',
  initialState,
  reducers: {
    logout() {},
    setLogoutErrorMessage(state, action: PayloadAction<{ errorMessage: string | undefined }>) {
      state.logoutErrorMessage = action.payload.errorMessage
    },
    setLogoutRequestStatus(state, action: PayloadAction<{ status: RequestStatus }>) {
      state.logoutRequestStatus = action.payload.status
    },
  },
})

export const { logout, setLogoutErrorMessage, setLogoutRequestStatus } = logoutSlice.actions
