import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { ExternalLoggingDetailsMessages } from '../../messages'

interface NewRelicExternalLoggingDetailsProps {
  /** The new relic host value. */
  newRelicHost: string | undefined
  /** The new relic ingestion key value. */
  newRelicIngestionKey: string | undefined
}

const colon = ':'

export const NewRelicExternalLoggingDetails: FunctionComponent<NewRelicExternalLoggingDetailsProps> = ({
  newRelicHost,
  newRelicIngestionKey,
}) => {
  const intl = useIntl()

  return (
    <div className="text-base font-medium">
      <p>{intl.formatMessage(ExternalLoggingDetailsMessages.newRelicSelectedLabel)}</p>
      <p className="truncate">
        {intl.formatMessage(ExternalLoggingDetailsMessages.hostLabel)}
        {colon}
        &nbsp;
        <span className="font-normal">{newRelicHost ?? ''}</span>
      </p>
      <p className="truncate">
        {intl.formatMessage(ExternalLoggingDetailsMessages.ingestionKeyLabel)}
        {colon}
        &nbsp;
        <span className="font-normal">{newRelicIngestionKey ?? ''}</span>
      </p>
    </div>
  )
}
