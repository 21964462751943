import { defineMessages } from 'react-intl'

export const JobQueueDetailsMessages = defineMessages({
  addJobQueueLabel: {
    defaultMessage: 'Add Job Queue',
    id: '10sv3CsK',
    description: 'The label for the add job queue button.',
  },
  disabledLabel: {
    defaultMessage: 'Disabled',
    id: 'XObt4hwS',
    description: 'The label for the job queue details if none is selected.',
  },
  queueNameLabel: {
    defaultMessage: 'Queue: {queue}',
    id: 'VD/lK/EU',
    description: 'The label for the job queue name.',
  },
  queuePathLabel: {
    defaultMessage: 'Path: {path}',
    id: 'W3AUtW5g',
    description: 'The label for the job queue path.',
  },
  queuePortLabel: {
    defaultMessage: 'Port: {port}',
    id: 'D0O84+YU',
    description: 'The label for the job queue port.',
  },
})
