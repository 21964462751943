import type { ReactNode } from 'react'
import type { IntlShape } from 'react-intl'
import type { ToastNotificationType } from '../../models'
import { SuccessfullyInvitedTeamMemberContentMessages } from './messages'

/**
 * Retrieves the content needed for a success `ToastNotification` that is shown when the user invites a team member to
 * their organization.
 *
 * @param intl The `IntlShape` object needed to format the messages.
 * @param userEmail The user email that is getting reinvited.
 * @param organizationDisplayName The organization display name.
 */
export const getInvitedTeamMemberSucceededContent = (
  intl: IntlShape,
  userEmail: string,
  organizationDisplayName: string,
): {
  body: ReactNode
  type: ToastNotificationType
  title: string
} => {
  return {
    body: intl.formatMessage(SuccessfullyInvitedTeamMemberContentMessages.body, {
      user_email: userEmail,
      organization_display_name: organizationDisplayName,
    }),

    type: 'success',
    title: SuccessfullyInvitedTeamMemberContentMessages.title.defaultMessage,
  }
}
