import type { ContainerGroupStatus, RecipeNetworking } from '@saladtechnologies/openapi-cloud-portal-browser'
import type { FunctionComponent } from 'react'
import { LeftNavigationBar } from '../../components/LeftNavigationBar'
import type { ProjectSelectOptions } from '../../components/LeftNavigationBar/models'
import { DeploymentInstance } from '../../features/containerGroupInstanceTable/models'
import { ThreeColumnLayout } from '../../layouts/threeColumnLayout'
import { RecipeDeploymentDetailsMainContent } from './components/RecipeDeploymentDetailsMainContent/RecipeDeploymentDetailsMainContent'

export interface RecipeDeploymentDetailsPageProps {
  /** The current organization that the user is in. */
  currentOrganization: { name: string; displayName: string }
  /** The current project that the user is in. */
  currentProject: { name: string; displayName: string }
  /** List of recipe deployment instances */
  deploymentInstances: DeploymentInstance[]
  /** The path for editing a recipe deployment */
  editRecipePath: string
  /** The flag indicating that the request to delete the project is pending. */
  isDeleteProjectPending: boolean
  /** The flag indicating that the request to delete the project is successful. */
  isDeleteProjectSuccessful: boolean
  /** The flag for if authentication is required */
  isDeleteRecipeDeploymentPending: boolean
  /** The flag indicating that the request to delete the recipe deployment was successful. */
  isDeleteRecipeDeploymentSuccessful?: boolean
  /** The flag indicating if the left column is showing */
  isLeftColumnOpen: boolean
  /** The flag indicating that the request to refresh the recipe deployment is pending. */
  isRefreshRecipeDeploymentPending: boolean
  /** The flag indicating that the request to start the recipe deployment is pending. */
  isStartRecipeDeploymentPending: boolean
  /** The flag indicating that the request to stop the recipe deployment is pending. */
  isStopRecipeDeploymentPending: boolean
  /**
   * The info regarding networking for the recipe deployment. If undefined, we will default to no networking being
   * enabled.
   */
  networking?: RecipeNetworking | null
  /** The callback executed when the user closes the left drawer */
  onCloseLeftDrawer: () => void
  /** The callback called when the user deletes the project. */
  onDeleteProject: (projectName: string) => void
  /** A callback that when executed deletes the deployed recipe */
  onDeleteRecipeDeployment: () => void
  /**
   * A callback that when executed records a mixpanel event
   *
   * @param label The label of the element that was clicked
   */
  onTrackMixpanelEvent: (label: string, machineId?: string) => void
  /**
   * The handler for when something new is selected in the project dropdown. If the current active project is selected,
   * nothing will happen.
   */
  onProjectChange: (projectDisplayName?: string) => void
  /** A callback that when executed refresh the deployed recipe */
  onRefreshRecipeDeployment: () => void
  /** A callback that when executed starts the deployed recipe */
  onStartRecipeDeployment: () => void
  /** A callback that when executed stops the deployed recipe */
  onStopRecipeDeployment: () => void
  /** An array of the current available projects. If empty, we will default to no select bar being displayed */
  projects: ProjectSelectOptions
  /** The display name of the deployed recipe */
  recipeDeploymentDisplayName: string
  /** The path for recipe deployments */
  recipeDeploymentsPath: string
  /** The recipe details page path */
  recipeDetailsPagePath: string
  /** The markdown of the deployed recipe */
  recipeMarkdown: string
  /** The original recipe name of the deployed recipe */
  recipeName: string
  /** The number of replicas of the deployed recipe */
  replicaCount: number
  /** The number of running replicas for deployed recipe. */
  replicasRunning: number
  /** The status of the deployed recipe */
  status: ContainerGroupStatus
  /** The pulling stage description. This should only show on the Preparing status */
  statusDescription?: string
}

export const RecipeDeploymentDetailsPage: FunctionComponent<RecipeDeploymentDetailsPageProps> = ({
  currentOrganization,
  currentProject,
  deploymentInstances,
  editRecipePath,
  isDeleteProjectPending,
  isDeleteProjectSuccessful,
  isDeleteRecipeDeploymentPending,
  isDeleteRecipeDeploymentSuccessful,
  isLeftColumnOpen,
  isRefreshRecipeDeploymentPending,
  isStartRecipeDeploymentPending,
  isStopRecipeDeploymentPending,
  networking,
  onCloseLeftDrawer,
  onDeleteProject,
  onDeleteRecipeDeployment,
  onProjectChange,
  onRefreshRecipeDeployment,
  onStartRecipeDeployment,
  onStopRecipeDeployment,
  onTrackMixpanelEvent,
  projects,
  recipeDeploymentDisplayName,
  recipeDeploymentsPath,
  recipeDetailsPagePath,
  recipeMarkdown,
  recipeName,
  replicaCount,
  replicasRunning,
  status,
  statusDescription,
}) => {
  return (
    <ThreeColumnLayout
      isRightColumnShowcase
      isLeftColumnOpen={isLeftColumnOpen}
      LeftColumn={
        <LeftNavigationBar
          currentOrganization={currentOrganization}
          currentProject={currentProject}
          isDeleteProjectPending={isDeleteProjectPending}
          isDeleteProjectSuccessful={isDeleteProjectSuccessful}
          onDeleteProject={onDeleteProject}
          onProjectChange={onProjectChange}
          projects={projects}
        />
      }
      MainColumn={
        <RecipeDeploymentDetailsMainContent
          deploymentInstances={deploymentInstances}
          editRecipePath={editRecipePath}
          isDeleteRecipeDeploymentPending={isDeleteRecipeDeploymentPending}
          isDeleteRecipeDeploymentSuccessful={isDeleteRecipeDeploymentSuccessful}
          isRefreshRecipeDeploymentPending={isRefreshRecipeDeploymentPending}
          isStartRecipeDeploymentPending={isStartRecipeDeploymentPending}
          isStopRecipeDeploymentPending={isStopRecipeDeploymentPending}
          networking={networking}
          onDeleteRecipeDeployment={onDeleteRecipeDeployment}
          onTrackMixpanelEvent={onTrackMixpanelEvent}
          onRefreshRecipeDeployment={onRefreshRecipeDeployment}
          onStartRecipeDeployment={onStartRecipeDeployment}
          onStopRecipeDeployment={onStopRecipeDeployment}
          recipeDeploymentDisplayName={recipeDeploymentDisplayName}
          recipeDeploymentsPath={recipeDeploymentsPath}
          recipeDetailsPagePath={recipeDetailsPagePath}
          recipeMarkdown={recipeMarkdown}
          recipeName={recipeName}
          replicaCount={replicaCount}
          replicasRunning={replicasRunning}
          status={status}
          statusDescription={statusDescription}
        />
      }
      onCloseLeftDrawer={onCloseLeftDrawer}
    />
  )
}
