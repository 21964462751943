import { yupResolver } from '@hookform/resolvers/yup'
import { InferenceEndpoint, InferenceEndpointJob } from '@saladtechnologies/openapi-cloud-portal-browser'
import classNames from 'classnames'
import { useState, type FunctionComponent } from 'react'
import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Button } from '../../../../components/Button'
import { TextField } from '../../../../components/TextField'
import { Card, Markdown } from '../../../../components/base'
import { useFixedForm } from '../../../../hooks'
import { InferenceEndpointDetailsMainContentMessages } from '../../messages'
import { InferenceEndpointName, InferenceEndpointPlaygroundFormValues, TranscribePayload } from '../../models'
import {
  convertInferenceEndpointJsonOutputToMarkdown,
  hasInferenceEndpointSuccessfullyCompleted,
  isInferenceEndpointJobRunningOrPending,
} from '../../utils'
import { InferenceEndpointPlaygroundStatus } from '../InferenceEndpointPlaygroundStatus'
import { useInferenceEndpointPlaygroundFormValidation } from '../useInferenceEndpointPlaygroundFormValidation'
import { onDownloadInferenceEndpointJobOutput } from '../utils'

interface InferenceEndpointPlaygroundProps {
  /** The Inference Endpoint */
  inferenceEndpoint: InferenceEndpoint
  /** The inference endpoint job associated with the inference endpoint. */
  inferenceEndpointJob?: InferenceEndpointJob
  /** The flag indicating if the create inference endpoint job is pending. */
  isCreateInferenceEndpointJobPending: boolean
  /** The callback executed when the user submits the playground form. */
  onCreateInferenceEndpointJob: (values: InferenceEndpointPlaygroundFormValues) => void
}

export const InferenceEndpointPlayground: FunctionComponent<InferenceEndpointPlaygroundProps> = ({
  inferenceEndpoint,
  inferenceEndpointJob,
  isCreateInferenceEndpointJobPending,
  onCreateInferenceEndpointJob,
}) => {
  const intl = useIntl()
  const inferenceEndpointPlaygroundFormValidation = useInferenceEndpointPlaygroundFormValidation()
  const [isDownloadLoading, setIsDownloadLoading] = useState(false)
  const { control, handleSubmit } = useFixedForm({
    resolver: yupResolver(inferenceEndpointPlaygroundFormValidation),
    onSubmit: onCreateInferenceEndpointJob,
  })

  const inferenceEndpointJobOutput =
    inferenceEndpointJob?.output ||
    intl.formatMessage(InferenceEndpointDetailsMainContentMessages.errorNoOutputAvailable)

  const isOutputShowing =
    inferenceEndpointJob &&
    inferenceEndpointJobOutput &&
    hasInferenceEndpointSuccessfullyCompleted(inferenceEndpointJob?.status)

  const handleOnDownload = () => {
    setIsDownloadLoading(true)
    onDownloadInferenceEndpointJobOutput(intl, inferenceEndpointJob, (isLoading: boolean) =>
      setIsDownloadLoading(isLoading),
    )
  }

  const defaultUrl = () => {
    const defaultTranscriptionUrl = `https://satranscription.blob.core.windows.net/transcription-unit-tests/That%20Little%20Voice.mp4?sp=r&st=2024-08-06T21:52:25Z&se=2027-04-01T05:52:25Z&spr=https&sv=2022-11-02&sr=b&sig=BMH0oUSmMlzne5%2BuFK0L6z2P6So8OOubieAk%2BVIpIiE%3D`
    const transcribePayloadUrl = (inferenceEndpointJob?.input as TranscribePayload)?.url

    if (transcribePayloadUrl) {
      return transcribePayloadUrl
    } else if (inferenceEndpoint.name === InferenceEndpointName.TRANSCRIBE) {
      return defaultTranscriptionUrl
    }

    return undefined
  }

  return (
    <Card classes="flex-grow w-full p-4">
      <form onSubmit={handleSubmit}>
        <h1 className="mb-4 text-3xl font-bold text-neutral-100">
          {intl.formatMessage(InferenceEndpointDetailsMainContentMessages.tryItOutButtonLabel)}
        </h1>
        <Controller
          name="url"
          control={control}
          defaultValue={defaultUrl}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              invalid={fieldState.invalid}
              isDisabled={isInferenceEndpointJobRunningOrPending(inferenceEndpointJob?.status)}
              helperText={fieldState.error?.message}
              defaultValue={defaultUrl()}
              label={intl.formatMessage(InferenceEndpointDetailsMainContentMessages.enterAudioUrl)}
              isFullWidth
              leftIconClassName="fa-solid fa-link"
              placeholder={intl.formatMessage(InferenceEndpointDetailsMainContentMessages.enterUrlPlaceholder)}
            />
          )}
        />
        <InferenceEndpointPlaygroundStatus status={inferenceEndpointJob?.status} />
        <Button
          isDisabled={isInferenceEndpointJobRunningOrPending(inferenceEndpointJob?.status)}
          isLoading={isCreateInferenceEndpointJobPending}
          isFullWidth
          type="submit"
        >
          {intl.formatMessage(InferenceEndpointDetailsMainContentMessages.runModel)}
        </Button>
      </form>
      {isOutputShowing && (
        <>
          <div className="mt-4 flex items-center justify-between">
            <h1 className="my-4 font-bold text-neutral-100">
              {intl.formatMessage(InferenceEndpointDetailsMainContentMessages.output)}
            </h1>
            <Button
              variant="green-outlined"
              onClick={handleOnDownload}
              isLoading={isDownloadLoading}
              isDisabled={isDownloadLoading}
            >
              <i className={classNames('fa-solid fa-download')} />
            </Button>
          </div>
          <div className="max-h-svh overflow-y-auto">
            <Markdown markdown={convertInferenceEndpointJsonOutputToMarkdown(inferenceEndpointJobOutput, intl)} />
          </div>
        </>
      )}
    </Card>
  )
}
