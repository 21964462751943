import { defineMessages } from 'react-intl'

export const GeneralErrorAcceptingTeamInvitationContentMessage = defineMessages({
  body: {
    defaultMessage:
      'We were unable to complete your request to accept the invitation to join {organization_display_name}. Try again and if this issue persists {contact_support}.',

    id: 'NiPM3Ypt',
    description: 'The body for the error Team Member Invite Accept notification.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'pIQEUfA+',
    description: 'The text shown for the contact support link.',
  },
  title: {
    defaultMessage: ' Unable to accept team invitation',
    id: 'bYMDzmqO',
    description: 'The title for the error Team Member Invite Accept notification.',
  },
})
