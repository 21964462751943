import type { FunctionComponent } from 'react'
import { contactSupportViaHelpScout } from '../../../utils'

export interface HelpScoutSupportLinkProps {
  /** An optional prop passed down to apply Tailwind classnames to the anchor element. */
  classNames?: string
  /** An optional prop passed down to set an ID attribute for an anchor element. */
  id?: string
  /** The text that will have applied link styles and will open Help Scout when clicked. */
  linkedText: string
}

export const HelpScoutSupportLink: FunctionComponent<HelpScoutSupportLinkProps> = ({ classNames, id, linkedText }) => {
  const handleContactSupportClick = () => {
    contactSupportViaHelpScout()
  }
  return (
    <a
      href="mailto:cloud@salad.com"
      onClick={handleContactSupportClick}
      id={id}
      className={`underline ${classNames ?? ''}`}
    >
      {linkedText}
    </a>
  )
}
