import type { FunctionComponent } from 'react'
import type { Control, FieldValues } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { TextField } from '../../../../../components/TextField'
import { EditHealthProbeComponentMessages } from '../../../messages'
import type { EditContainerGroupValues } from '../../../models'

interface EditHealthProbeServiceInputProps {
  /** The control for the create container group react hook form. */
  control: Control<FieldValues, EditContainerGroupValues>
  /** The error flag for the input */
  hasError: boolean
  /** The disabled flag of the input field */
  isDisabled: boolean
  /** The name given to the controller */
  name: string
}

export const EditHealthProbeServiceInput: FunctionComponent<EditHealthProbeServiceInputProps> = ({
  control,
  hasError,
  isDisabled,
  name,
}) => {
  const intl = useIntl()
  const maxCharacters = 128

  return (
    <div className="mb-10">
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState }) => {
          return (
            <TextField
              {...field}
              {...fieldState}
              defaultValue={field.value}
              helperText={
                hasError &&
                intl.formatMessage(EditHealthProbeComponentMessages.serviceErrorText, {
                  max_characters: maxCharacters,
                })
              }
              isDisabled={isDisabled}
              isFullWidth
              label={intl.formatMessage(EditHealthProbeComponentMessages.serviceLabel)}
            />
          )
        }}
      />
    </div>
  )
}
