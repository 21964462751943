import { defineMessages } from 'react-intl'

export const SuccessfullyLeftTeamContentMessages = defineMessages({
  body: {
    defaultMessage: 'You have left {organization_display_name}',
    id: 'opvpsNPJ',
    description: 'The body for the Team left notification.',
  },
  title: {
    defaultMessage: 'Team left',
    id: 'UHSU38KR',
    description: 'The title for the Team left notification.',
  },
})
