import { CreateContainerGroupField } from './CreateContainerGroupField'
import { CreateContainerGroupValues } from './CreateContainerGroupValues'

/** The default values for the Ubuntu preset container group. */
export const UbuntuPresetValues: Partial<CreateContainerGroupValues> = {
  [CreateContainerGroupField.IMAGE_SOURCE]: 'ghcr.io/saladtechnologies/recipe-base-ubuntu:0.1',
  [CreateContainerGroupField.READINESS_PROBE_COMMAND]: 'cat',
  [CreateContainerGroupField.READINESS_PROBE_COMMAND_ARGUMENTS]: [{ argument: '/healthy' }],
  [CreateContainerGroupField.READINESS_PROBE_ENABLED]: true,
  [CreateContainerGroupField.READINESS_PROBE_FAILURE_THRESHOLD]: 3,
  [CreateContainerGroupField.READINESS_PROBE_HEADERS]: [],
  [CreateContainerGroupField.READINESS_PROBE_INITIAL_DELAY_SECONDS]: 3,
  [CreateContainerGroupField.READINESS_PROBE_PERIOD_SECONDS]: 1,
  [CreateContainerGroupField.READINESS_PROBE_PORT]: 1,
  [CreateContainerGroupField.READINESS_PROBE_PROTOCOL]: 'exec',
  [CreateContainerGroupField.READINESS_PROBE_SUCCESS_THRESHOLD]: 1,
  [CreateContainerGroupField.READINESS_PROBE_TIMEOUT_SECONDS]: 1,
  [CreateContainerGroupField.GPU]: ['f51baccc-dc95-40fb-a5d1-6d0ee0db31d2'],
}
