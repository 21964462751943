import { defineMessages } from 'react-intl'

export const NoProjectsAvailableMainContentMessages = defineMessages({
  createProjectButtonLabel: {
    defaultMessage: 'Create a Project',
    id: 'Ri5083SK',
    description: 'The label for the create project button.',
  },
  description: {
    defaultMessage: 'Seems like your organization has no projects',
    id: 'f4lZoTqY',
    description: 'The main body for the no projects page.',
  },
})
