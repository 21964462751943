import { defineMessages } from 'react-intl'

export const UnableToGetContainerGroupInstanceMessages = defineMessages({
  body: {
    defaultMessage:
      'We were unable to retrieve your container group instance at this time. If this issue persists please {contact_support}.',
    id: 'ayK/xEqB',
    description:
      'The description shown in a toast notification message when we are unable to retrieve container group instance.',
  },
  title: {
    defaultMessage: 'Unable to retrieve container group instance',
    id: 'TeIt7szg',
    description: 'The toast notification title shown when we are unable to retrieve container group instance.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'BOAsXv6C',
    description:
      'The text shown for the contact support link in the unable to retrieve container group instance message.',
  },
})
