import type { RecipeDeployment } from '@saladtechnologies/openapi-cloud-portal-browser'
import type { FunctionComponent } from 'react'
import { LeftNavigationBar } from '../../components/LeftNavigationBar'
import type { ProjectSelectOptions } from '../../components/LeftNavigationBar/models'
import { ThreeColumnLayout } from '../../layouts/threeColumnLayout'
import { FailedToLoadRecipeDeployments } from './components/FailedToLoadRecipeDeployments'
import { RecipeDeploymentsPageMainContent } from './components/RecipeDeploymentsPageMainContent/RecipeDeploymentsPageMainContent'

export interface RecipeDeploymentsPageProps {
  /** The current organization that the user is in. */
  currentOrganization: { name: string; displayName: string }
  /** The current project that the user is in. */
  currentProject: { name: string; displayName: string }
  /** The callback executed when the user clicks the contact support button. */
  isDeleteProjectPending: boolean
  /** The flag indicating that the request to delete the project is successful. */
  isDeleteProjectSuccessful: boolean
  /** The flag indicating if the left column is showing */
  isLeftColumnOpen: boolean
  /** The callback executed when the user closes the left drawer */
  onCloseLeftDrawer: () => void
  /** The callback called when the user deletes the project. */
  onDeleteProject: (projectName: string) => void
  /** The callback executed when the user clicks the Learn More button */
  onLearnMore: () => void
  /**
   * The handler for when something new is selected in the project dropdown. If the current active project is selected,
   * nothing will happen.
   */
  onProjectChange: (projectName: string) => void
  /** The callback executed when the user clicks Try Again button to load recipes deployments */
  onLoadRecipesDeployments: () => void
  /** The display name of the project. */
  projectDisplayName: string
  /** An array of the current available projects. If empty, we will default to no select bar being displayed */
  projects: ProjectSelectOptions
  /** The list of recipe deployments for the current project */
  recipeDeploymentsList: RecipeDeployment[]
  /** The path to the recipe marketplace */
  viewRecipeMarketplacePath: string
  /** Indicates the request to load deployed recipes - failed */
  getDeployedRecipesIsFailed: boolean
}

export const RecipeDeploymentsPage: FunctionComponent<RecipeDeploymentsPageProps> = ({
  currentOrganization,
  currentProject,
  getDeployedRecipesIsFailed,
  isDeleteProjectPending,
  isDeleteProjectSuccessful,
  isLeftColumnOpen,
  onCloseLeftDrawer,
  onDeleteProject,
  onLearnMore,
  onLoadRecipesDeployments,
  onProjectChange,
  projectDisplayName,
  projects,
  recipeDeploymentsList,
  viewRecipeMarketplacePath,
}) => {
  return (
    <ThreeColumnLayout
      isLeftColumnOpen={isLeftColumnOpen}
      LeftColumn={
        <LeftNavigationBar
          currentOrganization={currentOrganization}
          currentProject={currentProject}
          isDeleteProjectPending={isDeleteProjectPending}
          isDeleteProjectSuccessful={isDeleteProjectSuccessful}
          onDeleteProject={onDeleteProject}
          onProjectChange={onProjectChange}
          projects={projects}
        />
      }
      onCloseLeftDrawer={onCloseLeftDrawer}
      MainColumn={
        getDeployedRecipesIsFailed ? (
          <FailedToLoadRecipeDeployments onTryAgain={onLoadRecipesDeployments} />
        ) : (
          <RecipeDeploymentsPageMainContent
            onLearnMore={onLearnMore}
            organizationName={currentOrganization.name}
            projectName={currentProject?.name}
            projectDisplayName={projectDisplayName}
            recipeDeploymentsList={recipeDeploymentsList}
            viewRecipeMarketplacePath={viewRecipeMarketplacePath}
          />
        )
      }
    />
  )
}
