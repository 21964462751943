import { defineMessages } from 'react-intl'

export const DeleteJobQueueConnectionModalMessages = defineMessages({
  cancelButtonLabel: {
    defaultMessage: 'Cancel',
    id: '5akCx9w4',
    description:
      'The label for the Cancel button on the modal that shows when a user wants to delete a job queue connection.',
  },
  description: {
    defaultMessage: 'Delete connection between {container_group_display_name} and {job_queue_display_name}?',
    id: 'Dj0DDRJu',
    description: 'The description for the modal that shows when a user wants to delete a job queue connection.',
  },
  title: {
    defaultMessage: 'Delete Connection',
    id: '3zVZ72l/',
    description: 'The title for the modal that shows when a user wants to delete a job queue connection.',
  },
})
