import type { ReactNode } from 'react'
import type { IntlShape } from 'react-intl'
import { HelpScoutSupportLink } from '../../../components/block/HelpScoutSupportLink'
import type { ToastNotificationType } from '../../models'
import { LoadCreditDashboardGeneralErrorContentMessages } from './messages'

/**
 * Retrieves the content needed for an error `ToastNotification` that is shown when the user encounters a not
 * initialized error when attempting to load their organization's credit dashboard.
 *
 * @param intl The `IntlShape` object needed to format the messages.
 */
export const getLoadCreditDashboardGeneralError = (
  intl: IntlShape,
): {
  body: ReactNode
  type: ToastNotificationType
  title: string
} => {
  return {
    body: intl.formatMessage(LoadCreditDashboardGeneralErrorContentMessages.body, {
      contact_support: (
        <HelpScoutSupportLink
          linkedText={intl.formatMessage(LoadCreditDashboardGeneralErrorContentMessages.contactSupportLinkText)}
          classNames="text-red-70 underline"
        />
      ),
    }),
    type: 'error',
    title: intl.formatMessage(LoadCreditDashboardGeneralErrorContentMessages.title),
  }
}
