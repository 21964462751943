import type { StorageOption } from '@saladtechnologies/openapi-cloud-portal-browser'
import { SvgIcon } from '../../../../components/base'
import type { RadioCardOptions } from '../../../../components/forms/RadioCards/models'
import { HardDriveIcon } from '../../../../components/icons'
import { EditHardwareResourceCardContent } from '../EditHardwareResourceCardContent'

/**
 * Generates an array of Radio Card Options for the disk space input field.
 *
 * @param options The disk space options.
 */
export const generateDiskSpaceOptionCards = (options: StorageOption[]): RadioCardOptions =>
  options.map((option) => ({
    content: (
      <EditHardwareResourceCardContent
        costPerHour={undefined}
        Icon={
          <SvgIcon size={32}>
            <HardDriveIcon />
          </SvgIcon>
        }
        isHighDemand={option.isHighDemand}
        label={option.name}
      />
    ),
    disabled: !option.enabled,
    value: option.value,
  }))

/**
 * Returns whether or not the disk space selected is in high demand.
 *
 * @param options The disk space options.
 * @param diskSpaceSelectedValue The disk space selected value.
 */
export const getDiskSpaceSelectedIsHighDemand = (options: StorageOption[], diskSpaceSelectedValue: number): boolean =>
  options.find((option) => option.value === diskSpaceSelectedValue)?.isHighDemand || false
