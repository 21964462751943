import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import { ObjectSchema, object, string } from 'yup'
import { InferenceEndpointDetailsMainContentMessages } from '../../messages'
import { InferenceEndpointPlaygroundFormValues } from '../../models/InferenceEndpointPlaygroundFormValues'

export const useInferenceEndpointPlaygroundFormValidation = (): ObjectSchema<InferenceEndpointPlaygroundFormValues> => {
  const intl = useIntl()
  const validationSchema = useMemo(
    () =>
      object({
        url: string().required(intl.formatMessage(InferenceEndpointDetailsMainContentMessages.urlRequired)),
      }),
    [intl],
  )
  return validationSchema
}
