import { defineMessages } from 'react-intl'

export const ChangePasswordMainContentMessages = defineMessages({
  changePasswordButtonLabel: {
    defaultMessage: 'Change Password',
    id: 'e2oXBvqT',
    description: 'The button label for the change password form submission button.',
  },
  currentPasswordFieldLabel: {
    defaultMessage: 'Current Password',
    id: 'd84OdwNh',
    description: 'The label for the current password field on the Change Password Page.',
  },
  currentPasswordRequired: {
    defaultMessage: 'Please enter your current password',
    id: 'kvQNHiKf',
    description:
      'The field validation error message shown when a user tries to submit the form without providing a current password.',
  },
  newPasswordFieldLabel: {
    defaultMessage: 'Password',
    id: 'hVS9LwIX',
    description: 'The label for the new password field on the Change Password Page.',
  },
  newPasswordInvalid: {
    defaultMessage:
      'Passwords must be at least 8 characters and contain at least one uppercase letter, one lowercase letter, one non-alphanumeric character, and one digit.',
    id: 'v2+puJbr',
    description:
      'The field validation error message shown when a user tries to submit the form without providing a valid new password.',
  },
  returnToAccountSettingsLinkText: {
    defaultMessage: 'Return to Account Settings',
    id: '5arOKTr6',
    description: 'The text for the link that takes the user back to the Account Settings page.',
  },
  subtitle: {
    defaultMessage: 'Enter a new password for {email}',
    id: 'yNj3c4sa',
    description: 'The subtitle for the Change Password page.',
  },
  title: {
    defaultMessage: 'Change Password',
    id: 'y7vzUeYv',
    description: 'The title for the Change Password page.',
  },
  verifyPasswordDoesNotMatch: {
    defaultMessage: 'The password does not match',
    id: 'o1C6Iibs',
    description:
      'The field validation error message shown when a user tries to submit the form with a verify password does not patch the new password.',
  },
  verifyPasswordFieldLabel: {
    defaultMessage: 'Verify Password',
    id: 'uZxWC8Gi',
    description: 'The label for the verify password field on the Change Password Page.',
  },
})
