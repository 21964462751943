import type { AppState } from '../../store'

export const selectGetProfileErrorMessage = (state: AppState) => state.profile.getProfileErrorMessage

export const selectGetProfileIsPending = (state: AppState) => state.profile.getProfileIsPending

export const selectProfile = (state: AppState) => state.profile.profile

export const selectProfileEmail = (state: AppState) => state.profile.profile?.email

export const selectProfileGravatar = (state: AppState) => state.profile.profile?.gravatarUrl

export const selectProfileUserId = (state: AppState) => state.profile.profile?.id
