import { defineMessages } from 'react-intl'

export const BillingInformationMissingModalMessages = defineMessages({
  cancelButtonLabel: {
    defaultMessage: 'Cancel',
    id: 'ZS+2yiAl',
    description: 'The label for the Cancel button.',
  },
  description: {
    defaultMessage: 'Billing is required to be entered for your organization before you can deploy a recipe.',
    id: 'J0YRT9lZ',
    description: 'The description for the modal that shows when a user needs to enter their billing information.',
  },
  enterBillingInformationButtonLabel: {
    defaultMessage: 'Enter Billing Information',
    id: 'ToYZhvk5',
    description: 'The label for the enter billing information button.',
  },
  title: {
    defaultMessage: 'Billing Information Missing',
    id: 'GoUUYYFe',
    description: 'The title for the modal that shows when a user needs to enter their billing information.',
  },
})
