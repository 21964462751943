import { defineMessages } from 'react-intl'

export const ReadyStatusMessages = defineMessages({
  readyStatusLabel: {
    defaultMessage: 'Ready',
    id: '+aXgbQfO',
    description: 'The label for the Ready status.',
  },
  notReadyStatusLabel: {
    defaultMessage: 'Not Ready',
    id: 'VEBO0sh7',
    description: 'The label for the Not Ready status.',
  },
})
