import type { IntlShape } from 'react-intl'
import type { RadioCardOptions } from '../../../../components/forms/RadioCards/models'
import { CPUIcon } from '../../../../components/icons/CPUIcon'
import CPUCoreOptions from '../../../../utils/CPUCoreOptions.json'
import { EditVCPUInputMessages } from '../../messages'
import { EditHardwareResourceCardContent } from '../EditHardwareResourceCardContent'

/**
 * Generates an array of Radio Card Options for the vCPU input field.
 *
 * @param intl The intl provider.
 */
export const generateVCPUOptionCards = (intl: IntlShape): RadioCardOptions =>
  CPUCoreOptions.options.map((option) => ({
    content: (
      <EditHardwareResourceCardContent
        costPerHour={option.costPerHour}
        Icon={<CPUIcon />}
        label={intl.formatMessage(EditVCPUInputMessages.optionLabel, {
          cores: option.cores,
        })}
      />
    ),
    value: option.cores,
  }))
