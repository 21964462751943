import { defineMessages } from 'react-intl'

export const ButtonMessages = defineMessages({
  countdownButtonLabel: {
    defaultMessage: '({seconds_remaining} Seconds)',
    id: 'QjF34T0x',
    description: 'The label for the countdown button where a timer shows the remaining time the button is disabled.',
  },
  deleteButtonLabel: {
    defaultMessage: 'Delete',
    id: 'n0HIoN4X',
    description: 'The label for the delete button.',
  },
})
