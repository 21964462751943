import type { FunctionComponent } from 'react'
import { useCallback, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { resendRegistrationEmail } from '../../features/registration/registrationSlice'
import { selectRequestStatusIsPending } from '../../features/requestStatus/requestStatusSelectors'
import { selectVerifyAccountResult } from '../../features/verifyAccount/verifyAccountSelectors'
import { confirmRegistration } from '../../features/verifyAccount/verifyAccountSlice'
import { APP_PATHS } from '../../routes/models/AppPaths'
import { useAppDispatch, useAppSelector } from '../../store'
import { VerifyAccount } from './components/VerifyAccount'

export const ConnectedVerifyAccountPage: FunctionComponent = () => {
  const [searchParam] = useSearchParams()
  const token = searchParam.get('token') as string
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const verificationResult = useAppSelector(selectVerifyAccountResult)
  const isResendRegistrationEmailPending = useAppSelector((state) =>
    selectRequestStatusIsPending(state, 'resendRegistrationEmail'),
  )

  const onResendRegistrationEmail = useCallback(
    (email: string) => {
      dispatch(resendRegistrationEmail({ email }))
    },
    [dispatch],
  )

  useEffect(() => {
    dispatch(confirmRegistration({ token }))
  }, [dispatch, token])

  return (
    <VerifyAccount
      verificationResult={verificationResult}
      onViewLoginPage={() => navigate(APP_PATHS.LOGIN)}
      onResendEmailVerificationLink={({ email }) => onResendRegistrationEmail(email)}
      isResendEmailVerificationLinkPending={isResendRegistrationEmailPending}
    />
  )
}
