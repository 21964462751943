import { defineMessages } from 'react-intl'

export const EditContainerGroupUpdateInProgressErrorContentMessages = defineMessages({
  title: {
    defaultMessage: 'Error editing container group',
    id: 'zwz+Ybok',
    description:
      'The toast notification title shown when we encounter an update in progress error on their request to update a container group.',
  },
  body: {
    defaultMessage:
      'We were unable to edit your container group. The container image may still be pulling / preparing. Please wait to make additional updates until the image update has completed.',
    id: 'sYEq3f4D',
    description:
      'The toast notification description shown when we encounter an update in progress error on their request to update a container group.',
  },
})
