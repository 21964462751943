import type { FunctionComponent } from 'react'
import { useState } from 'react'
import type { Control, FieldValues, UseFormClearErrors, UseFormTrigger } from 'react-hook-form'
import { useFormState, useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { FormStateCard } from '../../../../components/forms/FormStateCard'
import { KeyValuePairForm } from '../../../../components/forms/KeyValuePairForm'
import { EditContainerGroupMainContentMessages, EditEnvironmentVariablesFieldsMessages } from '../../messages'
import type { EditContainerGroupFormSectionIdAttributes, EditContainerGroupValues } from '../../models'
import { EditContainerGroupField } from '../../models'

interface EditEnvironmentVariablesFieldsProps {
  /** The react hook form method that clears errors for specified fields. */
  clearErrors: UseFormClearErrors<FieldValues>
  /** The control for the create container group react hook form. */
  control: Control<FieldValues, EditContainerGroupValues>
  /** The id attribute for the fields. */
  id: EditContainerGroupFormSectionIdAttributes
  /** The react hook form method that triggers validation for specified fields. */
  trigger: UseFormTrigger<FieldValues>
}

export const EditEnvironmentVariablesFields: FunctionComponent<EditEnvironmentVariablesFieldsProps> = ({
  clearErrors,
  control,
  id,
  trigger,
}) => {
  const intl = useIntl()
  const [isSidePanelOpen, setSidePanelOpenState] = useState<boolean>(false)
  const { errors } = useFormState({ control })

  const environmentVariables = useWatch({ control, name: EditContainerGroupField.ENVIRONMENT_VARIABLES })
  const environmentVariablesSet =
    environmentVariables && environmentVariables.length > 0 && environmentVariables[0].key !== ''
  const environmentVariablesFieldsHaveErrors = errors[EditContainerGroupField.ENVIRONMENT_VARIABLES] !== undefined

  return (
    <div className="mb-10" id={id}>
      <FormStateCard
        hasError={environmentVariablesFieldsHaveErrors}
        onEditForm={() => setSidePanelOpenState(true)}
        title={intl.formatMessage(EditEnvironmentVariablesFieldsMessages.environmentVariablesFieldsLabel)}
      >
        {environmentVariablesSet ? (
          <p className="cursor-pointer text-blue-90 underline" onClick={() => setSidePanelOpenState(true)}>
            {intl.formatMessage(EditEnvironmentVariablesFieldsMessages.viewEnvironmentVariablesLinkText, {
              count: environmentVariables.length,
            })}
          </p>
        ) : (
          <p>{intl.formatMessage(EditEnvironmentVariablesFieldsMessages.environmentVariablesNotSetLabel)}</p>
        )}
      </FormStateCard>
      <KeyValuePairForm
        addButtonLabel={intl.formatMessage(EditEnvironmentVariablesFieldsMessages.addEnvironmentVariableButtonLabel)}
        clearErrors={clearErrors}
        control={control}
        controllerName={EditContainerGroupField.ENVIRONMENT_VARIABLES}
        formId="updateEnvironmentVariablesFieldsForm"
        isSidePanelOpen={isSidePanelOpen}
        keyLabel={intl.formatMessage(EditEnvironmentVariablesFieldsMessages.keyLabel)}
        keyValuePairValues={environmentVariables}
        onSetIsSidePanelOpen={setSidePanelOpenState}
        submitButtonLabel={intl.formatMessage(EditContainerGroupMainContentMessages.configureButtonLabel)}
        subtitle={intl.formatMessage(EditEnvironmentVariablesFieldsMessages.subtitle, {
          learn_more_link_text: (
            <a
              href="https://docs.salad.com/container-engine/environment-variables "
              rel="noreferrer"
              target="_blank"
              className="text-blue-90 underline"
            >
              {intl.formatMessage(EditEnvironmentVariablesFieldsMessages.learMoreLinkText)}
            </a>
          ),
        })}
        title={intl.formatMessage(EditEnvironmentVariablesFieldsMessages.environmentVariablesFieldsLabel)}
        trigger={trigger}
        valueLabel={intl.formatMessage(EditEnvironmentVariablesFieldsMessages.valueLabel)}
      />
    </div>
  )
}
