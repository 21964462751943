import { defineMessages } from 'react-intl'

export const EditLivenessProbeFieldsMessages = defineMessages({
  disabledLabel: {
    defaultMessage: 'Disabled',
    id: 'JS7hX2iC',
    description: 'The disabled label for the liveness probe.',
  },
  title: {
    defaultMessage: 'Liveness Probe',
    id: 'POZmPbZc',
    description: 'The title for the liveness probe.',
  },
})
