import type { FunctionComponent } from 'react'
import { forwardRef, useState } from 'react'
import type { Control, FieldValues, UseFormResetField, UseFormTrigger } from 'react-hook-form'
import { Controller, useFormState, useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Button } from '../../../../components/Button'
import { InputLabel } from '../../../../components/InputLabel'
import { TextField } from '../../../../components/TextField'
import { Link } from '../../../../components/base'
import { SidePanelModal } from '../../../../components/block/SidePanelModal'
import { FormStateCard } from '../../../../components/forms/FormStateCard'
import { RadioTabs } from '../../../../components/forms/RadioTabs'
import { Select } from '../../../../components/forms/Select'
import {
  CreateContainerGroupMainContentMessages,
  ImageSourceInputMessages,
  ImageTypeInputMessages,
  SelectPrivateRegistryProviderMessages,
} from '../../messages'
import type { CreateContainerGroupFormSectionIdAttributes, CreateContainerGroupValues } from '../../models'
import { CreateContainerGroupField, ImageType, PrivateRegistryProvider } from '../../models'
import { AWSElasticInputFields } from './components/AWSElasticInputFields'
import { AzureInputFields } from './components/AzureInputFields'
import { DockerHubInputFields } from './components/DockerHubInputFields'
import { GitHubInputFields } from './components/GitHubInputFields'
import { GoogleArtifactRegistryInputFields, GoogleContainerRegistryInputFields } from './components/GoogleInputFields'
import { QuayInputFields } from './components/QuayInputFields'
import { SelfHostedInputFields } from './components/SelfHostedInputFields'
import { checkIfImageSourceFieldsHaveErrors, imageSourceFieldsList, privateRegistryFieldsList } from './utils'
const emptyValue = '-'

interface ImageSourceFieldsProps {
  /** The control for the create container group react hook form. */
  control: Control<FieldValues, CreateContainerGroupValues>
  /** The id attribute for the fields. */
  id: CreateContainerGroupFormSectionIdAttributes
  /** The react hook form method that provides the ability to reset a specified field value. */
  resetField: UseFormResetField<FieldValues>
  /** The react hook form method that triggers validation for specified fields. */
  trigger: UseFormTrigger<FieldValues>
}

export const ImageSourceFields: FunctionComponent<ImageSourceFieldsProps> = forwardRef<
  HTMLDivElement,
  ImageSourceFieldsProps
>(({ control, id, resetField, trigger }, ref) => {
  const [isSidePanelOpen, setSidePanelOpenState] = useState<boolean>(false)
  const [localPrivateRegistryProvider, setPrivateRegistryProvider] = useState<string | undefined>(undefined)
  const { errors } = useFormState({ control })
  const imageSource = useWatch({ control, name: CreateContainerGroupField.IMAGE_SOURCE })
  const imageType = useWatch({ control, name: CreateContainerGroupField.IMAGE_TYPE, defaultValue: ImageType.PUBLIC })
  const imageSourceFieldsHaveError = checkIfImageSourceFieldsHaveErrors(errors)
  const privateRegistryProvider = useWatch({ control, name: CreateContainerGroupField.PRIVATE_REGISTRY_PROVIDER })
  const intl = useIntl()

  const handleValidateFieldsBeforeClose = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    await trigger(imageSourceFieldsList).then((isValid) => {
      if (isValid) {
        setSidePanelOpenState(false)
      }
    })
  }

  return (
    <div className="mb-10" id={id} ref={ref}>
      <FormStateCard
        hasError={imageSourceFieldsHaveError}
        onEditForm={() => setSidePanelOpenState(true)}
        title={intl.formatMessage(CreateContainerGroupMainContentMessages.imageSourceSectionHeader)}
      >
        <InputLabel
          label={intl.formatMessage(ImageSourceInputMessages.imageSourceLabel)}
          hasError={imageSourceFieldsHaveError}
        />
        <p className="truncate">{imageSource || emptyValue}</p>
      </FormStateCard>

      <SidePanelModal
        CustomButton={
          <Button variant="green-filled" isFullWidth type="submit" form="updateImageSourceFieldsForm">
            {intl.formatMessage(CreateContainerGroupMainContentMessages.configureButtonLabel)}
          </Button>
        }
        isShown={isSidePanelOpen}
        onClose={() => setSidePanelOpenState(false)}
        title={intl.formatMessage(ImageSourceInputMessages.imageSourceSidePanelTitle)}
      >
        <div className="mt-12 px-10">
          <div className="mb-10">
            <h2 className="mb-2 text-3xl font-bold">
              {intl.formatMessage(ImageSourceInputMessages.imageSourceSidePanelTitle)}
            </h2>
            <p>
              {intl.formatMessage(ImageSourceInputMessages.dockerManifestHelperText, {
                learn_more_here_link: (
                  <Link url="https://docs.salad.com/container-engine/container-registries#specifying-the-container-image-source">
                    {intl.formatMessage(ImageSourceInputMessages.dockerManifestHelperTextLinkText)}
                  </Link>
                ),
              })}
            </p>
          </div>

          <form onSubmit={handleValidateFieldsBeforeClose} id="updateImageSourceFieldsForm">
            <Controller
              name={CreateContainerGroupField.IMAGE_SOURCE}
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    {...field}
                    {...fieldState}
                    helperText={
                      fieldState.error?.message ??
                      intl.formatMessage(ImageSourceInputMessages.imageSourceHelperText, {
                        learn_more: (
                          <Link url="https://docs.salad.com/container-engine/container-registries#specifying-the-container-image-source">
                            {intl.formatMessage(ImageSourceInputMessages.imageSourceHelperLearnMoreLinkText)}
                          </Link>
                        ),
                      })
                    }
                    label={intl.formatMessage(ImageSourceInputMessages.imageSourceLabel)}
                    isFullWidth
                  />
                )
              }}
            />

            <Controller
              name={CreateContainerGroupField.IMAGE_TYPE}
              control={control}
              defaultValue={ImageType.PUBLIC}
              render={({ field, fieldState }) => {
                return (
                  <RadioTabs
                    {...field}
                    {...fieldState}
                    onChange={(value) => {
                      if (value === ImageType.PUBLIC) {
                        resetField(CreateContainerGroupField.PRIVATE_REGISTRY_PROVIDER)
                        privateRegistryFieldsList.forEach((field) => {
                          resetField(field)
                        })
                        // This is needed to trigger the UI updating for the private registry provider field
                        setPrivateRegistryProvider(
                          intl.formatMessage(SelectPrivateRegistryProviderMessages.placeholderText),
                        )
                      }
                      field.onChange(value)
                    }}
                    tabs={[
                      {
                        label: intl.formatMessage(ImageTypeInputMessages.publicRegistrylabel),
                        value: ImageType.PUBLIC,
                      },
                      {
                        label: intl.formatMessage(ImageTypeInputMessages.privateRegistrylabel),
                        value: ImageType.PRIVATE,
                      },
                    ]}
                    label={intl.formatMessage(ImageTypeInputMessages.label)}
                  />
                )
              }}
            />

            <div className="mt-16">
              <div className="mb-16">
                <Controller
                  name={CreateContainerGroupField.PRIVATE_REGISTRY_PROVIDER}
                  control={control}
                  render={({ field, fieldState }) => {
                    return (
                      <Select
                        {...field}
                        {...fieldState}
                        defaultSelectedValue={localPrivateRegistryProvider}
                        onChange={(value) => {
                          field.onChange(value)
                          // This is needed to trigger the UI updating for the private registry provider field
                          setPrivateRegistryProvider(value)
                        }}
                        labelText={intl.formatMessage(SelectPrivateRegistryProviderMessages.label)}
                        options={[
                          {
                            label: intl.formatMessage(SelectPrivateRegistryProviderMessages.awsElasticLabel),
                            value: PrivateRegistryProvider.AWS_ECR,
                          },
                          {
                            label: intl.formatMessage(SelectPrivateRegistryProviderMessages.azureLabel),
                            value: PrivateRegistryProvider.AZURE_CONTAINER,
                          },
                          {
                            label: intl.formatMessage(SelectPrivateRegistryProviderMessages.dockerHubLabel),
                            value: PrivateRegistryProvider.DOCKER_HUB,
                          },
                          {
                            label: intl.formatMessage(SelectPrivateRegistryProviderMessages.gitHubLabel),
                            value: PrivateRegistryProvider.GITHUB_CONTAINER,
                          },
                          {
                            label: intl.formatMessage(
                              SelectPrivateRegistryProviderMessages.googleArtifactRegistryLabel,
                            ),
                            value: PrivateRegistryProvider.GOOGLE_ARTIFACT_REGISTRY,
                          },
                          {
                            label: intl.formatMessage(
                              SelectPrivateRegistryProviderMessages.googleContainerRegistryLabel,
                            ),
                            value: PrivateRegistryProvider.GOOGLE_CONTAINER_REGISTRY,
                          },
                          {
                            label: intl.formatMessage(SelectPrivateRegistryProviderMessages.selfHostedLabel),
                            value: PrivateRegistryProvider.SELF_HOSTED,
                          },
                          {
                            label: intl.formatMessage(SelectPrivateRegistryProviderMessages.quayLabel),
                            value: PrivateRegistryProvider.QUAY_CONTAINER,
                          },
                        ]}
                        placeholderText={intl.formatMessage(SelectPrivateRegistryProviderMessages.placeholderText)}
                        isDisabled={imageType === ImageType.PUBLIC}
                        isFullWidth
                      />
                    )
                  }}
                />
              </div>

              {privateRegistryProvider === PrivateRegistryProvider.AWS_ECR && (
                <AWSElasticInputFields control={control} />
              )}

              {privateRegistryProvider === PrivateRegistryProvider.AZURE_CONTAINER && (
                <AzureInputFields control={control} />
              )}

              {privateRegistryProvider === PrivateRegistryProvider.DOCKER_HUB && (
                <DockerHubInputFields control={control} />
              )}

              {privateRegistryProvider === PrivateRegistryProvider.GITHUB_CONTAINER && (
                <GitHubInputFields control={control} />
              )}

              {privateRegistryProvider === PrivateRegistryProvider.GOOGLE_ARTIFACT_REGISTRY && (
                <GoogleArtifactRegistryInputFields control={control} />
              )}

              {privateRegistryProvider === PrivateRegistryProvider.GOOGLE_CONTAINER_REGISTRY && (
                <GoogleContainerRegistryInputFields control={control} />
              )}

              {privateRegistryProvider === PrivateRegistryProvider.QUAY_CONTAINER && (
                <QuayInputFields control={control} />
              )}

              {privateRegistryProvider === PrivateRegistryProvider.SELF_HOSTED && (
                <SelfHostedInputFields control={control} />
              )}
            </div>
          </form>
        </div>
      </SidePanelModal>
    </div>
  )
})
