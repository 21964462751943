import { catchError, concat, delay, filter, forkJoin, from, mergeMap, of, switchMap } from 'rxjs'
import { OrganizationsAPI, ProjectsAPI, QueuesAPI } from '../apiMethods'
import { editJobQueue, getEditJobQueuePageData, jobQueueAdded } from '../features/jobQueues/jobQueuesSlice'
import { showToastNotification } from '../features/notifications/notificationsSlice'
import { organizationAdded } from '../features/organizations/organizationsSlice'
import { projectsAddedToOrganization } from '../features/projects/projectsSlice'
import { setRequestStatus } from '../features/requestStatus/requestStatusSlice'
import {
  getEditJobQueueErrorContent,
  getEditJobQueuePageDataErrorContent,
} from '../notifications/clientToastNotificationContent/jobQueues'
import { getJobQueueDetailsPagePath, getJobQueuesPagePath } from '../routes/routes-utils'
import type { AppEpic } from '../store'
import { navigateTo } from './navigationEpic'

export const onEditJobQueue: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(editJobQueue.match),
    mergeMap(({ payload: { organizationName, projectName, queueName, updatedQueue } }) =>
      concat(
        of(setRequestStatus({ request: 'editJobQueue', status: 'pending' })),
        from(
          QueuesAPI.updateQueue({
            organizationName,
            projectName,
            queueName,
            updateQueue: updatedQueue,
          }),
        ).pipe(
          mergeMap(() => {
            return concat(
              of(
                setRequestStatus({ request: 'editJobQueue', status: 'succeeded' }),
                navigateTo({ path: getJobQueueDetailsPagePath(organizationName, projectName, queueName) }),
              ),
              of(setRequestStatus({ request: 'editJobQueue', status: 'idle' })).pipe(delay(1)),
            )
          }),
          catchError(() =>
            concat(
              of(
                setRequestStatus({ request: 'editJobQueue', status: 'failed' }),
                showToastNotification(getEditJobQueueErrorContent(intl)),
              ),
              of(setRequestStatus({ request: 'editJobQueue', status: 'idle' })).pipe(delay(1)),
            ),
          ),
        ),
      ),
    ),
  )

export const onGetEditJobQueuePageData: AppEpic = (action$, _state$, { intl }) => {
  return action$.pipe(
    filter(getEditJobQueuePageData.match),
    switchMap(({ payload: { jobQueueName, organizationName, projectName } }) =>
      concat(
        of(setRequestStatus({ request: 'getEditJobQueuePageData', status: 'pending' })),
        forkJoin([
          QueuesAPI.getQueue({
            organizationName,
            projectName,
            queueName: jobQueueName,
          }),
          OrganizationsAPI.getOrganization({
            organizationName,
          }),
          ProjectsAPI.listProjects({
            organizationName,
          }),
        ]).pipe(
          switchMap(([jobQueueResponse, organizationResponse, projectsResponse]) => {
            return concat(
              of(
                jobQueueAdded({
                  organizationName,
                  projectName,
                  jobQueue: jobQueueResponse,
                }),
                organizationAdded(organizationResponse),
                projectsAddedToOrganization({
                  organizationName: organizationResponse.name,
                  projects: projectsResponse.items,
                }),
                setRequestStatus({ request: 'getEditJobQueuePageData', status: 'succeeded' }),
              ),
              of(setRequestStatus({ request: 'getEditJobQueuePageData', status: 'idle' })).pipe(delay(1)),
            )
          }),
          catchError(() =>
            concat(
              of(
                setRequestStatus({ request: 'getEditJobQueuePageData', status: 'failed' }),
                showToastNotification(getEditJobQueuePageDataErrorContent(intl)),
                navigateTo({ path: getJobQueuesPagePath(organizationName, projectName) }),
              ),
              of(setRequestStatus({ request: 'getEditJobQueuePageData', status: 'idle' })).pipe(delay(1)),
            ),
          ),
        ),
      ),
    ),
  )
}
