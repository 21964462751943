import { defineMessages } from 'react-intl'

export const CreateContainerGroupInvalidStorageOptionErrorContentMessages = defineMessages({
  body: {
    defaultMessage: 'You have provided an invalid storage option.',
    id: '2IFqvXFa',
    description:
      'The description shown in a toast notification message shown when we encounter an error on their request to create a container group with an invalid storage option',
  },
  title: {
    defaultMessage: 'Storage Option Invalid',
    id: '+atp/2M7',
    description:
      'The toast notification title shown when we encounter an error on their request to create a container group with an invalid storage option.',
  },
})
