import { defineMessages } from 'react-intl'

export const HTTPInputFieldsMessages = defineMessages({
  addHeaderButtonLabel: {
    defaultMessage: 'Add Header',
    id: '1JzcM9+2',
    description: 'The button label for the HTTP add Header button.',
  },
  addHeadersTitle: {
    defaultMessage: 'Add Headers',
    id: '1EtCurkh',
    description: 'The title for the HTTP add Headers modal.',
  },
  backButtonLabel: {
    defaultMessage: 'Back',
    id: 'oAd8GGJz',
    description: 'The button label for the HTTP Headers back button.',
  },
  compressionLabel: {
    defaultMessage: 'Compression',
    id: '8eHs2Xaj',
    description: 'The label for the HTTP compression input field.',
  },
  compressionGzipOptionLabel: {
    defaultMessage: 'gzip',
    id: 'dAtzTZPW',
    description: 'The label for the HTTP gzip compression option input field.',
  },
  compressionNoneOptionLabel: {
    defaultMessage: 'none',
    id: 'CSQ2cVTk',
    description: 'The label for the HTTP none compression option input field.',
  },
  configureButtonLabel: {
    defaultMessage: 'Configure',
    id: 'Alp6JWXt',
    description: 'The button label for the HTTP Headers configure button.',
  },
  editHeadersLabel: {
    defaultMessage: 'Edit',
    id: 'hwH6qBss',
    description: 'The text for the HTTP edit Headers label.',
  },
  formatLabel: {
    defaultMessage: 'Format',
    id: 'qWfea6Go',
    description: 'The label for the HTTP format input field.',
  },
  headerInputsLabel: {
    defaultMessage: 'Header {count}',
    id: 'ycHAlArh',
    description: 'The label for the Headers key value input card.',
  },
  headersLabel: {
    defaultMessage: 'Headers',
    id: '5PoJanc6',
    description: 'The label for the HTTP Headers input field.',
  },
  headersOptionalLabel: {
    defaultMessage: 'Headers (Optional)',
    id: '3HcuWB8S',
    description: 'The label for the HTTP Headers input field.',
  },
  hostLabel: {
    defaultMessage: 'Host',
    id: 'LpF+ZN/a',
    description: 'The label for the HTTP host input field.',
  },
  hostRequiredText: {
    defaultMessage: 'Host is a required field',
    id: 'WMIKUwL+',
    description: 'The error text shown for the headers fields when the host field is not completed.',
  },
  hostValidationText: {
    defaultMessage: 'Cannot begin with http:// or https://',
    id: 'AO7F4MqH',
    description: 'The validation text for the host input field.',
  },
  httpHeadersNotSetLabel: {
    defaultMessage: 'No HTTP Headers set',
    id: 'W75gvKwR',
    description: 'The label for the HTTP Headers fields when it is not set.',
  },
  jsonFormatOptionLabel: {
    defaultMessage: 'json',
    id: 'sNAwuPdT',
    description: 'The label for the HTTP json format option input field.',
  },
  jsonLinesFormatOptionLabel: {
    defaultMessage: 'json lines',
    id: '0b63eMH8',
    description: 'The label for the HTTP json lines format option input field.',
  },
  maxCharacterErrorText: {
    defaultMessage: 'Name and value must not be longer than 128 characters',
    id: 'tuw1hP1L',
    description: 'The error text shown for the headers fields when the max character limit is exceeded.',
  },
  nameLabel: {
    defaultMessage: 'Name',
    id: 'e06Xkpk5',
    description: 'The label for the HTTP name input field.',
  },
  nameRequiredErrorText: {
    defaultMessage: 'Name is required',
    id: 'rnQp9AX/',
    description: 'The error text shown for the headers fields when the name field is not completed.',
  },
  passwordLabel: {
    defaultMessage: 'Password',
    id: 'oVtUa5u0',
    description: 'The label for the HTTP password input field.',
  },
  passwordOptionalLabel: {
    defaultMessage: 'Password (Optional)',
    id: 'w6ToVCmQ',
    description: 'The label for the HTTP password input field.',
  },
  passwordRequiredText: {
    defaultMessage: 'Password is required when User is provided',
    id: 'cFr5M2NY',
    description: 'The error text shown for the headers fields when the password field is not completed.',
  },
  pathHelperText: {
    defaultMessage: 'Must be a valid path',
    id: '9wHVNG0p',
    description: 'The helper text for the HTTP path input field.',
  },
  pathLabel: {
    defaultMessage: 'Path',
    id: 'fjKr6b59',
    description: 'The label for the HTTP path input field.',
  },
  pathOptionalLabel: {
    defaultMessage: 'Path (Optional)',
    id: 'VSKXdBPe',
    description: 'The label for the HTTP path input field.',
  },
  portHelperText: {
    defaultMessage: 'Must be a number between 1 to 65535',
    id: 'bcpn+wfu',
    description: 'The helper text for the HTTP port input field.',
  },
  portLabel: {
    defaultMessage: 'Port',
    id: 'pYwOB+nc',
    description: 'The label for the HTTP port input field.',
  },
  removeHeaderButtonLabel: {
    defaultMessage: 'Remove Header',
    id: '6FI/uvd1',
    description: 'The button label for the HTTP remove Header button.',
  },
  userLabel: {
    defaultMessage: 'User',
    id: 'zfewTorx',
    description: 'The label for the HTTP user input field.',
  },
  userOptionalLabel: {
    defaultMessage: 'User (Optional)',
    id: 'cQp+ji7C',
    description: 'The label for the HTTP user input field.',
  },
  userRequiredText: {
    defaultMessage: 'User is required when Password is provided',
    id: '6CDNVd/L',
    description: 'The error text shown for the headers fields when the user field is not completed.',
  },
  valueLabel: {
    defaultMessage: 'Value',
    id: 'uzBlzRvH',
    description: 'The label for the HTTP value input field.',
  },
  valueRequiredErrorText: {
    defaultMessage: 'Value is required',
    id: '7Joj41g5',
    description: 'The error text shown for the headers fields when the value field is not completed.',
  },
  viewHTTPHeadersLinkText: {
    defaultMessage: 'View {count, plural, one {# HTTP Header} other {# HTTP Headers}} ',
    id: 'f/OZAcis',
    description: 'The text for the link that opens the HTTP Headers side panel.',
  },
})
