import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { Tag } from '../../../../Tag'
import { ReadyStatusMessages } from './messages'

interface ReadyStatusProps {
  /** Indicates if the deployment instance is ready */
  isReady: boolean
}

export const ReadyStatus: FunctionComponent<ReadyStatusProps> = ({ isReady }) => {
  const intl = useIntl()
  return (
    <>
      {isReady ? (
        <Tag color="green">
          <span className={classNames('fa-solid fa-play mr-2 font-bold')}></span>
          <span className="font-bold uppercase">{intl.formatMessage(ReadyStatusMessages.readyStatusLabel)}</span>
        </Tag>
      ) : (
        <Tag color="red">
          <span className={classNames('fa-solid fa-x mr-2 font-bold')}></span>
          <span className="gap-2 font-bold uppercase">
            {intl.formatMessage(ReadyStatusMessages.notReadyStatusLabel)}
          </span>
        </Tag>
      )}
    </>
  )
}
