import { defineMessages } from 'react-intl'

export const SuccessfullyAcceptedTeamInvitationContentMessage = defineMessages({
  body: {
    defaultMessage: 'You have successfully joined {organization_display_name}',
    id: 'uAvZz0XK',
    description: 'The body for the accepted team member invitation notification.',
  },
  title: {
    defaultMessage: 'Invitation Accepted',
    id: 'tQwSiIYV',
    description: 'The title for the accepted team member invitation notification.',
  },
})
