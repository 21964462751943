import { defineMessages } from 'react-intl'

export const RecipeDetailsNotAvailableErrorContentMessages = defineMessages({
  body: {
    defaultMessage: 'The recipe you are trying to view is no longer available.',
    id: 'ccnYAzyW',
    description:
      'The description shown in a toast notification message shown when we encounter an error on their request to get recipe details.',
  },
  title: {
    defaultMessage: 'Recipe does not exist',
    id: 'JE5/Qs/5',
    description:
      'The toast notification title shown when we encounter an error on their request to to get recipe details.',
  },
})
