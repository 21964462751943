import { defineMessages } from 'react-intl'

export const EditVCPUInputMessages = defineMessages({
  helperText: {
    defaultMessage: "Additional options available through Salad's API",
    id: 'vCGeKkzW',
    description: 'The helper text for the container group vCPU input field.',
  },
  label: {
    defaultMessage: 'How many vCPUs would you like to allocate?',
    id: 'MZLST/O9',
    description: 'The label for the container group vCPU input field.',
  },
  optionLabel: {
    defaultMessage: '{cores, plural, one {# vCPU} other {# vCPUs}}',
    id: 'lexSB0my',
    description: 'The option label for the container group vCPU input field.',
  },
})
