import { defineMessages } from 'react-intl'

export const VerificationSuccessMessages = defineMessages({
  description: {
    defaultMessage: 'Ready to run on Salad’s network?',
    id: 'n1eZxWdQ',
    description: 'The description of verification success dialog',
  },
  loginButtonLabel: {
    defaultMessage: 'Log In',
    id: '/UjEQinW',
    description: 'The "Log In" button text',
  },
  title: {
    defaultMessage: 'You’ve been verified!',
    id: '7H39ui6y',
    description: 'The title of verification success dialog',
  },
})
