import { createSelector } from '@reduxjs/toolkit'
import { AppState } from '../../store'
import { inferenceEndpointJobsAdapter } from './inferenceEndpointJobsSlice'
import { findLatestInferenceEndpointJob } from './utils'

const inferenceEndpointJobsSelectors = inferenceEndpointJobsAdapter.getSelectors(
  (state: AppState) => state.inferenceEndpointJobs,
)

export const selectInferenceEndpointJobs = createSelector(
  inferenceEndpointJobsSelectors.selectById,
  (inferenceEndpointJobs) => {
    return inferenceEndpointJobs?.inferenceEndpointJobs ?? []
  },
)

export const selectLatestInferenceEndpointJob = createSelector(inferenceEndpointJobsSelectors.selectById, (entity) => {
  const inferenceEndpointJobs = entity?.inferenceEndpointJobs ?? []
  return findLatestInferenceEndpointJob(inferenceEndpointJobs)
})
