import type { FunctionComponent, SVGProps } from 'react'

export const HardDriveIcon: FunctionComponent<SVGProps<SVGSVGElement>> = (props) => (
  <svg width={props.width} height={props.height} fill="#546470" viewBox="0 0 24 24" {...props}>
    <path
      d="M0 3.5C0 1.85938 1.3125 0.5 3 0.5H21C22.6406 0.5 24 1.85938 24 3.5V12.1719C23.2031 11.4688 22.125 11 21 11H3C1.82812 11 0.796875 11.4688 0 12.1719V3.5ZM3 12.5H21C22.6406 12.5 24 13.8594 24 15.5V18.5C24 20.1875 22.6406 21.5 21 21.5H3C1.3125 21.5 0 20.1875 0 18.5V15.5C0 13.8594 1.3125 12.5 3 12.5ZM15 18.5C15.7969 18.5 16.5 17.8438 16.5 17C16.5 16.2031 15.7969 15.5 15 15.5C14.1562 15.5 13.5 16.2031 13.5 17C13.5 17.8438 14.1562 18.5 15 18.5ZM21 17C21 16.2031 20.2969 15.5 19.5 15.5C18.6562 15.5 18 16.2031 18 17C18 17.8438 18.6562 18.5 19.5 18.5C20.2969 18.5 21 17.8438 21 17Z"
      fill="#546470"
    />
  </svg>
)
