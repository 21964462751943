import type { ContainerGroupState, RecipeNetworking } from '@saladtechnologies/openapi-cloud-portal-browser'
import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { Tag } from '../../../../components/Tag'
import { DeploymentStatusTag } from '../../../../components/block/DeploymentStatusTag'
import { DeployedRecipeCardMessages } from '../../messages'

interface DeployedRecipeCardProps {
  /** The container groups card current state. */
  currentState: ContainerGroupState
  /** The optional field for Container Gateway details. This also includes the auth flag */
  networking?: RecipeNetworking | null
  /** The path to the deployed recipe details. */
  path: string
  /** The display name of the deployed recipe */
  recipeDisplayName: string
  /** The original recipe name of the deployed recipe */
  recipeName: string
  /** The number of replicas of the deployed recipe */
  replicas: number
}

export const DeployedRecipeCard: FunctionComponent<DeployedRecipeCardProps> = ({
  currentState,
  networking,
  path,
  recipeDisplayName,
  recipeName,
  replicas,
}) => {
  const intl = useIntl()

  const { instanceStatusCounts, status: currentStatus } = currentState
  const { runningCount } = instanceStatusCounts

  return (
    <Link
      className="flex w-full flex-col rounded-lg px-6 py-4 shadow-md hover:shadow-green-30 focus-visible:shadow-green-30 focus-visible:outline-none"
      to={path}
    >
      <p className="mb-2 font-bold">{recipeDisplayName}</p>
      <p className="mb-2 mr-2 text-xs text-neutral-70">
        {intl.formatMessage(DeployedRecipeCardMessages.recipeSource, {
          recipe_name: recipeName,
        })}
      </p>
      <div className="flex flex-wrap gap-1">
        <DeploymentStatusTag status={currentStatus} />
        <Tag color="gray">
          <FormattedMessage
            defaultMessage="{replicas_running, number} / {replicas} {replicas_running, plural, one {Replica} other {Replicas}} Running"
            id="siDmXAvH"
            description="The label for the replicas."
            values={{
              replicas: replicas,
              replicas_running: runningCount,
            }}
            tagName="span"
          />
        </Tag>
        {networking !== undefined && networking !== null && (
          <Tag color="white">
            <i className={classNames('mr-2', networking.auth ? 'fa-solid fa-lock' : 'fa-solid fa-unlock')} />
            <span>
              {networking.auth
                ? intl.formatMessage(DeployedRecipeCardMessages.authRequired)
                : intl.formatMessage(DeployedRecipeCardMessages.containerGatewayEnabled)}
            </span>
          </Tag>
        )}
      </div>
    </Link>
  )
}
