import type { FunctionComponent } from 'react'
import { LeftNavigationBar } from '../../components/LeftNavigationBar'
import { ThreeColumnLayout } from '../../layouts/threeColumnLayout'
import { NoProjectsAvailableMainContent } from './components'

export interface NoProjectsAvailablePageProps {
  /** The current organization that the user is in. */
  currentOrganization: { name: string; displayName: string }
  /** The flag indicating if the left column is showing */
  isLeftColumnOpen: boolean
  /** The callback executed when the user closes the left drawer */
  onCloseLeftDrawer: () => void
}

export const NoProjectsAvailablePage: FunctionComponent<NoProjectsAvailablePageProps> = ({
  currentOrganization,
  isLeftColumnOpen,
  onCloseLeftDrawer,
}) => {
  return (
    <ThreeColumnLayout
      isLeftColumnOpen={isLeftColumnOpen}
      LeftColumn={<LeftNavigationBar currentOrganization={currentOrganization} />}
      MainColumn={<NoProjectsAvailableMainContent organizationName={currentOrganization.name} />}
      onCloseLeftDrawer={onCloseLeftDrawer}
    />
  )
}
