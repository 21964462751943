import { defineMessages } from 'react-intl'

export const RecipeDeploymentDetailsMainContentMessages = defineMessages({
  accessDomainNameHelperText: {
    defaultMessage: 'Your deployed containers will be accessible through this domain name.',
    id: 'AC3SKyNP',
    description: 'The helper text for the access domain field on the recipe deployment details page.',
  },
  accessDomainNameLabel: {
    defaultMessage: 'Access Domain Name',
    id: 'Lh/AYEzm',
    description:
      'The label for the access domain field if authentication is required on the recipe deployment details page.',
  },
  accessDomainNameProtectedLabel: {
    defaultMessage: 'Access Domain Name (Protected)',
    id: 'qTYw134Y',
    description: 'The label for the access domain protected field on the recipe deployment details page.',
  },
  accessDomainNameReadMoreText: {
    defaultMessage: 'read more here',
    id: 'S/rUiMcD',
    description: 'The read more text for the access domain field on the recipe deployment details page.',
  },
  authenticationRequired: {
    defaultMessage: 'Authentication Required',
    id: 'WNwnv2Hv',
    description: 'The label for the authentication required.',
  },
  backToRecipeDeploymentsLabel: {
    defaultMessage: 'Back to Recipe Deployments',
    id: '24Oz6dIO',
    description: 'The label for the back button on the recipe deployment details page.',
  },
  deleteButtonLabel: {
    defaultMessage: 'Delete',
    id: '6CWLgLk4',
    description: 'The label for the delete button on the recipe deployment details page.',
  },
  deployedOn: {
    defaultMessage: 'Deployed on {date}',
    id: 'OdcxN9fU',
    description: 'Date of the recipe deployment',
  },
  editButtonLabel: {
    defaultMessage: 'Edit',
    id: 'EEFFvieI',
    description: 'The label for the edit button on the recipe deployment details page.',
  },
  containerGatewayEnabled: {
    defaultMessage: 'Container Gateway Enabled',
    id: '6qzAITO7',
    description: 'The label for Container Gateway enabled.',
  },
  startButtonLabel: {
    defaultMessage: 'Start',
    id: 'U95AyUOq',
    description: 'The label for the start button on the recipe deployment details page.',
  },
  stopButtonLabel: {
    defaultMessage: 'Stop',
    id: '13eYWPbT',
    description: 'The label for the stop button on the recipe deployment details page.',
  },
  refreshButtonLabel: {
    defaultMessage: 'Refresh',
    id: 'HvU1Jl1d',
    description: 'The label for the refresh button on the recipe deployment details page.',
  },
  instances: {
    defaultMessage: 'Instances',
    id: 'XJ5QcEzj',
    description: 'Instances tab name',
  },
  deploymentDetails: {
    defaultMessage: 'Deployment Details',
    id: 'SH/J1GWC',
    description: 'Deployment Details tab name',
  },
})
