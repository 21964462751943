import {
  ContainerGroupStatus,
  ResponseError,
  StartContainerGroupProblemType,
} from '@saladtechnologies/openapi-cloud-portal-browser'
import {
  EMPTY,
  catchError,
  concat,
  defer,
  delay,
  filter,
  from,
  mergeMap,
  of,
  repeat,
  switchMap,
  takeUntil,
  timer,
} from 'rxjs'
import {
  ContainerGroupsAPI,
  OrganizationDataAPI,
  OrganizationOptionsAPI,
  OrganizationsAPI,
  ProjectsAPI,
  WorkloadErrorsAPI,
  assertUnreachable,
} from '../apiMethods'
import {
  deleteContainerGroup,
  getContainerGroupDetailsPageData,
  setContainerGroup,
  startContainerGroup,
  stopContainerGroup,
  stopPollingForContainerGroup,
} from '../features/containerGroupDetail/containerGroupDetailSlice'
import { GetContainerGroupRequestType } from '../features/containerGroupDetail/models'
import {
  containerGroupInstancesAdded,
  getContainerGroupInstances,
} from '../features/containerGroupInstances/containerGroupInstancesSlice'
import { containerGroupWorkloadErrorsAdded } from '../features/containerGroupWorkloadErrors/containerGroupWorkloadErrorsSlice'
import { setRequiredActionModalShowingState } from '../features/createContainerGroup/createContainerGroupSlice'
import type { gpuClassesProps } from '../features/gpuClasses/gpuClassesSlice'
import { gpuClassesReceived } from '../features/gpuClasses/gpuClassesSlice'
import { configureGpuClassesResponse } from '../features/gpuClasses/utils'
import { showToastNotification } from '../features/notifications/notificationsSlice'
import { organizationAdded } from '../features/organizations/organizationsSlice'
import { projectsAddedToOrganization } from '../features/projects/projectsSlice'
import { ramOptionsReceived } from '../features/ramOptions/ramOptionsSlice'
import { setRequestStatus } from '../features/requestStatus/requestStatusSlice'
import { storageOptionsReceived } from '../features/storageOptions/storageOptionsSlice'
import { getUnableToRetrieveContainerGroupDetailsContent } from '../notifications/clientToastNotificationContent/containerGroupDetails'
import { getStartContainerGroupGeneralErrorContent } from '../notifications/clientToastNotificationContent/containerGroups'
import { getDeleteContainerGroupGeneralErrorContent } from '../notifications/clientToastNotificationContent/containerGroups/getDeleteContainerGroupGeneralErrorContent'
import { getDeleteContainerGroupSucceededContent } from '../notifications/clientToastNotificationContent/containerGroups/getDeleteContainerGroupSucceededContent'
import { getContainerGroupsPagePath } from '../routes/routes-utils'
import type { AppEpic } from '../store'
import { navigateTo } from './navigationEpic'

export const onGetContainerGroupDetailsPageData: AppEpic = (action$, state$, { intl }) =>
  action$.pipe(
    filter(getContainerGroupDetailsPageData.match),
    switchMap(({ payload: { containerGroupName, organizationName, projectName, requestType } }) =>
      concat(
        of(setRequestStatus({ request: 'getContainerGroupDetailsPageData', status: 'pending' })),
        defer(() =>
          from(
            Promise.all([
              ContainerGroupsAPI.getContainerGroup({
                containerGroupName,
                organizationName,
                projectName,
              }),
              OrganizationDataAPI.listGpuClasses({
                organizationName,
              }),
              OrganizationOptionsAPI.listStorageOptions({
                organizationName,
              }),
              OrganizationOptionsAPI.listRamOptions({
                organizationName,
              }),
              OrganizationsAPI.getOrganization({
                organizationName,
              }),
              ProjectsAPI.listProjects({
                organizationName,
              }),
              ContainerGroupsAPI.listContainerGroupInstances({
                containerGroupName,
                organizationName,
                projectName,
              }),
              WorkloadErrorsAPI.getWorkloadErrors({
                containerGroupName,
                organizationName,
                projectName,
              }),
            ]),
          ),
        ).pipe(
          mergeMap(
            ([
              containerGroupResponse,
              gpuClassesResponse,
              storageOptionsResponse,
              ramOptionsResponse,
              organizationResponse,
              projectsResponse,
              instancesResponse,
              workloadErrorsResponse,
            ]) => {
              const gpuClasses: gpuClassesProps[] = [configureGpuClassesResponse(organizationName, gpuClassesResponse)]
              return concat(
                of(
                  setContainerGroup({ containerGroup: containerGroupResponse }),
                  gpuClassesReceived({ gpuClasses }),
                  storageOptionsReceived({ storageOptions: storageOptionsResponse.items }),
                  ramOptionsReceived({ ramOptions: ramOptionsResponse.items }),
                  organizationAdded(organizationResponse),
                  projectsAddedToOrganization({
                    organizationName: organizationResponse.name,
                    projects: projectsResponse.items,
                  }),
                  containerGroupInstancesAdded({
                    containerGroupName,
                    organizationName,
                    projectName,
                    instances: instancesResponse.instances,
                  }),
                  containerGroupWorkloadErrorsAdded({
                    containerGroupName: containerGroupName,
                    organizationName: organizationName,
                    projectName: projectName,
                    workloadErrors: workloadErrorsResponse.items,
                  }),
                  setRequestStatus({ request: 'getContainerGroupDetailsPageData', status: 'succeeded' }),
                ),
                of(setRequestStatus({ request: 'getContainerGroupDetailsPageData', status: 'idle' })).pipe(delay(1)),
              )
            },
          ),
          catchError((error: unknown) => {
            const projectContainersRoute = getContainerGroupsPagePath(organizationName, projectName)
            return error instanceof ResponseError && error.response.status === 404
              ? concat(
                  of(
                    setRequestStatus({ request: 'getContainerGroupDetailsPageData', status: 'failed' }),
                    showToastNotification(getUnableToRetrieveContainerGroupDetailsContent(intl)),
                    navigateTo({ path: projectContainersRoute }),
                  ),
                  of(setRequestStatus({ request: 'getContainerGroupDetailsPageData', status: 'idle' })).pipe(delay(1)),
                )
              : from(
                  ContainerGroupsAPI.getContainerGroup({
                    containerGroupName,
                    organizationName,
                    projectName,
                  }),
                ).pipe(
                  mergeMap((response) => {
                    return concat(
                      of(
                        setContainerGroup({ containerGroup: response }),
                        setRequestStatus({ request: 'getContainerGroupDetailsPageData', status: 'succeeded' }),
                      ),
                      of(setRequestStatus({ request: 'getContainerGroupDetailsPageData', status: 'idle' })).pipe(
                        delay(1),
                      ),
                    )
                  }),
                  // TODO: Decide what UI we should show to the user during our retry attempts where we keep failing
                  catchError(() => EMPTY),
                  takeUntil(action$.pipe(filter(stopPollingForContainerGroup.match))),
                )
          }),
          repeat({
            delay: (repeatCount) => {
              const containerGroupStatus = state$.value.containerGroupDetail.containerGroup?.currentState.status
              const isContainerGroupEditChangesPending = state$.value.containerGroupDetail.containerGroup?.pendingChange
              const everyMinuteInMS = 60000
              const everyFiveSecondsInMS = 5000
              const everyFifteenSecondsInMS = 15000

              let repeatTimerInMS = everyMinuteInMS
              if (requestType === GetContainerGroupRequestType.LOAD && containerGroupStatus === undefined) {
                if (repeatCount <= 10) {
                  repeatTimerInMS = everyFiveSecondsInMS
                } else if (repeatCount <= 20) {
                  repeatTimerInMS = everyFifteenSecondsInMS
                } else {
                  repeatTimerInMS = everyMinuteInMS
                }
              }

              if (
                (requestType === GetContainerGroupRequestType.LOAD ||
                  requestType === GetContainerGroupRequestType.START) &&
                (containerGroupStatus === ContainerGroupStatus.Pending ||
                  containerGroupStatus === ContainerGroupStatus.Deploying ||
                  containerGroupStatus === ContainerGroupStatus.Running)
              ) {
                repeatTimerInMS = everyFiveSecondsInMS
              }

              if (requestType === GetContainerGroupRequestType.START) {
                repeatTimerInMS = everyFifteenSecondsInMS
              }

              if (
                requestType === GetContainerGroupRequestType.STOP &&
                containerGroupStatus !== ContainerGroupStatus.Stopped
              ) {
                repeatTimerInMS = everyFiveSecondsInMS
              }

              if (isContainerGroupEditChangesPending) {
                repeatTimerInMS = everyFiveSecondsInMS
              }

              return timer(repeatTimerInMS)
            },
          }),
          takeUntil(action$.pipe(filter(stopPollingForContainerGroup.match))),
        ),
      ),
    ),
  )

// TODO: Internationalize error messages and add problem detail types
export const onGetContainerGroupInstances: AppEpic = (action$, _state$) =>
  action$.pipe(
    filter(getContainerGroupInstances.match),
    switchMap(({ payload: { containerGroupName, organizationName, projectName } }) =>
      concat(
        of(setRequestStatus({ request: 'getContainerGroupInstances', status: 'pending' })),
        from(
          ContainerGroupsAPI.listContainerGroupInstances({
            containerGroupName: containerGroupName,
            organizationName: organizationName,
            projectName: projectName,
          }),
        ).pipe(
          mergeMap((response) =>
            concat(
              of(
                containerGroupInstancesAdded({
                  organizationName,
                  projectName,
                  containerGroupName,
                  instances: response.instances,
                }),
                setRequestStatus({ request: 'getContainerGroupInstances', status: 'succeeded' }),
              ),
              of(setRequestStatus({ request: 'getContainerGroupInstances', status: 'idle' })).pipe(delay(1)),
            ),
          ),
          catchError(() =>
            concat(
              of(
                showToastNotification({
                  body: 'We were unable to fetch the container group instances at this time. Please try again.',
                  title: 'Unable to fetch container group instances',
                  type: 'error',
                }),
                setRequestStatus({ request: 'getContainerGroupInstances', status: 'failed' }),
              ),
              of(setRequestStatus({ request: 'getContainerGroupInstances', status: 'idle' })).pipe(delay(1)),
            ),
          ),
        ),
      ),
    ),
  )

export const onStartContainerGroup: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(startContainerGroup.match),
    mergeMap(({ payload: { containerGroupName, organizationName, projectName } }) =>
      concat(
        of(setRequestStatus({ request: 'startContainerGroup', status: 'pending' })),
        from(
          ContainerGroupsAPI.startContainerGroup({
            containerGroupName,
            organizationName,
            projectName,
          }),
        ).pipe(
          mergeMap(() => {
            return concat(
              of(
                getContainerGroupDetailsPageData({
                  organizationName,
                  projectName,
                  containerGroupName,
                  requestType: GetContainerGroupRequestType.START,
                }),
                setRequestStatus({ request: 'startContainerGroup', status: 'succeeded' }),
              ),
              of(setRequestStatus({ request: 'startContainerGroup', status: 'idle' })).pipe(delay(1)),
            )
          }),
          catchError((error: unknown) => {
            return error instanceof ResponseError && error.response.status === 400
              ? from(error.response.json()).pipe(
                  mergeMap((errorResponse) => {
                    const errorType = errorResponse.type as StartContainerGroupProblemType | null
                    if (errorType != null && errorType !== StartContainerGroupProblemType.Null) {
                      switch (errorType) {
                        case StartContainerGroupProblemType.CreatedQuotaExceeded:
                          return concat(
                            of(
                              setRequestStatus({ request: 'startContainerGroup', status: 'failed' }),
                              setRequiredActionModalShowingState({
                                requiredAction: 'deploymentsQuota',
                                showing: true,
                              }),
                            ),
                            of(setRequestStatus({ request: 'startContainerGroup', status: 'idle' })).pipe(delay(1)),
                          )
                        case StartContainerGroupProblemType.PaymentMethodRequired:
                          return concat(
                            of(
                              setRequestStatus({ request: 'startContainerGroup', status: 'failed' }),
                              setRequiredActionModalShowingState({
                                requiredAction: 'billing',
                                showing: true,
                              }),
                            ),
                            of(setRequestStatus({ request: 'startContainerGroup', status: 'idle' })).pipe(delay(1)),
                          )
                        default:
                          assertUnreachable(errorType)
                      }
                    } else {
                      return concat(
                        of(
                          setRequestStatus({ request: 'startContainerGroup', status: 'failed' }),
                          showToastNotification(getStartContainerGroupGeneralErrorContent(intl)),
                        ),
                        of(setRequestStatus({ request: 'startContainerGroup', status: 'idle' })).pipe(delay(1)),
                      )
                    }
                  }),
                )
              : concat(
                  of(
                    setRequestStatus({ request: 'startContainerGroup', status: 'failed' }),
                    showToastNotification(getStartContainerGroupGeneralErrorContent(intl)),
                  ),
                  of(setRequestStatus({ request: 'startContainerGroup', status: 'idle' })).pipe(delay(1)),
                )
          }),
        ),
      ),
    ),
  )

// TODO: Internationalize error messages and add problem detail types
export const onStopContainerGroup: AppEpic = (action$, _state$) =>
  action$.pipe(
    filter(stopContainerGroup.match),
    mergeMap(({ payload: { containerGroupName, organizationName, projectName } }) =>
      concat(
        of(setRequestStatus({ request: 'stopContainerGroup', status: 'pending' })),
        from(
          ContainerGroupsAPI.stopContainerGroup({
            containerGroupName,
            organizationName,
            projectName,
          }),
        ).pipe(
          mergeMap((_response) => {
            return concat(
              of(
                getContainerGroupDetailsPageData({
                  organizationName,
                  projectName,
                  containerGroupName,
                  requestType: GetContainerGroupRequestType.STOP,
                }),
                setRequestStatus({ request: 'stopContainerGroup', status: 'succeeded' }),
              ),
              of(setRequestStatus({ request: 'stopContainerGroup', status: 'idle' })).pipe(delay(1)),
            )
          }),
          catchError((error: unknown) => {
            const errorMessage = 'We were unable to stop the container group at this time. Please try again.'
            return error instanceof ResponseError && error.response.status === 400
              ? from(error.response.json()).pipe(
                  mergeMap((_errorResponse) => {
                    return concat(
                      of(
                        setRequestStatus({ request: 'stopContainerGroup', status: 'failed' }),
                        showToastNotification({
                          body: errorMessage,
                          title: 'Unable to stop container group',
                          type: 'error',
                        }),
                      ),
                      of(setRequestStatus({ request: 'stopContainerGroup', status: 'idle' })).pipe(delay(1)),
                    )
                  }),
                )
              : concat(
                  of(
                    setRequestStatus({ request: 'stopContainerGroup', status: 'failed' }),
                    showToastNotification({
                      body: errorMessage,
                      title: 'Unable to stop container group',
                      type: 'error',
                    }),
                  ),
                  of(setRequestStatus({ request: 'stopContainerGroup', status: 'idle' })).pipe(delay(1)),
                )
          }),
        ),
      ),
    ),
  )

export const onDeleteContainerGroup: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(deleteContainerGroup.match),
    mergeMap(({ payload: { containerGroupDisplayName, containerGroupName, organizationName, projectName } }) =>
      concat(
        of(setRequestStatus({ request: 'deleteContainerGroup', status: 'pending' })),
        from(
          ContainerGroupsAPI.deleteContainerGroup({
            containerGroupName,
            organizationName,
            projectName,
          }),
        ).pipe(
          mergeMap((_response) => {
            return concat(
              of(
                setRequestStatus({ request: 'deleteContainerGroup', status: 'succeeded' }),
                navigateTo({
                  path: getContainerGroupsPagePath(organizationName, projectName),
                }),
                showToastNotification(getDeleteContainerGroupSucceededContent(intl, containerGroupDisplayName)),
              ),
              of(setRequestStatus({ request: 'deleteContainerGroup', status: 'idle' })).pipe(delay(1)),
            )
          }),
          catchError((error: unknown) => {
            return error instanceof ResponseError && error.response.status === 400
              ? from(error.response.json()).pipe(
                  mergeMap((_errorResponse) => {
                    return concat(
                      of(
                        setRequestStatus({ request: 'deleteContainerGroup', status: 'failed' }),
                        showToastNotification(getDeleteContainerGroupGeneralErrorContent(intl)),
                      ),
                      of(setRequestStatus({ request: 'deleteContainerGroup', status: 'idle' })).pipe(delay(1)),
                    )
                  }),
                )
              : concat(
                  of(
                    setRequestStatus({ request: 'deleteContainerGroup', status: 'failed' }),
                    showToastNotification(getDeleteContainerGroupGeneralErrorContent(intl)),
                  ),
                  of(setRequestStatus({ request: 'deleteContainerGroup', status: 'idle' })).pipe(delay(1)),
                )
          }),
        ),
      ),
    ),
  )
