import { defineMessages } from 'react-intl'

export const GenericRequestFailedContentMessages = defineMessages({
  body: {
    defaultMessage:
      'Something went wrong with your request. Please try again and if things do not resolve, please {contact_support}.',
    id: 'bDi38U7Q',
    description: 'A generic error message shown in an error toast notification body when a request fails.',
  },
  contactSupportLinkCopy: {
    defaultMessage: 'contact support',
    id: 'qlzJKtw6',
    description:
      'A copy shown for the link to contact support in a generic error toast notification body when a request fails.',
  },
  title: {
    defaultMessage: 'Your request could not be completed',
    id: 'LEmC4Frz',
    description:
      "The toast notification title shown when we show a generic message when a user's request in our app fails.",
  },
})
