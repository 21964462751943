import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { ExternalLoggingDetailsMessages } from '../../messages'

interface SplunkExternalLoggingDetailsProps {
  /** The splunk host value. */
  splunkHost: string | undefined
  /** The splunk token value. */
  splunkToken: string | undefined
}

const colon = ':'

export const SplunkExternalLoggingDetails: FunctionComponent<SplunkExternalLoggingDetailsProps> = ({
  splunkHost,
  splunkToken,
}) => {
  const intl = useIntl()

  return (
    <div className="text-base font-medium">
      <p>{intl.formatMessage(ExternalLoggingDetailsMessages.splunkSelectedLabel)}</p>
      <p className="truncate">
        {intl.formatMessage(ExternalLoggingDetailsMessages.hostLabel)}
        {colon}
        &nbsp;
        <span className="font-normal">{splunkHost ?? ''}</span>
      </p>
      <p className="truncate">
        {intl.formatMessage(ExternalLoggingDetailsMessages.tokenLabel)}
        {colon}
        &nbsp;
        <span className="font-normal">{splunkToken ?? ''}</span>
      </p>
    </div>
  )
}
