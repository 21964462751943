import { yupResolver } from '@hookform/resolvers/yup'
import type { FunctionComponent } from 'react'
import type { FieldValues } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Button } from '../../../components/Button'
import { TextField } from '../../../components/TextField'
import { useFixedForm } from '../../../hooks'
import { ModalLayout } from '../../../layouts/authentication'
import { ResetPasswordLinkExpiredMessages } from './messages'
import { useResetPasswordLinkExpiredFormValidation } from './useResetPasswordLinkExpiredFormValidation'

export interface ResetPasswordLinkExpiredFormValues {
  /** The email address. */
  email: string
}

export interface ResetPasswordLinkExpiredProps {
  /** The flag indicating the form is submitting the request to send the user a reset password email. */
  isSendResetPasswordEmailPending: boolean
  /** The callback executed when the user submits the form in order to request a reset password email. */
  onSendResetPasswordEmail: (values: FieldValues) => void
}

export const ResetPasswordLinkExpired: FunctionComponent<ResetPasswordLinkExpiredProps> = ({
  isSendResetPasswordEmailPending,
  onSendResetPasswordEmail,
}) => {
  const intl = useIntl()
  const verificationFormValidationScheme = useResetPasswordLinkExpiredFormValidation()

  const { control, handleSubmit } = useFixedForm({
    resolver: yupResolver(verificationFormValidationScheme),
    onSubmit: onSendResetPasswordEmail,
  })

  return (
    <ModalLayout type="narrow">
      <form className="flex flex-col" onSubmit={handleSubmit}>
        <h1 className="pb-8 text-2xl font-bold">{intl.formatMessage(ResetPasswordLinkExpiredMessages.title)}</h1>
        <p className="pb-8 text-base">{intl.formatMessage(ResetPasswordLinkExpiredMessages.description)}</p>
        <Controller
          name="email"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              {...fieldState}
              helperText={fieldState.error?.message}
              placeholder={intl.formatMessage(ResetPasswordLinkExpiredMessages.emailPlaceholder)}
              leftIconClassName="fa-solid fa-envelope"
              label={intl.formatMessage(ResetPasswordLinkExpiredMessages.emailLabel)}
              isFullWidth
            />
          )}
        />
        <div className="flex flex-row justify-start gap-3">
          <Button
            isFullWidth
            isDisabled={isSendResetPasswordEmailPending}
            isLoading={isSendResetPasswordEmailPending}
            type="submit"
          >
            {intl.formatMessage(ResetPasswordLinkExpiredMessages.resendEmailButtonLabel)}
          </Button>
          <a
            className="flex w-full items-center justify-center rounded-sm border border-green-90 bg-transparent p-2 px-3 text-green-90"
            href="mailto:cloud@salad.com?subject=SaladCloud Support - Reset Password Verification Expired"
          >
            {intl.formatMessage(ResetPasswordLinkExpiredMessages.contactSupportLabel)}
          </a>
        </div>
      </form>
    </ModalLayout>
  )
}
