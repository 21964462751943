import { defineMessages } from 'react-intl'

export const ChangePasswordSucceededContentMessages = defineMessages({
  body: {
    defaultMessage: 'Don’t forget to update your password in your password manager if needed.',
    id: 'icJ2qeAf',
    description:
      'The description shown in a toast notification message shown when the user successfully changed their password.',
  },
  title: {
    defaultMessage: 'Your password has been updated!',
    id: 'GneZpxa/',
    description: 'The toast notification title shown when the user successfully changed their password.',
  },
})
