import { defineMessages } from 'react-intl'

export const GetContainerGroupLogsAxiomTimeouErrorContentMessages = defineMessages({
  body: {
    defaultMessage:
      'The query may be too large and is experiencing a timeout issue. Try again with a shorter time interval. If the issue persists {contact_support}.',
    id: 'D+ZhlV8K',
    description:
      'The description shown in a toast notification message shown when we encounter an axiom timeout error on their request to get container logs.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'Kwoiimy4',
    description: 'The text shown for the contact support link in the get container logs error toast notification.',
  },
  title: {
    defaultMessage: 'Query Timeout Error',
    id: 'qAJxKKtg',
    description:
      'The toast notification title shown when we encounter an axiom timeout error on their request to get container logs.',
  },
})
