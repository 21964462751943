import type { FunctionComponent } from 'react'
import { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import { LoadingPage } from '../../components/page/LoadingPage'
import { selectJobQueue } from '../../features/jobQueues/jobQueuesSelectors'
import { editJobQueue, getEditJobQueuePageData } from '../../features/jobQueues/jobQueuesSlice'
import { configuresJobQueuesEntityId } from '../../features/jobQueues/utils'
import { selectIsLeftColumnShowing } from '../../features/navigationBar/navigationBarSelectors'
import { setLeftColumnShowing } from '../../features/navigationBar/navigationBarSlice'
import { selectOrganizationDisplayName } from '../../features/organizations/organizationsSelectors'
import { selectProjectDisplayName, selectProjects } from '../../features/projects/projectsSelectors'
import { deleteProject } from '../../features/projects/projectsSlice'
import { selectRequestStatus, selectRequestStatusIsPending } from '../../features/requestStatus/requestStatusSelectors'
import { getJobQueueDetailsPagePath, getJobQueuesPagePath } from '../../routes/routes-utils'
import { useAppDispatch, useAppSelector } from '../../store'
import { EditJobQueuePage } from './EditJobQueuePage'

export const ConnectedEditJobQueuePage: FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { jobQueueName = '', organizationName = '', projectName = '' } = useParams()

  const jobQueueDetailsPath = getJobQueueDetailsPagePath(organizationName, projectName, jobQueueName)
  const isEditJobQueueRequestPending = useAppSelector((state) => selectRequestStatusIsPending(state, 'editJobQueue'))
  const isGetEditJobQueuePageDataRequestPending = useAppSelector((state) =>
    selectRequestStatusIsPending(state, 'getEditJobQueuePageData'),
  )
  const jobQueue = useAppSelector((state) =>
    selectJobQueue(state, configuresJobQueuesEntityId(organizationName, projectName), jobQueueName),
  )
  const deleteProjectRequestStatus = useAppSelector((state) => selectRequestStatus(state, 'deleteProject'))
  const isLeftColumnOpen = useAppSelector(selectIsLeftColumnShowing)
  const organizationDisplayName = useAppSelector((state) => selectOrganizationDisplayName(state, organizationName))
  const projects = useAppSelector((state) => selectProjects(state, organizationName))?.projects || []
  const currentProjectDisplayName = useAppSelector((state) =>
    selectProjectDisplayName(state, organizationName, projectName),
  )
  const projectSelectOptions = projects.map((project) => {
    return {
      ...project,
      selected: project.name === projectName,
    }
  })

  const handleCloseLeftNavBar = useCallback(() => {
    dispatch(setLeftColumnShowing({ showing: false }))
  }, [dispatch])

  const handleDeleteProject = useCallback(
    (projectName: string) => {
      dispatch(deleteProject({ organizationName, projectName }))
    },
    [dispatch, organizationName],
  )

  const handleProjectChange = useCallback(
    (projectName: string) => {
      navigate(getJobQueuesPagePath(organizationName, projectName))
    },
    [navigate, organizationName],
  )

  const handleGetEditJobQueuePageData = useCallback(() => {
    dispatch(
      getEditJobQueuePageData({
        organizationName,
        projectName,
        jobQueueName,
      }),
    )
  }, [dispatch, organizationName, projectName, jobQueueName])

  const onEditJobQueue = useCallback(
    (displayName: string, description?: string) => {
      dispatch(
        editJobQueue({
          organizationName,
          projectName,
          queueName: jobQueueName,
          updatedQueue: { displayName, description },
        }),
      )
    },
    [dispatch, organizationName, projectName, jobQueueName],
  )

  useEffect(() => {
    handleGetEditJobQueuePageData()
  }, [handleGetEditJobQueuePageData])

  return isGetEditJobQueuePageDataRequestPending && jobQueue === undefined ? (
    <LoadingPage />
  ) : (
    <EditJobQueuePage
      currentOrganization={{ name: organizationName, displayName: organizationDisplayName ?? organizationName }}
      currentProject={{ name: projectName, displayName: currentProjectDisplayName ?? projectName }}
      isDeleteProjectPending={deleteProjectRequestStatus === 'pending'}
      isDeleteProjectSuccessful={deleteProjectRequestStatus === 'succeeded'}
      isEditJobQueuePending={isEditJobQueueRequestPending}
      isLeftColumnOpen={isLeftColumnOpen}
      jobQueueDescription={jobQueue?.description}
      jobQueueDetailsPath={jobQueueDetailsPath}
      jobQueueDisplayName={jobQueue?.displayName as string}
      jobQueueName={jobQueue?.name as string}
      projects={projectSelectOptions}
      onCloseLeftDrawer={handleCloseLeftNavBar}
      onDeleteProject={handleDeleteProject}
      onEditJobQueue={(displayName, description) => onEditJobQueue(displayName, description)}
      onProjectChange={handleProjectChange}
    />
  )
}
