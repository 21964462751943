import { defineMessages } from 'react-intl'

export const PaymentMethodValidationHoldErrorMessages = defineMessages({
  body: {
    defaultMessage: 'We were unable to validate your payment method. Please try a different payment method.',
    id: 'XiYwxy/y',
    description:
      'The description in a toast notification message shown when we encounter an error validating payment method with temporary hold of money.',
  },
  title: {
    defaultMessage: 'Validation Failed',
    id: 'NQejHdLR',
    description:
      'The toast notification title shown when we encounter an error validating payment method with temporary hold of money.',
  },
})
