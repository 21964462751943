import { useMemo } from 'react'
import type { ObjectSchema } from 'yup'
import { object, string } from 'yup'
import type { DeleteOrganizationValues } from './DeleteOrganizationModal'

export const useDeleteOrganizationFormValidation = (
  organizationName: string,
): ObjectSchema<DeleteOrganizationValues> => {
  const validationScheme = useMemo(
    () =>
      object({
        organizationName: string()
          .required()
          .test((value) => value === organizationName),
      }),
    [organizationName],
  )
  return validationScheme
}
