import { defineMessages } from 'react-intl'

export const ContainerConfigurationMessages = defineMessages({
  backLinkText: {
    defaultMessage: 'Exit to Container Groups',
    id: 'hqaT1hGe',
    description: 'The text for the back link button.',
  },
  containerConfigurationTitle: {
    defaultMessage: 'Container Configuration',
    id: 'Qf9kN3LE',
    description: 'The title for the container configuration page.',
  },
  containerConfigurationSubtitle: {
    defaultMessage: 'Deploy your existing container on Salad',
    id: 'Qyona9xj',
    description: 'The subtitle for the container configuration page.',
  },
  nextStepButtonLabel: {
    defaultMessage: 'Next Step',
    id: 'xQLpHFdn',
    description: 'The label for the next step button in the bottom bar.',
  },
  previousButtonLabel: {
    defaultMessage: 'Previous',
    id: 'E0JVooDD',
    description: 'The label for the previous button in the bottom bar.',
  },
})
