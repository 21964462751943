import type { FunctionComponent } from 'react'
import { useCallback, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { trackMixpanelElementClickedEvent, trackMixpanelPageViewedEvent } from '../../features/analytics/analyticsSlice'
import { selectIsLeftColumnShowing } from '../../features/navigationBar/navigationBarSelectors'
import { setLeftColumnShowing } from '../../features/navigationBar/navigationBarSlice'
import { selectProductPageRoutePath } from '../../routes/routePaths'
import { useAppDispatch, useAppSelector } from '../../store'
import { SelectProductPage } from './SelectProductPage'

export const ConnectedSelectProductPage: FunctionComponent = () => {
  const { organizationName = '', projectName = '' } = useParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const isLeftColumnOpen = useAppSelector(selectIsLeftColumnShowing)

  const handleCloseLeftNavBar = useCallback(() => {
    dispatch(setLeftColumnShowing({ showing: false }))
  }, [dispatch])

  const onRecordMixpanelElementClickedEvent = useCallback(
    (label: string) => {
      dispatch(
        trackMixpanelElementClickedEvent({
          label,
          path: selectProductPageRoutePath,
          organizationName,
          projectName,
        }),
      )
    },
    [dispatch, organizationName, projectName],
  )

  useEffect(() => {
    const handlePopstate = (e: PopStateEvent) => {
      e.preventDefault()
      navigate(`/organizations`)
    }

    window.addEventListener('popstate', handlePopstate)
    return () => {
      setTimeout(() => {
        window.removeEventListener('popstate', handlePopstate)
      }, 0)
    }
  }, [navigate, organizationName])

  useEffect(() => {
    dispatch(trackMixpanelPageViewedEvent({ path: selectProductPageRoutePath, organizationName, projectName }))
  }, [dispatch, organizationName, projectName])

  return (
    <SelectProductPage
      isLeftColumnOpen={isLeftColumnOpen}
      onCloseLeftDrawer={handleCloseLeftNavBar}
      onRecordMixpanelElementClickedEvent={onRecordMixpanelElementClickedEvent}
      organizationName={organizationName}
      projectName={projectName}
    />
  )
}
