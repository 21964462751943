import { defineMessages } from 'react-intl'

export const InferenceEndpointDetailsGeneralErrorContentMessages = defineMessages({
  body: {
    defaultMessage:
      'We were unable to retrieve this inference endpoint. Try again and if this issue persists {contact_support}.',
    id: '2iLuMOJZ',
    description:
      'The description shown in a toast notification message shown when we encounter an error on their request to get their inference endpoint details.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'SrmqDSGI',
    description:
      'The text shown for the contact support link in the get inference endpoint details error toast notification.',
  },
  title: {
    defaultMessage: 'Unable to retrieve inference endpoint',
    id: 'uKXdQGKr',
    description:
      'The toast notification title shown when we encounter an error on their request to get their inference endpoint details.',
  },
})
