import { catchError, concat, delay, filter, forkJoin, from, mergeMap, of, switchMap } from 'rxjs'
import {
  ContainerGroupsAPI,
  OrganizationDataAPI,
  OrganizationOptionsAPI,
  OrganizationsAPI,
  ProjectsAPI,
  QuotasAPI,
} from '../apiMethods'
import {
  containerGroupsAddedToOrganization,
  getContainerGroups,
  getContainerGroupsPageData,
} from '../features/containerGroups/containerGroupsSlice'
import type { gpuClassesProps } from '../features/gpuClasses/gpuClassesSlice'
import { gpuClassesReceived } from '../features/gpuClasses/gpuClassesSlice'
import { configureGpuClassesResponse } from '../features/gpuClasses/utils'
import { showToastNotification } from '../features/notifications/notificationsSlice'
import { organizationAdded } from '../features/organizations/organizationsSlice'
import { projectsAddedToOrganization } from '../features/projects/projectsSlice'
import { quotasAddedToOrganization } from '../features/quotas/quotasSlice'
import { ramOptionsReceived } from '../features/ramOptions/ramOptionsSlice'
import { setRequestStatus } from '../features/requestStatus/requestStatusSlice'
import { storageOptionsReceived } from '../features/storageOptions/storageOptionsSlice'
import { getUnableToRetrieveContainerGroupsContent } from '../notifications/clientToastNotificationContent/containerGroups'
import { getSelectProductPagePath } from '../routes/routes-utils'
import type { AppEpic } from '../store'
import { navigateTo } from './navigationEpic'

export const onGetContainerGroupsPageData: AppEpic = (action$, _state$, { intl }) => {
  return action$.pipe(
    filter(getContainerGroupsPageData.match),
    switchMap(({ payload: { organizationName, projectName } }) =>
      concat(
        of(setRequestStatus({ request: 'getContainerGroupsPageData', status: 'pending' })),
        forkJoin([
          ContainerGroupsAPI.listContainerGroups({
            organizationName,
            projectName,
          }),
          OrganizationDataAPI.listGpuClasses({
            organizationName,
          }),
          OrganizationOptionsAPI.listStorageOptions({
            organizationName,
          }),
          OrganizationOptionsAPI.listRamOptions({
            organizationName,
          }),
          QuotasAPI.getQuotas({
            organizationName,
          }),
          OrganizationsAPI.getOrganization({
            organizationName,
          }),
          ProjectsAPI.listProjects({
            organizationName,
          }),
        ]).pipe(
          switchMap(
            ([
              containerGroupsResponse,
              gpuClassesResponse,
              storageOptionsResponse,
              ramOptionsResponse,
              quotasResponse,
              organizationResponse,
              projectsResponse,
            ]) => {
              const gpuClasses: gpuClassesProps[] = [configureGpuClassesResponse(organizationName, gpuClassesResponse)]
              return concat(
                of(
                  containerGroupsAddedToOrganization({
                    organizationName,
                    projectName,
                    containerGroups: containerGroupsResponse.items,
                  }),
                  gpuClassesReceived({ gpuClasses }),
                  storageOptionsReceived({ storageOptions: storageOptionsResponse.items }),
                  ramOptionsReceived({ ramOptions: ramOptionsResponse.items }),
                  organizationAdded(organizationResponse),
                  projectsAddedToOrganization({
                    organizationName: organizationResponse.name,
                    projects: projectsResponse.items,
                  }),
                  quotasAddedToOrganization({
                    organizationName: organizationResponse.name,
                    quotas: quotasResponse,
                  }),
                  setRequestStatus({ request: 'getContainerGroupsPageData', status: 'succeeded' }),
                ),
                of(setRequestStatus({ request: 'getContainerGroupsPageData', status: 'idle' })).pipe(delay(1)),
              )
            },
          ),
          catchError(() => {
            const projectRoute = getSelectProductPagePath(organizationName, projectName)
            return concat(
              of(
                setRequestStatus({ request: 'getContainerGroupsPageData', status: 'failed' }),
                showToastNotification(getUnableToRetrieveContainerGroupsContent(intl)),
                navigateTo({ path: projectRoute }),
              ),
              of(setRequestStatus({ request: 'getContainerGroupsPageData', status: 'idle' })).pipe(delay(1)),
            )
          }),
        ),
      ),
    ),
  )
}

export const onPollContainerGroups: AppEpic = (action$, _state$) =>
  action$.pipe(
    filter(getContainerGroups.match),
    mergeMap((action) =>
      concat(
        of(setRequestStatus({ request: 'pollContainerGroups', status: 'pending' })),
        from(
          ContainerGroupsAPI.listContainerGroups({
            organizationName: action.payload.organizationName,
            projectName: action.payload.projectName,
          }),
        ).pipe(
          mergeMap((response) => {
            return concat(
              of(
                containerGroupsAddedToOrganization({
                  organizationName: action.payload.organizationName,
                  projectName: action.payload.projectName,
                  containerGroups: response.items,
                }),
                setRequestStatus({ request: 'pollContainerGroups', status: 'succeeded' }),
              ),
              of(setRequestStatus({ request: 'pollContainerGroups', status: 'idle' })).pipe(delay(1)),
            )
          }),
          catchError(() => {
            return concat(
              of(setRequestStatus({ request: 'pollContainerGroups', status: 'failed' })),
              of(setRequestStatus({ request: 'pollContainerGroups', status: 'idle' })).pipe(delay(1)),
            )
          }),
        ),
      ),
    ),
  )
