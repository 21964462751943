import type { Queue } from '@saladtechnologies/openapi-cloud-portal-browser'
import type { FunctionComponent } from 'react'
import { useState } from 'react'
import type { Control, FieldValues, UseFormResetField, UseFormTrigger } from 'react-hook-form'
import { Controller, useFormState, useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router'
import { Button } from '../../../../components/Button'
import { TextField } from '../../../../components/TextField'
import { ButtonLink, Link } from '../../../../components/base'
import { SidePanelModal } from '../../../../components/block/SidePanelModal'
import { JobQueueDetails } from '../../../../components/containerGroups/JobQueueDetails'
import { FeaturePreviewInlineNotification } from '../../../../components/featurePreview/FeaturePreviewInlineNotification'
import { FormStateCard } from '../../../../components/forms/FormStateCard'
import { Select } from '../../../../components/forms/Select'
import { getCreateJobQueuePagePath } from '../../../../routes/routes-utils'
import { EditContainerGroupMainContentMessages, EditJobQueueFieldsMessages } from '../../messages'
import {
  EditContainerGroupField,
  EditContainerGroupFormSectionIdAttributes,
  EditContainerGroupValues,
} from '../../models'
import { checkIfJobQueueFieldsHaveErrors, getJobQueueDisplayName, jobQueueFieldsList } from './utils'

interface EditJobQueueFieldsProps {
  /** The control for the create container group react hook form. */
  control: Control<FieldValues, EditContainerGroupValues>
  /** The id attribute for the fields. */
  id: EditContainerGroupFormSectionIdAttributes
  /** The react hook form method that provides the ability to reset a specified field value. */
  resetField: UseFormResetField<FieldValues>
  /** The react hook form method that triggers validation for specified fields. */
  trigger: UseFormTrigger<FieldValues>
  /** The available job queues to select from for a container group. */
  queues: Queue[]
}

export const EditJobQueueFields: FunctionComponent<EditJobQueueFieldsProps> = ({
  control,
  id,
  queues,
  resetField,
  trigger,
}) => {
  const intl = useIntl()
  const { organizationName = '', projectName = '' } = useParams()
  const [localSelectedJobQueue, setSelectedJobQueue] = useState<string | undefined>(undefined)
  const { errors } = useFormState({ control })
  const jobQueue = useWatch({ control, name: EditContainerGroupField.JOB_QUEUE })
  const jobQueuePath = useWatch({ control, name: EditContainerGroupField.JOB_QUEUE_PATH })
  const jobQueuePort = useWatch({ control, name: EditContainerGroupField.JOB_QUEUE_PORT })
  const isContainerGatewayEnabled = useWatch({ control, name: EditContainerGroupField.CONTAINER_GATEWAY_ENABLED })
  const jobQueueFieldsHaveErrors = checkIfJobQueueFieldsHaveErrors(errors)
  const [isJobQueuesSidePanelOpen, setIsJobQueuesSidePanelOpen] = useState<boolean>(false)
  const queueOptions = queues.map((queue) => ({
    label: queue.displayName || queue.name,
    value: queue.name,
  }))
  const selectedQueueDisplayName = getJobQueueDisplayName(queueOptions, jobQueue)
  const handleValidateFieldsBeforeClose = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    await trigger(jobQueueFieldsList).then((isValid) => {
      if (isValid) {
        setIsJobQueuesSidePanelOpen(false)
      }
    })
  }
  const isJobQueueFieldsReadOnly = true

  return (
    <div className="mb-10" id={id}>
      <FormStateCard
        hasError={jobQueueFieldsHaveErrors}
        isFeaturePreview
        isDisabled={isContainerGatewayEnabled}
        onEditForm={() => setIsJobQueuesSidePanelOpen(true)}
        title={intl.formatMessage(EditJobQueueFieldsMessages.sectionTitle)}
      >
        {isContainerGatewayEnabled ? (
          intl.formatMessage(EditJobQueueFieldsMessages.jobQueuesDisabledContainerGatewayEnabledLabel)
        ) : (
          <JobQueueDetails
            name={selectedQueueDisplayName}
            onClickAddJobQueueButton={() => setIsJobQueuesSidePanelOpen(true)}
            path={jobQueuePath}
            port={jobQueuePort}
          />
        )}
      </FormStateCard>

      <SidePanelModal
        CustomButton={
          <Button variant="green-filled" form="updateJobQueueFieldsForm" isFullWidth type="submit" isDisabled>
            {intl.formatMessage(EditContainerGroupMainContentMessages.configureButtonLabel)}
          </Button>
        }
        isShown={isJobQueuesSidePanelOpen}
        onClose={() => setIsJobQueuesSidePanelOpen(false)}
        title={intl.formatMessage(EditJobQueueFieldsMessages.sectionTitle)}
      >
        <form onSubmit={handleValidateFieldsBeforeClose} id="updateJobQueueFieldsForm">
          <div className="mt-12 px-10">
            <h2 className="mb-2 text-3xl font-bold">{intl.formatMessage(EditJobQueueFieldsMessages.sectionTitle)}</h2>

            <p className="mb-6">
              {intl.formatMessage(EditJobQueueFieldsMessages.description, {
                documentation_link: (
                  <Link url="https://docs.salad.com/container-engine/job-queues">
                    {intl.formatMessage(EditJobQueueFieldsMessages.learnMoreHereLinkText)}
                  </Link>
                ),
              })}
            </p>

            <div className="mb-10">
              <FeaturePreviewInlineNotification />
            </div>

            <div className="mb-12 flex flex-col gap-6">
              <Controller
                name={EditContainerGroupField.JOB_QUEUE}
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <Select
                      {...field}
                      {...fieldState}
                      defaultSelectedValue={field.value || localSelectedJobQueue}
                      onChange={(value) => {
                        if (value === undefined) {
                          resetField(EditContainerGroupField.JOB_QUEUE)
                          jobQueueFieldsList.forEach((field) => {
                            resetField(field)
                          })

                          // This is needed to trigger the UI updating for the job queue field
                          setSelectedJobQueue(value)
                        }
                        field.onChange(value)
                      }}
                      isDisabled={isJobQueueFieldsReadOnly || queueOptions.length === 0}
                      labelText={intl.formatMessage(EditJobQueueFieldsMessages.selectJobQueueLabel)}
                      placeholderText={intl.formatMessage(EditJobQueueFieldsMessages.selectJobQueuePlaceholderLabel)}
                      options={queueOptions}
                      isFullWidth
                    />
                  )
                }}
              />

              <ButtonLink
                url={getCreateJobQueuePagePath(organizationName, projectName)}
                isCompact
                variant="green-filled-light"
              >
                {intl.formatMessage(EditJobQueueFieldsMessages.createJobQueueVButtonLabel)}
              </ButtonLink>
            </div>

            <div className="mb-6">
              <Controller
                name={EditContainerGroupField.JOB_QUEUE_PORT}
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <TextField
                      {...field}
                      {...fieldState}
                      defaultValue={field.value}
                      helperText={intl.formatMessage(EditJobQueueFieldsMessages.portHelperText)}
                      label={intl.formatMessage(EditJobQueueFieldsMessages.portLabel)}
                      placeholder="1"
                      isDisabled={isJobQueueFieldsReadOnly}
                      isFullWidth
                      onChange={(value) => {
                        field.onChange(Number(value))
                      }}
                      type="number"
                    />
                  )
                }}
              />
            </div>

            <Controller
              name={EditContainerGroupField.JOB_QUEUE_PATH}
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    {...field}
                    {...fieldState}
                    defaultValue={field.value}
                    helperText={intl.formatMessage(EditJobQueueFieldsMessages.pathHelperText)}
                    label={intl.formatMessage(EditJobQueueFieldsMessages.pathLabel)}
                    placeholder={intl.formatMessage(EditJobQueueFieldsMessages.pathPlaceholderText)}
                    isDisabled={isJobQueueFieldsReadOnly}
                    isFullWidth
                    type="text"
                  />
                )
              }}
            />
          </div>
        </form>
      </SidePanelModal>
    </div>
  )
}
