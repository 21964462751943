import type { FunctionComponent } from 'react'
import { useCallback, useEffect } from 'react'
import { LoadingPage } from '../../components/page/LoadingPage'
import { trackMixpanelPageViewedEvent } from '../../features/analytics/analyticsSlice'
import { deleteAccount } from '../../features/deleteAccount/deleteAccountSlice'
import { selectIsLeftColumnShowing } from '../../features/navigationBar/navigationBarSelectors'
import { setLeftColumnShowing } from '../../features/navigationBar/navigationBarSlice'
import { selectProfile } from '../../features/profile/profileSelectors'
import { selectRequestStatusIsPending } from '../../features/requestStatus/requestStatusSelectors'
import { accountPageRoutePath } from '../../routes/routePaths'
import { useAppDispatch, useAppSelector } from '../../store'
import { AccountSettingsPage } from './AccountSettingsPage'

export const ConnectedAccountSettingsPage: FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const profile = useAppSelector(selectProfile)
  const isDeleteAccountRequestPending = useAppSelector((state) => selectRequestStatusIsPending(state, 'deleteAccount'))
  const isLeftColumnOpen = useAppSelector(selectIsLeftColumnShowing)

  const handleDeleteAccount = (password: string) => {
    dispatch(deleteAccount({ password }))
  }

  const handleCloseLeftNavBar = useCallback(() => {
    dispatch(setLeftColumnShowing({ showing: false }))
  }, [dispatch])

  useEffect(() => {
    dispatch(trackMixpanelPageViewedEvent({ path: accountPageRoutePath }))
  }, [dispatch])

  return !profile ? (
    <LoadingPage />
  ) : (
    <AccountSettingsPage
      email={profile.email}
      createdDate={profile.createTime}
      isDeleteAccountPending={isDeleteAccountRequestPending}
      isLeftColumnOpen={isLeftColumnOpen}
      onCloseLeftDrawer={handleCloseLeftNavBar}
      onDeleteAccount={handleDeleteAccount}
    />
  )
}
