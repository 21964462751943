import type { FunctionComponent } from 'react'
import { ThreeColumnLayout } from '../../layouts/threeColumnLayout'
import { CreateProjectForm } from './components/CreateProjectForm'
import { CreateProjectSteps } from './components/CreateProjectSteps'

export interface CreateProjectPageProps {
  /** The project name. */
  projectName: string
  /** The organization display name. */
  organizationDisplayName: string
  /** The flag indicating if the left column is showing */
  isLeftColumnOpen: boolean
  /** The flag indicating that the request to create an project is pending. */
  isCreateProjectPending: boolean
  /** The callback executed when the user closes the left drawer */
  onCloseLeftDrawer: () => void
  /** A callback that when executed setups project. */
  onCreateProject: (projectName: string) => void
}

export const CreateProjectPage: FunctionComponent<CreateProjectPageProps> = ({
  isCreateProjectPending,
  isLeftColumnOpen,
  onCloseLeftDrawer,
  onCreateProject,
  organizationDisplayName,
  projectName,
}) => (
  <ThreeColumnLayout
    isLeftColumnOpen={isLeftColumnOpen}
    LeftColumn={
      <CreateProjectSteps
        currentStep="setupYourProject"
        projectDisplayName={projectName}
        organizationDisplayName={organizationDisplayName}
      />
    }
    MainColumn={<CreateProjectForm isCreateProjectPending={isCreateProjectPending} onCreateProject={onCreateProject} />}
    onCloseLeftDrawer={onCloseLeftDrawer}
  />
)
