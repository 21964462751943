import { defineMessages } from 'react-intl'

export const GeneralErrorLeavingTeamContentMessages = defineMessages({
  body: {
    defaultMessage: 'You were unable to leave your team. Try again and if this issue persists {contact_support}.',
    id: 'hkp24uf5',
    description: 'The body for the error Team left notification.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'pIQEUfA+',
    description: 'The text shown for the contact support link.',
  },
  title: {
    defaultMessage: 'Error leaving team',
    id: 'JHTBqfaZ',
    description: 'The title for the error Team left notification.',
  },
})
