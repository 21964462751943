import { defineMessages } from 'react-intl'

export const InstanceDetailsMainContentMessages = defineMessages({
  backButtonLabel: {
    defaultMessage: 'Back to {deployment_display_name}',
    id: 'Hfl9ENTx',
    description: 'The back label for the instance details page.',
  },
  logsLabel: {
    defaultMessage: 'LOGS',
    id: 'o5KSChEE',
    description: 'The logs label for the instance details page.',
  },
  machineIdLabel: {
    defaultMessage: 'Machine ID: {machine_id}',
    id: 'mAKqx1Zn',
    description: 'The machine ID label for the instance details page.',
  },
  runningStatusLabel: {
    defaultMessage: 'RUNNING',
    id: 'lGCvIVyx',
    description: 'The running status label for the instance details page.',
  },
  restartButtonLabel: {
    defaultMessage: 'Restart',
    id: '7ixHmVLw',
    description: 'Restart label for the button.',
  },
  recreateButtonLabel: {
    defaultMessage: 'Recreate',
    id: 'jcFBwGOE',
    description: 'Recreate label for the button.',
  },
  reallocateButtonLabel: {
    defaultMessage: 'Reallocate',
    id: 'i+tU0xzh',
    description: 'Reallocate label for the button.',
  },
  learnMoreButtonLabel: {
    defaultMessage: 'Learn More',
    id: 'kyAzGtV3',
    description: 'Learn More label for the button.',
  },
  actionsTitle: {
    defaultMessage: 'Container Actions',
    id: 'VwB4aIFl',
    description: 'The title for the actions block.',
  },
  title: {
    defaultMessage: 'Instance Details',
    id: 'CT6pyREu',
    description: 'The title for the instance details page.',
  },
  viewLogsButtonLabel: {
    defaultMessage: 'View Logs',
    id: 'PF+iF6jk',
    description: 'View Logs label for the button.',
  },
})
