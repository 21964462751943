import { defineMessages } from 'react-intl'

export const TeamPageMainContentMessages = defineMessages({
  inviteNewTeamMemberButtonLabel: {
    defaultMessage: 'Invite New Team Member',
    id: 'cWMxoK4d',
    description: 'The label for the button to invite a new team member on the Team Page.',
  },
  returnToPreviousPageLinkText: {
    defaultMessage: 'Return to previous page',
    id: 'BQAF8CIV',
    description: 'The text for the link that returns the user back to the previous page they were viewing.',
  },
  searchFilterByLabel: {
    defaultMessage: 'Filter by',
    id: '49Ctgsu1',
    description: 'The Team Page search filter option label for filtering the search query.',
  },
  searchFilterByInvitedOption: {
    defaultMessage: 'Invited',
    id: 'FO6inm+j',
    description: 'The Team Page search filter option for filtering the search query by members who have been invited.',
  },
  searchFilterByJoinedOption: {
    defaultMessage: 'Joined',
    id: 'QLqd855s',
    description:
      'The Team Page search filter option for filtering the search query by members who have already joined the organization.',
  },
  searchInputPlaceholder: {
    defaultMessage: 'Find a Team Member',
    id: 'WbXuRvFl',
    description: 'The search input placeholder for the Team Page.',
  },
  searchSortByAToZOption: {
    defaultMessage: 'A-Z',
    id: 'TWteeca4',
    description: 'The Team Page search filter option for sorting the search query from A to Z.',
  },
  searchSortByJoinedDateEarliestOption: {
    defaultMessage: 'Join Date (Earliest)',
    id: '59SMJzJj',
    description:
      'The Team Page search filter option for sorting the search query by their joined date from earliest to latest.',
  },
  searchSortByJoinedDateLatestOption: {
    defaultMessage: 'Join Date (Latest)',
    id: 'tmydXlTU',
    description:
      'The Team Page search filter option for sorting the search query by their joined date from latest to earliest.',
  },
  searchSortByZToAOption: {
    defaultMessage: 'Z-A',
    id: '+4eAxtvG',
    description: 'The Team Page search filter option for sorting the search query from Z to A.',
  },
  searchSortByLabel: {
    defaultMessage: 'Sort by',
    id: '3DGZwPIl',
    description: 'The Team Page search filter option label for sorting the search query.',
  },
  title: {
    defaultMessage: '{organization_display_name} Team',
    id: 'rH1EhXo9',
    description: 'The title of for the organization Team Page.',
  },
})
