import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { ContainerGroupsImageSourceMessages } from './messages'

interface ContainerGroupsImageSourceProps {
  /** The container groups card container image. */
  image: string
}

export const ContainerGroupsImageSource: FunctionComponent<ContainerGroupsImageSourceProps> = ({ image }) => {
  const intl = useIntl()

  return (
    <div className="mb-6 flex flex-col">
      <div className="flex items-baseline">
        <p className="mr-2 font-bold">{intl.formatMessage(ContainerGroupsImageSourceMessages.imageSourceLabel)}</p>
      </div>
      <p className="text-sm text-neutral-70">{image}</p>
    </div>
  )
}
