import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { Project } from '@saladtechnologies/openapi-cloud-portal-browser'
import { RequestStatus } from '../../models'

export interface EditProjectState {
  editProjectRequestStatus: RequestStatus
  getEditProjectsDataRequestStatus: RequestStatus
  getProjectRequestStatus: RequestStatus
  project: Project | undefined
}

const initialState: EditProjectState = {
  editProjectRequestStatus: RequestStatus.Idle,
  getEditProjectsDataRequestStatus: RequestStatus.Idle,
  getProjectRequestStatus: RequestStatus.Idle,
  project: undefined,
}

export const editProjectSlice = createSlice({
  name: 'editProject',
  initialState,
  reducers: {
    editProject(
      _state,
      _action: PayloadAction<{
        organizationName: string
        projectName: string
        newProjectDisplayName: string
      }>,
    ) {},
    getEditProjectsPageData(_state, _action: PayloadAction<{ organizationName: string }>) {},
    getProjectToEdit(_state, _action: PayloadAction<{ organizationName: string; projectName: string }>) {},
    setEditProjectRequestStatus(state, action: PayloadAction<{ status: RequestStatus }>) {
      state.editProjectRequestStatus = action.payload.status
    },
    setGetEditProjectsDataRequestStatus(state, action: PayloadAction<{ status: RequestStatus }>) {
      state.getEditProjectsDataRequestStatus = action.payload.status
    },
    setGetProjectToEditRequestStatus(state, action: PayloadAction<{ status: RequestStatus }>) {
      state.getProjectRequestStatus = action.payload.status
    },
    setProjectToEdit(state, action: PayloadAction<{ project: Project }>) {
      state.project = action.payload.project
    },
  },
})

export const {
  editProject,
  getEditProjectsPageData,
  getProjectToEdit,
  setEditProjectRequestStatus,
  setGetEditProjectsDataRequestStatus,
  setGetProjectToEditRequestStatus,
  setProjectToEdit,
} = editProjectSlice.actions
