import type { CSSProperties } from 'react'

/**
 * This provides the styles for the progress bar which account for a visual bug where the progress bar would overlap the
 * rounded border for percentages less than 8%. This is a temporary fix (hack) until we can figure out a better
 * solution.
 *
 * @param percentage The percentage completed of the progress bar.
 */
export const getBackgroundProgressBarStyles = (percentage: number): CSSProperties => {
  const backgroundWidth = `${percentage}%`

  let scaleValue = 0.5
  switch (percentage) {
    case 2:
      scaleValue = 0.65
      break
    case 3:
      scaleValue = 0.7
      break
    case 4:
      scaleValue = 0.75
      break
    case 5:
      scaleValue = 0.85
      break
    case 6:
      scaleValue = 0.9
      break
    case 7:
      scaleValue = 0.95
      break
  }

  if (percentage < 8) {
    return {
      width: backgroundWidth,
      transform: `scale(${scaleValue})`,
    }
  }

  return {
    width: backgroundWidth,
  }
}
