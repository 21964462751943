import type { FunctionComponent } from 'react'
import { LeftNavigationBar } from '../../components/LeftNavigationBar'
import { ThreeColumnLayout } from '../../layouts/threeColumnLayout'
import { EditOrganizationMainContent } from './components/EditOrganizationMainContent'

export interface EditOrganizationPageProps {
  /** The flag indicating that the request to edit the organization is pending. */
  isEditOrganizationPending: boolean
  /** The flag indicating if the left column is showing */
  isLeftColumnOpen: boolean
  /** The callback executed when the user closes the left drawer */
  onCloseLeftDrawer: () => void
  /** The callback executed when the user clicks the contact support button. */
  onEditOrganization: (displayName: string) => void
  /** The organization display name. */
  organizationDisplayName: string
  /** The organization name. */
  organizationName: string
}

export const EditOrganizationPage: FunctionComponent<EditOrganizationPageProps> = ({
  isEditOrganizationPending,
  isLeftColumnOpen,
  onCloseLeftDrawer,
  onEditOrganization,
  organizationDisplayName,
  organizationName,
}) => {
  return (
    <ThreeColumnLayout
      isLeftColumnOpen={isLeftColumnOpen}
      LeftColumn={<LeftNavigationBar />}
      MainColumn={
        <EditOrganizationMainContent
          isSubmitPending={isEditOrganizationPending}
          onEditOrganization={onEditOrganization}
          organizationDisplayName={organizationDisplayName}
          organizationName={organizationName}
        />
      }
      onCloseLeftDrawer={onCloseLeftDrawer}
    />
  )
}
