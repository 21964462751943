import { defineMessages } from 'react-intl'

export const InferenceEndpointsMarketplaceMainContentMessages = defineMessages({
  searchPlaceholder: {
    defaultMessage: 'Find an inference endpoint',
    id: '8/uUpYas',
    description: 'The placeholder for the search input.',
  },
  title: {
    defaultMessage: 'Inference Endpoints Marketplace',
    id: '4qbDY9Im',
    description: 'The title for the inference endpoints marketplace page.',
  },
})
