import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { Recipe } from '@saladtechnologies/openapi-cloud-portal-browser'

export interface RecipeMarketplaceState {
  recipes: Recipe[]
}

const initialState: RecipeMarketplaceState = {
  recipes: [],
}

export const recipeMarketplaceSlice = createSlice({
  name: 'recipeMarketplace',
  initialState,
  reducers: {
    getRecipeMarketplacePageData(_state, _action: PayloadAction<{ organizationName: string; projectName: string }>) {},
    setRecipes(state, action: PayloadAction<{ recipes: Recipe[] }>) {
      state.recipes = action.payload.recipes
    },
  },
})

export const { getRecipeMarketplacePageData, setRecipes } = recipeMarketplaceSlice.actions
