import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { EmptyResult } from '../../../components/page/EmptyResult'
import { getCreateProjectPagePath } from '../../../routes/routes-utils'
import { NoProjectsAvailableMainContentMessages } from '../messages'

interface NoProjectsAvailableMainContentProps {
  /** The organization name. */
  organizationName: string
}

export const NoProjectsAvailableMainContent: FunctionComponent<NoProjectsAvailableMainContentProps> = ({
  organizationName,
}) => {
  const intl = useIntl()
  return (
    <EmptyResult
      buttonIconClassName="fas fa-plus"
      buttonLabel={intl.formatMessage(NoProjectsAvailableMainContentMessages.createProjectButtonLabel)}
      buttonPath={getCreateProjectPagePath(organizationName)}
      mainMessage={intl.formatMessage(NoProjectsAvailableMainContentMessages.description)}
    />
  )
}
