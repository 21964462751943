import type { FunctionComponent } from 'react'
import { Spinner } from '../../Spinner'

export const LoadingPage: FunctionComponent = () => {
  return (
    <div className="fixed flex h-screen w-full flex-col items-center justify-center">
      <Spinner size="lg" />
    </div>
  )
}
