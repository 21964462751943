import { RequestStatus } from '../../models'
import type { AppState } from '../../store'

export const selectEditRecipeDeploymentIsPending = (state: AppState) =>
  state.editRecipeDeployment.editRecipeDeploymentRequestStatus === RequestStatus.Loading

export const selectRecipeDeploymentToEdit = (state: AppState) => state.editRecipeDeployment.recipeDeployment

export const selectEditRecipeDeploymentCostPerHour = (state: AppState) => state.editRecipeDeployment.costPerHour

export const selectRecipeDeploymentToEditIsPending = (state: AppState) =>
  state.editRecipeDeployment.getRecipeDeploymentToEditRequestStatus === RequestStatus.Loading

export const selectIsRecipeInstancesQuotaExceeded = (state: AppState) =>
  state.editRecipeDeployment.isRecipeInstancesQuotaExceeded
