import type { FieldErrors, FieldValues } from 'react-hook-form'
import { CreateContainerGroupField } from '../../models'

export const checkIfExternalLoggingServiceFieldsHaveErrors = (errors: FieldErrors<FieldValues>): boolean => {
  return (
    (errors[CreateContainerGroupField.AXIOM_API_KEY] ||
      errors[CreateContainerGroupField.AXIOM_DATASET_NAME] ||
      errors[CreateContainerGroupField.AXIOM_HOST] ||
      errors[CreateContainerGroupField.DATADOG_API] ||
      errors[CreateContainerGroupField.DATADOG_HOST] ||
      errors[CreateContainerGroupField.DATADOG_TAGS] ||
      errors[CreateContainerGroupField.HTTP_COMPRESSION] ||
      errors[CreateContainerGroupField.HTTP_FORMAT] ||
      errors[CreateContainerGroupField.HTTP_HEADERS] ||
      errors[CreateContainerGroupField.HTTP_HOST] ||
      errors[CreateContainerGroupField.HTTP_PASSWORD] ||
      errors[CreateContainerGroupField.HTTP_PATH] ||
      errors[CreateContainerGroupField.HTTP_PORT] ||
      errors[CreateContainerGroupField.HTTP_USER] ||
      errors[CreateContainerGroupField.NEW_RELIC_HOST] ||
      errors[CreateContainerGroupField.NEW_RELIC_INGESTION_KEY] ||
      errors[CreateContainerGroupField.SPLUNK_HOST] ||
      errors[CreateContainerGroupField.SPLUNK_TOKEN] ||
      errors[CreateContainerGroupField.TCP_HOST] ||
      errors[CreateContainerGroupField.TCP_PORT]) !== undefined
  )
}

export const externalLoggingFieldsList: string[] = [
  CreateContainerGroupField.AXIOM_API_KEY,
  CreateContainerGroupField.AXIOM_DATASET_NAME,
  CreateContainerGroupField.AXIOM_HOST,
  CreateContainerGroupField.DATADOG_API,
  CreateContainerGroupField.DATADOG_HOST,
  CreateContainerGroupField.DATADOG_TAGS,
  CreateContainerGroupField.HTTP_COMPRESSION,
  CreateContainerGroupField.HTTP_FORMAT,
  CreateContainerGroupField.HTTP_HEADERS,
  CreateContainerGroupField.HTTP_HOST,
  CreateContainerGroupField.HTTP_PASSWORD,
  CreateContainerGroupField.HTTP_PATH,
  CreateContainerGroupField.HTTP_PORT,
  CreateContainerGroupField.HTTP_USER,
  CreateContainerGroupField.NEW_RELIC_HOST,
  CreateContainerGroupField.NEW_RELIC_INGESTION_KEY,
  CreateContainerGroupField.SPLUNK_HOST,
  CreateContainerGroupField.SPLUNK_TOKEN,
  CreateContainerGroupField.TCP_HOST,
  CreateContainerGroupField.TCP_PORT,
]
