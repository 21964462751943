import { defineMessages } from 'react-intl'

export const RecipeMarketplaceFailedErrorContentMessages = defineMessages({
  body: {
    defaultMessage:
      'We are unable to retrieve recipes for the recipe marketplace at this time. Try again and if this issue persists {contact_support}.',
    id: 'BeRivWoR',
    description:
      'The description shown in a toast notification message shown when we encounter an error on their request to load the recipe marketplace.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'YkJ/kcIj',
    description:
      'The text shown for the contact support link in the failed to load the recipe marketplace error toast notification.',
  },
  title: {
    defaultMessage: 'Unable to retrieve recipes',
    id: '6ffqRWyO',
    description:
      'The toast notification title shown when we encounter an error on their request to load the recipes for the recipe marketplace.',
  },
})
