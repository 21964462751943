import { createSlice } from '@reduxjs/toolkit'
import type { ContainerGroupStatus } from '@saladtechnologies/openapi-cloud-portal-browser'
import type { DeploymentInstance } from '../containerGroupInstanceTable/models'
import {
  configureRecipeDeploymentInstances,
  convertContainerGroupImageSizeToMB,
  getAllocatedInstances,
} from '../containerGroupInstanceTable/utils'
import {
  deleteRecipeDeployment,
  setRecipeDeployment,
  setRecipeDeploymentInstances,
  stopRecipeDeployment,
} from '../recipeDeploymentDetails/recipeDeploymentDetailsSlice'

interface RecipeDeploymentInstanceTableState {
  recipeDeploymentImageSize: number | undefined
  recipeDeploymentReplicaCount: number
  recipeDeploymentReplicasRunning: number
  recipeDeploymentStartTime: Date | null
  recipeDeploymentStatus: ContainerGroupStatus | undefined
  instances: DeploymentInstance[]
}

const initialState: RecipeDeploymentInstanceTableState = {
  recipeDeploymentImageSize: undefined,
  recipeDeploymentReplicaCount: 1,
  recipeDeploymentReplicasRunning: 0,
  recipeDeploymentStatus: undefined,
  recipeDeploymentStartTime: null,
  instances: [],
}

export const recipeDeploymentInstanceTableSlice = createSlice({
  name: 'recipeDeploymentInstanceTable',
  initialState,
  reducers: {
    getUpToDateRecipeDeploymentInstances: (state) => {
      if (state.recipeDeploymentStatus === 'running') {
        state.instances = state.instances.map((instance) => {
          return {
            ...instance,
            updateCount: instance.updateCount ? instance.updateCount + 1 : 1,
          }
        })
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setRecipeDeploymentInstances, (state, action) => {
      const recipeDeploymentInstances = action.payload.recipeDeploymentInstances
      const recipeDeploymentStartTime = state.recipeDeploymentStartTime || new Date()
      const recipeDeploymentReplicaCount = state.recipeDeploymentReplicaCount
      const recipeDeploymentReplicasRunning = state.recipeDeploymentReplicasRunning
      const recipeDeploymentStatus = state.recipeDeploymentStatus

      if (recipeDeploymentStatus === 'pending' || recipeDeploymentStatus === undefined) {
        state.instances = []
        return
      }

      if (recipeDeploymentStatus === 'stopped') {
        if (recipeDeploymentReplicasRunning === 0) {
          state.instances = []
          return
        }

        state.instances = state.instances.map((instance) => {
          return {
            ...instance,
            status: 'stopping',
          }
        })
      }

      if (state.instances.length === 0 && recipeDeploymentInstances.length === 0) {
        state.instances = getAllocatedInstances(recipeDeploymentStartTime, recipeDeploymentReplicaCount)
        return
      }

      const allocatedInstancesNeeded = recipeDeploymentReplicaCount - recipeDeploymentInstances.length
      const allocatedInstances = getAllocatedInstances(recipeDeploymentStartTime, allocatedInstancesNeeded)
      const recipeDeploymentImageSizeInMB = convertContainerGroupImageSizeToMB(state.recipeDeploymentImageSize)
      const deploymentInstances = configureRecipeDeploymentInstances(
        recipeDeploymentInstances,
        recipeDeploymentImageSizeInMB,
      )

      state.instances = [...deploymentInstances, ...allocatedInstances]
    })

    builder.addCase(setRecipeDeployment, (state, action) => {
      state.recipeDeploymentReplicaCount = action.payload.recipeDeployment.replicas
      state.recipeDeploymentStartTime = action.payload.recipeDeployment.currentState.startTime
      state.recipeDeploymentImageSize = action.payload.recipeDeployment.size
      state.recipeDeploymentStatus = action.payload.recipeDeployment.currentState.status
      state.recipeDeploymentReplicasRunning =
        action.payload.recipeDeployment.currentState.instanceStatusCounts.runningCount
    })

    builder.addCase(stopRecipeDeployment, (state) => {
      const deploymentInstancesWithoutAllocating = state.instances.filter(
        (instance) => instance.status !== 'allocating',
      )
      state.instances = deploymentInstancesWithoutAllocating.map((instance) => {
        return {
          ...instance,
          status: 'stopping',
        }
      })

      state.recipeDeploymentStartTime = null
    })

    builder.addCase(deleteRecipeDeployment, (state) => {
      state.recipeDeploymentReplicaCount = 1
      state.recipeDeploymentStartTime = null
      state.instances = []
      state.recipeDeploymentImageSize = undefined
      state.recipeDeploymentStatus = undefined
    })
  },
})

export const { getUpToDateRecipeDeploymentInstances } = recipeDeploymentInstanceTableSlice.actions
