import type { FunctionComponent } from 'react'
import type { Control, FieldValues } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { TextField } from '../../../../../components/TextField'
import { HealthProbeComponentMessages } from '../../../messages'
import type { CreateContainerGroupValues } from '../../../models'

interface HealthProbeTimeoutSecondsInputProps {
  /** The control for the create container group react hook form. */
  control: Control<FieldValues, CreateContainerGroupValues>
  /** The error flag for the input */
  hasError: boolean
  /** The disabled flag of the input field */
  isDisabled: boolean
  /** The name given to the controller */
  name: string
}

export const HealthProbeTimeoutSecondsInput: FunctionComponent<HealthProbeTimeoutSecondsInputProps> = ({
  control,
  hasError,
  isDisabled,
  name,
}) => {
  const intl = useIntl()
  const max = 10000
  const min = 1

  return (
    <div className="mb-10">
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState }) => {
          return (
            <TextField
              {...field}
              {...fieldState}
              defaultValue={field.value}
              helperText={
                hasError
                  ? intl.formatMessage(HealthProbeComponentMessages.timeoutSecondsErrorText, {
                      max,
                      min,
                    })
                  : intl.formatMessage(HealthProbeComponentMessages.timeoutSecondsHelperText)
              }
              isDisabled={isDisabled}
              isFullWidth
              label={intl.formatMessage(HealthProbeComponentMessages.timeoutSecondsLabel)}
              max={max}
              min={min}
              type="number"
            />
          )
        }}
      />
    </div>
  )
}
