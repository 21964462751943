import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export interface NavigationBarState {
  isLeftColumnShowing: boolean
}

const initialState: NavigationBarState = {
  isLeftColumnShowing: false,
}

export const navigationBarSlice = createSlice({
  name: 'navigationBar',
  initialState,
  reducers: {
    setLeftColumnShowing(state, action: PayloadAction<{ showing: boolean }>) {
      state.isLeftColumnShowing = action.payload.showing
    },
  },
})

export const { setLeftColumnShowing } = navigationBarSlice.actions
