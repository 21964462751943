import { defineMessages } from 'react-intl'

export const DeleteJobQueueGeneralErrorContentMessages = defineMessages({
  title: {
    defaultMessage: 'Error deleting job queue',
    id: '4IN9wkry',
    description:
      'The toast notification title shown when we encounter an error on their request to delete a job queue.',
  },
  body: {
    defaultMessage: 'We were unable to delete your job queue. Try again and if this issue persists {contact_support}',
    id: 'K7gFXgxL',
    description:
      'The description shown in a toast notification message shown when we encounter an error on their request to delete a job queue.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'RINcPb45',
    description: 'The text shown for the contact support link in the edit job queue error toast notification.',
  },
})
