import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { Button } from '../../../../components/Button'
import { ButtonMessages } from '../../../../components/Button/messages'
import { Modal } from '../../../../components/block/Modal'
import type { JobQueueTableConnections } from '../../../../features/jobQueueConnectionsTable/models'
import { DeleteJobQueueModalMessages } from '../../messages'
import { getConnectedContainerGroupList } from './utils'

export interface DeleteJobQueueModalProps {
  /** The job queue connections */
  connections: JobQueueTableConnections
  /** The display name of the job queue the user wants to delete. */
  displayName: string
  /** The flag indicating that the request to delete the job queue is pending. */
  isDeleteJobQueuePending: boolean
  /** The callback executed when the user clicks the `Cancel` button. */
  onCancel: () => void
  /** The callback executed when the user clicks the `Delete` button. */
  onDeleteJobQueue: () => void
}

export const DeleteJobQueueModal: FunctionComponent<DeleteJobQueueModalProps> = ({
  connections,
  displayName,
  isDeleteJobQueuePending,
  onCancel,
  onDeleteJobQueue,
}) => {
  const intl = useIntl()
  const hasConnectedContainerGroups = connections.length > 0

  return (
    <Modal
      onClose={onCancel}
      title={intl.formatMessage(DeleteJobQueueModalMessages.title, {
        display_name: displayName,
      })}
    >
      <h1 className="mb-8 text-3xl font-bold">
        {intl.formatMessage(DeleteJobQueueModalMessages.title, {
          display_name: displayName,
        })}
      </h1>
      <p className="mb-8">
        {hasConnectedContainerGroups
          ? intl.formatMessage(DeleteJobQueueModalMessages.descriptionWithConnectedContainerGroups, {
              connected_container_groups: getConnectedContainerGroupList(connections),
            })
          : intl.formatMessage(DeleteJobQueueModalMessages.description)}
      </p>
      <div className="flex max-w-md gap-6">
        <Button variant="green-outlined" onClick={onCancel} isFullWidth>
          {intl.formatMessage(DeleteJobQueueModalMessages.cancelButtonLabel)}
        </Button>
        <Button
          variant="red-filled"
          onClick={onDeleteJobQueue}
          isLoading={isDeleteJobQueuePending}
          isFullWidth
          countdownSeconds={5}
        >
          {intl.formatMessage(ButtonMessages.deleteButtonLabel)}
        </Button>
      </div>
    </Modal>
  )
}
