import type { ReactNode } from 'react'
import type { IntlShape } from 'react-intl'
import type { ToastNotificationType } from '../../models'
import { GenericStripeFormErrorMessages } from './messages'

/**
 * Retrieves the content needed for an error `ToastNotification` that is shown when the user encounters Stripe form
 * error when attempting to add new payment method.
 *
 * @param intl The `IntlShape` object needed to format the messages.
 * @param errorMessage The add new payment method Stripe form error message.
 */
export const getGenericStripeFormError = (
  intl: IntlShape,
  errorMessage: string,
): {
  body: ReactNode
  type: ToastNotificationType
  title: string
} => {
  return {
    body: intl.formatMessage(GenericStripeFormErrorMessages.body, { error_message: errorMessage }),
    type: 'error',
    title: intl.formatMessage(GenericStripeFormErrorMessages.title),
  }
}
