import { defineMessages } from 'react-intl'

export const HealthProbeHeadersSidePanelModalMessages = defineMessages({
  addHeadersButtonLabel: {
    defaultMessage: 'Add Headers',
    id: 'PqU4fRQc',
    description: 'The label for the Add Headers button.',
  },
  backButtonLabel: {
    defaultMessage: 'Back',
    id: '2awDgewe',
    description: 'The label for the Back button.',
  },
  headerInputTitle: {
    defaultMessage: 'Header {count}',
    id: 'RnggZ1Q5',
    description: 'The title for the Header input card.',
  },
  headerNameFieldLabel: {
    defaultMessage: 'Name',
    id: 'st94GbXd',
    description: 'The label for the headers name input field.',
  },
  headerValueFieldLabel: {
    defaultMessage: 'Value',
    id: 'pBteKJsB',
    description: 'The label for the headers value input field.',
  },
  maxCharacterErrorText: {
    defaultMessage: 'Must not be longer than 128 characters',
    id: 'kgP+tCPs',
    description: 'The error text shown for the headers fields when the max character limit is exceeded.',
  },
  nameRequiredErrorText: {
    defaultMessage: 'Name is required',
    id: 'rnQp9AX/',
    description: 'The error text shown for the headers fields when the name field is not completed.',
  },
  removeButtonLabel: {
    defaultMessage: 'Remove',
    id: 'nsWp0jY5',
    description: 'The label for the Remove button.',
  },
  title: {
    defaultMessage: 'Headers for {health_probe}',
    id: 'fUXiL9ou',
    description: 'The title for the headers side panel modal form.',
  },
  valueRequiredErrorText: {
    defaultMessage: 'Value is required',
    id: '7Joj41g5',
    description: 'The error text shown for the headers fields when the value field is not completed.',
  },
})
