import type { SyntheticEvent } from 'react'

export const contactSupportViaHelpScout = (
  trackHelpScoutFailedToOpenMixpanelEvent?: () => void,
  event?: SyntheticEvent,
) => {
  event?.preventDefault()
  const beacon = window.Beacon
  if (beacon && beacon('info') !== undefined) {
    beacon('open')
  } else {
    trackHelpScoutFailedToOpenMixpanelEvent && trackHelpScoutFailedToOpenMixpanelEvent()
    window.location.href = 'mailto:cloud@salad.com'
  }
}
