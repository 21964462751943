import { defineMessages } from 'react-intl'

export const CreateProjectWithNonUniqueNameContentMessages = defineMessages({
  body: {
    defaultMessage: 'Project names must be unique. {project_name} has been used in your organization.',
    id: 'O20x/udr',
    description:
      'The description shown in a toast notification message shown when we encounter an error on their request to create the project with the name that has already been taken.',
  },
  title: {
    defaultMessage: 'Please choose another project name',
    id: 'J3T8YDQS',
    description:
      'The toast notification title shown when we encounter an error on their request to create the project with the name that has already been taken.',
  },
})
