import { defineMessages } from 'react-intl'

export const DatadogInputFieldsMessages = defineMessages({
  addTagButtonLabel: {
    defaultMessage: 'Add Tag',
    id: 'MK6apEUH',
    description: 'The button label for the Datadog add tag button.',
  },
  addTagsTitle: {
    defaultMessage: 'Add Tags',
    id: 'Q+ib1W24',
    description: 'The title for the Datadog add tags modal.',
  },
  apiKeyLabel: {
    defaultMessage: 'API Key',
    id: 'YhvHSlFr',
    description: 'The label for the Datadog API key input field.',
  },
  apiKeyRequiredText: {
    defaultMessage: 'API Key is required',
    id: 'diT4tGBO',
    description: 'The required text for the Datadog API key input field.',
  },
  backButtonLabel: {
    defaultMessage: 'Back',
    id: 'dTYoEdDO',
    description: 'The button label for the Datadog Tags back button.',
  },
  configureButtonLabel: {
    defaultMessage: 'Configure',
    id: 'qP30ebz1',
    description: 'The button label for the Datadog Tags configure button.',
  },
  datadogTagsNotSetLabel: {
    defaultMessage: 'No datadog tags set',
    id: 'QUCgvnnQ',
    description: 'The label for the datadog tags fields when it is not set.',
  },
  editTagsLabel: {
    defaultMessage: 'Edit',
    id: '211ws4TR',
    description: 'The text for the Datadog edit tags label.',
  },
  hostLabel: {
    defaultMessage: 'Host',
    id: 'W4dgbIUT',
    description: 'The label for the Datadog host input field.',
  },
  hostValidationText: {
    defaultMessage: 'Cannot begin with http:// or https://',
    id: 'rcwgAcdi',
    description: 'The validation text for the Datadog host input field.',
  },
  hostRequiredText: {
    defaultMessage: 'Host is required',
    id: 'MWlecYGV',
    description: 'The required text for the Datadog host input field.',
  },
  nameLabel: {
    defaultMessage: 'Name',
    id: '6dmqvXGF',
    description: 'The label for the name input of the datadog tags field.',
  },
  nameMustBeUnique: {
    defaultMessage: 'All names provided must be unique.',
    id: 'xd80thEy',
    description: 'The error text for the name input field of the datadog tags field when it is not unique.',
  },
  nameRequiredText: {
    defaultMessage: 'Name is required',
    id: 'ts7kbdC/',
    description: 'The required text for the name input field of the datadog tags field.',
  },
  removeTagButtonLabel: {
    defaultMessage: 'Remove Tag',
    id: 'ekpJdFJU',
    description: 'The button label for the Datadog remove tag button.',
  },
  tagInputsLabel: {
    defaultMessage: 'Tag {count}',
    id: 'kdT0NiBc',
    description: 'The label for the tags key value input card.',
  },
  tagsLabel: {
    defaultMessage: 'Tags',
    id: 'CrLNpgPE',
    description: 'The label for the Datadog tags input field.',
  },
  valueLabel: {
    defaultMessage: 'Value',
    id: '2ka1lWQ3',
    description: 'The label for the value input of the datadog tags field.',
  },
  valueRequiredText: {
    defaultMessage: 'Value is required',
    id: '28aTSx5x',
    description: 'The required text for the value input field of the datadog tags field.',
  },
  viewDatadogTagsLinkText: {
    defaultMessage: 'View {count, plural, one {# Datadog Tag} other {# Datadog Tags}} ',
    id: 'SePvzoCR',
    description: 'The text for the link that opens the Datadog Tags side panel.',
  },
})
