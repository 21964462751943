import { defineMessages } from 'react-intl'

export const TeamMemberCardMessages = defineMessages({
  invitationDate: {
    defaultMessage: 'on {invitation_date}',
    id: 'HwnIogJU',
    description: 'The details on when the invitation was sent.',
  },
  invited: {
    defaultMessage: 'Invited',
    id: 'zGhd05mk',
    description: 'The status label that shows when a user has been invited to a team.',
  },
  joined: {
    defaultMessage: 'Joined',
    id: 'zJqo/ETm',
    description: 'The status label that shows when a user has already joined a team.',
  },
  joinedDate: {
    defaultMessage: 'on {joined_date}',
    id: '5fLj/lpp',
    description: 'The details on when the member joined the team.',
  },
  leaveTeam: {
    defaultMessage: 'Leave Team',
    id: 'TJAKLTuG',
    description: 'The button label for when the user is leaving the team.',
  },
  removeMemberButtonLabel: {
    defaultMessage: 'Remove Member',
    id: '5KzKDXTm',
    description: 'The button label for removing a member from the team.',
  },
  resendInvitationButtonLabel: {
    defaultMessage: 'Resend Invite',
    id: 'PFJG1EfF',
    description: 'The button label for resending an invitation.',
  },
  revokeInvitationButtonLabel: {
    defaultMessage: 'Revoke Invite',
    id: 'ESoUP7ZQ',
    description: 'The button label for revoking an invitation.',
  },
})
