import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import { useState } from 'react'
import {
  useWatch,
  type Control,
  type FieldValues,
  type UseFormClearErrors,
  type UseFormResetField,
  type UseFormTrigger,
} from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router'
import { Button } from '../../../../components/Button'
import { ContainerMonitoringMessages } from '../../messages/ContainerMonitoringMessages'
import {
  CreateContainerGroupField,
  CreateContainerGroupFormSectionIdAttributes,
  CreateContainerGroupValues,
} from '../../models'
import { AutostartInput } from '../AutostartInput'
import { LivenessProbeFields, ReadinessProbeFields, StartupProbeFields } from '../HealthProbeFields'
import { LoggingFields } from '../LoggingFields'
import { MonitoringConfirmationModal } from '../MonitoringConfirmationModal'

export interface ContainerMonitoringProps {
  /** The react hook form method that clears errors for specified fields. */
  clearErrors: UseFormClearErrors<FieldValues>
  /** The control for the create container group react hook form */
  control: Control<FieldValues, CreateContainerGroupValues>
  /** The flag indicating that the request to create a container group is pending. */
  isSubmitPending: boolean
  /** The function to handle navigation to the previous page */
  onBackButton: () => void
  /**
   * A callback that when executed records a mixpanel event for the element that was clicked.
   *
   * @param label The label of the element that was clicked
   */
  onRecordMixpanelElementClickedEvent: (label: string) => void
  /** The submit handler for the create container group react hook form. */
  onSubmit: (event: React.SyntheticEvent<Element, Event>) => void
  /** The react hook form method that provides the ability to reset a specified field value. */
  resetField: UseFormResetField<FieldValues>
  /** The react hook form method that provides the ability to set a specified field value. */
  setValue: any
  /** The react hook form method that triggers validation for specified fields. */
  trigger: UseFormTrigger<FieldValues>
}

export const ContainerMonitoring: FunctionComponent<ContainerMonitoringProps> = ({
  clearErrors,
  control,
  isSubmitPending,
  onBackButton,
  onRecordMixpanelElementClickedEvent,
  onSubmit,
  resetField,
  setValue,
  trigger,
}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const [isMonitoringConfirmationModalOpen, setIsMonitoringConfirmationModalOpen] = useState(false)
  const [isReadinessProbeSidePanelOpen, setReadinessProbeSidePanelOpen] = useState(false)

  const gatewayEnabledValue = useWatch({
    control,
    name: CreateContainerGroupField.CONTAINER_GATEWAY_ENABLED,
  })

  const jobQueueValue = useWatch({
    control,
    name: CreateContainerGroupField.JOB_QUEUE,
  })

  const readinessProbeEnabledValue = useWatch({
    control,
    name: CreateContainerGroupField.READINESS_PROBE_ENABLED,
  })

  const handleDeploy = (event: React.SyntheticEvent<Element, Event>, isSkippingReadinessProbe?: boolean) => {
    if (!isSkippingReadinessProbe && (gatewayEnabledValue || jobQueueValue) && readinessProbeEnabledValue === false) {
      setIsMonitoringConfirmationModalOpen(true)
    } else {
      setIsMonitoringConfirmationModalOpen(false)
      onSubmit(event)
    }
  }

  const handleOpenReadinessProbe = () => {
    setIsMonitoringConfirmationModalOpen(false)
    setReadinessProbeSidePanelOpen(true)
  }

  return (
    <div className="relative h-screen w-full pb-36">
      <div className="h-full overflow-auto px-6 pt-8">
        <div className="mb-36 w-full max-w-2xl">
          <div className="mb-4">
            <button className="text-blue-90 underline" onClick={() => navigate(-1)}>
              <span className={classNames('fa-solid fa-arrow-left mr-2')} />
              {intl.formatMessage(ContainerMonitoringMessages.backLinkText)}
            </button>
          </div>
          <h1 className="mb-3 text-3xl font-bold">
            {intl.formatMessage(ContainerMonitoringMessages.containerMonitoringTitle)}
          </h1>
          <h3 className="mb-10 text-lg">
            {intl.formatMessage(ContainerMonitoringMessages.containerMonitoringSubtitle)}
          </h3>

          <StartupProbeFields
            clearErrors={clearErrors}
            control={control}
            id={CreateContainerGroupFormSectionIdAttributes.STARTUP_PROBE}
            setValue={setValue}
            trigger={trigger}
          />
          <LivenessProbeFields
            clearErrors={clearErrors}
            control={control}
            id={CreateContainerGroupFormSectionIdAttributes.LIVENESS_PROBE}
            setValue={setValue}
            trigger={trigger}
          />
          <ReadinessProbeFields
            clearErrors={clearErrors}
            control={control}
            id={CreateContainerGroupFormSectionIdAttributes.READINESS_PROBE}
            isReadinessProbeSidePanelOpen={isReadinessProbeSidePanelOpen}
            setReadinessProbeSidePanelOpen={setReadinessProbeSidePanelOpen}
            setValue={setValue}
            trigger={trigger}
          />
          <LoggingFields
            clearErrors={clearErrors}
            control={control}
            id={CreateContainerGroupFormSectionIdAttributes.EXTERNAL_LOGGING_SERVICE}
            onRecordMixpanelElementClickedEvent={onRecordMixpanelElementClickedEvent}
            resetField={resetField}
            trigger={trigger}
          />
        </div>
      </div>

      <div className="absolute bottom-16 left-0 mt-10 flex w-full flex-col justify-items-end bg-neutral-10 px-6 py-3 align-middle shadow">
        <div className="flex w-full items-center justify-end gap-6">
          <AutostartInput control={control} />
          <div className="flex gap-2">
            <Button
              isDisabled={isSubmitPending}
              isLoading={isSubmitPending}
              onClick={onBackButton}
              type="button"
              variant="green-outlined"
            >
              {intl.formatMessage(ContainerMonitoringMessages.previousButtonLabel)}
            </Button>
            <Button
              isDisabled={isSubmitPending}
              isLoading={isSubmitPending}
              onClick={handleDeploy}
              type="button"
              variant="green-filled"
            >
              {intl.formatMessage(ContainerMonitoringMessages.deployButtonLabel)}
            </Button>
          </div>
        </div>
        {isMonitoringConfirmationModalOpen && (
          <MonitoringConfirmationModal
            monitoringTypeText={intl.formatMessage(ContainerMonitoringMessages.readinessProbeMonitoringText)}
            onClose={() => setIsMonitoringConfirmationModalOpen(false)}
            onContinue={(event) => handleDeploy(event, true)}
            onOpenReadinessProbe={handleOpenReadinessProbe}
          />
        )}
      </div>
    </div>
  )
}
