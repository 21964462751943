import type { PayloadAction } from '@reduxjs/toolkit'
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import type { ContainerGroupInstance } from '@saladtechnologies/openapi-cloud-portal-browser'
import { configuresContainerGroupsInstancesEntityId } from './utils'

export interface ContainerGroupInstancesProps {
  containerGroupName: string
  organizationName: string
  projectName: string
  instances: ContainerGroupInstance[]
}

export const containerGroupInstancesAdapter = createEntityAdapter<ContainerGroupInstancesProps>({
  selectId: (item) =>
    configuresContainerGroupsInstancesEntityId(item.organizationName, item.projectName, item.containerGroupName),
  sortComparer: (a, b) => a.organizationName.localeCompare(b.organizationName),
})

export const containerGroupInstancesSlice = createSlice({
  name: 'containerGroupInstances',
  initialState: containerGroupInstancesAdapter.getInitialState(),
  reducers: {
    containerGroupInstanceAdded(
      state,
      action: PayloadAction<{
        organizationName: string
        projectName: string
        containerGroupName: string
        instance: ContainerGroupInstance
      }>,
    ) {
      const { containerGroupName, instance, organizationName, projectName } = action.payload
      const existingContainerGroup =
        state.entities[configuresContainerGroupsInstancesEntityId(organizationName, projectName, containerGroupName)]

      const updatedInstances = existingContainerGroup ? [...existingContainerGroup.instances, instance] : [instance]

      containerGroupInstancesAdapter.upsertOne(state, {
        containerGroupName,
        instances: updatedInstances,
        organizationName,
        projectName,
      })
    },
    containerGroupInstanceRemoved(
      state,
      action: PayloadAction<{
        organizationName: string
        projectName: string
        containerGroupName: string
        instanceId: string
      }>,
    ) {
      const { containerGroupName, instanceId, organizationName, projectName } = action.payload

      const existingContainerGroup =
        state.entities[configuresContainerGroupsInstancesEntityId(organizationName, projectName, containerGroupName)]

      const updatedInstances = existingContainerGroup
        ? existingContainerGroup.instances.filter((i) => i.instanceId !== instanceId)
        : []

      containerGroupInstancesAdapter.upsertOne(state, {
        containerGroupName,
        instances: updatedInstances,
        organizationName,
        projectName,
      })
    },
    containerGroupInstancesAdded: containerGroupInstancesAdapter.upsertOne,
    getContainerGroupInstanceDetailsPageData(
      _state,
      _action: PayloadAction<{
        organizationName: string
        projectName: string
        containerGroupName: string
        instanceId: string
      }>,
    ) {},
    getContainerGroupInstances(
      _state,
      _action: PayloadAction<{
        organizationName: string
        projectName: string
        containerGroupName: string
      }>,
    ) {},
    reallocateContainerGroupInstance(
      _state,
      _action: PayloadAction<{
        containerGroupName: string
        organizationName: string
        projectName: string
        instanceId: string
        redirectPath?: string
      }>,
    ) {},
    recreateContainerGroupInstance(
      _state,
      _action: PayloadAction<{
        containerGroupName: string
        organizationName: string
        projectName: string
        instanceId: string
        redirectPath?: string
      }>,
    ) {},
    restartContainerGroupInstance(
      _state,
      _action: PayloadAction<{
        containerGroupName: string
        organizationName: string
        projectName: string
        instanceId: string
        redirectPath?: string
      }>,
    ) {},
    stopPollingForContainerGroupInstance() {},
  },
})

export const {
  containerGroupInstanceAdded,
  containerGroupInstanceRemoved,
  containerGroupInstancesAdded,
  getContainerGroupInstanceDetailsPageData,
  getContainerGroupInstances,
  reallocateContainerGroupInstance,
  recreateContainerGroupInstance,
  restartContainerGroupInstance,
  stopPollingForContainerGroupInstance,
} = containerGroupInstancesSlice.actions
