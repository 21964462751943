import { defineMessages } from 'react-intl'

export const CreateRecipeDeploymentStepsMessages = defineMessages({
  configureAndDeployStep: {
    defaultMessage: 'Configure & Deploy',
    id: '89qAAcJ+',
    description: 'The configure and deploy step for deploying a recipe.',
  },
  header: {
    defaultMessage: 'DEPLOYING A RECIPE FOR',
    id: '3gai5tGl',
    description: 'The header for the steps the user needs to take to deploy a recipe.',
  },
})
