import { catchError, concat, delay, filter, from, mergeMap, of } from 'rxjs'
import { OrganizationsAPI, ProjectsAPI, RecipesAPI } from '../apiMethods'
import { showToastNotification } from '../features/notifications/notificationsSlice'
import { organizationAdded } from '../features/organizations/organizationsSlice'
import { projectsAddedToOrganization } from '../features/projects/projectsSlice'
import { getRecipeMarketplacePageData, setRecipes } from '../features/recipesMarketplace/recipeMarketplaceSlice'
import { setRequestStatus } from '../features/requestStatus/requestStatusSlice'
import { getRecipeMarketplaceFailedErrorContent } from '../notifications/clientToastNotificationContent/recipes'
import type { AppEpic } from '../store'

export const onGetRecipeMarketplacePageData: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(getRecipeMarketplacePageData.match),
    mergeMap((action) =>
      concat(
        of(setRequestStatus({ request: 'getRecipeMarketplacePageData', status: 'pending' })),
        from(
          Promise.all([
            RecipesAPI.listRecipes({
              organizationName: action.payload.organizationName,
            }),
            OrganizationsAPI.getOrganization({
              organizationName: action.payload.organizationName,
            }),
            ProjectsAPI.listProjects({
              organizationName: action.payload.organizationName,
            }),
          ]),
        ).pipe(
          mergeMap(([recipesResponse, organizationResponse, projectsResponse]) =>
            concat(
              of(
                setRequestStatus({ request: 'getRecipeMarketplacePageData', status: 'succeeded' }),
                setRecipes({ recipes: recipesResponse.items }),
                organizationAdded(organizationResponse),
                projectsAddedToOrganization({
                  organizationName: organizationResponse.name,
                  projects: projectsResponse.items,
                }),
              ),
              of(setRequestStatus({ request: 'getRecipeMarketplacePageData', status: 'idle' })).pipe(delay(1)),
            ),
          ),
          catchError(() =>
            concat(
              of(
                setRequestStatus({ request: 'getRecipeMarketplacePageData', status: 'failed' }),
                showToastNotification(getRecipeMarketplaceFailedErrorContent(intl)),
              ),
              of(setRequestStatus({ request: 'getRecipeMarketplacePageData', status: 'idle' })).pipe(delay(1)),
            ),
          ),
        ),
      ),
    ),
  )
