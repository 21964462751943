import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { ExternalLoggingDetailsMessages } from '../../messages'

interface TCPExternalLoggingDetailsProps {
  /** The tcp host value. */
  tcpHost: string | undefined
  /** The tcp port value. */
  tcpPort: number | undefined
}

const colon = ':'

export const TCPExternalLoggingDetails: FunctionComponent<TCPExternalLoggingDetailsProps> = ({ tcpHost, tcpPort }) => {
  const intl = useIntl()

  return (
    <div className="text-base font-medium">
      <p>{intl.formatMessage(ExternalLoggingDetailsMessages.tcpSelectedLabel)}</p>
      <p className="truncate">
        {intl.formatMessage(ExternalLoggingDetailsMessages.hostLabel)}
        {colon}
        &nbsp;
        <span className="font-normal">{tcpHost ?? ''}</span>
      </p>
      <p>
        {intl.formatMessage(ExternalLoggingDetailsMessages.portLabel)}
        {colon}
        &nbsp;
        <span className="font-normal">{tcpPort ?? ''}</span>
      </p>
    </div>
  )
}
