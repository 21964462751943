import type { ContainerGroupStatus, RecipeNetworking } from '@saladtechnologies/openapi-cloud-portal-browser'
import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button } from '../../../../components/Button'
import type { Tab } from '../../../../components/Tabs'
import { Tabs } from '../../../../components/Tabs'
import { Tag } from '../../../../components/Tag'
import { TextField } from '../../../../components/TextField'
import { ButtonLink, Link } from '../../../../components/base'
import { Card } from '../../../../components/base/Card'
import { Markdown } from '../../../../components/base/Markdown/Markdown'
import { DeploymentStatusTag } from '../../../../components/block/DeploymentStatusTag/DeploymentStatusTag'
import { DeploymentInstanceTable } from '../../../../components/deployments/DeploymentInstanceTable/DeploymentInstanceTable'
import { InstanceStatusesExplainerModal } from '../../../../components/deployments/InstanceStatusesExplainerModal'
import { DeploymentInstance } from '../../../../features/containerGroupInstanceTable/models'
import { createToastNotification } from '../../../../notifications'
import { getSuccessfullyCopiedAccessDomainNameContent } from '../../../../notifications/clientToastNotificationContent/recipes/getSuccessfullyCopiedAccessDomainNameContent'
import { RecipeDeploymentDetailsMainContentMessages } from '../../messages'
import { DeleteRecipeDeploymentModal } from '../DeleteRecipeDeploymentModal/DeleteRecipeDeploymentModal'

export interface RecipeDeploymentDetailsMainContentProps {
  /** List of recipe deployment instances */
  deploymentInstances: DeploymentInstance[]
  /** The path for editing a recipe deployment */
  editRecipePath: string
  /** The flag indicating that the request to delete the recipe deployment is pending. */
  isDeleteRecipeDeploymentPending: boolean
  /** The flag indicating that the request to delete the recipe deployment was successful. */
  isDeleteRecipeDeploymentSuccessful?: boolean
  /** The flag indicating that the request to refresh the recipe deployment is pending. */
  isRefreshRecipeDeploymentPending: boolean
  /** The flag indicating that the request to start the recipe deployment is pending. */
  isStartRecipeDeploymentPending: boolean
  /** The flag indicating that the request to stop the recipe deployment is pending. */
  isStopRecipeDeploymentPending: boolean
  /**
   * The info regarding Container Gateway for the recipe deployment. If undefined, we will default to no Container
   * Gateway being enabled.
   */
  networking?: RecipeNetworking | null
  /** A callback that when executed deletes the deployed recipe */
  onDeleteRecipeDeployment: () => void
  /**
   * A callback that when executed records a mixpanel event
   *
   * @param label The label of the element that was clicked
   */
  onTrackMixpanelEvent: (label: string, machineId?: string) => void
  /** A callback that when executed refresh the deployed recipe */
  onRefreshRecipeDeployment: () => void
  /** A callback that when executed starts the deployed recipe */
  onStartRecipeDeployment: () => void
  /** A callback that when executed stops the deployed recipe */
  onStopRecipeDeployment: () => void
  /** The display name of the deployed recipe */
  recipeDeploymentDisplayName: string
  /** The path for recipe deployments */
  recipeDeploymentsPath: string
  /** The recipe details page path */
  recipeDetailsPagePath: string
  /** The markdown of the deployed recipe */
  recipeMarkdown: string
  /** The original recipe name of the deployed recipe */
  recipeName: string
  /** The number of replicas of the deployed recipe */
  replicaCount: number
  /** The number of running replicas for deployed recipe. */
  replicasRunning: number
  /** The status of the deployed recipe */
  status: ContainerGroupStatus
  /** The pulling stage description. This should only show on the Preparing status */
  statusDescription?: string
}

export const RecipeDeploymentDetailsMainContent: FunctionComponent<RecipeDeploymentDetailsMainContentProps> = ({
  deploymentInstances,
  editRecipePath,
  isDeleteRecipeDeploymentPending,
  isDeleteRecipeDeploymentSuccessful,
  isRefreshRecipeDeploymentPending,
  isStartRecipeDeploymentPending,
  isStopRecipeDeploymentPending,
  networking,
  onDeleteRecipeDeployment,
  onRefreshRecipeDeployment,
  onStartRecipeDeployment,
  onStopRecipeDeployment,
  onTrackMixpanelEvent,
  recipeDeploymentDisplayName,
  recipeDeploymentsPath,
  recipeMarkdown,
  recipeName,
  replicaCount,
  replicasRunning,
  status,
  statusDescription,
}) => {
  const intl = useIntl()
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  const [isDeleteRecipeModalOpen, setIsDeleteRecipeModalOpen] = useState<boolean>(false)
  const [isInstanceStatusExplainerModalShowing, setInstanceStatusExplainerModalShowing] = useState(false)

  const shouldShowStartButton = status === 'stopped'
  const shouldShowStopButton = status === 'running' || status === 'pending' || status === 'deploying'
  const accessDomainName = networking ? `https://${networking.dns}` : undefined
  const isAccessDomainNameProtected = networking !== undefined && networking !== null && networking.auth

  const handleCopyAccessDomainName = useCallback(
    (accessDomainName: string) => {
      navigator.clipboard.writeText(accessDomainName)
      createToastNotification(getSuccessfullyCopiedAccessDomainNameContent(intl))
    },
    [intl],
  )

  useEffect(() => {
    setIsDeleteRecipeModalOpen(false)
  }, [isDeleteRecipeDeploymentSuccessful])

  const tabs: Tab[] = [
    {
      label: intl.formatMessage(RecipeDeploymentDetailsMainContentMessages.instances),
      content: (
        <div className="mb-5">
          <DeploymentInstanceTable
            deploymentInstances={deploymentInstances}
            deploymentStatus={status}
            onShowInstanceStatusExplainerModal={() => setInstanceStatusExplainerModalShowing(true)}
            onTrackMixpanelEvent={onTrackMixpanelEvent}
          />
        </div>
      ),
    },
    {
      label: intl.formatMessage(RecipeDeploymentDetailsMainContentMessages.deploymentDetails),
      content: (
        <div className="mt-14">
          <Card>
            <Markdown markdown={recipeMarkdown} />
          </Card>
        </div>
      ),
    },
  ]

  return (
    <div className="w-full">
      <div className="max-w-3xl">
        <div className="mb-8">
          <Link url={recipeDeploymentsPath}>
            <span className={classNames('fa-solid fa-arrow-left mr-2')} />
            {intl.formatMessage(RecipeDeploymentDetailsMainContentMessages.backToRecipeDeploymentsLabel)}
          </Link>
        </div>
        <h1 className="mb-1 text-2xl font-bold">{recipeDeploymentDisplayName}</h1>
        <p className="mb-2 font-medium">{recipeName}</p>
        <div
          className={classNames('flex flex-wrap gap-1', {
            'mb-6': !statusDescription,
            'mb-1': statusDescription,
          })}
        >
          <DeploymentStatusTag status={status} />
          <Tag color="gray">
            <FormattedMessage
              defaultMessage="{replicas_running, number} / {replicas} {replicas_running, plural, one {Replica} other {Replicas}} Running"
              id="siDmXAvH"
              description="The label for the replicas."
              values={{
                replicas: replicaCount,
                replicas_running: replicasRunning,
              }}
              tagName="span"
            />
          </Tag>
          {networking &&
            (networking.auth ? (
              <Tag color="white">
                <i className={classNames('mr-2', 'fa-solid fa-lock')} />
                <span>{intl.formatMessage(RecipeDeploymentDetailsMainContentMessages.authenticationRequired)}</span>
              </Tag>
            ) : (
              <Tag color="white">
                <i className={classNames('mr-2', 'fa-solid fa-lock-open')} />
                <span>{intl.formatMessage(RecipeDeploymentDetailsMainContentMessages.containerGatewayEnabled)}</span>
              </Tag>
            ))}
        </div>
        {statusDescription && <p className="mb-6 text-sm">{statusDescription}</p>}
        <div className="mb-6 flex flex-wrap gap-1">
          {shouldShowStartButton ? (
            <div className="w-32">
              <Button
                iconClassName="fa-solid fa-play"
                isDisabled={
                  isDeleteRecipeDeploymentPending ||
                  isRefreshRecipeDeploymentPending ||
                  isStartRecipeDeploymentPending ||
                  isStopRecipeDeploymentPending
                }
                isFullWidth
                isLoading={isStartRecipeDeploymentPending}
                onClick={onStartRecipeDeployment}
                variant="green-filled"
              >
                {intl.formatMessage(RecipeDeploymentDetailsMainContentMessages.startButtonLabel)}
              </Button>
            </div>
          ) : shouldShowStopButton ? (
            <div className="w-32">
              <Button
                iconClassName="fa-solid fa-stop"
                isDisabled={
                  isDeleteRecipeDeploymentPending ||
                  isRefreshRecipeDeploymentPending ||
                  isStartRecipeDeploymentPending ||
                  isStopRecipeDeploymentPending
                }
                isFullWidth
                isLoading={isStopRecipeDeploymentPending}
                variant="green-outlined"
                onClick={onStopRecipeDeployment}
              >
                {intl.formatMessage(RecipeDeploymentDetailsMainContentMessages.stopButtonLabel)}
              </Button>
            </div>
          ) : null}
          <Button
            iconClassName="fa-solid fa-arrows-rotate"
            variant="green-filled-light"
            isDisabled={
              isDeleteRecipeDeploymentPending ||
              isRefreshRecipeDeploymentPending ||
              isStartRecipeDeploymentPending ||
              isStopRecipeDeploymentPending
            }
            isLoading={isRefreshRecipeDeploymentPending}
            onClick={onRefreshRecipeDeployment}
          >
            {intl.formatMessage(RecipeDeploymentDetailsMainContentMessages.refreshButtonLabel)}
          </Button>
          <ButtonLink url={editRecipePath} iconClassName="fa-solid fa-pen-to-square" variant="blue-filled-light">
            {intl.formatMessage(RecipeDeploymentDetailsMainContentMessages.editButtonLabel)}
          </ButtonLink>
          <Button
            isDisabled={
              isDeleteRecipeDeploymentPending ||
              isRefreshRecipeDeploymentPending ||
              isStartRecipeDeploymentPending ||
              isStopRecipeDeploymentPending
            }
            iconClassName="fa-solid fa-trash"
            onClick={() => setIsDeleteRecipeModalOpen(true)}
            variant="red-filled-light"
          >
            {intl.formatMessage(RecipeDeploymentDetailsMainContentMessages.deleteButtonLabel)}
          </Button>
        </div>

        {/* Access Domain Name */}
        {accessDomainName && (
          <TextField
            helperText={intl.formatMessage(RecipeDeploymentDetailsMainContentMessages.accessDomainNameHelperText)}
            readonly
            isFullWidth
            label={
              isAccessDomainNameProtected
                ? intl.formatMessage(RecipeDeploymentDetailsMainContentMessages.accessDomainNameProtectedLabel)
                : intl.formatMessage(RecipeDeploymentDetailsMainContentMessages.accessDomainNameLabel)
            }
            leftIconClassName="fa-solid fa-lock"
            leftIconColor="text-blue-80"
            rightIconClassName="fa-solid fa-copy"
            rightIconColor="text-blue-80"
            onRightIconClick={() => handleCopyAccessDomainName(accessDomainName)}
            defaultValue={accessDomainName}
          />
        )}
      </div>

      {/* Instances and Deployment Details */}
      <div className="mt-10 max-w-7xl">
        <Tabs tabs={tabs} selectedTabIndex={selectedTabIndex} onSelectedTabIndexChange={setSelectedTabIndex} />
      </div>
      {isDeleteRecipeModalOpen && (
        <DeleteRecipeDeploymentModal
          onCancel={() => setIsDeleteRecipeModalOpen(false)}
          isDeleteRecipeDeploymentPending={isDeleteRecipeDeploymentPending}
          onDeleteRecipeDeployment={onDeleteRecipeDeployment}
          recipeDeploymentDisplayName={recipeDeploymentDisplayName}
        />
      )}

      {/* Instance Status Explainer Modal */}
      {isInstanceStatusExplainerModalShowing && (
        <InstanceStatusesExplainerModal onCancel={() => setInstanceStatusExplainerModalShowing(false)} />
      )}
    </div>
  )
}

RecipeDeploymentDetailsMainContent.defaultProps = {
  networking: undefined,
  statusDescription: undefined,
}
