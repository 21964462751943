import type { FunctionComponent } from 'react'
import type { Control, FieldValues } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { TextField } from '../../../../../../components/TextField'
import { ButtonLink } from '../../../../../../components/base'
import { EditAWSElasticInputFieldsMessages, EditContainerGroupMainContentMessages } from '../../../../messages'
import type { EditContainerGroupValues } from '../../../../models'
import { EditContainerGroupField } from '../../../../models'

interface EditAWSElasticInputFieldsProps {
  /** The control for the edit container group react hook form. */
  control: Control<FieldValues, EditContainerGroupValues>
}

export const EditAWSElasticInputFields: FunctionComponent<EditAWSElasticInputFieldsProps> = ({ control }) => {
  const intl = useIntl()

  return (
    <>
      <Controller
        name={EditContainerGroupField.AWS_ELASTIC_ACCESS_KEY_ID}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            {...fieldState}
            helperText={fieldState.error?.message}
            label={intl.formatMessage(EditAWSElasticInputFieldsMessages.accessKeyIDLabel)}
            placeholder={intl.formatMessage(EditAWSElasticInputFieldsMessages.accessKeyIDPlaceholderText)}
            type="text"
            isFullWidth
          />
        )}
      />
      <Controller
        name={EditContainerGroupField.AWS_ELASTIC_SECRET_ACCESS_KEY}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            {...fieldState}
            helperText={fieldState.error?.message}
            label={intl.formatMessage(EditAWSElasticInputFieldsMessages.secretAccessKeyLabel)}
            placeholder={intl.formatMessage(EditAWSElasticInputFieldsMessages.secretAccessKeyPlaceholderText)}
            type="password"
            isFullWidth
          />
        )}
      />

      <div className="pb-16">
        <p className="mb-2">{intl.formatMessage(EditAWSElasticInputFieldsMessages.needHelpText)}</p>
        <ButtonLink url="https://docs.salad.com/container-engine/registries/aws-ecr" variant="blue-filled-light">
          {intl.formatMessage(EditContainerGroupMainContentMessages.learnMoreButtonLabel)}
        </ButtonLink>
      </div>
    </>
  )
}
