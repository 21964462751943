import type { SyntheticEvent } from 'react'
import type { FieldValues, SubmitHandler } from 'react-hook-form'
import { useForm } from 'react-hook-form'

interface UseFixedFormConfig<T> extends FieldValues {
  onSubmit: (values: T) => void
}

export const useFixedForm = <T>(config: UseFixedFormConfig<T>) => {
  const { handleSubmit, ...form } = useForm(config as FieldValues)

  const handleSubmitFixed = fixSubmit(handleSubmit(config.onSubmit as SubmitHandler<FieldValues>))

  return { ...form, handleSubmit: handleSubmitFixed }
}

/**
 * Fixes the signature of the React Hook Form submit handler.
 *
 * @param onSubmitAsync The React Hook Form submit handler.
 * @returns The fixed submit handler.
 * @link https://github.com/react-hook-form/react-hook-form/discussions/8020
 */
const fixSubmit = <T>(onSubmitAsync: (event: SyntheticEvent) => Promise<T>) => {
  return (event: SyntheticEvent): void => {
    onSubmitAsync(event).catch(() => {})
  }
}
