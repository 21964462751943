import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import RecipeImage from '../../../../assets/Recipes.svg'
import { ButtonLink } from '../../../../components/base/ButtonLink'
import { NoRecipesDeployedMessages } from '../../messages'

interface NoRecipesDeployedProps {
  /** The callback executed when the user clicks the Learn More button */
  onLearnMore: () => void
  /** The path to the recipe marketplace */
  viewRecipeMarketplacePath: string
}

export const NoRecipesDeployed: FunctionComponent<NoRecipesDeployedProps> = ({ viewRecipeMarketplacePath }) => {
  const intl = useIntl()

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="mb-4">
        <img src={RecipeImage} alt={intl.formatMessage(NoRecipesDeployedMessages.recipesImageAlt)} />
      </div>
      <div className="mb-5">
        <h2 className="text-3xl font-bold">{intl.formatMessage(NoRecipesDeployedMessages.noRecipe)}</h2>
      </div>
      <div className="flex gap-2">
        <ButtonLink
          iconClassName="fa-solid fa-shop"
          url={viewRecipeMarketplacePath}
          role="navigation"
          variant="green-filled-light"
        >
          {intl.formatMessage(NoRecipesDeployedMessages.deployRecipeButtonLabel)}
        </ButtonLink>
        {/* // TODO: uncomment when correct link for "learn more" will appear
        <Button iconClassName="fa-solid fa-book" onClick={onLearnMore} role="navigation" variant="blue-filled-light">
          {intl.formatMessage(NoRecipesDeployedMessages.learnMoreButtonLabel)}
        </Button> */}
      </div>
    </div>
  )
}
