import { defineMessages } from 'react-intl'

export const RecipeQuotaExceededModalMessages = defineMessages({
  cancelButtonLabel: {
    defaultMessage: 'Cancel',
    id: 'ZS+2yiAl',
    description: 'The label for the Cancel button.',
  },
  description1: {
    defaultMessage: "You've reached the maximum number of deployed recipes for your organization.",
    id: 'dHYrJWHJ',
    description: 'The first description for the modal that shows when a user has exceeded their recipe quota.',
  },
  description2: {
    defaultMessage:
      'To create a new recipe deployment, submit a request to increase your quota or delete an existing recipe deployment.',
    id: 'DtsC9BYe',
    description: 'The second description for the modal that shows when a user has exceeded their recipe quota.',
  },
  requestIncreaseButtonLabel: {
    defaultMessage: 'Request Increase',
    id: 'x5ZwKuzv',
    description: 'The label for the request increase button.',
  },
  title: {
    defaultMessage: 'Recipe Quota Exceeded',
    id: 'GlRE38KU',
    description: 'The title for the modal that shows when a user has exceeded their recipe quota.',
  },
})
