import { defineMessages } from 'react-intl'

export const GetJobQueueDetailsPageDataErrorMessages = defineMessages({
  body: {
    defaultMessage:
      'We’re unable to retrieve the job queue to view at this time. Try again and if this issue persists {contact_support}.',
    id: 'FIxN7nO1',
    description:
      'The description shown in a toast notification message shown when we are unable to get the job queue page data.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: '1RwPFKxe',
    description: 'The text shown for the contact support link in the get job queues error toast notification.',
  },
  title: {
    defaultMessage: 'Unable to Retrieve Job Queue',
    id: 'Y8F3Y2Fj',
    description:
      'The toast notification title shown when we encounter an error and are unable to get the job queue page data.',
  },
})
