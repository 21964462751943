import type { FunctionComponent } from 'react'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { Link } from '../../../../components/base'
import { TableHeader } from '../../../../components/block/Table/TableHeader'
import { TableSortingOrder } from '../../../../components/block/Table/models'
import type { JobQueueTableConnections } from '../../../../features/jobQueueConnectionsTable/models'
import { getContainerGroupDetailsPagePath } from '../../../../routes/routes-utils'
import { JobQueueTableMessages } from '../../messages'
import { getSortedJobQueueTable } from './utils'

export interface JobQueueTableProps {
  /** The job queue connections */
  connections: JobQueueTableConnections
  /** The job queue display name */
  displayName: string
  /** The callback that deletes a job queue connection */
  onDeleteConnection: (containerGroupName: string) => void
}

export const JobQueueTable: FunctionComponent<JobQueueTableProps> = ({ connections }) => {
  const intl = useIntl()
  const { organizationName = '', projectName = '' } = useParams()
  const [sortingOrder, setSortingOrder] = useState<TableSortingOrder>(TableSortingOrder.Ascending)
  // const [isDeleteConnectionModalOpen, setDeleteConnectionModalOpen] = useState<boolean>(false)
  const sortedJobQueueConnections = getSortedJobQueueTable(connections, sortingOrder)
  // const connectionToDelete = connections.find((connection) => connection.containerGroupName === connectionToDeleteName)

  // useEffect(() => {
  //   if (connectionToDelete && connectionToDelete.isDeleteConnectionRequestSuccessful) {
  //     setDeleteConnectionModalOpen(false)
  //   }
  // }, [connectionToDelete])

  return (
    <>
      <table className="w-full table-fixed">
        <TableHeader
          headers={[
            {
              label: intl.formatMessage(JobQueueTableMessages.containerGroupColumnHeader),
              onClick: () =>
                setSortingOrder(
                  sortingOrder === TableSortingOrder.Ascending
                    ? TableSortingOrder.Descending
                    : TableSortingOrder.Ascending,
                ),
              sortingOrder: sortingOrder,
            },
            {
              label: intl.formatMessage(JobQueueTableMessages.portHeader),
            },
            {
              label: intl.formatMessage(JobQueueTableMessages.pathHeader),
            },
            // {
            //   label: '',
            // },
          ]}
        />
        {connections.length > 0 ? (
          <tbody>
            {sortedJobQueueConnections.map((connection) => {
              const { containerGroupDisplayName, containerGroupName, path, port } = connection
              return (
                <tr key={containerGroupName} className="bg-neutral-10 hover:bg-neutral-20 active:bg-neutral-30">
                  <td className="border-b border-b-neutral-30 px-4 py-9 align-middle">
                    <Link url={getContainerGroupDetailsPagePath(organizationName, projectName, containerGroupName)}>
                      {containerGroupDisplayName}
                    </Link>
                  </td>
                  <td className="border-b border-b-neutral-30 px-4 py-9 align-middle">
                    <p className="flex justify-center">{port}</p>
                  </td>
                  <td className="border-b border-b-neutral-30 px-4 py-9 align-middle">
                    <p className="flex justify-center">{path}</p>
                  </td>
                  {/* <td className="border-b border-b-neutral-30 px-4 py-9 align-middle">
                    <div className="flex justify-center">
                      <div
                        aria-label={intl.formatMessage(JobQueueTableMessages.deleteConnectionButtonLabel)}
                        className={classNames('fa-solid fa-trash cursor-pointer text-red-90')}
                        onClick={() => {
                          setDeleteConnectionModalOpen(true)
                          setConnectionToDeleteName(containerGroupName)
                        }}
                      />
                    </div>
                  </td> */}
                </tr>
              )
            })}
          </tbody>
        ) : null}
      </table>
      {/* {isDeleteConnectionModalOpen && connectionToDelete && (
        <DeleteJobQueueConnectionModal
          containerGroupDisplayName={connectionToDelete.containerGroupDisplayName}
          isDeleteJobQueueConnectionPending={connectionToDelete.isDeleteConnectionRequestPending}
          jobQueueDisplayName={displayName}
          onCancel={() => setDeleteConnectionModalOpen(false)}
          onDeleteConnection={() => onDeleteConnection(connectionToDelete.containerGroupName)}
        />
      )} */}
    </>
  )
}
