import { defineMessages } from 'react-intl'

export const UnableToRetrieveContainerCountLimitErrorMessages = defineMessages({
  body: {
    defaultMessage:
      'We were unable to retrieve container count quotas for your organization. You can still edit the container name. If this continues, please {contact_support}.',
    id: 'OqtOnvlB',
    description:
      'The description shown in a toast notification message shown when we encounter an error retrieving the container count quotas.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'HEO+oOT1',
    description:
      'The text shown for the contact support link in the unable to load container count quotas error toast notification.',
  },
  title: {
    defaultMessage: 'Unable to load container count quotas',
    id: 'FLKU43dp',
    description: 'The toast notification title shown when we encounter an error retrieving the container count quotas.',
  },
})
