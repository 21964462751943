import type { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { SaladVerified } from '../../SaladVerified'

export interface RecipeCardProps {
  /** The callback called when the recipe card is clicked. */
  path?: string
  /** The name of the original recipe */
  recipeName: string
}

export const RecipeCard: FunctionComponent<RecipeCardProps> = ({ path, recipeName }) => {
  return (
    <>
      {path ? (
        <Link
          className="flex w-full flex-row flex-nowrap rounded-lg px-6 py-4 shadow-md hover:shadow-green-30 focus-visible:shadow-green-30 focus-visible:outline-none"
          to={path}
        >
          <p className="mr-2 font-bold">{recipeName}</p>
          <SaladVerified />
        </Link>
      ) : (
        <div className="flex w-full flex-row flex-nowrap rounded-lg px-6 py-4 shadow-md">
          <p className="mr-2 font-bold">{recipeName}</p>
          <SaladVerified />
        </div>
      )}
    </>
  )
}
