import type { FunctionComponent } from 'react'
import { LeftNavigationBar } from '../../components/LeftNavigationBar'
import type { ProjectSelectOptions } from '../../components/LeftNavigationBar/models'
import { ThreeColumnLayout } from '../../layouts/threeColumnLayout'
import { InviteTeamMemberPageMainContent } from './components/InviteTeamMemberPageMainContent'

export interface InviteTeamMemberPageProps {
  /** The current organization that the user is in. */
  currentOrganization: { name: string; displayName: string }
  /** The flag indicating that the request to invite a team member is pending. */
  isInviteTeamMemberPending: boolean
  /** The flag indicating that the request to invite a team member is successful. */
  isInviteTeamMemberSuccessful: boolean
  /** The flag indicating if the left column is showing */
  isLeftColumnOpen: boolean
  /** The callback executed when the user closes the left drawer */
  onCloseLeftDrawer: () => void
  /** A callback that when executed revokes the invitation sent */
  onInviteTeamMember: (email: string) => void
  /**
   * The handler for when something new is selected in the project dropdown. If the current active project is selected,
   * nothing will happen.
   *
   * @param projectName The selected project name.
   */
  onProjectChange: (projectName: string) => void
  /** An array of the current available projects. If empty, we will default to no select bar being displayed */
  projects: ProjectSelectOptions
  /** The path for the Team Page. */
  teamPagePath: string
}

export const InviteTeamMemberPage: FunctionComponent<InviteTeamMemberPageProps> = ({
  currentOrganization,
  isInviteTeamMemberPending,
  isInviteTeamMemberSuccessful,
  isLeftColumnOpen,
  onCloseLeftDrawer,
  onInviteTeamMember,
  onProjectChange,
  projects,
  teamPagePath,
}) => {
  return (
    <ThreeColumnLayout
      isLeftColumnOpen={isLeftColumnOpen}
      LeftColumn={
        <LeftNavigationBar
          currentOrganization={currentOrganization}
          currentProject={undefined}
          isProjectSelectPlaceholderOptionSelectable={true}
          onProjectChange={onProjectChange}
          projects={projects}
        />
      }
      MainColumn={
        <InviteTeamMemberPageMainContent
          isInviteTeamMemberPending={isInviteTeamMemberPending}
          isInviteTeamMemberSuccessful={isInviteTeamMemberSuccessful}
          onInviteTeamMember={onInviteTeamMember}
          organizationDisplayName={currentOrganization.displayName}
          teamPagePath={teamPagePath}
        />
      }
      onCloseLeftDrawer={onCloseLeftDrawer}
    />
  )
}
