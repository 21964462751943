import {
  ContainerLoggingHttpCompressionEnum,
  ContainerLoggingHttpFormatEnum,
  HttpHeadersInner,
} from '@saladtechnologies/openapi-cloud-portal-browser'
import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { ExternalLoggingDetailsMessages } from '../../messages'
import { getFormattedHeaderValues } from '../utils'

interface HTTPExternalLoggingDetailsProps {
  /** The http compression value. */
  httpCompression: ContainerLoggingHttpCompressionEnum | undefined
  /** The http format value. */
  httpFormat: ContainerLoggingHttpFormatEnum | undefined
  /** The http headers value. */
  httpHeaders: HttpHeadersInner[] | null | undefined
  /** The http host value. */
  httpHost: string | undefined
  /** The http password value. */
  httpPassword: string | null | undefined
  /** The http path value. */
  httpPath: string | null | undefined
  /** The http port value. */
  httpPort: number | undefined
  /** The http user value. */
  httpUser: string | null | undefined
}

const colon = ':'

export const HTTPExternalLoggingDetails: FunctionComponent<HTTPExternalLoggingDetailsProps> = ({
  httpCompression,
  httpFormat,
  httpHeaders,
  httpHost,
  httpPassword,
  httpPath,
  httpPort,
  httpUser,
}) => {
  const intl = useIntl()

  return (
    <div className="text-base font-medium">
      <p>{intl.formatMessage(ExternalLoggingDetailsMessages.httpSelectedLabel)}</p>
      <p className="truncate">
        {intl.formatMessage(ExternalLoggingDetailsMessages.hostLabel)}
        {colon}
        &nbsp;
        <span className="font-normal">{httpHost ?? ''}</span>
      </p>
      <p className="truncate">
        {intl.formatMessage(ExternalLoggingDetailsMessages.portLabel)}
        {colon}
        &nbsp;
        <span className="font-normal">{httpPort ?? ''}</span>
      </p>
      <p className="truncate">
        {intl.formatMessage(ExternalLoggingDetailsMessages.formatLabel)}
        {colon}
        &nbsp;
        <span className="font-normal">{httpFormat ?? ''}</span>
      </p>
      <p className="truncate">
        {intl.formatMessage(ExternalLoggingDetailsMessages.compressionLabel)}
        {colon}
        &nbsp;
        <span className="font-normal">{httpCompression ?? ''}</span>
      </p>
      {httpPath && (
        <p className="truncate">
          {intl.formatMessage(ExternalLoggingDetailsMessages.pathLabel)}
          {colon}
          &nbsp;
          <span className="font-normal">{httpPath ?? ''}</span>
        </p>
      )}
      {httpUser && (
        <p className="truncate">
          {intl.formatMessage(ExternalLoggingDetailsMessages.userLabel)}
          {colon}
          &nbsp;
          <span className="font-normal">{httpUser ?? ''}</span>
        </p>
      )}
      {httpPassword && (
        <p className="truncate">
          {intl.formatMessage(ExternalLoggingDetailsMessages.passwordLabel)}
          {colon}
          &nbsp;
          <span className="font-normal">{httpPassword ?? ''}</span>
        </p>
      )}
      {httpHeaders && httpHeaders.length > 0 && (
        <p className="truncate">
          {intl.formatMessage(ExternalLoggingDetailsMessages.headersLabel)}
          {colon}
          &nbsp;
          <span className="font-normal">{getFormattedHeaderValues(httpHeaders)}</span>
        </p>
      )}
    </div>
  )
}
