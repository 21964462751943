import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { RamOption, RecipeDeployment } from '@saladtechnologies/openapi-cloud-portal-browser'
import { RequestStatus } from '../../models'
import { getRecipeCostPerHour } from '../../utils'

export interface EditRecipeDeploymentState {
  costPerHour: number
  editRecipeDeploymentRequestStatus: RequestStatus
  getRecipeDeploymentToEditRequestStatus: RequestStatus
  isRecipeInstancesQuotaExceeded: boolean
  recipeDeployment: RecipeDeployment | undefined
}

const initialState: EditRecipeDeploymentState = {
  costPerHour: 0,
  editRecipeDeploymentRequestStatus: RequestStatus.Idle,
  getRecipeDeploymentToEditRequestStatus: RequestStatus.Idle,
  isRecipeInstancesQuotaExceeded: false,
  recipeDeployment: undefined,
}

export const editRecipeDeploymentSlice = createSlice({
  name: 'editRecipeDeployment',
  initialState,
  reducers: {
    editRecipeDeployment(
      _state,
      _action: PayloadAction<{
        organizationName: string
        projectName: string
        recipeDeploymentName: string
        displayName: string
        replicaCount: number
      }>,
    ) {},
    getRecipeDeploymentToEdit(
      _state,
      _action: PayloadAction<{ organizationName: string; projectName: string; recipeDeploymentName: string }>,
    ) {},
    resetEditRecipeDeploymentState(state) {
      state.costPerHour = 0
      state.getRecipeDeploymentToEditRequestStatus = RequestStatus.Loading
      state.recipeDeployment = undefined
    },
    setEditRecipeDeploymentRequestStatus(state, action: PayloadAction<{ status: RequestStatus }>) {
      state.editRecipeDeploymentRequestStatus = action.payload.status
    },
    setGetRecipeDeploymentToEditRequestStatus(state, action: PayloadAction<{ status: RequestStatus }>) {
      state.getRecipeDeploymentToEditRequestStatus = action.payload.status
    },
    setIsRecipeInstancesQuotaExceeded(state, action: PayloadAction<{ exceeded: boolean }>) {
      state.isRecipeInstancesQuotaExceeded = action.payload.exceeded
    },
    setRecipeDeploymentToEdit(
      state,
      action: PayloadAction<{
        gpuPrice: number | undefined
        recipeDeployment: RecipeDeployment
        ramOptions: RamOption[]
      }>,
    ) {
      state.recipeDeployment = action.payload.recipeDeployment
      state.costPerHour = getRecipeCostPerHour(
        action.payload.recipeDeployment.recipe.resources,
        action.payload.gpuPrice ? action.payload.gpuPrice : undefined,
        action.payload.ramOptions,
      )
    },
  },
})

export const {
  editRecipeDeployment,
  getRecipeDeploymentToEdit,
  resetEditRecipeDeploymentState,
  setEditRecipeDeploymentRequestStatus,
  setGetRecipeDeploymentToEditRequestStatus,
  setIsRecipeInstancesQuotaExceeded,
  setRecipeDeploymentToEdit,
} = editRecipeDeploymentSlice.actions
