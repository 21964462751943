import type { AnyAction } from '@reduxjs/toolkit'
import { PureComponent } from 'react'
import type { IntlCache, IntlShape } from 'react-intl'
import { RawIntlProvider, createIntl, createIntlCache } from 'react-intl'
import { Provider } from 'react-redux'
import { RouterProvider } from 'react-router-dom'
import { createEpicMiddleware } from 'redux-observable'
import { rootEpic } from './epics/rootEpic'
import { ToastContainer } from './notifications'
import { router } from './routes/Routes'
import type { AppEpicDependencies, AppEpicMiddleware, AppState, AppStore } from './store'
import { createStore } from './store'

interface AppComponentState {
  intl: IntlShape
  lang: string
}

export class AppComponent extends PureComponent<{}, AppComponentState> {
  private readonly epicMiddleware: AppEpicMiddleware

  private readonly intlCache: IntlCache

  private readonly store: AppStore

  private disconnect: (() => void) | undefined

  private htmlObserver: MutationObserver | undefined

  constructor(props: {}) {
    super(props)

    this.intlCache = createIntlCache()

    const intl = createIntl(
      {
        locale: window.document.documentElement.lang || 'en',
        messages: {},
        onError: (err) => {
          if (err.code === 'MISSING_TRANSLATION') {
            return
          }
          throw err
        },
      },
      this.intlCache,
    )

    this.epicMiddleware = createEpicMiddleware<AnyAction, AnyAction, AppState, AppEpicDependencies>({
      dependencies: {
        fetch: window.fetch,
        intl: intl,
      },
    })
    this.store = createStore(this.epicMiddleware, {})

    this.state = {
      intl: intl,
      lang: window.document.documentElement.lang,
    }

    this.epicMiddleware.run(rootEpic)
  }

  override componentDidMount() {
    this.htmlObserver = new MutationObserver((mutations) => {
      const { lang } = this.state
      for (let i = 0; i < mutations.length; i += 1) {
        const mutation = mutations[i]
        if (
          mutation &&
          mutation.type === 'attributes' &&
          mutation.attributeName === 'lang' &&
          lang !== window.document.documentElement.lang
        ) {
          this.setState({
            lang: window.document.documentElement.lang,
          })
        }
      }
    })
    this.htmlObserver.observe(window.document.documentElement, {
      attributeFilter: ['lang'],
      attributeOldValue: false,
      attributes: true,
      characterData: false,
      childList: false,
      subtree: false,
    })
  }

  override componentWillUnmount() {
    if (this.disconnect !== undefined) {
      this.disconnect()
      this.disconnect = undefined
    }

    if (this.htmlObserver !== undefined) {
      this.htmlObserver.disconnect()
      this.htmlObserver = undefined
    }
  }

  override render() {
    const { intl } = this.state

    return (
      <RawIntlProvider value={intl}>
        <Provider store={this.store}>
          <RouterProvider router={router} />
        </Provider>
        <ToastContainer />
      </RawIntlProvider>
    )
  }
}
