import { defineMessages } from 'react-intl'

export const SuccessfullyReallocatedDeploymentInstanceMessages = defineMessages({
  title: {
    defaultMessage: 'Instance Reallocated',
    id: '9vYr6Y8E',
    description: 'The toast notification title shown when the deployment instance is successfully reallocated',
  },
  body: {
    defaultMessage: 'The instance has successfully been reallocated',
    id: '2P0kWGiZ',
    description: 'The toast notification description shown when the deployment instance is successfully reallocated',
  },
})
