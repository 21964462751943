import { defineMessages } from 'react-intl'

export const UpdatePasswordMessages = defineMessages({
  title: {
    defaultMessage: 'Update Password',
    id: 'ThXWI8tw',
    description: 'The title for the update password form.',
  },
  passwordLabel: {
    defaultMessage: 'Password',
    id: 'AU2UIiz8',
    description: 'The label for the password field.',
  },
  passwordPlaceholder: {
    defaultMessage: 'Password',
    id: 'zeHclMGl',
    description: 'The placeholder for the password field.',
  },
  passwordHelperText: {
    defaultMessage:
      'Passwords must be at least 8 characters and contain at least one uppercase letter, one lowercase letter, one non-alphanumeric character, and one digit.',
    id: 'TLPqQRx9',
    description: 'The helper text for the password field.',
  },
  passwordRequired: {
    defaultMessage: 'A password is required.',
    id: 'l7YrdeZY',
    description: 'The error message when the password is not provided.',
  },
  updatePasswordButtonLabel: {
    defaultMessage: 'Update Password',
    id: 'V+vy/M8t',
    description: 'Update password button label',
  },
  verifyPasswordLabel: {
    defaultMessage: 'Verify Password',
    id: '3H69YcG9',
    description: 'The label for the verify password field.',
  },
  verifyPasswordPlaceholder: {
    defaultMessage: 'Password',
    id: 'i5dG4vJz',
    description: 'The placeholder for the verify password field.',
  },
  verifyPasswordDoesNotMatch: {
    defaultMessage: 'Password does not match.',
    id: 'fLVYKbb6',
    description: 'Verify password does not match with password',
  },
})
