import { defineMessages } from 'react-intl'

export const OrganizationInvitationCardMessages = defineMessages({
  acceptInvitationButtonLabel: {
    defaultMessage: 'Accept Invitation',
    id: 'CEZ0RZsO',
    description: 'The label for the accept invitation button.',
  },
  declineInvitationButtonLabel: {
    defaultMessage: 'Decline Invitation',
    id: 'VLPq1VqP',
    description: 'The label for the decline invitation button.',
  },
  invitationCardTitle: {
    defaultMessage: `You've been invited to join...`,
    id: '1xBPJihq',
    description: 'The title for the organization invitation card.',
  },
})
