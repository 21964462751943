import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { ContainerGroup } from '@saladtechnologies/openapi-cloud-portal-browser'
import type { ContainerGroupLogsFormValues } from '../../pages/ContainerGroupDetails/models'
import type { GetContainerGroupRequestType } from './models'

export interface ContainerGroupDetailState {
  containerGroup: ContainerGroup | undefined
  defaultContainerGroupLogsFormValues?: Partial<ContainerGroupLogsFormValues>
  defaultTabIndex: number
}

const initialState: ContainerGroupDetailState = {
  containerGroup: undefined,
  defaultContainerGroupLogsFormValues: undefined,
  defaultTabIndex: 0,
}

export const containerGroupDetailSlice = createSlice({
  name: 'containerGroupDetail',
  initialState,
  reducers: {
    deleteContainerGroup(
      _state,
      _action: PayloadAction<{
        organizationName: string
        projectName: string
        containerGroupName: string
        containerGroupDisplayName: string
        jobQueueName?: string
      }>,
    ) {},
    getContainerGroupDetailsPageData(
      _state,
      _action: PayloadAction<{
        organizationName: string
        projectName: string
        containerGroupName: string
        requestType: GetContainerGroupRequestType
      }>,
    ) {},
    getContainerGroupAndInstances(
      _state,
      _action: PayloadAction<{
        organizationName: string
        projectName: string
        containerGroupName: string
      }>,
    ) {},
    setDefaultContainerGroupFormValues(
      state,
      action: PayloadAction<{ formValues?: Partial<ContainerGroupLogsFormValues>; tabValue?: number }>,
    ) {
      const { formValues, tabValue } = action.payload
      if (tabValue !== undefined) {
        state.defaultTabIndex = tabValue
      }

      state.defaultContainerGroupLogsFormValues = formValues
    },
    setContainerGroup(state, action: PayloadAction<{ containerGroup: ContainerGroup }>) {
      state.containerGroup = action.payload.containerGroup
    },
    setIsReallocateContainerGroupInstancePending(
      _state,
      _action: PayloadAction<{ instanceId: string; isPending: boolean }>,
    ) {},
    setIsRecreateContainerGroupInstancePending(
      _state,
      _action: PayloadAction<{ instanceId: string; isPending: boolean }>,
    ) {},
    setIsRestartContainerGroupInstancePending(
      _state,
      _action: PayloadAction<{ instanceId: string; isPending: boolean }>,
    ) {},
    startContainerGroup(
      _state,
      _action: PayloadAction<{ organizationName: string; projectName: string; containerGroupName: string }>,
    ) {},
    stopContainerGroup(
      _state,
      _action: PayloadAction<{ organizationName: string; projectName: string; containerGroupName: string }>,
    ) {},
    stopPollingForContainerGroup(state) {
      state.containerGroup = undefined
    },
  },
})

export const {
  deleteContainerGroup,
  getContainerGroupAndInstances,
  getContainerGroupDetailsPageData,
  setContainerGroup,
  setDefaultContainerGroupFormValues,
  setIsReallocateContainerGroupInstancePending,
  setIsRecreateContainerGroupInstancePending,
  setIsRestartContainerGroupInstancePending,
  startContainerGroup,
  stopContainerGroup,
  stopPollingForContainerGroup,
} = containerGroupDetailSlice.actions
