import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export interface TerminalState {
  terminalWebSocketUrl?: string
}

const initialState: TerminalState = {
  terminalWebSocketUrl: undefined,
}

export const terminalSlice = createSlice({
  name: 'terminal',
  initialState,
  reducers: {
    fetchTerminalToken(
      _state,
      _action: PayloadAction<{
        machineId: string
        organizationName: string
        projectName: string
        containerGroupName: string
      }>,
    ) {},
    setTerminalWebSocketUrl: (state, action: PayloadAction<{ url: string }>) => {
      state.terminalWebSocketUrl = action.payload.url
    },
  },
})

export const { fetchTerminalToken, setTerminalWebSocketUrl } = terminalSlice.actions
