import type { FunctionComponent } from 'react'
import { LeftNavigationBar } from '../../components/LeftNavigationBar'
import type { ProjectSelectOptions } from '../../components/LeftNavigationBar/models'
import { EstimatedCost } from '../../components/recipes/EstimatedCost'
import { ThreeColumnLayout } from '../../layouts/threeColumnLayout'
import { EditRecipeDeploymentMainContent } from './components/EditRecipeDeploymentMainContent'

export interface EditRecipeDeploymentPageProps {
  /** The current organization that the user is in. */
  currentOrganization: { name: string; displayName: string }
  /** The current project that the user is in. */
  currentProject?: { name: string; displayName: string }
  /** The flag indicating that the request to delete the project is pending. */
  isDeleteProjectPending: boolean
  /** The flag indicating that the request to delete the project is successful. */
  isDeleteProjectSuccessful: boolean
  /** The flag indicating that the request to edit the recipe deployment is pending. */
  isEditRecipeDeploymentPending: boolean
  /** The flag indicating if the left column is showing */
  isLeftColumnOpen: boolean
  /** The flag that indicating if the recipe instances quota exceeded. */
  isRecipeInstancesQuotaExceeded: boolean
  /** The link to the typeform for requesting an increase in the recipe deployment instance quota limit. */
  linkToRequestIncreaseForRecipeDeploymentInstancesQuotas: string
  /** The max replica count. */
  maxReplicaCount: number | undefined
  /** The callback executed when the user closes the left drawer */
  onCloseLeftDrawer: () => void
  /** The callback called when the user deletes the project. */
  onDeleteProject: (projectName: string) => void
  /** A callback that when executed revokes the invitation sent */
  onEditRecipeDeployment: (displayName: string, replicaCount: number) => void
  /**
   * The handler for when something new is selected in the project dropdown. If the current active project is selected,
   * nothing will happen.
   */
  onProjectChange: (projectName: string) => void
  /** A callback executed each time the replica count is updated. */
  onUpdateReplicaCount: (pendingReplicaCount: number) => void
  /** The pending recipe deployment replica count. */
  pendingReplicaCount: number | undefined
  /** An array of the current available projects. If empty, we will default to no select bar being displayed */
  projects: ProjectSelectOptions
  /** The recipe cost per hour. */
  recipeCostPerHour: number
  /** The path for the recipe deployment. */
  recipeDeploymentPath: string
  /** The recipe deployment display name. */
  recipeDisplayName: string
  /** The recipe deployment name. */
  recipeName: string
  /** The recipe deployment replica count. */
  replicaCount: number
}

export const EditRecipeDeploymentPage: FunctionComponent<EditRecipeDeploymentPageProps> = ({
  currentOrganization,
  currentProject,
  isDeleteProjectPending,
  isDeleteProjectSuccessful,
  isEditRecipeDeploymentPending,
  isLeftColumnOpen,
  isRecipeInstancesQuotaExceeded,
  linkToRequestIncreaseForRecipeDeploymentInstancesQuotas,
  maxReplicaCount,
  onCloseLeftDrawer,
  onDeleteProject,
  onEditRecipeDeployment,
  onProjectChange,
  onUpdateReplicaCount,
  pendingReplicaCount,
  projects,
  recipeCostPerHour,
  recipeDeploymentPath,
  recipeDisplayName,
  recipeName,
  replicaCount,
}) => {
  return (
    <ThreeColumnLayout
      isRightColumnShowcase
      isLeftColumnOpen={isLeftColumnOpen}
      LeftColumn={
        <LeftNavigationBar
          currentOrganization={currentOrganization}
          currentProject={currentProject}
          isDeleteProjectPending={isDeleteProjectPending}
          isDeleteProjectSuccessful={isDeleteProjectSuccessful}
          onDeleteProject={onDeleteProject}
          onProjectChange={onProjectChange}
          projects={projects}
        />
      }
      MainColumn={
        <EditRecipeDeploymentMainContent
          displayName={recipeDisplayName}
          isRecipeInstancesQuotaExceeded={isRecipeInstancesQuotaExceeded}
          isSubmitPending={isEditRecipeDeploymentPending}
          linkToRequestIncreaseForRecipeDeploymentInstancesQuotas={
            linkToRequestIncreaseForRecipeDeploymentInstancesQuotas
          }
          maxReplicaCount={maxReplicaCount}
          name={recipeName}
          onEditRecipeDeployment={onEditRecipeDeployment}
          onUpdateReplicaCount={onUpdateReplicaCount}
          pendingReplicaCount={pendingReplicaCount}
          recipeCostPerHour={recipeCostPerHour}
          recipeDeploymentPath={recipeDeploymentPath}
          replicaCount={replicaCount}
        />
      }
      onCloseLeftDrawer={onCloseLeftDrawer}
      RightColumn={
        <div className="px-6 py-8">
          <EstimatedCost replicaCount={pendingReplicaCount ?? replicaCount} costPerHour={recipeCostPerHour} />
        </div>
      }
    />
  )
}
