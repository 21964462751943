import { defineMessages } from 'react-intl'

export const UnableToExecuteActionToContainerGroupInstanceNodeNotExistErrorMessages = defineMessages({
  body: {
    defaultMessage: 'We were unable to {action} the instance because it no longer exists.',
    id: 'QgjQnI5D',
    description:
      'The description shown in a toast notification message shown when we are not able to {action} container as instance node not exists.',
  },
  title: {
    defaultMessage: 'Unable to {action} container',
    id: 'AgHmqXA4',
    description:
      'The toast notification title shown when we are not able to {action} container as instance node not exists.',
  },
})
