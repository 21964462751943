import { InferenceEndpoint, InferenceEndpointJob } from '@saladtechnologies/openapi-cloud-portal-browser'
import { IntlShape } from 'react-intl'
import { createToastNotification } from '../../../notifications'
import { getInferenceEndpointJobDownloadErrorContent } from '../../../notifications/clientToastNotificationContent/inferenceEndpoints'
import {
  InferenceEndpointJobsPayload,
  InferenceEndpointName,
  TranscribePayload,
  WhisperLargeV3Payload,
} from '../models/InferenceEndpointDetails'

export const configureTranscribePayload = (url: string): TranscribePayload => {
  return {
    url: url.trim(),
    language_code: 'en',
    word_level_timestamps: true,
    diarization: true,
    srt: true,
  }
}

export const configureWhisperLargeV3Payload = (url: string): WhisperLargeV3Payload => {
  return {
    url,
  }
}

export const getInferenceEndpointJobsRequestPayload = (
  inferenceEndpoint: InferenceEndpoint,
  url: string,
): InferenceEndpointJobsPayload | null => {
  const { name: inferenceEndpointName } = inferenceEndpoint
  if (inferenceEndpointName === InferenceEndpointName.TRANSCRIBE) {
    return configureTranscribePayload(url)
  } else if (inferenceEndpointName === InferenceEndpointName.WHISPER_LARGE_V3) {
    return configureWhisperLargeV3Payload(url)
  } else {
    return null
  }
}

export const hasValidInferenceEndpointJobSchema = (inferenceEndpoint: InferenceEndpoint): boolean => {
  return (
    inferenceEndpoint.name === InferenceEndpointName.TRANSCRIBE ||
    inferenceEndpoint.name === InferenceEndpointName.WHISPER_LARGE_V3
  )
}

/**
 * Downloads the inference endpoint job output.
 *
 * @param inferenceEndpointJob The inference endpoint job.
 * @param onToggleLoading The callback executed when the download starts and stops.
 * @param intl The internationalization object.
 */
export const onDownloadInferenceEndpointJobOutput = (
  intl: IntlShape,
  inferenceEndpointJob?: InferenceEndpointJob,
  onToggleLoading?: (isLoading: boolean) => void,
) => {
  if (!inferenceEndpointJob) {
    createToastNotification(getInferenceEndpointJobDownloadErrorContent(intl))
    return
  }

  const jobId = inferenceEndpointJob?.id || 'output'
  const inferenceEndpointJobOutput = inferenceEndpointJob?.output
  const filename = `${jobId}.txt`
  const outputString =
    typeof inferenceEndpointJobOutput === 'object'
      ? JSON.stringify(inferenceEndpointJobOutput, null, 2)
      : String(inferenceEndpointJobOutput)

  const blob = new Blob([outputString], { type: 'text/plain;charset=utf-8' })
  const link = document.createElement('a')
  link.href = URL.createObjectURL(blob)
  link.download = filename

  document.body.appendChild(link)
  try {
    onToggleLoading && onToggleLoading(true)
    link.click()
  } catch (error) {
    createToastNotification(getInferenceEndpointJobDownloadErrorContent(intl))
  } finally {
    onToggleLoading && onToggleLoading(false)
  }
  document.body.removeChild(link)
}
