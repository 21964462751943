import type { FunctionComponent } from 'react'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { setAuthenticationPage } from '../../features/authentication/authenticationSlice'
import { AuthenticationPage } from '../../features/authentication/models/AuthenticationPage'
import { setForgotPasswordPage } from '../../features/forgotPassword/forgotPasswordSlice'
import { ForgotPasswordPage } from '../../features/forgotPassword/models'
import { login } from '../../features/login/loginSlice'
import { selectRequestStatusIsPending } from '../../features/requestStatus/requestStatusSelectors'
import { APP_PATHS } from '../../routes/models/AppPaths'
import { useAppDispatch, useAppSelector } from '../../store'
import { Login } from './components/Login/Login'

export const ConnectedLoginForm: FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const isSubmitPending = useAppSelector((state) => selectRequestStatusIsPending(state, 'login'))

  const onLogin = useCallback(
    (email: string, password: string) => {
      dispatch(login({ email, password }))
    },
    [dispatch],
  )

  const onViewCreateAccountPage = useCallback(() => {
    dispatch(setAuthenticationPage({ page: AuthenticationPage.CreateAccount }))
  }, [dispatch])

  const onViewForgotPasswordPage = useCallback(() => {
    navigate(APP_PATHS.FORGOT_PASSWORD)
    dispatch(setForgotPasswordPage({ page: ForgotPasswordPage.ForgotYourPassword }))
  }, [navigate, dispatch])

  const onViewEarn = () => {
    window.open('https://salad.com/download', '_blank')
  }

  return (
    <Login
      isSubmitPending={isSubmitPending}
      onSubmit={({ email, password }) => onLogin(email, password)}
      onViewCreateAccount={onViewCreateAccountPage}
      onViewForgotPassword={onViewForgotPasswordPage}
      onViewEarn={onViewEarn}
    />
  )
}
