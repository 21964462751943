import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { ButtonLink } from '../../../../components/base/ButtonLink'
import type {
  MyOrganization,
  MyOrganizationInvitation,
  MyOrganizationInvitationsList,
  MyOrganizationsList,
} from '../../../../features/myOrganizations/models'
import { RequestStatus } from '../../../../models'
import { OrganizationsMainContentMessages } from '../../messages'
import { OrganizationCard } from '../OrganizationCard/OrganizationCard'
import { OrganizationInvitationCard } from '../OrganizationInvitationCard/OrganizationInvitationCard'

interface OrganizationsMainContentProps {
  /** The flag indicating if a request to delete an organization is pending. */
  isDeleteOrganizationPending: boolean
  /** The flag indicating if a request to delete an organization is successful. */
  isDeleteOrganizationSuccessful: boolean
  /**
   * The callback executed when the user accepts an invitation.
   *
   * @param invitationId The id of the invitation to accept.
   */
  onAcceptInvitation: (invitationId: string) => void
  /**
   * The callback executed when the user declines an invitation.
   *
   * @param invitationId The id of the invitation to decline
   */
  onDeclineInvitation: (invitationId: string) => void
  /**
   * The callback executed when the user deletes an organization.
   *
   * @param organizationName The name of the organization that is being deleted.
   */
  onDeleteOrganization: (organizationName: string) => void
  /** The list of a user's organizations. */
  organizations: MyOrganizationsList
  /** The list of a user's organization invitations. */
  organizationInvitations: MyOrganizationInvitationsList
}

export const OrganizationsMainContent: FunctionComponent<OrganizationsMainContentProps> = ({
  isDeleteOrganizationPending,
  isDeleteOrganizationSuccessful,
  onAcceptInvitation,
  onDeclineInvitation,
  onDeleteOrganization,
  organizationInvitations,
  organizations,
}) => {
  const intl = useIntl()
  return (
    <>
      <h1 className="mb-3 text-3xl font-bold">{intl.formatMessage(OrganizationsMainContentMessages.title)}</h1>
      <p className="mb-4">{intl.formatMessage(OrganizationsMainContentMessages.description)}</p>
      <ButtonLink variant="green-filled-light" url="/organizations/new" iconClassName="fa-solid fa-plus">
        {intl.formatMessage(OrganizationsMainContentMessages.createOrganizationButtonLabel)}
      </ButtonLink>
      <div className="mt-8">
        {organizations.map((organization: MyOrganization) => (
          <OrganizationCard
            createdDate={organization.createdDate}
            isDeleteOrganizationPending={isDeleteOrganizationPending}
            isDeleteOrganizationSuccessful={isDeleteOrganizationSuccessful}
            organizationDisplayName={organization.displayName}
            onDeleteOrganization={onDeleteOrganization}
            organizationMemberCount={organization.memberCount}
            organizationName={organization.name}
            organizationProjectCount={organization.projectCount}
            switchToOrgPath={organization.switchToOrgPath}
            key={organization.name}
          />
        ))}
        {organizationInvitations.map((invitation: MyOrganizationInvitation) => (
          <OrganizationInvitationCard
            isAcceptInvitationPending={invitation.acceptInvitationRequestStatus === RequestStatus.Loading}
            isDeclineInvitationPending={invitation.declineInvitationRequestStatus === RequestStatus.Loading}
            onAcceptInvitation={() => onAcceptInvitation(invitation.invitation.id)}
            onDeclineInvitation={() => onDeclineInvitation(invitation.invitation.id)}
            organizationInvitation={invitation.invitation}
          />
        ))}
      </div>
    </>
  )
}
