import type { ReactNode } from 'react'
import type { IntlShape } from 'react-intl'
import type { ToastNotificationType } from '../../models'
import { CreateContainerGroupInvalidStorageOptionErrorContentMessages } from './messages'

/**
 * Retrieves the content needed for an error `ToastNotification` that is shown when the user encounters an error when
 * attempting to create a container group where the storage option they provided was invalid.
 *
 * @param intl The `IntlShape` object needed to format the messages.
 */
export const getCreateContainerGroupInvalidStorageOptionErrorContent = (
  intl: IntlShape,
): {
  body: ReactNode
  type: ToastNotificationType
  title: string
} => {
  return {
    body: intl.formatMessage(CreateContainerGroupInvalidStorageOptionErrorContentMessages.body),
    type: 'error',
    title: intl.formatMessage(CreateContainerGroupInvalidStorageOptionErrorContentMessages.title),
  }
}
