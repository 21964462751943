import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { RamOption, Recipe } from '@saladtechnologies/openapi-cloud-portal-browser'
import { getRecipeCostPerHour } from '../../utils'

export interface RecipeDetailsState {
  costPerHour: number
  recipe: Recipe | undefined
}

const initialState: RecipeDetailsState = {
  costPerHour: 0,
  recipe: undefined,
}

export const recipeDetailsSlice = createSlice({
  name: 'recipeDetails',
  initialState,
  reducers: {
    getRecipeDetails(
      _state,
      _action: PayloadAction<{ organizationName: string; projectName: string; recipeName: string }>,
    ) {},
    resetState(state) {
      state.costPerHour = 0
      state.recipe = undefined
    },
    setRecipe(state, action: PayloadAction<{ recipe: Recipe; gpuPrice: number | undefined; ramOptions: RamOption[] }>) {
      state.recipe = action.payload.recipe
      state.costPerHour = getRecipeCostPerHour(
        action.payload.recipe.resources,
        action.payload.gpuPrice,
        action.payload.ramOptions,
      )
    },
  },
})

export const { getRecipeDetails, resetState, setRecipe } = recipeDetailsSlice.actions
