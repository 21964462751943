import type { FieldErrors, FieldValues } from 'react-hook-form'
import { EditContainerGroupField } from '../../models'

export const checkIfExternalLoggingServiceFieldsHaveErrors = (errors: FieldErrors<FieldValues>): boolean => {
  return (
    (errors[EditContainerGroupField.AXIOM_API_KEY] ||
      errors[EditContainerGroupField.AXIOM_DATASET_NAME] ||
      errors[EditContainerGroupField.AXIOM_HOST] ||
      errors[EditContainerGroupField.DATADOG_API] ||
      errors[EditContainerGroupField.DATADOG_HOST] ||
      errors[EditContainerGroupField.DATADOG_TAGS] ||
      errors[EditContainerGroupField.EXTERNAL_LOGGING_SERVICE] ||
      errors[EditContainerGroupField.HTTP_COMPRESSION] ||
      errors[EditContainerGroupField.HTTP_FORMAT] ||
      errors[EditContainerGroupField.HTTP_HEADERS] ||
      errors[EditContainerGroupField.HTTP_HOST] ||
      errors[EditContainerGroupField.HTTP_PASSWORD] ||
      errors[EditContainerGroupField.HTTP_PATH] ||
      errors[EditContainerGroupField.HTTP_PORT] ||
      errors[EditContainerGroupField.HTTP_USER] ||
      errors[EditContainerGroupField.NEW_RELIC_HOST] ||
      errors[EditContainerGroupField.NEW_RELIC_INGESTION_KEY] ||
      errors[EditContainerGroupField.SPLUNK_HOST] ||
      errors[EditContainerGroupField.SPLUNK_TOKEN] ||
      errors[EditContainerGroupField.TCP_HOST] ||
      errors[EditContainerGroupField.TCP_PORT]) !== undefined
  )
}

export const externalLoggingFieldsList: string[] = [
  EditContainerGroupField.AXIOM_API_KEY,
  EditContainerGroupField.AXIOM_DATASET_NAME,
  EditContainerGroupField.AXIOM_HOST,
  EditContainerGroupField.DATADOG_API,
  EditContainerGroupField.DATADOG_HOST,
  EditContainerGroupField.DATADOG_TAGS,
  EditContainerGroupField.EXTERNAL_LOGGING_SERVICE,
  EditContainerGroupField.HTTP_COMPRESSION,
  EditContainerGroupField.HTTP_FORMAT,
  EditContainerGroupField.HTTP_HEADERS,
  EditContainerGroupField.HTTP_HOST,
  EditContainerGroupField.HTTP_PASSWORD,
  EditContainerGroupField.HTTP_PATH,
  EditContainerGroupField.HTTP_PORT,
  EditContainerGroupField.HTTP_USER,
  EditContainerGroupField.NEW_RELIC_HOST,
  EditContainerGroupField.NEW_RELIC_INGESTION_KEY,
  EditContainerGroupField.SPLUNK_HOST,
  EditContainerGroupField.SPLUNK_TOKEN,
  EditContainerGroupField.TCP_HOST,
  EditContainerGroupField.TCP_PORT,
]
