import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { ResetPasswordPage } from './models'

export interface ResetPasswordState {
  currentPage: ResetPasswordPage
}

const initialState: ResetPasswordState = {
  currentPage: ResetPasswordPage.ResetPassword,
}

export const resetPasswordSlice = createSlice({
  name: 'resetPassword',
  initialState,
  reducers: {
    confirmPasswordReset(_state, _action: PayloadAction<{ token: string; newPassword: string }>) {},
    setResetPasswordPage(state, action: PayloadAction<{ page: ResetPasswordPage }>) {
      state.currentPage = action.payload.page
    },
  },
})

export const { confirmPasswordReset, setResetPasswordPage } = resetPasswordSlice.actions
