import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { ExternalLoggingDetailsMessages } from '../../messages'

interface AxiomExternalLoggingDetailsProps {
  /** The axiom api key value. */
  axiomApiKey: string | undefined
  /** The axiom dataset name value. */
  axiomDatasetName: string | undefined
  /** The axiom host value. */
  axiomHost: string | undefined
}

const colon = ':'

export const AxiomExternalLoggingDetails: FunctionComponent<AxiomExternalLoggingDetailsProps> = ({
  axiomApiKey,
  axiomDatasetName,
  axiomHost,
}) => {
  const intl = useIntl()

  return (
    <div className="text-base font-medium">
      <p>{intl.formatMessage(ExternalLoggingDetailsMessages.axiomSelectedLabel)}</p>
      <p className="truncate">
        {intl.formatMessage(ExternalLoggingDetailsMessages.hostLabel)}
        {colon}
        &nbsp;
        <span className="font-normal">{axiomHost ?? ''}</span>
      </p>
      <p className="truncate">
        {intl.formatMessage(ExternalLoggingDetailsMessages.datasetNameLabel)}
        {colon}
        &nbsp;
        <span className="font-normal">{axiomDatasetName ?? ''}</span>
      </p>
      <p className="truncate">
        {intl.formatMessage(ExternalLoggingDetailsMessages.apiKeyLabel)}
        {colon}
        &nbsp;
        <span className="font-normal">{axiomApiKey ?? ''}</span>
      </p>
    </div>
  )
}
