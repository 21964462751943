import { defineMessages } from 'react-intl'

export const EditRecipeDeploymentSuccessContentMessages = defineMessages({
  body: {
    defaultMessage: 'This recipe deployment has been updated to match your prefrences.',
    id: 'DVznMhU5',
    description: 'The toast notification body when we successfully update a recipe deployment.',
  },
  title: {
    defaultMessage: 'Recipe Deployment Updated',
    id: 'vrFbiJZN',
    description: 'The toast notification title when we successfully update a recipe deployment.',
  },
})
