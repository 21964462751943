import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import { DeploymentStatus } from '../../../../features/containerGroupInstanceTable/models'
import { DeploymentInstanceStatus } from './DeploymentInstanceTableStatus/DeploymentInstanceStatus'

interface DeploymentInstanceTableMachineIdAndStatusRowItemProps {
  /** The unique identifier for the machine */
  machineId: string | undefined
  /** The current status of the deployment instance */
  deploymentStatus: DeploymentStatus
  /** Percentage of creation progress */
  creatingProgress: number | undefined
  /** Percentage of downloading progress */
  downloadingProgress: number | undefined
  /** Indicates if the deployment status is 'running' */
  isDeploymentInstanceRunning: (status: DeploymentStatus) => boolean
  /** Indicates if the instance allocation is taking longer than expected */
  hasAllocatingTakenLongerThanExpected?: boolean
  /** Indicates if the deployment instance has started */
  hasStarted: boolean
  /** Callback executed when the user clicks the status */
  onClickStatus: () => void
  /** Function to navigate to the instance details page */
  onNavigateToInstanceDetailsPage: (machineId: string, event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  /** Callback to track Mixpanel events */
  onTrackMixpanelEvent: (label: string, machineId?: string) => void
  /** The path to the instance details page, if applicable */
  instanceDetailsPagePath?: string
}

export const DeploymentInstanceTableMachineIdAndStatusRowItem: FunctionComponent<
  DeploymentInstanceTableMachineIdAndStatusRowItemProps
> = ({
  creatingProgress,
  deploymentStatus,
  downloadingProgress,
  hasAllocatingTakenLongerThanExpected,
  hasStarted,
  instanceDetailsPagePath,
  isDeploymentInstanceRunning,
  machineId,
  onClickStatus,
  onNavigateToInstanceDetailsPage,
  onTrackMixpanelEvent,
}) => {
  return (
    <div
      className={classNames('justify-left flex flex-col text-left', {
        'cursor-pointer': deploymentStatus === 'running' && instanceDetailsPagePath,
      })}
      onClick={
        isDeploymentInstanceRunning(deploymentStatus) && instanceDetailsPagePath && machineId
          ? () => onNavigateToInstanceDetailsPage(machineId)
          : undefined
      }
    >
      <DeploymentInstanceStatus
        creatingPercentage={creatingProgress}
        deploymentStatus={deploymentStatus}
        downloadingPercentage={downloadingProgress}
        hasAllocatingTakenLongerThanExpected={hasAllocatingTakenLongerThanExpected || false}
        hasStarted={hasStarted}
        machineId={machineId}
        onClickStatus={onClickStatus}
        onTrackMixpanelEvent={onTrackMixpanelEvent}
      />
      {deploymentStatus !== 'allocating' && machineId}
    </div>
  )
}
