import { yupResolver } from '@hookform/resolvers/yup'
import type { FunctionComponent } from 'react'
import { Controller } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button } from '../../../../components/Button'
import { Checkbox } from '../../../../components/Checkbox'
import { TextField } from '../../../../components/TextField'
import { Link } from '../../../../components/base'
import { useFixedForm } from '../../../../hooks'
import { createAccountMessages } from './CreateAccountMessages'
import { useCreateAccountFormValidation } from './useCreateAccountFormValidation'

export interface CreateAccountValues {
  /** The email address. */
  email: string
  /** The password. */
  password: string
  /** The verified password. */
  verifyPassword: string
}

export interface CreateAccountProps {
  /** The flag indicating if the request to submit the form is pending. */
  isSubmitPending: boolean
  /** The submit handler. */
  onSubmit: (values: CreateAccountValues) => void
  /** The callback executed when the user clicks "Log-in". */
  onViewLoginPage: () => void
  /** The callback executed when the user clicks "Looking to earn". */
  onViewEarn: () => void
}

export const CreateAccount: FunctionComponent<CreateAccountProps> = ({
  isSubmitPending,
  onSubmit,
  onViewEarn,
  onViewLoginPage,
}) => {
  const intl = useIntl()
  const createAccountValidationScheme = useCreateAccountFormValidation()
  const { control, handleSubmit } = useFixedForm<CreateAccountValues>({
    resolver: yupResolver(createAccountValidationScheme),
    onSubmit,
  })

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex w-96 flex-col rounded bg-neutral-10 p-8 text-neutral-100 shadow-md">
        <h1 className="pb-8 text-2xl font-bold">{intl.formatMessage(createAccountMessages.title)}</h1>
        <p className="pb-8">
          {intl.formatMessage(createAccountMessages.haveAccount)}&nbsp;
          <button className="cursor-pointer text-blue-90 underline" onClick={onViewLoginPage} role="link" type="button">
            {intl.formatMessage(createAccountMessages.logIn)}
          </button>
        </p>
        <Controller
          name="email"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              {...fieldState}
              helperText={fieldState.error?.message}
              placeholder={intl.formatMessage(createAccountMessages.emailPlaceholder)}
              leftIconClassName="fa-solid fa-envelope"
              label={intl.formatMessage(createAccountMessages.emailLabel)}
              isFullWidth
            />
          )}
        />
        <Controller
          name="password"
          control={control}
          render={({ field, fieldState }) => {
            const requiredErrorMessage = fieldState.error?.type === 'required' && fieldState.error.message
            return (
              <TextField
                {...field}
                {...fieldState}
                type="password"
                isExtraHeight
                helperText={
                  requiredErrorMessage
                    ? requiredErrorMessage
                    : intl.formatMessage(createAccountMessages.passwordHelperText)
                }
                placeholder={intl.formatMessage(createAccountMessages.passwordPlaceholder)}
                leftIconClassName="fa-solid fa-lock"
                label={intl.formatMessage(createAccountMessages.passwordLabel)}
                isFullWidth
              />
            )
          }}
        />
        <Controller
          name="verifyPassword"
          control={control}
          render={({ field, fieldState }) => {
            return (
              <TextField
                {...field}
                {...fieldState}
                type="password"
                helperText={fieldState.error?.message}
                placeholder={intl.formatMessage(createAccountMessages.verifyPasswordPlaceholder)}
                leftIconClassName="fa-solid fa-lock"
                label={intl.formatMessage(createAccountMessages.verifyPasswordLabel)}
                isFullWidth
              />
            )
          }}
        />
        <div className="pb-8">
          <Controller
            name="termsOfServiceAndPrivacyPolicy"
            control={control}
            defaultValue={false}
            render={({ field, fieldState }) => {
              return (
                <Checkbox
                  {...field}
                  {...fieldState}
                  id="acceptedTermsOfServiceAndPrivacyPolicy"
                  isChecked={field.value as boolean}
                  isDisabled={isSubmitPending}
                  onChange={() => field.onChange(!field.value)}
                  label={
                    <FormattedMessage
                      {...createAccountMessages.termsOfServiceCopy}
                      values={{
                        terms_of_service_link: (
                          <Link id="terms_of_service_link" url="https://salad.com/terms/saladcloud">
                            {intl.formatMessage(createAccountMessages.termsOfServiceLinkCopy)}
                          </Link>
                        ),
                      }}
                    />
                  }
                />
              )
            }}
          />
        </div>
        <Button type="submit" isLoading={isSubmitPending} isDisabled={isSubmitPending}>
          {intl.formatMessage(createAccountMessages.createAccountButtonLabel)}
        </Button>
        <p className="pt-8">
          {intl.formatMessage(createAccountMessages.earn)}&nbsp;
          <button className="cursor-pointer text-blue-90 underline" onClick={onViewEarn} role="link" type="button">
            {intl.formatMessage(createAccountMessages.earnDownload)}
          </button>
        </p>
      </div>
    </form>
  )
}
