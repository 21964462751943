import type { ReactNode } from 'react'
import type { IntlShape } from 'react-intl'
import type { ToastNotificationType } from '../../models'
import { SuccessfullyRevokedTeamMemberInvitationContentMessages } from './messages'

/**
 * Retrieves the content needed for a success `ToastNotification` that is shown when the user revokes a team invitation.
 *
 * @param intl The `IntlShape` object needed to format the messages.
 * @param userEmail The user email that is getting reinvited.
 */
export const getRevokedTeamMemberInvitationSucceededContent = (
  intl: IntlShape,
  userEmail: string,
): {
  body: ReactNode
  type: ToastNotificationType
  title: string
} => {
  return {
    body: intl.formatMessage(SuccessfullyRevokedTeamMemberInvitationContentMessages.body, {
      user_email: userEmail,
    }),
    type: 'success',
    title: SuccessfullyRevokedTeamMemberInvitationContentMessages.title.defaultMessage,
  }
}
