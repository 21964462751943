import { defineMessages } from 'react-intl'

export const ResetPasswordEmailSentMessages = defineMessages({
  title: {
    defaultMessage: 'We sent a password reset email to',
    id: 'z69cjcpH',
    description: 'The title for the reset password form.',
  },
  subTitle: {
    defaultMessage: `Click on the link in the email to reset your password. If you don't see the email, please verify the email is correct and check your spam folder.`,
    id: 'npC2WBJc',
    description: 'The subtitle for the reset password form.',
  },
  resendEmailButton: {
    defaultMessage: 'Resend Email',
    id: 'SnFvHFKY',
    description: 'The label for the resend email button.',
  },
  contactSupportButton: {
    defaultMessage: 'Contact Support',
    id: '6utMk+R3',
    description: 'The label for the contact support button.',
  },
})
