import type { AppState } from '../../store'
import type { RequestType } from './models'

export const selectRequestStatus = (state: AppState, request: RequestType) => state.requestStatus[request]

export const selectRequestStatusIsFailed = (state: AppState, request: RequestType) =>
  state.requestStatus[request] === 'failed'

export const selectRequestStatusIsPending = (state: AppState, request: RequestType) =>
  state.requestStatus[request] === 'pending'

export const selectRequestStatusIsSucceeded = (state: AppState, request: RequestType) =>
  state.requestStatus[request] === 'succeeded'
