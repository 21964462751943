import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export interface CreateNewOrganizationState {}

const initialState: CreateNewOrganizationState = {}

export const createNewOrganizationSlice = createSlice({
  name: 'createNewOrganization',
  initialState,
  reducers: {
    createNewOrganizationOfCreateNewOrganizationAndProject(
      _state,
      _action: PayloadAction<{ organizationName: string; projectName: string; referralCode?: string }>,
    ) {},
    createNewProjectOfCreateNewOrganizationAndProject(
      _state,
      _action: PayloadAction<{ organizationName: string; projectName: string; referralCode?: string }>,
    ) {},
  },
})

export const {
  createNewOrganizationOfCreateNewOrganizationAndProject,
  createNewProjectOfCreateNewOrganizationAndProject,
} = createNewOrganizationSlice.actions
