import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { Button } from '../../../../components/Button'
import { ButtonLink } from '../../../../components/base'
import { Modal } from '../../../../components/block/Modal'
import { RecipeInstancesQuotaExceededModalMessages } from '../../messages'

export interface RecipeInstancesQuotaExceededModalProps {
  /** The link for users to request an increase to their recipe deployment instance quota */
  linkToRequestIncreaseForRecipeDeploymentInstancesQuotas: string
  /** The callback executed when the user clicks the `Cancel` button. */
  onCancel: () => void
}
export const RecipeInstancesQuotaExceededModal: FunctionComponent<RecipeInstancesQuotaExceededModalProps> = ({
  linkToRequestIncreaseForRecipeDeploymentInstancesQuotas,
  onCancel,
}) => {
  const intl = useIntl()

  return (
    <Modal onClose={onCancel} title={intl.formatMessage(RecipeInstancesQuotaExceededModalMessages.title)}>
      <h1 className="mb-8 text-3xl font-bold">{intl.formatMessage(RecipeInstancesQuotaExceededModalMessages.title)}</h1>
      <p className="mb-8">{intl.formatMessage(RecipeInstancesQuotaExceededModalMessages.body1)}</p>
      <p className="mb-8">{intl.formatMessage(RecipeInstancesQuotaExceededModalMessages.body2)}</p>
      <div className="flex max-w-md gap-6">
        <Button variant="green-outlined" onClick={onCancel} isFullWidth>
          {intl.formatMessage(RecipeInstancesQuotaExceededModalMessages.cancelButtonLabel)}
        </Button>
        <ButtonLink isFullWidth url={linkToRequestIncreaseForRecipeDeploymentInstancesQuotas}>
          {intl.formatMessage(RecipeInstancesQuotaExceededModalMessages.requestIncreaseButtonLabel)}
        </ButtonLink>
      </div>
    </Modal>
  )
}
