import { defineMessages } from 'react-intl'

export const CreateJobQueueLimitErrorContentMessages = defineMessages({
  body: {
    defaultMessage: 'The Job Queue Limit has been reached. You must delete a Job Queue before creating a new one.',
    id: 'Zt4qWg1/',
    description:
      'The description shown in a toast notification message shown when we encounter an error on their request to create a job queue when a limit is reached',
  },
  title: {
    defaultMessage: 'Job Queue Limit Reached',
    id: 'QixDJCGS',
    description:
      'The toast notification title shown when we encounter an error on their request to create a job queue when a limit is reached.',
  },
})
