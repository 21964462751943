import { catchError, concat, delay, filter, from, mergeMap, of } from 'rxjs'
import { InferenceEndpointsAPI, OrganizationsAPI, ProjectsAPI } from '../apiMethods'
import {
  getInferenceEndpointsMarketplacePageData,
  inferenceEndpointsAddedToOrganization,
} from '../features/inferenceEndpoints/inferenceEndpointsSlice'
import { showToastNotification } from '../features/notifications/notificationsSlice'
import { organizationAdded } from '../features/organizations/organizationsSlice'
import { projectsAddedToOrganization } from '../features/projects/projectsSlice'
import { setRequestStatus } from '../features/requestStatus/requestStatusSlice'
import { getInferenceEndpointsMarketplaceFailedErrorContent } from '../notifications/clientToastNotificationContent/inferenceEndpoints'
import type { AppEpic } from '../store'

export const onGetInferenceEndpointMarketplacePageData: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(getInferenceEndpointsMarketplacePageData.match),
    mergeMap(({ payload: { organizationName } }) =>
      concat(
        of(setRequestStatus({ request: 'getInferenceEndpointsMarketplacePageData', status: 'pending' })),
        from(
          Promise.all([
            InferenceEndpointsAPI.listInferenceEndpoints({
              organizationName,
            }),
            OrganizationsAPI.getOrganization({
              organizationName,
            }),
            ProjectsAPI.listProjects({
              organizationName,
            }),
          ]),
        ).pipe(
          mergeMap(([inferenceEndpointsResponse, organizationResponse, projectsResponse]) =>
            concat(
              of(
                setRequestStatus({ request: 'getInferenceEndpointsMarketplacePageData', status: 'succeeded' }),
                inferenceEndpointsAddedToOrganization({
                  organizationName,
                  inferenceEndpoints: inferenceEndpointsResponse.items,
                }),
                organizationAdded(organizationResponse),
                projectsAddedToOrganization({
                  organizationName: organizationResponse.name,
                  projects: projectsResponse.items,
                }),
              ),
              of(setRequestStatus({ request: 'getInferenceEndpointsMarketplacePageData', status: 'idle' })).pipe(
                delay(1),
              ),
            ),
          ),
          catchError(() =>
            concat(
              of(
                setRequestStatus({ request: 'getInferenceEndpointsMarketplacePageData', status: 'failed' }),
                showToastNotification(getInferenceEndpointsMarketplaceFailedErrorContent(intl)),
              ),
              of(setRequestStatus({ request: 'getInferenceEndpointsMarketplacePageData', status: 'idle' })).pipe(
                delay(1),
              ),
            ),
          ),
        ),
      ),
    ),
  )
