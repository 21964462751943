import { yupResolver } from '@hookform/resolvers/yup'
import type { FunctionComponent } from 'react'
import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Button } from '../../../components/Button'
import { TextField } from '../../../components/TextField'
import { useFixedForm } from '../../../hooks'
import { ModalLayout } from '../../../layouts/authentication'
import { UpdatePasswordMessages } from './UpdatePasswordMessages'
import { useUpdatePasswordFormValidation } from './useUpdatePasswordFormValidation'

export interface UpdatePasswordFormValues {
  /** The password. */
  password: string
  /** The repeated password. */
  verifyPassword: string
}

export interface UpdatePasswordProps {
  /** The flag indicating if the onSubmit request is pending */
  isSubmitPending: boolean
  /** The submit handler */
  onSubmit: (values: UpdatePasswordFormValues) => void
}

export const UpdatePassword: FunctionComponent<UpdatePasswordProps> = ({ isSubmitPending, onSubmit }) => {
  const intl = useIntl()
  const updatePasswordFormValidationSchema = useUpdatePasswordFormValidation()
  const { control, handleSubmit } = useFixedForm<UpdatePasswordFormValues>({
    resolver: yupResolver(updatePasswordFormValidationSchema),
    onSubmit,
  })

  return (
    <ModalLayout type="narrow">
      <form className="flex flex-col" onSubmit={handleSubmit}>
        <h1 className="pb-8 text-2xl font-bold">{intl.formatMessage(UpdatePasswordMessages.title)}</h1>
        <Controller
          name="password"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              {...fieldState}
              isExtraHeight
              type="password"
              helperText={intl.formatMessage(UpdatePasswordMessages.passwordHelperText)}
              placeholder={intl.formatMessage(UpdatePasswordMessages.passwordPlaceholder)}
              leftIconClassName="fa-solid fa-lock"
              isDisabled={isSubmitPending}
              label={intl.formatMessage(UpdatePasswordMessages.passwordLabel)}
              isFullWidth
            />
          )}
        />
        <Controller
          name="verifyPassword"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              {...fieldState}
              type="password"
              helperText={fieldState.error?.message}
              placeholder={intl.formatMessage(UpdatePasswordMessages.passwordPlaceholder)}
              leftIconClassName="fa-solid fa-lock"
              isDisabled={isSubmitPending}
              label={intl.formatMessage(UpdatePasswordMessages.verifyPasswordLabel)}
              isFullWidth
            />
          )}
        />
        <Button type="submit" isDisabled={isSubmitPending} isLoading={isSubmitPending}>
          {intl.formatMessage(UpdatePasswordMessages.updatePasswordButtonLabel)}
        </Button>
      </form>
    </ModalLayout>
  )
}
