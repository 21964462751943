import { catchError, concat, delay, filter, from, mergeMap, of } from 'rxjs'
import { QueuesAPI } from '../apiMethods'
import { deleteJobQueue } from '../features/jobQueues/jobQueuesSlice'
import { showToastNotification } from '../features/notifications/notificationsSlice'
import { setRequestStatus } from '../features/requestStatus/requestStatusSlice'
import { getDeleteJobQueueErrorContent } from '../notifications/clientToastNotificationContent/jobQueues'
import { getJobQueuesPagePath } from '../routes/routes-utils'
import type { AppEpic } from '../store'
import { navigateTo } from './navigationEpic'

export const onDeleteJobQueue: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(deleteJobQueue.match),
    mergeMap(({ payload: { organizationName, projectName, queueName } }) =>
      concat(
        of(setRequestStatus({ request: 'deleteJobQueue', status: 'pending' })),
        from(
          QueuesAPI.deleteQueue({
            organizationName,
            projectName,
            queueName,
          }),
        ).pipe(
          mergeMap(() => {
            return concat(
              of(
                setRequestStatus({ request: 'deleteJobQueue', status: 'succeeded' }),
                navigateTo({ path: getJobQueuesPagePath(organizationName, projectName) }),
              ),
              of(setRequestStatus({ request: 'deleteJobQueue', status: 'idle' })).pipe(delay(1)),
            )
          }),
          catchError(() =>
            concat(
              of(
                setRequestStatus({ request: 'deleteJobQueue', status: 'failed' }),
                showToastNotification(getDeleteJobQueueErrorContent(intl)),
              ),
              of(setRequestStatus({ request: 'deleteJobQueue', status: 'idle' })).pipe(delay(1)),
            ),
          ),
        ),
      ),
    ),
  )
