import { defineMessages } from 'react-intl'

export const ContainerGroupInstanceTerminalNodeDoesNotSupportLogStreamingErrorMessages = defineMessages({
  title: {
    defaultMessage: 'Unable to connect to terminal',
    id: 'SK9U8gIB',
    description:
      'The toast notification title shown when the user tries to connect to a terminal that does not support log streaming',
  },
  body: {
    defaultMessage: 'This container group instance does not support log streaming.',
    id: '8sZ66kHH',
    description:
      'The toast notification body shown when the user tries to connect to a terminal that does not support log streaming',
  },
})
