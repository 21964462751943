import type {
  ContainerGroupLivenessProbe,
  ContainerGroupReadinessProbe,
  ContainerGroupStartupProbe,
} from '@saladtechnologies/openapi-cloud-portal-browser'
import { ProtocolOptions } from '../../../../CreateContainerGroup/models'

export const determineHealthProbeProtocol = (
  probe: ContainerGroupLivenessProbe | ContainerGroupReadinessProbe | ContainerGroupStartupProbe | null | undefined,
) => {
  let protocol: ProtocolOptions = ProtocolOptions.EXEC
  if (probe && probe.exec) {
    protocol = ProtocolOptions.EXEC
  }

  if (probe && probe.grpc) {
    protocol = ProtocolOptions.GRPC
  }

  if (probe && probe.tcp) {
    protocol = ProtocolOptions.TCP
  }

  if (probe && probe.http) {
    protocol = ProtocolOptions.HTTP1X
  }

  return protocol
}
