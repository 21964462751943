import type { FunctionComponent } from 'react'
import { forwardRef, useState } from 'react'
import type { Control, FieldValues, UseFormResetField, UseFormTrigger } from 'react-hook-form'
import { Controller, useFormState, useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Button } from '../../../../components/Button'
import { InputLabel } from '../../../../components/InputLabel'
import { TextField } from '../../../../components/TextField'
import { Link } from '../../../../components/base'
import { SidePanelModal } from '../../../../components/block/SidePanelModal'
import { FormStateCard } from '../../../../components/forms/FormStateCard'
import { RadioTabs } from '../../../../components/forms/RadioTabs'
import { Select } from '../../../../components/forms/Select'
import {
  EditContainerGroupMainContentMessages,
  EditImageSourceInputMessages,
  EditImageTypeInputMessages,
  EditSelectPrivateRegistryProviderMessages,
} from '../../messages'
import type { EditContainerGroupFormSectionIdAttributes, EditContainerGroupValues } from '../../models'
import { EditContainerGroupField, ImageType, PrivateRegistryProvider } from '../../models'
import { EditAWSElasticInputFields } from './components/EditAWSElasticInputFields'
import { EditAzureInputFields } from './components/EditAzureInputFields'
import { EditDockerHubInputFields } from './components/EditDockerHubInputFields'
import { EditGitHubInputFields } from './components/EditGitHubInputFields'
import {
  EditGoogleArtifactRegistryInputFields,
  EditGoogleContainerRegistryInputFields,
} from './components/EditGoogleInputFields'
import { EditQuayInputFields } from './components/EditQuayInputFields'
import { EditSelfHostedInputFields } from './components/EditSelfHostedInputFields'
import { checkIfEditImageSourceFieldsHaveErrors, editImageSourceFieldsList, privateRegistryFieldsList } from './utils'

interface EditImageSourceFieldsProps {
  /** The control for the edit container group react hook form. */
  control: Control<FieldValues, EditContainerGroupValues>
  /** The current image source value. */
  currentImageSource: string
  /** The current image source hash */
  currentImageSourceHash?: string
  /** The id attribute for the fields. */
  id: EditContainerGroupFormSectionIdAttributes
  /** The react hook form method that provides the ability to reset a specified field value. */
  resetField: UseFormResetField<FieldValues>
  /** The react hook form method that triggers validation for specified fields. */
  trigger: UseFormTrigger<FieldValues>
}

export const EditImageSourceFields: FunctionComponent<EditImageSourceFieldsProps> = forwardRef<
  HTMLDivElement,
  EditImageSourceFieldsProps
>(({ control, currentImageSource, currentImageSourceHash, id, resetField, trigger }, ref) => {
  const intl = useIntl()
  const [isSidePanelOpen, setSidePanelOpenState] = useState<boolean>(false)
  const [localPrivateRegistryProvider, setPrivateRegistryProvider] = useState<string | undefined>(undefined)
  const { errors } = useFormState({ control })
  const imageSource = useWatch({ control, name: EditContainerGroupField.IMAGE_SOURCE })
  const imageType = useWatch({ control, name: EditContainerGroupField.IMAGE_TYPE, defaultValue: ImageType.PUBLIC })
  const imageSourceFieldsHaveError = checkIfEditImageSourceFieldsHaveErrors(errors)
  const privateRegistryProvider = useWatch({ control, name: EditContainerGroupField.PRIVATE_REGISTRY_PROVIDER })

  const handleValidateFieldsBeforeClose = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    await trigger(editImageSourceFieldsList).then((isValid) => {
      if (isValid) {
        setSidePanelOpenState(false)
      }
    })
  }

  return (
    <div className="mb-10" id={id} ref={ref}>
      <FormStateCard
        hasError={imageSourceFieldsHaveError}
        onEditForm={() => setSidePanelOpenState(true)}
        title={intl.formatMessage(EditContainerGroupMainContentMessages.imageSourceSectionHeader)}
      >
        <InputLabel
          label={intl.formatMessage(EditImageSourceInputMessages.imageSourceLabel)}
          hasError={imageSourceFieldsHaveError}
        />
        <p className="truncate">{imageSource || currentImageSource}</p>
        {!imageSource && currentImageSourceHash && <p>{currentImageSourceHash}</p>}
      </FormStateCard>

      <SidePanelModal
        CustomButton={
          <Button variant="green-filled" isFullWidth type="submit" form="updateImageSourceFieldsForm">
            {intl.formatMessage(EditContainerGroupMainContentMessages.configureButtonLabel)}
          </Button>
        }
        isShown={isSidePanelOpen}
        onClose={() => setSidePanelOpenState(false)}
        title={intl.formatMessage(EditImageSourceInputMessages.imageSourceSidePanelTitle)}
      >
        <div className="mt-12 px-10">
          <div className="mb-10">
            <h2 className="mb-2 text-3xl font-bold">
              {intl.formatMessage(EditImageSourceInputMessages.imageSourceSidePanelTitle)}
            </h2>
            <p>
              {intl.formatMessage(EditImageSourceInputMessages.dockerManifestHelperText, {
                learn_more_here_link: (
                  <Link url="https://docs.salad.com/container-engine/container-registries#specifying-the-container-image-source">
                    {intl.formatMessage(EditImageSourceInputMessages.dockerManifestHelperTextLinkText)}
                  </Link>
                ),
              })}
            </p>
          </div>

          <form onSubmit={handleValidateFieldsBeforeClose} id="updateImageSourceFieldsForm">
            <Controller
              name={EditContainerGroupField.IMAGE_SOURCE}
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    {...field}
                    {...fieldState}
                    helperText={
                      fieldState.error?.message ??
                      intl.formatMessage(EditImageSourceInputMessages.imageSourceHelperText, {
                        learn_more: (
                          <Link url="https://docs.salad.com/container-engine/container-registries#specifying-the-container-image-source">
                            {intl.formatMessage(EditImageSourceInputMessages.imageSourceHelperLearnMoreLinkText)}
                          </Link>
                        ),
                      })
                    }
                    label={intl.formatMessage(EditImageSourceInputMessages.imageSourceLabel)}
                    isFullWidth
                  />
                )
              }}
            />

            <Controller
              name={EditContainerGroupField.IMAGE_TYPE}
              control={control}
              defaultValue={ImageType.PUBLIC}
              render={({ field, fieldState }) => {
                return (
                  <RadioTabs
                    {...field}
                    {...fieldState}
                    onChange={(value) => {
                      if (value === ImageType.PUBLIC) {
                        resetField(EditContainerGroupField.PRIVATE_REGISTRY_PROVIDER)
                        privateRegistryFieldsList.forEach((field) => {
                          resetField(field)
                        })
                        // This is needed to trigger the UI updating for the private registry provider field
                        setPrivateRegistryProvider(
                          intl.formatMessage(EditSelectPrivateRegistryProviderMessages.placeholderText),
                        )
                      }
                      field.onChange(value)
                    }}
                    tabs={[
                      {
                        label: intl.formatMessage(EditImageTypeInputMessages.publicRegistrylabel),
                        value: ImageType.PUBLIC,
                      },
                      {
                        label: intl.formatMessage(EditImageTypeInputMessages.privateRegistrylabel),
                        value: ImageType.PRIVATE,
                      },
                    ]}
                    label={intl.formatMessage(EditImageTypeInputMessages.label)}
                  />
                )
              }}
            />

            <div className="mt-16">
              <div className="mb-16">
                <Controller
                  name={EditContainerGroupField.PRIVATE_REGISTRY_PROVIDER}
                  control={control}
                  render={({ field, fieldState }) => {
                    return (
                      <Select
                        {...field}
                        {...fieldState}
                        defaultSelectedValue={localPrivateRegistryProvider}
                        onChange={(value) => {
                          field.onChange(value)
                          // This is needed to trigger the UI updating for the private registry provider field
                          setPrivateRegistryProvider(value)
                        }}
                        labelText={intl.formatMessage(EditSelectPrivateRegistryProviderMessages.label)}
                        options={[
                          {
                            label: intl.formatMessage(EditSelectPrivateRegistryProviderMessages.awsElasticLabel),
                            value: PrivateRegistryProvider.AWS_ECR,
                          },
                          {
                            label: intl.formatMessage(EditSelectPrivateRegistryProviderMessages.azureLabel),
                            value: PrivateRegistryProvider.AZURE_CONTAINER,
                          },
                          {
                            label: intl.formatMessage(EditSelectPrivateRegistryProviderMessages.dockerHubLabel),
                            value: PrivateRegistryProvider.DOCKER_HUB,
                          },
                          {
                            label: intl.formatMessage(EditSelectPrivateRegistryProviderMessages.gitHubLabel),
                            value: PrivateRegistryProvider.GITHUB_CONTAINER,
                          },
                          {
                            label: intl.formatMessage(
                              EditSelectPrivateRegistryProviderMessages.googleArtifactRegistryLabel,
                            ),
                            value: PrivateRegistryProvider.GOOGLE_ARTIFACT_REGISTRY,
                          },
                          {
                            label: intl.formatMessage(
                              EditSelectPrivateRegistryProviderMessages.googleContainerRegistryLabel,
                            ),
                            value: PrivateRegistryProvider.GOOGLE_CONTAINER_REGISTRY,
                          },
                          {
                            label: intl.formatMessage(EditSelectPrivateRegistryProviderMessages.selfHostedLabel),
                            value: PrivateRegistryProvider.SELF_HOSTED,
                          },
                          {
                            label: intl.formatMessage(EditSelectPrivateRegistryProviderMessages.quayLabel),
                            value: PrivateRegistryProvider.QUAY_CONTAINER,
                          },
                        ]}
                        placeholderText={intl.formatMessage(EditSelectPrivateRegistryProviderMessages.placeholderText)}
                        isDisabled={imageType === ImageType.PUBLIC}
                        isFullWidth
                      />
                    )
                  }}
                />
              </div>

              {privateRegistryProvider === PrivateRegistryProvider.AWS_ECR && (
                <EditAWSElasticInputFields control={control} />
              )}

              {privateRegistryProvider === PrivateRegistryProvider.AZURE_CONTAINER && (
                <EditAzureInputFields control={control} />
              )}

              {privateRegistryProvider === PrivateRegistryProvider.DOCKER_HUB && (
                <EditDockerHubInputFields control={control} />
              )}

              {privateRegistryProvider === PrivateRegistryProvider.GITHUB_CONTAINER && (
                <EditGitHubInputFields control={control} />
              )}

              {privateRegistryProvider === PrivateRegistryProvider.GOOGLE_ARTIFACT_REGISTRY && (
                <EditGoogleArtifactRegistryInputFields control={control} />
              )}

              {privateRegistryProvider === PrivateRegistryProvider.GOOGLE_CONTAINER_REGISTRY && (
                <EditGoogleContainerRegistryInputFields control={control} />
              )}

              {privateRegistryProvider === PrivateRegistryProvider.QUAY_CONTAINER && (
                <EditQuayInputFields control={control} />
              )}

              {privateRegistryProvider === PrivateRegistryProvider.SELF_HOSTED && (
                <EditSelfHostedInputFields control={control} />
              )}
            </div>
          </form>
        </div>
      </SidePanelModal>
    </div>
  )
})
