import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { Button } from '../../../components/Button'
import { VerificationSuccessMessages } from '../messages'

export interface VerificationSuccessProps {
  /*
   The "Log In" button handler
  */
  onViewLoginPage: () => void
}

export const VerificationSuccess: FunctionComponent<VerificationSuccessProps> = ({ onViewLoginPage }) => {
  const intl = useIntl()

  return (
    <>
      <h1 className="text-2xl font-bold">{intl.formatMessage(VerificationSuccessMessages.title)}</h1>
      <p className="text-base">{intl.formatMessage(VerificationSuccessMessages.description)}</p>
      <Button onClick={onViewLoginPage}>{intl.formatMessage(VerificationSuccessMessages.loginButtonLabel)}</Button>
    </>
  )
}
