import { InferenceEndpointJobStatusEnum } from '@saladtechnologies/openapi-cloud-portal-browser'
import { IntlShape } from 'react-intl'
import { InferenceEndpointDetailsMainContentMessages } from './messages'

/**
 * Converts the JSON object to a markdown code block. For any arrays in the JSON object, it will only convert the first
 * 50 items due to performance concerns. The text and srt_content fields are also limited to a maximum of 10,000
 * characters.
 *
 * @param json - The JSON object to convert
 * @returns The JSON object as a markdown code block
 */
export const convertInferenceEndpointJsonOutputToMarkdown = (json: object, intl: IntlShape) => {
  let isJsonOutputCondensed = false
  const maxArrayLength = 50
  const maxStringLength = 10000

  const inferenceEndpointJsonOutput = JSON.parse(
    JSON.stringify(json, (_key, value) => {
      if (Array.isArray(value) && value.length > maxArrayLength) {
        isJsonOutputCondensed = true
        return value.slice(0, maxArrayLength)
      }
      if (value.length > maxStringLength) {
        isJsonOutputCondensed = true
        return `${value.slice(0, maxStringLength)}...`
      }
      return value
    }),
  )
  return `\`\`\`json
  ${isJsonOutputCondensed ? intl.formatMessage(InferenceEndpointDetailsMainContentMessages.jsonOutputPreviewNote) : ''}
  ${JSON.stringify(inferenceEndpointJsonOutput, null, 2)}
\`\`\``
}

/**
 * Check if the inference endpoint job has reached a terminal status.
 *
 * @param jobStatus
 * @returns
 */
export const isInferenceEndpointJobTerminal = (jobStatus: InferenceEndpointJobStatusEnum | undefined) =>
  jobStatus === InferenceEndpointJobStatusEnum.Failed || jobStatus === InferenceEndpointJobStatusEnum.Cancelled

/**
 * Check if the inference endpoint job has reached a final status.
 *
 * @param jobStatus - The status of the inference endpoint job
 * @returns True if the inference endpoint job has reached a final status, false otherwise
 */
export const hasInferenceEndpointJobReachedFinalStatus = (jobStatus: InferenceEndpointJobStatusEnum | undefined) =>
  jobStatus === InferenceEndpointJobStatusEnum.Succeeded || isInferenceEndpointJobTerminal(jobStatus)

/**
 * Check if the inference endpoint job is running or pending.
 *
 * @param jobStatus - The status of the inference endpoint job
 * @returns True if the inference endpoint job is running or pending, false otherwise
 */
export const isInferenceEndpointJobRunningOrPending = (jobStatus: InferenceEndpointJobStatusEnum | undefined) =>
  jobStatus === InferenceEndpointJobStatusEnum.Running || jobStatus === InferenceEndpointJobStatusEnum.Pending

/**
 * Check if the inference endpoint job has successfully completed.
 *
 * @param jobStatus - The status of the inference endpoint job
 * @returns True if the inference endpoint job has successfully completed, false otherwise
 */
export const hasInferenceEndpointSuccessfullyCompleted = (jobStatus: InferenceEndpointJobStatusEnum | undefined) =>
  jobStatus === InferenceEndpointJobStatusEnum.Succeeded
