import type { FunctionComponent } from 'react'
import { LeftNavigationBar } from '../../components/LeftNavigationBar'
import { ThreeColumnLayout } from '../../layouts/threeColumnLayout'
import { AccountSettingsMainContent } from './components/AccountSettingsMainContent'

export interface AccountSettingsPageProps {
  /** The user's email address. */
  email: string
  /** The date the account was created. */
  createdDate: Date
  /** The flag indicating that the request to delete the account is pending. */
  isDeleteAccountPending: boolean
  /** The flag indicating if the left column is showing */
  isLeftColumnOpen: boolean
  /** The callback executed when the user closes the left drawer */
  onCloseLeftDrawer: () => void
  /** The callback executed when the user clicks the `Delete` button. */
  onDeleteAccount: (password: string) => void
}

export const AccountSettingsPage: FunctionComponent<AccountSettingsPageProps> = ({
  createdDate,
  email,
  isDeleteAccountPending,
  isLeftColumnOpen,
  onCloseLeftDrawer,
  onDeleteAccount,
}) => {
  return (
    <ThreeColumnLayout
      isLeftColumnOpen={isLeftColumnOpen}
      LeftColumn={<LeftNavigationBar />}
      MainColumn={
        <AccountSettingsMainContent
          email={email}
          createdDate={createdDate}
          isDeleteAccountPending={isDeleteAccountPending}
          onDeleteAccount={onDeleteAccount}
        />
      }
      onCloseLeftDrawer={onCloseLeftDrawer}
    />
  )
}
