import { yupResolver } from '@hookform/resolvers/yup'
import type { FunctionComponent } from 'react'
import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Button } from '../../../components/Button'
import { TextField } from '../../../components/TextField'
import { useFixedForm } from '../../../hooks'
import { ModalLayout } from '../../../layouts/authentication'
import { ForgotPasswordMessages } from './ForgotPasswordMessages'
import { useForgotPasswordFormValidation } from './useForgotPasswordFormValidation'

export interface ForgotPasswordValues {
  /** The email address. */
  email: string
}

export interface ForgotPasswordProps {
  /** The loading flag for the submit action */
  isSubmitPending: boolean
  /** The submit handler. */
  onSubmit: (values: ForgotPasswordValues) => void
}

export const ForgotPassword: FunctionComponent<ForgotPasswordProps> = ({ isSubmitPending, onSubmit }) => {
  const intl = useIntl()
  const forgotPasswordFormValidationScheme = useForgotPasswordFormValidation()
  const { control, handleSubmit } = useFixedForm<ForgotPasswordValues>({
    resolver: yupResolver(forgotPasswordFormValidationScheme),
    onSubmit,
  })

  return (
    <ModalLayout type="narrow">
      <form className="flex flex-col" onSubmit={handleSubmit}>
        <h1 className="pb-8 text-2xl font-bold">{intl.formatMessage(ForgotPasswordMessages.title)}</h1>
        <h2 className="pb-8">{intl.formatMessage(ForgotPasswordMessages.subTitle)}</h2>
        <Controller
          name="email"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              {...fieldState}
              helperText={fieldState.error?.message}
              placeholder={intl.formatMessage(ForgotPasswordMessages.emailPlaceholder)}
              leftIconClassName="fa-solid fa-envelope"
              isDisabled={isSubmitPending}
              label={intl.formatMessage(ForgotPasswordMessages.emailLabel)}
              isFullWidth
            />
          )}
        />
        <Button type="submit" isDisabled={isSubmitPending} isLoading={isSubmitPending}>
          {intl.formatMessage(ForgotPasswordMessages.submit)}
        </Button>
      </form>
    </ModalLayout>
  )
}
