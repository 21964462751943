import { defineMessages } from 'react-intl'

export const CreateOrganizationWithInvalidReferralCodeContentMessages = defineMessages({
  body: {
    defaultMessage: 'Please check the code is current and has not expired.',
    id: '5HRu67wJ',
    description:
      'The description shown in a toast notification message shown when the user has provided an invalid invite code.',
  },
  title: {
    defaultMessage: 'The invite code you used is invalid',
    id: 'TdtVKB5H',
    description: 'The toast notification title shown when the user has provided an invalid invite code.',
  },
})
