import type { FunctionComponent } from 'react'
import type { Control, FieldValues, UseFormClearErrors, UseFormTrigger } from 'react-hook-form'
import { Controller, useFieldArray, useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Button } from '../../../../../components/Button'
import { TextField } from '../../../../../components/TextField'
import { Card, Link } from '../../../../../components/base'
import { SidePanelModal } from '../../../../../components/block/SidePanelModal'
import { CommandSidePanelModalMessages } from '../../../messages'
import type { CreateContainerGroupValues, HealthProbeType } from '../../../models'

interface HealthProbeCommandSidePanelModalProps {
  /** The name for the argument field. */
  argumentFieldName: string
  /** The react hook form method that clears errors for specified fields. */
  clearErrors: UseFormClearErrors<FieldValues>
  /** The name for the command field. */
  commandFieldName: string
  /** The control for the create container group react hook form. */
  control: Control<FieldValues, CreateContainerGroupValues>
  /** The ID for the command side panel modal form. */
  formID: string
  /** The health probe the command is being added to for the side panel modal. */
  healthProbe: HealthProbeType
  /** The flag indicating if the side panel is open. */
  isShown: boolean
  /** The callback executed to close the modal. */
  onClose: () => void
  /** The react hook form method that triggers validation for specified fields. */
  trigger: UseFormTrigger<FieldValues>
}

export const HealthProbeCommandSidePanelModal: FunctionComponent<HealthProbeCommandSidePanelModalProps> = ({
  argumentFieldName,
  clearErrors,
  commandFieldName,
  control,
  formID,
  healthProbe,
  isShown,
  onClose,
  trigger,
}) => {
  const { append, fields, remove } = useFieldArray({
    control,
    name: argumentFieldName,
  })
  const commandValues = useWatch({ control, name: commandFieldName })
  const intl = useIntl()

  const handleValidateFieldsBeforeClose = async (event?: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault()
    await trigger([commandFieldName, argumentFieldName]).then((isValid) => {
      if (fields.length === 0 && commandValues === undefined) {
        clearErrors(commandFieldName)
        onClose()

        return
      }

      if (isValid) {
        onClose()
      }
    })
  }

  const handleRemoveArgument = async (index: number) => {
    await trigger([argumentFieldName]).then(() => {
      remove(index)
    })
  }

  return (
    <SidePanelModal
      CustomButton={
        <Button
          variant="green-outlined"
          onClick={() => handleValidateFieldsBeforeClose()}
          form={formID}
          isFullWidth
          type="button"
        >
          {intl.formatMessage(CommandSidePanelModalMessages.backButtonLabel)}
        </Button>
      }
      isShown={isShown}
      isStacked
      onClose={() => handleValidateFieldsBeforeClose()}
      title={intl.formatMessage(CommandSidePanelModalMessages.title, { health_probe: healthProbe })}
    >
      <form onSubmit={handleValidateFieldsBeforeClose} id={formID}>
        <div className="my-12 px-10 pb-12">
          <div className="mb-10">
            <h2 className="mb-1 text-3xl font-bold">
              {intl.formatMessage(CommandSidePanelModalMessages.title, { health_probe: healthProbe })}
            </h2>
            <div>
              {intl.formatMessage(CommandSidePanelModalMessages.description, {
                learn_more: (
                  <Link url="https://docs.salad.com/container-engine/health-probes#probe-configuration">
                    {intl.formatMessage(CommandSidePanelModalMessages.learMoreLinkText)}
                  </Link>
                ),
              })}
            </div>
          </div>

          <div className="mb-4">
            <Card>
              <div className="mb-5">
                <p className="text-lg font-bold">
                  {intl.formatMessage(CommandSidePanelModalMessages.commandInputTitle)}
                </p>
              </div>

              <Controller
                name={commandFieldName}
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <TextField
                      {...field}
                      {...fieldState}
                      defaultValue={field.value}
                      label={intl.formatMessage(CommandSidePanelModalMessages.commandFieldsLabel)}
                      placeholder={intl.formatMessage(CommandSidePanelModalMessages.commandInputPlaceholderText)}
                      isFullWidth
                      isTextarea
                      type="text"
                    />
                  )
                }}
              />
            </Card>
          </div>

          {fields.map((item, index) => (
            <div className="mb-4" key={item.id}>
              <Card>
                <div className="mb-5 flex justify-between">
                  <p className="text-lg font-bold">
                    {intl.formatMessage(CommandSidePanelModalMessages.argumentInputTitle, {
                      count: index + 1,
                    })}
                  </p>
                  <Button variant="red-outlined" onClick={() => handleRemoveArgument(index)} type="button">
                    {intl.formatMessage(CommandSidePanelModalMessages.removeButtonLabel)}
                  </Button>
                </div>

                <Controller
                  name={`${argumentFieldName}.${index}.argument`}
                  control={control}
                  render={({ field, fieldState }) => {
                    return (
                      <TextField
                        {...field}
                        {...fieldState}
                        defaultValue={field.value}
                        label={intl.formatMessage(CommandSidePanelModalMessages.commandFieldsLabel)}
                        placeholder={intl.formatMessage(CommandSidePanelModalMessages.argumentInputPlaceholderText)}
                        isFullWidth
                        isTextarea
                        type="text"
                      />
                    )
                  }}
                />
              </Card>
            </div>
          ))}

          <Button
            variant="green-filled-light"
            onClick={() => append({ argument: '' })}
            type="button"
            isDisabled={fields.length === 100}
          >
            {intl.formatMessage(CommandSidePanelModalMessages.addArgumentButtonLabel)}
          </Button>
        </div>
      </form>
    </SidePanelModal>
  )
}
