import { defineMessages } from 'react-intl'

export const LoadCustomBillingPortalNotReadyErrorMessages = defineMessages({
  body: {
    defaultMessage:
      'Retrieving your information is taking longer than usual. Please try again, if the issue persists, please {contact_support}.',
    id: 't2hX3KC4',
    description:
      "The description shown in a toast notification message shown when we encounter an error loading the user's custom billing portal.",
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: '1Z4qbwqJ',
    description:
      "The text shown for the contact support link in the load user's custom billing portal error toast notification.",
  },
  title: {
    defaultMessage: 'Unable to access billing',
    id: 'BfQV7Pv2',
    description:
      "The toast notification title shown when we encounter an error loading the user's custom billing portal.",
  },
})
