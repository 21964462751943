import type { PayloadAction } from '@reduxjs/toolkit'
import { createAction, createSlice } from '@reduxjs/toolkit'
import type { BillingPaymentMethodSetupIntent } from '@saladtechnologies/openapi-cloud-portal-browser'

export interface BillingState {
  setupIntent: BillingPaymentMethodSetupIntent | undefined
}

const initialState: BillingState = {
  setupIntent: undefined,
}

export const billingSlice = createSlice({
  name: 'billing',
  initialState,
  reducers: {
    getBillingCustomerPortal(_state, _action: PayloadAction<{ organizationName: string; redirectPath: string }>) {},
    setPaymentMethodSetupIntent(
      state,
      action: PayloadAction<{ setupIntent: BillingPaymentMethodSetupIntent | undefined }>,
    ) {
      state.setupIntent = action.payload.setupIntent
    },
    unsubscribeFromBilling(_state, _action: PayloadAction<{ organizationName: string }>) {},
  },
})

export const getPaymentMethodSetupStatus = createAction<{ organizationName: string }>(
  'billing/getPaymentMethodSetupStatus',
)

export const getPaymentMethodSetupStatusAfterProcessingState = createAction<{ organizationName: string }>(
  'billing/getPaymentMethodSetupStatusAfterProcessingState',
)
export const createPaymentMethodSetupIntent = createAction<{ organizationName: string }>(
  'billing/createPaymentMethodSetupIntent',
)
export const showStripeFormError = createAction<{ message?: string; status?: string }>('billing/showStripeFormError')
export const getBillingPageData = createAction<{ organizationName: string }>('billing/getBillingPageData')
export const fetchPaymentMethod = createAction<{ organizationName: string }>('billing/fetchPaymentMethod')
export const removePaymentMethod = createAction<{ organizationName: string }>('billing/removePaymentMethod')
export const { getBillingCustomerPortal, setPaymentMethodSetupIntent, unsubscribeFromBilling } = billingSlice.actions
