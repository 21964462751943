import { defineMessages } from 'react-intl'

export const CheckboxCardsMessages = defineMessages({
  noneSelectedLabel: {
    defaultMessage: 'None Selected',
    id: '6zvsTf1w',
    description: 'The label for the checkbox cards option that deselects all options',
  },
  selectAllLabel: {
    defaultMessage: 'Select All',
    id: 'NP5K89qS',
    description: 'The label for the checkbox cards option that selects all options',
  },
})
