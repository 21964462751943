import type { PayloadAction } from '@reduxjs/toolkit'
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import type { WorkloadError } from '@saladtechnologies/openapi-cloud-portal-browser'
import { configuresContainerGroupWorkloadsErrorsEntityId } from './utils'

export interface ContainerGroupWorkloadErrorsProps {
  containerGroupName: string
  organizationName: string
  projectName: string
  workloadErrors: WorkloadError[]
}

export const containerGroupWorkloadErrorsAdapter = createEntityAdapter<ContainerGroupWorkloadErrorsProps>({
  selectId: (item) =>
    configuresContainerGroupWorkloadsErrorsEntityId(item.containerGroupName, item.organizationName, item.projectName),
  sortComparer: (a, b) => a.containerGroupName.localeCompare(b.containerGroupName),
})

export const containerGroupWorkloadErrorsSlice = createSlice({
  name: 'containerGroupWorkloadErrors',
  initialState: containerGroupWorkloadErrorsAdapter.getInitialState(),
  reducers: {
    getContainerGroupWorkloadErrors(
      _state,
      _action: PayloadAction<{
        organizationName: string
        projectName: string
        containerGroupName: string
      }>,
    ) {},
    containerGroupWorkloadErrorsAdded: containerGroupWorkloadErrorsAdapter.upsertOne,
  },
})

export const { containerGroupWorkloadErrorsAdded, getContainerGroupWorkloadErrors } =
  containerGroupWorkloadErrorsSlice.actions
