import type { AppState } from '../../store'
import { projectsAdapter } from './projectsSlice'

const projectsSelectors = projectsAdapter.getSelectors()

export const selectProjects = (state: AppState, organizationName: string) =>
  projectsSelectors.selectById(state.projects, organizationName)

export const selectProjectDisplayName = (state: AppState, organizationName: string, projectName: string) =>
  projectsSelectors
    .selectById(state.projects, organizationName)
    ?.projects.find((project) => project.name === projectName)?.displayName

export const selectLatestProject = (state: AppState, organizationName: string) => {
  const projects = projectsSelectors.selectById(state.projects, organizationName)?.projects || []

  return projects.length > 0
    ? projects.reduce((prev, current) => (prev.createTime > current.createTime ? prev : current))
    : undefined
}
