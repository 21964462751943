import { defineMessages } from 'react-intl'

export const ResetPasswordSucceededMessages = defineMessages({
  title: {
    defaultMessage: 'Your password was reset!',
    id: 'VLnNSnBs',
    description: 'The title for the successful password reset.',
  },
  description: {
    defaultMessage: 'Ready to run on Salad’s network? ',
    id: 'D10h+zhw',
    description: 'The description for the successful password reset.',
  },
  submit: {
    defaultMessage: 'Log In',
    id: 'Ezg7QGX/',
    description: 'Login button label',
  },
})
