import { defineMessages } from 'react-intl'

export const AccessDomainNameCopiedToKeyboardSuccess = defineMessages({
  body: {
    defaultMessage: 'Access Domain Name has been copied to your clipboard',
    id: '7PhaN2lY',
    description:
      'The description shown in a toast notification message shown when the user successfully copied the Access Domain Name to their clipboard.',
  },
  title: {
    defaultMessage: 'Access Domain Name Copied',
    id: '+OsLPrPY',
    description:
      'The toast notification title shown when the user successfully copied the Access Domain Name to their clipboard.',
  },
})
