import { TimeRange } from './TimeRange'

export const TimeOffsets: Record<Exclude<TimeRange, TimeRange.Custom>, number> = {
  [TimeRange.FiveMinutes]: 5 * 60 * 1000,
  [TimeRange.ThirtyMinutes]: 30 * 60 * 1000,
  [TimeRange.OneHour]: 60 * 60 * 1000,
  [TimeRange.OneDay]: 24 * 60 * 60 * 1000,
  [TimeRange.ThreeDays]: 3 * 24 * 60 * 60 * 1000,
  [TimeRange.SevenDays]: 7 * 24 * 60 * 60 * 1000,
}
