import {
  ContainerLoggingHttpCompressionEnum,
  ContainerLoggingHttpFormatEnum,
} from '@saladtechnologies/openapi-cloud-portal-browser'
import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import type { ObjectSchema } from 'yup'
import { array, boolean, mixed, number, object, string } from 'yup'
import { ExternalLoggingService } from '../../components/containerGroups/models'
import { isRequiredImageSourceValid } from '../../utils/formValidation'
import {
  AWSElasticInputFieldsMessages,
  AxiomInputFieldsMessages,
  AzureInputFieldsMessages,
  CommandFieldsMessages,
  DatadogInputFieldsMessages,
  DockerHubInputFieldsMessages,
  EnvironmentVariablesFieldsMessages,
  GitHubInputFieldsMessages,
  HTTPInputFieldsMessages,
  HealthProbeComponentMessages,
  HealthProbeHeadersSidePanelModalMessages,
  ImageSourceInputMessages,
  NewRelicInputFieldsMessages,
  QuayInputFieldsMessages,
  SelfHostedInputFieldsMessages,
  SplunkInputFieldsMessages,
  TCPInputFieldsMessages,
} from './messages'
import type { CreateContainerGroupValues } from './models'
import { CreateContainerGroupField, ImageType, PrivateRegistryProvider, ProtocolOptions } from './models'

export const useCreateContainerGroupFormValidation = (
  maxReplicaCount: number,
): ObjectSchema<CreateContainerGroupValues> => {
  const intl = useIntl()
  const validationScheme = useMemo(
    () =>
      object().shape(
        {
          [CreateContainerGroupField.AUTOSTART_POLICY]: boolean().required(),
          [CreateContainerGroupField.AWS_ELASTIC_ACCESS_KEY_ID]: string().when(
            CreateContainerGroupField.PRIVATE_REGISTRY_PROVIDER,
            {
              is: PrivateRegistryProvider.AWS_ECR,
              then: (schema) =>
                schema.required(intl.formatMessage(AWSElasticInputFieldsMessages.accessKeyIDRequiredText)),
              otherwise: (schema) => schema.notRequired(),
            },
          ),
          [CreateContainerGroupField.AWS_ELASTIC_SECRET_ACCESS_KEY]: string().when(
            CreateContainerGroupField.PRIVATE_REGISTRY_PROVIDER,
            {
              is: PrivateRegistryProvider.AWS_ECR,
              then: (schema) =>
                schema.required(intl.formatMessage(AWSElasticInputFieldsMessages.secretAccessKeyRequiredText)),
              otherwise: (schema) => schema.notRequired(),
            },
          ),
          [CreateContainerGroupField.AXIOM_API_KEY]: string().when(CreateContainerGroupField.EXTERNAL_LOGGING_SERVICE, {
            is: ExternalLoggingService.AXIOM,
            then: (schema) =>
              schema
                .required(intl.formatMessage(AxiomInputFieldsMessages.apiKeyRequiredText))
                .max(1000, intl.formatMessage(AxiomInputFieldsMessages.apiKeyMaxLengthText)),
            otherwise: (schema) => schema.notRequired(),
          }),
          [CreateContainerGroupField.AXIOM_DATASET_NAME]: string().when(
            CreateContainerGroupField.EXTERNAL_LOGGING_SERVICE,
            {
              is: ExternalLoggingService.AXIOM,
              then: (schema) =>
                schema
                  .required(intl.formatMessage(AxiomInputFieldsMessages.datasetNameRequiredText))
                  .max(1000, intl.formatMessage(AxiomInputFieldsMessages.datasetNameMaxLengthText)),
              otherwise: (schema) => schema.notRequired(),
            },
          ),
          [CreateContainerGroupField.AXIOM_HOST]: string().when(CreateContainerGroupField.EXTERNAL_LOGGING_SERVICE, {
            is: ExternalLoggingService.AXIOM,
            then: (schema) =>
              schema
                .required(intl.formatMessage(AxiomInputFieldsMessages.hostRequiredText))
                .matches(/^(?!https?:\/\/).*$/, intl.formatMessage(AxiomInputFieldsMessages.hostValidationText))
                .max(1000, intl.formatMessage(AxiomInputFieldsMessages.hostMaxLengthText)),
            otherwise: (schema) => schema.notRequired(),
          }),
          [CreateContainerGroupField.AZURE_PASSWORD]: string().when(
            CreateContainerGroupField.PRIVATE_REGISTRY_PROVIDER,
            {
              is: PrivateRegistryProvider.AZURE_CONTAINER,
              then: (schema) => schema.required(intl.formatMessage(AzureInputFieldsMessages.passwordRequiredText)),
              otherwise: (schema) => schema.notRequired(),
            },
          ),
          [CreateContainerGroupField.AZURE_USERNAME]: string().when(
            CreateContainerGroupField.PRIVATE_REGISTRY_PROVIDER,
            {
              is: PrivateRegistryProvider.AZURE_CONTAINER,
              then: (schema) => schema.required(intl.formatMessage(AzureInputFieldsMessages.usernameRequiredText)),
              otherwise: (schema) => schema.notRequired(),
            },
          ),
          [CreateContainerGroupField.COMMAND]: string().when(CreateContainerGroupField.COMMAND_ARGUMENTS, {
            is: (commandArguments: { argument: string }[] | undefined) =>
              commandArguments && commandArguments?.length > 0,
            then: (schema) =>
              schema
                .matches(/^(?!docker).*$/, intl.formatMessage(CommandFieldsMessages.dockerErrorMessage))
                .required(intl.formatMessage(CommandFieldsMessages.commandFieldErrorMessage)),
            otherwise: (schema) =>
              schema
                .matches(/^(?!docker).*$/, intl.formatMessage(CommandFieldsMessages.dockerErrorMessage))
                .notRequired(),
          }),
          [CreateContainerGroupField.COMMAND_ARGUMENTS]: array().of(object().shape({ argument: string() })),
          [CreateContainerGroupField.DATADOG_API]: string().when(CreateContainerGroupField.EXTERNAL_LOGGING_SERVICE, {
            is: ExternalLoggingService.DATADOG,
            then: (schema) => schema.required(intl.formatMessage(DatadogInputFieldsMessages.apiKeyRequiredText)),
            otherwise: (schema) => schema.notRequired(),
          }),
          [CreateContainerGroupField.DATADOG_HOST]: string().when(CreateContainerGroupField.EXTERNAL_LOGGING_SERVICE, {
            is: ExternalLoggingService.DATADOG,
            then: (schema) =>
              schema
                .matches(/^(?!https?:\/\/).*$/, intl.formatMessage(DatadogInputFieldsMessages.hostValidationText))
                .required(intl.formatMessage(DatadogInputFieldsMessages.hostRequiredText)),
            otherwise: (schema) => schema.notRequired(),
          }),
          [CreateContainerGroupField.DATADOG_TAGS]: array().of(
            object().shape({
              name: string().required(intl.formatMessage(DatadogInputFieldsMessages.nameRequiredText)),
              value: string().required(intl.formatMessage(DatadogInputFieldsMessages.valueRequiredText)),
            }),
          ),
          [CreateContainerGroupField.DISK_SPACE]: number(),
          [CreateContainerGroupField.DOCKER_HUB_PERSONAL_ACCESS_TOKEN]: string().when(
            CreateContainerGroupField.PRIVATE_REGISTRY_PROVIDER,
            {
              is: PrivateRegistryProvider.DOCKER_HUB,
              then: (schema) =>
                schema.required(intl.formatMessage(DockerHubInputFieldsMessages.personalAccessTokenRequiredText)),
              otherwise: (schema) => schema.notRequired(),
            },
          ),
          [CreateContainerGroupField.DOCKER_HUB_USERNAME]: string().when(
            CreateContainerGroupField.PRIVATE_REGISTRY_PROVIDER,
            {
              is: PrivateRegistryProvider.DOCKER_HUB,
              then: (schema) => schema.required(intl.formatMessage(DockerHubInputFieldsMessages.usernameRequiredText)),
              otherwise: (schema) => schema.notRequired(),
            },
          ),
          [CreateContainerGroupField.ENVIRONMENT_VARIABLES]: array()
            .of(
              object().shape({
                key: string()
                  .matches(/^[A-Za-z]\w*$/, intl.formatMessage(EnvironmentVariablesFieldsMessages.keyInvalid))
                  .required(intl.formatMessage(EnvironmentVariablesFieldsMessages.keyRequiredText)),
                value: string(),
              }),
            )
            .test({
              message: intl.formatMessage(EnvironmentVariablesFieldsMessages.keyMustBeUnique),
              test: (value) => value?.length === new Set(value?.map((item) => item.key)).size,
            }),
          [CreateContainerGroupField.EXTERNAL_LOGGING_SERVICE]: string().oneOf(Object.values(ExternalLoggingService)),
          [CreateContainerGroupField.GIT_HUB_PERSONAL_ACCESS_TOKEN]: string().when(
            CreateContainerGroupField.PRIVATE_REGISTRY_PROVIDER,
            {
              is: PrivateRegistryProvider.GITHUB_CONTAINER,
              then: (schema) =>
                schema.required(intl.formatMessage(GitHubInputFieldsMessages.personalAccessTokenRequiredText)),
              otherwise: (schema) => schema.notRequired(),
            },
          ),
          [CreateContainerGroupField.GIT_HUB_USERNAME]: string().when(
            CreateContainerGroupField.PRIVATE_REGISTRY_PROVIDER,
            {
              is: PrivateRegistryProvider.GITHUB_CONTAINER,
              then: (schema) => schema.required(intl.formatMessage(GitHubInputFieldsMessages.usernameRequiredText)),
              otherwise: (schema) => schema.notRequired(),
            },
          ),
          [CreateContainerGroupField.GOOGLE_ARTIFACT_JSON_KEY]: mixed<FileList>().when(
            CreateContainerGroupField.PRIVATE_REGISTRY_PROVIDER,
            {
              is: PrivateRegistryProvider.GOOGLE_ARTIFACT_REGISTRY,
              then: (schema) =>
                schema.required().test((value) => {
                  return value.length > 0
                }),
              otherwise: (schema) => schema.notRequired(),
            },
          ),
          [CreateContainerGroupField.GOOGLE_CONTAINER_JSON_KEY]: mixed<FileList>().when(
            CreateContainerGroupField.PRIVATE_REGISTRY_PROVIDER,
            {
              is: PrivateRegistryProvider.GOOGLE_CONTAINER_REGISTRY,
              then: (schema) =>
                schema.required().test((value) => {
                  return value.length > 0
                }),
              otherwise: (schema) => schema.notRequired(),
            },
          ),
          [CreateContainerGroupField.GPU]: array().of(string().required()),
          [CreateContainerGroupField.HTTP_COMPRESSION]: string()
            .oneOf(Object.values(ContainerLoggingHttpCompressionEnum))
            .when(CreateContainerGroupField.EXTERNAL_LOGGING_SERVICE, {
              is: ExternalLoggingService.HTTP,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [CreateContainerGroupField.HTTP_FORMAT]: string()
            .oneOf(Object.values(ContainerLoggingHttpFormatEnum))
            .when(CreateContainerGroupField.EXTERNAL_LOGGING_SERVICE, {
              is: ExternalLoggingService.HTTP,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [CreateContainerGroupField.HTTP_HEADERS]: array()
            .of(
              object().shape({
                name: string()
                  .required(intl.formatMessage(HTTPInputFieldsMessages.nameRequiredErrorText))
                  .max(128, intl.formatMessage(HTTPInputFieldsMessages.maxCharacterErrorText)),
                value: string()
                  .required(intl.formatMessage(HTTPInputFieldsMessages.valueRequiredErrorText))
                  .max(128, intl.formatMessage(HTTPInputFieldsMessages.maxCharacterErrorText)),
              }),
            )
            .when(CreateContainerGroupField.EXTERNAL_LOGGING_SERVICE, {
              is: ExternalLoggingService.HTTP,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [CreateContainerGroupField.HTTP_HOST]: string().when(CreateContainerGroupField.EXTERNAL_LOGGING_SERVICE, {
            is: ExternalLoggingService.HTTP,
            then: (schema) =>
              schema
                .required(intl.formatMessage(HTTPInputFieldsMessages.hostRequiredText))
                .matches(/^(?!https?:\/\/).*$/, intl.formatMessage(HTTPInputFieldsMessages.hostValidationText)),
            otherwise: (schema) => schema.notRequired(),
          }),
          [CreateContainerGroupField.HTTP_PASSWORD]: string().when(
            [
              CreateContainerGroupField.EXTERNAL_LOGGING_SERVICE,
              CreateContainerGroupField.HTTP_USER,
              CreateContainerGroupField.HTTP_PASSWORD,
            ],
            {
              is: (
                externalLoggingService: ExternalLoggingService,
                user: string | undefined,
                password: string | undefined,
              ) => externalLoggingService === ExternalLoggingService.HTTP && user && !password,
              then: (schema) => schema.required(intl.formatMessage(HTTPInputFieldsMessages.passwordRequiredText)),
              otherwise: (schema) => schema.notRequired(),
            },
          ),
          [CreateContainerGroupField.HTTP_PATH]: string(),
          [CreateContainerGroupField.HTTP_PORT]: number()
            .min(1)
            .max(65535)
            .when(CreateContainerGroupField.EXTERNAL_LOGGING_SERVICE, {
              is: ExternalLoggingService.HTTP,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [CreateContainerGroupField.HTTP_USER]: string().when(
            [
              CreateContainerGroupField.EXTERNAL_LOGGING_SERVICE,
              CreateContainerGroupField.HTTP_PASSWORD,
              CreateContainerGroupField.HTTP_USER,
            ],
            {
              is: (
                externalLoggingService: ExternalLoggingService,
                password: string | undefined,
                user: string | undefined,
              ) => externalLoggingService === ExternalLoggingService.HTTP && password && !user,
              then: (schema) => schema.required(intl.formatMessage(HTTPInputFieldsMessages.userRequiredText)),
              otherwise: (schema) => schema.notRequired(),
            },
          ),
          [CreateContainerGroupField.IMAGE_SOURCE]: string()
            .required(intl.formatMessage(ImageSourceInputMessages.imageSourceRequiredText))
            .test(
              'is-generally-valid-container-image-name',
              intl.formatMessage(ImageSourceInputMessages.imageSourceInvalidText),
              (value) => isRequiredImageSourceValid(value),
            ),
          [CreateContainerGroupField.IMAGE_TYPE]: string().oneOf([ImageType.PUBLIC, ImageType.PRIVATE]).required(),
          [CreateContainerGroupField.JOB_QUEUE]: string(),
          [CreateContainerGroupField.JOB_QUEUE_PATH]: string().when(CreateContainerGroupField.JOB_QUEUE, {
            is: (jobQueue: string | undefined) => jobQueue !== undefined,
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.notRequired(),
          }),
          [CreateContainerGroupField.JOB_QUEUE_PORT]: number()
            .min(0)
            .max(65535)
            .when(CreateContainerGroupField.JOB_QUEUE, {
              is: (jobQueue: string | undefined) => jobQueue !== undefined,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [CreateContainerGroupField.LIVENESS_PROBE_COMMAND]: string()
            .required()
            .when(
              [CreateContainerGroupField.LIVENESS_PROBE_ENABLED, CreateContainerGroupField.LIVENESS_PROBE_PROTOCOL],
              {
                is: (probeEnabled: boolean, probeProtocol: ProtocolOptions) =>
                  probeEnabled && probeProtocol === ProtocolOptions.EXEC,
                then: (schema) => schema.required(),
                otherwise: (schema) => schema.notRequired(),
              },
            ),
          [CreateContainerGroupField.LIVENESS_PROBE_COMMAND_ARGUMENTS]: array().of(
            object().shape({ argument: string().required() }),
          ),
          [CreateContainerGroupField.LIVENESS_PROBE_ENABLED]: boolean(),
          [CreateContainerGroupField.LIVENESS_PROBE_FAILURE_THRESHOLD]: number()
            .min(1)
            .max(65535)
            .required()
            .when(CreateContainerGroupField.LIVENESS_PROBE_ENABLED, {
              is: true,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [CreateContainerGroupField.LIVENESS_PROBE_HEADERS]: array()
            .of(
              object().shape({
                name: string()
                  .required(intl.formatMessage(HealthProbeHeadersSidePanelModalMessages.nameRequiredErrorText))
                  .max(128, intl.formatMessage(HealthProbeHeadersSidePanelModalMessages.maxCharacterErrorText)),
                value: string()
                  .required(intl.formatMessage(HealthProbeHeadersSidePanelModalMessages.valueRequiredErrorText))
                  .max(128, intl.formatMessage(HealthProbeHeadersSidePanelModalMessages.maxCharacterErrorText)),
              }),
            )
            .when(
              [CreateContainerGroupField.LIVENESS_PROBE_ENABLED, CreateContainerGroupField.LIVENESS_PROBE_PROTOCOL],
              {
                is: (probeEnabled: boolean, probeProtocol: ProtocolOptions) =>
                  probeEnabled && probeProtocol === ProtocolOptions.HTTP1X,
                then: (schema) => schema.required(),
                otherwise: (schema) => schema.notRequired(),
              },
            ),
          [CreateContainerGroupField.LIVENESS_PROBE_INITIAL_DELAY_SECONDS]: number()
            .min(0)
            .max(10000)
            .required()
            .when(CreateContainerGroupField.LIVENESS_PROBE_ENABLED, {
              is: true,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [CreateContainerGroupField.LIVENESS_PROBE_PATH]: string()
            .required(intl.formatMessage(HealthProbeComponentMessages.pathRequiredText))
            .when(
              [CreateContainerGroupField.LIVENESS_PROBE_ENABLED, CreateContainerGroupField.LIVENESS_PROBE_PROTOCOL],
              {
                is: (probeEnabled: boolean, probeProtocol: ProtocolOptions) =>
                  probeEnabled && probeProtocol === ProtocolOptions.HTTP1X,
                then: (schema) =>
                  schema
                    .required(intl.formatMessage(HealthProbeComponentMessages.pathRequiredText))
                    .matches(/^\/.*/, intl.formatMessage(HealthProbeComponentMessages.pathInvalidMessage)),
                otherwise: (schema) => schema.notRequired(),
              },
            ),
          [CreateContainerGroupField.LIVENESS_PROBE_PERIOD_SECONDS]: number()
            .min(0)
            .max(10000)
            .required()
            .when(CreateContainerGroupField.LIVENESS_PROBE_ENABLED, {
              is: true,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [CreateContainerGroupField.LIVENESS_PROBE_PORT]: number()
            .min(1)
            .max(65535)
            .required()
            .when(
              [CreateContainerGroupField.LIVENESS_PROBE_ENABLED, CreateContainerGroupField.LIVENESS_PROBE_PROTOCOL],
              {
                is: (probeEnabled: boolean, probeProtocol: ProtocolOptions) =>
                  probeEnabled && probeProtocol !== ProtocolOptions.EXEC,
                then: (schema) => schema.required(),
                otherwise: (schema) => schema.notRequired(),
              },
            ),
          [CreateContainerGroupField.LIVENESS_PROBE_PROTOCOL]: string()
            .oneOf(Object.values(ProtocolOptions))
            .required()
            .when(CreateContainerGroupField.LIVENESS_PROBE_ENABLED, {
              is: true,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [CreateContainerGroupField.LIVENESS_PROBE_SERVICE]: string()
            .max(128)
            .required()
            .when(
              [CreateContainerGroupField.LIVENESS_PROBE_ENABLED, CreateContainerGroupField.LIVENESS_PROBE_PROTOCOL],
              {
                is: (probeEnabled: boolean, probeProtocol: ProtocolOptions) =>
                  probeEnabled && probeProtocol === ProtocolOptions.GRPC,
                then: (schema) => schema.required(),
                otherwise: (schema) => schema.notRequired(),
              },
            ),
          [CreateContainerGroupField.LIVENESS_PROBE_SUCCESS_THRESHOLD]: number()
            .min(1)
            .max(10000)
            .required()
            .when(CreateContainerGroupField.LIVENESS_PROBE_ENABLED, {
              is: true,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [CreateContainerGroupField.LIVENESS_PROBE_TIMEOUT_SECONDS]: number()
            .min(1)
            .max(10000)
            .required()
            .when(CreateContainerGroupField.LIVENESS_PROBE_ENABLED, {
              is: true,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [CreateContainerGroupField.MEMORY]: number().required(),
          [CreateContainerGroupField.NAME]: string()
            .required()
            .matches(/^[a-z][a-z0-9-]{0,61}[a-z0-9]$/),
          [CreateContainerGroupField.CONTAINER_GATEWAY_ENABLED]: boolean(),
          [CreateContainerGroupField.CONTAINER_GATEWAY_PORT]: number()
            .min(1)
            .max(65535)
            .required()
            .when(CreateContainerGroupField.CONTAINER_GATEWAY_ENABLED, {
              is: true,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [CreateContainerGroupField.CONTAINER_GATEWAY_REQUIRES_AUTHENTICATION]: boolean()
            .required()
            .when(CreateContainerGroupField.CONTAINER_GATEWAY_ENABLED, {
              is: true,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [CreateContainerGroupField.NEW_RELIC_HOST]: string().when(
            CreateContainerGroupField.EXTERNAL_LOGGING_SERVICE,
            {
              is: ExternalLoggingService.NEW_RELIC,
              then: (schema) =>
                schema
                  .required(intl.formatMessage(NewRelicInputFieldsMessages.hostRequiredText))
                  .max(1000, intl.formatMessage(NewRelicInputFieldsMessages.hostMaxCharactersText)),
              otherwise: (schema) => schema.notRequired(),
            },
          ),
          [CreateContainerGroupField.NEW_RELIC_INGESTION_KEY]: string().when(
            CreateContainerGroupField.EXTERNAL_LOGGING_SERVICE,
            {
              is: ExternalLoggingService.NEW_RELIC,
              then: (schema) =>
                schema
                  .required(intl.formatMessage(NewRelicInputFieldsMessages.ingestionKeyRequiredText))
                  .max(1000, intl.formatMessage(NewRelicInputFieldsMessages.ingestionKeyMaxCharactersText)),
              otherwise: (schema) => schema.notRequired(),
            },
          ),
          [CreateContainerGroupField.PRIVATE_REGISTRY_PROVIDER]: string().oneOf(Object.values(PrivateRegistryProvider)),
          [CreateContainerGroupField.READINESS_PROBE_COMMAND]: string()
            .required()
            .when(
              [CreateContainerGroupField.READINESS_PROBE_ENABLED, CreateContainerGroupField.READINESS_PROBE_PROTOCOL],
              {
                is: (probeEnabled: boolean, probeProtocol: ProtocolOptions) =>
                  probeEnabled && probeProtocol === ProtocolOptions.EXEC,
                then: (schema) => schema.required(),
                otherwise: (schema) => schema.notRequired(),
              },
            ),
          [CreateContainerGroupField.READINESS_PROBE_COMMAND_ARGUMENTS]: array().of(
            object().shape({ argument: string().required() }),
          ),
          [CreateContainerGroupField.READINESS_PROBE_ENABLED]: boolean(),
          [CreateContainerGroupField.READINESS_PROBE_FAILURE_THRESHOLD]: number()
            .min(0)
            .max(10000)
            .required()
            .when(CreateContainerGroupField.READINESS_PROBE_ENABLED, {
              is: true,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [CreateContainerGroupField.READINESS_PROBE_HEADERS]: array()
            .of(
              object().shape({
                name: string()
                  .required(intl.formatMessage(HealthProbeHeadersSidePanelModalMessages.nameRequiredErrorText))
                  .max(128, intl.formatMessage(HealthProbeHeadersSidePanelModalMessages.maxCharacterErrorText)),
                value: string()
                  .required(intl.formatMessage(HealthProbeHeadersSidePanelModalMessages.valueRequiredErrorText))
                  .max(128, intl.formatMessage(HealthProbeHeadersSidePanelModalMessages.maxCharacterErrorText)),
              }),
            )
            .when(
              [CreateContainerGroupField.READINESS_PROBE_ENABLED, CreateContainerGroupField.READINESS_PROBE_PROTOCOL],
              {
                is: (probeEnabled: boolean, probeProtocol: ProtocolOptions) =>
                  probeEnabled && probeProtocol === ProtocolOptions.HTTP1X,
                then: (schema) => schema.required(),
                otherwise: (schema) => schema.notRequired(),
              },
            ),
          [CreateContainerGroupField.READINESS_PROBE_INITIAL_DELAY_SECONDS]: number()
            .min(0)
            .max(10000)
            .required()
            .when(CreateContainerGroupField.READINESS_PROBE_ENABLED, {
              is: true,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [CreateContainerGroupField.READINESS_PROBE_PORT]: number()
            .min(1)
            .max(65535)
            .required()
            .when(
              [CreateContainerGroupField.READINESS_PROBE_ENABLED, CreateContainerGroupField.READINESS_PROBE_PROTOCOL],
              {
                is: (probeEnabled: boolean, probeProtocol: ProtocolOptions) =>
                  probeEnabled && probeProtocol !== ProtocolOptions.EXEC,
                then: (schema) => schema.required(),
                otherwise: (schema) => schema.notRequired(),
              },
            ),
          [CreateContainerGroupField.READINESS_PROBE_PATH]: string()
            .required(intl.formatMessage(HealthProbeComponentMessages.pathRequiredText))
            .when(
              [CreateContainerGroupField.READINESS_PROBE_ENABLED, CreateContainerGroupField.READINESS_PROBE_PROTOCOL],
              {
                is: (probeEnabled: boolean, probeProtocol: ProtocolOptions) =>
                  probeEnabled && probeProtocol === ProtocolOptions.HTTP1X,
                then: (schema) =>
                  schema
                    .required(intl.formatMessage(HealthProbeComponentMessages.pathRequiredText))
                    .matches(/^\/.*/, intl.formatMessage(HealthProbeComponentMessages.pathInvalidMessage)),
                otherwise: (schema) => schema.notRequired(),
              },
            ),
          [CreateContainerGroupField.READINESS_PROBE_PERIOD_SECONDS]: number()
            .min(0)
            .max(10000)
            .required()
            .when(CreateContainerGroupField.READINESS_PROBE_ENABLED, {
              is: true,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [CreateContainerGroupField.READINESS_PROBE_PROTOCOL]: string()
            .oneOf(Object.values(ProtocolOptions))
            .required()
            .when(CreateContainerGroupField.READINESS_PROBE_ENABLED, {
              is: true,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [CreateContainerGroupField.READINESS_PROBE_SERVICE]: string()
            .max(128)
            .required()
            .when(
              [CreateContainerGroupField.READINESS_PROBE_ENABLED, CreateContainerGroupField.READINESS_PROBE_PROTOCOL],
              {
                is: (probeEnabled: boolean, probeProtocol: ProtocolOptions) =>
                  probeEnabled && probeProtocol === ProtocolOptions.GRPC,
                then: (schema) => schema.required(),
                otherwise: (schema) => schema.notRequired(),
              },
            ),
          [CreateContainerGroupField.READINESS_PROBE_SUCCESS_THRESHOLD]: number()
            .min(1)
            .max(10000)
            .required()
            .when(CreateContainerGroupField.READINESS_PROBE_ENABLED, {
              is: true,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [CreateContainerGroupField.READINESS_PROBE_TIMEOUT_SECONDS]: number()
            .min(1)
            .max(10000)
            .required()
            .when(CreateContainerGroupField.READINESS_PROBE_ENABLED, {
              is: true,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [CreateContainerGroupField.REPLICA_COUNT]: number().min(1).max(maxReplicaCount).required(),
          [CreateContainerGroupField.SELF_HOSTED_PASSWORD]: string().when(
            CreateContainerGroupField.PRIVATE_REGISTRY_PROVIDER,
            {
              is: PrivateRegistryProvider.SELF_HOSTED,
              then: (schema) => schema.required(intl.formatMessage(SelfHostedInputFieldsMessages.passwordRequiredText)),
              otherwise: (schema) => schema.notRequired(),
            },
          ),
          [CreateContainerGroupField.SELF_HOSTED_USERNAME]: string().when(
            CreateContainerGroupField.PRIVATE_REGISTRY_PROVIDER,
            {
              is: PrivateRegistryProvider.SELF_HOSTED,
              then: (schema) => schema.required(intl.formatMessage(SelfHostedInputFieldsMessages.usernameRequiredText)),
              otherwise: (schema) => schema.notRequired(),
            },
          ),
          [CreateContainerGroupField.SPLUNK_HOST]: string().when(CreateContainerGroupField.EXTERNAL_LOGGING_SERVICE, {
            is: ExternalLoggingService.SPLUNK,
            then: (schema) =>
              schema
                .required(intl.formatMessage(SplunkInputFieldsMessages.hostRequiredText))
                .max(1000, intl.formatMessage(SplunkInputFieldsMessages.hostMaxCharactersText)),
            otherwise: (schema) => schema.notRequired(),
          }),
          [CreateContainerGroupField.SPLUNK_TOKEN]: string().when(CreateContainerGroupField.EXTERNAL_LOGGING_SERVICE, {
            is: ExternalLoggingService.SPLUNK,
            then: (schema) => schema.required(intl.formatMessage(SplunkInputFieldsMessages.tokenRequiredText)),
            otherwise: (schema) => schema.notRequired(),
          }),
          [CreateContainerGroupField.STARTUP_PROBE_COMMAND]: string()
            .required()
            .when([CreateContainerGroupField.STARTUP_PROBE_ENABLED, CreateContainerGroupField.STARTUP_PROBE_PROTOCOL], {
              is: (probeEnabled: boolean, probeProtocol: ProtocolOptions) =>
                probeEnabled && probeProtocol === ProtocolOptions.EXEC,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [CreateContainerGroupField.STARTUP_PROBE_COMMAND_ARGUMENTS]: array().of(
            object().shape({ argument: string().required() }),
          ),
          [CreateContainerGroupField.STARTUP_PROBE_ENABLED]: boolean(),
          [CreateContainerGroupField.STARTUP_PROBE_FAILURE_THRESHOLD]: number()
            .min(0)
            .max(10000)
            .required()
            .when(CreateContainerGroupField.STARTUP_PROBE_ENABLED, {
              is: true,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [CreateContainerGroupField.STARTUP_PROBE_HEADERS]: array()
            .of(
              object().shape({
                name: string()
                  .required(intl.formatMessage(HealthProbeHeadersSidePanelModalMessages.nameRequiredErrorText))
                  .max(128, intl.formatMessage(HealthProbeHeadersSidePanelModalMessages.maxCharacterErrorText)),
                value: string()
                  .required(intl.formatMessage(HealthProbeHeadersSidePanelModalMessages.valueRequiredErrorText))
                  .max(128, intl.formatMessage(HealthProbeHeadersSidePanelModalMessages.maxCharacterErrorText)),
              }),
            )
            .when([CreateContainerGroupField.STARTUP_PROBE_ENABLED, CreateContainerGroupField.STARTUP_PROBE_PROTOCOL], {
              is: (probeEnabled: boolean, probeProtocol: ProtocolOptions) =>
                probeEnabled && probeProtocol === ProtocolOptions.HTTP1X,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [CreateContainerGroupField.STARTUP_PROBE_INITIAL_DELAY_SECONDS]: number()
            .min(0)
            .max(10000)
            .required()
            .when(CreateContainerGroupField.STARTUP_PROBE_ENABLED, {
              is: true,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [CreateContainerGroupField.STARTUP_PROBE_PATH]: string()
            .required(intl.formatMessage(HealthProbeComponentMessages.pathRequiredText))
            .when([CreateContainerGroupField.STARTUP_PROBE_ENABLED, CreateContainerGroupField.STARTUP_PROBE_PROTOCOL], {
              is: (probeEnabled: boolean, probeProtocol: ProtocolOptions) =>
                probeEnabled && probeProtocol === ProtocolOptions.HTTP1X,
              then: (schema) =>
                schema
                  .required(intl.formatMessage(HealthProbeComponentMessages.pathRequiredText))
                  .matches(/^\/.*/, intl.formatMessage(HealthProbeComponentMessages.pathInvalidMessage)),
              otherwise: (schema) => schema.notRequired(),
            }),
          [CreateContainerGroupField.STARTUP_PROBE_PERIOD_SECONDS]: number()
            .min(0)
            .max(10000)
            .required()
            .when(CreateContainerGroupField.STARTUP_PROBE_ENABLED, {
              is: true,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [CreateContainerGroupField.STARTUP_PROBE_PORT]: number()
            .min(1)
            .max(65535)
            .required()
            .when([CreateContainerGroupField.STARTUP_PROBE_ENABLED, CreateContainerGroupField.STARTUP_PROBE_PROTOCOL], {
              is: (probeEnabled: boolean, probeProtocol: ProtocolOptions) =>
                probeEnabled && probeProtocol !== ProtocolOptions.EXEC,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [CreateContainerGroupField.STARTUP_PROBE_PROTOCOL]: string()
            .oneOf(Object.values(ProtocolOptions))
            .required()
            .when(CreateContainerGroupField.STARTUP_PROBE_ENABLED, {
              is: true,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [CreateContainerGroupField.STARTUP_PROBE_SERVICE]: string()
            .max(128)
            .required()
            .when([CreateContainerGroupField.STARTUP_PROBE_ENABLED, CreateContainerGroupField.STARTUP_PROBE_PROTOCOL], {
              is: (probeEnabled: boolean, probeProtocol: ProtocolOptions) =>
                probeEnabled && probeProtocol === ProtocolOptions.GRPC,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [CreateContainerGroupField.STARTUP_PROBE_SUCCESS_THRESHOLD]: number()
            .min(1)
            .max(10000)
            .required()
            .when(CreateContainerGroupField.STARTUP_PROBE_ENABLED, {
              is: true,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [CreateContainerGroupField.STARTUP_PROBE_TIMEOUT_SECONDS]: number()
            .min(1)
            .max(10000)
            .required()
            .when(CreateContainerGroupField.STARTUP_PROBE_ENABLED, {
              is: true,
              then: (schema) => schema.required(),
              otherwise: (schema) => schema.notRequired(),
            }),
          [CreateContainerGroupField.TCP_HOST]: string().when(CreateContainerGroupField.EXTERNAL_LOGGING_SERVICE, {
            is: ExternalLoggingService.TCP,
            then: (schema) =>
              schema
                .required(intl.formatMessage(TCPInputFieldsMessages.hostRequiredText))
                .max(1000, intl.formatMessage(TCPInputFieldsMessages.hostMaxCharactersText)),
            otherwise: (schema) => schema.notRequired(),
          }),
          [CreateContainerGroupField.TCP_PORT]: number().when(CreateContainerGroupField.EXTERNAL_LOGGING_SERVICE, {
            is: ExternalLoggingService.TCP,
            then: (schema) => schema.required().min(1).max(65535),
            otherwise: (schema) => schema.notRequired(),
          }),
          [CreateContainerGroupField.VCPUS]: number().required(),
          [CreateContainerGroupField.QUAY_PASSWORD]: string().when(
            CreateContainerGroupField.PRIVATE_REGISTRY_PROVIDER,
            {
              is: PrivateRegistryProvider.QUAY_CONTAINER,
              then: (schema) => schema.required(intl.formatMessage(QuayInputFieldsMessages.passwordRequiredText)),
              otherwise: (schema) => schema.notRequired(),
            },
          ),
          [CreateContainerGroupField.QUAY_USERNAME]: string().when(
            CreateContainerGroupField.PRIVATE_REGISTRY_PROVIDER,
            {
              is: PrivateRegistryProvider.QUAY_CONTAINER,
              then: (schema) => schema.required(intl.formatMessage(QuayInputFieldsMessages.usernameRequiredText)),
              otherwise: (schema) => schema.notRequired(),
            },
          ),
        },
        [
          [CreateContainerGroupField.HTTP_USER, CreateContainerGroupField.HTTP_PASSWORD],
          [CreateContainerGroupField.HTTP_PASSWORD, CreateContainerGroupField.HTTP_PASSWORD],
          [CreateContainerGroupField.HTTP_USER, CreateContainerGroupField.HTTP_USER],
        ],
      ),
    [intl, maxReplicaCount],
  )
  return validationScheme
}
