import type { AnyAction } from '@reduxjs/toolkit'
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'
import type { IntlShape } from 'react-intl'
import type { TypedUseSelectorHook } from 'react-redux'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import type { Epic, EpicMiddleware } from 'redux-observable'
import { analyticsSlice } from './features/analytics/analyticsSlice'
import { apiKeySlice } from './features/apiKey/apiKeySlice'
import { authenticationSlice } from './features/authentication/authenticationSlice'
import { billingSlice } from './features/billing/billingSlice'
import { billingDashboardsSlice } from './features/billingDashboards/billingDashboardsSlice'
import { changePasswordSlice } from './features/changePassword/changePasswordSlice'
import { containerGroupDetailSlice } from './features/containerGroupDetail/containerGroupDetailSlice'
import { containerGroupInstanceTableSlice } from './features/containerGroupInstanceTable/containerGroupInstanceTableSlice'
import { containerGroupInstancesSlice } from './features/containerGroupInstances/containerGroupInstancesSlice'
import { containerGroupLogsSlice } from './features/containerGroupLogs/containerGroupLogsSlice'
import { containerGroupWorkloadErrorsSlice } from './features/containerGroupWorkloadErrors/containerGroupWorkloadErrorsSlice'
import { containerGroupsSlice } from './features/containerGroups/containerGroupsSlice'
import { createContainerGroupSlice } from './features/createContainerGroup/createContainerGroupSlice'
import { createNewOrganizationSlice } from './features/createNewOrganization/createNewOrganizationSlice'
import { createNewProjectSlice } from './features/createNewProject/createNewProjectSlice'
import { createRecipeDeploymentSlice } from './features/createRecipeDeployment/createRecipeDeploymentSlice'
import { deleteAccountSlice } from './features/deleteAccount/deleteAccountSlice'
import { deleteOrganizationSlice } from './features/deleteOrganization/deleteOrganizationSlice'
import { duplicateContainerGroupSlice } from './features/duplicateContainerGroup/duplicateContainerGroupSlice'
import { editContainerGroupSlice } from './features/editContainerGroup/editContainerGroupSlice'
import { editOrganizationSlice } from './features/editOrganization/editOrganizationSlice'
import { editProjectSlice } from './features/editProject/editProjectSlice'
import { editRecipeDeploymentSlice } from './features/editRecipeDeployment/editRecipeDeploymentSlice'
import { featureFlagsSlice } from './features/featureFlags/featureFlagsSlice'
import { forgotPasswordSlice } from './features/forgotPassword/forgotPasswordSlice'
import { gpuClassesSlice } from './features/gpuClasses/gpuClassesSlice'
import { inferenceEndpointJobsSlice } from './features/inferenceEndpointJobs/inferenceEndpointJobsSlice'
import { inferenceEndpointsSlice } from './features/inferenceEndpoints/inferenceEndpointsSlice'
import { inviteTeamMemberSlice } from './features/inviteTeamMember/inviteTeamMemberSlice'
import { jobQueueConnectionsTableSlice } from './features/jobQueueConnectionsTable/jobQueueConnectionsTableSlice'
import { jobQueuesSlice } from './features/jobQueues/jobQueuesSlice'
import { lastResourceViewedSlice } from './features/lastResourceViewed/lastResourceViewedSlice'
import { loginSlice } from './features/login/loginSlice'
import { logoutSlice } from './features/logout/logoutSlice'
import { myOrganizationsSlice } from './features/myOrganizations/myOrganizationSlice'
import { navigationBarSlice } from './features/navigationBar/navigationBarSlice'
import { notificationsSlice } from './features/notifications/notificationsSlice'
import { organizationsSlice } from './features/organizations/organizationsSlice'
import { paymentMethodsSlice } from './features/paymentMethod/paymentMethodSlice'
import { profileSlice } from './features/profile/profileSlice'
import { projectsSlice } from './features/projects/projectsSlice'
import { quotasSlice } from './features/quotas/quotasSlice'
import { ramOptionsSlice } from './features/ramOptions/ramOptionsSlice'
import { recipeDeploymentDetailsSlice } from './features/recipeDeploymentDetails/recipeDeploymentDetailsSlice'
import { recipeDeploymentInstanceTableSlice } from './features/recipeDeploymentInstanceTable/recipeDeploymentInstanceTableSlice'
import { recipeDeploymentsSlice } from './features/recipeDeployments/recipeDeploymentsSlice'
import { recipeDetailsSlice } from './features/recipeDetails/recipeDetailsSlice'
import { recipeMarketplaceSlice } from './features/recipesMarketplace/recipeMarketplaceSlice'
import { registrationSlice } from './features/registration/registrationSlice'
import { requestStatusSlice } from './features/requestStatus/requestStatusSlice'
import { resetPasswordSlice } from './features/resetPassword/resetPasswordSlice'
import { storageOptionsSlice } from './features/storageOptions/storageOptionsSlice'
import { teamSlice } from './features/team/teamSlice'
import { terminalSlice } from './features/terminal/terminalSlice'
import { verifyAccountSlice } from './features/verifyAccount/verifyAccountSlice'
import { RequestStatus } from './models'

const appReducer = combineReducers({
  analytics: analyticsSlice.reducer,
  apiKey: apiKeySlice.reducer,
  authentication: authenticationSlice.reducer,
  billing: billingSlice.reducer,
  billingDashboards: billingDashboardsSlice.reducer,
  changePassword: changePasswordSlice.reducer,
  containerGroupDetail: containerGroupDetailSlice.reducer,
  containerGroupInstanceTable: containerGroupInstanceTableSlice.reducer,
  containerGroupInstances: containerGroupInstancesSlice.reducer,
  containerGroupLogs: containerGroupLogsSlice.reducer,
  containerGroups: containerGroupsSlice.reducer,
  createContainerGroup: createContainerGroupSlice.reducer,
  createNewOrganization: createNewOrganizationSlice.reducer,
  createNewProject: createNewProjectSlice.reducer,
  createRecipeDeployment: createRecipeDeploymentSlice.reducer,
  deleteAccount: deleteAccountSlice.reducer,
  deleteOrganization: deleteOrganizationSlice.reducer,
  duplicateContainerGroup: duplicateContainerGroupSlice.reducer,
  editContainerGroup: editContainerGroupSlice.reducer,
  editOrganization: editOrganizationSlice.reducer,
  editProject: editProjectSlice.reducer,
  editRecipeDeployment: editRecipeDeploymentSlice.reducer,
  featureFlags: featureFlagsSlice.reducer,
  forgotPassword: forgotPasswordSlice.reducer,
  gpuClasses: gpuClassesSlice.reducer,
  inferenceEndpoints: inferenceEndpointsSlice.reducer,
  inferenceEndpointJobs: inferenceEndpointJobsSlice.reducer,
  inviteTeamMember: inviteTeamMemberSlice.reducer,
  jobQueueConnectionsTable: jobQueueConnectionsTableSlice.reducer,
  jobQueues: jobQueuesSlice.reducer,
  lastResourceViewed: lastResourceViewedSlice.reducer,
  login: loginSlice.reducer,
  logout: logoutSlice.reducer,
  myOrganizations: myOrganizationsSlice.reducer,
  navigationBar: navigationBarSlice.reducer,
  notifications: notificationsSlice.reducer,
  organizations: organizationsSlice.reducer,
  paymentMethod: paymentMethodsSlice.reducer,
  profile: profileSlice.reducer,
  projects: projectsSlice.reducer,
  quotas: quotasSlice.reducer,
  ramOptions: ramOptionsSlice.reducer,
  recipeDeploymentDetails: recipeDeploymentDetailsSlice.reducer,
  recipeDeploymentInstanceTable: recipeDeploymentInstanceTableSlice.reducer,
  recipeDeployments: recipeDeploymentsSlice.reducer,
  recipeDetails: recipeDetailsSlice.reducer,
  recipeMarketplace: recipeMarketplaceSlice.reducer,
  registration: registrationSlice.reducer,
  requestStatus: requestStatusSlice.reducer,
  resetPassword: resetPasswordSlice.reducer,
  storageOptions: storageOptionsSlice.reducer,
  team: teamSlice.reducer,
  terminal: terminalSlice.reducer,
  verifyAccount: verifyAccountSlice.reducer,
  workLoadErrors: containerGroupWorkloadErrorsSlice.reducer,
})

const rootReducer = (state: ReturnType<typeof appReducer> | undefined, action: AnyAction) => {
  if (state?.logout?.logoutRequestStatus === RequestStatus.Succeeded) {
    toast.dismiss()
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}

// TODO: serializer issue when normalizing state with any Date value returned from API - https://github.com/reduxjs/redux-toolkit/issues/456
export const createStore = (epicMiddleware: AppEpicMiddleware, preloadedState: Partial<AppState>) =>
  configureStore({
    enhancers: [Sentry.createReduxEnhancer({})],
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(epicMiddleware),
    preloadedState,
    reducer: rootReducer,
  })

export const useAppDispatch = () => useDispatch<AppDispatch>()

export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector

export type AppDispatch = ReturnType<typeof createStore>['dispatch']

export type AppEpic = Epic<AnyAction, AnyAction, AppState, AppEpicDependencies>

export interface AppEpicDependencies {
  fetch: (typeof window)['fetch']
  intl: IntlShape
}

export type AppEpicMiddleware = EpicMiddleware<AnyAction, AnyAction, AppState, AppEpicDependencies>

export type AppState = ReturnType<typeof appReducer>

export type AppStore = ReturnType<typeof createStore>
