import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import type { ObjectSchema } from 'yup'
import { object, string } from 'yup'
import type { ResetPasswordLinkExpiredFormValues } from './ResetPasswordLinkExpired'
import { ResetPasswordLinkExpiredMessages } from './messages'

export const useResetPasswordLinkExpiredFormValidation = (): ObjectSchema<ResetPasswordLinkExpiredFormValues> => {
  const intl = useIntl()
  const validationScheme = useMemo(
    () =>
      object({
        email: string()
          .required(intl.formatMessage(ResetPasswordLinkExpiredMessages.emailRequired))
          .email(intl.formatMessage(ResetPasswordLinkExpiredMessages.emailInvalid)),
      }),
    [intl],
  )
  return validationScheme
}
