import { createSelector } from '@reduxjs/toolkit'
import { containerGroupWorkloadErrorsAdapter } from './containerGroupWorkloadErrorsSlice'

const ContainerGroupWorkloadErrorsSelectors = containerGroupWorkloadErrorsAdapter.getSelectors()

export const selectContainerGroupWorkloadErrors = createSelector(
  ContainerGroupWorkloadErrorsSelectors.selectById,
  (containerGroup) => {
    return containerGroup?.workloadErrors ?? []
  },
)
