import type { ResponseError } from '@saladtechnologies/openapi-cloud-portal-browser'
import { catchError, concat, delay, filter, from, mergeMap, of } from 'rxjs'
import { OrganizationsAPI } from '../apiMethods'
import { deleteOrganization } from '../features/deleteOrganization/deleteOrganizationSlice'
import { showToastNotification } from '../features/notifications/notificationsSlice'
import { getOrganization, organizationAdded } from '../features/organizations/organizationsSlice'
import { setRequestStatus } from '../features/requestStatus/requestStatusSlice'
import {
  getDeleteOrganizationGeneralErrorContent,
  getDeleteOrganizationSucceededContent,
} from '../notifications/clientToastNotificationContent/organizations'
import type { AppEpic } from '../store'
import { navigateTo } from './navigationEpic'

export const onDeleteOrganization: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(deleteOrganization.match),
    mergeMap((action) =>
      concat(
        of(setRequestStatus({ request: 'deleteOrganization', status: 'pending' })),
        from(
          OrganizationsAPI.deleteOrganization({
            organizationName: action.payload.organizationName,
          }),
        ).pipe(
          mergeMap(() => {
            return concat(
              of(
                setRequestStatus({ request: 'deleteOrganization', status: 'succeeded' }),
                showToastNotification(getDeleteOrganizationSucceededContent(intl, action.payload.organizationName)),
                navigateTo({ path: '/organizations' }),
              ),
              of(setRequestStatus({ request: 'deleteOrganization', status: 'idle' })).pipe(delay(1)),
            )
          }),
          catchError((_error: ResponseError) => {
            return concat(
              of(
                setRequestStatus({ request: 'deleteOrganization', status: 'failed' }),
                showToastNotification(getDeleteOrganizationGeneralErrorContent(intl)),
              ),
              of(setRequestStatus({ request: 'deleteOrganization', status: 'idle' })).pipe(delay(1)),
            )
          }),
        ),
      ),
    ),
  )

export const onGetOrganization: AppEpic = (action$, _state$) =>
  action$.pipe(
    filter(getOrganization.match),
    mergeMap(({ payload: { organizationName } }) =>
      concat(
        of(setRequestStatus({ request: 'getOrganization', status: 'pending' })),
        from(OrganizationsAPI.getOrganization({ organizationName })).pipe(
          mergeMap((organizationResponse) => {
            return concat(
              of(organizationAdded(organizationResponse)),
              of(setRequestStatus({ request: 'getOrganization', status: 'succeeded' })),
            )
          }),
          catchError((_error: ResponseError) => {
            return concat(
              of(setRequestStatus({ request: 'getOrganization', status: 'failed' })),
              of(setRequestStatus({ request: 'getOrganization', status: 'idle' })).pipe(delay(1)),
            )
          }),
        ),
      ),
    ),
  )
