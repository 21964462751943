import type { FunctionComponent } from 'react'
import {
  type Control,
  type FieldValues,
  type UseFormClearErrors,
  type UseFormSetValue,
  type UseFormTrigger,
} from 'react-hook-form'
import { useIntl } from 'react-intl'
import { EditHealthProbeFieldsMessages } from '../../../messages'
import type { EditContainerGroupFormSectionIdAttributes, EditContainerGroupValues } from '../../../models'
import { EditContainerGroupField } from '../../../models'
import { EditHealthProbeFields } from '../components'

interface EditReadinessProbeProps {
  /** The react hook form method that clears errors for specified fields. */
  clearErrors: UseFormClearErrors<FieldValues>
  /** The control for the create container group react hook form. */
  control: Control<FieldValues, EditContainerGroupValues>
  /** The id attribute for the fields. */
  id: EditContainerGroupFormSectionIdAttributes
  /** The react hook form method that provides the ability to set a specified field value. */
  setValue: UseFormSetValue<FieldValues>
  /** The react hook form method that triggers validation for specified fields. */
  trigger: UseFormTrigger<FieldValues>
}

export const EditReadinessProbeFields: FunctionComponent<EditReadinessProbeProps> = ({
  clearErrors,
  control,
  id,
  setValue,
  trigger,
}) => {
  const intl = useIntl()

  return (
    <EditHealthProbeFields
      clearErrors={clearErrors}
      commandFieldName={EditContainerGroupField.READINESS_PROBE_COMMAND}
      commandArgumentsFieldName={EditContainerGroupField.READINESS_PROBE_COMMAND_ARGUMENTS}
      control={control}
      enabledFieldName={EditContainerGroupField.READINESS_PROBE_ENABLED}
      failureThresholdFieldName={EditContainerGroupField.READINESS_PROBE_FAILURE_THRESHOLD}
      formID="readinessProbeFieldsForm"
      headersFieldName={EditContainerGroupField.READINESS_PROBE_HEADERS}
      healthProbe="Readiness"
      id={id}
      subtitle={intl.formatMessage(EditHealthProbeFieldsMessages.subtitle, {
        health_probe_type: 'Readiness',
      })}
      initialDelaySecondsFieldName={EditContainerGroupField.READINESS_PROBE_INITIAL_DELAY_SECONDS}
      pathFieldName={EditContainerGroupField.READINESS_PROBE_PATH}
      periodSecondsFieldName={EditContainerGroupField.READINESS_PROBE_PERIOD_SECONDS}
      portFieldName={EditContainerGroupField.READINESS_PROBE_PORT}
      protocolFieldName={EditContainerGroupField.READINESS_PROBE_PROTOCOL}
      serviceFieldName={EditContainerGroupField.READINESS_PROBE_SERVICE}
      setValue={setValue}
      successThresholdFieldName={EditContainerGroupField.READINESS_PROBE_SUCCESS_THRESHOLD}
      timeoutSecondsFieldName={EditContainerGroupField.READINESS_PROBE_TIMEOUT_SECONDS}
      trigger={trigger}
    />
  )
}
