import { defineMessages } from 'react-intl'

export const EditProjectMainContentMessages = defineMessages({
  displayNameFieldLabel: {
    defaultMessage: 'Project Display Name',
    id: '2aZUafI0',
    description: 'The label for the display name field on the Edit Project Page.',
  },
  displayNameRequired: {
    defaultMessage: 'The display name cannot be empty.',
    id: 'J5THGKd4',
    description: 'The error message shown when the display name is not provided.',
  },
  editProjectButtonLabel: {
    defaultMessage: 'Save',
    id: 'Zfth20KO',
    description: 'The button label for the form submit button used to edit an project.',
  },
  projectNameHeader: {
    defaultMessage: 'Project Slug Name',
    id: 'YOWJOB2t',
    description: 'The header for the section describing the project name.',
  },
  projectNameDescription: {
    defaultMessage:
      'This is the permanent name for your project used in API calls. You can change the name used in the Portal by editing the display name below.',
    id: 'ZEE6kk7r',
    description: "The description of how Salad uses an project's name.",
  },
  projectNameMinMaxErrorMessage: {
    defaultMessage: 'The display name must be between 2-63 characters.',
    id: 't1lp+0jf',
    description: 'The error message for filling out the project name input field.',
  },
  returnBackLinkText: {
    defaultMessage: 'Back',
    id: '8hRAZDTU',
    description: 'The text for the link that returns the user back to the projects page.',
  },
  title: {
    defaultMessage: 'Edit Display Name',
    id: 'cg1ZRRAF',
    description: 'The title of for the Edit Project page.',
  },
})
