import { defineMessages } from 'react-intl'

export const DeleteOrganizationGeneralErrorContentMessages = defineMessages({
  body: {
    defaultMessage:
      'We were unable to delete your organization. Try again and if this issue persists {contact_support}.',
    id: 'Wbi9FmhW',
    description:
      'The description shown in a toast notification message shown when we encounter an error on their request to delete their organization.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'bko1WtBN',
    description: 'The text shown for the contact support link in the delete organization error toast notification.',
  },
  title: {
    defaultMessage: 'Error deleting organization',
    id: 'ChK+XNkD',
    description:
      'The toast notification title shown when we encounter an error on their request to delete their organization.',
  },
})
