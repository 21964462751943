export enum PrivateRegistryProvider {
  AWS_ECR = 'AWS_ECR',
  AZURE_CONTAINER = 'AZURE_CONTAINER',
  DOCKER_HUB = 'DOCKER_HUB',
  GITHUB_CONTAINER = 'GITHUB_CONTAINER',
  GOOGLE_ARTIFACT_REGISTRY = 'GOOGLE_ARTIFACT_REGISTRY',
  GOOGLE_CONTAINER_REGISTRY = 'GOOGLE_CONTAINER_REGISTRY',
  SELF_HOSTED = 'self_hosted',
  QUAY_CONTAINER = 'QUAY_CONTAINER',
}
