import { defineMessages } from 'react-intl'

export const CreateContainerGroupProbeInvalidErrorContentMessages = defineMessages({
  body: {
    defaultMessage: 'Please make sure the {probe_type} probe you have provided is valid.',
    id: '54m4Dvwa',
    description:
      'This is the body of the toast notification that is displayed when a user tries to create a container group with an invalid probe.',
  },
  title: {
    defaultMessage: 'Invalid {probe_type} probe',
    id: '3+yHVppp',
    description:
      'This is the title of the toast notification that is displayed when a user tries to create a container group with an invalid probe.',
  },
})
