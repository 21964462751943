import type { RecipeDeployment } from '@saladtechnologies/openapi-cloud-portal-browser'
import type { FunctionComponent } from 'react'
import { useCallback, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { ButtonLink } from '../../../../components/base/ButtonLink'
import { SearchWithFilters } from '../../../../components/search'
import { getRecipeDeploymentDetailsPagePath } from '../../../../routes/routes-utils'
import { RecipesDeployedMessages } from '../../messages'
import { DeployedRecipeCard } from '../DeployedRecipeCard/DeployedRecipeCard'
interface RecipesDeployedProps {
  /** The callback executed when the user clicks the Learn More button */
  onLearnMore: () => void
  /** The name of the current organization */
  organizationName: string
  /** The name of the current project */
  projectName: string
  /** The list of recipe deployments for the current project */
  recipeDeploymentsList: RecipeDeployment[]
  /** The path to the recipe marketplace */
  viewRecipeMarketplacePath: string
}

export const RecipesDeployed: FunctionComponent<RecipesDeployedProps> = ({
  organizationName,
  projectName,
  recipeDeploymentsList,
  viewRecipeMarketplacePath,
}) => {
  const intl = useIntl()

  const [query, setQuery] = useState<{ query: string; filter: { [key: string]: string | undefined } }>({
    query: '',
    filter: {},
  })

  const filterByLabel = intl.formatMessage(RecipesDeployedMessages.searchFilter)
  const filterByPreparingOption = intl.formatMessage(RecipesDeployedMessages.searchFilterByPreparing)
  const filterByStoppedOption = intl.formatMessage(RecipesDeployedMessages.searchFilterByStopped)
  const filterByRunningOption = intl.formatMessage(RecipesDeployedMessages.searchFilterByRunning)
  const filterByFailedOption = intl.formatMessage(RecipesDeployedMessages.searchFilterByFailed)

  const sortByLabel = intl.formatMessage(RecipesDeployedMessages.searchSortBy)
  const sortByAToZOption = intl.formatMessage(RecipesDeployedMessages.searchSortByAToZOption)
  const sortByZToAOption = intl.formatMessage(RecipesDeployedMessages.searchSortByZToAOption)
  const sortByStatusOption = intl.formatMessage(RecipesDeployedMessages.searchSortByStatusOption)
  const sortByStatusReversedOption = intl.formatMessage(RecipesDeployedMessages.searchSortByStatusReversedOption)

  const updateDeployedRecipeListBasedOnQuery = useCallback(
    (query: string, filter: { [key: string]: string | undefined }) => {
      let deployedRecipesThatMatchQuery = [...recipeDeploymentsList]

      const selectedFilterByOption =
        filter[filterByLabel] !== undefined && filter[filterByLabel] !== filterByLabel
          ? filter[filterByLabel]
          : undefined

      deployedRecipesThatMatchQuery = recipeDeploymentsList.filter((recipe) => {
        const filterByQuery = query.length > 0 ? recipe.displayName.toUpperCase().includes(query.toUpperCase()) : true

        const filterByPreparingStatus =
          // TODO: once the API has been updated, change the pending to preparing
          selectedFilterByOption === filterByPreparingOption ? recipe.currentState.status === 'pending' : true
        const filterByStoppedStatus =
          selectedFilterByOption === filterByStoppedOption ? recipe.currentState.status === 'stopped' : true
        const filterByRunnnningStatus =
          selectedFilterByOption === filterByRunningOption ? recipe.currentState.status === 'running' : true
        const filterByFailedStatus =
          selectedFilterByOption === filterByFailedOption ? recipe.currentState.status === 'failed' : true

        return (
          filterByQuery &&
          filterByPreparingStatus &&
          filterByStoppedStatus &&
          filterByRunnnningStatus &&
          filterByFailedStatus
        )
      })

      const selectedSortByOption =
        filter[sortByLabel] !== undefined && filter[sortByLabel] !== sortByLabel ? filter[sortByLabel] : undefined

      // TODO: update pending to preparing once it has been updated in the api and remove any
      const statusOrder: any = {
        pending: 0,
        stopped: 1,
        deploying: 2,
        running: 3,
        failed: 4,
      }

      switch (selectedSortByOption) {
        case sortByAToZOption:
          deployedRecipesThatMatchQuery = deployedRecipesThatMatchQuery.sort((recipeA, recipeB) =>
            recipeA.displayName.localeCompare(recipeB.displayName),
          )

          break

        case sortByZToAOption:
          deployedRecipesThatMatchQuery = deployedRecipesThatMatchQuery
            .sort((recipeA, recipeB) => recipeA.displayName.localeCompare(recipeB.displayName))
            .reverse()

          break

        case sortByStatusOption:
          deployedRecipesThatMatchQuery.sort(
            (recipeA, recipeB) => statusOrder[recipeA.currentState.status] - statusOrder[recipeB.currentState.status],
          )

          break

        case sortByStatusReversedOption:
          deployedRecipesThatMatchQuery.sort(
            (recipeA, recipeB) => statusOrder[recipeB.currentState.status] - statusOrder[recipeA.currentState.status],
          )

          break
      }

      return deployedRecipesThatMatchQuery
    },
    [
      recipeDeploymentsList,
      filterByFailedOption,
      filterByLabel,
      filterByPreparingOption,
      filterByRunningOption,
      filterByStoppedOption,
      sortByAToZOption,
      sortByLabel,
      sortByStatusOption,
      sortByStatusReversedOption,
      sortByZToAOption,
    ],
  )

  const deployedRecipeListBasedOnQuery = useMemo(() => {
    return updateDeployedRecipeListBasedOnQuery(query.query, query.filter)
  }, [query, updateDeployedRecipeListBasedOnQuery])

  return (
    <div className="flex flex-col">
      <div className="mb-4">
        <SearchWithFilters
          filters={[
            {
              label: filterByLabel,
              options: [filterByPreparingOption, filterByStoppedOption, filterByRunningOption, filterByFailedOption],
            },
            {
              label: sortByLabel,
              options: [sortByAToZOption, sortByZToAOption, sortByStatusOption, sortByStatusReversedOption],
            },
          ]}
          isFullWidth
          onChange={({ filter, query }) => setQuery({ query, filter })}
          placeholder={intl.formatMessage(RecipesDeployedMessages.searchPlaceholder)}
        />
      </div>
      <div className="mb-9 flex gap-2">
        <ButtonLink
          iconClassName="fa-solid fa-shop"
          url={viewRecipeMarketplacePath}
          role="navigation"
          variant="green-filled-light"
        >
          {intl.formatMessage(RecipesDeployedMessages.viewRecipeMarketplaceButtonLabel)}
        </ButtonLink>
        {/* TODO: uncomment when correct link for "learn more" will appear
        <Button iconClassName="fa-solid fa-book" onClick={onLearnMore} role="navigation" variant="blue-filled-light">
          {intl.formatMessage(RecipesDeployedMessages.learnMoreButtonLabel)}
        </Button> */}
      </div>

      <div className="grid gap-4">
        {deployedRecipeListBasedOnQuery.map((deployedRecipe) => (
          <DeployedRecipeCard
            key={`${deployedRecipe.displayName} ${deployedRecipe.name}`}
            currentState={deployedRecipe.currentState}
            networking={deployedRecipe.recipe.networking}
            path={getRecipeDeploymentDetailsPagePath(organizationName, projectName, deployedRecipe.name)}
            recipeDisplayName={deployedRecipe.displayName}
            recipeName={deployedRecipe.name}
            replicas={deployedRecipe.replicas}
          />
        ))}
      </div>
    </div>
  )
}
