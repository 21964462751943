import { defineMessages } from 'react-intl'

export const GetContainerGroupLogsGeneralErrorContentMessages = defineMessages({
  body: {
    defaultMessage:
      'We were unable to retrieve your container group logs. Try again and if this issue persists {contact_support}.',
    id: 'lu0m9COE',
    description:
      'The description shown in a toast notification message shown when we encounter an error on their request to get container logs.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'Kwoiimy4',
    description: 'The text shown for the contact support link in the get container logs error toast notification.',
  },
  title: {
    defaultMessage: 'Error getting container group logs',
    id: 'otPnhoQG',
    description:
      'The toast notification title shown when we encounter an error on their request to get container logs.',
  },
})
