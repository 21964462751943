import isPlainObject from 'lodash/isPlainObject'
import mixpanel from 'mixpanel-browser'
import type { IToggle, IVariant } from 'unleash-proxy-client'

import {
  MIXPANEL_API_URL,
  MIXPANEL_ORGANIZATION_PROJECT_TOKEN,
  MIXPANEL_USER_PROJECT_TOKEN,
  SENTRY_DSN,
  SENTRY_ENVIRONMENT,
  SENTRY_RELEASE,
  UNLEASH_APP_NAME,
  UNLEASH_CLIENT_KEY,
  UNLEASH_DEVELOPMENT_TOGGLES,
  UNLEASH_ENVIRONMENT,
  UNLEASH_PROXY_URL,
} from '../config'
import { FeatureFlag } from '../features/featureFlags/models'
import type { SentryServiceOptions } from './sentry'
import { SentryService } from './sentry'
import type { UnleashServiceOptions } from './unleash'
import { UnleashService } from './unleash'

const sentryService = new SentryService(createSentryServiceOptions())

if (MIXPANEL_USER_PROJECT_TOKEN) {
  mixpanel.init(MIXPANEL_USER_PROJECT_TOKEN, {
    api_host: MIXPANEL_API_URL,
    ignore_dnt: true,
    secure_cookie: true,
  })
}

let unleashService: UnleashService | undefined
UnleashService.create(createUnleashServiceOptions()).then((service) => {
  unleashService = service
  service.getStream('portal_sentry').subscribe((variant) => {
    if (variant.enabled) {
      sentryService.enable()
    } else {
      sentryService.disable()
    }
  })
})

export const onAuthenticated = (userId: string, email: string) => {
  sentryService.authenticate(userId, email)

  if (MIXPANEL_USER_PROJECT_TOKEN !== undefined) {
    mixpanel.identify(userId)
  }

  if (unleashService !== undefined) {
    unleashService.authenticate(userId)
  }
}

export const initializeAndIdentifyMixpanelOrganizationProject = (organizationId: string) => {
  if (MIXPANEL_ORGANIZATION_PROJECT_TOKEN !== undefined) {
    const orgsMixpanel = mixpanel.init(
      MIXPANEL_ORGANIZATION_PROJECT_TOKEN,
      {
        api_host: MIXPANEL_API_URL,
        ignore_dnt: true,
        secure_cookie: true,
      },
      'organization',
    )
    orgsMixpanel.identify(organizationId)
  }
}

export const onFetchFeatureFlagsFromUnleash = () => {
  let enabledFeatureFlags: FeatureFlag[] = []
  const featureFlagArray = Object.values(FeatureFlag) as FeatureFlag[]

  featureFlagArray.forEach((flag) => {
    if (flag !== FeatureFlag.None) {
      unleashService?.getStream(flag).subscribe((variant) => {
        if (variant.feature_enabled) {
          enabledFeatureFlags.push(flag)
        }
      })
    }
  })

  return enabledFeatureFlags
}

export const onDeauthenticated = () => {
  sentryService.deauthenticate()

  if (unleashService !== undefined) {
    unleashService.deauthenticate()
  }
}

function createSentryServiceOptions(): SentryServiceOptions {
  const variant = getVariantFromCache('portal_sentry')
  const options: SentryServiceOptions = {
    dsn: SENTRY_DSN,
    enabled: variant.enabled,
    environment: SENTRY_ENVIRONMENT,
    errorSampleRate: 0,
    release: SENTRY_RELEASE,
    transactionSampleRate: 0,
  }
  if (variant.payload !== undefined && variant.payload.type === 'json') {
    try {
      const payload = JSON.parse(variant.payload.value) as unknown
      if (isPlainObject(payload)) {
        const payloadObject = payload as Record<string, unknown>

        if ('errorSampleRate' in payloadObject && typeof payloadObject['errorSampleRate'] === 'number') {
          options.errorSampleRate = payloadObject['errorSampleRate']
        }

        if ('transactionSampleRate' in payloadObject && typeof payloadObject['transactionSampleRate'] === 'number') {
          options.transactionSampleRate = payloadObject['transactionSampleRate']
        }
      }
    } catch (error) {}
  }

  return options
}

function createUnleashServiceOptions(): UnleashServiceOptions {
  let developmentToggles: IToggle[] | undefined
  if (UNLEASH_DEVELOPMENT_TOGGLES !== undefined) {
    try {
      developmentToggles = JSON.parse(UNLEASH_DEVELOPMENT_TOGGLES)
    } catch {}
  }

  const options: UnleashServiceOptions = {
    appName: UNLEASH_APP_NAME,
    clientKey: UNLEASH_CLIENT_KEY,
    developmentToggles,
    environment: UNLEASH_ENVIRONMENT,
    onImpression: (event) => {
      if (MIXPANEL_USER_PROJECT_TOKEN !== undefined && event.enabled) {
        mixpanel.track('$experiment_started', {
          $insert_id: event.eventId,
          'Experiment name': event.featureName,
          'Variant name': event.variant,
        })
      }
    },
    proxyUrl: UNLEASH_PROXY_URL,
    refreshInterval: 300,
  }
  return options
}

function getVariantFromCache(name: string): IVariant {
  // https://github.com/Unleash/unleash-proxy-client-js/blob/main/src/index.ts
  // https://github.com/Unleash/unleash-proxy-client-js/blob/main/src/storage-provider-local.ts
  let toggles: IToggle[]
  try {
    const data = window.localStorage.getItem('unleash:repository:repo')
    toggles = (data ? (JSON.parse(data) as IToggle[]) : undefined) || []
  } catch {
    toggles = []
  }

  const toggle = toggles.find((toggle) => toggle.name === name)
  if (toggle != null && toggle.variant != null) {
    return toggle.variant
  }

  return { name: 'disabled', enabled: false }
}
