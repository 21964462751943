import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Link } from '../../../../components/base'
import { EstimatedCost } from '../../../../components/recipes/EstimatedCost'
import { EditRecipeDeploymentMainContentMessages } from '../../messages'
import { EditRecipeDeploymentForm } from '../EditRecipeDeploymentForm'
import { RecipeInstancesQuotaExceededModal } from '../RecipeInstancesQuotaExceededModal'

export interface EditRecipeDeploymentMainContentProps {
  /** The recipe deployment display name. */
  displayName: string
  /** The flag that indicating if the recipe instances quota exceeded. */
  isRecipeInstancesQuotaExceeded: boolean
  /** The flag indicating that the request to edit the recipe deployment is pending. */
  isSubmitPending: boolean
  /** The link to the typeform for requesting an increase in the recipe deployment instance quota limit. */
  linkToRequestIncreaseForRecipeDeploymentInstancesQuotas: string
  /** The max replica count. */
  maxReplicaCount: number | undefined
  /** The recipe deployment name. */
  name: string
  /** A callback that when executed revokes the invitation sent */
  onEditRecipeDeployment: (displayName: string, replicaCount: number) => void
  /** A callback executed each time the replica count is updated. */
  onUpdateReplicaCount: (pendingReplicaCount: number) => void
  /** The pending recipe deployment replica count. */
  pendingReplicaCount: number | undefined
  /** The recipe cost per hour. */
  recipeCostPerHour: number
  /** The path for the recipe deployment. */
  recipeDeploymentPath: string
  /** The recipe deployment replica count. */
  replicaCount: number
}

export const EditRecipeDeploymentMainContent: FunctionComponent<EditRecipeDeploymentMainContentProps> = ({
  displayName,
  isRecipeInstancesQuotaExceeded,
  isSubmitPending,
  linkToRequestIncreaseForRecipeDeploymentInstancesQuotas,
  maxReplicaCount,
  name,
  onEditRecipeDeployment,
  onUpdateReplicaCount,
  pendingReplicaCount,
  recipeCostPerHour,
  recipeDeploymentPath,
  replicaCount,
}) => {
  const intl = useIntl()
  const [isRecipeInstancesQuotaExceedModalShowing, setRecipeInstancesQuotaExceededModalOpen] = useState<boolean>(false)

  useEffect(() => {
    if (isRecipeInstancesQuotaExceeded) {
      setRecipeInstancesQuotaExceededModalOpen(true)
    }
  }, [isRecipeInstancesQuotaExceeded])

  return (
    <>
      <div className="w-full max-w-md">
        <div className="mb-4">
          <Link url={recipeDeploymentPath}>
            <span className={classNames('fa-solid fa-arrow-left mr-2')} />
            {intl.formatMessage(EditRecipeDeploymentMainContentMessages.returnToRecipeDeploymentPageLinkText, {
              recipe_deployment_display_name: displayName,
            })}
          </Link>
        </div>

        <h1 className="mb-10 text-3xl font-bold">
          {intl.formatMessage(EditRecipeDeploymentMainContentMessages.title, {
            recipe_display_name: displayName,
          })}
        </h1>
        <p className="mb-2 text-base font-bold">
          {intl.formatMessage(EditRecipeDeploymentMainContentMessages.recipeNameTitle)}
        </p>
        <p className="mb-2 text-xl font-bold text-green-80">{name}</p>
        <p className="mb-10 text-xs">
          {intl.formatMessage(EditRecipeDeploymentMainContentMessages.recipeNameDescription)}
        </p>

        <EditRecipeDeploymentForm
          displayName={displayName}
          isSubmitPending={isSubmitPending}
          linkToRequestIncreaseForRecipeDeploymentInstancesQuotas={
            linkToRequestIncreaseForRecipeDeploymentInstancesQuotas
          }
          maxReplicaCount={maxReplicaCount}
          onEditRecipeDeployment={onEditRecipeDeployment}
          onUpdateReplicaCount={onUpdateReplicaCount}
          replicaCount={replicaCount}
        />

        <div className="mt-10 md:hidden">
          <EstimatedCost replicaCount={pendingReplicaCount ?? replicaCount} costPerHour={recipeCostPerHour} />
        </div>
      </div>
      {isRecipeInstancesQuotaExceedModalShowing && (
        <RecipeInstancesQuotaExceededModal
          linkToRequestIncreaseForRecipeDeploymentInstancesQuotas={
            linkToRequestIncreaseForRecipeDeploymentInstancesQuotas
          }
          onCancel={() => setRecipeInstancesQuotaExceededModalOpen(false)}
        />
      )}
    </>
  )
}
