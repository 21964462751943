import type { FunctionComponent } from 'react'
import { useCallback, useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { LoadingPage } from '../../components/page/LoadingPage'
import { trackMixpanelEvent, trackMixpanelPageViewedEvent } from '../../features/analytics/analyticsSlice'
import { selectIsLeftColumnShowing } from '../../features/navigationBar/navigationBarSelectors'
import { setLeftColumnShowing } from '../../features/navigationBar/navigationBarSlice'
import { selectOrganizationDisplayName } from '../../features/organizations/organizationsSelectors'
import { selectProjectDisplayName, selectProjects } from '../../features/projects/projectsSelectors'
import { deleteProject } from '../../features/projects/projectsSlice'
import { GetRecipeDeploymentRequestType } from '../../features/recipeDeploymentDetails/models'
import {
  selectDeleteRecipeDeploymentIsPending,
  selectGetRecipeDeploymentIsPending,
  selectRecipeDeployment,
  selectStartRecipeDeploymentIsPending,
  selectStopRecipeDeploymentIsPending,
} from '../../features/recipeDeploymentDetails/recipeDeploymentDetailsSelectors'
import {
  deleteRecipeDeployment,
  getRecipeDeploymentDetailsPageData,
  getRecipeDeploymentInstances,
  pollRecipeDeployment,
  startRecipeDeployment,
  stopPollingForRecipeDeployment,
  stopRecipeDeployment,
} from '../../features/recipeDeploymentDetails/recipeDeploymentDetailsSlice'
import { selectRecipeDeploymentTableInstances } from '../../features/recipeDeploymentInstanceTable/recipeDeploymentInstanceTableSelectors'
import { getUpToDateRecipeDeploymentInstances } from '../../features/recipeDeploymentInstanceTable/recipeDeploymentInstanceTableSlice'
import { selectRequestStatus } from '../../features/requestStatus/requestStatusSelectors'
import { recipeDeploymentDetailsPageRoutePath } from '../../routes/routePaths'
import {
  getEditRecipePagePath,
  getRecipeDeploymentDetailsPagePath,
  getRecipeDeploymentsPagePath,
} from '../../routes/routes-utils'
import { useAppDispatch, useAppSelector } from '../../store'
import { RecipeDeploymentDetailsPage } from './RecipeDeploymentDetailsPage'

export const ConnectedRecipeDeploymentDetailsPage: FunctionComponent = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { organizationName = '', projectName = '', recipeDeploymentName = '' } = useParams()
  const recipeDeployment = useAppSelector(selectRecipeDeployment)
  const recipeDeploymentStatus = recipeDeployment?.currentState.status
  const recipeDeploymentInstances = useAppSelector(selectRecipeDeploymentTableInstances)
  const organizationDisplayName = useAppSelector((state) => selectOrganizationDisplayName(state, organizationName))

  const projects = useAppSelector((state) => selectProjects(state, organizationName))?.projects || []
  const currentProjectDisplayName = useAppSelector((state) =>
    selectProjectDisplayName(state, organizationName, projectName),
  )
  const deleteProjectRequestStatus = useAppSelector((state) => selectRequestStatus(state, 'deleteProject'))
  const isDeleteRecipeDeploymentPending = useAppSelector(selectDeleteRecipeDeploymentIsPending)
  const isStartRecipeDeploymentPending = useAppSelector(selectStartRecipeDeploymentIsPending)
  const isStopRecipeDeploymentPending = useAppSelector(selectStopRecipeDeploymentIsPending)
  const isGetRecipeDeploymentIsPending = useAppSelector(selectGetRecipeDeploymentIsPending)
  const isLeftColumnOpen = useAppSelector(selectIsLeftColumnShowing)

  const handleCloseLeftNavBar = useCallback(() => {
    dispatch(setLeftColumnShowing({ showing: false }))
  }, [dispatch])

  const getRecipeDeploymentDetailsPageDataMemoized = useCallback(() => {
    dispatch(
      getRecipeDeploymentDetailsPageData({
        organizationName,
        projectName,
        recipeDeploymentName,
        requestType: GetRecipeDeploymentRequestType.LOAD,
      }),
    )
  }, [dispatch, organizationName, projectName, recipeDeploymentName])

  const readme = useMemo(() => {
    if (recipeDeployment === undefined) {
      return ''
    } else {
      return recipeDeployment.recipe.readme.replaceAll(
        '{{ salad_recipe_dns }}',
        recipeDeployment.recipe.networking?.dns ?? 'example.salad.cloud',
      )
    }
  }, [recipeDeployment])

  const projectSelectOptions = projects.map((project) => {
    return {
      ...project,
      selected: project.name === projectName,
    }
  })

  const handleProjectChange = useCallback(
    (projectName?: string) => {
      if (projectName) {
        navigate(getRecipeDeploymentsPagePath(organizationName, projectName))
      }
    },
    [navigate, organizationName],
  )

  const handleRefreshRecipeDeployment = () => {
    dispatch(
      pollRecipeDeployment({
        organizationName,
        projectName,
        recipeDeploymentName,
        requestType: GetRecipeDeploymentRequestType.LOAD,
      }),
    )
  }

  const handleDeleteProject = useCallback(
    (projectName: string) => {
      dispatch(deleteProject({ organizationName, projectName }))
    },
    [dispatch, organizationName],
  )

  const handleDeleteRecipeDeployment = () => {
    dispatch(
      deleteRecipeDeployment({
        organizationName,
        projectName,
        recipeDeploymentName,
      }),
    )
  }
  const handleStartRecipeDeployment = () => {
    dispatch(startRecipeDeployment({ organizationName, projectName, recipeDeploymentName }))
  }

  const handleStopRecipeDeployment = () => {
    dispatch(stopRecipeDeployment({ organizationName, projectName, recipeDeploymentName }))
  }

  const handleMixpanelEvent = useCallback(
    (label: string, machineId?: string) => {
      dispatch(
        trackMixpanelEvent({
          event: 'Event', // TODO: Change this to a more descriptive event name
          properties: {
            label,
            path: recipeDeploymentDetailsPageRoutePath,
            organizationName,
            projectName,
            resourceName: recipeDeploymentName,
            machineId,
          },
        }),
      )
    },
    [dispatch, organizationName, projectName, recipeDeploymentName],
  )

  useEffect(() => {
    if (recipeDeploymentStatus === 'running') {
      const interval = setInterval(() => {
        dispatch(getUpToDateRecipeDeploymentInstances())
      }, 1000)

      return () => clearInterval(interval)
    }

    return () => {}
  }, [dispatch, recipeDeploymentStatus])

  useEffect(() => {
    dispatch(
      getRecipeDeploymentInstances({
        organizationName,
        projectName,
        recipeName: recipeDeploymentName,
      }),
    )
  }, [dispatch, organizationName, projectName, recipeDeploymentName])

  useEffect(() => {
    getRecipeDeploymentDetailsPageDataMemoized()
    return () => {
      dispatch(stopPollingForRecipeDeployment())
    }
  }, [dispatch, getRecipeDeploymentDetailsPageDataMemoized])

  useEffect(() => {
    dispatch(
      trackMixpanelPageViewedEvent({
        path: recipeDeploymentDetailsPageRoutePath,
        organizationName,
        projectName,
        resourceName: recipeDeploymentName,
      }),
    )
  }, [dispatch, organizationName, projectName, recipeDeploymentName])

  return recipeDeployment === undefined ? (
    <LoadingPage />
  ) : (
    <RecipeDeploymentDetailsPage
      currentOrganization={{ name: organizationName, displayName: organizationDisplayName ?? organizationName }}
      currentProject={{ name: projectName, displayName: currentProjectDisplayName ?? projectName }}
      deploymentInstances={recipeDeploymentInstances}
      editRecipePath={getEditRecipePagePath(organizationName, projectName, recipeDeployment.name)}
      isDeleteProjectPending={deleteProjectRequestStatus === 'pending'}
      isDeleteProjectSuccessful={deleteProjectRequestStatus === 'succeeded'}
      isDeleteRecipeDeploymentPending={isDeleteRecipeDeploymentPending}
      isLeftColumnOpen={isLeftColumnOpen}
      isRefreshRecipeDeploymentPending={isGetRecipeDeploymentIsPending}
      isStartRecipeDeploymentPending={isStartRecipeDeploymentPending}
      isStopRecipeDeploymentPending={isStopRecipeDeploymentPending}
      onCloseLeftDrawer={handleCloseLeftNavBar}
      onDeleteProject={handleDeleteProject}
      onDeleteRecipeDeployment={handleDeleteRecipeDeployment}
      onTrackMixpanelEvent={handleMixpanelEvent}
      onProjectChange={handleProjectChange}
      onRefreshRecipeDeployment={handleRefreshRecipeDeployment}
      onStartRecipeDeployment={handleStartRecipeDeployment}
      onStopRecipeDeployment={handleStopRecipeDeployment}
      projects={projectSelectOptions}
      recipeDeploymentDisplayName={recipeDeployment.displayName}
      recipeDeploymentsPath={getRecipeDeploymentsPagePath(organizationName, projectName)}
      recipeDetailsPagePath={getRecipeDeploymentDetailsPagePath(organizationName, projectName, recipeDeploymentName)}
      recipeMarkdown={readme}
      recipeName={recipeDeployment.recipe.name}
      replicaCount={recipeDeployment.replicas}
      replicasRunning={recipeDeployment.currentState.instanceStatusCounts.runningCount}
      status={recipeDeployment.currentState.status}
      statusDescription={recipeDeployment.currentState.description ?? undefined}
      networking={recipeDeployment.recipe.networking}
    />
  )
}
