import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { Button } from '../../../components/Button'
import { VerificationErrorMessages } from '../messages'

export interface VerificationErrorProps {
  /*
   The "Log In" button handler
  */
  onViewLoginPage: () => void
}

export const VerificationError: FunctionComponent<VerificationErrorProps> = ({ onViewLoginPage }) => {
  const intl = useIntl()

  return (
    <>
      <h1 className="text-2xl font-bold">{intl.formatMessage(VerificationErrorMessages.title)}</h1>
      <p className="text-base">{intl.formatMessage(VerificationErrorMessages.description)}</p>
      <div className="flex flex-row justify-start gap-3">
        <Button isFullWidth onClick={onViewLoginPage}>
          {intl.formatMessage(VerificationErrorMessages.logInButtonLabel)}
        </Button>
        <a
          className="flex w-full items-center justify-center rounded-sm border border-green-90 bg-transparent p-2 px-3 text-green-90"
          href="mailto:cloud@salad.com?subject=SaladCloud Support - Verification Problem"
        >
          {intl.formatMessage(VerificationErrorMessages.contactSupportLabel)}
        </a>
      </div>
    </>
  )
}
