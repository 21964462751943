import { defineMessages } from 'react-intl'

export const CreateJobQueueNameConflictErrorContentMessages = defineMessages({
  body: {
    defaultMessage: 'That Job Queue Name has been used before. You must use a new Job Queue Name.',
    id: '9/U198bR',
    description:
      'The description shown in a toast notification message shown when we encounter an error on their request to create a job queue with a name that has already been used.',
  },
  title: {
    defaultMessage: 'You must use a unique Job Queue Name',
    id: '4/qhCdEq',
    description:
      'The toast notification title shown when we encounter an error on their request to create a job queue with a name that has already been used.',
  },
})
