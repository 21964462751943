import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export interface DeleteOrganizationState {}

const initialState: DeleteOrganizationState = {}

export const deleteOrganizationSlice = createSlice({
  name: 'deleteOrganization',
  initialState,
  reducers: {
    deleteOrganization(_state, _action: PayloadAction<{ organizationName: string }>) {},
  },
})

export const { deleteOrganization } = deleteOrganizationSlice.actions
