import { yupResolver } from '@hookform/resolvers/yup'
import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import { useState } from 'react'
import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../../../components/Button'
import { TextField } from '../../../../components/TextField'
import { useFixedForm } from '../../../../hooks'
import { EditProjectMainContentMessages } from '../../messages'
import { useEditProjectFormValidation } from './useEditProjectFormValidation'

export interface EditProjectValues {
  /** The project display name. */
  displayName: string
}

interface EditProjectPageMainContentProps {
  /** The flag indicating that the request to edit the project is pending. */
  isSubmitPending: boolean
  /**
   * A callback that is executed when the user submits the form to edit the project.
   *
   * @param displayName The project display name.
   */
  onEditProject: (displayName: string) => void
  /** The project display name. */
  projectDisplayName: string
  /** The project name. */
  projectName: string
}

export const EditProjectMainContent: FunctionComponent<EditProjectPageMainContentProps> = ({
  isSubmitPending,
  onEditProject,
  projectDisplayName,
  projectName,
}) => {
  const intl = useIntl()
  const navigate = useNavigate()

  const EditProjectValidationScheme = useEditProjectFormValidation()
  const { control, handleSubmit, register } = useFixedForm<EditProjectValues>({
    resolver: yupResolver(EditProjectValidationScheme),
    onSubmit: ({ displayName }) => onEditProject(displayName),
  })
  const [isDifferentDisplayName, toggleIsDifferentDisplayName] = useState<boolean>(false)

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const displayNameChanged = event.target.value.trim() !== projectDisplayName
    toggleIsDifferentDisplayName(displayNameChanged)
  }

  return (
    <div className="w-full max-w-xl">
      <div className="mb-5">
        <button onClick={() => navigate(-1)} className="text-blue-90 underline">
          <span className={classNames('fa-solid fa-arrow-left mr-2')} />
          {intl.formatMessage(EditProjectMainContentMessages.returnBackLinkText)}
        </button>
      </div>
      <h1 className="mb-10 text-3xl font-bold">{intl.formatMessage(EditProjectMainContentMessages.title)}</h1>
      <h2 className="mb-2 text-base font-bold">
        {intl.formatMessage(EditProjectMainContentMessages.projectNameHeader)}
      </h2>
      <p className="mb-2 text-xl font-bold text-green-80">{projectName}</p>
      <p className="mb-10 text-base">{intl.formatMessage(EditProjectMainContentMessages.projectNameDescription)}</p>
      <form onSubmit={handleSubmit}>
        <Controller
          name="displayName"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...register('displayName', { onChange: handleOnChange })}
              {...field}
              {...fieldState}
              defaultValue={projectDisplayName}
              helperText={fieldState.error?.message}
              label={intl.formatMessage(EditProjectMainContentMessages.displayNameFieldLabel)}
              isFullWidth
            />
          )}
        />
        <Button
          type="submit"
          isLoading={isSubmitPending}
          isDisabled={!isDifferentDisplayName || isSubmitPending}
          isFullWidth
        >
          {intl.formatMessage(EditProjectMainContentMessages.editProjectButtonLabel)}
        </Button>
      </form>
    </div>
  )
}
