import type { ContainerGroup, RamOption, StorageOption } from '@saladtechnologies/openapi-cloud-portal-browser'
import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { Tag } from '../../../../../components/Tag'
import { Card } from '../../../../../components/base/Card'
import { SidePanelModal } from '../../../../../components/block/SidePanelModal'
import { CommandDetails } from '../../../../../components/containerGroups/CommandDetails'
import { ExternalLoggingDetails } from '../../../../../components/containerGroups/ExternalLogging/'
import { determineExternalLoggingService } from '../../../../../components/containerGroups/ExternalLogging/utils'
import { HealthProbeDetails } from '../../../../../components/containerGroups/HealthProbeDetails'
import { JobQueueDetails } from '../../../../../components/containerGroups/JobQueueDetails'
import type { GpuClass } from '../../../../../features/gpuClasses/models'
import { ContainerGroupsAllocatedResources } from '../../../../ContainerGroups/components/ContainerGroupsAllocatedResources'
import { configureHealthProbeHeaders } from '../../../../CreateContainerGroup/components/HealthProbeFields/utils'
import { detectFailedToPullImageDescription, getFailedToPullImageMessage } from '../../../utils'
import { EnvironmentVariablesList } from '../../EnvironmentVariablesList'
import { DeploymentDetailsMessages } from './DeploymentDetailsMessages'
import { determineHealthProbeProtocol } from './utils'

interface DeploymentDetailsProps {
  /** Container Group to be displayed on the page */
  containerGroup: ContainerGroup
  /** The detailed data of the GPU classes */
  gpuClasses?: GpuClass[]
  /** The list of available ram options to choose from. */
  ramOptions: RamOption[] | undefined
  /** The list of available storage options to choose from. */
  storageOptions: StorageOption[] | undefined
}

export const DeploymentDetails: FunctionComponent<DeploymentDetailsProps> = ({
  containerGroup,
  gpuClasses,
  ramOptions,
  storageOptions,
}) => {
  const {
    container: { command: commandArray, environmentVariables, hash, image, logging, resources },
    currentState: { description },
    livenessProbe,
    networking: containerGateway,
    readinessProbe,
    replicas,
    startupProbe,
  } = containerGroup
  const intl = useIntl()
  const [isEnvVariablesSidePanelShown, setIsEnvVariablesSidePanelShown] = useState(false)
  const environmentVariablesArray = environmentVariables
    ? Object.entries(environmentVariables).map(([key, value]) => {
        return {
          key: key,
          value: value || '', // For some reason the type allows for an undefined value, but we don't allow that during creation,
        }
      })
    : []
  const environmentVariablesCount = environmentVariablesArray && environmentVariablesArray.length
  const hasEnvVariables = environmentVariablesCount !== 0
  const hasFailedToPullImage = detectFailedToPullImageDescription(description)
  const command = commandArray?.[0] || ''
  const commandArgs = commandArray?.slice(1).map((arg) => ({ argument: arg })) || []

  return (
    <>
      {/* Image Source  */}
      <div className="mt-10">
        <h2 className="text-base font-bold">{intl.formatMessage(DeploymentDetailsMessages.imageSourceTitle)}</h2>
        <div className="mt-4">
          <Card>
            <div className="flex flex-col">
              <div className="flex">
                <p
                  className={classNames('text-base font-bold', {
                    'mr-2 text-red-80': hasFailedToPullImage,
                  })}
                >
                  {intl.formatMessage(DeploymentDetailsMessages.url)}
                </p>
                {hasFailedToPullImage && (
                  <Tag color="red">
                    <i className={classNames('fa-solid fa-triangle-exclamation')} />
                  </Tag>
                )}
              </div>
              <p className="truncate text-base font-normal text-neutral-70">{image}</p>
              {hash && <p className="truncate text-base font-normal text-neutral-70">{hash}</p>}
              {hasFailedToPullImage && (
                <p className="text-base font-normal text-red-80">{getFailedToPullImageMessage(intl)}</p>
              )}
            </div>
          </Card>
        </div>
      </div>
      {/* Allocated Resources  */}
      <div className="mt-10">
        <h2 className="text-base font-bold">{intl.formatMessage(DeploymentDetailsMessages.allocatedResourcesTitle)}</h2>
        <div className="mt-4">
          <ContainerGroupsAllocatedResources
            resources={resources}
            gpuClasses={gpuClasses}
            replicas={replicas}
            showAllGpuClasses={true}
            withTitle={false}
            ramOptions={ramOptions}
            storageOptions={storageOptions}
          />
        </div>
      </div>
      {/* Command  */}
      <div className="mt-10">
        <h2 className="text-base font-bold">{intl.formatMessage(DeploymentDetailsMessages.commandTitle)}</h2>
        <div className="mt-4">
          <Card>
            <CommandDetails command={command} args={commandArgs} />
          </Card>
        </div>
      </div>

      {/* Container Gateway  */}
      <div className="mt-10">
        <h2 className="text-base font-bold">{intl.formatMessage(DeploymentDetailsMessages.containerGatewayTitle)}</h2>
        <div className="mt-4">
          <Card>
            <span className="font-medium text-neutral-70">
              {containerGateway ? (
                <>
                  {intl.formatMessage(DeploymentDetailsMessages.containerGatewayPort, { port: containerGateway.port })}
                  {containerGateway.auth && intl.formatMessage(DeploymentDetailsMessages.containerGatewayIsProtected)}
                </>
              ) : (
                <>{intl.formatMessage(DeploymentDetailsMessages.containerGatewayNotEnabled)}</>
              )}
            </span>
          </Card>
        </div>
      </div>

      {/* External Logging Services */}
      <div className="mt-10">
        <h2 className="text-base font-bold">
          {intl.formatMessage(DeploymentDetailsMessages.externalLoggingServicesTitle)}
        </h2>
        <div className="mt-4">
          <Card>
            <ExternalLoggingDetails
              axiomApiKey={logging?.axiom?.apiToken}
              axiomDatasetName={logging?.axiom?.dataset}
              axiomHost={logging?.axiom?.host}
              datadogApiKey={logging?.datadog?.apiKey}
              datadogHost={logging?.datadog?.host}
              datadogTags={logging?.datadog?.tags}
              externalLoggingService={determineExternalLoggingService(logging)}
              httpCompression={logging?.http?.compression}
              httpFormat={logging?.http?.format}
              httpHeaders={logging?.http?.headers}
              httpHost={logging?.http?.host}
              httpPassword={logging?.http?.password}
              httpPath={logging?.http?.path}
              httpPort={logging?.http?.port}
              httpUser={logging?.http?.user}
              newRelicHost={logging?.newRelic?.host}
              newRelicIngestionKey={logging?.newRelic?.ingestionKey}
              splunkHost={logging?.splunk?.host}
              splunkToken={logging?.splunk?.token}
              tcpHost={logging?.tcp?.host}
              tcpPort={logging?.tcp?.port}
            />
          </Card>
        </div>
      </div>

      {/* Environment Variables  */}
      <div className="mt-10">
        <h2 className="text-base font-bold">
          {intl.formatMessage(DeploymentDetailsMessages.environmentVariablesTitle)}
        </h2>
        <div className="mt-4">
          <Card>
            <span
              onClick={() => setIsEnvVariablesSidePanelShown(hasEnvVariables)}
              className={classNames('font-medium', {
                'text-neutral-70': !hasEnvVariables,
                'cursor-pointer text-blue-90 underline': hasEnvVariables,
              })}
            >
              {intl.formatMessage(DeploymentDetailsMessages.viewEnvironmentVariables, {
                env_variables_amount: environmentVariablesCount,
              })}
            </span>
          </Card>
        </div>
      </div>
      <SidePanelModal
        isShown={isEnvVariablesSidePanelShown}
        title={intl.formatMessage(DeploymentDetailsMessages.environmentVariablesTitle)}
        onClose={() => setIsEnvVariablesSidePanelShown(false)}
      >
        <EnvironmentVariablesList envVariables={environmentVariablesArray} />
      </SidePanelModal>

      {/* Health Probes  */}

      {/* Startup Probe  */}
      <div className="mt-10">
        <h2 className="text-base font-bold">{intl.formatMessage(DeploymentDetailsMessages.startupProbeTitle)}</h2>
        <div className="mt-4">
          <Card>
            <HealthProbeDetails
              command={startupProbe?.exec?.command ? startupProbe.exec.command.join(' ') : undefined}
              failureThreshold={startupProbe?.failureThreshold}
              headers={
                startupProbe?.http?.headers ? configureHealthProbeHeaders(startupProbe?.http?.headers) : undefined
              }
              path={startupProbe?.http?.path}
              periodSeconds={startupProbe?.periodSeconds}
              port={startupProbe?.http?.port || startupProbe?.tcp?.port || startupProbe?.grpc?.port}
              initialDelaySeconds={startupProbe?.initialDelaySeconds}
              isEnabled={startupProbe != null}
              protocol={determineHealthProbeProtocol(startupProbe)}
              service={startupProbe?.grpc?.service}
              successThreshold={startupProbe?.successThreshold}
              timeoutSeconds={startupProbe?.timeoutSeconds}
            />
          </Card>
        </div>
      </div>

      {/* Liveness Probe  */}
      <div className="mt-10">
        <h2 className="text-base font-bold">{intl.formatMessage(DeploymentDetailsMessages.livenessProbeTitle)}</h2>
        <div className="mt-4">
          <Card>
            <HealthProbeDetails
              command={livenessProbe?.exec?.command ? livenessProbe.exec.command.join(' ') : undefined}
              failureThreshold={livenessProbe?.failureThreshold}
              headers={
                livenessProbe?.http?.headers ? configureHealthProbeHeaders(livenessProbe?.http?.headers) : undefined
              }
              path={livenessProbe?.http?.path}
              periodSeconds={livenessProbe?.periodSeconds}
              port={livenessProbe?.http?.port || livenessProbe?.tcp?.port || livenessProbe?.grpc?.port}
              initialDelaySeconds={livenessProbe?.initialDelaySeconds}
              isEnabled={livenessProbe != null}
              protocol={determineHealthProbeProtocol(livenessProbe)}
              service={livenessProbe?.grpc?.service}
              successThreshold={livenessProbe?.successThreshold}
              timeoutSeconds={livenessProbe?.timeoutSeconds}
            />
          </Card>
        </div>
      </div>

      {/* Readiness Probe  */}
      <div className="mt-10">
        <h2 className="text-base font-bold">{intl.formatMessage(DeploymentDetailsMessages.readinessProbeTitle)}</h2>
        <div className="mt-4">
          <Card>
            <HealthProbeDetails
              command={readinessProbe?.exec?.command ? readinessProbe.exec.command.join(' ') : undefined}
              failureThreshold={readinessProbe?.failureThreshold}
              path={readinessProbe?.http?.path}
              periodSeconds={readinessProbe?.periodSeconds}
              port={readinessProbe?.http?.port || readinessProbe?.tcp?.port || readinessProbe?.grpc?.port}
              initialDelaySeconds={readinessProbe?.initialDelaySeconds}
              isEnabled={readinessProbe != null}
              protocol={determineHealthProbeProtocol(readinessProbe)}
              service={readinessProbe?.grpc?.service}
              successThreshold={readinessProbe?.successThreshold}
              timeoutSeconds={readinessProbe?.timeoutSeconds}
            />
          </Card>
        </div>
      </div>

      {/* Job Queue */}
      <div className="mt-10">
        <h2 className="text-base font-bold">{intl.formatMessage(DeploymentDetailsMessages.jobQueueTitle)}</h2>
        <div className="mt-4">
          <Card>
            <JobQueueDetails
              name={containerGroup.queueConnection?.queueName}
              path={containerGroup.queueConnection?.path}
              port={containerGroup.queueConnection?.port}
            />
          </Card>
        </div>
      </div>
    </>
  )
}
