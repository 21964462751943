import { defineMessages } from 'react-intl'

export const CreateOrganizationGeneralErrorContentMessages = defineMessages({
  body: {
    defaultMessage:
      'We were unable to create your new organization. Try again and if this issue persists {contact_support}.',
    id: 'OIpbBOQ5',
    description:
      'The description shown in a toast notification message shown when we encounter an error on their request to create an organization.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'GaVxzbZR',
    description: 'The text shown for the contact support link in the create organization error toast notification.',
  },
  title: {
    defaultMessage: 'Error creating organization',
    id: 'PnfKlH/3',
    description:
      'The toast notification title shown when we encounter an error on their request to create an organization.',
  },
})
