import type { FunctionComponent } from 'react'
import { useCallback } from 'react'
import { sendResetPasswordEmail } from '../../features/forgotPassword/forgotPasswordSlice'
import { selectRequestStatusIsPending } from '../../features/requestStatus/requestStatusSelectors'
import { useAppDispatch, useAppSelector } from '../../store'
import { ResetPasswordLinkExpired } from './components/ResetPasswordLinkExpired'

export const ConnectedResetPasswordLinkExpiredPage: FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const sendResetPasswordEmailIsPending = useAppSelector((state) =>
    selectRequestStatusIsPending(state, 'sendResetPasswordEmail'),
  )

  const onSendResetPasswordEmail = useCallback(
    (email: string) => {
      dispatch(sendResetPasswordEmail({ email }))
    },
    [dispatch],
  )
  return (
    <ResetPasswordLinkExpired
      isSendResetPasswordEmailPending={sendResetPasswordEmailIsPending}
      onSendResetPasswordEmail={({ email }) => onSendResetPasswordEmail(email)}
    />
  )
}
