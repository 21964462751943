import { defineMessages } from 'react-intl'

export const PaymentSessionCanceledErrorMessages = defineMessages({
  body: {
    defaultMessage:
      'The payment session was canceled unexpectedly. Please refresh the page and try again but if this issue persists please {contact_support}.',
    id: 'Ngay/7+8',
    description:
      'The description shown in a toast notification message shown when we encounter an error with canceled payment session.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'aMki5BdC',
    description:
      'The text shown for the contact support link in the canceled payment session error toast notification.',
  },
  title: {
    defaultMessage: 'Unexpected Payment Error',
    id: 'ibZnBTDn',
    description: 'The toast notification title shown when we encounter an error with canceled payment session.',
  },
})
