import { defineMessages } from 'react-intl'

export const ContainerGroupInstanceTerminalGeneralErrorMessages = defineMessages({
  title: {
    defaultMessage: 'Unable to connect to terminal',
    id: 'TblY7E5O',
    description: 'The toast notification title shown when we are not able to connect to the terminal',
  },
  body: {
    defaultMessage: 'We were unable to connect to the terminal. Please refresh the page and try again.',
    id: 'CVKkyWJb',
    description: 'The toast body shown when we are not able to connect to the terminal',
  },
})
