import type { PayloadAction } from '@reduxjs/toolkit'
import { combineReducers, configureStore, createSlice } from '@reduxjs/toolkit'
import type { TypedUseSelectorHook } from 'react-redux'
import { useDispatch, useSelector } from 'react-redux'

const initialState = {
  email: 'luke.skywalker@salad.com',
  isLeftColumnShowing: false,
  organization: 'Salad Technologies',
}

const navigationBarSlice = createSlice({
  name: 'navigationBar',
  initialState,
  reducers: {
    setLeftColumnShowing(state, action: PayloadAction<{ showing: boolean }>) {
      state.isLeftColumnShowing = action.payload.showing
    },
  },
})

const featureFlagsInitialState = {}

const featureFlagsSlice = createSlice({
  name: 'featureFlags',
  initialState: featureFlagsInitialState,
  reducers: {},
})

export const { setLeftColumnShowing } = navigationBarSlice.actions

const reducer = combineReducers({
  featureFlags: featureFlagsSlice.reducer,
  navigationBar: navigationBarSlice.reducer,
})

export const mockStore = configureStore({
  reducer,
  preloadedState: {},
})

export const useMockAppDispatch = () => useDispatch<MockAppDispatch>()

export const useMockAppSelector: TypedUseSelectorHook<MockAppState> = useSelector

export type MockAppState = ReturnType<typeof reducer>
export type MockAppDispatch = (typeof mockStore)['dispatch']
