import type {
  ContainerGroup,
  ContainerGroupLivenessProbe,
  ContainerGroupReadinessProbe,
  ContainerGroupStartupProbe,
  ContainerLogging,
  ContainerLoggingHttp,
  CreateContainerGroup,
  CreateContainerRegistryAuthentication,
  HttpHeadersInner,
} from '@saladtechnologies/openapi-cloud-portal-browser'
import {
  ContainerLoggingHttpCompressionEnum,
  ContainerLoggingHttpFormatEnum,
} from '@saladtechnologies/openapi-cloud-portal-browser'
import flattenDeep from 'lodash/flattenDeep'
import type { FieldValues, UseFormGetFieldState } from 'react-hook-form'
import type { VerticalProgressBarStep } from '../../components/block/VerticalProgressBarWithClick'
import { ExternalLoggingService } from '../../components/containerGroups/models'
import CPUCoreOptions from '../../utils/CPUCoreOptions.json'
import { getDefaultRamOption } from '../../utils/ramOptions'
import type { CreateContainerGroupValues, HTTPExternalLoggingDefaultValues, HealthProbeDefaultValues } from './models'
import {
  CreateContainerGroupField,
  CreateContainerGroupFormSectionIdAttributes,
  ImageType,
  PrivateRegistryProvider,
  ProtocolOptions,
} from './models'

/**
 * Configures the default values for the startup health probe fields for the Create Container Group form.
 *
 * @param healthProbe The health probe.
 */
const configureStartupHealthProbeDefaultValues = (
  healthProbe:
    | ContainerGroupLivenessProbe
    | ContainerGroupReadinessProbe
    | ContainerGroupStartupProbe
    | null
    | undefined,
): HealthProbeDefaultValues => {
  const command = healthProbe?.exec?.command[0]
  let commandArguments = undefined
  if (healthProbe?.exec?.command && healthProbe?.exec?.command.length > 1) {
    commandArguments = healthProbe.exec.command.slice(1).map((arg) => ({ argument: arg }))
  }

  return {
    command,
    commandArguments,
    enabled: healthProbe != null,
    failureThreshold: healthProbe?.failureThreshold || 1200,
    headers: healthProbe?.http?.headers,
    initialDelaySeconds: healthProbe?.initialDelaySeconds || 0,
    path: healthProbe?.http?.path,
    periodSeconds: healthProbe?.periodSeconds || 3,
    port: healthProbe?.http?.port || healthProbe?.grpc?.port || healthProbe?.tcp?.port || 1,
    protocol: healthProbe?.http
      ? ProtocolOptions.HTTP1X
      : healthProbe?.exec
        ? ProtocolOptions.EXEC
        : healthProbe?.tcp
          ? ProtocolOptions.TCP
          : healthProbe?.grpc
            ? ProtocolOptions.GRPC
            : undefined,
    service: healthProbe?.grpc?.service,
    successThreshold: healthProbe?.successThreshold || 2,
    timeoutSeconds: healthProbe?.timeoutSeconds || 10,
  }
}

/**
 * Configures the default values for the liveness health probe fields for the Create Container Group form.
 *
 * @param healthProbe The health probe.
 */
const configureLivenessHealthProbeDefaultValues = (
  healthProbe:
    | ContainerGroupLivenessProbe
    | ContainerGroupReadinessProbe
    | ContainerGroupStartupProbe
    | null
    | undefined,
): HealthProbeDefaultValues => {
  const command = healthProbe?.exec?.command[0]
  let commandArguments = undefined
  if (healthProbe?.exec?.command && healthProbe?.exec?.command.length > 1) {
    commandArguments = healthProbe.exec.command.slice(1).map((arg) => ({ argument: arg }))
  }

  return {
    command,
    commandArguments,
    enabled: healthProbe != null,
    failureThreshold: healthProbe?.failureThreshold || 3,
    headers: healthProbe?.http?.headers,
    initialDelaySeconds: healthProbe?.initialDelaySeconds || 0,
    path: healthProbe?.http?.path,
    periodSeconds: healthProbe?.periodSeconds || 10,
    port: healthProbe?.http?.port || healthProbe?.grpc?.port || healthProbe?.tcp?.port || 1,
    protocol: healthProbe?.http
      ? ProtocolOptions.HTTP1X
      : healthProbe?.exec
        ? ProtocolOptions.EXEC
        : healthProbe?.tcp
          ? ProtocolOptions.TCP
          : healthProbe?.grpc
            ? ProtocolOptions.GRPC
            : undefined,
    service: healthProbe?.grpc?.service,
    successThreshold: healthProbe?.successThreshold || 1,
    timeoutSeconds: healthProbe?.timeoutSeconds || 30,
  }
}

/**
 * Configures the default values for the readiness health probe fields for the Create Container Group form.
 *
 * @param healthProbe The health probe.
 */
const configureReadinessHealthProbeDefaultValues = (
  healthProbe:
    | ContainerGroupLivenessProbe
    | ContainerGroupReadinessProbe
    | ContainerGroupStartupProbe
    | null
    | undefined,
): HealthProbeDefaultValues => {
  const command = healthProbe?.exec?.command[0]
  let commandArguments = undefined
  if (healthProbe?.exec?.command && healthProbe?.exec?.command.length > 1) {
    commandArguments = healthProbe.exec.command.slice(1).map((arg) => ({ argument: arg }))
  }

  return {
    command,
    commandArguments,
    enabled: healthProbe != null,
    failureThreshold: healthProbe?.failureThreshold || 3,
    headers: healthProbe?.http?.headers,
    initialDelaySeconds: healthProbe?.initialDelaySeconds || 0,
    path: healthProbe?.http?.path,
    periodSeconds: healthProbe?.periodSeconds || 1,
    port: healthProbe?.http?.port || healthProbe?.grpc?.port || healthProbe?.tcp?.port || 1,
    protocol: healthProbe?.http
      ? ProtocolOptions.HTTP1X
      : healthProbe?.exec
        ? ProtocolOptions.EXEC
        : healthProbe?.tcp
          ? ProtocolOptions.TCP
          : healthProbe?.grpc
            ? ProtocolOptions.GRPC
            : undefined,
    service: healthProbe?.grpc?.service,
    successThreshold: healthProbe?.successThreshold || 1,
    timeoutSeconds: healthProbe?.timeoutSeconds || 1,
  }
}

/**
 * Configures the default values for the HTTP logging fields for the Create Container Group form.
 *
 * @param httpLogging The HTTP Logging values.
 */
const configureExternalLoggingHttpDefaultValues = (
  httpLogging: ContainerLoggingHttp | null | undefined,
): HTTPExternalLoggingDefaultValues => {
  return {
    compression: httpLogging?.compression || ContainerLoggingHttpCompressionEnum.Gzip,
    format: httpLogging?.format ?? ContainerLoggingHttpFormatEnum.JsonLines,
    headers: httpLogging?.headers ?? undefined,
    host: httpLogging?.host ?? '127.0.0.1',
    password: httpLogging?.password ?? undefined,
    path: httpLogging?.path ?? undefined,
    port: httpLogging?.port || 443,
    user: httpLogging?.user ?? undefined,
  }
}

/**
 * Configures the default values for the Create Container Group form, this will either supply the default values for a
 * blank field or the values from the container group the user wishes to duplicate.
 *
 * @param duplicateContainerGroup The container group the user wishes to duplicate.
 */
export const configureDefaultValues = (
  duplicateContainerGroup: ContainerGroup | undefined,
): CreateContainerGroupValues => {
  const livenessProbeDefaultValues = configureLivenessHealthProbeDefaultValues(duplicateContainerGroup?.livenessProbe)
  const readinessProbeDefaultValues = configureReadinessHealthProbeDefaultValues(
    duplicateContainerGroup?.readinessProbe,
  )
  const startupProbeDefaultValues = configureStartupHealthProbeDefaultValues(duplicateContainerGroup?.startupProbe)
  const externalLoggingHttpDefaultValues = configureExternalLoggingHttpDefaultValues(
    duplicateContainerGroup?.container.logging?.http,
  )
  const externalLoggingService = duplicateContainerGroup?.container.logging?.newRelic
    ? ExternalLoggingService.NEW_RELIC
    : duplicateContainerGroup?.container.logging?.splunk
      ? ExternalLoggingService.SPLUNK
      : duplicateContainerGroup?.container.logging?.tcp
        ? ExternalLoggingService.TCP
        : duplicateContainerGroup?.container.logging?.datadog
          ? ExternalLoggingService.DATADOG
          : duplicateContainerGroup?.container.logging?.http
            ? ExternalLoggingService.HTTP
            : duplicateContainerGroup?.container.logging?.axiom
              ? ExternalLoggingService.AXIOM
              : undefined
  const environmentVariables = duplicateContainerGroup?.container.environmentVariables
  const environmentVariablesArray = environmentVariables
    ? Object.entries(environmentVariables || {}).map(([key, value]) => {
        return { key, value }
      })
    : undefined

  const duplicateContainerGroupMemory = duplicateContainerGroup?.container.resources.memory
  const newRelicHostDefaultValue = 'https://log-api.newrelic.com/log/v1'
  const axiomHostDefaultValue = 'api.axiom.co'
  const dataDogHostDefaultValue = 'http-intake.logs.datadoghq.com/'

  const defaultCoreOption =
    CPUCoreOptions.options.find((option) => option.cores === 8)?.cores || CPUCoreOptions.options[0]?.cores

  let commandArguments = undefined
  if (duplicateContainerGroup?.container.command && duplicateContainerGroup?.container.command.length > 1) {
    commandArguments = duplicateContainerGroup.container.command.slice(1).map((arg) => ({ argument: arg }))
  }

  return {
    awsElasticAccessKeyId: undefined,
    awsElasticSecretAccessKey: undefined,
    axiomApiKey: duplicateContainerGroup?.container.logging?.axiom?.apiToken,
    axiomHost: duplicateContainerGroup?.container.logging?.axiom?.host || axiomHostDefaultValue,
    axiomDatasetName: duplicateContainerGroup?.container.logging?.axiom?.dataset,
    azurePassword: undefined,
    azureUsername: undefined,
    autostartPolicy: duplicateContainerGroup?.autostartPolicy || true,
    command: duplicateContainerGroup?.container?.command?.[0] ?? undefined,
    commandArguments,
    datadogApi: duplicateContainerGroup?.container.logging?.datadog?.apiKey,
    datadogHost: duplicateContainerGroup?.container.logging?.datadog?.host || dataDogHostDefaultValue,
    datadogTags: duplicateContainerGroup?.container.logging?.datadog?.tags || [],
    diskSpace: duplicateContainerGroup?.container.resources.storageAmount || undefined,
    dockerHubPersonalAccessToken: undefined,
    dockerHubUsername: undefined,
    environmentVariables: environmentVariablesArray,
    externalLoggingService: externalLoggingService,
    gitHubPersonalAccessToken: undefined,
    gitHubUsername: undefined,
    googleArtifactJsonKey: undefined,
    googleContainerJsonKey: undefined,
    gpu: duplicateContainerGroup?.container.resources.gpuClasses ?? undefined,
    httpCompression: externalLoggingHttpDefaultValues.compression,
    httpFormat: externalLoggingHttpDefaultValues.format,
    httpHeaders: externalLoggingHttpDefaultValues.headers,
    httpHost: externalLoggingHttpDefaultValues.host,
    httpPassword: externalLoggingHttpDefaultValues.password,
    httpPath: externalLoggingHttpDefaultValues.path,
    httpPort: externalLoggingHttpDefaultValues.port,
    httpUser: externalLoggingHttpDefaultValues.user,
    imageSource: '',
    imageType: ImageType.PUBLIC,
    jobQueue: duplicateContainerGroup?.queueConnection?.queueName ?? undefined,
    jobQueuePath: duplicateContainerGroup?.queueConnection?.path ?? undefined,
    jobQueuePort: duplicateContainerGroup?.queueConnection?.port ?? undefined,
    livenessProbeCommand: livenessProbeDefaultValues.command,
    livenessProbeCommandArguments: livenessProbeDefaultValues.commandArguments,
    livenessProbeEnabled: livenessProbeDefaultValues.enabled,
    livenessProbeFailureThreshold: livenessProbeDefaultValues.failureThreshold,
    livenessProbeHeaders: livenessProbeDefaultValues.headers,
    livenessProbeInitialDelaySeconds: livenessProbeDefaultValues.initialDelaySeconds,
    livenessProbePath: livenessProbeDefaultValues.path,
    livenessProbePeriodSeconds: livenessProbeDefaultValues.periodSeconds,
    livenessProbePort: livenessProbeDefaultValues.port,
    livenessProbeProtocol: livenessProbeDefaultValues.protocol,
    livenessProbeService: livenessProbeDefaultValues.service,
    livenessProbeSuccessThreshold: livenessProbeDefaultValues.successThreshold,
    livenessProbeTimeoutSeconds: livenessProbeDefaultValues.timeoutSeconds,
    memory: duplicateContainerGroupMemory ? duplicateContainerGroupMemory : getDefaultRamOption(),
    name: duplicateContainerGroup?.name ? `${duplicateContainerGroup?.name}-duplicate` : '',
    containerGatewayRequiresAuthentication: duplicateContainerGroup?.networking?.auth || false,
    containerGatewayEnabled: duplicateContainerGroup?.networking != null,
    containerGatewayPort: duplicateContainerGroup?.networking?.port || 1,
    newRelicHost: duplicateContainerGroup?.container.logging?.newRelic?.host ?? newRelicHostDefaultValue,
    newRelicIngestionKey: duplicateContainerGroup?.container.logging?.newRelic?.ingestionKey,
    privateRegistryProvider: undefined,
    quayPassword: undefined,
    quayUsername: undefined,
    readinessProbeCommand: readinessProbeDefaultValues.command,
    readinessProbeCommandArguments: readinessProbeDefaultValues.commandArguments,
    readinessProbeEnabled: readinessProbeDefaultValues.enabled,
    readinessProbeFailureThreshold: readinessProbeDefaultValues.failureThreshold,
    readinessProbeHeaders: readinessProbeDefaultValues.headers,
    readinessProbeInitialDelaySeconds: readinessProbeDefaultValues.initialDelaySeconds,
    readinessProbePath: readinessProbeDefaultValues.path,
    readinessProbePeriodSeconds: readinessProbeDefaultValues.periodSeconds,
    readinessProbePort: readinessProbeDefaultValues.port,
    readinessProbeProtocol: readinessProbeDefaultValues.protocol,
    readinessProbeService: readinessProbeDefaultValues.service,
    readinessProbeSuccessThreshold: readinessProbeDefaultValues.successThreshold,
    readinessProbeTimeoutSeconds: readinessProbeDefaultValues.timeoutSeconds,
    replicaCount: duplicateContainerGroup?.replicas || 1,
    selfHostedPassword: undefined,
    selfHostedUsername: undefined,
    splunkHost: duplicateContainerGroup?.container.logging?.splunk?.host,
    splunkToken: duplicateContainerGroup?.container.logging?.splunk?.token,
    startupProbeCommand: startupProbeDefaultValues.command,
    startupProbeCommandArguments: startupProbeDefaultValues.commandArguments,
    startupProbeEnabled: startupProbeDefaultValues.enabled,
    startupProbeFailureThreshold: startupProbeDefaultValues.failureThreshold,
    startupProbeHeaders: startupProbeDefaultValues.headers,
    startupProbeInitialDelaySeconds: startupProbeDefaultValues.initialDelaySeconds,
    startupProbePath: startupProbeDefaultValues.path,
    startupProbePeriodSeconds: startupProbeDefaultValues.periodSeconds,
    startupProbePort: startupProbeDefaultValues.port,
    startupProbeProtocol: startupProbeDefaultValues.protocol,
    startupProbeService: startupProbeDefaultValues.service,
    startupProbeSuccessThreshold: startupProbeDefaultValues.successThreshold,
    startupProbeTimeoutSeconds: startupProbeDefaultValues.timeoutSeconds,
    tcpHost: duplicateContainerGroup?.container.logging?.tcp?.host,
    tcpPort: duplicateContainerGroup?.container.logging?.tcp?.port || 1,
    vcpus: duplicateContainerGroup?.container.resources.cpu || defaultCoreOption || 1,
  }
}

/**
 * The apply preset configuration function that applies the preset configuration to the Create Container Group form.
 *
 * @param presetConfig The preset configuration.
 * @param onCardClick The callback function to handle the card click.
 */
export const applyPresetConfig = (
  onCardClick: (values: Partial<CreateContainerGroupValues>) => void,
  presetValues?: Partial<CreateContainerGroupValues>,
) => {
  const defaultValues = configureDefaultValues(undefined)
  const mergedValues = { ...defaultValues, ...presetValues }
  onCardClick(mergedValues)
}

/**
 * There are multiple options (`DockerHub`, `Quay`, and `Github`) that we provide for the user to select from when
 * choosing a private registry provider, that we treat the same way when we pass along the input values to the API. This
 * function takes the two required values and returns the appropriate authentication object to be passed along to the
 * API.
 *
 * @param username The username for the private registry provider.
 * @param password The password for the private registry provider.
 */
const configureDockerHubRegistryAuthentication = (
  username: string,
  password: string,
): CreateContainerRegistryAuthentication => {
  return {
    dockerHub: {
      username: username,
      personalAccessToken: password,
    },
  }
}

/**
 * There are two options (`Azure` and `Self-Hosted`) that we provide for the user to select from when choosing a private
 * registry provider, that we treat the same way when we pass along the input values to the API. This function takes the
 * two required values and returns the appropriate authentication object to be passed along to the API.
 *
 * @param username The username for the private registry provider.
 * @param password The password for the private registry provider.
 */
const configureBasicRegistryAuthentication = (
  username: string,
  password: string,
): CreateContainerRegistryAuthentication => {
  return {
    basic: {
      username: username,
      password: password,
    },
  }
}

const configureRegistryAuthentication = (
  privateRegistryProvider: PrivateRegistryProvider | undefined,
  dockerHubUsername: string | undefined,
  dockerHubPassword: string | undefined,
  gitHubUsername: string | undefined,
  gitHubPassword: string | undefined,
  quayUsername: string | undefined,
  quayPassword: string | undefined,
  awsElasticAccessKeyID: string | undefined,
  awsElasticSecretAccessKey: string | undefined,
  selfHostedUsername: string | undefined,
  selfHostedPassword: string | undefined,
  azureUsername: string | undefined,
  azurePassword: string | undefined,
): CreateContainerRegistryAuthentication | null => {
  if (privateRegistryProvider === PrivateRegistryProvider.DOCKER_HUB && dockerHubUsername && dockerHubPassword) {
    return configureDockerHubRegistryAuthentication(dockerHubUsername, dockerHubPassword)
  }

  if (privateRegistryProvider === PrivateRegistryProvider.GITHUB_CONTAINER && gitHubUsername && gitHubPassword) {
    return configureDockerHubRegistryAuthentication(gitHubUsername, gitHubPassword)
  }

  if (privateRegistryProvider === PrivateRegistryProvider.QUAY_CONTAINER && quayUsername && quayPassword) {
    return configureDockerHubRegistryAuthentication(quayUsername, quayPassword)
  }

  if (
    privateRegistryProvider === PrivateRegistryProvider.AWS_ECR &&
    awsElasticAccessKeyID &&
    awsElasticSecretAccessKey
  ) {
    return {
      awsEcr: {
        accessKeyId: awsElasticAccessKeyID,
        secretAccessKey: awsElasticSecretAccessKey,
      },
    }
  }

  if (privateRegistryProvider === PrivateRegistryProvider.SELF_HOSTED && selfHostedUsername && selfHostedPassword) {
    return configureBasicRegistryAuthentication(selfHostedUsername, selfHostedPassword)
  }

  if (privateRegistryProvider === PrivateRegistryProvider.AZURE_CONTAINER && azureUsername && azurePassword) {
    return configureBasicRegistryAuthentication(azureUsername, azurePassword)
  }

  return null
}

/**
 * The submit handler for the Create Container Group form that configures the object shape to be passed along to the
 * API.
 *
 * @param fieldValues The create container group form field values.
 * @param onSubmit The submit callback that when evoked creates the container group.
 */
export const handleSubmitCreateContainerGroup = (
  fieldValues: CreateContainerGroupValues,
  onSubmit: (values: CreateContainerGroup) => void,
) => {
  const {
    autostartPolicy,
    awsElasticAccessKeyId,
    awsElasticSecretAccessKey,
    axiomApiKey,
    axiomDatasetName,
    axiomHost,
    azurePassword,
    azureUsername,
    command,
    commandArguments,
    containerGatewayEnabled,
    containerGatewayPort,
    containerGatewayRequiresAuthentication,
    datadogApi,
    datadogHost,
    datadogTags,
    diskSpace,
    dockerHubPersonalAccessToken,
    dockerHubUsername,
    environmentVariables,
    externalLoggingService,
    gitHubPersonalAccessToken,
    gitHubUsername,
    googleArtifactJsonKey,
    googleContainerJsonKey,
    gpu,
    httpCompression,
    httpFormat,
    httpHeaders,
    httpHost,
    httpPassword,
    httpPath,
    httpPort,
    httpUser,
    imageSource,
    imageType,
    jobQueue,
    jobQueuePath,
    jobQueuePort,
    livenessProbeCommand,
    livenessProbeCommandArguments,
    livenessProbeEnabled,
    livenessProbeFailureThreshold,
    livenessProbeHeaders,
    livenessProbeInitialDelaySeconds,
    livenessProbePath,
    livenessProbePeriodSeconds,
    livenessProbePort,
    livenessProbeProtocol,
    livenessProbeService,
    livenessProbeSuccessThreshold,
    livenessProbeTimeoutSeconds,
    memory,
    name,
    newRelicHost,
    newRelicIngestionKey,
    privateRegistryProvider,
    quayPassword,
    quayUsername,
    readinessProbeCommand,
    readinessProbeCommandArguments,
    readinessProbeEnabled,
    readinessProbeFailureThreshold,
    readinessProbeHeaders,
    readinessProbeInitialDelaySeconds,
    readinessProbePath,
    readinessProbePeriodSeconds,
    readinessProbePort,
    readinessProbeProtocol,
    readinessProbeService,
    readinessProbeSuccessThreshold,
    readinessProbeTimeoutSeconds,
    replicaCount,
    selfHostedPassword,
    selfHostedUsername,
    splunkHost,
    splunkToken,
    startupProbeCommand,
    startupProbeCommandArguments,
    startupProbeEnabled,
    startupProbeFailureThreshold,
    startupProbeHeaders,
    startupProbeInitialDelaySeconds,
    startupProbePath,
    startupProbePeriodSeconds,
    startupProbePort,
    startupProbeProtocol,
    startupProbeService,
    startupProbeSuccessThreshold,
    startupProbeTimeoutSeconds,
    tcpHost,
    tcpPort,
    vcpus,
  } = fieldValues

  let constructedCommand = null
  if (command) {
    const args = commandArguments?.map((arg) => arg.argument || '')
    constructedCommand = args ? flattenDeep([command, ...args]) : [command]
  }

  let envVariables: { [key: string]: string } | undefined = undefined
  if (environmentVariables !== undefined && environmentVariables.length > 0) {
    envVariables = environmentVariables.reduce((accumulator, item) => {
      return { ...accumulator, [item.key]: item.value }
    }, {})
  }

  let logging: ContainerLogging | null = null
  if (externalLoggingService) {
    if (externalLoggingService === ExternalLoggingService.AXIOM && axiomApiKey && axiomDatasetName && axiomHost) {
      logging = {
        axiom: {
          apiToken: axiomApiKey,
          dataset: axiomDatasetName,
          host: axiomHost,
        },
      }
    }

    if (externalLoggingService === ExternalLoggingService.DATADOG && datadogApi && datadogHost) {
      logging = {
        datadog: {
          apiKey: datadogApi,
          host: datadogHost,
          tags: datadogTags,
        },
      }
    }

    if (
      externalLoggingService === ExternalLoggingService.HTTP &&
      httpCompression &&
      httpFormat &&
      httpPort &&
      httpHost
    ) {
      logging = {
        http: {
          compression: httpCompression,
          format: httpFormat,
          headers: httpHeaders && httpHeaders.length > 0 ? httpHeaders : null,
          host: httpHost,
          password: httpPassword ?? null,
          path: httpPath ?? null,
          port: httpPort,
          user: httpUser ?? null,
        },
      }
    }

    if (externalLoggingService === ExternalLoggingService.NEW_RELIC && newRelicHost && newRelicIngestionKey) {
      logging = {
        newRelic: {
          host: newRelicHost,
          ingestionKey: newRelicIngestionKey,
        },
      }
    }

    if (externalLoggingService === ExternalLoggingService.SPLUNK && splunkHost && splunkToken) {
      logging = {
        splunk: {
          host: splunkHost,
          token: splunkToken,
        },
      }
    }

    if (externalLoggingService === ExternalLoggingService.TCP && tcpHost && tcpPort) {
      logging = {
        tcp: {
          host: tcpHost,
          port: tcpPort,
        },
      }
    }
  }

  const livenessProbe = configureHealthProbeValues({
    command: livenessProbeCommand,
    commandArgs: livenessProbeCommandArguments,
    enabled: livenessProbeEnabled,
    failureThreshold: livenessProbeFailureThreshold,
    headers: livenessProbeHeaders,
    initialDelaySeconds: livenessProbeInitialDelaySeconds,
    periodSeconds: livenessProbePeriodSeconds,
    path: livenessProbePath,
    port: livenessProbePort,
    protocol: livenessProbeProtocol,
    service: livenessProbeService,
    successThreshold: livenessProbeSuccessThreshold,
    timeoutSeconds: livenessProbeTimeoutSeconds,
  })

  const readinessProbe = configureHealthProbeValues({
    command: readinessProbeCommand,
    commandArgs: readinessProbeCommandArguments,
    enabled: readinessProbeEnabled,
    failureThreshold: readinessProbeFailureThreshold,
    headers: readinessProbeHeaders,
    initialDelaySeconds: readinessProbeInitialDelaySeconds,
    path: readinessProbePath,
    periodSeconds: readinessProbePeriodSeconds,
    port: readinessProbePort,
    protocol: readinessProbeProtocol,
    service: readinessProbeService,
    successThreshold: readinessProbeSuccessThreshold,
    timeoutSeconds: readinessProbeTimeoutSeconds,
  })

  const startupProbe = configureHealthProbeValues({
    command: startupProbeCommand,
    commandArgs: startupProbeCommandArguments,
    enabled: startupProbeEnabled,
    failureThreshold: startupProbeFailureThreshold,
    headers: startupProbeHeaders,
    initialDelaySeconds: startupProbeInitialDelaySeconds,
    path: startupProbePath,
    periodSeconds: startupProbePeriodSeconds,
    port: startupProbePort,
    protocol: startupProbeProtocol,
    service: startupProbeService,
    successThreshold: startupProbeSuccessThreshold,
    timeoutSeconds: startupProbeTimeoutSeconds,
  })

  const queueConnection =
    jobQueue && jobQueuePath && jobQueuePort ? { queueName: jobQueue, path: jobQueuePath, port: jobQueuePort } : null

  const containerGroup: CreateContainerGroup = {
    autostartPolicy,
    container: {
      command: constructedCommand,
      environmentVariables: envVariables,
      image: imageSource,
      logging,
      registryAuthentication: null,
      resources: {
        cpu: vcpus,
        gpuClasses: gpu,
        memory,
        storageAmount: diskSpace,
      },
    },
    queueConnection,
    livenessProbe,
    name,
    networking: containerGatewayEnabled
      ? {
          auth: containerGatewayRequiresAuthentication,
          protocol: 'http',
          port: containerGatewayPort,
        }
      : null,
    readinessProbe,
    replicas: replicaCount,
    restartPolicy: 'always',
    startupProbe,
  }

  if (
    imageType === ImageType.PRIVATE &&
    privateRegistryProvider === PrivateRegistryProvider.GOOGLE_ARTIFACT_REGISTRY &&
    googleArtifactJsonKey
  ) {
    const file = googleArtifactJsonKey[0]
    const reader = new FileReader()
    reader.addEventListener('load', () => {
      const registryAuthentication: CreateContainerRegistryAuthentication = {
        gcpGar: {
          serviceKey: reader.result as string,
        },
      }
      containerGroup.container.registryAuthentication = registryAuthentication

      return onSubmit(containerGroup)
    })
    reader.addEventListener('error', () => {
      // TODO: Get requirements on how we would like to show this error to the end user
      console.error('Error reading file')
    })
    if (file) {
      reader.readAsText(file)
    }
  } else if (
    imageType === ImageType.PRIVATE &&
    privateRegistryProvider === PrivateRegistryProvider.GOOGLE_CONTAINER_REGISTRY &&
    googleContainerJsonKey
  ) {
    const file = googleContainerJsonKey[0]
    const reader = new FileReader()
    reader.addEventListener('load', () => {
      const registryAuthentication: CreateContainerRegistryAuthentication = {
        gcpGcr: {
          serviceKey: reader.result as string,
        },
      }
      containerGroup.container.registryAuthentication = registryAuthentication

      return onSubmit(containerGroup)
    })
    reader.addEventListener('error', () => {
      // TODO: Get requirements on how we would like to show this error to the end user
      console.error('Error reading file')
    })
    if (file) {
      reader.readAsText(file)
    }
  } else {
    containerGroup.container.registryAuthentication =
      imageType === ImageType.PRIVATE
        ? configureRegistryAuthentication(
            privateRegistryProvider,
            dockerHubUsername,
            dockerHubPersonalAccessToken,
            gitHubUsername,
            gitHubPersonalAccessToken,
            quayUsername,
            quayPassword,
            awsElasticAccessKeyId,
            awsElasticSecretAccessKey,
            selfHostedUsername,
            selfHostedPassword,
            azureUsername,
            azurePassword,
          )
        : null

    onSubmit(containerGroup)
  }
}

/**
 * A function that handles taking the status of each steps visibility on the page in order to update the steps with the
 * appropriate state for the `WizardProgressBar` component.
 *
 * @param stepsStatus An object of each step and the status of whether or not they are visible on the page.
 * @param steps The steps for the `CreateContainerGroup` form that are identified in the `WizardProgressBar` component.
 * @param onUpdateSteps The callback to update the steps for the `WizardProgressBar` component.
 */
export const handleUpdateStepInView = (
  stepsStatus: {
    containerGroupName: boolean
    imageSource: boolean
    resourceAllocation: boolean
    containerGatewayAndEnvironmentVariables: boolean
  },
  steps: VerticalProgressBarStep[],
  onUpdateSteps: (steps: VerticalProgressBarStep[]) => void,
) => {
  const stepsByKeys = Object.keys(stepsStatus)

  let lastStepInViewIndex = -1
  if (stepsStatus['containerGroupName'] && stepsStatus['imageSource'] && stepsStatus['resourceAllocation']) {
    lastStepInViewIndex = 1
  } else {
    lastStepInViewIndex = stepsByKeys
      .map((key) => {
        const stepKey = key as keyof typeof stepsStatus
        return stepsStatus[stepKey]
      })
      .lastIndexOf(true)
  }

  const lastStepInView = steps[lastStepInViewIndex]
  if (lastStepInView !== undefined) {
    const currentStepIndex = steps.findIndex((s) => s.label === lastStepInView.label)
    const updatedSteps: VerticalProgressBarStep[] = steps.map((s, index) => {
      if (index < currentStepIndex) {
        return { ...s, status: 'completed' }
      } else if (index === currentStepIndex) {
        return { ...s, status: 'current' }
      } else {
        return { ...s, status: 'incomplete' }
      }
    })

    onUpdateSteps(updatedSteps)
  }
}

interface ProbeValues {
  enabled?: boolean
  command?: string
  commandArgs?: { argument: string }[]
  failureThreshold?: number
  headers?: HttpHeadersInner[]
  initialDelaySeconds?: number
  path?: string
  periodSeconds?: number
  port?: number
  protocol?: string
  service?: string
  successThreshold?: number
  timeoutSeconds?: number
}

/**
 * A function that handles taking the values from any health probe from and configuring them to be passed along to the
 * API request for creating a container group.
 *
 * @param values The health probe form values.
 */
const configureHealthProbeValues = (
  values: ProbeValues,
): ContainerGroupLivenessProbe | ContainerGroupReadinessProbe | ContainerGroupStartupProbe | null => {
  const {
    command,
    commandArgs,
    enabled,
    failureThreshold,
    headers,
    initialDelaySeconds,
    path,
    periodSeconds,
    port,
    protocol,
    service,
    successThreshold,
    timeoutSeconds,
  } = values
  let healthProbe: ContainerGroupLivenessProbe | ContainerGroupReadinessProbe | ContainerGroupStartupProbe | null = null

  if (
    enabled &&
    failureThreshold !== undefined &&
    initialDelaySeconds !== undefined &&
    periodSeconds !== undefined &&
    protocol &&
    successThreshold !== undefined &&
    timeoutSeconds !== undefined
  ) {
    const commonValues = {
      failureThreshold,
      initialDelaySeconds,
      protocol,
      periodSeconds,
      successThreshold,
      timeoutSeconds,
    }

    // Health Probe with Exec Protocol
    if (protocol === ProtocolOptions.EXEC && command) {
      const commandArguments = commandArgs?.map((arg) => arg.argument)
      const constructedCommand = commandArguments ? flattenDeep([command, ...commandArguments]) : [command]
      healthProbe = {
        exec: {
          command: constructedCommand,
        },
        ...commonValues,
      }
    }

    // Health Probe with gRPC Protocol
    if (protocol === ProtocolOptions.GRPC && port) {
      healthProbe = {
        grpc: {
          port,
          service: service || '',
        },
        ...commonValues,
      }
    }

    // Health Probe with HTTP Protocol
    if (protocol === ProtocolOptions.HTTP1X && port && path) {
      healthProbe = {
        http: {
          headers,
          path,
          port,
          scheme: 'http',
        },
        ...commonValues,
      }
    }

    // Health Probe with TCP Protocol
    if (protocol === ProtocolOptions.TCP && port) {
      healthProbe = {
        tcp: {
          port,
        },
        ...commonValues,
      }
    }
  }

  return healthProbe
}

/**
 * A callback that identifies the first field with a validation error, if any error exists, and returns the section id
 * where that field is placed for the `CreateContainerGroup` form.
 *
 * @param onGetFieldState The `react-hook-form` function that returns the state of a field.
 */
export const getSectionIdWithFirstValidationError = (
  onGetFieldState: UseFormGetFieldState<FieldValues>,
): CreateContainerGroupFormSectionIdAttributes | undefined => {
  const fields = Object.values(CreateContainerGroupField)

  for (const field of fields) {
    const fieldState = onGetFieldState(field)
    if (fieldState?.invalid) {
      return getFieldSectionId(field)
    }
  }

  return undefined
}

/**
 * Based on the field that is passed in, this function will return the appropriate section id for the
 * `CreateContainerGroup` form.
 *
 * @param field The field.
 */
const getFieldSectionId = (field: string): CreateContainerGroupFormSectionIdAttributes => {
  switch (field) {
    case CreateContainerGroupField.NAME:
      return CreateContainerGroupFormSectionIdAttributes.CONTAINER_GROUP_NAME

    case CreateContainerGroupField.IMAGE_SOURCE:
    case CreateContainerGroupField.IMAGE_TYPE:
    case CreateContainerGroupField.PRIVATE_REGISTRY_PROVIDER:
    case CreateContainerGroupField.DOCKER_HUB_USERNAME:
    case CreateContainerGroupField.DOCKER_HUB_PERSONAL_ACCESS_TOKEN:
    case CreateContainerGroupField.GIT_HUB_USERNAME:
    case CreateContainerGroupField.GIT_HUB_PERSONAL_ACCESS_TOKEN:
    case CreateContainerGroupField.QUAY_USERNAME:
    case CreateContainerGroupField.QUAY_PASSWORD:
    case CreateContainerGroupField.AWS_ELASTIC_ACCESS_KEY_ID:
    case CreateContainerGroupField.AWS_ELASTIC_SECRET_ACCESS_KEY:
    case CreateContainerGroupField.SELF_HOSTED_USERNAME:
    case CreateContainerGroupField.SELF_HOSTED_PASSWORD:
    case CreateContainerGroupField.AZURE_USERNAME:
    case CreateContainerGroupField.AZURE_PASSWORD:
    case CreateContainerGroupField.GOOGLE_ARTIFACT_JSON_KEY:
    case CreateContainerGroupField.GOOGLE_CONTAINER_JSON_KEY:
      return CreateContainerGroupFormSectionIdAttributes.IMAGE_SOURCE

    case CreateContainerGroupField.REPLICA_COUNT:
      return CreateContainerGroupFormSectionIdAttributes.REPLICA_COUNT

    case CreateContainerGroupField.VCPUS:
      return CreateContainerGroupFormSectionIdAttributes.VCPU

    case CreateContainerGroupField.MEMORY:
      return CreateContainerGroupFormSectionIdAttributes.MEMORY

    case CreateContainerGroupField.GPU:
      return CreateContainerGroupFormSectionIdAttributes.GPU

    case CreateContainerGroupField.DISK_SPACE:
      return CreateContainerGroupFormSectionIdAttributes.DISK_SPACE

    case CreateContainerGroupField.STARTUP_PROBE_COMMAND:
    case CreateContainerGroupField.STARTUP_PROBE_COMMAND_ARGUMENTS:
    case CreateContainerGroupField.STARTUP_PROBE_ENABLED:
    case CreateContainerGroupField.STARTUP_PROBE_FAILURE_THRESHOLD:
    case CreateContainerGroupField.STARTUP_PROBE_HEADERS:
    case CreateContainerGroupField.STARTUP_PROBE_INITIAL_DELAY_SECONDS:
    case CreateContainerGroupField.STARTUP_PROBE_PATH:
    case CreateContainerGroupField.STARTUP_PROBE_PERIOD_SECONDS:
    case CreateContainerGroupField.STARTUP_PROBE_PORT:
    case CreateContainerGroupField.STARTUP_PROBE_PROTOCOL:
    case CreateContainerGroupField.STARTUP_PROBE_SERVICE:
    case CreateContainerGroupField.STARTUP_PROBE_SUCCESS_THRESHOLD:
    case CreateContainerGroupField.STARTUP_PROBE_TIMEOUT_SECONDS:
      return CreateContainerGroupFormSectionIdAttributes.STARTUP_PROBE

    case CreateContainerGroupField.LIVENESS_PROBE_COMMAND:
    case CreateContainerGroupField.LIVENESS_PROBE_COMMAND_ARGUMENTS:
    case CreateContainerGroupField.LIVENESS_PROBE_ENABLED:
    case CreateContainerGroupField.LIVENESS_PROBE_FAILURE_THRESHOLD:
    case CreateContainerGroupField.LIVENESS_PROBE_HEADERS:
    case CreateContainerGroupField.LIVENESS_PROBE_INITIAL_DELAY_SECONDS:
    case CreateContainerGroupField.LIVENESS_PROBE_PATH:
    case CreateContainerGroupField.LIVENESS_PROBE_PERIOD_SECONDS:
    case CreateContainerGroupField.LIVENESS_PROBE_PORT:
    case CreateContainerGroupField.LIVENESS_PROBE_PROTOCOL:
    case CreateContainerGroupField.LIVENESS_PROBE_SERVICE:
    case CreateContainerGroupField.LIVENESS_PROBE_SUCCESS_THRESHOLD:
    case CreateContainerGroupField.LIVENESS_PROBE_TIMEOUT_SECONDS:
      return CreateContainerGroupFormSectionIdAttributes.LIVENESS_PROBE

    case CreateContainerGroupField.READINESS_PROBE_COMMAND:
    case CreateContainerGroupField.READINESS_PROBE_COMMAND_ARGUMENTS:
    case CreateContainerGroupField.READINESS_PROBE_ENABLED:
    case CreateContainerGroupField.READINESS_PROBE_FAILURE_THRESHOLD:
    case CreateContainerGroupField.READINESS_PROBE_HEADERS:
    case CreateContainerGroupField.READINESS_PROBE_INITIAL_DELAY_SECONDS:
    case CreateContainerGroupField.READINESS_PROBE_PATH:
    case CreateContainerGroupField.READINESS_PROBE_PERIOD_SECONDS:
    case CreateContainerGroupField.READINESS_PROBE_PORT:
    case CreateContainerGroupField.READINESS_PROBE_PROTOCOL:
    case CreateContainerGroupField.READINESS_PROBE_SERVICE:
    case CreateContainerGroupField.READINESS_PROBE_SUCCESS_THRESHOLD:
    case CreateContainerGroupField.READINESS_PROBE_TIMEOUT_SECONDS:
      return CreateContainerGroupFormSectionIdAttributes.READINESS_PROBE

    case CreateContainerGroupField.COMMAND:
    case CreateContainerGroupField.COMMAND_ARGUMENTS:
      return CreateContainerGroupFormSectionIdAttributes.COMMAND

    case CreateContainerGroupField.CONTAINER_GATEWAY_ENABLED:
    case CreateContainerGroupField.CONTAINER_GATEWAY_PORT:
    case CreateContainerGroupField.CONTAINER_GATEWAY_REQUIRES_AUTHENTICATION:
      return CreateContainerGroupFormSectionIdAttributes.CONTAINER_GATEWAY

    case CreateContainerGroupField.JOB_QUEUE:
    case CreateContainerGroupField.JOB_QUEUE_PATH:
    case CreateContainerGroupField.JOB_QUEUE_PORT:
      return CreateContainerGroupFormSectionIdAttributes.JOB_QUEUE

    case CreateContainerGroupField.EXTERNAL_LOGGING_SERVICE:
    case CreateContainerGroupField.AXIOM_API_KEY:
    case CreateContainerGroupField.AXIOM_DATASET_NAME:
    case CreateContainerGroupField.AXIOM_HOST:
    case CreateContainerGroupField.DATADOG_API:
    case CreateContainerGroupField.DATADOG_HOST:
    case CreateContainerGroupField.DATADOG_TAGS:
    case CreateContainerGroupField.HTTP_COMPRESSION:
    case CreateContainerGroupField.HTTP_FORMAT:
    case CreateContainerGroupField.HTTP_HEADERS:
    case CreateContainerGroupField.HTTP_HOST:
    case CreateContainerGroupField.HTTP_PASSWORD:
    case CreateContainerGroupField.HTTP_PATH:
    case CreateContainerGroupField.HTTP_PORT:
    case CreateContainerGroupField.HTTP_USER:
    case CreateContainerGroupField.NEW_RELIC_HOST:
    case CreateContainerGroupField.NEW_RELIC_INGESTION_KEY:
    case CreateContainerGroupField.SPLUNK_HOST:
    case CreateContainerGroupField.SPLUNK_TOKEN:
    case CreateContainerGroupField.TCP_HOST:
    case CreateContainerGroupField.TCP_PORT:
      return CreateContainerGroupFormSectionIdAttributes.EXTERNAL_LOGGING_SERVICE

    case CreateContainerGroupField.ENVIRONMENT_VARIABLES:
      return CreateContainerGroupFormSectionIdAttributes.ENVIRONMENT_VARIABLES

    default:
      return CreateContainerGroupFormSectionIdAttributes.CONTAINER_GROUP_NAME
  }
}

export enum CreateContainerGroupPageStep {
  PresetContainer,
  ContainerConfiguration,
  MachineHardware,
  ContainerConnections,
  ContainerMonitoring,
}

export const fieldsToValidateByStep: Record<CreateContainerGroupPageStep, CreateContainerGroupField[]> = {
  [CreateContainerGroupPageStep.PresetContainer]: [],
  [CreateContainerGroupPageStep.ContainerConfiguration]: [
    CreateContainerGroupField.NAME,
    CreateContainerGroupField.IMAGE_SOURCE,
    CreateContainerGroupField.REPLICA_COUNT,
    CreateContainerGroupField.ENVIRONMENT_VARIABLES,
    CreateContainerGroupField.COMMAND,
  ],
  [CreateContainerGroupPageStep.MachineHardware]: [
    CreateContainerGroupField.VCPUS,
    CreateContainerGroupField.MEMORY,
    CreateContainerGroupField.GPU,
    CreateContainerGroupField.DISK_SPACE,
  ],
  [CreateContainerGroupPageStep.ContainerConnections]: [
    CreateContainerGroupField.CONTAINER_GATEWAY_ENABLED,
    CreateContainerGroupField.CONTAINER_GATEWAY_PORT,
    CreateContainerGroupField.CONTAINER_GATEWAY_REQUIRES_AUTHENTICATION,
    CreateContainerGroupField.JOB_QUEUE,
    CreateContainerGroupField.JOB_QUEUE_PATH,
    CreateContainerGroupField.JOB_QUEUE_PORT,
  ],
  [CreateContainerGroupPageStep.ContainerMonitoring]: [
    CreateContainerGroupField.LIVENESS_PROBE_ENABLED,
    CreateContainerGroupField.LIVENESS_PROBE_PROTOCOL,
    CreateContainerGroupField.LIVENESS_PROBE_PORT,
    CreateContainerGroupField.LIVENESS_PROBE_PATH,
    CreateContainerGroupField.LIVENESS_PROBE_INITIAL_DELAY_SECONDS,
    CreateContainerGroupField.LIVENESS_PROBE_PERIOD_SECONDS,
    CreateContainerGroupField.LIVENESS_PROBE_TIMEOUT_SECONDS,
    CreateContainerGroupField.LIVENESS_PROBE_FAILURE_THRESHOLD,
    CreateContainerGroupField.LIVENESS_PROBE_SUCCESS_THRESHOLD,
    CreateContainerGroupField.LIVENESS_PROBE_COMMAND,
    CreateContainerGroupField.LIVENESS_PROBE_COMMAND_ARGUMENTS,
    CreateContainerGroupField.LIVENESS_PROBE_SERVICE,
    CreateContainerGroupField.LIVENESS_PROBE_HEADERS,
    CreateContainerGroupField.READINESS_PROBE_ENABLED,
    CreateContainerGroupField.READINESS_PROBE_PROTOCOL,
    CreateContainerGroupField.READINESS_PROBE_PORT,
    CreateContainerGroupField.READINESS_PROBE_PATH,
    CreateContainerGroupField.READINESS_PROBE_INITIAL_DELAY_SECONDS,
    CreateContainerGroupField.READINESS_PROBE_PERIOD_SECONDS,
    CreateContainerGroupField.READINESS_PROBE_TIMEOUT_SECONDS,
    CreateContainerGroupField.READINESS_PROBE_FAILURE_THRESHOLD,
    CreateContainerGroupField.READINESS_PROBE_SUCCESS_THRESHOLD,
    CreateContainerGroupField.READINESS_PROBE_COMMAND,
    CreateContainerGroupField.READINESS_PROBE_COMMAND_ARGUMENTS,
    CreateContainerGroupField.READINESS_PROBE_SERVICE,
    CreateContainerGroupField.READINESS_PROBE_HEADERS,
    CreateContainerGroupField.STARTUP_PROBE_ENABLED,
    CreateContainerGroupField.STARTUP_PROBE_PROTOCOL,
    CreateContainerGroupField.STARTUP_PROBE_PORT,
    CreateContainerGroupField.STARTUP_PROBE_PATH,
    CreateContainerGroupField.STARTUP_PROBE_INITIAL_DELAY_SECONDS,
    CreateContainerGroupField.STARTUP_PROBE_PERIOD_SECONDS,
    CreateContainerGroupField.STARTUP_PROBE_TIMEOUT_SECONDS,
    CreateContainerGroupField.STARTUP_PROBE_FAILURE_THRESHOLD,
    CreateContainerGroupField.STARTUP_PROBE_SUCCESS_THRESHOLD,
    CreateContainerGroupField.STARTUP_PROBE_COMMAND,
    CreateContainerGroupField.STARTUP_PROBE_COMMAND_ARGUMENTS,
    CreateContainerGroupField.STARTUP_PROBE_SERVICE,
    CreateContainerGroupField.STARTUP_PROBE_HEADERS,
    CreateContainerGroupField.EXTERNAL_LOGGING_SERVICE,
    CreateContainerGroupField.AXIOM_API_KEY,
    CreateContainerGroupField.AXIOM_DATASET_NAME,
    CreateContainerGroupField.AXIOM_HOST,
    CreateContainerGroupField.DATADOG_API,
    CreateContainerGroupField.DATADOG_HOST,
    CreateContainerGroupField.DATADOG_TAGS,
    CreateContainerGroupField.HTTP_COMPRESSION,
    CreateContainerGroupField.HTTP_FORMAT,
    CreateContainerGroupField.HTTP_HEADERS,
    CreateContainerGroupField.HTTP_HOST,
    CreateContainerGroupField.HTTP_PASSWORD,
    CreateContainerGroupField.HTTP_PATH,
    CreateContainerGroupField.HTTP_PORT,
    CreateContainerGroupField.HTTP_USER,
    CreateContainerGroupField.NEW_RELIC_HOST,
    CreateContainerGroupField.NEW_RELIC_INGESTION_KEY,
    CreateContainerGroupField.SPLUNK_HOST,
    CreateContainerGroupField.SPLUNK_TOKEN,
    CreateContainerGroupField.TCP_HOST,
    CreateContainerGroupField.TCP_PORT,
  ],
}
