import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import type { ObjectSchema } from 'yup'
import { object, string } from 'yup'
import { InviteTeamMemberMainContentMessages } from '../../messages'
import type { InviteTeamMemberValues } from './InviteTeamMemberPageMainContent'

export const useInviteTeamMemberFormValidation = (): ObjectSchema<InviteTeamMemberValues> => {
  const intl = useIntl()
  const validationScheme = useMemo(
    () =>
      object({
        email: string()
          .required(intl.formatMessage(InviteTeamMemberMainContentMessages.emailRequired))
          .email(intl.formatMessage(InviteTeamMemberMainContentMessages.emailInvalid)),
      }),
    [intl],
  )
  return validationScheme
}
