import type { ReactNode } from 'react'
import type { IntlShape } from 'react-intl'
import type { ToastNotificationType } from '../../models'
import { apiUrlCopiedToKeyboardSucceededMessages } from './messages'

/**
 * Retrieves the content needed for a success `ToastNotification` that is shown when the user copies their API URL to
 * the clipboard.
 *
 * @param intl The `IntlShape` object needed to format the messages.
 */
export const getApiUrlCopiedToKeyboardSucceededContent = (
  intl: IntlShape,
): {
  body: ReactNode
  type: ToastNotificationType
  title: string
} => {
  return {
    body: intl.formatMessage(apiUrlCopiedToKeyboardSucceededMessages.body),
    type: 'success',
    title: intl.formatMessage(apiUrlCopiedToKeyboardSucceededMessages.title),
  }
}
