import type { FunctionComponent } from 'react'
import type { Control, FieldValues } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { TextField } from '../../../../components/TextField'
import { Link } from '../../../../components/base'
import { ReplicCountInputMessages } from '../../messages'
import type { CreateContainerGroupFormSectionIdAttributes, CreateContainerGroupValues } from '../../models'
import { CreateContainerGroupField } from '../../models'

interface ReplicaCountInputProps {
  /** The control for the react hook form. */
  control: Control<FieldValues, CreateContainerGroupValues>
  /** The id attribute for the field. TODO: Make this required once this isn't used in Recipe Deployments */
  id?: CreateContainerGroupFormSectionIdAttributes
  /** The maximum replica count allowed for the organization. */
  max: number
  /** Link to increase limit */
  requestIncreaseLimitLink: string
  /** The maximum replica count allowed for the organization. */
  onChange?: (value: number) => void
}

export const ReplicaCountInput: FunctionComponent<ReplicaCountInputProps> = ({
  control,
  id,
  max,
  onChange,
  requestIncreaseLimitLink,
}) => {
  const intl = useIntl()
  const unableToUpdateReplicas = max === 0 || max === 1

  return (
    <div id={id}>
      <Controller
        name={CreateContainerGroupField.REPLICA_COUNT}
        control={control}
        render={({ field, fieldState }) => {
          return (
            <TextField
              {...field}
              {...fieldState}
              defaultValue={max === 0 ? 0 : field.value}
              helperText={
                unableToUpdateReplicas
                  ? intl.formatMessage(ReplicCountInputMessages.helperTextWhenMaxIsOne, {
                      contact_support: (
                        <Link url={requestIncreaseLimitLink}>
                          {intl.formatMessage(ReplicCountInputMessages.contactUsText)}
                        </Link>
                      ),
                    })
                  : intl.formatMessage(ReplicCountInputMessages.helperText, {
                      contact_support: (
                        <Link url={requestIncreaseLimitLink}>
                          {intl.formatMessage(ReplicCountInputMessages.contactUsText)}
                        </Link>
                      ),
                      max_replicas: max,
                    })
              }
              onChange={(value) => {
                onChange?.(value as number)
                field.onChange(value)
              }}
              label={intl.formatMessage(ReplicCountInputMessages.label)}
              min={1}
              max={max}
              isDisabled={unableToUpdateReplicas}
              isFullWidth
              type="number"
            />
          )
        }}
      />
    </div>
  )
}
