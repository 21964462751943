import { defineMessages } from 'react-intl'

export const ChangeAPIKeySucceededContentMessages = defineMessages({
  body: {
    defaultMessage:
      'Your old API key will no longer be accepted. Remember to update any uses of this key in your work.',
    id: 'f31zdq/m',
    description:
      'The description shown in a toast notification message shown when the user successfully changed their API Key.',
  },
  title: {
    defaultMessage: 'Your API key has been changed',
    id: '6NFuuFQf',
    description: 'The toast notification title shown when the user successfully changed their API Key.',
  },
})
