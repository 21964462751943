import {
  ContainerGroupStatus,
  DeleteContainerGroupProblemType,
  GetRecipeDeploymentProblemType,
  ResponseError,
  StartRecipeDeploymentProblemType,
  StopRecipeDeploymentProblemType,
} from '@saladtechnologies/openapi-cloud-portal-browser'
import {
  EMPTY,
  catchError,
  concat,
  defer,
  delay,
  filter,
  forkJoin,
  from,
  mergeMap,
  of,
  repeat,
  switchMap,
  takeUntil,
  timer,
} from 'rxjs'
import { OrganizationsAPI, ProjectsAPI, RecipeDeploymentsAPI, assertUnreachable } from '../apiMethods'
import { showToastNotification } from '../features/notifications/notificationsSlice'
import { organizationAdded } from '../features/organizations/organizationsSlice'
import { projectsAddedToOrganization } from '../features/projects/projectsSlice'
import { GetRecipeDeploymentRequestType } from '../features/recipeDeploymentDetails/models'
import {
  deleteRecipeDeployment,
  getRecipeDeploymentDetailsPageData,
  getRecipeDeploymentInstances,
  pollRecipeDeployment,
  setDeleteRecipeDeploymentRequestStatus,
  setGetRecipeDeploymentRequestStatus,
  setRecipeDeployment,
  setRecipeDeploymentInstances,
  setStartRecipeDeploymentRequestStatus,
  setStopRecipeDeploymentRequestStatus,
  startRecipeDeployment,
  stopPollingForRecipeDeployment,
  stopRecipeDeployment,
} from '../features/recipeDeploymentDetails/recipeDeploymentDetailsSlice'
import { RequestStatus } from '../models'
import {
  getRecipeDeploymentDetailsNotAvailableErrorContent,
  getRecipeDeploymentInstancesGeneralErrorContent,
  getSuccessfullyDeletedRecipeDeploymentContent,
} from '../notifications/clientToastNotificationContent/recipes'
import { getRecipeDeploymentsPagePath } from '../routes/routes-utils'
import type { AppEpic } from '../store'
import { navigateTo } from './navigationEpic'

export const onPollRecipeDeployment: AppEpic = (action$, state$, { intl }) =>
  action$.pipe(
    filter(pollRecipeDeployment.match),
    switchMap((action) =>
      concat(
        of(setGetRecipeDeploymentRequestStatus({ status: RequestStatus.Loading })),
        defer(() =>
          from(
            RecipeDeploymentsAPI.getRecipeDeployment({
              organizationName: action.payload.organizationName,
              projectName: action.payload.projectName,
              recipeDeploymentName: action.payload.recipeDeploymentName,
            }),
          ),
        ).pipe(
          mergeMap((response) =>
            concat(
              of(
                getRecipeDeploymentInstances({
                  organizationName: action.payload.organizationName,
                  projectName: action.payload.projectName,
                  recipeName: action.payload.recipeDeploymentName,
                }),
                setRecipeDeployment({ recipeDeployment: response }),
                setGetRecipeDeploymentRequestStatus({ status: RequestStatus.Succeeded }),
              ),
              of(setGetRecipeDeploymentRequestStatus({ status: RequestStatus.Idle })).pipe(delay(1)),
            ),
          ),
          catchError((error: unknown) => {
            const errorMessage =
              'We were unable to complete your request to view this recipe deployment at this time. Please try again.'
            return error instanceof ResponseError && error.response.status === 400
              ? from(error.response.json()).pipe(
                  mergeMap((errorResponse) => {
                    const errorType = errorResponse.type as GetRecipeDeploymentProblemType | null
                    if (errorType != null && errorType !== GetRecipeDeploymentProblemType.Null) {
                      switch (errorType) {
                        default:
                          assertUnreachable(errorType)
                      }
                    } else {
                      return concat(
                        of(
                          showToastNotification({
                            body: errorMessage,
                            title: 'Unable to retrieve recipe deployment',
                            type: 'error',
                          }),
                          setGetRecipeDeploymentRequestStatus({ status: RequestStatus.Failed }),
                        ),
                        of(setGetRecipeDeploymentRequestStatus({ status: RequestStatus.Idle })).pipe(delay(1)),
                      )
                    }
                  }),
                )
              : error instanceof ResponseError && error.response.status === 404
                ? concat(
                    of(
                      setGetRecipeDeploymentRequestStatus({ status: RequestStatus.Failed }),
                      showToastNotification(getRecipeDeploymentDetailsNotAvailableErrorContent(intl)),
                      navigateTo({
                        path: getRecipeDeploymentsPagePath(action.payload.organizationName, action.payload.projectName),
                      }),
                    ),
                    of(setGetRecipeDeploymentRequestStatus({ status: RequestStatus.Idle })).pipe(delay(1)),
                  )
                : from(
                    RecipeDeploymentsAPI.getRecipeDeployment({
                      organizationName: action.payload.organizationName,
                      projectName: action.payload.projectName,
                      recipeDeploymentName: action.payload.recipeDeploymentName,
                    }),
                  ).pipe(
                    mergeMap((response) => {
                      return concat(
                        of(
                          setRecipeDeployment({ recipeDeployment: response }),
                          setGetRecipeDeploymentRequestStatus({ status: RequestStatus.Succeeded }),
                        ),
                        of(setGetRecipeDeploymentRequestStatus({ status: RequestStatus.Idle })).pipe(delay(1)),
                      )
                    }),
                    // TODO: Decide what UI we should show to the user during our retry attempts where we keep failing
                    catchError(() => EMPTY),
                    takeUntil(action$.pipe(filter(stopPollingForRecipeDeployment.match))),
                  )
          }),
          repeat({
            delay: (repeatCount) => {
              const { requestType } = action.payload
              const recipeDeploymentStatus = state$.value.recipeDeploymentDetails.recipeDeployment?.currentState.status
              const everyMinuteInMS = 60000
              const everyThreeSecondsInMS = 3000
              const everyFifteenSecondsInMS = 15000

              let repeatTimerInMS = everyMinuteInMS
              if (requestType === GetRecipeDeploymentRequestType.LOAD && recipeDeploymentStatus === undefined) {
                if (repeatCount <= 10) {
                  repeatTimerInMS = everyThreeSecondsInMS
                } else if (repeatCount <= 20) {
                  repeatTimerInMS = everyFifteenSecondsInMS
                } else {
                  repeatTimerInMS = everyMinuteInMS
                }
              }

              if (
                requestType === GetRecipeDeploymentRequestType.LOAD &&
                recipeDeploymentStatus === ContainerGroupStatus.Pending
              ) {
                repeatTimerInMS = everyThreeSecondsInMS
              }

              if (
                requestType === GetRecipeDeploymentRequestType.START &&
                recipeDeploymentStatus !== ContainerGroupStatus.Running
              ) {
                repeatTimerInMS = everyThreeSecondsInMS
              }

              if (
                requestType === GetRecipeDeploymentRequestType.STOP &&
                recipeDeploymentStatus !== ContainerGroupStatus.Stopped
              ) {
                repeatTimerInMS = everyThreeSecondsInMS
              }

              return timer(repeatTimerInMS)
            },
          }),
          takeUntil(action$.pipe(filter(stopPollingForRecipeDeployment.match))),
        ),
      ),
    ),
  )

export const onGetRecipeDeploymentDetailsPageData: AppEpic = (action$, _state$, { intl }) => {
  return action$.pipe(
    filter(getRecipeDeploymentDetailsPageData.match),
    switchMap((action) =>
      concat(
        of(
          pollRecipeDeployment({
            organizationName: action.payload.organizationName,
            projectName: action.payload.projectName,
            recipeDeploymentName: action.payload.recipeDeploymentName,
            requestType: GetRecipeDeploymentRequestType.LOAD,
          }),
        ),
        forkJoin([
          OrganizationsAPI.getOrganization({
            organizationName: action.payload.organizationName,
          }),
          ProjectsAPI.listProjects({
            organizationName: action.payload.organizationName,
          }),
        ]).pipe(
          mergeMap(([organizationResponse, projectsResponse]) =>
            concat(
              of(
                organizationAdded(organizationResponse),
                projectsAddedToOrganization({
                  organizationName: action.payload.organizationName,
                  projects: projectsResponse.items,
                }),
                setGetRecipeDeploymentRequestStatus({ status: RequestStatus.Succeeded }),
              ),
            ),
          ),
          catchError(() =>
            concat(
              of(
                showToastNotification(getRecipeDeploymentDetailsNotAvailableErrorContent(intl)),
                navigateTo({
                  path: getRecipeDeploymentsPagePath(action.payload.organizationName, action.payload.projectName),
                }),
                setGetRecipeDeploymentRequestStatus({ status: RequestStatus.Failed }),
              ),
              of(setGetRecipeDeploymentRequestStatus({ status: RequestStatus.Idle })).pipe(delay(1)),
            ),
          ),
        ),
      ),
    ),
  )
}

export const onGetRecipeDeploymentInstances: AppEpic = (action$, _state$, { intl }) => {
  return action$.pipe(
    filter(getRecipeDeploymentInstances.match),
    switchMap((action) =>
      concat(
        from(
          RecipeDeploymentsAPI.listRecipeDeploymentInstances({
            organizationName: action.payload.organizationName,
            projectName: action.payload.projectName,
            recipeDeploymentName: action.payload.recipeName,
          }),
        ),
      ).pipe(
        mergeMap((response) => of(setRecipeDeploymentInstances({ recipeDeploymentInstances: response.instances }))),
        catchError(() => of(showToastNotification(getRecipeDeploymentInstancesGeneralErrorContent(intl)))),
      ),
    ),
  )
}

export const onDeleteRecipeDeployment: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(deleteRecipeDeployment.match),
    mergeMap((action) =>
      concat(
        of(setDeleteRecipeDeploymentRequestStatus({ status: RequestStatus.Loading })),
        from(
          RecipeDeploymentsAPI.deleteRecipeDeployment({
            organizationName: action.payload.organizationName,
            projectName: action.payload.projectName,
            recipeDeploymentName: action.payload.recipeDeploymentName,
          }),
        ).pipe(
          mergeMap(() =>
            concat(
              of(
                setDeleteRecipeDeploymentRequestStatus({ status: RequestStatus.Succeeded }),
                showToastNotification(
                  getSuccessfullyDeletedRecipeDeploymentContent(intl, action.payload.recipeDeploymentName),
                ),
                stopPollingForRecipeDeployment(),
                navigateTo({
                  path: getRecipeDeploymentsPagePath(action.payload.organizationName, action.payload.projectName),
                }),
              ),
              of(setDeleteRecipeDeploymentRequestStatus({ status: RequestStatus.Idle })).pipe(delay(1)),
            ),
          ),
          catchError((error: unknown) => {
            const errorMessage =
              'We were unable to complete your request to delete this recipe deployment at this time. Please try again.'
            return error instanceof ResponseError && error.response.status === 400
              ? from(error.response.json()).pipe(
                  mergeMap((errorResponse) => {
                    const errorType = errorResponse.type as DeleteContainerGroupProblemType | null
                    if (errorType != null && errorType !== DeleteContainerGroupProblemType.Null) {
                      switch (errorType) {
                        default:
                          assertUnreachable(errorType)
                      }
                    } else {
                      return concat(
                        of(
                          showToastNotification({
                            body: errorMessage,
                            title: 'Unable to delete recipe deployment',
                            type: 'error',
                          }),
                          setDeleteRecipeDeploymentRequestStatus({ status: RequestStatus.Failed }),
                        ),
                        of(setDeleteRecipeDeploymentRequestStatus({ status: RequestStatus.Idle })).pipe(delay(1)),
                      )
                    }
                  }),
                )
              : concat(
                  of(
                    showToastNotification({
                      body: errorMessage,
                      title: 'Unable to delete recipe deployment',
                      type: 'error',
                    }),
                    setDeleteRecipeDeploymentRequestStatus({ status: RequestStatus.Failed }),
                  ),
                  of(setDeleteRecipeDeploymentRequestStatus({ status: RequestStatus.Idle })).pipe(delay(1)),
                )
          }),
        ),
      ),
    ),
  )

export const onStartRecipeDeployment: AppEpic = (action$, _state$) =>
  action$.pipe(
    filter(startRecipeDeployment.match),
    mergeMap((action) =>
      concat(
        of(setStartRecipeDeploymentRequestStatus({ status: RequestStatus.Loading })),
        from(
          RecipeDeploymentsAPI.startDeployedRecipe({
            organizationName: action.payload.organizationName,
            projectName: action.payload.projectName,
            recipeDeploymentName: action.payload.recipeDeploymentName,
          }),
        ).pipe(
          mergeMap(() =>
            concat(
              of(
                setStartRecipeDeploymentRequestStatus({ status: RequestStatus.Succeeded }),
                pollRecipeDeployment({
                  organizationName: action.payload.organizationName,
                  projectName: action.payload.projectName,
                  recipeDeploymentName: action.payload.recipeDeploymentName,
                  requestType: GetRecipeDeploymentRequestType.START,
                }),
              ),
              of(setStartRecipeDeploymentRequestStatus({ status: RequestStatus.Idle })).pipe(delay(1)),
            ),
          ),
          catchError((error: unknown) => {
            const errorMessage =
              'We were unable to complete your request to start this recipe deployment at this time. Please try again.'
            return error instanceof ResponseError && error.response.status === 400
              ? from(error.response.json()).pipe(
                  mergeMap((errorResponse) => {
                    const errorType = errorResponse.type as StartRecipeDeploymentProblemType | null
                    if (errorType != null && errorType !== StartRecipeDeploymentProblemType.Null) {
                      switch (errorType) {
                        default:
                          assertUnreachable(errorType)
                      }
                    } else {
                      return concat(
                        of(
                          showToastNotification({
                            body: errorMessage,
                            title: 'Unable to start recipe deployment',
                            type: 'error',
                          }),
                          setStartRecipeDeploymentRequestStatus({ status: RequestStatus.Failed }),
                        ),
                        of(setStartRecipeDeploymentRequestStatus({ status: RequestStatus.Idle })).pipe(delay(1)),
                      )
                    }
                  }),
                )
              : concat(
                  of(
                    showToastNotification({
                      body: errorMessage,
                      title: 'Unable to start recipe deployment',
                      type: 'error',
                    }),
                    setStartRecipeDeploymentRequestStatus({ status: RequestStatus.Failed }),
                  ),
                  of(setStartRecipeDeploymentRequestStatus({ status: RequestStatus.Idle })).pipe(delay(1)),
                )
          }),
        ),
      ),
    ),
  )

export const onStopRecipeDeployment: AppEpic = (action$, _state$) =>
  action$.pipe(
    filter(stopRecipeDeployment.match),
    mergeMap((action) =>
      concat(
        of(setStopRecipeDeploymentRequestStatus({ status: RequestStatus.Loading })),
        from(
          RecipeDeploymentsAPI.stopDeployedRecipe({
            organizationName: action.payload.organizationName,
            projectName: action.payload.projectName,
            recipeDeploymentName: action.payload.recipeDeploymentName,
          }),
        ).pipe(
          mergeMap(() =>
            concat(
              of(
                setStopRecipeDeploymentRequestStatus({ status: RequestStatus.Succeeded }),
                pollRecipeDeployment({
                  organizationName: action.payload.organizationName,
                  projectName: action.payload.projectName,
                  recipeDeploymentName: action.payload.recipeDeploymentName,
                  requestType: GetRecipeDeploymentRequestType.STOP,
                }),
              ),
              of(setStopRecipeDeploymentRequestStatus({ status: RequestStatus.Idle })).pipe(delay(1)),
            ),
          ),
          catchError((error: unknown) => {
            const errorMessage =
              'We were unable to complete your request to stop this recipe deployment at this time. Please try again.'
            return error instanceof ResponseError && error.response.status === 400
              ? from(error.response.json()).pipe(
                  mergeMap((errorResponse) => {
                    const errorType = errorResponse.type as StopRecipeDeploymentProblemType | null
                    if (errorType != null && errorType !== StopRecipeDeploymentProblemType.Null) {
                      switch (errorType) {
                        default:
                          assertUnreachable(errorType)
                      }
                    } else {
                      return concat(
                        of(
                          showToastNotification({
                            body: errorMessage,
                            title: 'Unable to stop recipe deployment',
                            type: 'error',
                          }),
                          setStopRecipeDeploymentRequestStatus({ status: RequestStatus.Failed }),
                        ),
                        of(setStopRecipeDeploymentRequestStatus({ status: RequestStatus.Idle })).pipe(delay(1)),
                      )
                    }
                  }),
                )
              : concat(
                  of(
                    showToastNotification({
                      body: errorMessage,
                      title: 'Unable to stop recipe deployment',
                      type: 'error',
                    }),
                    setStopRecipeDeploymentRequestStatus({ status: RequestStatus.Failed }),
                  ),
                  of(setStopRecipeDeploymentRequestStatus({ status: RequestStatus.Idle })).pipe(delay(1)),
                )
          }),
        ),
      ),
    ),
  )
