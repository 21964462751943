import type { FunctionComponent } from 'react'
import { useCallback } from 'react'
import { useParams } from 'react-router'
import { selectLogoutIsPending } from '../features/logout/logoutSelectors'
import { logout } from '../features/logout/logoutSlice'
import { selectIsLeftColumnShowing } from '../features/navigationBar/navigationBarSelectors'
import { setLeftColumnShowing } from '../features/navigationBar/navigationBarSlice'
import { selectOrganizationDisplayName } from '../features/organizations/organizationsSelectors'
import { selectProfileEmail, selectProfileGravatar } from '../features/profile/profileSelectors'
import {
  selectMockIsLeftColumnShowing,
  selectMockOrganization,
  selectMockUserEmail,
} from '../mockFeatures/mockNavigationBarSelectors'
import { useMockAppDispatch, useMockAppSelector } from '../mockFeatures/mockStore'
import { useAppDispatch, useAppSelector } from '../store'
import { NavigationBar } from './NavigationBar'

export const ConnectedNavigationBar: FunctionComponent = () => {
  let isStorybook = false
  if (window.location.port === '6006' || window.location.host === 'preview-portal-ui.salad.com') {
    isStorybook = true
  }

  return <>{isStorybook ? <ConnectedStorybookNavigationBar /> : <ConnectedAppNavigationBar />}</>
}

const ConnectedStorybookNavigationBar: FunctionComponent = () => {
  // mock store
  const mockDispatch = useMockAppDispatch()
  const mockIsLeftColumnShowing = useMockAppSelector(selectMockIsLeftColumnShowing)
  const mockUserEmail = useMockAppSelector(selectMockUserEmail)
  const mockOrganization = useMockAppSelector(selectMockOrganization)

  return (
    <NavigationBar
      onLeftMenuClick={() => mockDispatch(setLeftColumnShowing({ showing: !mockIsLeftColumnShowing }))}
      onLogOut={() => {}}
      organizationDisplayName={mockOrganization}
      email={mockUserEmail}
      avatarUrl={undefined}
      isLogoutPending={false}
    />
  )
}

const ConnectedAppNavigationBar: FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const { organizationName = '' } = useParams()
  const avatarUrl = useAppSelector(selectProfileGravatar)
  const email = useAppSelector(selectProfileEmail)
  const isLeftColumnShowing = useAppSelector(selectIsLeftColumnShowing)
  const organizationDisplayName = useAppSelector((state) => selectOrganizationDisplayName(state, organizationName))
  const isLogoutPending = useAppSelector(selectLogoutIsPending)

  const onLeftMenuClick = useCallback(() => {
    dispatch(setLeftColumnShowing({ showing: !isLeftColumnShowing }))
  }, [dispatch, isLeftColumnShowing])

  const onLogout = useCallback(() => {
    dispatch(logout())
  }, [dispatch])

  return (
    <NavigationBar
      onLeftMenuClick={onLeftMenuClick}
      onLogOut={onLogout}
      organizationDisplayName={
        organizationName !== undefined ? (organizationDisplayName ?? organizationName) : undefined
      }
      email={email as string}
      avatarUrl={avatarUrl}
      isLogoutPending={isLogoutPending}
    />
  )
}
