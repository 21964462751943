export const getRequestContainerGroupQuotaIncreaseLink = (email: string, organizationName: string) =>
  `https://p1atjy4hixj.typeform.com/to/DkjApeBj#email=${email}&organization=${organizationName}`

export const getRequestContainerGroupInstanceQuotaIncreaseLink = (email: string, organizationName: string) =>
  `https://p1atjy4hixj.typeform.com/to/rqjzclMX#email=${email}&organization=${organizationName}`

export const getRequestRecipeDeploymentQuotaIncreaseLink = (email: string, organizationName: string) =>
  `https://p1atjy4hixj.typeform.com/to/nKsOff2S#email=${email}&organization=${organizationName}`

export const getRequestRecipeDeploymentInstanceQuotaIncreaseLink = (email: string, organizationName: string) =>
  `https://p1atjy4hixj.typeform.com/to/cKARpz3v#email=${email}&organization=${organizationName}`

export const getRequestDeploymentQuotaIncreaseLink = (email: string, organizationName: string) =>
  `https://p1atjy4hixj.typeform.com/to/xcsaR504#email=${email}&organization=${organizationName}`

export const getAppSumoInviteCodeLink = (email: string, organizationName: string) =>
  `https://p1atjy4hixj.typeform.com/to/OoGg7lBB#email=${email}&organization=${organizationName}`
