import { defineMessages } from 'react-intl'

export const MonitoringConfirmationModalMessages = defineMessages({
  monitoringTitle: {
    defaultMessage: 'Continue without enabling readiness probe for the {monitoring_type_text}?',
    id: '4wpLl5Fj',
    description: 'The title for the readiness probe modal.',
  },
  confirmationText: {
    defaultMessage:
      'The Readiness probe ensures that your container group is fully prepared to handle traffic, optimizing its performance and reliability.',
    id: 'wny6OlyC',
    description: 'The confirmation text asking if the user is sure they do not want to enable a readiness probe.',
  },
  enableButton: {
    defaultMessage: 'Enable Readiness Probe',
    id: 'JgiZ5uBB',
    description: 'The label for the go back and enable button.',
  },
  continueButton: {
    defaultMessage: 'Continue',
    id: '6FiYGKDq',
    description: 'The label for the skip button.',
  },
})
