import type { FunctionComponent, ReactNode } from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'

interface LinkProps {
  /** The content that is linked. */
  children: ReactNode
  /** The link ID. */
  id?: string
  /** The flag indicating if we should show our danger styles. */
  isDanger?: boolean
  /** The link click handler. */
  onClick?: () => void
  /** The url. */
  url: string
}

export const Link: FunctionComponent<LinkProps> = ({ children, id, isDanger, onClick, url }) => {
  const isExternalUrl = url.startsWith('/') === false
  const linkStyles = `${isDanger ? 'text-red-70' : 'text-blue-90'} underline`
  return isExternalUrl ? (
    <a className={linkStyles} href={url} id={id} onClick={onClick} target="_blank" rel="noreferrer">
      {children}
    </a>
  ) : (
    <ReactRouterLink id={id} onClick={onClick} className={linkStyles} to={url}>
      {children}
    </ReactRouterLink>
  )
}
