import type { FunctionComponent } from 'react'
import type { Control, FieldValues } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { TextField } from '../../../../../../components/TextField'
import { ButtonLink } from '../../../../../../components/base'
import {
  LoggingInputFieldMessages,
  SelectExternalLoggingServiceMessages,
  SplunkInputFieldsMessages,
} from '../../../../messages'
import type { CreateContainerGroupValues } from '../../../../models'
import { CreateContainerGroupField } from '../../../../models'

interface SplunkInputFieldsProps {
  /** The control for the create container group react hook form. */
  control: Control<FieldValues, CreateContainerGroupValues>
}

export const SplunkInputFields: FunctionComponent<SplunkInputFieldsProps> = ({ control }) => {
  const intl = useIntl()

  return (
    <>
      <Controller
        name={CreateContainerGroupField.SPLUNK_HOST}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            {...fieldState}
            defaultValue={field.value}
            helperText={fieldState.error?.message}
            label={intl.formatMessage(SplunkInputFieldsMessages.hostLabel)}
            type="text"
            isFullWidth
          />
        )}
      />
      <Controller
        name={CreateContainerGroupField.SPLUNK_TOKEN}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            {...fieldState}
            defaultValue={field.value}
            helperText={fieldState.error?.message}
            label={intl.formatMessage(SplunkInputFieldsMessages.tokenLabel)}
            type="text"
            isFullWidth
          />
        )}
      />
      <div>
        <p className="mb-2">
          {intl.formatMessage(LoggingInputFieldMessages.needHelpText, {
            external_logging_provider: intl.formatMessage(SelectExternalLoggingServiceMessages.splunkLabel),
          })}
        </p>
        <ButtonLink
          variant="blue-filled-light"
          url="https://docs.salad.com/container-engine/external-logging/splunk-logging"
        >
          {intl.formatMessage(LoggingInputFieldMessages.learnMoreText)}
        </ButtonLink>
      </div>
    </>
  )
}
