import type { ReactNode } from 'react'
import type { IntlShape } from 'react-intl'
import type { ToastNotificationType } from '../../models'
import { SuccessfullyDeclinedTeamInvitationContentMessage } from './messages'

/**
 * Retrieves the content needed for a success `ToastNotification` that is shown when the user declines a team
 * invitation.
 *
 * @param intl The `IntlShape` object needed to format the messages.
 * @param organizationDisplayName The organization display name.
 */
export const getSuccessfullyDeclinedTeamMemberInvitationContent = (
  intl: IntlShape,
  organizationDisplayName: string,
): {
  body: ReactNode
  type: ToastNotificationType
  title: string
} => {
  return {
    body: intl.formatMessage(SuccessfullyDeclinedTeamInvitationContentMessage.body, {
      organization_display_name: organizationDisplayName,
    }),
    type: 'success',
    title: SuccessfullyDeclinedTeamInvitationContentMessage.title.defaultMessage,
  }
}
