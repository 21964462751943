import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { TimeFrameBasedEstimatedCost } from '../TimeFrameBasedEstimatedCost'
import { EstimatedCostMessages } from './messages'

interface EstimatedCostProps {
  costPerHour: number
  replicaCount: number
}

export const EstimatedCost: FunctionComponent<EstimatedCostProps> = ({ costPerHour, replicaCount }) => {
  const intl = useIntl()
  const costPerHourBasedOnNumberOfInstances = costPerHour * replicaCount

  return (
    <section>
      <p className="mb-4 text-2xl font-bold">{intl.formatMessage(EstimatedCostMessages.title)}</p>
      <p className="mb-10">{intl.formatMessage(EstimatedCostMessages.description)}</p>

      <div className="mb-4 flex justify-between text-xl">
        <p>{intl.formatMessage(EstimatedCostMessages.selectedRecipeCostLabel)}</p>
        <p className="font-bold">
          {intl.formatMessage(EstimatedCostMessages.selectedRecipeCost, { cost_per_hour: costPerHour })}
        </p>
      </div>

      <div className="mb-6 flex justify-between text-xl">
        <p>{intl.formatMessage(EstimatedCostMessages.replicaCountLabel)}</p>
        <p className="font-bold">
          {replicaCount === 1
            ? intl.formatMessage(EstimatedCostMessages.replicaCount, { replica_count: replicaCount })
            : intl.formatMessage(EstimatedCostMessages.replicaCountPlural, { replica_count: replicaCount })}
        </p>
      </div>

      <hr className="mb-6 border-neutral-70" />

      <div className="flex justify-end">
        <TimeFrameBasedEstimatedCost costPerHour={costPerHourBasedOnNumberOfInstances} />
      </div>
    </section>
  )
}
