import { filter, ignoreElements, tap } from 'rxjs'
import { showToastNotification } from '../features/notifications/notificationsSlice'
import { createToastNotification } from '../notifications/createToastNotification'
import type { AppEpic } from '../store'

/**
 * An epic that shows a `Toast Notification` in the app, this `Toast Notification` appears above all the content on the
 * page.
 */
export const onShowToastNotification: AppEpic = (action$, _state$) =>
  action$.pipe(
    filter(showToastNotification.match),
    tap((action) => {
      const { body, title, type } = action.payload

      createToastNotification({
        autoClose: 5000,
        body,
        title,
        type,
      })
    }),
    ignoreElements(),
  )
