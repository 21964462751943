import type { FunctionComponent } from 'react'
import { EstimatedCost } from '../../components/recipes/EstimatedCost'
import { ThreeColumnLayout } from '../../layouts/threeColumnLayout'
import { CreateRecipeDeploymentMainContent } from './components/CreateRecipeDeploymentMainContent/CreateRecipeDeploymentMainContent'
import { CreateRecipeDeploymentSteps } from './components/CreateRecipeDeploymentSteps'

export interface CreateRecipeDeploymentPageProps {
  /** The path for the change recipe page. */
  changeRecipePath: string
  /** The current organization that the user is in. */
  currentOrganization: { name: string; displayName: string }
  /** The current project that the user is in. */
  currentProject: { name: string; displayName: string }
  /** The flag that indicating if the billing information required. */
  isBillingInformationRequired: boolean
  /** The flag indicating that the request to create the project is pending. */
  isCreatingRecipeDeploymentPending: boolean
  /** The flag indicating if the left column is showing */
  isLeftColumnOpen: boolean
  /** The flag that indicating if the recipe instances quota exceeded. */
  isRecipeInstancesQuotaExceeded: boolean
  /** The flag that indicating if the recipe quota exceeded. */
  isRecipeQuotaExceeded: boolean
  /** The link to the typeform for requesting an increase in the recipe deployment instance quota limit. */
  linkToRequestIncreaseForRecipeDeploymentInstancesQuotas: string
  /** The link to the typeform for requesting an increase in the recipe deployment max quota limit. */
  linkToRequestIncreaseForRecipeDeploymentQuotas: string
  /** The maximum recipe count allowed (quota) */
  maxRecipeDeployments: number
  /** The max replica count. */
  maxReplicaCount: number
  /** The callback executed when the user closes the left drawer */
  onCloseLeftDrawer: () => void
  /** A callback that when executed creates recipe deployment. */
  onCreateRecipeDeployment: (recipeDeploymentName: string, replicaCount: number) => void
  /** The callback executed when the user needs to enter their billing information. */
  onEnterBillingInformation: () => void
  /** A callback executed each time the replica count is updated. */
  onUpdateReplicaCount: (pendingReplicaCount: number) => void
  /** The pending recipe deployment replica count. */
  pendingReplicaCount: number
  /** The recipe cost per hour. */
  recipeCostPerHour: number
  /** The path for the recipe details. */
  recipeDetailsPath: string
  /** The recipe deployment name. */
  recipeName: string
}

export const CreateRecipeDeploymentPage: FunctionComponent<CreateRecipeDeploymentPageProps> = ({
  changeRecipePath,
  currentOrganization,
  currentProject,
  isBillingInformationRequired,
  isCreatingRecipeDeploymentPending,
  isLeftColumnOpen,
  isRecipeInstancesQuotaExceeded,
  isRecipeQuotaExceeded,
  linkToRequestIncreaseForRecipeDeploymentInstancesQuotas,
  linkToRequestIncreaseForRecipeDeploymentQuotas,
  maxRecipeDeployments,
  maxReplicaCount,
  onCloseLeftDrawer,
  onCreateRecipeDeployment,
  onEnterBillingInformation,
  onUpdateReplicaCount,
  pendingReplicaCount,
  recipeCostPerHour,
  recipeDetailsPath,
  recipeName,
}) => {
  return (
    <ThreeColumnLayout
      isRightColumnShowcase
      isLeftColumnOpen={isLeftColumnOpen}
      LeftColumn={
        <CreateRecipeDeploymentSteps
          organizationDisplayName={currentOrganization.displayName}
          projectDisplayName={currentProject.displayName}
        />
      }
      MainColumn={
        <CreateRecipeDeploymentMainContent
          changeRecipePath={changeRecipePath}
          isBillingInformationRequired={isBillingInformationRequired}
          isCreatingRecipeDeploymentPending={isCreatingRecipeDeploymentPending}
          isRecipeInstancesQuotaExceeded={isRecipeInstancesQuotaExceeded}
          isRecipeQuotaExceeded={isRecipeQuotaExceeded}
          linkToRequestIncreaseForRecipeDeploymentInstancesQuotas={
            linkToRequestIncreaseForRecipeDeploymentInstancesQuotas
          }
          linkToRequestIncreaseForRecipeDeploymentQuotas={linkToRequestIncreaseForRecipeDeploymentQuotas}
          maxRecipeDeployments={maxRecipeDeployments}
          maxReplicaCount={maxReplicaCount}
          onCreateRecipeDeployment={onCreateRecipeDeployment}
          onEnterBillingInformation={onEnterBillingInformation}
          onUpdateReplicaCount={onUpdateReplicaCount}
          pendingReplicaCount={pendingReplicaCount}
          recipeCostPerHour={recipeCostPerHour}
          recipeDetailsPath={recipeDetailsPath}
          recipeName={recipeName}
        />
      }
      onCloseLeftDrawer={onCloseLeftDrawer}
      RightColumn={
        <div className="px-6 py-8">
          <EstimatedCost replicaCount={pendingReplicaCount} costPerHour={recipeCostPerHour} />
        </div>
      }
    />
  )
}
