import { defineMessages } from 'react-intl'

export const HealthProbeDetailsMessages = defineMessages({
  commandSummaryLabel: {
    defaultMessage: 'Command: {command}',
    id: 'Yw4N0jF/',
    description: 'The command summary label for the health probe.',
  },
  disabledLabel: {
    defaultMessage: 'Disabled',
    id: 'f3rxrhlM',
    description: 'The disabled label for the health probe.',
  },
  enabledSummaryLabel: {
    defaultMessage: 'Enabled',
    id: 'O2hfk95l',
    description: 'The label to show when health probe is enabled.',
  },
  failureThresholdSummaryLabel: {
    defaultMessage: 'Failure Threshold: {seconds}',
    id: 'n5f7qSId',
    description: 'The failure threshold summary label for the health probe.',
  },
  headersSummaryLabel: {
    defaultMessage: 'Headers: {headers}',
    id: 'sdqG/EWV',
    description: 'The headers summary label for the health probe.',
  },
  initialDelaySecondsSummaryLabel: {
    defaultMessage: 'Initial Delay Seconds: {seconds}',
    id: 'qTkc0FFo',
    description: 'The initial delay seconds summary label for the health probe.',
  },
  pathSummaryLabel: {
    defaultMessage: 'Path: {path}',
    id: 'gGJlvlCG',
    description: 'The path summary label for the health probe.',
  },
  periodSecondsSummaryLabel: {
    defaultMessage: 'Period Seconds: {seconds}',
    id: 'iaZ44yvc',
    description: 'The period seconds summary label for the health probe.',
  },
  portSummaryLabel: {
    defaultMessage: 'Port: {port}',
    id: 'eFa8zH+T',
    description: 'The port summary label for the health probe.',
  },
  protocolSummaryLabel: {
    defaultMessage: 'Protocol: {protocol}',
    id: 'CFWFSAmr',
    description: 'The protocol summary label for the health probe.',
  },
  serviceSummaryLabel: {
    defaultMessage: 'Service: {service}',
    id: 'zkW9GqNV',
    description: 'The service summary label for the health probe.',
  },
  successThresholdSummaryLabel: {
    defaultMessage: 'Success Threshold: {seconds}',
    id: 'GtLvIoRr',
    description: 'The success threshold summary label for the health probe.',
  },
  timeoutSecondsSummaryLabel: {
    defaultMessage: 'Timeout Seconds: {seconds}',
    id: 'uNkUEcQy',
    description: 'The timeout seconds summary label for the health probe.',
  },
})
