import { useMemo } from 'react'
import type { ObjectSchema } from 'yup'
import { object, string } from 'yup'
import type { DeleteProjectValues } from './DeleteProjectModal'

export const useDeleteProjectFormValidation = (projectName: string): ObjectSchema<DeleteProjectValues> => {
  const validationScheme = useMemo(
    () =>
      object({
        projectName: string()
          .required()
          .test((value) => value === projectName),
      }),
    [projectName],
  )
  return validationScheme
}
