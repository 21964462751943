import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import type { ObjectSchema } from 'yup'
import { object, string } from 'yup'
import { EditJobQueueFormMessages } from '../../messages'
import type { EditJobQueueFormValues } from './EditJobQueueForm'

export const useEditJobQueueFormValidation = (): ObjectSchema<EditJobQueueFormValues> => {
  const intl = useIntl()

  const validationScheme = useMemo(
    () =>
      object({
        displayName: string()
          .required()
          .matches(/^[ ,-.0-9A-Za-z]+$/),
        description: string()
          .optional()
          .max(500, intl.formatMessage(EditJobQueueFormMessages.descriptionMaxCharacterErrorMessage)),
      }),
    [intl],
  )
  return validationScheme
}
