import type { FunctionComponent } from 'react'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { Button } from '../../../../components/Button'
import { InputLabel } from '../../../../components/InputLabel'
import { TextField } from '../../../../components/TextField'
import { EnvironmentVariablesListMessages } from './EnvironmentVariablesListMessages'

interface EnvironmentVariablesListProps {
  /** Initial environment variables */
  envVariables: { key: string; value: string }[]
}

export const EnvironmentVariablesList: FunctionComponent<EnvironmentVariablesListProps> = ({ envVariables }) => {
  const [isEnvVariablesHidden, setIsEnvVariablesHidden] = useState(true)
  const intl = useIntl()

  const toggleEnvVariablesHidden = () => {
    setIsEnvVariablesHidden(!isEnvVariablesHidden)
  }

  return (
    <div className="flex w-full flex-col justify-start p-14">
      <div className="flex flex-row gap-6">
        <h1 className="text-3xl font-bold">{intl.formatMessage(EnvironmentVariablesListMessages.title)}</h1>
        <div className="w-20">
          <Button
            onClick={toggleEnvVariablesHidden}
            isCompact
            isFullWidth
            variant={isEnvVariablesHidden ? 'blue-filled-light' : 'blue-outlined'}
            iconClassName={isEnvVariablesHidden ? 'fa-solid fa-eye' : 'fa-solid fa-eye-slash'}
          >
            {intl.formatMessage(
              isEnvVariablesHidden ? EnvironmentVariablesListMessages.show : EnvironmentVariablesListMessages.hide,
            )}
          </Button>
        </div>
      </div>
      <div className="mt-10 flex shrink flex-col gap-2">
        {envVariables.map((envVariable) => (
          <div key={envVariable.key} className="m-0 h-20 overflow-hidden">
            <InputLabel label={envVariable.key} />
            <TextField
              defaultValue={envVariable.value}
              type={isEnvVariablesHidden ? 'password' : 'text'}
              isFullWidth
              onChange={() => {}}
              readonly
            />
          </div>
        ))}
      </div>
    </div>
  )
}
