import { yupResolver } from '@hookform/resolvers/yup'
import type { Queue, RamOption, StorageOption } from '@saladtechnologies/openapi-cloud-portal-browser'
import { ContainerGroup, CreateContainerGroup } from '@saladtechnologies/openapi-cloud-portal-browser'
import type { FunctionComponent } from 'react'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import type { VerticalProgressBarStep } from '../../components/block/VerticalProgressBarWithClick'
import { EstimatedCost } from '../../components/containerGroups/EstimatedCost'
import { GPUOptions } from '../../features/gpuClasses/models'
import { useFixedForm } from '../../hooks'
import { ThreeColumnLayout } from '../../layouts/threeColumnLayout'
import { BillingInformationMissingModal } from './components/BillingInformationMissingModal'
import { ContainerGroupInstancesQuotaExceededModal } from './components/ContainerGroupInstancesQuotaExceededModal'
import { ContainerGroupQuotaExceededModal } from './components/ContainerGroupQuotaExceededModal'
import { CreateContainerGroupMainContent } from './components/CreateContainerGroupMainContent'
import { WizardProgressBar } from './components/WizardProgressBar'
import {
  ContainerConfigurationMessages,
  ContainerConnectionsMessages,
  MachineHardwareMessages,
  PresetContainerMessages,
} from './messages'
import { ContainerMonitoringMessages } from './messages/ContainerMonitoringMessages'
import { CreateContainerGroupValues } from './models'
import { useCreateContainerGroupFormValidation } from './useCreateContainerGroupFormValidation'
import { useGetEstimatedCost } from './useGetEstimatedCost'
import {
  CreateContainerGroupPageStep,
  configureDefaultValues,
  fieldsToValidateByStep,
  handleSubmitCreateContainerGroup,
} from './utils'

export interface CreateContainerGroupPageProps {
  /** The container group the user has selected to duplicate */
  duplicateContainerGroup?: ContainerGroup
  /** The list of available GPU options to choose from. */
  gpuOptions: GPUOptions
  /** The flag indicating the billing information missing modal showing state */
  isBillingInformationMissingModalShowing: boolean
  /** The flag indicating the container group deployment exceeded modal showing state */
  isContainerGroupDeploymentQuotaExceededModalShowing: boolean
  /** The flag indicating the container group instance modal exceeded showing state */
  isContainerGroupInstancesQuotaExceededModalShowing: boolean
  /** The flag indicating if the left column is showing */
  isLeftColumnOpen: boolean
  /** The flag indicating that the request to create a container group is pending. */
  isSubmitPending: boolean
  /** The link to the typeform for requesting an increase in the container group instance quota limit. */
  linkToRequestIncreaseForContainerGroupInstancesQuotas: string
  /** The link for users to request an increase to their container groups quota */
  linkToRequestIncreaseForContainerGroupQuotas: string
  /** The maximum replica count allowed for the organization. */
  maxReplicaCount: number
  /** The callback executed when the user closes the left drawer */
  onCloseLeftDrawer: () => void
  /** The callback executed when the user needs to enter their billing information. */
  onEnterBillingInformation: () => void
  /**
   * A callback that when executed records a mixpanel event for the element that was clicked.
   *
   * @param label The label of the element that was clicked
   */
  onRecordMixpanelElementClickedEvent: (label: string) => void
  /** The callback executed when there is an update to the billing information required modal showing state */
  onSetBillingInformationMissingModalShowingState: (showing: boolean) => void
  /** The callback executed when there is an update to the container group deployments exceeded modal showing state */
  onSetContainerGroupDeploymentsQuotaExceededModalShowingState: (showing: boolean) => void
  /** The callback executed when there is an update to the container group instances exceeded modal showing state */
  onSetContainerGroupInstancesQuotaExceededModalShowingState: (showing: boolean) => void
  /** The callback executed when the user wishes to deploy the container group they just created. */
  onSubmit: (values: CreateContainerGroup) => void
  /**
   * A callback that when executed records a mixpanel page viewed event for the sub page that was viewed.
   *
   * @param subPage The sub page that was viewed
   * @returns
   */
  onTrackMixpanelPageViewedEvent: (subPage: string) => void
  /** The organization display name. */
  organizationDisplayName: string
  /** The project display name. */
  projectDisplayName: string
  /** The available job queues to select from for a container group. */
  queues: Queue[]
  /** The list of available RAM options to choose from. */
  ramOptions: RamOption[] | undefined
  /** The list of available storage options to choose from. */
  storageOptions: StorageOption[] | undefined
}

export const CreateContainerGroupPage: FunctionComponent<CreateContainerGroupPageProps> = ({
  duplicateContainerGroup,
  gpuOptions,
  isBillingInformationMissingModalShowing,
  isContainerGroupDeploymentQuotaExceededModalShowing,
  isContainerGroupInstancesQuotaExceededModalShowing,
  isLeftColumnOpen,
  isSubmitPending,
  linkToRequestIncreaseForContainerGroupInstancesQuotas,
  linkToRequestIncreaseForContainerGroupQuotas,
  maxReplicaCount,
  onCloseLeftDrawer,
  onEnterBillingInformation,
  onRecordMixpanelElementClickedEvent,
  onSetBillingInformationMissingModalShowingState,
  onSetContainerGroupDeploymentsQuotaExceededModalShowingState,
  onSetContainerGroupInstancesQuotaExceededModalShowingState,
  onSubmit,
  onTrackMixpanelPageViewedEvent,
  organizationDisplayName,
  projectDisplayName,
  queues,
  ramOptions,
  storageOptions,
}) => {
  const intl = useIntl()
  const [steps, setSteps] = useState<VerticalProgressBarStep[]>([])
  const [currentPage, setCurrentPage] = useState(duplicateContainerGroup ? 1 : 0)

  const { clearErrors, control, handleSubmit, reset, resetField, setValue, trigger } =
    useFixedForm<CreateContainerGroupValues>({
      defaultValues: configureDefaultValues(duplicateContainerGroup),
      onSubmit: (values) => {
        handleSubmitCreateContainerGroup(values, onSubmit)
      },
      resolver: yupResolver(useCreateContainerGroupFormValidation(maxReplicaCount)),
      mode: 'onChange',
      shouldFocusError: false,
    })

  const estimateCostValues = useGetEstimatedCost(control, gpuOptions, ramOptions)

  useEffect(() => {
    const updatedSteps: VerticalProgressBarStep[] = [
      {
        label: intl.formatMessage(PresetContainerMessages.startingPointText),
        status: currentPage === 0 ? 'current' : 'completed',
      },
      {
        label: intl.formatMessage(ContainerConfigurationMessages.containerConfigurationTitle),
        status: currentPage === 1 ? 'current' : currentPage > 2 ? 'completed' : 'incomplete',
      },
      {
        label: intl.formatMessage(MachineHardwareMessages.machineHardwareTitle),
        status: currentPage === 2 ? 'current' : currentPage > 3 ? 'completed' : 'incomplete',
      },
      {
        label: intl.formatMessage(ContainerConnectionsMessages.containerConnectionsTitle),
        status: currentPage === 3 ? 'current' : currentPage > 4 ? 'completed' : 'incomplete',
      },
      {
        label: intl.formatMessage(ContainerMonitoringMessages.containerMonitoringTitle),
        status: currentPage === 4 ? 'current' : 'incomplete',
      },
    ]

    setSteps(updatedSteps)
    const currentStep = updatedSteps[currentPage]
    if (currentStep) {
      onTrackMixpanelPageViewedEvent(currentStep.label)
    }
  }, [intl, currentPage, onTrackMixpanelPageViewedEvent])

  const handlePageChange = async (stepIndex: number, isValidationRequired?: boolean) => {
    if (isValidationRequired) {
      setCurrentPage(stepIndex)
    } else {
      const fieldsToValidate = fieldsToValidateByStep[currentPage as CreateContainerGroupPageStep]
      const isValid = await trigger(fieldsToValidate)

      if (isValid) {
        setCurrentPage(stepIndex)
      }
    }
  }

  const onCardClick = (values: Partial<CreateContainerGroupValues>) => {
    reset(values)
    handlePageChange(1, true)
  }

  return (
    <>
      <ThreeColumnLayout
        LeftColumn={
          <WizardProgressBar
            onStepClick={handlePageChange}
            organizationDisplayName={organizationDisplayName}
            projectDisplayName={projectDisplayName}
            steps={steps}
          />
        }
        MainColumn={
          <CreateContainerGroupMainContent
            clearErrors={clearErrors}
            control={control}
            currentPage={currentPage}
            gpuOptions={gpuOptions}
            handlePageChange={handlePageChange}
            isSubmitPending={isSubmitPending}
            linkToRequestIncreaseForContainerGroupInstancesQuotas={
              linkToRequestIncreaseForContainerGroupInstancesQuotas
            }
            maxReplicaCount={maxReplicaCount}
            onCardClick={onCardClick}
            onRecordMixpanelElementClickedEvent={onRecordMixpanelElementClickedEvent}
            onSubmit={handleSubmit}
            queues={queues}
            ramOptions={ramOptions}
            resetField={resetField}
            setValue={setValue}
            storageOptions={storageOptions}
            trigger={trigger}
          />
        }
        RightColumn={
          currentPage !== 0 && (
            <div className="p-12">
              <EstimatedCost
                replicaCount={estimateCostValues.replicaCount}
                allocatedResources={estimateCostValues.allocatedResources}
              />
            </div>
          )
        }
        isLeftColumnOpen={isLeftColumnOpen}
        isMainColumnWithoutLayoutStyles
        isMainColumnWithoutScrollStyles
        isRightColumnShowcase
        onCloseLeftDrawer={onCloseLeftDrawer}
      />
      {isBillingInformationMissingModalShowing && (
        <BillingInformationMissingModal
          onCloseWindow={() => onSetBillingInformationMissingModalShowingState(false)}
          onEnterBillingInformation={onEnterBillingInformation}
        />
      )}
      {isContainerGroupDeploymentQuotaExceededModalShowing && (
        <ContainerGroupQuotaExceededModal
          linkToRequestIncreaseForContainerGroupQuotas={linkToRequestIncreaseForContainerGroupQuotas}
          onCloseWindow={() => onSetContainerGroupDeploymentsQuotaExceededModalShowingState(false)}
        />
      )}
      {isContainerGroupInstancesQuotaExceededModalShowing && (
        <ContainerGroupInstancesQuotaExceededModal
          linkToRequestIncreaseForContainerGroupInstancesQuotas={linkToRequestIncreaseForContainerGroupInstancesQuotas}
          onCloseWindow={() => onSetContainerGroupInstancesQuotaExceededModalShowingState(false)}
        />
      )}
    </>
  )
}
