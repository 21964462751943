import type { FunctionComponent } from 'react'
import { LeftNavigationBar } from '../../components/LeftNavigationBar'
import type { ProjectSelectOptions } from '../../components/LeftNavigationBar/models'
import { ThreeColumnLayout } from '../../layouts/threeColumnLayout'
import { EditProjectMainContent } from './components/EditProjectMainContent'

export interface EditProjectPageProps {
  /** The current organization that the user is in. */
  currentOrganization: { name: string; displayName: string }
  /** The current project that the user is in. */
  currentProject: { name: string; displayName: string }
  /** The flag indicating that the request to delete the project is pending. */
  isDeleteProjectPending: boolean
  /** The flag indicating that the request to delete the project is successful. */
  isDeleteProjectSuccessful: boolean
  /** The flag indicating that the request to edit the project is pending. */
  isEditProjectPending: boolean
  /** The flag indicating if the left column is showing */
  isLeftColumnOpen: boolean
  /** The callback executed when the user closes the left drawer */
  onCloseLeftDrawer: () => void
  /**
   * A callback that is executed when the user submits the form to edit the project.
   *
   * @param displayName The project display name.
   */
  onEditProject: (displayName: string) => void
  /** The callback executed when the user clicks the delete project button. */
  onDeleteProject?: (projectName: string) => void
  /**
   * The handler for when something new is selected in the project dropdown. If the current active project is selected,
   * nothing will happen.
   */
  onProjectChange: (projectName: string) => void
  /** List of projects. */
  projects: ProjectSelectOptions
}

export const EditProjectPage: FunctionComponent<EditProjectPageProps> = ({
  currentOrganization,
  currentProject,
  isDeleteProjectPending,
  isDeleteProjectSuccessful,
  isEditProjectPending,
  isLeftColumnOpen,
  onCloseLeftDrawer,
  onDeleteProject,
  onEditProject,
  onProjectChange,
  projects,
}) => {
  return (
    <ThreeColumnLayout
      isLeftColumnOpen={isLeftColumnOpen}
      LeftColumn={
        <LeftNavigationBar
          currentOrganization={currentOrganization}
          currentProject={currentProject}
          isDeleteProjectPending={isDeleteProjectPending}
          isDeleteProjectSuccessful={isDeleteProjectSuccessful}
          onDeleteProject={onDeleteProject}
          onProjectChange={onProjectChange}
          projects={projects}
        />
      }
      MainColumn={
        <EditProjectMainContent
          isSubmitPending={isEditProjectPending}
          onEditProject={onEditProject}
          projectDisplayName={currentProject.displayName}
          projectName={currentProject.name}
        />
      }
      onCloseLeftDrawer={onCloseLeftDrawer}
    />
  )
}
