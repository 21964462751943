import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { Button } from '../../../components/Button'
import { ModalLayout } from '../../../layouts/authentication'
import { ResetPasswordSucceededMessages } from './ResetPasswordSucceededMessages'

export interface ResetPasswordSucceededProps {
  /** "Go to login" button handler. */
  onViewLoginPage: () => void
}

export const ResetPasswordSucceeded: FunctionComponent<ResetPasswordSucceededProps> = ({ onViewLoginPage }) => {
  const intl = useIntl()

  return (
    <ModalLayout type="narrow">
      <h1 className="text-2xl font-bold">{intl.formatMessage(ResetPasswordSucceededMessages.title)}</h1>
      <p className="text-base">{intl.formatMessage(ResetPasswordSucceededMessages.description)}</p>
      <Button onClick={onViewLoginPage}>{intl.formatMessage(ResetPasswordSucceededMessages.submit)}</Button>
    </ModalLayout>
  )
}
