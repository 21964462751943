import {
  ReallocateContainerGroupInstanceProblemType,
  RecreateContainerGroupInstanceProblemType,
  ResponseError,
  RestartContainerGroupInstanceProblemType,
} from '@saladtechnologies/openapi-cloud-portal-browser'
import { catchError, concat, filter, from, mergeMap, of } from 'rxjs'
import { ContainerGroupsAPI, assertUnreachable } from '../apiMethods'
import {
  getContainerGroupAndInstances,
  setIsReallocateContainerGroupInstancePending,
  setIsRecreateContainerGroupInstancePending,
  setIsRestartContainerGroupInstancePending,
} from '../features/containerGroupDetail/containerGroupDetailSlice'
import {
  reallocateContainerGroupInstance,
  recreateContainerGroupInstance,
  restartContainerGroupInstance,
} from '../features/containerGroupInstances/containerGroupInstancesSlice'
import { InstanceActionType } from '../features/containerGroupInstances/models'
import { showToastNotification } from '../features/notifications/notificationsSlice'
import {
  getContainerGroupInstanceNotFoundErrorContent,
  getContainerGroupNotFoundErrorContent,
  getOrganizationNotFoundErrorContent,
  getOrganizationQuotasNotDefinedErrorContent,
  getSuccessfullyReallocatedDeploymentInstanceContent,
  getSuccessfullyRecreatedDeploymentInstanceContent,
  getSuccessfullyRestartedDeploymentInstanceContent,
  getUnableToExecuteActionToContainerGroupInstanceGeneralErrorContent,
  getUnableToExecuteActionToContainerGroupInstanceNodeNotExistErrorContent,
  getUnableToExecuteActionToInstanceLimitExceededErrorContent,
} from '../notifications/clientToastNotificationContent/instanceDetails'
import {
  getContainerGroupDetailsPagePath,
  getContainerGroupsPagePath,
  getOrganizationsPagePath,
} from '../routes/routes-utils'
import type { AppEpic } from '../store'
import { navigateTo } from './navigationEpic'

export const onReallocateContainerGroupInstance: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(reallocateContainerGroupInstance.match),
    mergeMap(({ payload: { containerGroupName, instanceId, organizationName, projectName, redirectPath } }) =>
      concat(
        of(
          setIsReallocateContainerGroupInstancePending({
            instanceId,
            isPending: true,
          }),
        ),
        from(
          ContainerGroupsAPI.containerGroupInstanceReallocate({
            containerGroupName,
            organizationName,
            projectName,
            containerGroupInstanceId: instanceId,
          }),
        ).pipe(
          mergeMap(() => {
            return redirectPath
              ? concat(
                  of(
                    setIsReallocateContainerGroupInstancePending({
                      instanceId,
                      isPending: false,
                    }),
                  ),
                  of(showToastNotification(getSuccessfullyReallocatedDeploymentInstanceContent(intl))),
                  of(navigateTo({ path: redirectPath })),
                )
              : concat(
                  of(
                    setIsReallocateContainerGroupInstancePending({
                      instanceId,
                      isPending: false,
                    }),
                  ),
                  of(
                    getContainerGroupAndInstances({
                      containerGroupName,
                      organizationName,
                      projectName,
                    }),
                  ),
                  of(showToastNotification(getSuccessfullyReallocatedDeploymentInstanceContent(intl))),
                )
          }),
          catchError((error: unknown) =>
            error instanceof ResponseError && error.response.status !== 500
              ? from(error.response.json()).pipe(
                  mergeMap((errorResponse) => {
                    const errorType = errorResponse.type as ReallocateContainerGroupInstanceProblemType | null

                    if (errorType != null && errorType !== ReallocateContainerGroupInstanceProblemType.Null) {
                      switch (errorType) {
                        case ReallocateContainerGroupInstanceProblemType.NodeNotScheduled:
                          return concat(
                            of(
                              setIsReallocateContainerGroupInstancePending({
                                instanceId,
                                isPending: false,
                              }),
                              getContainerGroupAndInstances({
                                containerGroupName,
                                organizationName,
                                projectName,
                              }),
                            ),
                            of(
                              showToastNotification(
                                getUnableToExecuteActionToContainerGroupInstanceNodeNotExistErrorContent(
                                  intl,
                                  InstanceActionType.Reallocate,
                                ),
                              ),
                            ),
                          )

                        case ReallocateContainerGroupInstanceProblemType.TooManyRequests:
                          return concat(
                            of(
                              setIsRestartContainerGroupInstancePending({
                                instanceId,
                                isPending: false,
                              }),
                            ),
                            of(
                              showToastNotification(
                                getUnableToExecuteActionToInstanceLimitExceededErrorContent(
                                  intl,
                                  InstanceActionType.Reallocate,
                                ),
                              ),
                            ),
                          )
                        case ReallocateContainerGroupInstanceProblemType.OrganizationQuotasNotDefined:
                          return concat(
                            of(
                              setIsRecreateContainerGroupInstancePending({
                                instanceId,
                                isPending: false,
                              }),
                            ),
                            of(showToastNotification(getOrganizationQuotasNotDefinedErrorContent(intl))),
                          )
                        case ReallocateContainerGroupInstanceProblemType.ContainerGroupInstanceNotFound:
                          return concat(
                            of(
                              setIsRecreateContainerGroupInstancePending({
                                instanceId,
                                isPending: false,
                              }),
                              getContainerGroupAndInstances({
                                containerGroupName,
                                organizationName,
                                projectName,
                              }),
                            ),
                            of(showToastNotification(getContainerGroupInstanceNotFoundErrorContent(intl))),
                            of(
                              navigateTo({
                                path: getContainerGroupDetailsPagePath(
                                  organizationName,
                                  projectName,
                                  containerGroupName,
                                ),
                              }),
                            ),
                          )
                        case ReallocateContainerGroupInstanceProblemType.OrganizationNotFound:
                          return concat(
                            of(
                              setIsRecreateContainerGroupInstancePending({
                                instanceId,
                                isPending: false,
                              }),
                            ),
                            of(showToastNotification(getOrganizationNotFoundErrorContent(intl))),
                            of(
                              navigateTo({
                                path: getOrganizationsPagePath(),
                              }),
                            ),
                          )
                        case ReallocateContainerGroupInstanceProblemType.ContainerGroupNotFound:
                          return concat(
                            of(
                              setIsRecreateContainerGroupInstancePending({
                                instanceId,
                                isPending: false,
                              }),
                            ),
                            of(showToastNotification(getContainerGroupNotFoundErrorContent(intl))),
                            of(
                              navigateTo({
                                path: getContainerGroupsPagePath(organizationName, projectName),
                              }),
                            ),
                          )
                        case ReallocateContainerGroupInstanceProblemType.UnexpectedError: {
                          return concat(
                            of(
                              setIsReallocateContainerGroupInstancePending({
                                instanceId,
                                isPending: false,
                              }),
                            ),
                            of(
                              showToastNotification(
                                getUnableToExecuteActionToContainerGroupInstanceGeneralErrorContent(
                                  intl,
                                  InstanceActionType.Reallocate,
                                ),
                              ),
                            ),
                          )
                        }

                        default:
                          assertUnreachable(errorType)
                      }
                    } else {
                      return concat(
                        of(
                          setIsReallocateContainerGroupInstancePending({
                            instanceId,
                            isPending: false,
                          }),
                        ),
                        of(
                          showToastNotification(
                            getUnableToExecuteActionToContainerGroupInstanceGeneralErrorContent(
                              intl,
                              InstanceActionType.Reallocate,
                            ),
                          ),
                        ),
                      )
                    }
                  }),
                )
              : concat(
                  of(
                    setIsReallocateContainerGroupInstancePending({
                      instanceId,
                      isPending: false,
                    }),
                  ),
                  of(
                    showToastNotification(
                      getUnableToExecuteActionToContainerGroupInstanceGeneralErrorContent(
                        intl,
                        InstanceActionType.Reallocate,
                      ),
                    ),
                  ),
                ),
          ),
        ),
      ),
    ),
  )

export const onRecreateContainerGroupInstance: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(recreateContainerGroupInstance.match),
    mergeMap(({ payload: { containerGroupName, instanceId, organizationName, projectName, redirectPath } }) =>
      concat(
        of(
          setIsRecreateContainerGroupInstancePending({
            instanceId,
            isPending: true,
          }),
        ),
        from(
          ContainerGroupsAPI.containerGroupInstanceRecreate({
            containerGroupName,
            containerGroupInstanceId: instanceId,
            organizationName,
            projectName,
          }),
        ).pipe(
          mergeMap(() => {
            return redirectPath
              ? concat(
                  of(
                    setIsRecreateContainerGroupInstancePending({
                      instanceId,
                      isPending: false,
                    }),
                  ),
                  of(showToastNotification(getSuccessfullyRecreatedDeploymentInstanceContent(intl))),
                  of(navigateTo({ path: redirectPath })),
                )
              : concat(
                  of(
                    setIsRecreateContainerGroupInstancePending({
                      instanceId,
                      isPending: false,
                    }),
                  ),
                  of(
                    getContainerGroupAndInstances({
                      containerGroupName,
                      organizationName,
                      projectName,
                    }),
                  ),
                  of(showToastNotification(getSuccessfullyRecreatedDeploymentInstanceContent(intl))),
                )
          }),
          catchError((error: unknown) =>
            error instanceof ResponseError && error.response.status !== 500
              ? from(error.response.json()).pipe(
                  mergeMap((errorResponse) => {
                    const errorType = errorResponse.type as RecreateContainerGroupInstanceProblemType | null

                    if (errorType != null && errorType !== RecreateContainerGroupInstanceProblemType.Null) {
                      switch (errorType) {
                        case RecreateContainerGroupInstanceProblemType.NodeNotScheduled:
                          return concat(
                            of(
                              setIsRecreateContainerGroupInstancePending({
                                instanceId,
                                isPending: false,
                              }),
                              getContainerGroupAndInstances({
                                containerGroupName,
                                organizationName,
                                projectName,
                              }),
                            ),
                            of(
                              showToastNotification(
                                getUnableToExecuteActionToContainerGroupInstanceNodeNotExistErrorContent(
                                  intl,
                                  InstanceActionType.Recreate,
                                ),
                              ),
                            ),
                          )
                        case RecreateContainerGroupInstanceProblemType.TooManyRequests:
                          return concat(
                            of(
                              setIsRestartContainerGroupInstancePending({
                                instanceId,
                                isPending: false,
                              }),
                            ),
                            of(
                              showToastNotification(
                                getUnableToExecuteActionToInstanceLimitExceededErrorContent(
                                  intl,
                                  InstanceActionType.Recreate,
                                ),
                              ),
                            ),
                          )
                        case RecreateContainerGroupInstanceProblemType.OrganizationQuotasNotDefined:
                          return concat(
                            of(
                              setIsRecreateContainerGroupInstancePending({
                                instanceId,
                                isPending: false,
                              }),
                            ),
                            of(showToastNotification(getOrganizationQuotasNotDefinedErrorContent(intl))),
                          )
                        case RecreateContainerGroupInstanceProblemType.ContainerGroupInstanceNotFound:
                          return concat(
                            of(
                              setIsRecreateContainerGroupInstancePending({
                                instanceId,
                                isPending: false,
                              }),
                              getContainerGroupAndInstances({
                                containerGroupName,
                                organizationName,
                                projectName,
                              }),
                            ),
                            of(showToastNotification(getContainerGroupInstanceNotFoundErrorContent(intl))),
                            of(
                              navigateTo({
                                path: getContainerGroupDetailsPagePath(
                                  organizationName,
                                  projectName,
                                  containerGroupName,
                                ),
                              }),
                            ),
                          )
                        case RecreateContainerGroupInstanceProblemType.OrganizationNotFound:
                          return concat(
                            of(
                              setIsRecreateContainerGroupInstancePending({
                                instanceId,
                                isPending: false,
                              }),
                            ),
                            of(showToastNotification(getOrganizationNotFoundErrorContent(intl))),
                            of(
                              navigateTo({
                                path: getOrganizationsPagePath(),
                              }),
                            ),
                          )
                        case RecreateContainerGroupInstanceProblemType.ContainerGroupNotFound:
                          return concat(
                            of(
                              setIsRecreateContainerGroupInstancePending({
                                instanceId,
                                isPending: false,
                              }),
                            ),
                            of(showToastNotification(getContainerGroupNotFoundErrorContent(intl))),
                            of(
                              navigateTo({
                                path: getContainerGroupsPagePath(organizationName, projectName),
                              }),
                            ),
                          )
                        case RecreateContainerGroupInstanceProblemType.UnexpectedError:
                          return concat(
                            of(
                              setIsRecreateContainerGroupInstancePending({
                                instanceId,
                                isPending: false,
                              }),
                            ),
                            of(
                              showToastNotification(
                                getUnableToExecuteActionToContainerGroupInstanceGeneralErrorContent(
                                  intl,
                                  InstanceActionType.Recreate,
                                ),
                              ),
                            ),
                          )

                        default:
                          assertUnreachable(errorType)
                      }
                    } else {
                      return concat(
                        of(
                          setIsRecreateContainerGroupInstancePending({
                            instanceId,
                            isPending: false,
                          }),
                        ),
                        of(
                          showToastNotification(
                            getUnableToExecuteActionToContainerGroupInstanceGeneralErrorContent(
                              intl,
                              InstanceActionType.Recreate,
                            ),
                          ),
                        ),
                      )
                    }
                  }),
                )
              : concat(
                  of(
                    setIsRecreateContainerGroupInstancePending({
                      instanceId,
                      isPending: false,
                    }),
                  ),
                  of(
                    showToastNotification(
                      getUnableToExecuteActionToContainerGroupInstanceGeneralErrorContent(
                        intl,
                        InstanceActionType.Recreate,
                      ),
                    ),
                  ),
                ),
          ),
        ),
      ),
    ),
  )

export const onRestartContainerGroupInstance: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(restartContainerGroupInstance.match),
    mergeMap(({ payload: { containerGroupName, instanceId, organizationName, projectName, redirectPath } }) =>
      concat(
        of(
          setIsRestartContainerGroupInstancePending({
            instanceId,
            isPending: true,
          }),
        ),
        from(
          ContainerGroupsAPI.containerGroupInstanceRestart({
            containerGroupName,
            organizationName,
            projectName,
            containerGroupInstanceId: instanceId,
          }),
        ).pipe(
          mergeMap(() => {
            return redirectPath
              ? concat(
                  of(
                    setIsRestartContainerGroupInstancePending({
                      instanceId: instanceId,
                      isPending: false,
                    }),
                  ),
                  of(showToastNotification(getSuccessfullyRestartedDeploymentInstanceContent(intl))),
                  of(navigateTo({ path: redirectPath })),
                )
              : concat(
                  of(
                    getContainerGroupAndInstances({
                      containerGroupName,
                      organizationName,
                      projectName,
                    }),
                    setIsRestartContainerGroupInstancePending({
                      instanceId: instanceId,
                      isPending: false,
                    }),
                  ),
                  of(showToastNotification(getSuccessfullyRestartedDeploymentInstanceContent(intl))),
                )
          }),
          catchError((error: unknown) =>
            error instanceof ResponseError && error.response.status !== 500
              ? from(error.response.json()).pipe(
                  mergeMap((errorResponse) => {
                    const errorType = errorResponse.type as RestartContainerGroupInstanceProblemType | null

                    if (errorType != null && errorType !== RestartContainerGroupInstanceProblemType.Null) {
                      switch (errorType) {
                        case RestartContainerGroupInstanceProblemType.NodeNotScheduled:
                          return concat(
                            of(
                              setIsRestartContainerGroupInstancePending({
                                instanceId: instanceId,
                                isPending: false,
                              }),
                              getContainerGroupAndInstances({
                                containerGroupName,
                                organizationName,
                                projectName,
                              }),
                            ),
                            of(
                              showToastNotification(
                                getUnableToExecuteActionToContainerGroupInstanceNodeNotExistErrorContent(
                                  intl,
                                  InstanceActionType.Restart,
                                ),
                              ),
                            ),
                          )
                        case RestartContainerGroupInstanceProblemType.TooManyRequests:
                          return concat(
                            of(
                              setIsRestartContainerGroupInstancePending({
                                instanceId,
                                isPending: false,
                              }),
                            ),
                            of(
                              showToastNotification(
                                getUnableToExecuteActionToInstanceLimitExceededErrorContent(
                                  intl,
                                  InstanceActionType.Restart,
                                ),
                              ),
                            ),
                          )
                        case RestartContainerGroupInstanceProblemType.OrganizationQuotasNotDefined:
                          return concat(
                            of(
                              setIsRecreateContainerGroupInstancePending({
                                instanceId,
                                isPending: false,
                              }),
                            ),
                            of(showToastNotification(getOrganizationQuotasNotDefinedErrorContent(intl))),
                          )
                        case RestartContainerGroupInstanceProblemType.ContainerGroupInstanceNotFound:
                          return concat(
                            of(
                              setIsRecreateContainerGroupInstancePending({
                                instanceId,
                                isPending: false,
                              }),
                              getContainerGroupAndInstances({
                                containerGroupName,
                                organizationName,
                                projectName,
                              }),
                            ),
                            of(showToastNotification(getContainerGroupInstanceNotFoundErrorContent(intl))),
                            of(
                              navigateTo({
                                path: getContainerGroupDetailsPagePath(
                                  organizationName,
                                  projectName,
                                  containerGroupName,
                                ),
                              }),
                            ),
                          )
                        case RestartContainerGroupInstanceProblemType.OrganizationNotFound:
                          return concat(
                            of(
                              setIsRecreateContainerGroupInstancePending({
                                instanceId,
                                isPending: false,
                              }),
                            ),
                            of(showToastNotification(getOrganizationNotFoundErrorContent(intl))),
                            of(
                              navigateTo({
                                path: getOrganizationsPagePath(),
                              }),
                            ),
                          )
                        case RestartContainerGroupInstanceProblemType.ContainerGroupNotFound:
                          return concat(
                            of(
                              setIsRecreateContainerGroupInstancePending({
                                instanceId,
                                isPending: false,
                              }),
                            ),
                            of(showToastNotification(getContainerGroupNotFoundErrorContent(intl))),
                            of(
                              navigateTo({
                                path: getContainerGroupsPagePath(organizationName, projectName),
                              }),
                            ),
                          )
                        case RestartContainerGroupInstanceProblemType.UnexpectedError:
                          return concat(
                            of(
                              setIsRestartContainerGroupInstancePending({
                                instanceId,
                                isPending: false,
                              }),
                            ),
                            of(
                              showToastNotification(
                                getUnableToExecuteActionToContainerGroupInstanceGeneralErrorContent(
                                  intl,
                                  InstanceActionType.Restart,
                                ),
                              ),
                            ),
                          )

                        default:
                          assertUnreachable(errorType)
                      }
                    } else {
                      return concat(
                        of(
                          setIsRestartContainerGroupInstancePending({
                            instanceId,
                            isPending: false,
                          }),
                        ),
                        of(
                          showToastNotification(
                            getUnableToExecuteActionToContainerGroupInstanceGeneralErrorContent(
                              intl,
                              InstanceActionType.Restart,
                            ),
                          ),
                        ),
                      )
                    }
                  }),
                )
              : concat(
                  of(
                    setIsRestartContainerGroupInstancePending({
                      instanceId,
                      isPending: false,
                    }),
                  ),
                  of(
                    showToastNotification(
                      getUnableToExecuteActionToContainerGroupInstanceGeneralErrorContent(
                        intl,
                        InstanceActionType.Restart,
                      ),
                    ),
                  ),
                ),
          ),
        ),
      ),
    ),
  )
