import { defineMessages } from 'react-intl'

export const ContainerGroupsImageSourceMessages = defineMessages({
  public: {
    defaultMessage: 'Public',
    id: 'RxlGuBOv',
    description: 'The label for the public pill.',
  },
  private: {
    defaultMessage: 'Private',
    id: 'Jl4Pltf3',
    description: 'The label for the private pill.',
  },
  imageSourceLabel: {
    defaultMessage: 'Image Source',
    id: 'tjIblW04',
    description: 'The label for the image source.',
  },
})
