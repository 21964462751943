import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import type { ObjectSchema } from 'yup'
import { number, object, string } from 'yup'
import { EditRecipeDeploymentFormMessages } from '../../messages'
import type { EditRecipeDeploymentValues } from './EditRecipeDeploymentForm'

export const useEditRecipeDeploymentFormValidation = (
  maxReplicas: number,
): ObjectSchema<EditRecipeDeploymentValues> => {
  const intl = useIntl()
  const validationScheme = useMemo(
    () =>
      object({
        displayName: string()
          .min(1, intl.formatMessage(EditRecipeDeploymentFormMessages.displayNameInvalid))
          .min(2, intl.formatMessage(EditRecipeDeploymentFormMessages.displayNameMinMaxErrorMessage))
          .max(63, intl.formatMessage(EditRecipeDeploymentFormMessages.displayNameMinMaxErrorMessage)),
        replicaCount: number()
          .min(1, intl.formatMessage(EditRecipeDeploymentFormMessages.replicaCountMinRequired))
          .max(
            maxReplicas,
            intl.formatMessage(EditRecipeDeploymentFormMessages.replicaCountMaxExceeded, { max_replicas: maxReplicas }),
          ),
      }),
    [intl, maxReplicas],
  )
  return validationScheme
}
