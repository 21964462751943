import { defineMessages } from 'react-intl'

export const CreateJobQueueGeneralErrorContentMessages = defineMessages({
  title: {
    defaultMessage: 'Error creating job queue',
    id: 'swg0IZxk',
    description:
      'The toast notification title shown when we encounter an error on their request to create a job queue.',
  },
  body: {
    defaultMessage: 'We were unable to create your job queue. Try again and if this issue persists {contact_support}',
    id: 'UikmNoQk',
    description:
      'The description shown in a toast notification message shown when we encounter an error on their request to create a job queue.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'NrJtHpRF',
    description: 'The text shown for the contact support link in the create job queue error toast notification.',
  },
})
