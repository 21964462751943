import type { RamOption, StorageOption } from '@saladtechnologies/openapi-cloud-portal-browser'
import type { FunctionComponent } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Button } from '../../../../components/Button'
import { ButtonLink } from '../../../../components/base/ButtonLink'
import type { ContainerGroupDetailsList } from '../../../../components/containerGroups/models'
import { SearchWithFilters } from '../../../../components/search'
import { ContainerGroupsDeploymentsListMessages, ContainerGroupsPageMainContentMessages } from '../../messages'
import { ContainerGroupsCard } from '../ContainerGroupsCard'
import { filterContainerGroupsDeployments, sortContainerGroupsDeployments } from './utils'

interface ContainerGroupsDeploymentsListProps {
  /** The path for opening container. */
  containerCommonPath: string
  /** The container groups list. */
  containerGroupsList: ContainerGroupDetailsList
  /** The path for the "Deploy a Container Group" button. */
  deployNewContainerGroupPath: string
  /** The callback called when the user clicks the "Learn More" button. */
  onLearnMore: () => void
  /** The list of available ram options to choose from. */
  ramOptions: RamOption[] | undefined
  /** The list of available storage options to choose from. */
  storageOptions: StorageOption[] | undefined
}

export const ContainerGroupsDeploymentsList: FunctionComponent<ContainerGroupsDeploymentsListProps> = ({
  containerCommonPath,
  containerGroupsList,
  deployNewContainerGroupPath,
  onLearnMore,
  ramOptions,
  storageOptions,
}) => {
  const intl = useIntl()

  const [deployedContainerListBasedOnQuery, setDeployedContainerListBasedOnQuery] =
    useState<ContainerGroupDetailsList>(containerGroupsList)
  const [query, setQuery] = useState<string>('')
  const [filter, setFilter] = useState<{ [key: string]: string | undefined }>({
    [intl.formatMessage(ContainerGroupsDeploymentsListMessages.searchFilter)]: undefined,
    [intl.formatMessage(ContainerGroupsDeploymentsListMessages.searchSortBy)]: undefined,
  })

  const filterByLabel = intl.formatMessage(ContainerGroupsDeploymentsListMessages.searchFilter)
  const filterByPreparingOption = intl.formatMessage(ContainerGroupsDeploymentsListMessages.searchFilterByPreparing)
  const filterByStoppedOption = intl.formatMessage(ContainerGroupsDeploymentsListMessages.searchFilterByStopped)
  const filterByDeployingOption = intl.formatMessage(ContainerGroupsDeploymentsListMessages.searchFilterByDeploying)
  const filterByRunningOption = intl.formatMessage(ContainerGroupsDeploymentsListMessages.searchFilterByRunning)
  const filterByFailedOption = intl.formatMessage(ContainerGroupsDeploymentsListMessages.searchFilterByFailed)

  const sortByLabel = intl.formatMessage(ContainerGroupsDeploymentsListMessages.searchSortBy)
  const sortByAToZOption = intl.formatMessage(ContainerGroupsDeploymentsListMessages.searchSortByAToZOption)
  const sortByZToAOption = intl.formatMessage(ContainerGroupsDeploymentsListMessages.searchSortByZToAOption)
  const sortByStatusOption = intl.formatMessage(ContainerGroupsDeploymentsListMessages.searchSortByStatusOption)
  const sortByStatusReversedOption = intl.formatMessage(
    ContainerGroupsDeploymentsListMessages.searchSortByStatusReversedOption,
  )
  const sortByNewestOption = intl.formatMessage(ContainerGroupsDeploymentsListMessages.searchSortByNewestOption)
  const sortByOlderOption = intl.formatMessage(ContainerGroupsDeploymentsListMessages.searchSortByOldestOption)

  const handleSearchFilterSortChange = useCallback(
    (query: string, filter: { [key: string]: string | undefined }) => {
      const selectedFilterByOption = filter[filterByLabel]
      const selectedSortByOption = filter[sortByLabel]

      const deployedContainerGroupsThatMatchQuery = sortContainerGroupsDeployments(
        filterContainerGroupsDeployments(
          containerGroupsList,
          selectedFilterByOption,
          filterByPreparingOption,
          filterByStoppedOption,
          filterByDeployingOption,
          filterByRunningOption,
          filterByFailedOption,
          query,
        ),
        selectedSortByOption,
        sortByAToZOption,
        sortByZToAOption,
        sortByStatusOption,
        sortByStatusReversedOption,
        sortByNewestOption,
        sortByOlderOption,
      )

      setDeployedContainerListBasedOnQuery(deployedContainerGroupsThatMatchQuery)
    },
    [
      containerGroupsList,
      filterByDeployingOption,
      filterByFailedOption,
      filterByLabel,
      filterByPreparingOption,
      filterByRunningOption,
      filterByStoppedOption,
      sortByAToZOption,
      sortByLabel,
      sortByNewestOption,
      sortByOlderOption,
      sortByStatusOption,
      sortByStatusReversedOption,
      sortByZToAOption,
    ],
  )

  useEffect(() => {
    handleSearchFilterSortChange(query, filter)
  }, [containerGroupsList, filter, handleSearchFilterSortChange, query])

  return (
    <div className="flex flex-col justify-center">
      <div className="mb-4">
        <SearchWithFilters
          onChange={({ filter, query }) => {
            setQuery(query)
            setFilter(filter)
            handleSearchFilterSortChange(query, filter)
          }}
          filters={[
            {
              label: filterByLabel,
              options: [
                filterByPreparingOption,
                filterByStoppedOption,
                filterByDeployingOption,
                filterByRunningOption,
                filterByFailedOption,
              ],
            },
            {
              label: sortByLabel,
              options: [
                sortByAToZOption,
                sortByZToAOption,
                sortByStatusOption,
                sortByStatusReversedOption,
                sortByNewestOption,
                sortByOlderOption,
              ],
            },
          ]}
          isFullWidth
          placeholder={intl.formatMessage(ContainerGroupsDeploymentsListMessages.searchInputPlaceholder)}
        />
      </div>
      <div className="mb-9 flex gap-2">
        <ButtonLink
          iconClassName="fa-solid fa-folder-plus"
          role="navigation"
          url={deployNewContainerGroupPath}
          variant="green-filled-light"
        >
          {intl.formatMessage(ContainerGroupsPageMainContentMessages.deployContainerGroupButtonLabel)}
        </ButtonLink>
        <Button
          iconClassName="fa-solid fa-book"
          onClick={onLearnMore}
          role="navigation"
          type="button"
          variant="blue-filled-light"
        >
          {intl.formatMessage(ContainerGroupsPageMainContentMessages.learnMoreButtonLabel)}
        </Button>
      </div>
      <div>
        {deployedContainerListBasedOnQuery.map((containerGroups) => (
          <ContainerGroupsCard
            containerPath={`${containerCommonPath}/${containerGroups.name}`}
            createTime={containerGroups.createTime}
            currentState={containerGroups.currentState}
            displayName={containerGroups.displayName}
            gpuClasses={containerGroups.gpuClasses}
            image={containerGroups.container.image}
            isAuthRequired={containerGroups.networking?.auth}
            key={containerGroups.id}
            ramOptions={ramOptions}
            replicas={containerGroups.replicas}
            resources={containerGroups.container.resources}
            storageOptions={storageOptions}
            version={containerGroups.version}
          />
        ))}
      </div>
    </div>
  )
}
