import { defineMessages } from 'react-intl'

export const MachineHardwareMessages = defineMessages({
  backLinkText: {
    defaultMessage: 'Exit to Container Groups',
    description: 'The text for the back link.',
    id: 'bNLMvQXK',
  },
  machineHardwareTitle: {
    defaultMessage: 'Machine Hardware',
    description: 'The title for the machine hardware configuration page.',
    id: 'mc0wqJR0',
  },
  machineHardwareSubtitle: {
    defaultMessage: 'Configure the hardware that will be assigned to each instance of your container',
    description: 'The subtitle for the machine hardware configuration page.',
    id: 'CHvIoIwa',
  },
  nextStepButtonLabel: {
    defaultMessage: 'Next Step',
    description: 'The label for the next step button.',
    id: 'RWHgMdVu',
  },
  previousButtonLabel: {
    defaultMessage: 'Previous Step',
    description: 'The text for the back link.',
    id: 'XhE96M+U',
  },
})
