import { defineMessages } from 'react-intl'

export const DeleteAccountAsLastMemberOfOrganizationErrorMessages = defineMessages({
  body: {
    defaultMessage:
      'Before deleting your account, you must first delete any organizations for which you are the only member. If this issue persists, please {contact_support}.',
    id: '96nBXTl9',
    description:
      "The body of the error toast notification shown when the user tries to delete their account but they're the last member of an organization.",
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: '4cwxHzJV',
    description:
      'The text shown for the contact support link in the error toast notification shown when the user is trying to delete their account while being the last member of the organization.',
  },
  title: {
    defaultMessage: 'Unable to Delete Account',
    id: 'ufmHAWiG',
    description:
      "The body of the error toast notification shown when the user tries to delete their account but they're the last member of an organization.",
  },
})
