import { defineMessages } from 'react-intl'

export const SuccessfullyDeclinedTeamInvitationContentMessage = defineMessages({
  body: {
    defaultMessage: 'You have successfully declined the invitation to join {organization_display_name}',
    id: 'BkbNXdD1',
    description: 'The body for the declined team member invitation notification.',
  },
  title: {
    defaultMessage: 'Invitation Declined',
    id: 'htiu0/MH',
    description: 'The title for the declined team member invitation notification.',
  },
})
