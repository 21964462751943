import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import { DeploymentStatus } from '../../../../features/containerGroupInstanceTable/models'
import { configureAgeLabel } from '../utils'

interface DeploymentInstanceTableAgeRowItemProps {
  /** Function to navigate to the instance details page */
  onNavigateToInstanceDetailsPage: (machineId: string, event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  /** The container group details page path */
  instanceDetailsPagePath?: string
  /** Indicates if the deployment status is 'running' */
  isDeploymentInstanceRunning: (status: DeploymentStatus) => boolean
  /** The unique identifier for the machine, if available */
  machineId?: string
  /** The deployment instance status */
  status: DeploymentStatus
  /** The last update time of the deployment */
  updateTime: Date | null
}

export const DeploymentInstanceTableAgeRowItem: FunctionComponent<DeploymentInstanceTableAgeRowItemProps> = ({
  instanceDetailsPagePath,
  isDeploymentInstanceRunning,
  machineId,
  onNavigateToInstanceDetailsPage,
  status,
  updateTime,
}) => {
  return (
    <div
      className={classNames('', {
        'cursor-pointer': status === 'running' && instanceDetailsPagePath,
      })}
      onClick={
        isDeploymentInstanceRunning(status) && instanceDetailsPagePath && machineId
          ? () => onNavigateToInstanceDetailsPage(machineId)
          : undefined
      }
    >
      {configureAgeLabel(updateTime)}
    </div>
  )
}
