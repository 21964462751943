import { defineMessages } from 'react-intl'

export const RemovePaymentMethodErrorMessages = defineMessages({
  body: {
    defaultMessage: 'Failed to remove payment method. Please try again later.',
    id: 'cJcj3Yoh',
    description:
      'The description shown in a toast notification message shown when we encounter an error to remove the payment method.',
  },
  title: {
    defaultMessage: 'Failed to remove payment method',
    id: '7SyaCyxT',
    description: 'The toast notification title shown when we encounter an error to remove the payment method.',
  },
})
