import type { AppState } from '../../store'
import { billingDashboardsAdapter } from './billingDashboardsSlice'

const billingDashboardsSelectors = billingDashboardsAdapter.getSelectors()

export const selectBillingCreditsDashboardEmbedUrl = (state: AppState, organizationName: string) =>
  billingDashboardsSelectors.selectById(state.billingDashboards, organizationName)?.creditsEmbedUrl

export const selectBillingInformationNotReadyErrorNotificationSeen = (state: AppState, organizationName: string) =>
  billingDashboardsSelectors.selectById(state.billingDashboards, organizationName)
    ?.hasSeenInformationNotReadyErrorNotification

export const selectBillingInvoiceDashboardEmbedUrl = (state: AppState, organizationName: string) =>
  billingDashboardsSelectors.selectById(state.billingDashboards, organizationName)?.invoiceEmbedUrl

export const selectBillingUsageDashboardEmbedUrl = (state: AppState, organizationName: string) =>
  billingDashboardsSelectors.selectById(state.billingDashboards, organizationName)?.usageEmbedUrl
