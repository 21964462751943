import { createSelector } from '@reduxjs/toolkit'
import { jobQueueConnectionsTableAdapter } from './jobQueueConnectionsTableSlice'

const jobQueueConnectionsTableSelectors = jobQueueConnectionsTableAdapter.getSelectors()

export const selectJobQueueConnectionsTableItems = createSelector(
  jobQueueConnectionsTableSelectors.selectById,
  (jobQueue) => {
    return jobQueue?.connections ?? []
  },
)
