import type { ReactNode } from 'react'
import type { IntlShape } from 'react-intl'
import type { ToastNotificationType } from '../../models'
import { ContainerGroupInstanceNotFoundErrorMessages } from './messages'

/**
 * Retrieves the content needed for an error `ToastNotification` that is shown when we are unable to find the container
 * group instance
 *
 * @param intl The `IntlShape` object needed to format the messages.
 */
export const getContainerGroupInstanceNotFoundErrorContent = (
  intl: IntlShape,
): {
  body: ReactNode
  type: ToastNotificationType
  title: string
} => {
  return {
    body: intl.formatMessage(ContainerGroupInstanceNotFoundErrorMessages.body),
    type: 'error',
    title: intl.formatMessage(ContainerGroupInstanceNotFoundErrorMessages.title),
  }
}
