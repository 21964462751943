import { defineMessages } from 'react-intl'

export const PageNotFoundMessages = defineMessages({
  pageNotFoundBackgroundImage: {
    defaultMessage: 'Page not found background image',
    id: '15qWT+3D',
    description: 'Page not found background image',
  },
  pageNotFoundImage: {
    defaultMessage: 'Page not found image',
    id: 'zdSOM8lT',
    description: 'Page not found image',
  },
  pageNotFoundTitle: {
    defaultMessage: '404 detected.',
    id: 'WZrjIdux',
    description: 'Page not found title',
  },
  pageNotFoundDescription: {
    defaultMessage: 'Those ingredients seem to be missing...',
    id: 'at5TWmgr',
    description: 'Page not found description',
  },
  goBack: {
    defaultMessage: 'Go Back',
    id: 'KvfqIdM+',
    description: 'Go back button text',
  },
})
