import type { FunctionComponent } from 'react'
import { useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { selectIsLeftColumnShowing } from '../../features/navigationBar/navigationBarSelectors'
import { setLeftColumnShowing } from '../../features/navigationBar/navigationBarSlice'
import { selectOrganizationDisplayName } from '../../features/organizations/organizationsSelectors'
import { getOrganizationWithProjects } from '../../features/projects/projectsSlice'
import { useAppDispatch, useAppSelector } from '../../store'
import { NoProjectsAvailablePage } from './NoProjectsAvailablePage'

export const ConnectedNoProjectsAvailablePage: FunctionComponent = () => {
  const { organizationName = '' } = useParams()
  const dispatch = useAppDispatch()

  const isLeftColumnOpen = useAppSelector(selectIsLeftColumnShowing)
  const organizationDisplayName = useAppSelector((state) => selectOrganizationDisplayName(state, organizationName))

  const handleCloseLeftNavBar = useCallback(() => {
    dispatch(setLeftColumnShowing({ showing: false }))
  }, [dispatch])

  useEffect(() => {
    dispatch(getOrganizationWithProjects({ organizationName }))
  }, [dispatch, organizationName])

  return (
    <NoProjectsAvailablePage
      currentOrganization={{ name: organizationName, displayName: organizationDisplayName ?? organizationName }}
      isLeftColumnOpen={isLeftColumnOpen}
      onCloseLeftDrawer={handleCloseLeftNavBar}
    />
  )
}
