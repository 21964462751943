import type { ReactNode } from 'react'
import type { IntlShape } from 'react-intl'
import { HelpScoutSupportLink } from '../../../components/block/HelpScoutSupportLink'
import type { ToastNotificationType } from '../../models'
import { GetContainerGroupLogsAxiomTimeouErrorContentMessages } from './messages'

/**
 * Retrieves the content needed for an error `ToastNotification` that is shown when the user encounters an axiom timeout
 * error when attempting to get container group logs.
 *
 * @param intl The `IntlShape` object needed to format the messages.
 */
export const GetContainerGroupLogsAxiomTimeouErrorContent = (
  intl: IntlShape,
): {
  body: ReactNode
  type: ToastNotificationType
  title: string
} => {
  return {
    body: intl.formatMessage(GetContainerGroupLogsAxiomTimeouErrorContentMessages.body, {
      contact_support: (
        <HelpScoutSupportLink
          linkedText={intl.formatMessage(GetContainerGroupLogsAxiomTimeouErrorContentMessages.contactSupportLinkText)}
          classNames="text-red-70 underline"
        />
      ),
    }),
    type: 'error',
    title: intl.formatMessage(GetContainerGroupLogsAxiomTimeouErrorContentMessages.title),
  }
}
