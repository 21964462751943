import { defineMessages } from 'react-intl'

export const GeneralErrorLoadingTeamInvitationsAndMembersMessages = defineMessages({
  body: {
    defaultMessage:
      'We were unable to loading your team invitations and members. Try again and if this issue persists {contact_support}.',
    id: '32lH5nIl',
    description: 'The body for the loading invitation and members error notification.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'pIQEUfA+',
    description: 'The text shown for the contact support link.',
  },
  title: {
    defaultMessage: 'Error loading team invitations and members',
    id: '6QCgelSR',
    description: 'The title for the error Team left notification.',
  },
})
