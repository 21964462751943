import { CreateRecipeDeploymentProblemType, ResponseError } from '@saladtechnologies/openapi-cloud-portal-browser'
import { EMPTY, catchError, concat, delay, filter, forkJoin, from, mergeMap, of } from 'rxjs'
import { OrganizationsAPI, ProjectsAPI, QuotasAPI, RecipeDeploymentsAPI, assertUnreachable } from '../apiMethods'
import {
  createRecipeDeployment,
  setCreateRecipeDeploymentRequestStatus,
  setIsRecipeQuotaExceeded,
} from '../features/createRecipeDeployment/createRecipeDeploymentSlice'
import { setIsRecipeInstancesQuotaExceeded } from '../features/editRecipeDeployment/editRecipeDeploymentSlice'
import { showToastNotification } from '../features/notifications/notificationsSlice'
import { organizationAdded } from '../features/organizations/organizationsSlice'
import { paymentMethodRemoved } from '../features/paymentMethod/paymentMethodSlice'
import { projectsAddedToOrganization } from '../features/projects/projectsSlice'
import { quotasAddedToOrganization } from '../features/quotas/quotasSlice'
import { getDeployedRecipesPageData, setDeployedRecipes } from '../features/recipeDeployments/recipeDeploymentsSlice'
import { setRequestStatus } from '../features/requestStatus/requestStatusSlice'
import { RequestStatus } from '../models'
import { getLoadRecipeDeploymentsGeneralErrorContent } from '../notifications/clientToastNotificationContent/recipes/getLoadRecipeDeploymentsGeneralErrorContent'
import { getRecipeDeploymentDetailsPagePath } from '../routes/routes-utils'
import type { AppEpic } from '../store'
import { navigateTo } from './navigationEpic'

export const onGetRecipeDeploymentsPageData: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(getDeployedRecipesPageData.match),
    mergeMap((action) =>
      concat(
        action.payload.firstRequest
          ? of(
              setRequestStatus({ request: 'getRecipeDeploymentsPageData', status: 'pending' }),
              setIsRecipeQuotaExceeded({ exceeded: false }),
            )
          : EMPTY,
        forkJoin([
          RecipeDeploymentsAPI.listRecipeDeployments({
            organizationName: action.payload.organizationName,
            projectName: action.payload.projectName,
          }),
          QuotasAPI.getQuotas({
            organizationName: action.payload.organizationName,
          }),
          OrganizationsAPI.getOrganization({
            organizationName: action.payload.organizationName,
          }),
          ProjectsAPI.listProjects({
            organizationName: action.payload.organizationName,
          }),
        ]).pipe(
          mergeMap(([recipeDeploymentsResponse, quotasResponse, organizationResponse, projectsResponse]) => {
            return concat(
              of(
                setRequestStatus({ request: 'getRecipeDeploymentsPageData', status: 'succeeded' }),
                setDeployedRecipes({ deployedRecipes: recipeDeploymentsResponse.items }),
                organizationAdded(organizationResponse),
                projectsAddedToOrganization({
                  organizationName: action.payload.organizationName,
                  projects: projectsResponse.items,
                }),
                quotasAddedToOrganization({
                  organizationName: action.payload.organizationName,
                  quotas: quotasResponse,
                }),
              ),
              of(setRequestStatus({ request: 'getRecipeDeploymentsPageData', status: 'idle' })).pipe(delay(1)),
            )
          }),
          catchError(() => {
            return concat(
              of(
                showToastNotification(getLoadRecipeDeploymentsGeneralErrorContent(intl)),
                setRequestStatus({ request: 'getRecipeDeploymentsPageData', status: 'failed' }),
              ),
              of(setRequestStatus({ request: 'getRecipeDeploymentsPageData', status: 'idle' })).pipe(delay(1)),
            )
          }),
        ),
      ),
    ),
  )

export const onCreateRecipeDeployment: AppEpic = (action$, _state$) =>
  action$.pipe(
    filter(createRecipeDeployment.match),
    mergeMap((action) =>
      concat(
        of(setCreateRecipeDeploymentRequestStatus({ status: RequestStatus.Loading })),
        from(
          RecipeDeploymentsAPI.createRecipeDeployment({
            createRecipeDeployment: {
              autostartPolicy: action.payload.createRecipeDeployment.autostartPolicy,
              displayName: action.payload.createRecipeDeployment.displayName,
              name: action.payload.createRecipeDeployment.name,
              recipeName: action.payload.createRecipeDeployment.recipeName,
              replicas: action.payload.createRecipeDeployment.replicas,
            },
            organizationName: action.payload.organizationName,
            projectName: action.payload.projectName,
          }),
        ).pipe(
          mergeMap((response) => {
            return concat(
              of(
                setCreateRecipeDeploymentRequestStatus({ status: RequestStatus.Succeeded }),
                navigateTo({
                  path: getRecipeDeploymentDetailsPagePath(
                    action.payload.organizationName,
                    action.payload.projectName,
                    response.name,
                  ),
                }),
              ),
            )
          }),
          catchError((error: unknown) => {
            const genericCreateRecipeDeploymentErrorMessage =
              'We were unable to complete your request to create this recipe deployment at this time. Please try again.'
            return error instanceof ResponseError && error.response.status === 400
              ? from(error.response.json()).pipe(
                  mergeMap((errorResponse) => {
                    const errorType = errorResponse.type as CreateRecipeDeploymentProblemType | null
                    if (errorType != null && errorType !== CreateRecipeDeploymentProblemType.Null) {
                      switch (errorType) {
                        case CreateRecipeDeploymentProblemType.BillingInformationRequired:
                          return concat(
                            of(
                              paymentMethodRemoved(action.payload.organizationName),
                              setCreateRecipeDeploymentRequestStatus({ status: RequestStatus.Failed }),
                            ),
                            of(setCreateRecipeDeploymentRequestStatus({ status: RequestStatus.Idle })).pipe(delay(1)),
                          )
                        case CreateRecipeDeploymentProblemType.CreatedQuotaExceeded:
                          return concat(
                            of(
                              setIsRecipeQuotaExceeded({ exceeded: true }),
                              setCreateRecipeDeploymentRequestStatus({ status: RequestStatus.Failed }),
                            ),
                            of(setCreateRecipeDeploymentRequestStatus({ status: RequestStatus.Idle })).pipe(delay(1)),
                          )
                        case CreateRecipeDeploymentProblemType.NameConflict:
                          return concat(
                            of(
                              showToastNotification({
                                body: 'Please create a recipe deployment with a unique name.',
                                title: 'Name must be unique',
                                type: 'error',
                              }),
                              setCreateRecipeDeploymentRequestStatus({ status: RequestStatus.Failed }),
                            ),
                            of(setCreateRecipeDeploymentRequestStatus({ status: RequestStatus.Idle })).pipe(delay(1)),
                          )
                        case CreateRecipeDeploymentProblemType.OrganizationNotFound:
                          return concat(
                            of(
                              showToastNotification({
                                body: 'The organization you are trying to create this recipe deployment for can not be found.',
                                title: 'Organization not found',
                                type: 'error',
                              }),
                              navigateTo({ path: '/organizations' }),
                              setCreateRecipeDeploymentRequestStatus({ status: RequestStatus.Failed }),
                            ),
                            of(setCreateRecipeDeploymentRequestStatus({ status: RequestStatus.Idle })).pipe(delay(1)),
                          )
                        case CreateRecipeDeploymentProblemType.ProjectNotFound:
                          return concat(
                            of(
                              showToastNotification({
                                body: 'The project you are trying to create this recipe deployment for can not be found.',
                                title: 'Project not found',
                                type: 'error',
                              }),
                              navigateTo({ previousPage: true }),
                              setCreateRecipeDeploymentRequestStatus({ status: RequestStatus.Failed }),
                            ),
                            of(setCreateRecipeDeploymentRequestStatus({ status: RequestStatus.Idle })).pipe(delay(1)),
                          )
                        case CreateRecipeDeploymentProblemType.RecipeNotFound:
                          return concat(
                            of(
                              showToastNotification({
                                body: 'The recipe you are trying to deploy is no longer available. Please return to the recipe marketplace and select another recipe.',
                                title: 'Recipe is not available',
                                type: 'error',
                              }),
                              setCreateRecipeDeploymentRequestStatus({ status: RequestStatus.Failed }),
                            ),
                            of(setCreateRecipeDeploymentRequestStatus({ status: RequestStatus.Idle })).pipe(delay(1)),
                          )
                        case CreateRecipeDeploymentProblemType.UnexpectedError:
                          return concat(
                            of(
                              showToastNotification({
                                body: genericCreateRecipeDeploymentErrorMessage,
                                title: 'Unable to create recipe deployment',
                                type: 'error',
                              }),
                              setCreateRecipeDeploymentRequestStatus({ status: RequestStatus.Failed }),
                            ),
                            of(setCreateRecipeDeploymentRequestStatus({ status: RequestStatus.Idle })).pipe(delay(1)),
                          )
                        case CreateRecipeDeploymentProblemType.CreatedInstanceQuotaExceeded:
                          return concat(
                            of(
                              setIsRecipeInstancesQuotaExceeded({ exceeded: true }),
                              setCreateRecipeDeploymentRequestStatus({ status: RequestStatus.Failed }),
                            ),
                            of(setCreateRecipeDeploymentRequestStatus({ status: RequestStatus.Idle })).pipe(delay(1)),
                          )
                        default:
                          assertUnreachable(errorType)
                      }
                    } else {
                      return concat(
                        of(
                          showToastNotification({
                            body: genericCreateRecipeDeploymentErrorMessage,
                            title: 'Unable to create recipe deployment',
                            type: 'error',
                          }),
                          setCreateRecipeDeploymentRequestStatus({ status: RequestStatus.Failed }),
                        ),
                        of(setCreateRecipeDeploymentRequestStatus({ status: RequestStatus.Idle })).pipe(delay(1)),
                      )
                    }
                  }),
                )
              : concat(
                  of(
                    showToastNotification({
                      body: genericCreateRecipeDeploymentErrorMessage,
                      title: 'Unable to create recipe deployment',
                      type: 'error',
                    }),
                    setCreateRecipeDeploymentRequestStatus({ status: RequestStatus.Failed }),
                  ),
                  of(setCreateRecipeDeploymentRequestStatus({ status: RequestStatus.Idle })).pipe(delay(1)),
                )
          }),
        ),
      ),
    ),
  )
