import { defineMessages } from 'react-intl'

export const ContainerGroupsDeploymentsListMessages = defineMessages({
  searchInputPlaceholder: {
    defaultMessage: 'Find a deployment',
    id: 'iMeLX/wA',
    description: 'The search input placeholder for the Container Groups page.',
  },
  searchFilter: {
    defaultMessage: 'Filter by',
    id: 'uvW7U4zR',
    description: 'The filter label for the search input.',
  },
  searchFilterByFailed: {
    defaultMessage: 'Failed',
    id: 'crO1k7IL',
    description: 'The Failed filter for the search input.',
  },
  searchFilterByDeploying: {
    defaultMessage: 'Deploying',
    id: 'dFBLFy9+',
    description: 'The Deploying filter for the search input.',
  },
  searchFilterByPreparing: {
    defaultMessage: 'Preparing',
    id: 'L/90+FUm',
    description: 'The Preparing filter for the search input.',
  },
  searchFilterByRunning: {
    defaultMessage: 'Running',
    id: 'eDhP7R6r',
    description: 'The Running filter for the search input.',
  },
  searchFilterByStopped: {
    defaultMessage: 'Stopped',
    id: 'Am8hU2Pc',
    description: 'The Stopped filter for the search input.',
  },
  searchPlaceholder: {
    defaultMessage: 'Find a deployment',
    id: 'wdn/UWPB',
    description: 'The placeholder for the search input.',
  },
  searchSortBy: {
    defaultMessage: 'Sort by',
    id: '+KThaCLr',
    description: 'The sort label for the search input.',
  },
  searchSortByAToZOption: {
    defaultMessage: 'A-Z',
    id: 'c/zGbu+T',
    description: 'The A-Z sort option for the search input.',
  },
  searchSortByZToAOption: {
    defaultMessage: 'Z-A',
    id: 'Jvqpe8sY',
    description: 'The Z-A sort option for the search input.',
  },
  searchSortByStatusOption: {
    defaultMessage: 'Status',
    id: 'n1noj2lL',
    description: 'The Status sort option for the search input.',
  },
  searchSortByStatusReversedOption: {
    defaultMessage: 'Status (Reversed)',
    id: 'DxbRA3sW',
    description: 'The Status (Reversed) sort option for the search input.',
  },
  searchSortByNewestOption: {
    defaultMessage: 'Newest First',
    id: 'SQPyfOYd',
    description: 'The Newest sort option for the search input.',
  },
  searchSortByOldestOption: {
    defaultMessage: 'Oldest First',
    id: '+6Fttv/Y',
    description: 'The Oldest sort option for the search input.',
  },
})
