import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { Organization } from '@saladtechnologies/openapi-cloud-portal-browser'

export interface editOrganizationState {
  organizationToEdit: Organization | undefined
}

const initialState: editOrganizationState = {
  organizationToEdit: undefined,
}

export const editOrganizationSlice = createSlice({
  name: 'editOrganization',
  initialState,
  reducers: {
    editOrganizationDisplayName(
      _state,
      _action: PayloadAction<{ organizationName: string; newOrganizationDisplayName: string }>,
    ) {},
    setOrganizationToEdit(state, action: PayloadAction<{ organizationToEdit: Organization | undefined }>) {
      state.organizationToEdit = action.payload.organizationToEdit
    },
  },
})

export const { editOrganizationDisplayName, setOrganizationToEdit } = editOrganizationSlice.actions
