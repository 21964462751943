import { defineMessages } from 'react-intl'

export const SuccessfullyDeletedRecipeDeploymentContentMessages = defineMessages({
  body: {
    defaultMessage: '{recipe_deployment_name} will no longer be accessible',
    id: '1cBh4pfI',
    description:
      'The description shown in a toast notification message shown when the user successfully deletes a recipe deployment.',
  },
  title: {
    defaultMessage: 'The recipe deployment {recipe_deployment_name} has been deleted',
    id: 'LtPtZWPf',
    description: 'The toast notification title shown when the user successfully deletes a recipe deployment.',
  },
})
