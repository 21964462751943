import classNames from 'classnames'
import type { FunctionComponent, SyntheticEvent } from 'react'
import { NavLink } from 'react-router-dom'
import { FeaturePreviewPill } from '../../featurePreview/FeaturePreviewPill'

type MenuItemVariant = 'left-nav' | 'top-nav'

export interface MenuItemProps {
  /**
   * The external link that user is navigated to in a separate window when they click on the menu item. Either this, the
   * `path`, or the `onClick` prop needs to be added for the menu item to be visible.
   */
  externalLink?: string
  /** A Font Awesome icon class name (e.g. `fa-envelope`). */
  iconClassName?: string
  /** A flag indication if danger (red) styles should be applied to the menu item. */
  isDanger?: boolean
  /**
   * A flag indication if the menu item is disabled. We should not be disabling links. This prop should only be applied
   * when the menu item is a button.
   */
  isDisabled?: boolean
  /** The flag indicating that the menu item is part of a feature preview. Which will display a pill alongside the text. */
  isFeaturePreview?: boolean
  /** The label for the menu item. */
  label: string
  /**
   * The callback executed when the menu item is clicked. Either this, the `externalLink`, or the `oath` prop needs to
   * be added for the menu item to be visible.
   */
  onClick?: (event?: SyntheticEvent) => void
  /**
   * The path that user is navigated to when they click on the menu item. Either this, the `externalLink`, or the
   * `onClick` prop needs to be added for the menu item to be visible.
   */
  path?: string
  /** The value indicating the variant of the menu item. */
  variant: MenuItemVariant
}

export const MenuItem: FunctionComponent<MenuItemProps> = ({
  externalLink,
  iconClassName,
  isDanger,
  isDisabled,
  isFeaturePreview,
  label,
  onClick,
  path,
  variant,
}) => {
  const MenuItemVariantStyles: Record<MenuItemVariant, string> = {
    'left-nav': classNames('hover:bg-green-10 py-3 px-6 gap-4', {
      'text-neutral-40 hover:bg-transparent active:text-neutral-40': isDisabled,
      'text-red-60': isDanger && !isDisabled,
      'text-green-80': !isDanger && !isDisabled,
      'text-neutral-80 hover:text-neutral-80': !isDanger && !isDisabled,
    }),
    'top-nav': classNames('focus:text-green-60 active:text-green-60 gap-2', {
      'text-neutral-40 hover:bg-transparent active:text-neutral-40': isDisabled,
      'text-red-40': isDanger && !isDisabled,
      'text-green-10 hover:text-green-20': !isDanger,
    }),
  }

  return (
    <>
      {externalLink !== undefined && (
        <a
          className={classNames('flex w-full items-center text-start', MenuItemVariantStyles[variant])}
          href={externalLink}
          target="_blank"
          rel="noreferrer"
          onClick={onClick}
        >
          {iconClassName && <i className={classNames(iconClassName, 'fa-fw')} />}
          <span>{label}</span>
        </a>
      )}
      {onClick !== undefined && !externalLink && (
        <button
          type="button"
          className={classNames('flex w-full items-center text-start', MenuItemVariantStyles[variant])}
          onClick={onClick}
          disabled={isDisabled}
        >
          {iconClassName && <i className={classNames(iconClassName, 'fa-fw')} />}
          <span>{label}</span>
        </button>
      )}
      {path !== undefined && (
        <NavLink
          className={({ isActive }) =>
            classNames(
              'flex w-full items-center text-start',
              MenuItemVariantStyles[variant],
              isActive ? '!text-green-80' : 'text-neutral-80',
            )
          }
          to={path}
        >
          {iconClassName && <i className={classNames(iconClassName, 'fa-fw')} />}
          <span>{label}</span>
          {isFeaturePreview && <FeaturePreviewPill />}
        </NavLink>
      )}
    </>
  )
}

MenuItem.defaultProps = {
  externalLink: undefined,
  iconClassName: undefined,
  isDanger: false,
  isDisabled: false,
  onClick: undefined,
  path: undefined,
  variant: 'left-nav',
}
