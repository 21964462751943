import classNames from 'classnames'
import type { FunctionComponent, ReactNode } from 'react'
import { useIntl } from 'react-intl'
import type { DeploymentStatus } from '../../../../features/containerGroupInstanceTable/models'
import { Tag } from '../../../Tag'
import { ProgressPill } from '../../../block/ProgressPill'
import { DeploymentInstanceStatusMessages } from '../../messages'
import { InstanceStatusesExplainerModalMessages } from '../messages'

interface InstanceStatusDefinitionProps {
  /** The definition */
  definition: ReactNode
  /** The deployment status */
  deploymentStatus: DeploymentStatus
  /** The flag indicating if the instance has started */
  hasStarted?: boolean
}

export const InstanceStatusDefinition: FunctionComponent<InstanceStatusDefinitionProps> = ({
  definition,
  deploymentStatus,
  hasStarted,
}) => {
  const intl = useIntl()

  return (
    <div>
      {deploymentStatus === 'allocating' && (
        <div className="flex flex-wrap gap-1 sm:gap-0">
          <div className="flex gap-1">
            <Tag color="green">
              <i className={classNames('mr-2 animate-spin', 'fa-solid fa-spinner')} />
              <span className="uppercase">{deploymentStatus}</span>
            </Tag>
            <ProgressPill icon="" label="" />
            <ProgressPill icon="" label="" />
            <ProgressPill icon="" label="" />
            <ProgressPill icon="" label="" />
          </div>

          <p className="mx-6 text-neutral-40">
            {intl.formatMessage(InstanceStatusesExplainerModalMessages.orDividerText)}
          </p>

          <div className="flex gap-1">
            <Tag color="yellow">
              <i className={classNames('mr-2 animate-spin', 'fa-solid fa-spinner')} />
              <span className="uppercase">{deploymentStatus}</span>
            </Tag>
            <ProgressPill icon="" label="" />
            <ProgressPill icon="" label="" />
            <ProgressPill icon="" label="" />
            <ProgressPill icon="" label="" />
          </div>
        </div>
      )}

      {deploymentStatus === 'downloading' && (
        <div className="flex gap-1">
          <ProgressPill icon="" label="" percentage={100} />
          <ProgressPill
            label={deploymentStatus}
            icon={<i className={classNames('fa-solid fa-download')} />}
            percentage={24}
            width={170}
          />
          <ProgressPill icon="" label="" />
          <ProgressPill icon="" label="" />
          <ProgressPill icon="" label="" />
        </div>
      )}

      {deploymentStatus === 'creating' && (
        <div className="flex gap-1">
          <ProgressPill icon="" label="" percentage={100} />
          <ProgressPill icon="" label="" percentage={100} />
          <ProgressPill
            label={deploymentStatus}
            icon={<i className={classNames('fa-solid fa-clock')} />}
            percentage={32}
            width={130}
          />
          <ProgressPill icon="" label="" />
          <ProgressPill icon="" label="" />
        </div>
      )}

      {deploymentStatus === 'running' && !hasStarted && (
        <div className="flex gap-1">
          <ProgressPill icon="" label="" percentage={100} />
          <ProgressPill icon="" label="" percentage={100} />
          <ProgressPill icon="" label="" percentage={100} />
          <Tag color="darkGreen">
            <i className={classNames('mr-2', 'fa-solid fa-play')} />
            <span className="uppercase">{intl.formatMessage(DeploymentInstanceStatusMessages.starting)}</span>
          </Tag>
          <ProgressPill icon="" label="" />
        </div>
      )}

      {deploymentStatus === 'running' && hasStarted && (
        <div className="flex gap-1">
          <ProgressPill icon="" label="" percentage={100} />
          <ProgressPill icon="" label="" percentage={100} />
          <ProgressPill icon="" label="" percentage={100} />
          <ProgressPill icon="" label="" percentage={100} />
          <Tag color="darkGreen">
            <i className={classNames('mr-2', 'fa-solid fa-play')} />
            <span className="uppercase">{deploymentStatus}</span>
          </Tag>
        </div>
      )}

      {deploymentStatus === 'stopping' && (
        <Tag color="red">
          <i className={classNames('mr-2', 'fa-solid fa-stop')} />
          <span className="uppercase">{deploymentStatus}</span>
        </Tag>
      )}

      <p className="mt-2 text-xs">{definition}</p>
    </div>
  )
}
