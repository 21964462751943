import { defineMessages } from 'react-intl'

export const SuccessfullyRevokedTeamMemberInvitationContentMessages = defineMessages({
  body: {
    defaultMessage: '{user_email} can no longer use the invite code you originally sent them',
    id: 'La4IrMYG',
    description: 'The body for the Team Member Invite Revoked notification.',
  },
  title: {
    defaultMessage: 'Invite Revoked',
    id: '0mJUBgWF',
    description: 'The title for the Team Member Invite Revoked notification.',
  },
})
