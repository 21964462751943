import classNames from 'classnames'
import type { FunctionComponent, ReactElement } from 'react'
import { useIntl } from 'react-intl'
import { SvgIcon } from '../../../../components/base/SvgIcon'
import { CreateContainerGroupMainContentMessages } from '../../messages'

interface HardwareResourceCardContentProps {
  /** The cost per hour for the hardware resource. */
  costPerHour: number | undefined
  /** The icon that represents the hardware resource. */
  Icon: ReactElement
  /** The flag indicating whether or not the hardware resource is in high demand. */
  isHighDemand?: boolean
  /** The label for the hardware resource. */
  label: string
}

export const HardwareResourceCardContent: FunctionComponent<HardwareResourceCardContentProps> = ({
  costPerHour,
  Icon,
  isHighDemand,
  label,
}) => {
  const intl = useIntl()
  let formattedCostPerHour = undefined
  if (costPerHour !== undefined) {
    formattedCostPerHour = intl.formatNumber(costPerHour, {
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 3,
      style: 'currency',
    })
  }

  return (
    <div className="flex items-center">
      <SvgIcon size={36}>{Icon}</SvgIcon>
      <div className="ml-3">
        {isHighDemand && (
          <p className={classNames('text-base font-medium text-red-80')}>
            {intl.formatMessage(CreateContainerGroupMainContentMessages.highDemandLabel)}
          </p>
        )}
        <p className="text-base font-medium">{label}</p>
        {formattedCostPerHour && (
          <p className="text-sm text-neutral-70">
            {intl.formatMessage(CreateContainerGroupMainContentMessages.hardwareResourceCostPerHourLabel, {
              cost_per_hour: formattedCostPerHour,
            })}
          </p>
        )}
      </div>
    </div>
  )
}
