import { defineMessages } from 'react-intl'

export const PaymentMethodNotAllowedValidationErrorMessages = defineMessages({
  body: {
    defaultMessage:
      'Your payment method does not allow automatic validation. Please try a different payment method or contact us to discuss alternatives {contact_support}.',
    id: 'mDYuftD7',
    description:
      'The description in a toast notification message shown when we encounter an error validating payment method with not allowed validation.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'wWFGuioE',
    description:
      'The text shown for the contact support link in the not allowed validation payment method error toast notification.',
  },
  title: {
    defaultMessage: 'Could not Validate',
    id: 'SNVHKJW1',
    description:
      'The toast notification title shown when we encounter an error validating payment method with not allowed validation.',
  },
})
