import { createSelector } from '@reduxjs/toolkit'
import { AppState } from '../../store'
import { containerGroupsAdapter } from './containerGroupsSlice'

const containerGroupsSelectors = containerGroupsAdapter.getSelectors()

export const selectContainerGroups = createSelector(containerGroupsSelectors.selectById, (containerGroups) => {
  return containerGroups?.containerGroups
})

export const selectContainerGroup = (state: AppState, entityId: string, name: string) => {
  const containerGroups = state.containerGroups.entities[entityId]?.containerGroups ?? []

  if (containerGroups.length > 0) {
    return containerGroups.find((containerGroup) => containerGroup.name === name)
  }

  return undefined
}
