import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import type { TableHeaders } from '../models/TableHeaders'
import { TableSortingOrder } from '../models/TableSortingOrder'

export interface TableHeaderProps {
  /** List of table headers */
  headers: TableHeaders
  /** Whether the text should be aligned to the left vs center */
  isCentered?: boolean
}

export const TableHeader: FunctionComponent<TableHeaderProps> = ({ headers, isCentered }) => (
  <thead className="sticky top-0 z-20">
    <tr>
      {headers.map(({ columnWidth, label, onClick, sortingOrder }, index) => (
        <th
          key={`${label}-${index}`}
          aria-sort={sortingOrder}
          className={classNames(
            `border-b border-b-neutral-30 bg-neutral-20 p-4 text-left align-middle text-sm text-neutral-100`,
            columnWidth,
            isCentered && 'text-center',
          )}
        >
          <button onClick={onClick} className="w-fit cursor-pointer text-left">
            <span className="pr-1">{label}</span>
            <i
              className={classNames('fa-solid text-neutral-100', {
                'fa-caret-down': sortingOrder === TableSortingOrder.Ascending,
                'fa-caret-up': sortingOrder === TableSortingOrder.Descending,
              })}
            />
          </button>
        </th>
      ))}
    </tr>
  </thead>
)
