import { defineMessages } from 'react-intl'

export const AccountSettingsMainContentMessages = defineMessages({
  deleteAccountButtonLabel: {
    defaultMessage: 'Delete my Account',
    id: 'hER23pjD',
    description: 'The label for the delete account button on the Account Settings page.',
  },
  deleteAccountHeader: {
    defaultMessage: 'Delete Account',
    id: 'APnh3kn1',
    description: 'The header displayed above the delete account button on the Account Settings page.',
  },
  title: {
    defaultMessage: 'Account Settings',
    id: 'h0iYjEpF',
    description: 'The title for the Account Settings page.',
  },
  yourAccountHeader: {
    defaultMessage: 'Your Account',
    id: 'Sb+viUUl',
    description: 'The header displayed above the account card on the Account Settings page.',
  },
})
