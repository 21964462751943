import { defineMessages } from 'react-intl'

export const NameInputMessages = defineMessages({
  helperText: {
    defaultMessage:
      'Must be 2-63 lowercase letters, numbers, or hyphens. Cannot start with a number, or start or end with a hyphen',
    id: 'Fh7eooMe',
    description: 'The helper text for the container group name field.',
  },
  label: {
    defaultMessage: 'Container Group Name',
    id: 'dPYtAG6R',
    description: 'The label for the container group name field.',
  },
})
