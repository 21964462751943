import { defineMessages } from 'react-intl'

export const EditDisplayNameInputMessages = defineMessages({
  label: {
    defaultMessage: 'Display Name',
    id: 'A6EuHQNt',
    description: 'The label for the container group display name field.',
  },
  minMaxErrorMessage: {
    defaultMessage: 'The display name must be between 2-63 characters.',
    id: 'sdrq3+Lo',
    description:
      'The error message for filling out the display name input field when the user has not met the min/max character rules.',
  },
  required: {
    defaultMessage: 'A display name is required',
    id: 'TFCR+DZu',
    description: 'The required error message for the display name field.',
  },
  nameRegexError: {
    defaultMessage:
      'Must be 2-63 lowercase letters, numbers, or hyphens. Cannot start with a number, or start or end with a hyphen',
    id: '5+YxnZR/',
    description: 'The error message for the display name field when the user has not met the regex rules.',
  },
})
