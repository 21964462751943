import type { IntlShape } from 'react-intl'

/**
 * Formats a date to a date and time string.
 *
 * Example: 2023-09-14T11:30:05.2271645Z to 9/14/2023, 7:30 AM
 *
 * @param intl The IntlShape.
 * @param date The date to format.
 */
export const formatToDateTime = (intl: IntlShape, date: Date) =>
  intl.formatDate(date, {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  })
