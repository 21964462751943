import type { FunctionComponent } from 'react'
import { useCallback, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { LoadingPage } from '../../components/page/LoadingPage'
import { trackMixpanelPageViewedEvent } from '../../features/analytics/analyticsSlice'
import { selectIsLeftColumnShowing } from '../../features/navigationBar/navigationBarSelectors'
import { setLeftColumnShowing } from '../../features/navigationBar/navigationBarSlice'
import { selectOrganizationDisplayName } from '../../features/organizations/organizationsSelectors'
import { selectProjectDisplayName, selectProjects } from '../../features/projects/projectsSelectors'
import { deleteProject } from '../../features/projects/projectsSlice'
import { selectRecipes } from '../../features/recipesMarketplace/recipeMarketplaceSelectors'
import { getRecipeMarketplacePageData } from '../../features/recipesMarketplace/recipeMarketplaceSlice'
import {
  selectRequestStatus,
  selectRequestStatusIsFailed,
  selectRequestStatusIsPending,
} from '../../features/requestStatus/requestStatusSelectors'
import { recipesMarketplacePageRoutePath } from '../../routes/routePaths'
import { getRecipeDeploymentsPagePath } from '../../routes/routes-utils'
import { useAppDispatch, useAppSelector } from '../../store'
import { RecipeMarketplacePage } from './RecipeMarketplacePage'

export const ConnectedRecipeMarketplacePage: FunctionComponent = () => {
  const { organizationName = '', projectName = '' } = useParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const isGetRecipesPending = useAppSelector((state) =>
    selectRequestStatusIsPending(state, 'getRecipeMarketplacePageData'),
  )
  const isGetRecipesFailed = useAppSelector((state) =>
    selectRequestStatusIsFailed(state, 'getRecipeMarketplacePageData'),
  )
  const deleteProjectRequestStatus = useAppSelector((state) => selectRequestStatus(state, 'deleteProject'))
  const recipes = useAppSelector(selectRecipes)
  const isLeftColumnOpen = useAppSelector(selectIsLeftColumnShowing)
  const organizationDisplayName = useAppSelector((state) => selectOrganizationDisplayName(state, organizationName))
  const projects = useAppSelector((state) => selectProjects(state, organizationName))?.projects || []
  const currentProjectDisplayName = useAppSelector((state) =>
    selectProjectDisplayName(state, organizationName, projectName),
  )

  const projectSelectOptions = projects.map((project) => {
    return {
      ...project,
      selected: project.name === projectName,
    }
  })

  const handleProjectChange = useCallback(
    (projectName: string) => {
      navigate(getRecipeDeploymentsPagePath(organizationName, projectName))
    },
    [navigate, organizationName],
  )

  const handleDeleteProject = useCallback(
    (projectName: string) => {
      dispatch(deleteProject({ organizationName, projectName }))
    },
    [dispatch, organizationName],
  )

  const handleCloseLeftNavBar = useCallback(() => {
    dispatch(setLeftColumnShowing({ showing: false }))
  }, [dispatch])

  const getRecipesData = useCallback(() => {
    dispatch(getRecipeMarketplacePageData({ organizationName, projectName }))
  }, [dispatch, organizationName, projectName])

  useEffect(() => {
    getRecipesData()
    dispatch(trackMixpanelPageViewedEvent({ path: recipesMarketplacePageRoutePath, organizationName, projectName }))
  }, [dispatch, getRecipesData, organizationName, projectName])

  return isGetRecipesPending ? (
    <LoadingPage />
  ) : (
    <RecipeMarketplacePage
      currentOrganization={{ name: organizationName, displayName: organizationDisplayName ?? organizationName }}
      currentProject={{ name: projectName, displayName: currentProjectDisplayName ?? projectName }}
      isDeleteProjectPending={deleteProjectRequestStatus === 'pending'}
      isDeleteProjectSuccessful={deleteProjectRequestStatus === 'succeeded'}
      isErrorLoadingRecipeMarketplace={isGetRecipesFailed}
      isLeftColumnOpen={isLeftColumnOpen}
      onCloseLeftDrawer={handleCloseLeftNavBar}
      onDeleteProject={handleDeleteProject}
      onProjectChange={handleProjectChange}
      onReloadPage={getRecipesData}
      projects={projectSelectOptions}
      recipeList={recipes}
    />
  )
}
