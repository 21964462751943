import type { FunctionComponent } from 'react'
import ReactMarkdown from 'react-markdown'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { oneLight } from 'react-syntax-highlighter/dist/esm/styles/prism'
import remarkGfm from 'remark-gfm'

export interface MarkdownProps {
  /** The html markdown that is rendered. */
  markdown: string
}

export const Markdown: FunctionComponent<MarkdownProps> = ({ markdown }) => {
  return (
    <ReactMarkdown
      children={markdown}
      components={{
        // eslint-disable-next-line jsx-a11y/anchor-has-content -- anchor will have content
        a: ({ node, ...props }) => <a className="cursor-pointer text-blue-90 underline" {...props} />,
        code({ children, className, inline, lang, node, ...props }) {
          const match = /language-(\w+)/.exec(className || '')
          return !inline && match ? (
            <div className="my-4">
              <SyntaxHighlighter
                children={String(children).replace(/\n$/, '')}
                customStyle={{ borderRadius: 0, margin: 0, padding: 0 }}
                // @ts-ignore - oneLight is not undefined
                style={oneLight}
                language={match[1]}
                PreTag="div"
                {...props}
              />
            </div>
          ) : (
            <code {...props}>{children}</code>
          )
        },
        h1: ({ node, ...props }) => (
          // eslint-disable-next-line jsx-a11y/heading-has-content -- h1 will have content
          <h1 className="mb-3 mt-6 border-b border-b-neutral-50 pb-3 text-2xl font-bold first:mt-0" {...props} />
        ),
        h2: ({ node, ...props }) => (
          // eslint-disable-next-line jsx-a11y/heading-has-content -- h1 will have content
          <h2 className="mb-3 mt-6 border-b border-b-neutral-50 pb-3 text-xl font-bold first:mt-0" {...props} />
        ),
        li: ({ node, ...props }) => <li className="list-item" {...props} />,
        ol: ({ node, ...props }) => <ol className="mb-4 list-decimal pl-8" {...props} />,
        p: ({ node, ...props }) => <p className="mb-4" {...props} />,
        pre: ({ node, ...props }) => <pre {...props} />,
        ul: ({ node, ...props }) => <ul className="mb-4 list-disc pl-8 marker:text-green-70" {...props} />,
      }}
      remarkPlugins={[remarkGfm]}
      skipHtml
    />
  )
}
