import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import type { ObjectSchema } from 'yup'
import { bool, object, ref, string } from 'yup'
import { isValidPassword } from '../../../../utils/formValidation'
import type { CreateAccountValues } from './CreateAccount'
import { createAccountMessages } from './CreateAccountMessages'

export const useCreateAccountFormValidation = (): ObjectSchema<CreateAccountValues> => {
  const intl = useIntl()
  const validationScheme = useMemo(
    () =>
      object({
        email: string()
          .required(intl.formatMessage(createAccountMessages.emailRequired))
          .email(intl.formatMessage(createAccountMessages.emailInvalid)),
        password: string()
          .required(intl.formatMessage(createAccountMessages.passwordRequired))
          .test((value) => isValidPassword(value)),
        verifyPassword: string()
          .required('')
          .oneOf([ref('password')], intl.formatMessage(createAccountMessages.verifyPasswordDoesNotMatch)),
        termsOfServiceAndPrivacyPolicy: bool().required().oneOf([true]),
      }),
    [intl],
  )
  return validationScheme
}
