import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import type { DeploymentStatus } from '../../../../../../features/containerGroupInstanceTable/models'
import { Tag } from '../../../../../Tag'
import { ProgressPill } from '../../../../../block/ProgressPill'
import { DeploymentInstanceStatusMessages } from '../../../../messages'

interface RunningStatusProps {
  /** The deployment status */
  deploymentStatus: DeploymentStatus
  /** The flag indicating if the instance has started */
  hasStarted: boolean
  /** The callback executed when the user clicks the status */
  onClick: () => void
}

export const RunningStatus: FunctionComponent<RunningStatusProps> = ({ deploymentStatus, hasStarted, onClick }) => {
  const intl = useIntl()
  const hasStartedAndRunning = deploymentStatus === 'running' && hasStarted

  return (
    <>
      {hasStartedAndRunning ? (
        <Tag color="darkGreen">
          <i className={classNames('mr-2', 'fa-solid fa-play')} />
          <span className="uppercase">{intl.formatMessage(DeploymentInstanceStatusMessages.running)}</span>
        </Tag>
      ) : (
        <ProgressPill label="" icon="" percentage={undefined} onClick={onClick} />
      )}
    </>
  )
}
