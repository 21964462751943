import type { FieldErrors, FieldValues } from 'react-hook-form'
import { EditContainerGroupField } from '../../models'

export const checkIfIContainerGatewayFieldsHaveErrors = (errors: FieldErrors<FieldValues>): boolean => {
  return errors[EditContainerGroupField.CONTAINER_GATEWAY_PORT] !== undefined
}

export const containerGatewayFieldsList: string[] = [
  EditContainerGroupField.CONTAINER_GATEWAY_ENABLED,
  EditContainerGroupField.CONTAINER_GATEWAY_PORT,
  EditContainerGroupField.CONTAINER_GATEWAY_REQUIRES_AUTHENTICATION,
]
