import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export const deleteAccountSlice = createSlice({
  name: 'deleteAccount',
  initialState: {},
  reducers: {
    deleteAccount(_state, _action: PayloadAction<{ password: string }>) {},
  },
})

export const { deleteAccount } = deleteAccountSlice.actions
