import { defineMessages } from 'react-intl'

export const workloadErrorsDownloadSuccessfullyStartedContentMessages = defineMessages({
  body: {
    defaultMessage: `You have successfully started downloading your workload errors.`,
    id: 'IsPE7N/v',
    description: `The toast notification body shown when we start downloading the user's workload errors.`,
  },
  title: {
    defaultMessage: 'Download started',
    id: 'l0oJmPhM',
    description: "The toast notification title shown when we start downloading the user's workload errors.",
  },
})
