import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { DeploymentStatus } from '../../../../features/containerGroupInstanceTable/models'
import { formatToDateTime } from '../../../../utils'
import { ContainerGroupVersionTag } from '../../../containerGroups/ContainerGroupVersionTag/ContainerGroupVersionTag'

interface DeploymentInstanceTableStatusDetailsRowItemProps {
  /** Function to navigate to the instance details page */
  onNavigateToInstanceDetailsPage: (machineId: string, event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  /** The container group details page path */
  instanceDetailsPagePath?: string
  /** Indicates if the deployment status is 'running' */
  isDeploymentInstanceRunning: (status: DeploymentStatus) => boolean
  /** The unique identifier for the machine, if available */
  machineId?: string
  /** The deployment instance status */
  status: DeploymentStatus
  /** The version number of the deployment */
  version?: number
  /** The last update time of the deployment */
  updateTime: Date | null
}

export const DeploymentInstanceTableStatusDetailsRowItem: FunctionComponent<
  DeploymentInstanceTableStatusDetailsRowItemProps
> = ({
  instanceDetailsPagePath,
  isDeploymentInstanceRunning,
  machineId,
  onNavigateToInstanceDetailsPage,
  status,
  updateTime,
  version,
}) => {
  const intl = useIntl()

  return (
    <div
      className={classNames('', {
        'cursor-pointer': status === 'running' && instanceDetailsPagePath,
      })}
      onClick={
        isDeploymentInstanceRunning(status) && instanceDetailsPagePath && machineId
          ? () => onNavigateToInstanceDetailsPage(machineId)
          : undefined
      }
    >
      {version && <ContainerGroupVersionTag version={version} />}
      {updateTime && formatToDateTime(intl, updateTime)}
    </div>
  )
}
