import { defineMessages } from 'react-intl'

export const DeleteProjectGeneralErrorContentMessages = defineMessages({
  body: {
    defaultMessage: 'We were unable to delete your project. Try again and if this issue persists {contact_support}.',
    id: 'y774w7cM',
    description:
      'The description shown in a toast notification message shown when we encounter an error on their request to delete their project.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'WfXB4smg',
    description: 'The text shown for the contact support link in the delete project error toast notification.',
  },
  title: {
    defaultMessage: 'Error deleting project',
    id: 'wBZSeEUm',
    description:
      'The toast notification title shown when we encounter an error on their request to delete their project.',
  },
})
