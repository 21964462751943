import { ResponseError } from '@saladtechnologies/openapi-cloud-portal-browser'
import { catchError, concat, concatMap, delay, filter, from, mergeMap, of, withLatestFrom } from 'rxjs'
import { OrganizationsAPI, ProjectsAPI } from '../apiMethods'
import { getEditProjectsPageData, setGetEditProjectsDataRequestStatus } from '../features/editProject/editProjectSlice'
import { showToastNotification } from '../features/notifications/notificationsSlice'
import { organizationAdded } from '../features/organizations/organizationsSlice'
import {
  deleteProject,
  getOrganizationWithProjects,
  projectsAddedToOrganization,
} from '../features/projects/projectsSlice'
import { setRequestStatus } from '../features/requestStatus/requestStatusSlice'
import { RequestStatus } from '../models'
import {
  getDeleteProjectGeneralErrorContent,
  getDeleteProjectSucceededContent,
  getEditProjectsDataGeneralErrorContent,
} from '../notifications/clientToastNotificationContent/projects'
import { APP_PATHS } from '../routes/models/AppPaths'
import { getCreateProjectPagePath, getSelectProductPagePath } from '../routes/routes-utils'
import type { AppEpic } from '../store'
import { navigateTo } from './navigationEpic'

export const onDeleteProject: AppEpic = (action$, state$, { intl }) =>
  action$.pipe(
    filter(deleteProject.match),
    mergeMap((action) =>
      concat(
        of(setRequestStatus({ request: 'deleteProject', status: 'pending' })),
        from(
          ProjectsAPI.deleteProject({
            organizationName: action.payload.organizationName,
            projectName: action.payload.projectName,
          }),
        ).pipe(
          withLatestFrom(state$),
          mergeMap(([_action, state]) => {
            const projectsCopy = state.projects.entities[action.payload.organizationName]?.projects || []
            const navigateNextPage = () => {
              if (projectsCopy.length > 0) {
                const projectsWithoutDeletedProject = projectsCopy.filter(
                  (project) => project.name !== action.payload.projectName,
                )

                if (projectsWithoutDeletedProject.length === 0) {
                  return navigateTo({ path: getCreateProjectPagePath(action.payload.organizationName) })
                }

                const lastProjectName = projectsWithoutDeletedProject[projectsWithoutDeletedProject.length - 1]?.name

                if (lastProjectName) {
                  return navigateTo({
                    path: getSelectProductPagePath(action.payload.organizationName, lastProjectName),
                  })
                }
                return navigateTo({ previousPage: true })
              } else {
                return navigateTo({ path: getCreateProjectPagePath(action.payload.organizationName) })
              }
            }
            return concat(
              of(
                setRequestStatus({ request: 'deleteProject', status: 'succeeded' }),
                showToastNotification(getDeleteProjectSucceededContent(intl, action.payload.projectName)),
                navigateNextPage(),
              ),
              of(setRequestStatus({ request: 'deleteProject', status: 'idle' })).pipe(delay(1)),
            )
          }),
          catchError(() =>
            concat(
              of(
                setRequestStatus({ request: 'deleteProject', status: 'failed' }),
                showToastNotification(getDeleteProjectGeneralErrorContent(intl)),
              ),
              of(setRequestStatus({ request: 'deleteProject', status: 'idle' })).pipe(delay(1)),
            ),
          ),
        ),
      ),
    ),
  )

// TODO: This epic is used in multiple pages, where if it fails makes assumptions on where we navigate back to.
// This epic either needs to be refactored to be more dynamic on error scenarios, or just replaces with individual
// epics made for specific page load requests
export const onGetOrganizationWithProjects: AppEpic = (action$, _state$) =>
  action$.pipe(
    filter(getOrganizationWithProjects.match),
    concatMap((action) =>
      concat(
        of(setRequestStatus({ request: 'getOrganizationWithProjects', status: 'pending' })),
        from(
          Promise.all([
            OrganizationsAPI.getOrganization({
              organizationName: action.payload.organizationName,
            }),
            ProjectsAPI.listProjects({
              organizationName: action.payload.organizationName,
            }),
          ]),
        ).pipe(
          mergeMap(([organizationResponse, projectsResponse]) =>
            concat(
              of(
                setRequestStatus({ request: 'getOrganizationWithProjects', status: 'succeeded' }),
                organizationAdded(organizationResponse),
                projectsAddedToOrganization({
                  organizationName: action.payload.organizationName,
                  projects: projectsResponse.items,
                }),
              ),
              of(setRequestStatus({ request: 'getOrganizationWithProjects', status: 'idle' })).pipe(delay(1)),
            ),
          ),
          catchError((error: unknown) => {
            const genericGetProjectsPageDataErrorMessage =
              'We were unable to get your list of projects at this time. Please refresh the page.'
            return error instanceof ResponseError && error.response.status === 404
              ? concat(
                  of(
                    setRequestStatus({ request: 'getOrganizationWithProjects', status: 'failed' }),
                    navigateTo({ path: APP_PATHS.ORGANIZATIONS }),
                  ),
                  of(setRequestStatus({ request: 'getOrganizationWithProjects', status: 'idle' })).pipe(delay(1)),
                )
              : concat(
                  of(
                    setRequestStatus({ request: 'getOrganizationWithProjects', status: 'failed' }),
                    showToastNotification({
                      body: genericGetProjectsPageDataErrorMessage,
                      title: 'Unable to retrieve projects',
                      type: 'error',
                    }),
                  ),
                  of(setRequestStatus({ request: 'getOrganizationWithProjects', status: 'idle' })).pipe(delay(1)),
                )
          }),
        ),
      ),
    ),
  )

export const onGetEditProjectsPageData: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(getEditProjectsPageData.match),
    concatMap((action) =>
      concat(
        of(setGetEditProjectsDataRequestStatus({ status: RequestStatus.Loading })),
        from(
          Promise.all([
            OrganizationsAPI.getOrganization({
              organizationName: action.payload.organizationName,
            }),
            ProjectsAPI.listProjects({
              organizationName: action.payload.organizationName,
            }),
          ]),
        ).pipe(
          mergeMap(([organizationResponse, projectsResponse]) =>
            concat(
              of(
                setGetEditProjectsDataRequestStatus({ status: RequestStatus.Succeeded }),
                organizationAdded(organizationResponse),
                projectsAddedToOrganization({
                  organizationName: action.payload.organizationName,
                  projects: projectsResponse.items,
                }),
              ),
              of(setGetEditProjectsDataRequestStatus({ status: RequestStatus.Idle })).pipe(delay(1)),
            ),
          ),
          catchError((error: unknown) =>
            error instanceof ResponseError && error.response.status === 404
              ? concat(
                  of(
                    setGetEditProjectsDataRequestStatus({ status: RequestStatus.Failed }),
                    navigateTo({ path: APP_PATHS.ORGANIZATIONS }),
                  ),
                  of(setGetEditProjectsDataRequestStatus({ status: RequestStatus.Idle })).pipe(delay(1)),
                )
              : concat(
                  of(
                    setGetEditProjectsDataRequestStatus({ status: RequestStatus.Failed }),
                    showToastNotification(getEditProjectsDataGeneralErrorContent(intl)),
                    navigateTo({ previousPage: true }),
                  ),
                  of(setGetEditProjectsDataRequestStatus({ status: RequestStatus.Idle })).pipe(delay(1)),
                ),
          ),
        ),
      ),
    ),
  )
