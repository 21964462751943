import type { FunctionComponent } from 'react'
import { useState } from 'react'
import type { Control, FieldValues, UseFormClearErrors, UseFormTrigger } from 'react-hook-form'
import { useFormState, useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { CommandDetails } from '../../../../components/containerGroups/CommandDetails'
import { FormStateCard } from '../../../../components/forms/FormStateCard'
import { CommandFieldsMessages } from '../../messages'
import type { CreateContainerGroupFormSectionIdAttributes, CreateContainerGroupValues } from '../../models'
import { CreateContainerGroupField } from '../../models'
import { CommandSidePanelModalModal } from './components'
import { checkIfCommandFieldsHaveErrors } from './utils'

interface CommandFieldsProps {
  /** The react hook form method that clears errors for specified fields. */
  clearErrors: UseFormClearErrors<FieldValues>
  /** The control for the create container group react hook form. */
  control: Control<FieldValues, CreateContainerGroupValues>
  /** The id attribute for the fields. */
  id: CreateContainerGroupFormSectionIdAttributes
  /** The react hook form method that triggers validation for specified fields. */
  trigger: UseFormTrigger<FieldValues>
}

export const CommandFields: FunctionComponent<CommandFieldsProps> = ({ clearErrors, control, id, trigger }) => {
  const intl = useIntl()
  const [isSidePanelOpen, setSidePanelModalOpen] = useState<boolean>(false)
  const { errors } = useFormState({ control })
  const commandFieldsHaveErrors = checkIfCommandFieldsHaveErrors(errors)
  const commandFieldsValues = useWatch({
    control,
    name: [CreateContainerGroupField.COMMAND, CreateContainerGroupField.COMMAND_ARGUMENTS],
  })

  return (
    <div className="mb-10" id={id}>
      <FormStateCard
        hasError={commandFieldsHaveErrors}
        onEditForm={() => setSidePanelModalOpen(true)}
        title={intl.formatMessage(CommandFieldsMessages.commandFieldLabel)}
      >
        <CommandDetails
          command={commandFieldsValues[0]}
          args={commandFieldsValues[1]}
          onClick={() => setSidePanelModalOpen(true)}
        />
      </FormStateCard>

      {isSidePanelOpen && (
        <CommandSidePanelModalModal
          clearErrors={clearErrors}
          control={control}
          isShown={isSidePanelOpen}
          onClose={() => setSidePanelModalOpen(false)}
          trigger={trigger}
        />
      )}
    </div>
  )
}
