import { defineMessages } from 'react-intl'

export const ContainerGroupQuotaExceededMessages = defineMessages({
  body1: {
    defaultMessage: "You've reached the maximum number of deployed container groups for your organization.",
    id: 'ADV5MQVS',
    description:
      'The first section of the body for the modal that shows when a user has met their container group quota.',
  },
  body2: {
    defaultMessage:
      'To create a new container group, submit a request to increase your quota or delete an existing container group.',
    id: 'e9mw453t',
    description:
      'The second section of the body for the modal that shows when a user has met their container group quota.',
  },
  cancelButtonLabel: {
    defaultMessage: 'Cancel',
    id: 'amwGJ59Y',
    description:
      'The label for the cancel button on the modal that shows when a user has met their container group quota.',
  },
  requestIncreaseButtonLabel: {
    defaultMessage: 'Request Increase',
    id: 'XHJtF2QM',
    description:
      'The label for the enter request increase button on the modal that shows when a user has met their container group quota.',
  },
  title: {
    defaultMessage: 'Container Group Quota Exceeded',
    id: 's3An7eIC',
    description: 'The title for the modal that shows when a organization has met their container group quota.',
  },
})
