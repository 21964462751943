import type { ReactNode } from 'react'
import type { IntlShape } from 'react-intl'
import type { ToastNotificationType } from '../../models'
import { DeleteContainerGroupSucceededContentMessages } from './messages'

/**
 * Retrieves the content needed for a success `ToastNotification` that is shown when the user delete container group.
 *
 * @param intl The `IntlShape` object needed to format the messages.
 */
export const getDeleteContainerGroupSucceededContent = (
  intl: IntlShape,
  containerGroupDisplayName: string,
): {
  body: ReactNode
  type: ToastNotificationType
  title: string
} => {
  return {
    type: 'success',
    title: intl.formatMessage(DeleteContainerGroupSucceededContentMessages.title, {
      container_group_display_name: containerGroupDisplayName,
    }),
    body: intl.formatMessage(DeleteContainerGroupSucceededContentMessages.body),
  }
}
