import { defineMessages } from 'react-intl'

export const EditLoggingInputFieldMessages = defineMessages({
  axiomSelectedLabel: {
    defaultMessage: 'Using Axiom',
    id: '/9zE9XCt',
    description:
      'The text shown in the external logging service card on the create container group page when Axiom is selected.',
  },
  datadogSelectedLabel: {
    defaultMessage: 'Using Datadog',
    id: 'f8x3a7/N',
    description:
      'The text shown in the external logging service card on the create container group page when datadog is selected.',
  },
  httpSelectedLabel: {
    defaultMessage: 'Using HTTP',
    id: 'aWWMXZp4',
    description:
      'The text shown in the external logging service card on the create container group page when HTTP is selected.',
  },
  learnMoreText: {
    defaultMessage: 'Learn More',
    id: 'ON7Rm6nY',
    description: 'The text for the learn more link on the create container group page.',
  },
  loggingDescription: {
    defaultMessage:
      'Stream events from your container group deployment to an external service for easier parsing, retention, and monitoring. {learn_more}',
    id: 'DIv50JMq',
    description: 'The description for the logging input fields on the create container group page.',
  },
  loggingDisabledText: {
    defaultMessage: 'External logging is disabled',
    id: 'BX+OiR5k',
    description:
      'The text shown in the external logging service card on the create container group page when logging is disabled.',
  },
  needHelpText: {
    defaultMessage: 'Need help configuring external logging with {external_logging_provider}?',
    id: 'E9BkRNf5',
    description: 'The help text shown below the Google Cloud input fields.',
  },
  newRelicSelectedLabel: {
    defaultMessage: 'Using New Relic',
    id: 'DpIC28Vi',
    description:
      'The text shown in the external logging service card on the create container group page when new relic is selected.',
  },
  sidePanelTitle: {
    defaultMessage: 'Logging',
    id: 'PED7ce87',
    description:
      'The title for the dialog box that opens when a user wants to edit Logging fields on the create container group page.',
  },
  splunkSelectedLabel: {
    defaultMessage: 'Using Splunk',
    id: 'bs7xyEZo',
    description:
      'The text shown in the external logging service card on the create container group page when Splunk is selected.',
  },
  tcpSelectedLabel: {
    defaultMessage: 'Using TCP',
    id: 'V3rvM1ki',
    description:
      'The text shown in the external logging service card on the create container group page when TCP is selected.',
  },
})
