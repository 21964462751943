import type { FunctionComponent } from 'react'
import { useState } from 'react'
import type { Control, FieldValues, UseFormTrigger } from 'react-hook-form'
import { Controller, useFormState, useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Button } from '../../../../components/Button'
import { Checkbox } from '../../../../components/Checkbox'
import { TextField } from '../../../../components/TextField'
import { Link } from '../../../../components/base'
import { SidePanelModal } from '../../../../components/block/SidePanelModal'
import { FormStateCard } from '../../../../components/forms/FormStateCard'
import { Select } from '../../../../components/forms/Select'
import { EditContainerGatewayFieldsMessages, EditContainerGroupMainContentMessages } from '../../messages'
import type { EditContainerGroupFormSectionIdAttributes, EditContainerGroupValues } from '../../models'
import { EditContainerGroupField } from '../../models'
import { checkIfIContainerGatewayFieldsHaveErrors, containerGatewayFieldsList } from './utils'

interface EditContainerGatewayFieldsProps {
  /** The control for the create container group react hook form. */
  control: Control<FieldValues, EditContainerGroupValues>
  /** The id attribute for the fields. */
  id: EditContainerGroupFormSectionIdAttributes
  /** The flag indicating if the containerGateway side panel is open. */
  isContainerGatewaySidePanelOpen: boolean
  /** The function that sets the state of the isContainerGatewaySidePanelOpen state. */
  setIsContainerGatewaySidePanelOpen: (isContainerGatewaySidePanelOpen: boolean) => void
  /** The react hook form method that triggers validation for specified fields. */
  trigger: UseFormTrigger<FieldValues>
}

export const EditContainerGatewayFields: FunctionComponent<EditContainerGatewayFieldsProps> = ({
  control,
  id,
  isContainerGatewaySidePanelOpen,
  setIsContainerGatewaySidePanelOpen,
  trigger,
}) => {
  const intl = useIntl()
  const { errors } = useFormState({ control })
  const isContainerGatewayAuthenticationRequired = useWatch({
    control,
    name: EditContainerGroupField.CONTAINER_GATEWAY_REQUIRES_AUTHENTICATION,
  })
  const isContainerGatewayEnabled = useWatch({ control, name: EditContainerGroupField.CONTAINER_GATEWAY_ENABLED })
  const containerGatewayPort = useWatch({ control, name: EditContainerGroupField.CONTAINER_GATEWAY_PORT })
  const containerGatewayFieldsHaveErrors = checkIfIContainerGatewayFieldsHaveErrors(errors)
  const [isContainerGatewayAuthenticated, setIsContainerGatewayAuthenticated] = useState<boolean>(
    isContainerGatewayAuthenticationRequired,
  )

  const handleValidateFieldsBeforeClose = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    await trigger(containerGatewayFieldsList).then((isValid) => {
      if (isValid) {
        setIsContainerGatewaySidePanelOpen(false)
      }
    })
  }

  return (
    <div className="mb-10" id={id}>
      <FormStateCard
        hasError={containerGatewayFieldsHaveErrors}
        isDisabled={!isContainerGatewayEnabled}
        onEditForm={() => setIsContainerGatewaySidePanelOpen(true)}
        title={intl.formatMessage(EditContainerGatewayFieldsMessages.containerGatewayFieldsLabel)}
      >
        <p>
          {isContainerGatewayEnabled
            ? isContainerGatewayAuthenticated
              ? intl.formatMessage(
                  EditContainerGatewayFieldsMessages.containerGatewayEnabledAuthenticationRequiredLabel,
                  {
                    port: containerGatewayPort ?? 1,
                  },
                )
              : intl.formatMessage(
                  EditContainerGatewayFieldsMessages.containerGatewayEnabledAuthenticationNotRequiredLabel,
                  {
                    port: containerGatewayPort ?? 1,
                  },
                )
            : intl.formatMessage(EditContainerGatewayFieldsMessages.containerGatewayDisabledLabel)}
        </p>
      </FormStateCard>

      <SidePanelModal
        CustomButton={
          <Button variant="green-filled" form="updateContainerGatewayFieldsForm" isFullWidth type="submit">
            {intl.formatMessage(EditContainerGroupMainContentMessages.configureButtonLabel)}
          </Button>
        }
        isShown={isContainerGatewaySidePanelOpen}
        onClose={() => setIsContainerGatewaySidePanelOpen(false)}
        title={intl.formatMessage(EditContainerGatewayFieldsMessages.sectionTitle)}
      >
        <form onSubmit={handleValidateFieldsBeforeClose} id="updateContainerGatewayFieldsForm">
          <div className="mt-12 px-10">
            <h2 className="mb-2 text-3xl font-bold">
              {intl.formatMessage(EditContainerGatewayFieldsMessages.sectionTitle)}
            </h2>

            <p className="mb-10">
              {intl.formatMessage(EditContainerGatewayFieldsMessages.description, {
                documentation_link: (
                  <Link url="https://docs.salad.com/container-engine/gateway/enabling-ipv6">
                    {intl.formatMessage(EditContainerGatewayFieldsMessages.descriptionDocumentationLinkText)}
                  </Link>
                ),
              })}
            </p>

            <div className="mb-6">
              <Controller
                name={EditContainerGroupField.CONTAINER_GATEWAY_ENABLED}
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <Checkbox
                      {...field}
                      {...fieldState}
                      isChecked={field.value as boolean}
                      isDisabled={true}
                      id="containerGateway-enabled-checkbox"
                      label={intl.formatMessage(EditContainerGatewayFieldsMessages.enableContainerGatewayLabel)}
                    />
                  )
                }}
              />
            </div>

            <div className="mb-6">
              <Controller
                name={EditContainerGroupField.CONTAINER_GATEWAY_PORT}
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <TextField
                      {...field}
                      {...fieldState}
                      defaultValue={field.value}
                      helperText={intl.formatMessage(EditContainerGatewayFieldsMessages.portHelperText)}
                      label={intl.formatMessage(EditContainerGatewayFieldsMessages.portLabel)}
                      isDisabled={!isContainerGatewayEnabled}
                      isFullWidth
                      min={1}
                      max={65535}
                      type="number"
                    />
                  )
                }}
              />
            </div>

            <Controller
              name={EditContainerGroupField.CONTAINER_GATEWAY_REQUIRES_AUTHENTICATION}
              control={control}
              defaultValue={isContainerGatewayAuthenticated}
              render={({ field, fieldState }) => {
                return (
                  <Select
                    {...field}
                    {...fieldState}
                    defaultSelectedValue={field.value}
                    helperText={intl.formatMessage(
                      EditContainerGatewayFieldsMessages.containerGatewayAuthenticationHelperText,
                    )}
                    labelText={intl.formatMessage(
                      EditContainerGatewayFieldsMessages.containerGatewayAuthenticationLabel,
                    )}
                    isDisabled={true}
                    isFullWidth
                    isPlaceholderOptionDisabled
                    onChange={(value) => {
                      const booleanValue = value === 'true'
                      field.onChange(booleanValue)
                      // This is needed to trigger the UI updating for the protocol field
                      setIsContainerGatewayAuthenticated(booleanValue)
                    }}
                    options={[
                      {
                        label: intl.formatMessage(EditContainerGatewayFieldsMessages.yesLabel),
                        value: true,
                      },
                      {
                        label: intl.formatMessage(EditContainerGatewayFieldsMessages.noLabel),
                        value: false,
                      },
                    ]}
                  />
                )
              }}
            />
          </div>
        </form>
      </SidePanelModal>
    </div>
  )
}
