import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { Button } from '../../../../components/Button'
import { ButtonLink } from '../../../../components/base'
import { Modal } from '../../../../components/block/Modal'
import { ContainerGroupInstancesQuotaExceededMessages } from '../../messages/ContainerGroupInstancesQuotaExceededModalMessages'

interface ContainerGroupInstancesQuotaExceededModalProps {
  /** The link for users to request an increase to their container groups instance quota */
  linkToRequestIncreaseForContainerGroupInstancesQuotas: string
  /** The callback executed when the user clicks the `Cancel` button. */
  onCloseWindow: () => void
}

export const ContainerGroupInstancesQuotaExceededModal: FunctionComponent<
  ContainerGroupInstancesQuotaExceededModalProps
> = ({ linkToRequestIncreaseForContainerGroupInstancesQuotas, onCloseWindow }) => {
  const intl = useIntl()

  return (
    <Modal onClose={onCloseWindow} title={intl.formatMessage(ContainerGroupInstancesQuotaExceededMessages.title)}>
      <h1 className="mb-8 text-2xl font-bold">
        {intl.formatMessage(ContainerGroupInstancesQuotaExceededMessages.title)}
      </h1>
      <p className="mb-8">{intl.formatMessage(ContainerGroupInstancesQuotaExceededMessages.body1)}</p>
      <p className="mb-8">{intl.formatMessage(ContainerGroupInstancesQuotaExceededMessages.body2)}</p>
      <div className="flex max-w-md gap-6">
        <Button variant="green-outlined" onClick={onCloseWindow} isFullWidth>
          {intl.formatMessage(ContainerGroupInstancesQuotaExceededMessages.cancelButtonLabel)}
        </Button>
        <ButtonLink isFullWidth url={linkToRequestIncreaseForContainerGroupInstancesQuotas}>
          {intl.formatMessage(ContainerGroupInstancesQuotaExceededMessages.requestIncreaseButtonLabel)}
        </ButtonLink>
      </div>
    </Modal>
  )
}
