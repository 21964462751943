import { defineMessages } from 'react-intl'

export const CreateOrganizationWithAlreadyUsedReferralCodeContentMessages = defineMessages({
  body: {
    defaultMessage: 'Invite codes can only be used once',
    id: 'Pq9RL9kk',
    description:
      'The description shown in a toast notification message shown when the user has provided a referral code that has already been used.',
  },
  title: {
    defaultMessage: 'The invite code has been used',
    id: 'HfqIyTp7',
    description:
      'The toast notification title shown when the user has provided a referral code that has already been used.',
  },
})
