import { defineMessages } from 'react-intl'

export const CreateContainerGroupInvalidRamOptionErrorContentMessages = defineMessages({
  body: {
    defaultMessage: 'You have provided an invalid RAM option.',
    id: 'oUln4dAg',
    description:
      'This is the body of the toast notification that is displayed when a user tries to create a container group with an invalid RAM option.',
  },
  title: {
    defaultMessage: 'RAM Option Invalid',
    id: 'e+brvV5G',
    description:
      'This is the title of the toast notification that is displayed when a user tries to create a container group with an invalid RAM option.',
  },
})
