import type { FunctionComponent } from 'react'
import { forwardRef } from 'react'

interface RadioTabsProps {
  /** The label for the radio tabs input. */
  label: string
  /** The onChange handler */
  onChange: (value: string) => void
  /** The name for the radio tabs input. */
  name: string
  /** The tab options the user has to select from. */
  tabs: { label: string; value: string }[]
}

export const RadioTabs: FunctionComponent<RadioTabsProps> = forwardRef<HTMLInputElement, RadioTabsProps>(
  ({ label, name, onChange, tabs }, ref) => {
    return (
      <fieldset className="flex w-full">
        <legend className="mb-2 text-base font-bold">{label}</legend>
        {tabs.map((tab, index) => (
          <div className="relative w-full" key={tab.value}>
            <input
              defaultChecked={index === 0}
              onChange={(e) => (e.target.checked ? onChange(tab.value) : undefined)}
              className="peer absolute block size-full cursor-pointer opacity-0"
              id={`${tab.value + index}`}
              name={name}
              ref={ref}
              type="radio"
              value={tab.value}
              required={index === 0}
            />
            <label
              className="flex w-full items-center justify-center rounded-sm border-b-2 border-neutral-40 px-4 py-2 text-base font-normal text-neutral-100 outline-none peer-checked:border-blue-80 peer-checked:font-bold peer-checked:text-blue-80 peer-hover:border-blue-80 peer-focus:border-blue-80 peer-active:border-blue-80"
              htmlFor={`${tab.value + index}`}
            >
              {tab.label}
            </label>
          </div>
        ))}
      </fieldset>
    )
  },
)
