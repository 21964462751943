import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { ButtonDropdownMenu } from '../../../../block/ButtonDropdownMenu/ButtonDropdownMenu'
import { DeploymentInstanceTableActionsButtonMessages } from './messages'

export interface DeploymentInstanceTableActionsButtonProps {
  /** The flag indicating that the deploy instance action button is disabled */
  isDisabled?: boolean
  /** Indicates whether the instance is in the process of being reallocated */
  isReallocateInstancePending: boolean
  /** Indicates whether the instance is in the process of being recreated. */
  isRecreateInstancePending: boolean
  /** Indicates whether the instance is in the process of being restarted. */
  isRestartInstancePending: boolean
  /** The callback executed when reallocating an instance */
  onReallocateInstance: () => void
  /** The callback executed when recreating an instance. */
  onRecreateInstance: () => void
  /** The callback executed when restarting an instance. */
  onRestartInstance: () => void
  /** The callback executed to view this machine ids container logs */
  onViewContainerGroupLogs: () => void
  /** The state of the instance */
  state: string
}

export const DeploymentInstanceTableActionsButton: FunctionComponent<DeploymentInstanceTableActionsButtonProps> = ({
  isDisabled,
  isReallocateInstancePending,
  isRecreateInstancePending,
  isRestartInstancePending,
  onReallocateInstance,
  onRecreateInstance,
  onRestartInstance,
  onViewContainerGroupLogs,
  state,
}) => {
  const intl = useIntl()
  const isRunningState = state === 'running'

  const menuItems = [
    {
      iconClassName: 'fa-solid fa-rotate-right',
      isLoading: isReallocateInstancePending,
      label: intl.formatMessage(DeploymentInstanceTableActionsButtonMessages.reallocateLabel),
      onClick: onReallocateInstance,
    },
    {
      iconClassName: 'fa-solid fa-file-text',
      label: intl.formatMessage(DeploymentInstanceTableActionsButtonMessages.viewContainerLogsLabel),
      onClick: onViewContainerGroupLogs,
    },
  ]

  const menuItemsWithRestartAndRecreate = [
    {
      iconClassName: 'fa-solid fa-power-off',
      isLoading: isRestartInstancePending,
      label: intl.formatMessage(DeploymentInstanceTableActionsButtonMessages.restartLabel),
      onClick: onRestartInstance,
    },
    {
      iconClassName: 'fa-solid fa-window-restore',
      isLoading: isRecreateInstancePending,
      label: intl.formatMessage(DeploymentInstanceTableActionsButtonMessages.recreateLabel),
      onClick: onRecreateInstance,
    },
    ...menuItems,
  ]

  return (
    <div className="flex justify-center">
      <ButtonDropdownMenu
        dropDownMenuItems={isRunningState ? menuItemsWithRestartAndRecreate : menuItems}
        isDisabled={isDisabled}
      />
    </div>
  )
}
