import { defineMessages } from 'react-intl'

export const DeploymentInstanceTableMessages = defineMessages({
  actionsHeader: {
    id: 'TdqA7o3D',
    defaultMessage: 'Action',
    description: "The deployment instance's Action table header",
  },
  ageHeader: {
    id: 'hw2A9MdF',
    defaultMessage: 'Age',
    description: "The deployment instance's Age table header",
  },
  readyHeader: {
    id: 'QzCjpU7p',
    defaultMessage: 'Ready',
    description: "The deployment instance's Ready table header",
  },
  instanceStatusesDescription: {
    defaultMessage: 'For more information about instance statuses, {read_more_here_link}.',
    id: 'W59qi0rJ',
    description: 'Instance statuses description',
  },
  lastStatusUpdateHeader: {
    id: 'zYFdDXwG',
    defaultMessage: 'Last Status Update',
    description: "The deployment instance's Last Status Update table header",
  },
  machineIdAndStatusHeader: {
    id: '81dbBMd2',
    defaultMessage: 'Machine ID and Status',
    description: "The deployment instance's Machine Id and Status table header",
  },
  readMoreHere: {
    defaultMessage: 'read more here',
    id: '2292ESdd',
    description: 'Read more link',
  },
  stoppingStatus: {
    id: 'vNLMKHYu',
    defaultMessage: 'Stopping',
    description: "The deployment instance's stopping status",
  },
})
