import type { FunctionComponent } from 'react'
import { forwardRef } from 'react'
import type { Control, FieldValues } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { TextField } from '../../../../components/TextField'
import { NameInputMessages } from '../../messages'
import type { CreateContainerGroupFormSectionIdAttributes, CreateContainerGroupValues } from '../../models'
import { CreateContainerGroupField } from '../../models'

interface NameInputProps {
  /** The control for the react hook form. */
  control: Control<FieldValues, CreateContainerGroupValues>
  /** The id attribute for the field. */
  id: CreateContainerGroupFormSectionIdAttributes
}

export const NameInput: FunctionComponent<NameInputProps> = forwardRef<HTMLDivElement, NameInputProps>(
  ({ control, id }, ref) => {
    const intl = useIntl()

    return (
      <div id={id} ref={ref}>
        <Controller
          name={CreateContainerGroupField.NAME}
          control={control}
          render={({ field, fieldState }) => {
            return (
              <TextField
                {...field}
                {...fieldState}
                defaultValue={field.value}
                helperText={intl.formatMessage(NameInputMessages.helperText)}
                label={intl.formatMessage(NameInputMessages.label)}
                isFullWidth
              />
            )
          }}
        />
      </div>
    )
  },
)
