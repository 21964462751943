import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import type { DeploymentStatus } from '../../../../../features/containerGroupInstanceTable/models'
import { Tag } from '../../../../Tag'
import { DeploymentInstanceStatusMessages } from '../../../messages'
import { AllocatingStatus, CreatingStatus, DownloadingStatus, RunningStatus, StartingStatus } from './components'

interface DeploymentInstanceStatusProps {
  /** The creating percentage */
  creatingPercentage: number | undefined
  /** The deployment status */
  deploymentStatus: DeploymentStatus
  /** The downloading percentage */
  downloadingPercentage: number | undefined
  /** The flag indicating if allocating has taken longer than expected */
  hasAllocatingTakenLongerThanExpected: boolean
  /** The flag indicating if the instance has started */
  hasStarted: boolean
  /** The machine ID of the deployment instance. */
  machineId?: string
  /** The callback executed when the user clicks the status */
  onClickStatus: () => void
  /**
   * Optional callback that when executed records a mixpanel event for the element that was clicked.
   *
   * @param label The label of the element that was clicked
   */
  onTrackMixpanelEvent: (label: string, machineId?: string) => void
}

export const DeploymentInstanceStatus: FunctionComponent<DeploymentInstanceStatusProps> = ({
  creatingPercentage,
  deploymentStatus,
  downloadingPercentage,
  hasAllocatingTakenLongerThanExpected,
  hasStarted,
  machineId,
  onClickStatus,
  onTrackMixpanelEvent,
}) => {
  const intl = useIntl()
  const [creatingProgressStuck, setCreatingProgressStuck] = useState<
    { stuck: boolean; eventFired: boolean } | undefined
  >(undefined)

  const [downloadingProgressStuck, setDownloadingProgressStuck] = useState<
    { stuck: boolean; eventFired: boolean } | undefined
  >(undefined)

  useEffect(() => {
    if (creatingPercentage === 99 && !creatingProgressStuck?.stuck) {
      setTimeout(() => {
        setCreatingProgressStuck({ stuck: true, eventFired: false })
      }, 90000)
    }
  }, [setCreatingProgressStuck, creatingPercentage, creatingProgressStuck?.stuck])

  useEffect(() => {
    if (creatingProgressStuck?.stuck && !creatingProgressStuck?.eventFired && creatingPercentage === 99) {
      onTrackMixpanelEvent(`Creating percentage stuck at 99% for more than 90 seconds`, machineId)
      setCreatingProgressStuck({ stuck: true, eventFired: true })
    }
  }, [creatingProgressStuck, creatingPercentage, machineId, onTrackMixpanelEvent])

  useEffect(() => {
    if (downloadingPercentage === 99 && !downloadingProgressStuck?.stuck) {
      setTimeout(() => {
        setDownloadingProgressStuck({ stuck: true, eventFired: false })
      }, 90000)
    }
  }, [setDownloadingProgressStuck, downloadingPercentage, downloadingProgressStuck?.stuck])

  useEffect(() => {
    if (downloadingProgressStuck?.stuck && !downloadingProgressStuck?.eventFired && downloadingPercentage === 99) {
      onTrackMixpanelEvent(`Downloading percentage stuck at 99% for more than 90 seconds`, machineId)
      setDownloadingProgressStuck({ stuck: true, eventFired: true })
    }
  }, [downloadingProgressStuck, downloadingPercentage, machineId, onTrackMixpanelEvent])

  return (
    <>
      {deploymentStatus === 'stopping' ? (
        <Tag color="red" onClick={onClickStatus} hasHelpCursor>
          <i className={classNames('mr-2', 'fa-solid fa-stop')} />
          <span className="uppercase">{intl.formatMessage(DeploymentInstanceStatusMessages.stopping)}</span>
        </Tag>
      ) : (
        <div className="flex flex-wrap gap-1">
          <AllocatingStatus
            deploymentStatus={deploymentStatus}
            hasTakenLongerThanExpected={hasAllocatingTakenLongerThanExpected}
            onClick={onClickStatus}
          />
          <DownloadingStatus
            deploymentStatus={deploymentStatus}
            percentage={downloadingPercentage}
            onClick={onClickStatus}
          />
          <CreatingStatus deploymentStatus={deploymentStatus} percentage={creatingPercentage} onClick={onClickStatus} />
          <StartingStatus deploymentStatus={deploymentStatus} hasStarted={hasStarted} onClick={onClickStatus} />
          <RunningStatus deploymentStatus={deploymentStatus} hasStarted={hasStarted} onClick={onClickStatus} />
        </div>
      )}
    </>
  )
}
