import { defineMessages } from 'react-intl'

export const CreateOrganizationWithNonUnqiueNameContentMessages = defineMessages({
  body: {
    defaultMessage: 'Organization names must be unique. {organization_name} has been used.',
    id: '5hVr/Jac',
    description:
      'The description shown in a toast notification message shown when we encounter an error on their request to create an organization with a name that has already been taken.',
  },
  title: {
    defaultMessage: 'Please choose another organization name',
    id: 'MXe0IEJE',
    description:
      'The toast notification title shown when we encounter an error on their request to create an organization with a name that has already been taken.',
  },
})
