import { ContainerGroupInstanceStateEnum } from '@saladtechnologies/openapi-cloud-portal-browser'
import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { Tag } from '../../../../Tag'
import { InstanceStatusTagMessages } from './messages'

interface InstanceStatusTagProps {
  /** Indicates the deployment instance status */
  deploymentStatus?: ContainerGroupInstanceStateEnum
  /** Started boolean of the instance */
  hasStarted?: boolean
}

export const InstanceStatusTag: FunctionComponent<InstanceStatusTagProps> = ({ deploymentStatus, hasStarted }) => {
  const intl = useIntl()

  switch (deploymentStatus) {
    case ContainerGroupInstanceStateEnum.Allocating: {
      return (
        <Tag color="green">
          <i className={classNames('fa-solid fa-spinner mr-2 animate-spin')} />
          <span className="uppercase">{deploymentStatus}</span>
        </Tag>
      )
    }
    case ContainerGroupInstanceStateEnum.Creating: {
      return (
        <Tag color="green">
          <i className={classNames('fa-solid fa-clock mr-2')} />
          <span className="uppercase">{deploymentStatus}</span>
        </Tag>
      )
    }
    case ContainerGroupInstanceStateEnum.Running: {
      return (
        <Tag color="darkGreen">
          <i className={classNames('fa-solid fa-play mr-2 font-bold')}></i>
          <span className="font-bold uppercase">
            {hasStarted ? deploymentStatus : intl.formatMessage(InstanceStatusTagMessages.startedStatusLabel)}
          </span>
        </Tag>
      )
    }
    case ContainerGroupInstanceStateEnum.Downloading: {
      return (
        <Tag color="green">
          <i className={classNames('fa-solid fa-download mr-2')} />
          <span className="uppercase">{deploymentStatus}</span>
        </Tag>
      )
    }
    case ContainerGroupInstanceStateEnum.Stopping: {
      return (
        <Tag color="red">
          <i className={classNames('fa-solid fa-stop mr-2')} />
          <span className="uppercase">{deploymentStatus}</span>
        </Tag>
      )
    }
    default: {
      return (
        <Tag color="gray">
          <i className={classNames('fa-solid fa-ban mr-2')} />
          <span className="uppercase">{intl.formatMessage(InstanceStatusTagMessages.inactiveStatusLabel)}</span>
        </Tag>
      )
    }
  }
}
