import { yupResolver } from '@hookform/resolvers/yup'
import type { FunctionComponent } from 'react'
import { useState } from 'react'
import { Controller } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { Button } from '../../../../components/Button'
import { TextField } from '../../../../components/TextField'
import { Link } from '../../../../components/base'
import { useFixedForm } from '../../../../hooks'
import { EditRecipeDeploymentFormMessages } from '../../messages'
import { useEditRecipeDeploymentFormValidation } from './useEditRecipeDeploymentFormValidation'

export interface EditRecipeDeploymentValues {
  /** The display name. */
  displayName?: string
  /** The replica count. */
  replicaCount?: number
}

interface EditRecipeDeploymentFormProps {
  /** The recipe deployment display name. */
  displayName: string
  /** The flag indicating that the request to edit the recipe deployment is pending. */
  isSubmitPending: boolean
  /** The link to the typeform for requesting an increase in the recipe deployment instance quota limit. */
  linkToRequestIncreaseForRecipeDeploymentInstancesQuotas: string
  /** The max replica count. */
  maxReplicaCount: number | undefined
  /** A callback that when executed revokes the invitation sent */
  onEditRecipeDeployment: (displayName: string, replicaCount: number) => void
  /** A callback executed each time the replica count is updated. */
  onUpdateReplicaCount: (pendingReplicaCount: number) => void
  /** The recipe deployment replica count. */
  replicaCount: number
}

export const EditRecipeDeploymentForm: FunctionComponent<EditRecipeDeploymentFormProps> = ({
  displayName,
  isSubmitPending,
  linkToRequestIncreaseForRecipeDeploymentInstancesQuotas,
  maxReplicaCount,
  onEditRecipeDeployment,
  onUpdateReplicaCount,
  replicaCount,
}) => {
  const intl = useIntl()
  const availableReplicaCount = maxReplicaCount ?? 1
  const editRecipeDeploymentValidationScheme = useEditRecipeDeploymentFormValidation(availableReplicaCount)
  const [isDifferentDisplayName, toggleIsDifferentDisplayName] = useState<boolean>(false)

  const { control, getValues, handleSubmit, register } = useFixedForm<EditRecipeDeploymentValues>({
    resolver: yupResolver(editRecipeDeploymentValidationScheme),
    onSubmit: (values) => {
      if (values.displayName !== undefined || values.replicaCount !== undefined) {
        const updatedDisplayName =
          values.displayName && values.displayName !== displayName ? values.displayName : displayName
        const updatedReplicaCount =
          values.replicaCount && values.replicaCount !== replicaCount ? values.replicaCount : replicaCount
        onEditRecipeDeployment(updatedDisplayName, updatedReplicaCount)
      }
    },
  })

  const updatedReplicaCount = getValues('replicaCount')
  const handleOnChangeRecipeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const displayNameChanged = event.target.value.trim() !== displayName
    toggleIsDifferentDisplayName(displayNameChanged)
  }
  const isDifferentDisplayNameOrReplicaCount = isDifferentDisplayName || updatedReplicaCount !== undefined

  return (
    <form onSubmit={handleSubmit}>
      <Controller
        name="displayName"
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...register('displayName', { onChange: handleOnChangeRecipeName })}
            {...field}
            {...fieldState}
            helperText={fieldState.error?.message}
            defaultValue={displayName}
            label={intl.formatMessage(EditRecipeDeploymentFormMessages.displayNameFieldLabel)}
            isFullWidth
          />
        )}
      />
      <Controller
        name="replicaCount"
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            {...fieldState}
            helperText={
              fieldState.error?.message ?? (
                <FormattedMessage
                  {...EditRecipeDeploymentFormMessages.replicaCountHelpText}
                  values={{
                    contact_support_link: (
                      <Link url={linkToRequestIncreaseForRecipeDeploymentInstancesQuotas} id="terms_of_service_link">
                        {intl.formatMessage(EditRecipeDeploymentFormMessages.contactSupportLinkText)}
                      </Link>
                    ),
                    max_replicas: availableReplicaCount,
                  }}
                />
              )
            }
            onChange={(value) => {
              onUpdateReplicaCount(value as number)
              field.onChange(value)
            }}
            defaultValue={replicaCount}
            label={intl.formatMessage(EditRecipeDeploymentFormMessages.replicaCountFieldLabel)}
            isFullWidth
            min={1}
            type="number"
          />
        )}
      />
      <Button
        type="submit"
        isLoading={isSubmitPending}
        isDisabled={isSubmitPending || !isDifferentDisplayNameOrReplicaCount}
        isFullWidth
      >
        {intl.formatMessage(EditRecipeDeploymentFormMessages.editRecipeDeploymentButtonLabel)}
      </Button>
    </form>
  )
}
