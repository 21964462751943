import type { FunctionComponent, ReactNode } from 'react'
import { ConnectedNavigationBar } from '../../components/ConnectedNavigationBar'

interface AppLayoutProps {
  children: ReactNode
}

export const AppLayout: FunctionComponent<AppLayoutProps> = ({ children }) => {
  return (
    <>
      <ConnectedNavigationBar />
      {children}
    </>
  )
}
