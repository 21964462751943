import type { FunctionComponent } from 'react'
import { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import { LoadingPage } from '../../components/page/LoadingPage'
import { selectJobQueues } from '../../features/jobQueues/jobQueuesSelectors'
import { getJobQueues, getJobQueuesPageData } from '../../features/jobQueues/jobQueuesSlice'
import { configuresJobQueuesEntityId } from '../../features/jobQueues/utils'
import { selectIsLeftColumnShowing } from '../../features/navigationBar/navigationBarSelectors'
import { setLeftColumnShowing } from '../../features/navigationBar/navigationBarSlice'
import { selectOrganizationDisplayName } from '../../features/organizations/organizationsSelectors'
import { selectProjectDisplayName, selectProjects } from '../../features/projects/projectsSelectors'
import { deleteProject } from '../../features/projects/projectsSlice'
import { selectRequestStatus, selectRequestStatusIsPending } from '../../features/requestStatus/requestStatusSelectors'
import { getCreateJobQueuePagePath, getJobQueuesPagePath } from '../../routes/routes-utils'
import { useAppDispatch, useAppSelector } from '../../store'
import { JobQueuesPage } from './JobQueuesPage'

export const ConnectedJobQueuesPage: FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { organizationName = '', projectName = '' } = useParams()

  const createJobQueuePath = getCreateJobQueuePagePath(organizationName, projectName)
  const isGetJobQueuesPageDataRequestPending = useAppSelector((state) =>
    selectRequestStatusIsPending(state, 'getJobQueuesPageData'),
  )
  const jobQueues = useAppSelector((state) =>
    selectJobQueues(state.jobQueues, configuresJobQueuesEntityId(organizationName, projectName)),
  )
  const deleteProjectRequestStatus = useAppSelector((state) => selectRequestStatus(state, 'deleteProject'))
  const isLeftColumnOpen = useAppSelector(selectIsLeftColumnShowing)
  const organizationDisplayName = useAppSelector((state) => selectOrganizationDisplayName(state, organizationName))
  const projects = useAppSelector((state) => selectProjects(state, organizationName))?.projects || []
  const currentProjectDisplayName = useAppSelector((state) =>
    selectProjectDisplayName(state, organizationName, projectName),
  )
  const projectSelectOptions = projects.map((project) => {
    return {
      ...project,
      selected: project.name === projectName,
    }
  })

  const handleCloseLeftNavBar = useCallback(() => {
    dispatch(setLeftColumnShowing({ showing: false }))
  }, [dispatch])

  const handleGetJobQueues = useCallback(() => {
    dispatch(
      getJobQueues({
        organizationName,
        projectName,
      }),
    )
  }, [dispatch, organizationName, projectName])

  const handleGetJobQueuesPageData = useCallback(() => {
    dispatch(
      getJobQueuesPageData({
        organizationName,
        projectName,
      }),
    )
  }, [dispatch, organizationName, projectName])

  const handleDeleteProject = useCallback(
    (projectName: string) => {
      dispatch(deleteProject({ organizationName, projectName }))
    },
    [dispatch, organizationName],
  )

  const handleProjectChange = useCallback(
    (projectName: string) => {
      navigate(getJobQueuesPagePath(organizationName, projectName))
    },
    [navigate, organizationName],
  )

  useEffect(() => {
    handleGetJobQueuesPageData()
    const intervalId = setInterval(handleGetJobQueues, 15000)

    return () => clearInterval(intervalId)
  }, [handleGetJobQueues, handleGetJobQueuesPageData])

  return isGetJobQueuesPageDataRequestPending ? (
    <LoadingPage />
  ) : (
    <JobQueuesPage
      createJobQueuePath={createJobQueuePath}
      currentOrganization={{ name: organizationName, displayName: organizationDisplayName ?? organizationName }}
      currentProject={{ name: projectName, displayName: currentProjectDisplayName ?? projectName }}
      isDeleteProjectPending={deleteProjectRequestStatus === 'pending'}
      isDeleteProjectSuccessful={deleteProjectRequestStatus === 'succeeded'}
      isLeftColumnOpen={isLeftColumnOpen}
      projects={projectSelectOptions}
      onCloseLeftDrawer={handleCloseLeftNavBar}
      onDeleteProject={handleDeleteProject}
      onProjectChange={handleProjectChange}
      jobQueues={jobQueues}
    />
  )
}
