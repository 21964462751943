import { defineMessages } from 'react-intl'

export const ContainerMonitoringMessages = defineMessages({
  backLinkText: {
    defaultMessage: 'Exit to Container Groups',
    id: 'hqaT1hGe',
    description: 'The text for the back link button.',
  },
  containerMonitoringTitle: {
    defaultMessage: 'Container Monitoring',
    id: 'hk3cH1KW',
    description: 'The title for the container monitoring page.',
  },
  containerMonitoringSubtitle: {
    defaultMessage:
      'Allow Salad to automatically monitor your container to ensure that it is running correctly across all replicas.',
    id: '7H8DYfEG',
    description: 'The subtitle for the container monitoring page.',
  },
  deployButtonLabel: {
    defaultMessage: 'Deploy',
    id: 'fl3qtSWH',
    description: 'The label for the deploy button in the bottom bar.',
  },
  previousButtonLabel: {
    defaultMessage: 'Previous Step',
    description: 'The text for the back link.',
    id: 'XhE96M+U',
  },
  readinessProbeMonitoringText: {
    defaultMessage: 'Enable Readiness Probe Monitoring',
    id: '0MaEDkoU',
    description: 'The text explaining the need to enable readiness probe monitoring.',
  },
})
