import { defineMessages } from 'react-intl'

export const RecreateConfirmationModalMessages = defineMessages({
  title: {
    defaultMessage: 'Recreate the container',
    id: 'KLolGOV0',
    description: 'Title of the recreate instance modal',
  },
  body: {
    defaultMessage:
      'Recreates the container from scratch. Not as fast as restart and not as destructive as reallocate. Use this when there is a problem with the image running.',
    id: '/3mggC2g',
    description: 'Description of the recreate instance modal',
  },
  cancel: {
    defaultMessage: 'Cancel',
    id: 'hHNj31G9',
    description: 'Cancel button text',
  },
  delete: {
    defaultMessage: 'Recreate',
    id: 'fMWSx4Rs',
    description: 'Recreate button text',
  },
})
