import { defineMessages } from 'react-intl'

export const EditRecipeDeploymentFormMessages = defineMessages({
  contactSupportLinkText: {
    defaultMessage: 'contact us',
    id: 'TtpzKfQK',
    description: 'The text shown for the contact support link.',
  },
  displayNameFieldLabel: {
    defaultMessage: 'Display Name',
    id: 'auaKe0RH',
    description: 'The label for the display name field on the Edit Recipe Deployment Page.',
  },
  displayNameInvalid: {
    defaultMessage: 'Display name cannot be empty',
    id: 'pvtObKUG',
    description:
      'The field validation error message shown when a user tries to submit the form without providing a display name.',
  },
  displayNameMinMaxErrorMessage: {
    defaultMessage: 'The display name must be between 2-63 characters.',
    id: 'sdrq3+Lo',
    description:
      'The error message for filling out the display name input field when the user has not met the min/max character rules.',
  },
  editRecipeDeploymentButtonLabel: {
    defaultMessage: 'Save',
    id: 'GBpLFZ8c',
    description: 'The button label for the edit recipe deployment form submission button.',
  },
  replicaCountFieldLabel: {
    defaultMessage: 'Replica Count',
    id: 'mv0Sm4Ra',
    description: 'The label for the replica count field on the Edit Recipe Deployment Page.',
  },
  replicaCountHelpText: {
    defaultMessage:
      'Deploy with 2+ replicas to ensure the uptime of your deployment. Need to increase your replica count limit? please {contact_support_link}',
    id: 'oJfTrhyq',
    description: 'The help text for the replica count field.',
  },
  replicaCountMinRequired: {
    defaultMessage: 'Replica count must be greater than or equal to 1',
    id: '2vksy1CU',
    description:
      'The field validation error message shown when a user enters more replica count instances than allowed.',
  },
  replicaCountMaxExceeded: {
    defaultMessage: 'Replica count must be less than or equal to {max_replicas}',
    id: 'qlP3/t2w',
    description:
      'The field validation error message shown when a user enters more replica count instances than allowed.',
  },
})
