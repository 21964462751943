import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import type { ObjectSchema } from 'yup'
import { object, string } from 'yup'
import type { LoginFormValues } from './Login'
import { loginMessages } from './LoginMessages'

export const useLoginFormValidation = (): ObjectSchema<LoginFormValues> => {
  const intl = useIntl()
  const validationScheme = useMemo(
    () =>
      object({
        email: string()
          .trim()
          .required(intl.formatMessage(loginMessages.emailRequired))
          .email(intl.formatMessage(loginMessages.emailInvalid)),
        password: string()
          .required(intl.formatMessage(loginMessages.passwordRequired))
          .min(8, intl.formatMessage(loginMessages.passwordLengthInvalid))
          .max(64, intl.formatMessage(loginMessages.passwordLengthInvalid)),
      }),
    [intl],
  )
  return validationScheme
}
