import { InferenceEndpointJobStatusEnum } from '@saladtechnologies/openapi-cloud-portal-browser'
import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { InferenceEndpointDetailsPlaygroundMessages } from '../../messages'
import { hasInferenceEndpointJobReachedFinalStatus, isInferenceEndpointJobTerminal } from '../../utils'
import { InferenceEndpointDetailsPlaygroundStatusItem } from '../InferenceEndpointDetailsPlaygroundStatusItem'

interface InferenceEndpointPlaygroundStatusProps {
  /** The inference endpoint job associated with the inference endpoint. */
  status?: InferenceEndpointJobStatusEnum
}

export const InferenceEndpointPlaygroundStatus: FunctionComponent<InferenceEndpointPlaygroundStatusProps> = ({
  status,
}) => {
  const intl = useIntl()

  const isTerminalStatus = isInferenceEndpointJobTerminal(status)
  const isFinalStatus = hasInferenceEndpointJobReachedFinalStatus(status)
  const getFinalStatusLabel = () => {
    if (status === InferenceEndpointJobStatusEnum.Failed) {
      return intl.formatMessage(InferenceEndpointDetailsPlaygroundMessages.failed)
    } else if (status === InferenceEndpointJobStatusEnum.Cancelled) {
      return intl.formatMessage(InferenceEndpointDetailsPlaygroundMessages.cancelled)
    } else {
      return intl.formatMessage(InferenceEndpointDetailsPlaygroundMessages.succeeded)
    }
  }

  return (
    <div className="mb-4 flex justify-center">
      <InferenceEndpointDetailsPlaygroundStatusItem
        label={intl.formatMessage(InferenceEndpointDetailsPlaygroundMessages.pending)}
        isLoading={status === InferenceEndpointJobStatusEnum.Pending}
        isCompleted={status === InferenceEndpointJobStatusEnum.Running || isFinalStatus}
        isErrorStatus={false}
        hasStatus={!!status}
        icon="fa-clock"
      />
      <InferenceEndpointDetailsPlaygroundStatusItem
        label={intl.formatMessage(InferenceEndpointDetailsPlaygroundMessages.running)}
        isLoading={status === InferenceEndpointJobStatusEnum.Running}
        isCompleted={isFinalStatus}
        isErrorStatus={false}
        hasStatus={!!status}
        icon="fa-running"
      />
      <InferenceEndpointDetailsPlaygroundStatusItem
        label={getFinalStatusLabel()}
        isLoading={false}
        isCompleted={isFinalStatus}
        isErrorStatus={isTerminalStatus}
        hasStatus={!!status}
        icon={isTerminalStatus ? 'fa-times' : 'fa-check'}
      />
    </div>
  )
}
