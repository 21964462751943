import type { FunctionComponent } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { LoadingPage } from '../../components/page/LoadingPage'
import { trackMixpanelPageViewedEvent } from '../../features/analytics/analyticsSlice'
import {
  selectCreateRecipeDeploymentSubmitting,
  selectEstimatedRecipeDeploymentCostPerHour,
  selectIsRecipeQuotaExceeded,
} from '../../features/createRecipeDeployment/createRecipeDeploymentSelectors'
import { createRecipeDeployment } from '../../features/createRecipeDeployment/createRecipeDeploymentSlice'
import { selectIsRecipeInstancesQuotaExceeded } from '../../features/editRecipeDeployment/editRecipeDeploymentSelectors'
import { selectIsLeftColumnShowing } from '../../features/navigationBar/navigationBarSelectors'
import { setLeftColumnShowing } from '../../features/navigationBar/navigationBarSlice'
import { selectOrganizationDisplayName } from '../../features/organizations/organizationsSelectors'
import { selectPaymentMethod } from '../../features/paymentMethod/paymentMethodSelectors'
import { selectProfileEmail } from '../../features/profile/profileSelectors'
import { selectProjectDisplayName } from '../../features/projects/projectsSelectors'
import {
  selectMaxCreatedRecipeDeploymentQuota,
  selectRecipeDeploymentInstanceQuota,
} from '../../features/quotas/quotasSelectors'
import { getRecipeDetails } from '../../features/recipeDetails/recipeDetailsSlice'
import { selectRequestStatusIsPending } from '../../features/requestStatus/requestStatusSelectors'
import { createRecipePageRoutePath } from '../../routes/routePaths'
import {
  getOrganizationBillingPagePath,
  getRecipeDetailsPagePath,
  getRecipeMarketplacePagePath,
} from '../../routes/routes-utils'
import { useAppDispatch, useAppSelector } from '../../store'
import {
  getRequestRecipeDeploymentInstanceQuotaIncreaseLink,
  getRequestRecipeDeploymentQuotaIncreaseLink,
} from '../../utils'
import { CreateRecipeDeploymentPage } from './CreateRecipeDeploymentPage'

export const ConnectedCreateRecipeDeploymentPage: FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { organizationName = '', projectName = '', recipeName = '' } = useParams()

  const [pendingReplicaCount, setPendingReplicaCount] = useState<number>(1)

  const isLeftColumnOpen = useAppSelector(selectIsLeftColumnShowing)
  const organizationDisplayName = useAppSelector((state) => selectOrganizationDisplayName(state, organizationName))
  const currentProjectDisplayName = useAppSelector((state) =>
    selectProjectDisplayName(state, organizationName, projectName),
  )

  const costPerHour = useAppSelector(selectEstimatedRecipeDeploymentCostPerHour)
  const createRecipeDeploymentSubmitting = useAppSelector(selectCreateRecipeDeploymentSubmitting)
  const email = useAppSelector(selectProfileEmail)
  const isBillingInformationRequired =
    useAppSelector((state) => selectPaymentMethod(state, organizationName)) === undefined
  const isGetRecipeDetailsPending = useAppSelector((state) => selectRequestStatusIsPending(state, 'getRecipeDetails'))
  const isRecipeQuotaExceeded = useAppSelector(selectIsRecipeQuotaExceeded)
  const isRecipeInstancesQuotaExceeded = useAppSelector(selectIsRecipeInstancesQuotaExceeded)
  const replicaCountLimit = useAppSelector((state) => selectRecipeDeploymentInstanceQuota(state, organizationName))
  const maxRecipeDeployments = useAppSelector((state) => selectMaxCreatedRecipeDeploymentQuota(state, organizationName))

  const linkToRequestIncreaseForRecipeDeploymentInstancesQuotas = getRequestRecipeDeploymentInstanceQuotaIncreaseLink(
    email as string,
    organizationName,
  )
  const linkToRequestIncreaseForRecipeDeploymentQuotas = getRequestRecipeDeploymentQuotaIncreaseLink(
    email as string,
    organizationName,
  )

  const handleEnterBillingInformation = () => {
    navigate(getOrganizationBillingPagePath(organizationName))
  }

  const handleCloseLeftNavBar = useCallback(() => {
    dispatch(setLeftColumnShowing({ showing: false }))
  }, [dispatch])

  const onUpdateReplicaCount = useCallback((replicas: number) => {
    setPendingReplicaCount(replicas)
  }, [])

  const onCreateRecipeDeployment = useCallback(
    (deploymentName: string, replicaCount: number) => {
      dispatch(
        createRecipeDeployment({
          createRecipeDeployment: {
            autostartPolicy: true,
            displayName: deploymentName,
            name: deploymentName,
            recipeName,
            replicas: replicaCount,
          },
          organizationName,
          projectName,
        }),
      )
    },
    [dispatch, organizationName, projectName, recipeName],
  )

  useEffect(() => {
    dispatch(getRecipeDetails({ organizationName, projectName, recipeName }))
    dispatch(
      trackMixpanelPageViewedEvent({
        path: createRecipePageRoutePath,
        organizationName,
        projectName,
        recipeName,
      }),
    )
  }, [dispatch, organizationName, projectName, recipeName])

  return isGetRecipeDetailsPending ? (
    <LoadingPage />
  ) : (
    <CreateRecipeDeploymentPage
      changeRecipePath={getRecipeMarketplacePagePath(organizationName, projectName)}
      currentOrganization={{ name: organizationName, displayName: organizationDisplayName ?? organizationName }}
      currentProject={{ name: projectName, displayName: currentProjectDisplayName ?? projectName }}
      isBillingInformationRequired={isBillingInformationRequired}
      isCreatingRecipeDeploymentPending={createRecipeDeploymentSubmitting}
      isLeftColumnOpen={isLeftColumnOpen}
      isRecipeInstancesQuotaExceeded={isRecipeInstancesQuotaExceeded}
      isRecipeQuotaExceeded={isRecipeQuotaExceeded}
      linkToRequestIncreaseForRecipeDeploymentInstancesQuotas={linkToRequestIncreaseForRecipeDeploymentInstancesQuotas}
      linkToRequestIncreaseForRecipeDeploymentQuotas={linkToRequestIncreaseForRecipeDeploymentQuotas}
      maxRecipeDeployments={maxRecipeDeployments}
      maxReplicaCount={replicaCountLimit}
      onCloseLeftDrawer={handleCloseLeftNavBar}
      onCreateRecipeDeployment={onCreateRecipeDeployment}
      onEnterBillingInformation={handleEnterBillingInformation}
      onUpdateReplicaCount={onUpdateReplicaCount}
      pendingReplicaCount={pendingReplicaCount}
      recipeCostPerHour={costPerHour}
      recipeDetailsPath={getRecipeDetailsPagePath(organizationName, projectName, recipeName)}
      recipeName={recipeName}
    />
  )
}
