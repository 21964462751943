import type { ContainerGroupStatus } from '@saladtechnologies/openapi-cloud-portal-browser'
import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Tag } from '../../Tag'
import { DeploymentStatusTag } from '../../block/DeploymentStatusTag/DeploymentStatusTag'
import { isWorkloadErrorPillVisible } from '../../deployments/WorkloadErrorsTable/utils'
import { ContainerGroupVersionTag } from '../ContainerGroupVersionTag/ContainerGroupVersionTag'
import { ContainerGroupsDeploymentTagsMessages } from '../messages'

export interface ContainerGroupsDeploymentTagsProps {
  /** The card current status. */
  currentStatus: ContainerGroupStatus
  /** The flag indicating the container group has failed to pull the image. */
  hasFailedToPullImage?: boolean
  /** The flag indicating the card is authentication required. */
  isAuthRequired?: boolean
  /** The container group replicas count. */
  replicas: number
  /** The count of running replicas for card. */
  replicasRunning: number
  /** The count of workload errors */
  workloadErrorCount?: number
  /** The container group version */
  version: number
}

export const ContainerGroupsDeploymentTags: FunctionComponent<ContainerGroupsDeploymentTagsProps> = ({
  currentStatus,
  hasFailedToPullImage,
  isAuthRequired,
  replicas,
  replicasRunning,
  version,
  workloadErrorCount,
}) => {
  const intl = useIntl()

  const showWorkloadErrorPill =
    workloadErrorCount !== undefined && isWorkloadErrorPillVisible(replicas, workloadErrorCount)

  return (
    <div className="flex gap-1">
      <DeploymentStatusTag hasFailedToPullImage={hasFailedToPullImage} status={currentStatus} />
      <Tag color="gray">
        <FormattedMessage
          defaultMessage="{replicas_running, number} / {replicas} {replicas_running, plural, one {Replica} other {Replicas}} Running"
          id="siDmXAvH"
          description="The label for the replicas."
          values={{
            replicas: replicas,
            replicas_running: replicasRunning,
          }}
          tagName="span"
        />
      </Tag>
      {showWorkloadErrorPill && (
        <Tag color="red">
          {intl.formatMessage(ContainerGroupsDeploymentTagsMessages.workloadErrorsLabel, {
            workload_total_errors: workloadErrorCount > 49 ? '50+' : workloadErrorCount,
          })}
        </Tag>
      )}
      <ContainerGroupVersionTag version={version} />
      {isAuthRequired !== undefined && (
        <Tag color={isAuthRequired ? 'blue' : 'white'}>
          <i className={classNames('mr-2', isAuthRequired ? 'fa-solid fa-lock' : 'fa-solid fa-unlock')} />
          <span>
            {isAuthRequired
              ? intl.formatMessage(ContainerGroupsDeploymentTagsMessages.authenticationRequired)
              : intl.formatMessage(ContainerGroupsDeploymentTagsMessages.open)}
          </span>
        </Tag>
      )}
    </div>
  )
}
