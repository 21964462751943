import type { FunctionComponent } from 'react'
import {
  type Control,
  type FieldValues,
  type UseFormClearErrors,
  type UseFormSetValue,
  type UseFormTrigger,
} from 'react-hook-form'
import { useIntl } from 'react-intl'
import { HealthProbeFieldsMessages } from '../../../messages'
import type { CreateContainerGroupFormSectionIdAttributes, CreateContainerGroupValues } from '../../../models'
import { CreateContainerGroupField } from '../../../models'
import { HealthProbeFields } from '../components'

interface ReadinessProbeProps {
  /** The react hook form method that clears errors for specified fields. */
  clearErrors: UseFormClearErrors<FieldValues>
  /** The control for the create container group react hook form. */
  control: Control<FieldValues, CreateContainerGroupValues>
  /** The id attribute for the fields. */
  id: CreateContainerGroupFormSectionIdAttributes
  /** The flag indicating whether the side panel is open. */
  isReadinessProbeSidePanelOpen?: boolean
  /** The function to set the side panel open state. */
  setReadinessProbeSidePanelOpen?: (isOpen: boolean) => void
  /** The react hook form method that provides the ability to set a specified field value. */
  setValue: UseFormSetValue<FieldValues>
  /** The react hook form method that triggers validation for specified fields. */
  trigger: UseFormTrigger<FieldValues>
}

export const ReadinessProbeFields: FunctionComponent<ReadinessProbeProps> = ({
  clearErrors,
  control,
  id,
  isReadinessProbeSidePanelOpen,
  setReadinessProbeSidePanelOpen,
  setValue,
  trigger,
}) => {
  const intl = useIntl()

  return (
    <HealthProbeFields
      clearErrors={clearErrors}
      commandArgumentsFieldName={CreateContainerGroupField.READINESS_PROBE_COMMAND_ARGUMENTS}
      commandFieldName={CreateContainerGroupField.READINESS_PROBE_COMMAND}
      control={control}
      enabledFieldName={CreateContainerGroupField.READINESS_PROBE_ENABLED}
      failureThresholdFieldName={CreateContainerGroupField.READINESS_PROBE_FAILURE_THRESHOLD}
      formID="readinessProbeFieldsForm"
      headersFieldName={CreateContainerGroupField.READINESS_PROBE_HEADERS}
      healthProbe="Readiness"
      id={id}
      initialDelaySecondsFieldName={CreateContainerGroupField.READINESS_PROBE_INITIAL_DELAY_SECONDS}
      isReadinessProbeSidePanelOpen={isReadinessProbeSidePanelOpen}
      pathFieldName={CreateContainerGroupField.READINESS_PROBE_PATH}
      periodSecondsFieldName={CreateContainerGroupField.READINESS_PROBE_PERIOD_SECONDS}
      portFieldName={CreateContainerGroupField.READINESS_PROBE_PORT}
      protocolFieldName={CreateContainerGroupField.READINESS_PROBE_PROTOCOL}
      serviceFieldName={CreateContainerGroupField.READINESS_PROBE_SERVICE}
      setReadinessProbeSidePanelOpen={setReadinessProbeSidePanelOpen}
      setValue={setValue}
      subtitle={intl.formatMessage(HealthProbeFieldsMessages.subTitle, { health_probe_type: 'Readiness' })}
      successThresholdFieldName={CreateContainerGroupField.READINESS_PROBE_SUCCESS_THRESHOLD}
      timeoutSecondsFieldName={CreateContainerGroupField.READINESS_PROBE_TIMEOUT_SECONDS}
      trigger={trigger}
    />
  )
}
