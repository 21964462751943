import type { ReactNode } from 'react'
import type { IntlShape } from 'react-intl'
import type { ToastNotificationType } from '../../models'
import { EditProjectSucceededContentMessages } from './messages'

/**
 * Retrieves the content needed for a success `ToastNotification` that is shown when the user edits their project
 * details.
 *
 * @param intl The `IntlShape` object needed to format the messages.
 * @param projectName The project name.
 * @param projectDisplayName The project display name.
 */
export const getEditProjectSucceededContent = (
  intl: IntlShape,
  projectName: string,
  projectDisplayName: string,
): {
  body: ReactNode
  type: ToastNotificationType
  title: string
} => {
  return {
    body: intl.formatMessage(EditProjectSucceededContentMessages.body, {
      project_name: projectName,
    }),
    type: 'success',
    title: intl.formatMessage(EditProjectSucceededContentMessages.title, {
      project_name: projectName,
      project_display_name: projectDisplayName,
    }),
  }
}
