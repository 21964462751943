import { defineMessages } from 'react-intl'

export const DeleteContainerGroupSucceededContentMessages = defineMessages({
  title: {
    defaultMessage: '{container_group_display_name} was deleted',
    id: 'kmPsZ8bU',
    description: 'The toast notification title shown in case of successfully deleted container group.',
  },
  body: {
    defaultMessage: 'Deleted container groups cannot be recovered',
    id: '/cKYk7S2',
    description:
      'The description shown in a toast notification message in case of successfully deleted container group.',
  },
})
