import type { ContainerGroup } from '@saladtechnologies/openapi-cloud-portal-browser'
import type { ContainerGroupDetailsList } from '../../components/containerGroups/models'
import type { GPUOptions, GpuClass } from '../../features/gpuClasses/models'

/**
 * Iterates over the container group list to properly identify and assign the correct GPU Names utilized by the
 * container group based on if an `ID` or `Name` was provided via the API.
 *
 * @param containerGroups The container groups
 * @param availableGpuClasses A list of all the available Gpu Classes
 */
export const getContainerGroupsListWithGpuClasses = (
  containerGroups: ContainerGroup[] | undefined,
  availableGpuClasses: GPUOptions,
): ContainerGroupDetailsList => {
  if (!containerGroups) {
    return []
  }

  const getGpuClassesByIdOrName = (containerGroup: ContainerGroup) => {
    let selectedGpuClasses: GpuClass[] = []

    containerGroup.container.resources.gpuClasses?.map((gpu) => {
      return availableGpuClasses?.map((gpuClass) => {
        return gpu === gpuClass.id || gpu === gpuClass.name ? selectedGpuClasses.push(gpuClass) : undefined
      })
    })
    return selectedGpuClasses
  }
  const containerGroupsCopy = [...containerGroups]

  return containerGroupsCopy.map((containerGroup) => {
    const gpuClasses = getGpuClassesByIdOrName(containerGroup)
    return {
      ...containerGroup,
      gpuClasses,
    }
  })
}
