import type { ReactNode } from 'react'
import type { IntlShape } from 'react-intl'
import type { ToastNotificationType } from '../../models'
import { ContainerGroupInstanceTerminalNodeNotScheduledErrorMessages } from './messages'

/**
 * Retrieves the content needed for an error `ToastNotification` that is shown when we are unable to connect to the
 * terminal because the node is not scheduled.
 *
 * @param intl The `IntlShape` object needed to format the messages.
 */
export const getContainerGroupInstanceTerminalNodeNotScheduledErrorMessages = (
  intl: IntlShape,
): {
  body: ReactNode
  type: ToastNotificationType
  title: string
} => {
  return {
    body: intl.formatMessage(ContainerGroupInstanceTerminalNodeNotScheduledErrorMessages.body),
    type: 'error',
    title: intl.formatMessage(ContainerGroupInstanceTerminalNodeNotScheduledErrorMessages.title),
  }
}
