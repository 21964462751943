import { defineMessages } from 'react-intl'

export const EmailNotSentContentMessage = defineMessages({
  body: {
    defaultMessage:
      'We were unable to send an email to {email}. Please ensure that your email address was entered correctly and try again. If this issue persists, {contact_support}.',

    id: '2dk/yR7J',
    description: 'The body for the email not sent error.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'pIQEUfA+',
    description: 'The text shown for the contact support link.',
  },
  title: {
    defaultMessage: 'Error Sending Email',
    id: 'KvzW1sLU',
    description: 'The title for the email not sent error notification.',
  },
})
