import { defineMessages } from 'react-intl'

export const ContainerGroupInstancesQuotaExceededMessages = defineMessages({
  body1: {
    defaultMessage: 'You have requested more replicas than your organizations allowed maximum.',
    id: 'tjdQqFjC',
    description:
      'The first section of the body for the modal that shows when a user has met their container group replicas quota.',
  },
  body2: {
    defaultMessage: 'Decrease the requested replica count or submit a request to increase your quota.',
    id: 'QXnzljpq',
    description:
      'The second section of the body for the modal that shows when a user has met their container group replica quota.',
  },
  cancelButtonLabel: {
    defaultMessage: 'Cancel',
    id: '+AsBL7zC',
    description:
      'The label for the cancel button on the modal that shows when a user has met their container group replica quota.',
  },
  requestIncreaseButtonLabel: {
    defaultMessage: 'Request Increase',
    id: '6JE2UXBE',
    description:
      'The label for the enter request increase button on the modal that shows when a user has met their container group replica quota.',
  },
  title: {
    defaultMessage: 'Container Replica Quota Exceeded',
    id: 'uu6WoR46',
    description: 'The title for the modal that shows when a organization has met their container group replica quota.',
  },
})
