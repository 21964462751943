import { defineMessages } from 'react-intl'

export const EditJobQueueGeneralErrorContentMessages = defineMessages({
  title: {
    defaultMessage: 'Error editing job queue',
    id: 'sZ87x/2Z',
    description: 'The toast notification title shown when we encounter an error on their request to save a job queue.',
  },
  body: {
    defaultMessage: 'We were unable to edit your job queue. Try again and if this issue persists {contact_support}',
    id: 'lJjIHwIn',
    description:
      'The description shown in a toast notification message shown when we encounter an error on their request to save a job queue.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'RINcPb45',
    description: 'The text shown for the contact support link in the edit job queue error toast notification.',
  },
})
