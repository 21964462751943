import type { FunctionComponent } from 'react'
import type { Control, FieldValues } from 'react-hook-form'
import { Controller, useFormState, useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { TextField } from '../../../../../../components/TextField'
import { ButtonLink } from '../../../../../../components/base'
import { FormStateCard } from '../../../../../../components/forms/FormStateCard'
import {
  DatadogInputFieldsMessages,
  LoggingInputFieldMessages,
  SelectExternalLoggingServiceMessages,
} from '../../../../messages'
import type { CreateContainerGroupValues } from '../../../../models'
import { CreateContainerGroupField } from '../../../../models'

interface DatadogInputFieldsProps {
  /** The control for the create container group react hook form. */
  control: Control<FieldValues, CreateContainerGroupValues>
  /** A callback that toggles the open state of the Datadog Tags side panel. */
  onSetIsDatadogTagsSidePanelShown: (isDatadogTagsSidePanelShown: boolean) => void
}

export const DatadogInputFields: FunctionComponent<DatadogInputFieldsProps> = ({
  control,
  onSetIsDatadogTagsSidePanelShown,
}) => {
  const intl = useIntl()
  const { errors } = useFormState({ control })
  const datadogTags = useWatch({ control, name: CreateContainerGroupField.DATADOG_TAGS })
  const datadogTagsSet = datadogTags && datadogTags.length > 0 && datadogTags[0].name !== ''

  return (
    <>
      <Controller
        name={CreateContainerGroupField.DATADOG_HOST}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            {...fieldState}
            defaultValue={field.value}
            helperText={fieldState.error?.message}
            label={intl.formatMessage(DatadogInputFieldsMessages.hostLabel)}
            type="text"
            isFullWidth
          />
        )}
      />
      <Controller
        name={CreateContainerGroupField.DATADOG_API}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            {...fieldState}
            defaultValue={field.value}
            helperText={fieldState.error?.message}
            label={intl.formatMessage(DatadogInputFieldsMessages.apiKeyLabel)}
            type="text"
            isFullWidth
          />
        )}
      />
      <div className="mb-10">
        <FormStateCard
          hasError={errors[CreateContainerGroupField.DATADOG_TAGS] !== undefined}
          onEditForm={() => onSetIsDatadogTagsSidePanelShown(true)}
          title={intl.formatMessage(DatadogInputFieldsMessages.tagsLabel)}
        >
          {datadogTagsSet ? (
            <p className="cursor-pointer text-blue-90 underline" onClick={() => onSetIsDatadogTagsSidePanelShown(true)}>
              {intl.formatMessage(DatadogInputFieldsMessages.viewDatadogTagsLinkText, {
                count: datadogTags.length,
              })}
            </p>
          ) : (
            <p>{intl.formatMessage(DatadogInputFieldsMessages.datadogTagsNotSetLabel)}</p>
          )}
        </FormStateCard>
      </div>

      <div>
        <p className="mb-2">
          {intl.formatMessage(LoggingInputFieldMessages.needHelpText, {
            external_logging_provider: intl.formatMessage(SelectExternalLoggingServiceMessages.datadogLabel),
          })}
        </p>
        <ButtonLink url="https://docs.salad.com/container-engine/external-logging/datadog" variant="blue-filled-light">
          {intl.formatMessage(LoggingInputFieldMessages.learnMoreText)}
        </ButtonLink>
      </div>
    </>
  )
}
