import { defineMessages } from 'react-intl'

export const DiskSpaceInputMessages = defineMessages({
  contactUsLinkText: {
    defaultMessage: 'Contact Us',
    id: 'xeeF/Sai',
    description: 'The linked text for the container group disk space input helper text field.',
  },
  helperText: {
    defaultMessage:
      'Your container does not count against this amount. Need more than 50GB of free space? {contact_us_link}.',
    id: 'fAKS8g20',
    description: 'The helper text for the container group disk space input field.',
  },
  helpScoutLinkText: {
    defaultMessage: 'contact support',
    id: 'Jzg3oZeg',
    description:
      'The text shown for the contact support link under the Disk Space input field on the create container group page.',
  },
  highDemandNotificationBody: {
    defaultMessage:
      'It may take longer than usual to allocate nodes that meet your RAM specifications. In rare cases, we may be unable to reach the desired replica count.',
    id: '6FRcKbdO',
    description: 'The description for the high demand disk space selected notification.',
  },
  highDemandNotificationTitle: {
    defaultMessage: `You’ve selected disk space that is in high demand`,
    id: 'DkojzTHk',
    description: 'The title for the high demand disk space selected notification.',
  },
  label: {
    defaultMessage: 'How much disk space would you like to allocate?',
    id: 'qJVSNHkS',
    description: 'The label for the container group disk space input field.',
  },
  unableToGetOptionsText: {
    defaultMessage: 'We were unable to load available Disk Space options. If this issue persists {contact_support}.',
    id: 'btsM9j1E',
    description:
      'The error message we show when we were unable to get disk space options for the create container group page.',
  },
})
