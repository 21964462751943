import { FunctionComponent, forwardRef, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { usePrevious } from '../../../hooks'
import { DatePicker } from '../DatePicker'
import { DateRangeTimes } from './components/DateRangeTimes'
import { DatePickerRangeMessages } from './messages'

export interface DatePickerRangeProps {
  /** The format for displaying the date within the input field. */
  dateFormat?: string
  /** The default end date to initialize the date picker with. */
  defaultEndDate?: Date | null
  /** The default start date to initialize the date picker with. */
  defaultStartDate?: Date | null
  /** A flag indicatiing if 100% of parent element width should be occupied */
  isFullWidth?: boolean
  /** Indicates whether to show error styling on the date picker. */
  hasError?: boolean
  /** Shows a dropdown to select the month directly. */
  hasMonthDropdown?: boolean
  /** Whether to show time selection options alongside date selection. */
  hasTimeSelect?: boolean
  /** Shows a dropdown to select the year directly. */
  hasYearDropdown?: boolean
  /** A unique identifier for the date picker range component. */
  id: string
  /** Whether the date picker input should display a clear button to remove the selected date. */
  isClearable?: boolean
  /** If true, disables interaction with the date picker. */
  isDisabled?: boolean
  /** The maximum selectable date. */
  maxDate?: Date
  /** The minimum selectable date. */
  minDate?: Date
  /** The number of calendar months to show at once. */
  monthsShown?: number
  /** Callback function called when either the start or end date changes. */
  onChange: (startDate: Date | null, endDate: Date | null) => void
  /** Adds a "Today" button to the calendar for selecting the current date. */
  todayButton?: string
}

export const DatePickerRange: FunctionComponent<DatePickerRangeProps> = forwardRef<
  HTMLDivElement,
  DatePickerRangeProps
>(
  (
    {
      dateFormat,
      defaultEndDate,
      defaultStartDate,
      hasError,
      hasMonthDropdown,
      hasTimeSelect,
      hasYearDropdown,
      id,
      isClearable,
      isDisabled,
      isFullWidth,
      maxDate,
      minDate,
      monthsShown,
      onChange,
      todayButton,
    },
    ref,
  ) => {
    const intl = useIntl()

    const [showCustomDatePicker, setShowCustomDatePicker] = useState(false)
    const [startDate, setStartDate] = useState<Date | null>(defaultStartDate ?? null)
    const [endDate, setEndDate] = useState<Date | null>(defaultEndDate ?? null)
    const [internalError, setInternalError] = useState<boolean>(false)
    const prevStartDate = usePrevious(startDate)
    const prevEndDate = usePrevious(endDate)

    useEffect(() => {
      if (startDate !== prevStartDate || endDate !== prevEndDate) {
        if (onChange) {
          onChange(startDate, endDate)
        }
      }
      const startAfterEnd = startDate && endDate && startDate > endDate
      const endBeforeStart = startDate && endDate && endDate < startDate
      setInternalError(!!(startAfterEnd || endBeforeStart))
    }, [startDate, endDate, prevStartDate, prevEndDate, onChange])

    const handleTimeRangeSelect = (newStartDate: Date | null, newEndDate: Date | null, showCustom: boolean) => {
      setStartDate(newStartDate)
      setEndDate(newEndDate)
      setShowCustomDatePicker(showCustom)
    }

    return (
      <div ref={ref} className="flex flex-col">
        <DateRangeTimes handleTimeRangeSelect={handleTimeRangeSelect} />
        {showCustomDatePicker && (
          <div className="flex gap-1">
            <DatePicker
              dateFormat={dateFormat}
              endDate={endDate}
              isFullWidth={isFullWidth}
              hasError={hasError || internalError}
              hasMonthDropdown={hasMonthDropdown}
              hasTimeSelect={hasTimeSelect}
              hasYearDropdown={hasYearDropdown}
              id={`${intl.formatMessage(DatePickerRangeMessages.startDateLabel)}-${id}`}
              isClearable={isClearable}
              isDisabled={isDisabled}
              isSelectEnd={false}
              isSelectStart={true}
              maxDate={maxDate}
              minDate={minDate}
              monthsShown={monthsShown}
              onChange={setStartDate}
              placeholderText={intl.formatMessage(DatePickerRangeMessages.startDateLabel)}
              startDate={startDate}
              todayButton={todayButton}
            />
            <DatePicker
              dateFormat={dateFormat}
              endDate={endDate}
              isFullWidth={isFullWidth}
              hasError={hasError || internalError}
              hasMonthDropdown={hasMonthDropdown}
              hasTimeSelect={hasTimeSelect}
              hasYearDropdown={hasYearDropdown}
              id={`${intl.formatMessage(DatePickerRangeMessages.endDateLabel)}-${id}`}
              isClearable={isClearable}
              isDisabled={isDisabled}
              isSelectEnd={true}
              isSelectStart={false}
              maxDate={maxDate}
              minDate={minDate}
              monthsShown={monthsShown}
              onChange={setEndDate}
              placeholderText={intl.formatMessage(DatePickerRangeMessages.endDateLabel)}
              startDate={startDate}
              todayButton={todayButton}
            />
          </div>
        )}
      </div>
    )
  },
)

DatePickerRange.defaultProps = {
  isFullWidth: false,
  defaultEndDate: null,
  defaultStartDate: null,
  hasError: false,
  hasMonthDropdown: false,
  hasTimeSelect: false,
  hasYearDropdown: false,
  isClearable: true,
  isDisabled: false,
  monthsShown: 1,
  todayButton: undefined,
}
