import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { EmptyResult } from '../../../../components/page/EmptyResult'
import { NoOrganizationsAvailableMessages } from '../../messages'

export const NoOrganizationsAvailable: FunctionComponent = () => {
  const intl = useIntl()
  return (
    <EmptyResult
      buttonIconClassName="fas fa-plus"
      buttonLabel={intl.formatMessage(NoOrganizationsAvailableMessages.createNewOrganizationButtonLabel)}
      mainMessage={intl.formatMessage(NoOrganizationsAvailableMessages.mainMessage)}
      buttonPath="/organizations/new"
      subMessage={intl.formatMessage(NoOrganizationsAvailableMessages.subMessage)}
    />
  )
}
