import { defineMessages } from 'react-intl'

export const RecipeDeploymentNoLongerAvailableToEditContentMessages = defineMessages({
  body: {
    defaultMessage: 'The recipe deployment you are trying to edit is no longer available.',
    id: '3qI6WYX2',
    description:
      'The description shown in a toast notification message shown when we encounter an error on their request to get the recipe deployment.',
  },
  title: {
    defaultMessage: 'Recipe deployment does not exist',
    id: 'U6/2PIaa',
    description:
      'The toast notification title shown when we encounter an error on their request to get the recipe deployment.',
  },
})
