import { defineMessages } from 'react-intl'

export const CreateRecipeDeploymentGeneralErrorContentMessages = defineMessages({
  body: {
    defaultMessage:
      'We were unable to create your recipe deployment. Try again and if this issue persists {contact_support}.',
    id: 'wAZK6iG5',
    description:
      'The description shown in a toast notification message shown when we encounter an error on their request to create a recipe deployment.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'q9gjFEoo',
    description:
      'The text shown for the contact support link in the create recipe deployment error toast notification.',
  },
  title: {
    defaultMessage: 'Error creating recipe deployment',
    id: 'sjHMabKO',
    description:
      'The toast notification title shown when we encounter an error on their request to create a recipe deployment.',
  },
})
