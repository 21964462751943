import { defineMessages } from 'react-intl'

export const RecipeDeploymentDetailsNotAvailableErrorContentMessages = defineMessages({
  body: {
    defaultMessage: 'The recipe deployment you are trying to view is no longer available.',
    id: 'e2vj/aEw',
    description:
      'The description shown in a toast notification message shown when we encounter an error on their request to get recipe deployment details.',
  },
  title: {
    defaultMessage: 'Recipe deployment does not exist',
    id: 'zUUplVYQ',
    description:
      'The toast notification title shown when we encounter an error on their request to to get recipe deployment details.',
  },
})
