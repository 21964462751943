import {
  ContainerLoggingHttpCompressionEnum,
  ContainerLoggingHttpFormatEnum,
} from '@saladtechnologies/openapi-cloud-portal-browser'
import type { FunctionComponent } from 'react'
import { useState } from 'react'
import type { Control, FieldValues, UseFormClearErrors } from 'react-hook-form'
import { Controller, useFormState, useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { TextField } from '../../../../../../components/TextField'
import { ButtonLink } from '../../../../../../components/base'
import { FormStateCard } from '../../../../../../components/forms/FormStateCard'
import { Select } from '../../../../../../components/forms/Select'
import {
  EditHTTPInputFieldsMessages,
  EditLoggingInputFieldMessages,
  EditSelectExternalLoggingServiceMessages,
} from '../../../../messages'
import type { EditContainerGroupValues } from '../../../../models'
import { EditContainerGroupField } from '../../../../models'

interface EditHTTPInputFieldsProps {
  /** The react hook form method that clears errors for specified fields. */
  clearErrors: UseFormClearErrors<FieldValues>
  /** The control for the create container group react hook form. */
  control: Control<FieldValues, EditContainerGroupValues>
  /** A callback that toggles the open state of the HTTP Headers side panel. */
  onSetIsHTTPHeadersSidePanelShown: (isHTTPHeadersSidePanelShown: boolean) => void
}

export const EditHTTPInputFields: FunctionComponent<EditHTTPInputFieldsProps> = ({
  clearErrors,
  control,
  onSetIsHTTPHeadersSidePanelShown,
}) => {
  const intl = useIntl()
  const { errors } = useFormState({ control })
  const [localUserValue, setLocalUserValue] = useState('')
  const [localPasswordValue, setLocalPasswordValue] = useState('')
  const httpHeaders = useWatch({ control, name: EditContainerGroupField.HTTP_HEADERS })
  const httpHeadersSet = httpHeaders && httpHeaders.length > 0 && httpHeaders[0].name !== ''

  const handleClearUserAndPasswordErrors = () => {
    clearErrors(EditContainerGroupField.HTTP_USER)
    clearErrors(EditContainerGroupField.HTTP_PASSWORD)
  }

  return (
    <>
      <Controller
        name={EditContainerGroupField.HTTP_HOST}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            {...fieldState}
            defaultValue={field.value}
            helperText={fieldState.error?.message}
            label={intl.formatMessage(EditHTTPInputFieldsMessages.hostLabel)}
            type="text"
            isFullWidth
          />
        )}
      />
      <Controller
        name={EditContainerGroupField.HTTP_PORT}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            {...fieldState}
            defaultValue={field.value}
            helperText={intl.formatMessage(EditHTTPInputFieldsMessages.portHelperText)}
            label={intl.formatMessage(EditHTTPInputFieldsMessages.portLabel)}
            type="number"
            isFullWidth
          />
        )}
      />
      <div className="mb-12">
        <Controller
          name={EditContainerGroupField.HTTP_FORMAT}
          control={control}
          render={({ field, fieldState }) => (
            <Select
              {...field}
              {...fieldState}
              defaultSelectedValue={field.value}
              isFullWidth
              labelText={intl.formatMessage(EditHTTPInputFieldsMessages.formatLabel)}
              onChange={(value) => {
                field.onChange(value)
              }}
              isPlaceholderOptionDisabled
              options={[
                {
                  label: intl.formatMessage(EditHTTPInputFieldsMessages.jsonFormatOptionLabel),
                  value: ContainerLoggingHttpFormatEnum.Json,
                },
                {
                  label: intl.formatMessage(EditHTTPInputFieldsMessages.jsonLinesFormatOptionLabel),
                  value: ContainerLoggingHttpFormatEnum.JsonLines,
                },
              ]}
            />
          )}
        />
      </div>

      <div className="mb-12">
        <Controller
          name={EditContainerGroupField.HTTP_COMPRESSION}
          control={control}
          render={({ field, fieldState }) => (
            <Select
              {...field}
              {...fieldState}
              defaultSelectedValue={field.value}
              isFullWidth
              labelText={intl.formatMessage(EditHTTPInputFieldsMessages.compressionLabel)}
              onChange={(value) => {
                field.onChange(value)
              }}
              isPlaceholderOptionDisabled
              options={[
                {
                  label: intl.formatMessage(EditHTTPInputFieldsMessages.compressionGzipOptionLabel),
                  value: ContainerLoggingHttpCompressionEnum.Gzip,
                },
                {
                  label: intl.formatMessage(EditHTTPInputFieldsMessages.compressionNoneOptionLabel),
                  value: ContainerLoggingHttpCompressionEnum.None,
                },
              ]}
            />
          )}
        />
      </div>
      <Controller
        name={EditContainerGroupField.HTTP_PATH}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            {...fieldState}
            defaultValue={field.value}
            helperText={intl.formatMessage(EditHTTPInputFieldsMessages.pathHelperText)}
            label={intl.formatMessage(EditHTTPInputFieldsMessages.pathOptionalLabel)}
            type="text"
            isFullWidth
          />
        )}
      />
      <Controller
        name={EditContainerGroupField.HTTP_USER}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            {...fieldState}
            defaultValue={field.value}
            helperText={fieldState.error?.message}
            label={intl.formatMessage(EditHTTPInputFieldsMessages.userOptionalLabel)}
            onChange={(value) => {
              field.onChange(value === '' ? undefined : value)
              setLocalUserValue(value as string)
              if (value === '' && localPasswordValue === '') {
                handleClearUserAndPasswordErrors()
              }
            }}
            type="text"
            isFullWidth
          />
        )}
      />
      <Controller
        name={EditContainerGroupField.HTTP_PASSWORD}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            {...fieldState}
            defaultValue={field.value}
            helperText={fieldState.error?.message}
            label={intl.formatMessage(EditHTTPInputFieldsMessages.passwordOptionalLabel)}
            onChange={(value) => {
              field.onChange(value === '' ? undefined : value)
              setLocalPasswordValue(value as string)
              if (value === '' && localUserValue === '') {
                handleClearUserAndPasswordErrors()
              }
            }}
            type="text"
            isFullWidth
          />
        )}
      />
      <div className="mb-16">
        <FormStateCard
          hasError={errors[EditContainerGroupField.HTTP_HEADERS] !== undefined}
          onEditForm={() => onSetIsHTTPHeadersSidePanelShown(true)}
          title={intl.formatMessage(EditHTTPInputFieldsMessages.headersOptionalLabel)}
        >
          {httpHeadersSet ? (
            <p className="cursor-pointer text-blue-90 underline" onClick={() => onSetIsHTTPHeadersSidePanelShown(true)}>
              {intl.formatMessage(EditHTTPInputFieldsMessages.viewHTTPHeadersLinkText, {
                count: httpHeaders.length,
              })}
            </p>
          ) : (
            <p>{intl.formatMessage(EditHTTPInputFieldsMessages.httpHeadersNotSetLabel)}</p>
          )}
        </FormStateCard>
      </div>
      <div className="mb-16">
        <p className="mb-2">
          {intl.formatMessage(EditLoggingInputFieldMessages.needHelpText, {
            external_logging_provider: intl.formatMessage(EditSelectExternalLoggingServiceMessages.httpLabel),
          })}
        </p>
        <ButtonLink url="https://docs.salad.com/container-engine/external-logging/http" variant="blue-filled-light">
          {intl.formatMessage(EditLoggingInputFieldMessages.learnMoreText)}
        </ButtonLink>
      </div>
    </>
  )
}
