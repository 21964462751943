import { defineMessages } from 'react-intl'

export const EditAzureInputFieldsMessages = defineMessages({
  needHelpText: {
    defaultMessage: 'Need help with setting up on Azure?',
    id: '36caMwbf',
    description: 'The help text shown below the Azure input fields.',
  },
  passwordLabel: {
    defaultMessage: 'Password',
    id: 'MBf1oNgS',
    description: 'The label for the Azure password input field.',
  },
  passwordPlaceholderText: {
    defaultMessage: 'Your Password',
    id: 'wGL+0em7',
    description: 'The placeholder text for the Azure password input field.',
  },
  passwordRequiredText: {
    defaultMessage: 'Password is required',
    id: 'nGHoxj8l',
    description: 'The required text for the Azure password input field.',
  },
  usernameLabel: {
    defaultMessage: 'Username',
    id: '0FoVS3eQ',
    description: 'The label for the Azure username input field.',
  },
  usernameHelperText: {
    defaultMessage: 'Service Principal, Access Token, Or Registry Admin',
    id: '4t+OvKMx',
    description: 'The helper text for the Azure username input field.',
  },
  usernamePlaceholderText: {
    defaultMessage: 'Your Username',
    id: 'DjdWuBA4',
    description: 'The placeholder text for the Azure username input field.',
  },
  usernameRequiredText: {
    defaultMessage: 'Username is required',
    id: '+2Wcbibb',
    description: 'The required text for the Azure username input field.',
  },
})
