import type { FunctionComponent } from 'react'
import { useCallback, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { LoadingPage } from '../../components/page/LoadingPage'
import { trackMixpanelPageViewedEvent } from '../../features/analytics/analyticsSlice'
import { getTeamMemberPageData, inviteTeamMember } from '../../features/inviteTeamMember/inviteTeamMemberSlice'
import { selectIsLeftColumnShowing } from '../../features/navigationBar/navigationBarSelectors'
import { setLeftColumnShowing } from '../../features/navigationBar/navigationBarSlice'
import { selectOrganizationDisplayName } from '../../features/organizations/organizationsSelectors'
import { selectProjects } from '../../features/projects/projectsSelectors'
import {
  selectRequestStatusIsPending,
  selectRequestStatusIsSucceeded,
} from '../../features/requestStatus/requestStatusSelectors'
import { inviteTeamMemberPageRoutePath } from '../../routes/routePaths'
import { getContainerGroupsPagePath, getOrganizationTeamPagePath } from '../../routes/routes-utils'
import { useAppDispatch, useAppSelector } from '../../store'
import { InviteTeamMemberPage } from './InviteTeamMemberPage'

export const ConnectedInviteTeamMemberPage: FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { organizationName = '' } = useParams()

  const isGetInviteTeamMemberPageDataPending = useAppSelector((state) =>
    selectRequestStatusIsPending(state, 'getInviteTeamMemberPageData'),
  )
  const isLeftColumnOpen = useAppSelector(selectIsLeftColumnShowing)
  const isInviteTeamMemberPending = useAppSelector((state) => selectRequestStatusIsPending(state, 'inviteTeamMember'))
  const isInviteTeamMemberSuccessful = useAppSelector((state) =>
    selectRequestStatusIsSucceeded(state, 'inviteTeamMember'),
  )
  const projects = useAppSelector((state) => selectProjects(state, organizationName))?.projects || []
  const organizationDisplayName = useAppSelector((state) => selectOrganizationDisplayName(state, organizationName))
  const teamPagePath = getOrganizationTeamPagePath(organizationName)

  const projectSelectOptions = projects.map((project) => {
    return {
      ...project,
      selected: false,
    }
  })

  const handleProjectChange = useCallback(
    (projectName: string) => {
      navigate(getContainerGroupsPagePath(organizationName, projectName))
    },
    [navigate, organizationName],
  )

  const handleCloseLeftNavBar = useCallback(() => {
    dispatch(setLeftColumnShowing({ showing: false }))
  }, [dispatch])

  const onInviteTeamMember = useCallback(
    (email: string) => {
      dispatch(inviteTeamMember({ organizationName, email }))
    },
    [dispatch, organizationName],
  )

  useEffect(() => {
    dispatch(getTeamMemberPageData({ organizationName }))
    dispatch(trackMixpanelPageViewedEvent({ path: inviteTeamMemberPageRoutePath, organizationName }))
  }, [dispatch, organizationName])

  return isGetInviteTeamMemberPageDataPending ? (
    <LoadingPage />
  ) : (
    <InviteTeamMemberPage
      currentOrganization={{ name: organizationName, displayName: organizationDisplayName ?? organizationName }}
      isInviteTeamMemberPending={isInviteTeamMemberPending}
      isInviteTeamMemberSuccessful={isInviteTeamMemberSuccessful}
      isLeftColumnOpen={isLeftColumnOpen}
      onCloseLeftDrawer={handleCloseLeftNavBar}
      onInviteTeamMember={(email) => onInviteTeamMember(email)}
      onProjectChange={handleProjectChange}
      projects={projectSelectOptions}
      teamPagePath={teamPagePath}
    />
  )
}
