import classNames from 'classnames'
import { useState, type FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { Spinner } from '../../../../components/Spinner'
import { BillingCreditsMessages } from '../../messages'

export interface BillingCreditsProps {
  /** The credit dashboard embed url. */
  billingCreditEmbedUrl: string | undefined
  /** The organization display name. */
  organizationDisplayName: string
}

export const BillingCredits: FunctionComponent<BillingCreditsProps> = ({
  billingCreditEmbedUrl,
  organizationDisplayName,
}) => {
  const intl = useIntl()
  const [isIframeLoaded, setIsIframeLoaded] = useState(false)
  const isShowingLoadingSpinner = billingCreditEmbedUrl === undefined || !isIframeLoaded

  const handleSetIframeLoaded = () => {
    setTimeout(() => setIsIframeLoaded(true), 700)
  }

  return (
    <>
      {isShowingLoadingSpinner && (
        <div className="flex size-full flex-col items-center pt-12">
          <Spinner size="lg" />
        </div>
      )}

      <iframe
        className={classNames('mt-5 aspect-video h-full w-full', {
          hidden: isShowingLoadingSpinner,
        })}
        onLoad={handleSetIframeLoaded}
        src={billingCreditEmbedUrl}
        title={intl.formatMessage(BillingCreditsMessages.creditIFrameTitle, {
          organization_display_name: organizationDisplayName,
        })}
      />
    </>
  )
}
