import type { FunctionComponent } from 'react'
import { useCallback, useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { LoadingPage } from '../../components/page/LoadingPage'
import { trackMixpanelPageViewedEvent } from '../../features/analytics/analyticsSlice'
import { selectIsLeftColumnShowing } from '../../features/navigationBar/navigationBarSelectors'
import { setLeftColumnShowing } from '../../features/navigationBar/navigationBarSlice'
import { selectOrganizationDisplayName } from '../../features/organizations/organizationsSelectors'
import { selectProjectDisplayName, selectProjects } from '../../features/projects/projectsSelectors'
import { deleteProject } from '../../features/projects/projectsSlice'
import { selectCostPerHour, selectRecipe } from '../../features/recipeDetails/recipeDetailsSelectors'
import { getRecipeDetails } from '../../features/recipeDetails/recipeDetailsSlice'
import { selectRequestStatus, selectRequestStatusIsPending } from '../../features/requestStatus/requestStatusSelectors'
import { recipeDetailsPageRoutePath } from '../../routes/routePaths'
import {
  getCreateRecipeDeploymentPagePath,
  getRecipeDeploymentsPagePath,
  getRecipeMarketplacePagePath,
} from '../../routes/routes-utils'
import { useAppDispatch, useAppSelector } from '../../store'
import { RecipeDetailsPage } from './RecipeDetailsPage'

export const ConnectedRecipeDetailsPage: FunctionComponent = () => {
  const { organizationName = '', projectName = '', recipeDeploymentName = '', recipeName = '' } = useParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const costPerHour = useAppSelector(selectCostPerHour)
  const isGetRecipeDetailsPending = useAppSelector((state) => selectRequestStatusIsPending(state, 'getRecipeDetails'))
  const recipe = useAppSelector(selectRecipe)
  const deleteProjectRequestStatus = useAppSelector((state) => selectRequestStatus(state, 'deleteProject'))
  const isLeftColumnOpen = useAppSelector(selectIsLeftColumnShowing)
  const organizationDisplayName = useAppSelector((state) => selectOrganizationDisplayName(state, organizationName))
  const projects = useAppSelector((state) => selectProjects(state, organizationName))?.projects || []
  const currentProjectDisplayName = useAppSelector((state) =>
    selectProjectDisplayName(state, organizationName, projectName),
  )

  const projectSelectOptions = projects.map((project) => {
    return {
      ...project,
      selected: project.name === projectName,
    }
  })

  const readme = useMemo(() => {
    if (recipe === undefined) {
      return ''
    } else {
      return recipe.readme.replaceAll('{{ salad_recipe_dns }}', recipe.networking?.dns ?? 'example.salad.cloud')
    }
  }, [recipe])

  const handleCloseLeftNavBar = useCallback(() => {
    dispatch(setLeftColumnShowing({ showing: false }))
  }, [dispatch])

  const handleDeployRecipe = () => {
    navigate(getCreateRecipeDeploymentPagePath(organizationName, projectName, recipeName))
  }

  const handleProjectChange = useCallback(
    (projectName: string) => {
      navigate(getRecipeDeploymentsPagePath(organizationName, projectName))
    },
    [navigate, organizationName],
  )

  const handleDeleteProject = useCallback(
    (projectName: string) => {
      dispatch(deleteProject({ organizationName, projectName }))
    },
    [dispatch, organizationName],
  )

  useEffect(() => {
    dispatch(getRecipeDetails({ organizationName, projectName, recipeName }))
    dispatch(
      trackMixpanelPageViewedEvent({
        path: recipeDetailsPageRoutePath,
        organizationName,
        projectName,
        resourceName: recipeDeploymentName,
        recipeName,
      }),
    )
  }, [dispatch, organizationName, projectName, recipeDeploymentName, recipeName])

  return isGetRecipeDetailsPending ? (
    <LoadingPage />
  ) : (
    <RecipeDetailsPage
      currentOrganization={{ name: organizationName, displayName: organizationDisplayName ?? organizationName }}
      currentProject={{ name: projectName, displayName: currentProjectDisplayName ?? projectName }}
      isDeleteProjectPending={deleteProjectRequestStatus === 'pending'}
      isDeleteProjectSuccessful={deleteProjectRequestStatus === 'succeeded'}
      isLeftColumnOpen={isLeftColumnOpen}
      onCloseLeftDrawer={handleCloseLeftNavBar}
      onDeleteProject={handleDeleteProject}
      onDeployRecipe={handleDeployRecipe}
      onProjectChange={handleProjectChange}
      projects={projectSelectOptions}
      recipeCostPerHour={costPerHour}
      recipeMarkdown={readme}
      recipeMarketplacePath={getRecipeMarketplacePagePath(organizationName, projectName)}
      recipeName={recipeDeploymentName}
    />
  )
}
