import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import type { DeploymentStatus } from '../../../../../../features/containerGroupInstanceTable/models'
import { ProgressPill } from '../../../../../block/ProgressPill'
import { DeploymentInstanceStatusMessages } from '../../../../messages'

interface DownloadingStatusProps {
  /** The deployment status */
  deploymentStatus: DeploymentStatus
  /** The callback executed when the user clicks the status */
  onClick: () => void
  /** The percentage */
  percentage: number | undefined
}

export const DownloadingStatus: FunctionComponent<DownloadingStatusProps> = ({
  deploymentStatus,
  onClick,
  percentage,
}) => {
  const intl = useIntl()
  const downloadingComplete = deploymentStatus === 'running' || deploymentStatus === 'creating'
  const isActive = deploymentStatus === 'downloading'

  return (
    <ProgressPill
      icon={<i className={classNames('fa-solid fa-download')} />}
      label={intl.formatMessage(DeploymentInstanceStatusMessages.downloading)}
      onClick={onClick}
      percentage={downloadingComplete ? 100 : isActive ? percentage : undefined}
      width={170}
    />
  )
}
