import { defineMessages } from 'react-intl'

export const ContainerGroupsAllocatedResourcesMessages = defineMessages({
  allocatedResourcesLabel: {
    defaultMessage: 'Allocated Resources',
    id: '4pLl4MGW',
    description: 'The label for the allocated resources.',
  },
  cpu: {
    defaultMessage: '{cpu} vCPU',
    id: '/nbIJL47',
    description: 'The label for the CPU pill.',
  },
  genericGpuLabel: {
    defaultMessage: 'GPU Class',
    id: 'HuIX7/yD',
    description: 'The label for the GPU pill.',
  },
  gpuLabel: {
    defaultMessage: 'GPU',
    id: 'OEiNVe5v',
    description: 'The label for the GPU pill.',
  },
  highDemand: {
    defaultMessage: 'High Demand',
    id: 'tPNLzP7h',
    description: 'The label for the high demand card.',
  },
  memory: {
    defaultMessage: '{memory} GB',
    id: '85x30AjI',
    description: 'The label for the memory pill.',
  },
  multipleGpuClassesLabel: {
    defaultMessage: 'Multiple GPU Classes',
    id: 'u1KJd2iv',
    description: 'The label for the multiple GPU classes pill.',
  },
  noMemoryImageAlt: {
    defaultMessage: 'Memory Image',
    id: 'oHsq5Yrx',
    description: 'The alt text for the memory image.',
  },
  noCPUImageAlt: {
    defaultMessage: 'CPU Image',
    id: 'YYYVlv+K',
    description: 'The alt text for the CPU image.',
  },
  noGPUImageAlt: {
    defaultMessage: 'GPU Image',
    id: 'JCX/gEIk',
    description: 'The alt text for the GPU image.',
  },
  storageLabel: {
    defaultMessage: '{storage} GB',
    id: 'kdUzrNcF',
    description: 'The label for the storage card.',
  },
})
