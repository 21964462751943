import { useMemo } from 'react'
import type { ObjectSchema } from 'yup'
import { object, string } from 'yup'
import type { DeleteAccountValues } from './DeleteAccountModal'

export const useDeleteAccountFormValidation = (): ObjectSchema<DeleteAccountValues> => {
  const validationScheme = useMemo(
    () =>
      object({
        password: string().required(),
      }),
    [],
  )
  return validationScheme
}
