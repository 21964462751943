import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import { useEffect, useRef, useState } from 'react'

export type VerticalProgressBarStepStatus = 'incomplete' | 'current' | 'completed'
export interface VerticalProgressBarStep {
  label: string
  status: VerticalProgressBarStepStatus
}
export interface VerticalProgressBarProps {
  /** Vertical Progress Bar Steps to be displayed */
  steps: VerticalProgressBarStep[]
}

export const VerticalProgressBar: FunctionComponent<VerticalProgressBarProps> = ({ steps }) => {
  const containerElementRef = useRef<HTMLDivElement | null>(null)
  const currentStepLabelElementRef = useRef<HTMLSpanElement | null>(null)
  const [progressLineHeight, setProgressLineHeight] = useState(0)

  const completedStepsAmount = steps.filter((step) => step.status === 'completed').length
  const areAllStepsCompleted = completedStepsAmount === steps.length

  useEffect(() => {
    if (containerElementRef.current && currentStepLabelElementRef.current) {
      const currentStepLabelBottomPosition =
        currentStepLabelElementRef.current.getBoundingClientRect().bottom -
        containerElementRef.current.getBoundingClientRect().top

      const isCurrentStepIsTheFirst = steps[0]?.status === 'current'

      const shiftPositionForTheFirstStep = 8
      const progressLinePosition = isCurrentStepIsTheFirst
        ? currentStepLabelBottomPosition + shiftPositionForTheFirstStep
        : currentStepLabelBottomPosition

      setProgressLineHeight(progressLinePosition)
    }
  }, [steps])

  return (
    <div ref={containerElementRef} className="relative flex h-full flex-row">
      {/* ProgressLine */}
      <div className="pr-8">
        <div className="relative size-full">
          <div className="absolute left-0 top-0 h-full w-2 rounded-full bg-neutral-30 shadow-inner" />
          <div
            style={{
              height: areAllStepsCompleted ? '100%' : `${progressLineHeight}px`,
            }}
            className="absolute left-0 top-0 h-full w-2 rounded-full bg-gradient-to-tr from-green-70 to-green-40"
          />
        </div>
      </div>
      {/* StepLabels */}
      <div className="flex h-full flex-col justify-between">
        {steps.map((step) => {
          const isCompletedStep = step.status === 'completed'
          const isCurrentStep = step.status === 'current'
          const isIncompleteStep = step.status === 'incomplete'

          return (
            <span
              ref={isCurrentStep ? currentStepLabelElementRef : null}
              key={step.label}
              className={classNames('text-sm', {
                'text-neutral-100': isCompletedStep,
                'font-medium text-neutral-100': isCurrentStep,
                'text-neutral-70': isIncompleteStep,
              })}
            >
              {step.label}
            </span>
          )
        })}
      </div>
    </div>
  )
}
