import type { FunctionComponent } from 'react'
import { useCallback, useEffect } from 'react'
import { trackMixpanelPageViewedEvent } from '../../features/analytics/analyticsSlice'
import { selectIsChangePasswordRequestLoading } from '../../features/changePassword/changePasswordSelectors'
import { changePassword } from '../../features/changePassword/changePasswordSlice'
import { selectIsLeftColumnShowing } from '../../features/navigationBar/navigationBarSelectors'
import { setLeftColumnShowing } from '../../features/navigationBar/navigationBarSlice'
import { selectProfileEmail } from '../../features/profile/profileSelectors'
import { changePasswordPageRoutePath } from '../../routes/routePaths'
import { useAppDispatch, useAppSelector } from '../../store'
import { ChangePasswordPage } from './ChangePasswordPage'

export const ConnectedChangePasswordPage: FunctionComponent = () => {
  const dispatch = useAppDispatch()

  const isChangePasswordPending = useAppSelector(selectIsChangePasswordRequestLoading)
  const email = useAppSelector(selectProfileEmail)
  const isLeftColumnOpen = useAppSelector(selectIsLeftColumnShowing)

  const onChangePassword = (currentPassword: string, newPassword: string) => {
    dispatch(changePassword({ currentPassword, newPassword }))
  }

  const handleCloseLeftNavBar = useCallback(() => {
    dispatch(setLeftColumnShowing({ showing: false }))
  }, [dispatch])

  useEffect(() => {
    dispatch(trackMixpanelPageViewedEvent({ path: changePasswordPageRoutePath }))
  }, [dispatch])

  return (
    <ChangePasswordPage
      isChangePasswordPending={isChangePasswordPending}
      onChangePassword={onChangePassword}
      onCloseLeftDrawer={handleCloseLeftNavBar}
      isLeftColumnOpen={isLeftColumnOpen}
      email={email as string}
    />
  )
}
