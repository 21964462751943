import {
  EMPTY,
  catchError,
  concat,
  defer,
  delay,
  filter,
  from,
  mergeMap,
  of,
  repeat,
  switchMap,
  takeUntil,
  timer,
  withLatestFrom,
} from 'rxjs'
import { InferenceEndpointsAPI } from '../apiMethods'
import { trackMixpanelEvent } from '../features/analytics/analyticsSlice'
import { selectLatestInferenceEndpointJob } from '../features/inferenceEndpointJobs/inferenceEndpointJobsSelectors'
import {
  createInferenceEndpointJob,
  inferenceEndpointJobAdded,
  startPollingInferenceEndpointJob,
  stopPollingInferenceEndpointJob,
} from '../features/inferenceEndpointJobs/inferenceEndpointJobsSlice'
import { configuresInferenceEndpointJobsEntityId } from '../features/inferenceEndpointJobs/utils'
import { showToastNotification } from '../features/notifications/notificationsSlice'
import { setRequestStatus } from '../features/requestStatus/requestStatusSlice'
import { getInferenceEndpointDetailsGeneralErrorContent } from '../notifications/clientToastNotificationContent/inferenceEndpoints'
import {
  hasInferenceEndpointJobReachedFinalStatus,
  isInferenceEndpointJobTerminal,
} from '../pages/InferenceEndpointDetails/utils'
import type { AppEpic } from '../store'

export const onCreateInferenceEndpointJob: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(createInferenceEndpointJob.match),
    mergeMap(({ payload: { createInferenceEndpointJob, inferenceEndpointName, organizationName } }) =>
      concat(
        of(setRequestStatus({ request: 'createInferenceEndpointJob', status: 'pending' })),
        from(
          InferenceEndpointsAPI.createInferenceEndpointJob({
            organizationName,
            inferenceEndpointName,
            createInferenceEndpointJob,
          }),
        ).pipe(
          mergeMap((inferenceEndpointJob) =>
            concat(
              of(
                setRequestStatus({ request: 'createInferenceEndpointJob', status: 'succeeded' }),
                inferenceEndpointJobAdded({ inferenceEndpointJob, inferenceEndpointName, organizationName }),
                startPollingInferenceEndpointJob({
                  organizationName,
                  inferenceEndpointName,
                  inferenceEndpointJobId: inferenceEndpointJob.id,
                }),
                trackMixpanelEvent({
                  event: 'Job Created',
                  properties: {
                    organizationName: organizationName,
                    inferenceEndpointName: inferenceEndpointName,
                    jobId: inferenceEndpointJob.id,
                    resource: 'Inference Endpoint',
                    source: 'SIE Playground',
                  },
                }),
              ),
              of(setRequestStatus({ request: 'createInferenceEndpointJob', status: 'idle' })).pipe(delay(1)),
            ),
          ),
          catchError(() =>
            concat(
              of(
                setRequestStatus({ request: 'createInferenceEndpointJob', status: 'failed' }),
                showToastNotification(getInferenceEndpointDetailsGeneralErrorContent(intl)),
              ),
              of(setRequestStatus({ request: 'createInferenceEndpointJob', status: 'idle' })).pipe(delay(1)),
            ),
          ),
        ),
      ),
    ),
  )

export const onPollInferenceEndpointJob: AppEpic = (action$, state$, { intl }) =>
  action$.pipe(
    filter(startPollingInferenceEndpointJob.match),
    switchMap(({ payload: { inferenceEndpointJobId, inferenceEndpointName, organizationName } }) =>
      defer(() =>
        from(
          InferenceEndpointsAPI.getInferenceEndpointJob({
            organizationName,
            inferenceEndpointName,
            inferenceEndpointJobId,
          }),
        ),
      ).pipe(
        repeat({
          delay: () => {
            const twentySecondsInMS = 20000
            return timer(twentySecondsInMS)
          },
        }),
        withLatestFrom(state$),
        mergeMap(([inferenceEndpointJob, state]) => {
          return selectLatestInferenceEndpointJob(
            state,
            configuresInferenceEndpointJobsEntityId(organizationName, inferenceEndpointName),
          )?.status !== inferenceEndpointJob.status
            ? concat(
                of(
                  setRequestStatus({ request: 'getInferenceEndpointJob', status: 'succeeded' }),
                  inferenceEndpointJobAdded({ inferenceEndpointJob, inferenceEndpointName, organizationName }),
                  setRequestStatus({ request: 'getInferenceEndpointJob', status: 'idle' }),
                ).pipe(delay(1)),
                hasInferenceEndpointJobReachedFinalStatus(inferenceEndpointJob.status)
                  ? concat(
                      isInferenceEndpointJobTerminal(inferenceEndpointJob.status)
                        ? of(
                            showToastNotification(getInferenceEndpointDetailsGeneralErrorContent(intl)),
                            stopPollingInferenceEndpointJob(),
                          )
                        : EMPTY,
                      of(stopPollingInferenceEndpointJob()),
                    )
                  : EMPTY,
              )
            : EMPTY
        }),
        catchError(() =>
          concat(
            of(
              setRequestStatus({ request: 'getInferenceEndpointJob', status: 'failed' }),
              showToastNotification(getInferenceEndpointDetailsGeneralErrorContent(intl)),
            ),
            of(setRequestStatus({ request: 'getInferenceEndpointJob', status: 'idle' })).pipe(delay(1)),
          ),
        ),
        takeUntil(action$.pipe(filter(stopPollingInferenceEndpointJob.match))),
      ),
    ),
  )
