import type { FunctionComponent } from 'react'
import { LeftNavigationBar } from '../../components/LeftNavigationBar'
import { ThreeColumnLayout } from '../../layouts/threeColumnLayout'
import { APIKeyPageMainContent } from './components/APIKeyPageMainContent'

export interface APIKeyPageProps {
  /** The API Key. */
  apiKey: string
  /** The flag indicating that the request to change the API key is pending. */
  isChangeAPIKeyRequestPending: boolean
  /** The flag indicating that the request to change the API key was successful. */
  isChangeAPIKeyRequestSuccessful?: boolean
  /** The flag indicating if the left column is showing */
  isLeftColumnOpen: boolean
  /** The callback executed when the user clicks on the Change API Key button. */
  onChangeAPIKey: () => void
  /** The callback executed when the user closes the left drawer */
  onCloseLeftDrawer: () => void
}

export const APIKeyPage: FunctionComponent<APIKeyPageProps> = ({
  apiKey,
  isChangeAPIKeyRequestPending,
  isChangeAPIKeyRequestSuccessful,
  isLeftColumnOpen,
  onChangeAPIKey,
  onCloseLeftDrawer,
}) => {
  return (
    <ThreeColumnLayout
      isLeftColumnOpen={isLeftColumnOpen}
      LeftColumn={<LeftNavigationBar />}
      MainColumn={
        <APIKeyPageMainContent
          apiKey={apiKey}
          isChangeAPIKeyRequestPending={isChangeAPIKeyRequestPending}
          isChangeAPIKeyRequestSuccessful={isChangeAPIKeyRequestSuccessful}
          onChangeAPIKey={onChangeAPIKey}
        />
      }
      onCloseLeftDrawer={onCloseLeftDrawer}
    />
  )
}
