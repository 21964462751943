import { catchError, concat, delay, filter, forkJoin, from, mergeMap, of, switchMap } from 'rxjs'
import { OrganizationsAPI, ProjectsAPI, QueuesAPI } from '../apiMethods'
import { getJobQueues, getJobQueuesPageData, jobQueuesReceived } from '../features/jobQueues/jobQueuesSlice'
import { showToastNotification } from '../features/notifications/notificationsSlice'
import { organizationAdded } from '../features/organizations/organizationsSlice'
import { projectsAddedToOrganization } from '../features/projects/projectsSlice'
import { setRequestStatus } from '../features/requestStatus/requestStatusSlice'
import { getJobQueuesErrorContent } from '../notifications/clientToastNotificationContent/jobQueues'
import type { AppEpic } from '../store'

export const onGetJobQueuesPageData: AppEpic = (action$, _state$, { intl }) => {
  return action$.pipe(
    filter(getJobQueuesPageData.match),
    switchMap((action) =>
      concat(
        of(setRequestStatus({ request: 'getJobQueuesPageData', status: 'pending' })),
        forkJoin([
          QueuesAPI.listQueues({
            organizationName: action.payload.organizationName,
            projectName: action.payload.projectName,
          }),
          OrganizationsAPI.getOrganization({
            organizationName: action.payload.organizationName,
          }),
          ProjectsAPI.listProjects({
            organizationName: action.payload.organizationName,
          }),
        ]).pipe(
          switchMap(([jobQueuesResponse, organizationResponse, projectsResponse]) => {
            return concat(
              of(
                jobQueuesReceived({
                  organizationName: action.payload.organizationName,
                  projectName: action.payload.projectName,
                  jobQueues: jobQueuesResponse.items,
                }),
                organizationAdded(organizationResponse),
                projectsAddedToOrganization({
                  organizationName: organizationResponse.name,
                  projects: projectsResponse.items,
                }),
                setRequestStatus({ request: 'getJobQueuesPageData', status: 'succeeded' }),
              ),
              of(setRequestStatus({ request: 'getJobQueuesPageData', status: 'idle' })).pipe(delay(1)),
            )
          }),
          catchError(() =>
            concat(
              of(
                setRequestStatus({ request: 'getJobQueuesPageData', status: 'failed' }),
                showToastNotification(getJobQueuesErrorContent(intl)),
              ),
              of(setRequestStatus({ request: 'getJobQueuesPageData', status: 'idle' })).pipe(delay(1)),
            ),
          ),
        ),
      ),
    ),
  )
}

export const onGetJobQueues: AppEpic = (action$, _state$) =>
  action$.pipe(
    filter(getJobQueues.match),
    mergeMap((action) =>
      concat(
        of(setRequestStatus({ request: 'getJobQueues', status: 'pending' })),
        from(
          QueuesAPI.listQueues({
            organizationName: action.payload.organizationName,
            projectName: action.payload.projectName,
          }),
        ).pipe(
          mergeMap((response) => {
            return concat(
              of(
                jobQueuesReceived({
                  organizationName: action.payload.organizationName,
                  projectName: action.payload.projectName,
                  jobQueues: response.items,
                }),
                setRequestStatus({ request: 'getJobQueues', status: 'succeeded' }),
              ),
              of(setRequestStatus({ request: 'getJobQueues', status: 'idle' })).pipe(delay(1)),
            )
          }),
          catchError(() =>
            concat(
              of(setRequestStatus({ request: 'getJobQueues', status: 'failed' })),
              of(setRequestStatus({ request: 'getJobQueues', status: 'idle' })).pipe(delay(1)),
            ),
          ),
        ),
      ),
    ),
  )
