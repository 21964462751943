import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { Organization } from '@saladtechnologies/openapi-cloud-portal-browser'
import { RequestStatus } from '../../models'
import type { TeamPageMemberCard, TeamPageMemberCardList } from '../../pages/Team/models/TeamMemberCardList'
import type { OrganizationMembersAndInvitationsResponse } from './models/OrganizationMembersAndInvitationsResponse'

export interface OrganizationSettingsState {
  organizationMembersAndInvitations: TeamPageMemberCardList
  organization: Organization | undefined
}

const initialState: OrganizationSettingsState = {
  organizationMembersAndInvitations: [],
  organization: undefined,
}

export const teamSlice = createSlice({
  name: 'organizationSettings',
  initialState,
  reducers: {
    getOrganizationMembersAndInvitations(_state, _action: PayloadAction<{ organizationName: string }>) {},
    removeMemberFromOrganization(
      _state,
      _action: PayloadAction<{ organizationName: string; memberId: string; isCurrentMember?: boolean; email: string }>,
    ) {},
    resendInvitation(
      _state,
      _action: PayloadAction<{ organizationName: string; invitationId: string; email: string }>,
    ) {},
    revokeInvitation(
      _state,
      _action: PayloadAction<{ organizationName: string; invitationId: string; email: string }>,
    ) {},
    setOrganizationMembersAndInvitations(
      state,
      action: PayloadAction<{
        organizationWithMembersAndInvitations: OrganizationMembersAndInvitationsResponse
        currentUserEmail: string | undefined
      }>,
    ) {
      const { invitations, members } = action.payload.organizationWithMembersAndInvitations
      const membersArray = members.map((member) => {
        const organizationSettingMember: TeamPageMemberCard = {
          id: member.id,
          email: member.email,
          invitation: undefined,
          isRemoveMemberPending: false,
          isResendInvitationPending: false,
          isRevokeInvitationPending: false,
          joinedDate: member.createTime,
        }
        return organizationSettingMember
      })
      const invitationsArray = invitations.map((invitation) => {
        const organizationSettingMember: TeamPageMemberCard = {
          id: invitation.id,
          email: invitation.email,
          invitation: { date: invitation.createTime, id: invitation.id },
          isRemoveMemberPending: false,
          isResendInvitationPending: false,
          isRevokeInvitationPending: false,
        }
        return organizationSettingMember
      })
      state.organization = action.payload.organizationWithMembersAndInvitations.organization
      state.organizationMembersAndInvitations = [...membersArray, ...invitationsArray]
    },
    setRemoveMemberFromOrganizationRequestStatus(
      state,
      action: PayloadAction<{ memberId: string; status: RequestStatus }>,
    ) {
      if (action.payload.status === RequestStatus.Succeeded) {
        const memberListAfterRemoval = state.organizationMembersAndInvitations.filter(
          (member) => member.id !== action.payload.memberId,
        )
        state.organizationMembersAndInvitations = memberListAfterRemoval
      } else {
        state.organizationMembersAndInvitations = state.organizationMembersAndInvitations.map((member) => {
          if (member.id === action.payload.memberId) {
            member.isRemoveMemberPending = action.payload.status === RequestStatus.Loading
          }

          return member
        })
      }
    },
    setResendInvitationRequestStatus(state, action: PayloadAction<{ invitationId: string; status: RequestStatus }>) {
      state.organizationMembersAndInvitations = state.organizationMembersAndInvitations.map((member) => {
        if (member.invitation !== undefined && member.invitation.id === action.payload.invitationId) {
          member.isResendInvitationPending = action.payload.status === RequestStatus.Loading
        }

        return member
      })
    },
    setRevokeInvitationRequestStatus(state, action: PayloadAction<{ invitationId: string; status: RequestStatus }>) {
      if (action.payload.status === RequestStatus.Succeeded) {
        const memberListWithoutRevokedUser = state.organizationMembersAndInvitations.filter((member) => {
          if (member.invitation && member.invitation.id === action.payload.invitationId) {
            return false
          } else {
            return true
          }
        })

        state.organizationMembersAndInvitations = memberListWithoutRevokedUser
      } else {
        state.organizationMembersAndInvitations = state.organizationMembersAndInvitations.map((member) => {
          if (member.invitation !== undefined && member.invitation.id === action.payload.invitationId) {
            member.isRevokeInvitationPending = action.payload.status === RequestStatus.Loading
          }

          return member
        })
      }
    },
  },
})

export const {
  getOrganizationMembersAndInvitations,
  removeMemberFromOrganization,
  resendInvitation,
  revokeInvitation,
  setOrganizationMembersAndInvitations,
  setRemoveMemberFromOrganizationRequestStatus,
  setResendInvitationRequestStatus,
  setRevokeInvitationRequestStatus,
} = teamSlice.actions
