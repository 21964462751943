import type { ReactNode } from 'react'
import type { IntlShape } from 'react-intl'
import type { ToastNotificationType } from '../../models'
import { AddPaymentMethodGenericValidationErrorMessages } from './messages'

/**
 * Retrieves the content needed for an error `ToastNotification` that is shown when the user encounters the validation
 * error when attempting to add new payment method.
 *
 * @param intl The `IntlShape` object needed to format the messages.
 */
export const getAddNewPaymentMethodGenericValidationError = (
  intl: IntlShape,
): {
  body: ReactNode
  type: ToastNotificationType
  title: string
} => {
  return {
    body: intl.formatMessage(AddPaymentMethodGenericValidationErrorMessages.body),
    type: 'error',
    title: intl.formatMessage(AddPaymentMethodGenericValidationErrorMessages.title),
  }
}
