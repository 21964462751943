import type { FunctionComponent } from 'react'
import { LeftNavigationBar } from '../../components/LeftNavigationBar'
import { ThreeColumnLayout } from '../../layouts/threeColumnLayout'
import { ChangePasswordMainContent } from './components/ChangePasswordMainContent'

export interface ChangePasswordPageProps {
  /** The user's email address. */
  email: string
  /** The flag indicating that the request to change the password is pending. */
  isChangePasswordPending: boolean
  /** The flag indicating if the left column is showing */
  isLeftColumnOpen: boolean
  /** A callback that when executed changes the user's password. */
  onChangePassword: (currentPassword: string, newPassword: string) => void
  /** The callback executed when the user closes the left drawer */
  onCloseLeftDrawer: () => void
}

export const ChangePasswordPage: FunctionComponent<ChangePasswordPageProps> = ({
  email,
  isChangePasswordPending,
  isLeftColumnOpen,
  onChangePassword,
  onCloseLeftDrawer,
}) => {
  return (
    <ThreeColumnLayout
      isLeftColumnOpen={isLeftColumnOpen}
      LeftColumn={<LeftNavigationBar />}
      MainColumn={
        <ChangePasswordMainContent
          email={email}
          isChangePasswordPending={isChangePasswordPending}
          onChangePassword={onChangePassword}
        />
      }
      onCloseLeftDrawer={onCloseLeftDrawer}
    />
  )
}
