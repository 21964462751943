import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import { DeploymentStatus } from '../../../../features/containerGroupInstanceTable/models'
import { Tag } from '../../../Tag'
import { ProgressPill } from '../../../block/ProgressPill'

interface DeploymentInstanceTableReadinessRowItemProps {
  /** Function to navigate to the instance details page */
  onNavigateToInstanceDetailsPage: (machineId: string, event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  /** The container group details page path */
  instanceDetailsPagePath?: string
  /** Indicates if the deployment status is 'running' */
  isDeploymentInstanceRunning: (status: DeploymentStatus) => boolean
  /** Indicates if the deployment instance is ready */
  isReady: boolean
  /** The unique identifier for the machine, if available */
  machineId?: string
  /** The deployment instance status */
  status: DeploymentStatus
}

export const DeploymentInstanceTableReadinessRowItem: FunctionComponent<
  DeploymentInstanceTableReadinessRowItemProps
> = ({
  instanceDetailsPagePath,
  isDeploymentInstanceRunning,
  isReady,
  machineId,
  onNavigateToInstanceDetailsPage,
  status,
}) => {
  return (
    <div
      className={classNames('flex justify-center', {
        'cursor-pointer': status === 'running' && instanceDetailsPagePath,
      })}
      onClick={
        isDeploymentInstanceRunning(status) && instanceDetailsPagePath && machineId
          ? () => onNavigateToInstanceDetailsPage(machineId)
          : undefined
      }
    >
      {isReady ? (
        <ProgressPill label="" icon="" percentage={100} />
      ) : (
        <Tag color="red">
          <i className={classNames('fa-solid fa-x')} />
        </Tag>
      )}
    </div>
  )
}
