import { defineMessages } from 'react-intl'

export const OutboundRequestsMessages = defineMessages({
  outboundRequestsTitle: {
    defaultMessage: 'Outbound Requests',
    id: '9LhHb4u6',
    description: 'The title for the outbound requests section.',
  },
  outboundRequestsDescription: {
    defaultMessage: `Send requests to services, websites or API's running outside of Salad.`,
    id: 'oFofjPaM',
    description: 'The description for the outbound requests section.',
  },
  outboundRequestsCardText: {
    defaultMessage: `Always Included for Free`,
    id: 'UXlc/7/y',
    description: 'The text for the outbound requests card.',
  },
})
