import { defineMessages } from 'react-intl'

export const BillingInformationNotReadyErrorMessages = defineMessages({
  body: {
    defaultMessage:
      'We’re still setting up your organization, so your billing information is not quite ready yet. If this issue persists {contact_support}.',
    id: '2YmQd9L4',
    description:
      'The description shown in a toast notification message shown when we encounter an error loading their billing information before it has been setup.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'oJhkIUEY',
    description:
      'The text shown for the contact support link in the billing information not ready error toast notification.',
  },
  title: {
    defaultMessage: 'Give us a moment',
    id: 'HG2/7DAX',
    description:
      'The toast notification title shown when we encounter an error loading their billing information before it has been setup.',
  },
})
