import { defineMessages } from 'react-intl'

export const EditContainerGroupNoLongerAvailableErrorContentMessages = defineMessages({
  title: {
    defaultMessage: 'Container group does not exist',
    id: 'XzkkBFhm',
    description:
      'The toast notification title shown when we encounter the no longer available error on their request to view edit a container group page.',
  },
  body: {
    defaultMessage:
      'The Container Group you wanted to edit is no longer available. Please select another Container Group.',
    id: 'foOTJRay',
    description:
      'The description shown in a toast notification message shown when we encounter the no longer available error on their request to view edit a container group page.',
  },
})
