import { InferenceEndpoint, InferenceEndpointJob } from '@saladtechnologies/openapi-cloud-portal-browser'
import type { FunctionComponent } from 'react'
import { LeftNavigationBar } from '../../components/LeftNavigationBar'
import { ProjectSelectOptions } from '../../components/LeftNavigationBar/models'
import { ThreeColumnLayout } from '../../layouts/threeColumnLayout'
import { InferenceEndpointDetailsMainContent } from './components/InferenceEndpointDetailsMainContent.tsx/InferenceEndpointDetailsMainContent'
import { InferenceEndpointPlaygroundFormValues } from './models'

export interface InferenceEndpointDetailsPageProps {
  /** The current organization that the user is in. */
  currentOrganization: { name: string; displayName: string }
  /**
   * The current inference endpoint that the user is viewing. This is used to display the details of the inference
   * endpoint.
   */
  inferenceEndpoint: InferenceEndpoint
  /** The inference endpoint job associated with the inference endpoint. */
  inferenceEndpointJob?: InferenceEndpointJob
  /** The path to the Inference Endpoint Marketplace */
  inferenceEndpointMarketplacePath: string
  /** The flag indicating if the create inference endpoint job is pending. */
  isCreateInferenceEndpointJobPending: boolean
  /** The flag indicating if the left column is showing. */
  isLeftColumnOpen: boolean
  /** The callback executed when the user closes the left drawer */
  onCloseLeftDrawer: () => void
  /** The handler updating the project */
  onProjectChange: (projectName: string) => void
  /** The callback executed when the user submits the playground form. */
  onCreateInferenceEndpointJob: (values: InferenceEndpointPlaygroundFormValues) => void
  /** An array of the current available projects. */
  projects: ProjectSelectOptions
}

export const InferenceEndpointDetailsPage: FunctionComponent<InferenceEndpointDetailsPageProps> = ({
  currentOrganization,
  inferenceEndpoint,
  inferenceEndpointJob,
  inferenceEndpointMarketplacePath,
  isCreateInferenceEndpointJobPending,
  isLeftColumnOpen,
  onCloseLeftDrawer,
  onCreateInferenceEndpointJob,
  onProjectChange,
  projects,
}) => {
  return (
    <ThreeColumnLayout
      isLeftColumnOpen={isLeftColumnOpen}
      LeftColumn={
        <LeftNavigationBar
          currentOrganization={currentOrganization}
          isProjectSelectPlaceholderOptionSelectable
          onProjectChange={onProjectChange}
          projects={projects}
        />
      }
      onCloseLeftDrawer={onCloseLeftDrawer}
      MainColumn={
        <InferenceEndpointDetailsMainContent
          inferenceEndpoint={inferenceEndpoint}
          inferenceEndpointJob={inferenceEndpointJob}
          inferenceEndpointMarketplacePath={inferenceEndpointMarketplacePath}
          isCreateInferenceEndpointJobPending={isCreateInferenceEndpointJobPending}
          onCreateInferenceEndpointJob={onCreateInferenceEndpointJob}
        />
      }
    />
  )
}
