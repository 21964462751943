import { defineMessages } from 'react-intl'

export const DeleteRecipeDeploymentModalMessages = defineMessages({
  cancelButtonLabel: {
    defaultMessage: 'Cancel',
    id: 'lqsMhF/7',
    description:
      'The label for the Cancel button on the modal that shows when a user wants to delete a recipe deployment.',
  },
  description: {
    defaultMessage: "Deleting recipe deployments are permanent. Are you sure you'd like to delete this deployment?",
    id: '56kkjy6s',
    description: 'The description for the modal that shows when a user wants to delete a recipe deployment.',
  },
  title: {
    defaultMessage: 'Delete {recipe_deployment_display_name}',
    id: 't4OzavKa',
    description: 'The title for the modal that shows when a user wants to delete a recipe deployment.',
  },
})
