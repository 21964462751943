import { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { Button } from '../../../../components/Button'
import { Modal } from '../../../../components/block/Modal'
import { MonitoringConfirmationModalMessages } from '../../messages'

interface MonitoringConfirmationModalProps {
  /** The text to explaining what readiness probe type they are enabling */
  monitoringTypeText: string
  /** The callback executed when the user closes the modal. */
  onClose: () => void
  /** The callback executed when the user clicks the `Cancel` button. */
  onOpenReadinessProbe: () => void
  /** The callback executed when the user clicks the `Continue` button. */
  onContinue: (event: React.SyntheticEvent<Element, Event>) => void
}

export const MonitoringConfirmationModal: FunctionComponent<MonitoringConfirmationModalProps> = ({
  monitoringTypeText,
  onClose,
  onContinue,
  onOpenReadinessProbe,
}) => {
  const intl = useIntl()

  return (
    <Modal customModalTailwindSizeClasses="max-w-lg max-h-full" onClose={onClose} title="Enable Readiness Probe">
      <h1 className="mb-4 font-bold">
        {intl.formatMessage(MonitoringConfirmationModalMessages.monitoringTitle, {
          monitoring_type_text: monitoringTypeText,
        })}
      </h1>
      <h3 className="mb-4">{intl.formatMessage(MonitoringConfirmationModalMessages.confirmationText)}</h3>
      <div className="flex justify-end space-x-4">
        <Button variant="green-filled-light" onClick={onContinue}>
          {intl.formatMessage(MonitoringConfirmationModalMessages.continueButton)}
        </Button>
        <Button variant="green-filled" onClick={onOpenReadinessProbe}>
          {intl.formatMessage(MonitoringConfirmationModalMessages.enableButton)}
        </Button>
      </div>
    </Modal>
  )
}
