import type { ContainerLogging, HttpHeadersInner } from '@saladtechnologies/openapi-cloud-portal-browser'
import { ExternalLoggingService } from './../models'

export const getFormattedHeaderValues = (headers: HttpHeadersInner[]): string => {
  return headers
    .map((header) => {
      return `${header.name}:${header.value}`
    })
    .join(', ')
}

export const determineExternalLoggingService = (loggingService: ContainerLogging | null | undefined) => {
  if (loggingService?.axiom) {
    return ExternalLoggingService.AXIOM
  }
  if (loggingService?.datadog) {
    return ExternalLoggingService.DATADOG
  }
  if (loggingService?.http) {
    return ExternalLoggingService.HTTP
  }
  if (loggingService?.newRelic) {
    return ExternalLoggingService.NEW_RELIC
  }
  if (loggingService?.splunk) {
    return ExternalLoggingService.SPLUNK
  }
  if (loggingService?.tcp) {
    return ExternalLoggingService.TCP
  }
  return undefined
}
