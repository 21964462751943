import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import type { ButtonVariant } from '../../Button/Button'
import { buttonVariantStyles, getButtonTextColor } from '../../Button/Button'

interface ButtonLinkProps {
  /** The aria label for the button link. */
  ariaLabel?: React.AriaAttributes['aria-label']
  /** The label for the button link. */
  children?: React.ReactNode
  /** A Font Awesome icon class name (e.g. `fa-envelope`) that will show to the left of the button link label. */
  iconClassName?: string
  /** A value indicating whether the padding is compact. */
  isCompact?: boolean
  /**
   * A flag indicating whether the padding the link is disabled, will make the route just direct to # and apply disabled
   * styles. TODO: Not sure if this is the best solution long term, this is a quick fix for launch
   */
  isDisabled?: boolean
  /** The flag indicating if the Text Field should occupy all available width provided by the parent element. */
  isFullWidth?: boolean
  /** The button click handler. */
  onClick?: () => void
  /** The aria role for the button link. */
  role?: React.AriaRole | undefined
  /** The path for the page that exists in the react router app or the external url. */
  url: string
  /** A value indicating which styles to apply to the button link. */
  variant?: ButtonVariant
}

export const ButtonLink: FunctionComponent<ButtonLinkProps> = ({
  ariaLabel,
  children,
  iconClassName,
  isCompact,
  isDisabled,
  isFullWidth,
  onClick,
  role,
  url,
  variant,
}) => {
  const buttonVariant = variant ?? 'green-filled'
  const isExternalUrl = url.startsWith('/') === false

  const buttonLinkClassNames = classNames(
    'flex max-w-fit items-center justify-center rounded-sm p-2 text-center',
    `hover:${getButtonTextColor(buttonVariant)}`,
    buttonVariantStyles[buttonVariant],
    {
      'w-full max-w-none': isFullWidth,
      'h-8 px-2': isCompact,
      'px-3': !isCompact,
      'cursor-default opacity-50': isDisabled,
    },
  )

  const iconClassNames = classNames(iconClassName, {
    'mr-2': children,
  })

  const childrenClassNames = classNames({
    'text-xs ': isCompact,
    'text-base': !isCompact,
    underline: variant?.includes('text'),
  })

  return isExternalUrl && !isDisabled ? (
    <a
      aria-label={ariaLabel}
      className={buttonLinkClassNames}
      href={url}
      onClick={onClick}
      rel="noreferrer"
      role={role}
      target="_blank"
    >
      {iconClassName && <div className={iconClassNames} />}
      {children && <div className={childrenClassNames}>{children}</div>}
    </a>
  ) : (
    <Link
      aria-label={ariaLabel}
      onClick={onClick}
      to={isDisabled ? '#' : url}
      role={role}
      className={buttonLinkClassNames}
    >
      {iconClassName && <div className={iconClassNames} />}
      {children && <div className={childrenClassNames}>{children}</div>}
    </Link>
  )
}

// TODO: look at what this is
ButtonLink.defaultProps = {
  children: undefined,
  iconClassName: undefined,
  isCompact: false,
  isFullWidth: false,
  role: undefined,
  variant: 'green-filled',
}
