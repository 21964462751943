import type { FunctionComponent, ReactNode } from 'react'

interface ToastBodyProps {
  /** The content shown below the title of the toast notification. */
  body: string | ReactNode
  /** The title for the toast notification. */
  title: string
}

/** The component that handles the display for the content shown in the Toast Notification. */
export const ToastBody: FunctionComponent<ToastBodyProps> = ({ body, title }) => (
  <div>
    <p className="font-bold text-neutral-100">{title}</p>
    {typeof body === 'string' ? (
      <p className="text-sm text-neutral-70">{body}</p>
    ) : (
      <div className="text-sm text-neutral-70">{body}</div>
    )}
  </div>
)
