import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { RequestStatus } from '../../models'

export interface CreateNewProjectState {
  requestStatus: RequestStatus
}

const initialState: CreateNewProjectState = {
  requestStatus: RequestStatus.Idle,
}

export const createNewProjectSlice = createSlice({
  name: 'createNewProject',
  initialState,
  reducers: {
    createNewProject(_state, _action: PayloadAction<{ organizationName: string; projectName: string }>) {},
    setCreateNewProjectRequestStatus(state, action: PayloadAction<{ status: RequestStatus }>) {
      state.requestStatus = action.payload.status
    },
  },
})

export const { createNewProject, setCreateNewProjectRequestStatus } = createNewProjectSlice.actions
