import { defineMessages } from 'react-intl'

export const UnableToRetrieveContainerGroupDetailsContentMessages = defineMessages({
  body: {
    defaultMessage:
      'We were unable to retrieve your container group details. If this issue persists {contact_support}.',
    id: 'e6jBHM5f',
    description:
      'The description shown in a toast notification message shown when we encounter an error retireving container group details.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'f0jExgAU',
    description:
      'The text shown for the contact support link in the unable to load container group details error toast notification.',
  },
  title: {
    defaultMessage: 'Unable to retrieve container group details',
    id: 'FYwdah9N',
    description: 'The toast notification title shown when we encounter an error retireving container group details.',
  },
})
