import type { FieldErrors, FieldValues } from 'react-hook-form'
import { EditContainerGroupField } from '../../models'

export const checkIfEditImageSourceFieldsHaveErrors = (errors: FieldErrors<FieldValues>): boolean => {
  return (
    (errors[EditContainerGroupField.IMAGE_SOURCE] ||
      errors[EditContainerGroupField.DOCKER_HUB_USERNAME] ||
      errors[EditContainerGroupField.DOCKER_HUB_PERSONAL_ACCESS_TOKEN] ||
      errors[EditContainerGroupField.GIT_HUB_PERSONAL_ACCESS_TOKEN] ||
      errors[EditContainerGroupField.GIT_HUB_USERNAME] ||
      errors[EditContainerGroupField.QUAY_PASSWORD] ||
      errors[EditContainerGroupField.QUAY_USERNAME] ||
      errors[EditContainerGroupField.AWS_ELASTIC_ACCESS_KEY_ID] ||
      errors[EditContainerGroupField.AWS_ELASTIC_SECRET_ACCESS_KEY] ||
      errors[EditContainerGroupField.SELF_HOSTED_PASSWORD] ||
      errors[EditContainerGroupField.SELF_HOSTED_USERNAME] ||
      errors[EditContainerGroupField.AZURE_PASSWORD] ||
      errors[EditContainerGroupField.AZURE_USERNAME] ||
      errors[EditContainerGroupField.GOOGLE_ARTIFACT_JSON_KEY] ||
      errors[EditContainerGroupField.GOOGLE_CONTAINER_JSON_KEY]) !== undefined
  )
}

export const editImageSourceFieldsList: string[] = [
  EditContainerGroupField.AWS_ELASTIC_ACCESS_KEY_ID,
  EditContainerGroupField.AWS_ELASTIC_SECRET_ACCESS_KEY,
  EditContainerGroupField.DOCKER_HUB_PERSONAL_ACCESS_TOKEN,
  EditContainerGroupField.DOCKER_HUB_USERNAME,
  EditContainerGroupField.GIT_HUB_PERSONAL_ACCESS_TOKEN,
  EditContainerGroupField.GIT_HUB_USERNAME,
  EditContainerGroupField.IMAGE_SOURCE,
  EditContainerGroupField.SELF_HOSTED_PASSWORD,
  EditContainerGroupField.SELF_HOSTED_USERNAME,
  EditContainerGroupField.QUAY_PASSWORD,
  EditContainerGroupField.QUAY_USERNAME,
  EditContainerGroupField.AZURE_PASSWORD,
  EditContainerGroupField.AZURE_USERNAME,
  EditContainerGroupField.GOOGLE_ARTIFACT_JSON_KEY,
  EditContainerGroupField.GOOGLE_CONTAINER_JSON_KEY,
]

const imageSourceFieldsListCopy = editImageSourceFieldsList.slice()
const indexOfImageSource = imageSourceFieldsListCopy.indexOf(EditContainerGroupField.IMAGE_SOURCE)
imageSourceFieldsListCopy.splice(indexOfImageSource, 1)

export const privateRegistryFieldsList: string[] = imageSourceFieldsListCopy
