import type { FunctionComponent } from 'react'
import { Control, FieldValues, UseFormClearErrors } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Link } from '../../../../components/base'
import { ExternalLoggingService } from '../../../../components/containerGroups/models'
import { SelectExternalLoggingServiceMessages } from '../../messages'
import { CreateContainerGroupValues } from '../../models'
import { AxiomInputFields } from './components/Axiom'
import { DatadogInputFields } from './components/Datadog'
import { HTTPInputFields } from './components/HTTP'
import { NewRelicInputFields } from './components/NewRelicInputFields'
import { SplunkInputFields } from './components/Splunk'
import { TCPInputFields } from './components/TCP'

interface LoggingFieldsInputFormsProps {
  /** The react hook form method that clears errors for specified fields. */
  clearErrors: UseFormClearErrors<FieldValues>
  /** The control for the create container group react hook form. */
  control: Control<FieldValues, CreateContainerGroupValues>
  /** The external logging service for the create container group. */
  externalLoggingService?: ExternalLoggingService
  /**
   * A callback that when executed records a mixpanel event for the element that was clicked.
   *
   * @param label The label of the element that was clicked
   */
  onRecordMixpanelElementClickedEvent: (label: string) => void
  /** A callback that toggles the open state of the Datadog Tags side panel. */
  onSetIsDatadogTagsSidePanelShown: (isDatadogTagsSidePanelShown: boolean) => void
  /** A callback that toggles the open state of the HTTP Headers side panel. */
  onSetIsHTTPHeadersSidePanelShown: (isHTTPHeadersSidePanelShown: boolean) => void
}

export const LoggingFieldsInputForms: FunctionComponent<LoggingFieldsInputFormsProps> = ({
  clearErrors,
  control,
  externalLoggingService,
  onRecordMixpanelElementClickedEvent,
  onSetIsDatadogTagsSidePanelShown,
  onSetIsHTTPHeadersSidePanelShown,
}) => {
  const intl = useIntl()

  const handleAxiomDocsLinkClick = () => {
    onRecordMixpanelElementClickedEvent(
      SelectExternalLoggingServiceMessages.axiomMixpanelEventClickLabel.defaultMessage,
    )
  }

  switch (externalLoggingService) {
    case ExternalLoggingService.NEW_RELIC: {
      return <NewRelicInputFields control={control} />
    }
    case ExternalLoggingService.SPLUNK: {
      return <SplunkInputFields control={control} />
    }
    case ExternalLoggingService.TCP: {
      return <TCPInputFields control={control} />
    }
    case ExternalLoggingService.DATADOG: {
      return (
        <DatadogInputFields control={control} onSetIsDatadogTagsSidePanelShown={onSetIsDatadogTagsSidePanelShown} />
      )
    }
    case ExternalLoggingService.HTTP: {
      return (
        <HTTPInputFields
          clearErrors={clearErrors}
          control={control}
          onSetIsHTTPHeadersSidePanelShown={onSetIsHTTPHeadersSidePanelShown}
        />
      )
    }
    case ExternalLoggingService.AXIOM: {
      return <AxiomInputFields control={control} />
    }
    default: {
      return (
        <div className="mb-2">
          <p>{intl.formatMessage(SelectExternalLoggingServiceMessages.noExternalLoggingServiceSelectedPt1)}</p>
          <p>
            {intl.formatMessage(SelectExternalLoggingServiceMessages.noExternalLoggingServiceSelectedPt2, {
              axiom_link: (
                <Link id="axiom_docs" url="https://axiom.co" onClick={handleAxiomDocsLinkClick}>
                  {intl.formatMessage(SelectExternalLoggingServiceMessages.signUpWithAxiom)}
                </Link>
              ),
            })}
          </p>
        </div>
      )
    }
  }
}
