import { defineMessages } from 'react-intl'

export const CreateContainerGroupHostInvalidErrorContentMessages = defineMessages({
  body: {
    defaultMessage: 'Please make sure the host name you have provided is a valid DNS name.',
    id: 'qnhIl2Z5',
    description:
      'This is the body of the toast notification that is displayed when a user tries to create a container group with an invalid host name.',
  },
  title: {
    defaultMessage: 'Invalid Host Name',
    id: '4/E15Xyi',
    description:
      'This is the title of the toast notification that is displayed when a user tries to create a container group with an invalid host name.',
  },
})
