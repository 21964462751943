import type { ReactNode } from 'react'
import type { IntlShape } from 'react-intl'
import type { InstanceActionType } from '../../../features/containerGroupInstances/models'
import type { ToastNotificationType } from '../../models'
import { UnableToExecuteActionToContainerGroupInstanceNodeNotExistErrorMessages } from './messages'

/**
 * Retrieves the content needed for an error `ToastNotification` that is shown when the user encounters the instance
 * node not exist error when attempting to execute action with container instance.
 *
 * @param intl The `IntlShape` object needed to format the messages.
 */
export const getUnableToExecuteActionToContainerGroupInstanceNodeNotExistErrorContent = (
  intl: IntlShape,
  action: InstanceActionType,
): {
  body: ReactNode
  type: ToastNotificationType
  title: string
} => {
  return {
    body: intl.formatMessage(UnableToExecuteActionToContainerGroupInstanceNodeNotExistErrorMessages.body, { action }),
    type: 'error',
    title: intl.formatMessage(UnableToExecuteActionToContainerGroupInstanceNodeNotExistErrorMessages.title, { action }),
  }
}
