import type { AppState } from '../../store'

export const selectContainerGroup = (state: AppState) => state.containerGroupDetail.containerGroup

export const selectContainerGroupCount = (state: AppState) => state.containerGroupDetail.containerGroup?.replicas

export const selectContainerGroupDisplayName = (state: AppState) =>
  state.containerGroupDetail.containerGroup?.displayName

export const selectDefaultContainerGroupLogsFormValues = (state: AppState) =>
  state.containerGroupDetail.defaultContainerGroupLogsFormValues

export const selectDefaultTabIndex = (state: AppState) => state.containerGroupDetail.defaultTabIndex
