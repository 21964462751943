import { createSelector } from '@reduxjs/toolkit'
import type { AppState } from '../../store'
import { gpuClassesAdapter } from './gpuClassesSlice'
import type { GpuClass } from './models'

const gpuClassesSelectors = gpuClassesAdapter.getSelectors()

const getGpuClassesByIdOrName = (state: AppState, organizationName: string, gpuIdsOrNames: string[] | undefined) => {
  const availableGpuClasses = gpuClassesSelectors.selectById(state.gpuClasses, organizationName)?.gpuClasses
  let gpuClasses: GpuClass[] = []
  gpuIdsOrNames?.map((gpu) => {
    return availableGpuClasses?.map((gpuClass) => {
      return gpu === gpuClass.id || gpu === gpuClass.name ? gpuClasses.push(gpuClass) : undefined
    })
  })
  return gpuClasses
}

export const selectGpuClasses = createSelector(gpuClassesSelectors.selectById, (gpuClasses) => gpuClasses?.gpuClasses)

export const selectGpuClassesById = (state: AppState, organizationName: string, id: string[] | undefined) => {
  return getGpuClassesByIdOrName(state, organizationName, id)
}
