import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import { useRef, useState } from 'react'
import { useDetectClickOutsideElement } from '../../../hooks'
import { DropdownMenuItem } from '../../base/DropdownMenuItem'
import type { DropdownMenuItemProps } from '../../base/DropdownMenuItem/DropdownMenuItem'

interface ButtonDropdownMenuProps {
  /** The aria label for the button. */
  ariaLabel?: React.AriaAttributes['aria-label']
  /** The items that populate the dropdown menu */
  dropDownMenuItems: DropdownMenuItemProps[]
  /** The flag indicating that the dropdown menu is disabled */
  isDisabled?: boolean
}

export const ButtonDropdownMenu: FunctionComponent<ButtonDropdownMenuProps> = ({
  ariaLabel,
  dropDownMenuItems,
  isDisabled,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const buttonDropdownMenuRef = useRef<HTMLDivElement>(null)
  useDetectClickOutsideElement(buttonDropdownMenuRef, () => setIsOpen(false))

  return (
    <div ref={buttonDropdownMenuRef}>
      <button
        aria-label={ariaLabel}
        className={classNames('relative flex items-center', {
          'border border-blue-90 bg-transparent': isOpen,
          'bg-blue-20': !isOpen,
          'bg-neutral-20 opacity-50': isDisabled,
        })}
        disabled={isDisabled}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className={classNames('fa-solid fa-ellipsis-vertical mx-4 my-3')} />
      </button>
      {/* TODO:  This dropdown will be cutoff on smaller screens since it assumes it always has space to the right */}
      {isOpen && (
        <ul className="absolute z-10 w-52 overflow-hidden rounded-md border border-neutral-50 bg-neutral-10 shadow-lg ring-1">
          {dropDownMenuItems.map((item) => (
            <DropdownMenuItem key={item.label} {...item} />
          ))}
        </ul>
      )}
    </div>
  )
}
