import { defineMessages } from 'react-intl'

export const SuccessfullyRemovedTeamMemberContentNotification = defineMessages({
  body: {
    defaultMessage: '{user_email} has been removed from {organization_display_name}',
    id: '6WkoD/ew',
    description: 'The body for the Team Member Removed notification.',
  },
  title: {
    defaultMessage: 'Member Removed',
    id: 'asabzxGt',
    description: 'The title for the Team Member Removed notification.',
  },
})
