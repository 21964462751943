import type { RamOption } from '@saladtechnologies/openapi-cloud-portal-browser'
import type { RadioCardOptions } from '../../../../components/forms/RadioCards/models'
import { RAMIcon } from '../../../../components/icons/RAMIcon'
import { HardwareResourceCardContent } from '../HardwareResourceCardContent'

/**
 * Generates an array of Radio Card Options for the memory input field.
 *
 * @param RamOption The intl provider.
 */
export const generateMemoryOptionCards = (ramOptions: RamOption[] | undefined): RadioCardOptions =>
  ramOptions
    ? ramOptions.map((option) => ({
        content: (
          <HardwareResourceCardContent
            costPerHour={option.costPerHour ? option.costPerHour : 0}
            Icon={<RAMIcon />}
            isHighDemand={option.isHighDemand}
            label={option.name}
          />
        ),
        value: option.value,
      }))
    : []
