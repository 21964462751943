import { defineMessages } from 'react-intl'

export const SuccessfullyRestartedDeploymentInstanceMessages = defineMessages({
  title: {
    defaultMessage: 'Instance Restarted',
    id: 'TNo9oRQb',
    description: 'The toast notification title shown when the deployment instance is successfully restarted',
  },
  body: {
    defaultMessage: 'The instance has successfully been restarted',
    id: 'ANp/XgAS',
    description: 'The toast notification description shown when the deployment instance is successfully restarted',
  },
})
