import type { ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from '../../components/base/Link'
import { SaladCloudSupportEmailAddress } from '../../constants'
import type { ToastNotificationType } from '../models'
import { UnauthenticatedRequestFailedToastContentMessages } from './messages'

/**
 * Retrieves the content needed for an error `ToastNotification` that is shown on unauthenticated user flows, where the
 * error message is generic and points the user to contact support via email.
 */
export const getUnauthenticatedRequestFailedContent = (): {
  body: ReactNode
  type: ToastNotificationType
  title: string
} => {
  return {
    body: (
      <FormattedMessage
        {...UnauthenticatedRequestFailedToastContentMessages.body}
        values={{
          support_email: (
            <Link isDanger url="mailto:cloud@salad.com?subject=SaladCloud Support - General Problem">
              {SaladCloudSupportEmailAddress}
            </Link>
          ),
        }}
      />
    ),
    type: 'error',
    title: UnauthenticatedRequestFailedToastContentMessages.title.defaultMessage,
  }
}
