import type {
  ContainerLoggingHttpCompressionEnum,
  ContainerLoggingHttpFormatEnum,
  HttpHeadersInner,
} from '@saladtechnologies/openapi-cloud-portal-browser'
import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { ExternalLoggingDetailsMessages } from '../messages'
import { ExternalLoggingService } from './../models'
import {
  AxiomExternalLoggingDetails,
  DatadogExternalLoggingDetails,
  HTTPExternalLoggingDetails,
  NewRelicExternalLoggingDetails,
  SplunkExternalLoggingDetails,
  TCPExternalLoggingDetails,
} from './components'

interface ExternalLoggingDetailsProps {
  /** The axiom api key value. */
  axiomApiKey: string | undefined
  /** The axiom dataset name value. */
  axiomDatasetName: string | undefined
  /** The axiom host value. */
  axiomHost: string | undefined
  /** The datadog api key value. */
  datadogApiKey: string | undefined
  /** The datadog host value. */
  datadogHost: string | undefined
  /** The datadog tags value. */
  datadogTags: HttpHeadersInner[] | null | undefined
  /** The selected external logging service. */
  externalLoggingService: ExternalLoggingService | undefined
  /** The http compression value. */
  httpCompression: ContainerLoggingHttpCompressionEnum | undefined
  /** The http format value. */
  httpFormat: ContainerLoggingHttpFormatEnum | undefined
  /** The http headers value. */
  httpHeaders: HttpHeadersInner[] | null | undefined
  /** The http host value. */
  httpHost: string | undefined
  /** The http password value. */
  httpPassword: string | null | undefined
  /** The http path value. */
  httpPath: string | null | undefined
  /** The http port value. */
  httpPort: number | undefined
  /** The http user value. */
  httpUser: string | null | undefined
  /** The new relic host value. */
  newRelicHost: string | undefined
  /** The new relic ingestion key value. */
  newRelicIngestionKey: string | undefined
  /** The splunk host value. */
  splunkHost: string | undefined
  /** The splunk token value. */
  splunkToken: string | undefined
  /** The tcp host value. */
  tcpHost: string | undefined
  /** The tcp port value. */
  tcpPort: number | undefined
}

export const ExternalLoggingDetails: FunctionComponent<ExternalLoggingDetailsProps> = ({
  axiomApiKey,
  axiomDatasetName,
  axiomHost,
  datadogApiKey,
  datadogHost,
  datadogTags,
  externalLoggingService,
  httpCompression,
  httpFormat,
  httpHeaders,
  httpHost,
  httpPassword,
  httpPath,
  httpPort,
  httpUser,
  newRelicHost,
  newRelicIngestionKey,
  splunkHost,
  splunkToken,
  tcpHost,
  tcpPort,
}) => {
  const intl = useIntl()

  return (
    <div className="text-neutral-70">
      {externalLoggingService === undefined && (
        <p>{intl.formatMessage(ExternalLoggingDetailsMessages.loggingDisabledText)}</p>
      )}
      {externalLoggingService === ExternalLoggingService.AXIOM && (
        <AxiomExternalLoggingDetails
          axiomApiKey={axiomApiKey}
          axiomDatasetName={axiomDatasetName}
          axiomHost={axiomHost}
        />
      )}
      {externalLoggingService === ExternalLoggingService.DATADOG && (
        <DatadogExternalLoggingDetails
          datadogApiKey={datadogApiKey}
          datadogHost={datadogHost}
          datadogTags={datadogTags}
        />
      )}
      {externalLoggingService === ExternalLoggingService.NEW_RELIC && (
        <NewRelicExternalLoggingDetails newRelicHost={newRelicHost} newRelicIngestionKey={newRelicIngestionKey} />
      )}
      {externalLoggingService === ExternalLoggingService.SPLUNK && (
        <SplunkExternalLoggingDetails splunkHost={splunkHost} splunkToken={splunkToken} />
      )}
      {externalLoggingService === ExternalLoggingService.TCP && (
        <TCPExternalLoggingDetails tcpHost={tcpHost} tcpPort={tcpPort} />
      )}
      {externalLoggingService === ExternalLoggingService.HTTP && (
        <HTTPExternalLoggingDetails
          httpCompression={httpCompression}
          httpFormat={httpFormat}
          httpHeaders={httpHeaders}
          httpHost={httpHost}
          httpPassword={httpPassword}
          httpPath={httpPath}
          httpPort={httpPort}
          httpUser={httpUser}
        />
      )}
    </div>
  )
}
