import type { FunctionComponent } from 'react'
import { selectForgotPasswordCurrentPage } from '../../features/forgotPassword/forgotPasswordSelectors'
import { ForgotPasswordPage } from '../../features/forgotPassword/models'
import { useAppSelector } from '../../store'
import { ConnectedForgotPasswordPage } from './ConnectedForgotPasswordPage'
import { ConnectedResetPasswordEmailSentPage } from './ConnectedResetPasswordEmailSentPage'

const renderPage = (currentPage: ForgotPasswordPage) => {
  switch (currentPage) {
    case ForgotPasswordPage.ForgotYourPassword:
      return <ConnectedForgotPasswordPage />
    case ForgotPasswordPage.ResetPasswordEmailSent:
      return <ConnectedResetPasswordEmailSentPage />
    default:
      return null
  }
}

export const ForgotPasswordPageContainer: FunctionComponent = () => {
  const currentPage = useAppSelector(selectForgotPasswordCurrentPage)

  return renderPage(currentPage)
}
