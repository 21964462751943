import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import { useCallback, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router'
import { ButtonLink } from '../../../../components/base'
import { SearchWithFilters } from '../../../../components/search'
import { TeamPageMainContentMessages } from '../../messages'
import type { TeamPageMemberCardList } from '../../models'
import { TeamMemberCard } from '../TeamMemberCard'

interface TeamPageMainContentProps {
  /** The current user's email. */
  currentUserEmail: string
  /** The path to the invite team member page. */
  inviteTeamMemberPath: string
  /**
   * The list of team members for the current organization being viewed. This list is shaped to match the information
   * needed to create a `Team Page Member Card`.
   */
  memberList: TeamPageMemberCardList
  /**
   * A callback that when executed removes a member from a team.
   *
   * @param memberId The unique identifier of the invitation.
   * @param email The email address of the member that is being reinvited.
   */
  onRemoveMember: (memberId: string, email: string) => void
  /**
   * A callback that when executed will resend the invitation to the team member.
   *
   * @param invitationId The unique identifier of the invitation.
   * @param email The email address of the member that is being reinvited.
   */
  onResendInvitation: (invitationId: string, email: string) => void
  /**
   * A callback that when executed revokes the invitation sent for to the team member.
   *
   * @param invitationId The unique identifier of the invitation.
   * @param email The email address of the member that is being reinvited.
   */
  onRevokeInvitation: (invitationId: string, email: string) => void
  /** The organization display name. */
  organizationDisplayName: string
}

export const TeamPageMainContent: FunctionComponent<TeamPageMainContentProps> = ({
  currentUserEmail,
  inviteTeamMemberPath,
  memberList,
  onRemoveMember,
  onResendInvitation,
  onRevokeInvitation,
  organizationDisplayName,
}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const [query, setQuery] = useState<{ query: string; filter: { [key: string]: string | undefined } }>({
    query: '',
    filter: {},
  })

  const filterByLabel = intl.formatMessage(TeamPageMainContentMessages.searchFilterByLabel)
  const filterByInvitedOption = intl.formatMessage(TeamPageMainContentMessages.searchFilterByInvitedOption)
  const filterByJoinedOption = intl.formatMessage(TeamPageMainContentMessages.searchFilterByJoinedOption)

  const sortByLabel = intl.formatMessage(TeamPageMainContentMessages.searchSortByLabel)
  const sortByAToZOption = intl.formatMessage(TeamPageMainContentMessages.searchSortByAToZOption)
  const sortByZToAOption = intl.formatMessage(TeamPageMainContentMessages.searchSortByZToAOption)
  const sortByJoinedDateEarliestOption = intl.formatMessage(
    TeamPageMainContentMessages.searchSortByJoinedDateEarliestOption,
  )
  const sortByJoinedDateLatestOption = intl.formatMessage(
    TeamPageMainContentMessages.searchSortByJoinedDateLatestOption,
  )

  const updateMemberListBasedOnQuery = useCallback(
    (query: string, filter: { [key: string]: string | undefined }) => {
      let membersThatMatchQuery = [...memberList]

      const selectedFilterByOption =
        filter[filterByLabel] !== undefined && filter[filterByLabel] !== filterByLabel
          ? filter[filterByLabel]
          : undefined

      membersThatMatchQuery = memberList.filter((member) => {
        const filterByQuery = query.length > 0 ? member.email.toUpperCase().includes(query.toUpperCase()) : true
        const filterByJoined = selectedFilterByOption === filterByJoinedOption ? member.invitation === undefined : true
        const filterByInvited =
          selectedFilterByOption === filterByInvitedOption ? member.invitation !== undefined : true

        return filterByQuery && filterByJoined && filterByInvited
      })

      const selectedSortByOption =
        filter[sortByLabel] !== undefined && filter[sortByLabel] !== sortByLabel ? filter[sortByLabel] : undefined

      switch (selectedSortByOption) {
        case sortByAToZOption:
          membersThatMatchQuery = membersThatMatchQuery.sort((a, b) =>
            a.email.toUpperCase() < b.email.toUpperCase() ? -1 : a.email.toUpperCase() > b.email.toUpperCase() ? 1 : 0,
          )
          break
        case sortByZToAOption:
          membersThatMatchQuery = membersThatMatchQuery.sort((a, b) =>
            a.email.toUpperCase() > b.email.toUpperCase() ? -1 : a.email.toUpperCase() < b.email.toUpperCase() ? 1 : 0,
          )
          break
        case sortByJoinedDateEarliestOption:
          membersThatMatchQuery = membersThatMatchQuery.sort((a, b) => {
            const joinedDateA = a.joinedDate ? new Date(a.joinedDate).getTime() : Infinity
            const joinedDateB = b.joinedDate ? new Date(b.joinedDate).getTime() : Infinity
            return joinedDateA - joinedDateB
          })
          break
        case sortByJoinedDateLatestOption:
          membersThatMatchQuery = membersThatMatchQuery.sort((a, b) => {
            const joinedDateA = a.joinedDate ? new Date(a.joinedDate).getTime() : -Infinity
            const joinedDateB = b.joinedDate ? new Date(b.joinedDate).getTime() : -Infinity
            return joinedDateB - joinedDateA
          })
          break
      }

      return membersThatMatchQuery
    },
    [
      filterByInvitedOption,
      filterByJoinedOption,
      filterByLabel,
      sortByAToZOption,
      sortByZToAOption,
      sortByJoinedDateEarliestOption,
      sortByJoinedDateLatestOption,
      sortByLabel,
      memberList,
    ],
  )

  const memberListBasedOnQuery = useMemo(() => {
    return updateMemberListBasedOnQuery(query.query, query.filter)
  }, [query, updateMemberListBasedOnQuery])

  const membersThatHaveJoinedTeam = useMemo(() => {
    return memberList.filter((member) => member.invitation === undefined)
  }, [memberList])

  const isLastMemberOfTeam = membersThatHaveJoinedTeam.length === 1

  return (
    <>
      <div className="mb-5">
        <button onClick={() => navigate(-1)} className="text-blue-90 underline">
          <span className={classNames('fa-solid fa-arrow-left mr-2')} />
          {intl.formatMessage(TeamPageMainContentMessages.returnToPreviousPageLinkText)}
        </button>
      </div>
      <h1 className="mb-4 text-3xl font-bold">
        {intl.formatMessage(TeamPageMainContentMessages.title, {
          organization_display_name: organizationDisplayName,
        })}
      </h1>
      <div className="mb-4">
        <SearchWithFilters
          onChange={({ filter, query }) => setQuery({ query, filter })}
          filters={[
            { label: filterByLabel, options: [filterByInvitedOption, filterByJoinedOption] },
            {
              label: sortByLabel,
              options: [
                sortByAToZOption,
                sortByZToAOption,
                sortByJoinedDateLatestOption,
                sortByJoinedDateEarliestOption,
              ],
            },
          ]}
          isFullWidth
          placeholder={intl.formatMessage(TeamPageMainContentMessages.searchInputPlaceholder)}
        />
      </div>
      <div className="mb-8">
        <ButtonLink
          iconClassName="fa-solid fa-plus"
          url={inviteTeamMemberPath}
          variant="green-filled-light"
          role="link"
        >
          {intl.formatMessage(TeamPageMainContentMessages.inviteNewTeamMemberButtonLabel)}
        </ButtonLink>
      </div>
      <ul>
        {memberListBasedOnQuery.map((member) => (
          // TODO: This last modifier doesn't work as expected...
          <li className="mb-4 last:mb-0" key={member.id}>
            <TeamMemberCard
              currentUserEmail={currentUserEmail}
              email={member.email}
              memberId={member.id}
              invitation={member.invitation}
              isLastMember={isLastMemberOfTeam}
              isRemoveMemberPending={member.isRemoveMemberPending}
              isResendInvitationPending={member.isResendInvitationPending}
              isRevokeInvitationPending={member.isRevokeInvitationPending}
              joinedDate={member.joinedDate}
              onRemoveMember={() => onRemoveMember(member.id, member.email)}
              onResendInvitation={() => onResendInvitation(member.invitation?.id as string, member.email)}
              onRevokeInvitation={() => onRevokeInvitation(member.invitation?.id as string, member.email)}
            />
          </li>
        ))}
      </ul>
    </>
  )
}
