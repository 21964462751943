import { defineMessages } from 'react-intl'

export const ContainerGroupLogsMessages = defineMessages({
  containerGroupLogsDescriptionText: {
    defaultMessage:
      'View your container logs in this simple interface to troubleshoot your container deployments. For more robust logging functionality, we recommend configuring the External Logging Service option in your container group. {learn_more_link}',
    id: 'A5Jca2QC',
    description: 'Container logs description',
  },
  createTimeLabel: {
    defaultMessage: 'Create Time',
    id: 'osEFH5L0',
    description: 'Label for the Create Time column in container logs table',
  },
  filterByMachineIdPlaceholder: {
    defaultMessage: 'Machine ID',
    id: 'adFk44D6',
    description: 'Filter by Machine ID placeholder text',
  },
  filterPlaceholder: {
    defaultMessage: 'Filter',
    id: 'c1SDbwyP',
    description: 'Filter placeholder text',
  },
  machineIdLabel: {
    defaultMessage: 'Machine ID',
    id: '6uCOgkiA',
    description: 'Label for the Machine ID column in container logs table',
  },
  messageLabel: {
    defaultMessage: 'Message',
    id: 'qXcZyGa5',
    description: 'Label for the Message column in container logs table',
  },
  learMoreLinkText: {
    defaultMessage: 'Learn More',
    id: 'ySYJyzac',
    description: 'The text for the learn more link.',
  },
  queryButtonLabel: {
    defaultMessage: 'Query',
    id: 'xcdYB8us',
    description: 'Run the query',
  },
})
