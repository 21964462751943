import { catchError, concat, delay, filter, from, mergeMap, of } from 'rxjs'
import { OrganizationsAPI } from '../apiMethods'
import { editOrganizationDisplayName } from '../features/editOrganization/editOrganizationSlice'
import { showToastNotification } from '../features/notifications/notificationsSlice'
import { organizationUpdated } from '../features/organizations/organizationsSlice'
import { setRequestStatus } from '../features/requestStatus/requestStatusSlice'
import {
  getEditOrganizationGeneralErrorContent,
  getEditOrganizationSucceededContent,
} from '../notifications/clientToastNotificationContent/organizations'
import type { AppEpic } from '../store'
import { navigateTo } from './navigationEpic'

export const onEditOrganizationDisplayName: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(editOrganizationDisplayName.match),
    mergeMap((action) =>
      concat(
        of(setRequestStatus({ request: 'editOrganization', status: 'pending' })),
        from(
          OrganizationsAPI.updateOrganization({
            organizationName: action.payload.organizationName,
            updateOrganization: {
              displayName: action.payload.newOrganizationDisplayName,
            },
          }),
        ).pipe(
          mergeMap(() => {
            return concat(
              of(
                showToastNotification(
                  getEditOrganizationSucceededContent(
                    intl,
                    action.payload.organizationName,
                    action.payload.newOrganizationDisplayName,
                  ),
                ),
                organizationUpdated({
                  id: action.payload.organizationName,
                  changes: { displayName: action.payload.newOrganizationDisplayName },
                }),
                setRequestStatus({ request: 'editOrganization', status: 'succeeded' }),
              ),
              of(setRequestStatus({ request: 'editOrganization', status: 'idle' })).pipe(delay(1)),
              of(navigateTo({ path: '/organizations' })),
            )
          }),
          catchError(() => {
            return concat(
              of(
                showToastNotification(getEditOrganizationGeneralErrorContent(intl)),
                setRequestStatus({ request: 'editOrganization', status: 'failed' }),
              ),
              of(setRequestStatus({ request: 'editOrganization', status: 'idle' })).pipe(delay(1)),
            )
          }),
        ),
      ),
    ),
  )
