import { defineMessages } from 'react-intl'

export const CreateContainerGroupNameConflictErrorContentMessages = defineMessages({
  body: {
    defaultMessage: 'That Container Group Name has been used before. You must use a new Container Group Name.',
    id: 'Ug15SmHn',
    description:
      'The description shown in a toast notification message shown when we encounter an error on their request to create a container group with a name that has already been used.',
  },
  title: {
    defaultMessage: 'You must use a unique Container Group Name',
    id: 'z1TjbJaV',
    description:
      'The toast notification title shown when we encounter an error on their request to create a container group with a name that has already been used.',
  },
})
