import { defineMessages } from 'react-intl'

export const EditProjectGeneralErrorContentMessages = defineMessages({
  body: {
    defaultMessage:
      'We were unable to update the display name of your project. Try again and if this issue persists {contact_support}.',
    id: 'nzlrRNxC',
    description:
      'The description shown in a toast notification message shown when we encounter an error on their request to edit their project details.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'VkjwXQHP',
    description: 'The text shown for the contact support link in the edit project error toast notification.',
  },
  title: {
    defaultMessage: 'Error updating display name',
    id: 'YBYZ2d7B',
    description:
      'The toast notification title shown when we encounter an error on their request to edit their project details.',
  },
})
