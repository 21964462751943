import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { RequestStatus } from '../../models'

export interface changePasswordState {
  changePasswordRequestStatus: RequestStatus
}

const initialState: changePasswordState = {
  changePasswordRequestStatus: RequestStatus.Idle,
}

export const changePasswordSlice = createSlice({
  name: 'changePassword',
  initialState,
  reducers: {
    changePassword(_state, _action: PayloadAction<{ currentPassword: string; newPassword: string }>) {},
    setChangePasswordRequestStatus(state, action: PayloadAction<{ requestStatus: RequestStatus }>) {
      state.changePasswordRequestStatus = action.payload.requestStatus
    },
  },
})

export const { changePassword, setChangePasswordRequestStatus } = changePasswordSlice.actions
