import type { FunctionComponent } from 'react'
import { LeftNavigationBar } from '../../components/LeftNavigationBar'
import type { ProjectSelectOptions } from '../../components/LeftNavigationBar/models'
import { ThreeColumnLayout } from '../../layouts/threeColumnLayout'
import { RecipeDetailsMainContent } from './components/RecipeDetailsMainContent/RecipeDetailsMainContent'

export interface RecipeDetailsPageProps {
  /** The current organization that the user is in. */
  currentOrganization: { name: string; displayName: string }
  /** The current project that the user is in. */
  currentProject?: { name: string; displayName: string }
  /** The callback executed when the user clicks the contact support button. */
  isDeleteProjectPending: boolean
  /** The flag indicating that the request to delete the project is successful. */
  isDeleteProjectSuccessful: boolean
  /** The flag indicating if the left column is showing. */
  isLeftColumnOpen: boolean
  /** The callback executed when the user closes the left drawer */
  onCloseLeftDrawer: () => void
  /** The callback called when the user deletes the project. */
  onDeleteProject: (projectName: string) => void
  /** The callback executed when the user clicks the Configure & Deploy button. */
  onDeployRecipe: () => void
  /**
   * The handler for when something new is selected in the project dropdown. If the current active project is selected,
   * nothing will happen.
   */
  onProjectChange: (projectName: string) => void
  /** An array of the current available projects. If empty, we will default to no select bar being displayed */
  projects: ProjectSelectOptions
  /** The cost per hour of the recipe */
  recipeCostPerHour: number
  /** The markdown of the deployed recipe */
  recipeMarkdown: string
  /** The path to the recipe marketplace */
  recipeMarketplacePath: string
  /** The original recipe name of the deployed recipe */
  recipeName: string
}

export const RecipeDetailsPage: FunctionComponent<RecipeDetailsPageProps> = ({
  currentOrganization,
  currentProject,
  isDeleteProjectPending,
  isDeleteProjectSuccessful,
  isLeftColumnOpen,
  onCloseLeftDrawer,
  onDeleteProject,
  onDeployRecipe,
  onProjectChange,
  projects,
  recipeCostPerHour,
  recipeMarkdown,
  recipeMarketplacePath,
  recipeName,
}) => {
  return (
    <ThreeColumnLayout
      isLeftColumnOpen={isLeftColumnOpen}
      LeftColumn={
        <LeftNavigationBar
          currentOrganization={currentOrganization}
          currentProject={currentProject}
          isDeleteProjectPending={isDeleteProjectPending}
          isDeleteProjectSuccessful={isDeleteProjectSuccessful}
          onDeleteProject={onDeleteProject}
          onProjectChange={onProjectChange}
          projects={projects}
        />
      }
      onCloseLeftDrawer={onCloseLeftDrawer}
      MainColumn={
        <RecipeDetailsMainContent
          onDeployRecipe={onDeployRecipe}
          recipeCostPerHour={recipeCostPerHour}
          recipeMarkdown={recipeMarkdown}
          recipeMarketplacePath={recipeMarketplacePath}
          recipeName={recipeName}
        />
      }
    />
  )
}
