import type { FunctionComponent } from 'react'
import { useCallback, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { LoadingPage } from '../../components/page/LoadingPage'
import { trackMixpanelPageViewedEvent } from '../../features/analytics/analyticsSlice'
import { selectIsLeftColumnShowing } from '../../features/navigationBar/navigationBarSelectors'
import { setLeftColumnShowing } from '../../features/navigationBar/navigationBarSlice'
import { selectOrganizationDisplayName } from '../../features/organizations/organizationsSelectors'
import { selectProfileEmail, selectProfileUserId } from '../../features/profile/profileSelectors'
import { selectProjects } from '../../features/projects/projectsSelectors'
import { selectRequestStatusIsPending } from '../../features/requestStatus/requestStatusSelectors'
import { selectOrganizationMembersAndInvitations } from '../../features/team/teamSelectors'
import {
  getOrganizationMembersAndInvitations,
  removeMemberFromOrganization,
  resendInvitation,
  revokeInvitation,
} from '../../features/team/teamSlice'
import { teamPageRoutePath } from '../../routes/routePaths'
import { getContainerGroupsPagePath, getInviteTeamMemberPagePath } from '../../routes/routes-utils'
import { useAppDispatch, useAppSelector } from '../../store'
import { TeamPage } from './TeamPage'

export const ConnectedTeamPage: FunctionComponent = () => {
  const { organizationName = '' } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const email = useAppSelector(selectProfileEmail)
  const currentUserId = useAppSelector(selectProfileUserId)
  const organizationDisplayName = useAppSelector((state) => selectOrganizationDisplayName(state, organizationName))
  const projects = useAppSelector((state) => selectProjects(state, organizationName))?.projects || []
  const membersAndInvitations = useAppSelector(selectOrganizationMembersAndInvitations)
  const isLeftColumnOpen = useAppSelector(selectIsLeftColumnShowing)
  const isGetMyTeamMembersAndInvitationsPending = useAppSelector((state) =>
    selectRequestStatusIsPending(state, 'getMyOrganizationMembersAndInvitations'),
  )
  const inviteTeamMemberPath = getInviteTeamMemberPagePath(organizationName)

  const projectSelectOptions = projects.map((project) => {
    return {
      ...project,
      selected: false,
    }
  })

  const handleProjectChange = useCallback(
    (projectName: string) => {
      navigate(getContainerGroupsPagePath(organizationName, projectName))
    },
    [navigate, organizationName],
  )

  const handleCloseLeftNavBar = useCallback(() => {
    dispatch(setLeftColumnShowing({ showing: false }))
  }, [dispatch])

  const onResendInvitation = useCallback(
    (invitationId: string, email: string) => {
      dispatch(resendInvitation({ organizationName, invitationId, email }))
    },
    [dispatch, organizationName],
  )

  const onRemoveMemberFromOrganization = useCallback(
    (memberId: string, email: string) => {
      const isCurrentMember = memberId === currentUserId
      dispatch(removeMemberFromOrganization({ organizationName, memberId, isCurrentMember, email }))
    },
    [dispatch, organizationName, currentUserId],
  )

  const onRevokeInvitation = useCallback(
    (invitationId: string, email: string) => {
      dispatch(revokeInvitation({ organizationName, invitationId, email }))
    },
    [dispatch, organizationName],
  )

  useEffect(() => {
    dispatch(getOrganizationMembersAndInvitations({ organizationName }))
    dispatch(trackMixpanelPageViewedEvent({ path: teamPageRoutePath, organizationName }))
  }, [dispatch, organizationName])

  return isGetMyTeamMembersAndInvitationsPending ? (
    <LoadingPage />
  ) : (
    <TeamPage
      currentOrganization={{ name: organizationName, displayName: organizationDisplayName ?? organizationName }}
      email={email as string}
      inviteTeamMemberPath={inviteTeamMemberPath}
      isLeftColumnOpen={isLeftColumnOpen}
      memberList={membersAndInvitations}
      onCloseLeftDrawer={handleCloseLeftNavBar}
      onProjectChange={handleProjectChange}
      onRemoveMember={(memberId, email) => onRemoveMemberFromOrganization(memberId, email)}
      onResendInvitation={(invitationId, email) => onResendInvitation(invitationId, email)}
      onRevokeInvitation={(invitationId, email) => onRevokeInvitation(invitationId, email)}
      projects={projectSelectOptions}
    />
  )
}
