import classNames from 'classnames'
import type { FunctionComponent, KeyboardEvent, ReactNode } from 'react'
import { useRef } from 'react'
import { KeyboardEventKey } from '../../../utils'

export interface TabItemProps {
  /** The label. */
  children?: ReactNode
  /** The flag indicating whether the tab has error styles applied. */
  hasError?: boolean
  /** A Font Awesome icon class name (e.g. `fa-envelope`). */
  iconClassName?: string
  /** The tab id. */
  id: string
  /** The value indicates whether tab is selected. */
  isSelected: boolean
  /** The click handler. */
  onSelectTab: () => void
  /** The tab index for focus. */
  tabItemIndex: number
}

export const TabItem: FunctionComponent<TabItemProps> = ({
  children,
  hasError,
  iconClassName,
  id,
  isSelected,
  onSelectTab,
  tabItemIndex,
}) => {
  const tabItemRef = useRef<HTMLButtonElement>(null)

  const handleKeyDown = (event: KeyboardEvent<HTMLButtonElement>) => {
    switch (event.key) {
      case KeyboardEventKey.Space:
      case KeyboardEventKey.SpaceSymbol:
      case KeyboardEventKey.Enter: {
        onSelectTab()
        break
      }

      case KeyboardEventKey.ArrowLeft: {
        const previousTab = tabItemRef.current?.previousElementSibling as HTMLButtonElement
        previousTab.focus()
        break
      }

      case KeyboardEventKey.ArrowRight: {
        const nextTab = tabItemRef.current?.nextElementSibling as HTMLButtonElement
        nextTab.focus()
        break
      }

      case KeyboardEventKey.Home: {
        const firstTab = tabItemRef.current?.parentElement?.firstElementChild as HTMLButtonElement
        firstTab.focus()
        break
      }

      case KeyboardEventKey.End: {
        const lastTab = tabItemRef.current?.parentElement?.lastElementChild as HTMLButtonElement
        lastTab.focus()
        break
      }

      default:
        break
    }
  }

  return (
    <button
      id={id}
      type="button"
      role="tab"
      tabIndex={tabItemIndex}
      onClick={onSelectTab}
      onKeyDown={handleKeyDown}
      aria-label={children ? undefined : id}
      aria-selected={isSelected}
      className={classNames(
        'flex flex-1 items-center justify-center rounded-sm border-b-2 border-neutral-40 px-4 py-2 text-base font-normal text-neutral-100 outline-none hover:border-blue-80 focus:border-blue-80',
        {
          'text-red-80 hover:border-red-80 focus:border-red-80': hasError,
        },
      )}
      ref={tabItemRef}
    >
      {iconClassName && (
        <div
          className={classNames(iconClassName, {
            'mr-2': children,
          })}
        />
      )}
      <span
        className={classNames({
          'border-blue-80 font-bold text-blue-80': isSelected,
          'border-red-80 font-bold text-red-80': isSelected && hasError,
        })}
      >
        {children}
      </span>
    </button>
  )
}

TabItem.defaultProps = {
  children: undefined,
  iconClassName: undefined,
}
