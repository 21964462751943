import type { FunctionComponent } from 'react'
import { memo } from 'react'
import { useIntl } from 'react-intl'
import { Button } from '../../../../Button'
import { Modal } from '../../../../block/Modal'
import { RecreateConfirmationModalMessages } from './RecreateConfirmationModalMessages'

export interface RecreateConfirmationModalProps {
  /** The value indicates whether recreate request is in progress (waiting for response) */
  isRecreateInstancePending: boolean
  /** The callback executed when the user clicks the `Cancel` button. */
  onCancel: () => void
  /** The callback executed when the user clicks the `Recreate` button. */
  onRecreateInstance: () => void
}

export const RecreateConfirmationModal: FunctionComponent<RecreateConfirmationModalProps> = memo(
  ({ isRecreateInstancePending, onCancel, onRecreateInstance }) => {
    const intl = useIntl()

    return (
      <Modal onClose={onCancel} title={intl.formatMessage(RecreateConfirmationModalMessages.title)}>
        <h1 className="mb-8 text-3xl font-bold">{intl.formatMessage(RecreateConfirmationModalMessages.title)}</h1>
        <p className="mb-8 font-normal">{intl.formatMessage(RecreateConfirmationModalMessages.body)}</p>
        <div className="flex max-w-md gap-6">
          <Button
            type="button"
            variant="blue-outlined"
            onClick={onCancel}
            isFullWidth
            isDisabled={isRecreateInstancePending}
          >
            {intl.formatMessage(RecreateConfirmationModalMessages.cancel)}
          </Button>
          <Button variant="blue-filled" onClick={onRecreateInstance} isFullWidth isLoading={isRecreateInstancePending}>
            {intl.formatMessage(RecreateConfirmationModalMessages.delete)}
          </Button>
        </div>
      </Modal>
    )
  },
)
