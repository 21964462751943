import { defineMessages } from 'react-intl'

export const JobQueueTableMessages = defineMessages({
  containerGroupColumnHeader: {
    defaultMessage: 'Container Group Name',
    id: 'l6clgsmy',
    description: 'The header for the container group column of the job queue table.',
  },
  deleteConnectionButtonLabel: {
    defaultMessage: 'Delete Connection',
    id: 'fNRkievW',
    description: 'The label for the delete connection button.',
  },
  pathHeader: {
    defaultMessage: 'Path',
    id: 'ikOsFan8',
    description: 'The header for the path column of the job queue table.',
  },
  portHeader: {
    defaultMessage: 'Port',
    id: 'AG7xMA7l',
    description: 'The header for the port column of the job queue table.',
  },
})
