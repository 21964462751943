import { useState, type FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import {
  getContainerGroupsPagePath,
  getInferenceEndpointsMarketplacePagePath,
  getJobQueuesPagePath,
  getOrganizationTeamPagePath,
  getRecipeDeploymentsPagePath,
} from '../../../routes/routes-utils'
import { contactSupportViaHelpScout } from '../../../utils'
import { MenuItem } from '../../block/MenuItem'
import { LeftNavigationBarMessages } from '../messages'
import { AppSumoCodeModal } from './AppSumoCodeModal'

interface MenuItemsProps {
  organizationName?: string
  projectName?: string
}

export const StorybookMenuItems: FunctionComponent<MenuItemsProps> = ({ organizationName, projectName }) => {
  const intl = useIntl()
  const [isAppSumoCodeModalOpen, setIsAppSumoCodeModalOpen] = useState(false)

  const handleContactSupportClick = () => {
    contactSupportViaHelpScout()
  }

  const handleOpenAppSumoModal = () => {
    setIsAppSumoCodeModalOpen(true)
  }

  return (
    <>
      <ul>
        {organizationName === undefined && projectName === undefined && (
          <>
            <li>
              <MenuItem
                iconClassName="fa-solid fa-layer-group"
                label={intl.formatMessage(LeftNavigationBarMessages.organizationsNavigationLabel)}
                path="/organizations"
                variant="left-nav"
              />
            </li>
            <li>
              <MenuItem
                iconClassName="fa-solid fa-cog"
                label={intl.formatMessage(LeftNavigationBarMessages.accountSettingsNavigationLabel)}
                path="/account"
                variant="left-nav"
              />
            </li>
            <li>
              <MenuItem
                iconClassName="fa-solid fa-code"
                label={intl.formatMessage(LeftNavigationBarMessages.apiAccessNavigationLabel)}
                path="/api-key"
                variant="left-nav"
              />
            </li>
          </>
        )}

        {organizationName && projectName && (
          <>
            <li>
              <MenuItem
                iconClassName="fa-solid fa-code"
                label={intl.formatMessage(LeftNavigationBarMessages.inferenceEndpointsNavigationLabel)}
                path={getInferenceEndpointsMarketplacePagePath(organizationName)}
                variant="left-nav"
              />
            </li>
            <li>
              <MenuItem
                iconClassName="fa-solid fa-boxes"
                label={intl.formatMessage(LeftNavigationBarMessages.containerGroupsNavigationLabel)}
                path={getContainerGroupsPagePath(organizationName, projectName)}
                variant="left-nav"
              />
            </li>
            <li>
              <MenuItem
                iconClassName="fa-solid fa-window-restore"
                isFeaturePreview
                label={intl.formatMessage(LeftNavigationBarMessages.jobQueuesNavigationLabel)}
                path={getJobQueuesPagePath(organizationName, projectName)}
                variant="left-nav"
              />
            </li>
            <li>
              <MenuItem
                iconClassName="fa-solid fa-utensils"
                label={intl.formatMessage(LeftNavigationBarMessages.recipeDeploymentsNavigationLabel)}
                path={getRecipeDeploymentsPagePath(organizationName, projectName)}
                variant="left-nav"
              />
            </li>
          </>
        )}

        {organizationName && (
          <>
            <li>
              <MenuItem
                iconClassName="fa-solid fa-users"
                label={intl.formatMessage(LeftNavigationBarMessages.teamNavigationLabel)}
                path={getOrganizationTeamPagePath(organizationName)}
                variant="left-nav"
              />
            </li>
            <li>
              <MenuItem
                iconClassName="fa-solid fa-credit-card"
                label={intl.formatMessage(LeftNavigationBarMessages.billingAndUsageNavigationLabel)}
                path={`/organizations/${organizationName}/billing`}
                variant="left-nav"
              />
            </li>
          </>
        )}

        <li>
          <MenuItem
            iconClassName="fa-solid fa-book"
            label={intl.formatMessage(LeftNavigationBarMessages.documentationNavigationLabel)}
            externalLink="https://docs.salad.com/introduction"
            variant="left-nav"
          />
        </li>
        <li>
          <MenuItem
            iconClassName="fa-solid fa-headset"
            label={intl.formatMessage(LeftNavigationBarMessages.contactSupportButtonLabel)}
            onClick={handleContactSupportClick}
            variant="left-nav"
          />
        </li>
        {organizationName && projectName === undefined && (
          <li>
            <MenuItem
              iconClassName="fa-solid fa-ticket"
              label={intl.formatMessage(LeftNavigationBarMessages.appSumoCodeNavigationLabel)}
              onClick={handleOpenAppSumoModal}
              variant="left-nav"
            />
          </li>
        )}
      </ul>
      {isAppSumoCodeModalOpen && (
        <AppSumoCodeModal
          onCloseWindow={() => setIsAppSumoCodeModalOpen(false)}
          organizationName={organizationName || ''}
          email={''}
        />
      )}
    </>
  )
}
