import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export interface RegistrationState {
  email: string
}

const initialState: RegistrationState = {
  email: '',
}

export const registrationSlice = createSlice({
  name: 'registration',
  initialState,
  reducers: {
    registerAccount(state, action: PayloadAction<{ email: string; password: string }>) {
      state.email = action.payload.email
    },
    resendRegistrationEmail(_state, _action: PayloadAction<{ email: string }>) {},
  },
})

export const { registerAccount, resendRegistrationEmail } = registrationSlice.actions
