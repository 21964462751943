import { defineMessages } from 'react-intl'

export const SuccessfullyDeletedAccountToastContentMessages = defineMessages({
  body: {
    defaultMessage: `We’re sorry to see you go. Make a new account to try Salad again.`,
    id: '/ycEQmvD',
    description:
      'The description shown in a toast notification that guides the user on what to do after we delete their account.',
  },
  title: {
    defaultMessage: 'Your account was deleted',
    id: 'nyH3/Cof',
    description: `The toast notification title for when we successfully delete a user's account.`,
  },
})
