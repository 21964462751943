import { defineMessages } from 'react-intl'

export const ChangeAPIKeyFailedContentMessages = defineMessages({
  body: {
    defaultMessage: 'We were unable to change your API key. Try again and if this issue persists {contact_support}.',
    id: '4PqW8Ixr',
    description: 'The description shown in a toast notification message shown when we failed to change their API Key',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'mJWhKEEC',
    description: 'The text shown for the contact support link in the change password error toast notification.',
  },
  title: {
    defaultMessage: 'Error changing API key',
    id: '8lL2Cv3z',
    description: 'The toast notification title shown when we failed to change their API Key.',
  },
})
