import { defineMessages } from 'react-intl'

export const EditGPUInputMessages = defineMessages({
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'gAkS3BMj',
    description:
      'The text shown for the contact support link under the GPU input field on the create container group page.',
  },
  filterBy16GBPlus: {
    defaultMessage: '16GB+',
    id: 'WM67WOy6',
    description: 'Filter by 16GB+ GPUs',
  },
  filterBy8GBPlus: {
    defaultMessage: '8GB+',
    id: 'Vz+iqS5f',
    description: 'Filter by 8GB+ GPUs',
  },
  filterBy24GB: {
    defaultMessage: '24GB',
    id: '9aa82+/B',
    description: 'Filter by 24GB GPUs',
  },
  filterByLessThan8GB: {
    defaultMessage: '<8GB',
    id: 'j0GpU2jO',
    description: 'Filter by less than 8GB GPUs',
  },
  helperText: {
    defaultMessage: 'If needed, select the GPUs for your container group. You can select multiple GPUs',
    id: 'B2t6tC1p',
    description: 'The helper text for the container group GPU input field.',
  },
  highDemandGpuSelectedNotificationBody: {
    defaultMessage: `It may take longer than usual to allocate nodes that meet your GPU specifications. In rare cases, we may be unable to reach the desired replica count.`,
    id: 'MLfEtoIN',
    description: 'The description for the high demand GPU selected notification.',
  },
  highDemandGpuSelectedNotificationTitle: {
    defaultMessage: `You’ve selected GPUs that are in high demand`,
    id: 'B5cfhYa9',
    description: 'The title for the high demand GPU selected notification.',
  },
  label: {
    defaultMessage: 'Would you like to allocate some GPUs?',
    id: 'JN2qlKJv',
    description: 'The label for the container group GPU input field.',
  },
  noneOptionLabel: {
    defaultMessage: 'No GPUs',
    id: 'HL5ecRNW',
    description: 'The option label for the container group GPU input field when they would like none.',
  },
  searchFilter: {
    defaultMessage: 'Filter by',
    id: 'ZVgBT2Ba',
    description: 'The search filter label for the gpu input.',
  },
  searchInputPlaceholder: {
    defaultMessage: 'Find a GPU',
    id: 'ZQdOEvJZ',
    description: 'The search input placeholder for the gpu input.',
  },
  searchSortBy: {
    defaultMessage: 'Sort by',
    id: 'oNjS1RSr',
    description: 'The search sort by label for the gpu input.',
  },
  sortByHighToLowOption: {
    defaultMessage: 'High to Low',
    id: 'uTfxAYfA',
    description: 'The high to low option for the sort by filter.',
  },
  unableToGetGPUOptionsText: {
    defaultMessage: 'We were unable to load available GPUs. If this issue persists {contact_support}.',
    id: '1Cws7IMB',
    description:
      'The error message we show when we were unable to get gpu options for the create container group page.',
  },
})
