import { yupResolver } from '@hookform/resolvers/yup'
import type { FunctionComponent } from 'react'
import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Button } from '../../../../components/Button'
import { TextField } from '../../../../components/TextField'
import { useFixedForm } from '../../../../hooks'
import { CreateProjectFormMessages } from '../../messages'
import { useCreateProjectFormValidation } from './useCreateProjectFormValidation'

export interface CreateProjectFormValues {
  /** The project name. */
  projectName: string
}

interface CreateProjectFormProps {
  /** The flag indicating that the request to create the project is pending. */
  isCreateProjectPending: boolean
  /** A callback that when executed creates the project. */
  onCreateProject: (projectName: string) => void
}

export const CreateProjectForm: FunctionComponent<CreateProjectFormProps> = ({
  isCreateProjectPending,
  onCreateProject,
}) => {
  const intl = useIntl()
  const createProjectValidationScheme = useCreateProjectFormValidation()
  const { control, handleSubmit } = useFixedForm<CreateProjectFormValues>({
    resolver: yupResolver(createProjectValidationScheme),
    onSubmit: ({ projectName }) => onCreateProject(projectName),
  })

  return (
    <div className="w-full max-w-md">
      <h1 className="mb-3 text-3xl font-bold">{intl.formatMessage(CreateProjectFormMessages.title)}</h1>
      <p className="mb-10 text-base">{intl.formatMessage(CreateProjectFormMessages.description)}</p>
      <form onSubmit={handleSubmit}>
        <h2 className="mb-2 text-xl font-bold">{intl.formatMessage(CreateProjectFormMessages.projectDetailsHeader)}</h2>
        <p className="mb-8 text-base">{intl.formatMessage(CreateProjectFormMessages.projectDetailsDescription)}</p>
        <Controller
          name="projectName"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              {...fieldState}
              helperText={intl.formatMessage(CreateProjectFormMessages.projectNameHelperText)}
              placeholder={intl.formatMessage(CreateProjectFormMessages.projectNameFieldPlaceholder)}
              label={intl.formatMessage(CreateProjectFormMessages.projectNameFieldLabel)}
              isFullWidth
            />
          )}
        />
        <Button type="submit" isLoading={isCreateProjectPending} isDisabled={isCreateProjectPending} isFullWidth>
          {intl.formatMessage(CreateProjectFormMessages.createProjectButtonLabel)}
        </Button>
      </form>
    </div>
  )
}
