import { defineMessages } from 'react-intl'

export const UnauthenticatedRequestFailedToastContentMessages = defineMessages({
  body: {
    defaultMessage: 'If the issue persists, please contact support at {support_email}.',
    id: 'hywsj5M7',
    description: 'A generic error message shown in an error toast notification body.',
  },
  title: {
    defaultMessage: 'Request Failed',
    id: 't96opEGb',
    description: 'The title shown in the generic error toast notification.',
  },
})
