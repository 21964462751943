import type { ContainerGroupLogsQuery } from '@saladtechnologies/openapi-cloud-portal-browser'

/**
 * Transforms the ContainerGroupLogsQuery dates to ISO strings.
 *
 * @param containerGroupLogsQuery
 * @returns The ContainerGroupLogsQuery with the dates transformed to ISO strings.
 */
export const getContainerGroupLogsMixpanelQuery = (containerGroupLogsQuery: ContainerGroupLogsQuery) => {
  return {
    ...containerGroupLogsQuery,
    startTime: containerGroupLogsQuery.startTime
      ? new Date(containerGroupLogsQuery.startTime).toISOString()
      : containerGroupLogsQuery.startTime,
    endTime: containerGroupLogsQuery.endTime
      ? new Date(containerGroupLogsQuery.endTime).toISOString()
      : containerGroupLogsQuery.endTime,
  }
}
