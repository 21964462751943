import { defineMessages } from 'react-intl'

export const VerificationLinkExpiredMessages = defineMessages({
  title: {
    defaultMessage: 'Email Link Verification Link Expired',
    id: '2B1V8oqq',
    description: 'The title of verification link expired dialog',
  },
  contactSupportLabel: {
    defaultMessage: 'Contact Support',
    id: 'GhAamYSQ',
    description: 'The contact support button label',
  },
  description: {
    defaultMessage:
      'Please enter your email address. We will send you a new email to verify your email address and activate your account.',
    id: 'ggq8rYeX',
    description: 'The description of verification link expired dialog',
  },
  emailInvalid: {
    defaultMessage: 'Must be a valid email',
    id: '0a+oaz68',
    description: 'The email is invalid.',
  },
  emailLabel: {
    defaultMessage: 'Email Address',
    id: 'ouh/i5yE',
    description: 'The label for the email address field.',
  },
  emailPlaceholder: {
    defaultMessage: 'Email',
    id: 'w1g6SHO6',
    description: 'The placeholder for the email address field.',
  },
  emailRequired: {
    defaultMessage: 'An email address is required.',
    id: '4PeHXlzA',
    description: 'The error message when the email address is not provided.',
  },
  resendEmailButtonLabel: {
    defaultMessage: 'Resend Email',
    id: 'OjBMgB43',
    description: 'The Resent Email button text',
  },
})
