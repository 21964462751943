import { defineMessages } from 'react-intl'

export const NoContainerGroupsDeployedMessages = defineMessages({
  noContainerGroupsImageAlt: {
    defaultMessage: 'No Container Groups Image',
    id: 'Iwf+81EL',
    description: 'The alt text for the no container groups image.',
  },
  title: {
    defaultMessage: 'You haven’t deployed a container group yet',
    id: 'nG9VviBX',
    description: 'The title of for the No Container Groups page.',
  },
})
