import type { FunctionComponent } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Button } from '../../../../components/Button'
import { TextField } from '../../../../components/TextField'
import { getAPIKeyCopiedToClipboardSucceededContent } from '../../../../notifications/clientToastNotificationContent/apiKey'
import { createToastNotification } from '../../../../notifications/createToastNotification'
import { APIKeyMainContentMessages } from '../../messages'
import { ChangeAPIKeyModal } from '../ChangeAPIKeyModal'

interface APIKeyPageMainContentProps {
  /** The API Key. */
  apiKey: string
  /** The flag indicating that the request to change the API key is pending. */
  isChangeAPIKeyRequestPending: boolean
  /** The flag indicating that the request to change the API key was successful. */
  isChangeAPIKeyRequestSuccessful?: boolean
  /** The callback executed when the user clicks on the Change API Key button. */
  onChangeAPIKey: () => void
}

export const APIKeyPageMainContent: FunctionComponent<APIKeyPageMainContentProps> = ({
  apiKey,
  isChangeAPIKeyRequestPending,
  isChangeAPIKeyRequestSuccessful,
  onChangeAPIKey,
}) => {
  const [revealPassword, setRevealPassword] = useState<boolean>(false)
  const [isChangeAPIKeyModalOpen, setIsChangeAPIKeyModalOpen] = useState<boolean>(false)
  const intl = useIntl()

  const handleCopyAPIKey = useCallback(
    (apiKey: string) => {
      navigator.clipboard.writeText(apiKey)
      createToastNotification(getAPIKeyCopiedToClipboardSucceededContent(intl))
    },
    [intl],
  )

  useEffect(() => {
    setIsChangeAPIKeyModalOpen(false)
  }, [isChangeAPIKeyRequestSuccessful])

  return (
    <div className="w-full max-w-2xl">
      <h1 className="mb-3 text-3xl font-bold">{intl.formatMessage(APIKeyMainContentMessages.title)}</h1>
      <p className="mb-8 text-base">{intl.formatMessage(APIKeyMainContentMessages.description)}</p>
      <TextField
        defaultValue={apiKey}
        key={apiKey}
        label={intl.formatMessage(APIKeyMainContentMessages.currentAPIKeyLabel)}
        onChange={() => {}}
        onRightIconClick={() => setRevealPassword(!revealPassword)}
        readonly
        rightIconClassName={`fa-solid ${revealPassword ? 'fa-eye-slash' : 'fa-eye'}`}
        rightIconColor="text-blue-80"
        type={revealPassword ? 'text' : 'password'}
        isFullWidth
      />
      <div className="flex flex-wrap gap-2">
        <Button type="button" variant="green-filled-light" onClick={() => handleCopyAPIKey(apiKey)}>
          {intl.formatMessage(APIKeyMainContentMessages.copyAPIKeyButtonLabel)}
        </Button>
        <Button
          onClick={() => setIsChangeAPIKeyModalOpen(true)}
          isLoading={isChangeAPIKeyRequestPending}
          isDisabled={isChangeAPIKeyRequestPending}
          type="button"
          variant="blue-filled-light"
        >
          {intl.formatMessage(APIKeyMainContentMessages.generateAPIKeyButtonLabel)}
        </Button>
      </div>

      {isChangeAPIKeyModalOpen && (
        <ChangeAPIKeyModal
          isChangeAPIKeyPending={isChangeAPIKeyRequestPending}
          onCancel={() => setIsChangeAPIKeyModalOpen(false)}
          onChangeAPIKey={onChangeAPIKey}
        />
      )}
    </div>
  )
}
