import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import { useEffect, useState } from 'react'
import {
  Control,
  Controller,
  FieldValues,
  UseFormClearErrors,
  UseFormTrigger,
  useFieldArray,
  useFormState,
} from 'react-hook-form'
import { useIntl } from 'react-intl'
import { CreateContainerGroupValues } from '../../../pages/CreateContainerGroup/models'
import { EditContainerGroupValues } from '../../../pages/EditContainerGroup/models'
import { Button } from '../../Button'
import { TextField } from '../../TextField'
import { SidePanelModal } from '../../block/SidePanelModal'
import { KeyValuePairFormMessages } from './messages'

interface KeyValuePairFormProps {
  /** The label for the button that will add key value pairs to the form. */
  addButtonLabel: string
  /** The react hook form method that clears errors for specified fields. */
  clearErrors: UseFormClearErrors<FieldValues>
  /** The control for the create or edit container group react hook form. */
  control: Control<FieldValues, CreateContainerGroupValues> | Control<FieldValues, EditContainerGroupValues>
  /** The name of the controller that will be used to store the key value pairs. */
  controllerName: string
  /** The id of the form. */
  formId: string
  /** The state that determines if the side panel is open or not. */
  isSidePanelOpen: boolean
  /** The flag indicating if the form is displayed on top of another side panel. */
  isStacked?: boolean
  /** The label for the key input field. */
  keyLabel?: string
  /** The key value pairs to display in the form. */
  keyValuePairValues: any[]
  /** The react hook form method that sets the state of the side panel. */
  onSetIsSidePanelOpen: (isSidePanelOpen: boolean) => void
  /** The label for the submit button. */
  submitButtonLabel: string
  /** The subtitle for the form. */
  subtitle?: React.ReactNode
  /** The title for the form. */
  title: string
  /** The react hook form method that triggers validation for specified fields. */
  trigger: UseFormTrigger<FieldValues>
  /** The label for the value input field. */
  valueLabel?: string
}

export const KeyValuePairForm: FunctionComponent<KeyValuePairFormProps> = ({
  addButtonLabel,
  clearErrors,
  control,
  controllerName,
  formId,
  isSidePanelOpen,
  isStacked,
  keyLabel,
  keyValuePairValues,
  onSetIsSidePanelOpen,
  submitButtonLabel,
  subtitle,
  title,
  trigger,
  valueLabel,
}) => {
  const intl = useIntl()
  const { append, fields, remove, update } = useFieldArray({
    control,
    name: controllerName,
  })
  const { errors } = useFormState({ control, name: controllerName })
  const [isBulkEditEnabled, setBulkEditState] = useState<boolean>(false)
  const [keyValuePairBulkEditValues, setKeyValuePairBulkEditValues] = useState<string>('')
  const isKeyValuePairInvalid = Object.keys(errors).some((key) => key.includes(controllerName))
  const keyLabelLowercase = keyLabel?.toLowerCase() ?? 'key'
  const defaultBulkEditKeyValuePairValues = keyValuePairValues
    ?.map((item) => `${item[keyLabelLowercase]}=${item.value}`)
    ?.join('\n')
  const generalErrorMessage = errors[controllerName]?.message

  const getBulkEditErrorMessageFromKey = (errors: any[], key: string): string | undefined => {
    return errors
      ?.map((error) => error?.[key]?.message)
      ?.filter((message, index, self) => message && self.indexOf(message) === index)
      ?.join(' ')
  }

  const getBulkEditKeyValuePairErrorMessage = (
    errors: Record<string, any>,
    controllerName: string,
    keyLabelLowercase: string,
  ): string | undefined => {
    if (generalErrorMessage) return generalErrorMessage as string

    const BulkEditKeyErrorMessage = getBulkEditErrorMessageFromKey(errors[controllerName], keyLabelLowercase)
    if (BulkEditKeyErrorMessage) return BulkEditKeyErrorMessage as string

    const bulkEditValueErrorMessage = getBulkEditErrorMessageFromKey(errors[controllerName], 'value')
    return bulkEditValueErrorMessage as string
  }

  const bulkEditKeyValuePairErrorMessage = getBulkEditKeyValuePairErrorMessage(
    errors,
    controllerName,
    keyLabelLowercase,
  )

  useEffect(() => {
    setKeyValuePairBulkEditValues(defaultBulkEditKeyValuePairValues ?? '')
  }, [defaultBulkEditKeyValuePairValues])

  const handleToggleBulkEditEnabled = () => {
    if (isBulkEditEnabled) {
      return handleConvertBulkEditToKeyValuePair()
    }
    return setBulkEditState(!isBulkEditEnabled)
  }
  const handleRemoveKeyValuePair = async (index: number) => {
    await trigger([controllerName]).then(() => {
      remove(index)
    })
  }

  const handleResetPageToDefaultState = (value: string, index: number, field: string) => {
    if (index === 0 && value?.length === 0) {
      if (field !== 'value' && keyValuePairValues[index].value?.length === 0) {
        clearErrors(controllerName)
        handleRemoveKeyValuePair(index)
      }

      if (field === 'value' && keyValuePairValues[index].keyLabelLowercase?.length === 0) {
        clearErrors(controllerName)
        handleRemoveKeyValuePair(index)
      }
    }
  }

  const handleConvertBulkEditToKeyValuePair = async () => {
    const keyValuePairBulkEditValuesObject = keyValuePairBulkEditValues
      .split('\n')
      .map((item) => {
        if (!item.includes('=')) {
          return { [keyLabelLowercase]: item, value: '' }
        }
        const [key, ...rest] = item.split('=')
        const value = rest.join('=')
        return { [keyLabelLowercase]: key, value }
      })
      .filter((item) => item[keyLabelLowercase])

    keyValuePairBulkEditValuesObject.forEach((item, index) => {
      if (item[keyLabelLowercase]) {
        update(index, item)
      }
    })
    if (keyValuePairBulkEditValuesObject?.length < keyValuePairValues?.length) {
      for (let i = keyValuePairBulkEditValuesObject?.length; i < keyValuePairValues?.length; i++) {
        await handleRemoveKeyValuePair(i)
      }
    }
    await trigger([controllerName]).then((isValid) => {
      if (isValid) {
        setBulkEditState(!isBulkEditEnabled)
      }
    })
  }

  const handleValidateFieldsBeforeClose = async (event?: React.FormEvent<HTMLFormElement>) => {
    event?.preventDefault()

    isBulkEditEnabled && (await handleConvertBulkEditToKeyValuePair())

    await trigger([controllerName]).then((isValid) => {
      if (
        keyValuePairValues &&
        keyValuePairValues?.length === 1 &&
        keyValuePairValues[0]?.[keyLabelLowercase] === '' &&
        keyValuePairValues[0]?.value === ''
      ) {
        clearErrors(controllerName)
        remove()
        onSetIsSidePanelOpen(false)

        return
      }
      if (isValid) {
        onSetIsSidePanelOpen(false)
      }
    })
  }
  return (
    <SidePanelModal
      CustomButton={
        <Button
          form={formId}
          isFullWidth
          onClick={() => handleValidateFieldsBeforeClose()}
          type="button"
          variant="green-filled"
        >
          {submitButtonLabel}
        </Button>
      }
      isLarge
      isStacked={isStacked}
      isShown={isSidePanelOpen}
      onClose={handleValidateFieldsBeforeClose}
      title={title}
    >
      <form onSubmit={handleValidateFieldsBeforeClose} id={formId}>
        <div className="mt-12 w-full px-10 pb-12">
          <div className="mb-10">
            <div className="inline-flex w-full justify-between">
              <h2 className="text-3xl font-bold">{title}</h2>
              <Button variant={'blue-filled-light'} type="button" onClick={handleToggleBulkEditEnabled}>
                {isBulkEditEnabled
                  ? intl.formatMessage(KeyValuePairFormMessages.keyValueEditLabel)
                  : intl.formatMessage(KeyValuePairFormMessages.bulkEditLabel)}
              </Button>
            </div>

            <p className="mt-2">{subtitle}</p>

            {generalErrorMessage && !isBulkEditEnabled && (
              <p className="text-red-70">{generalErrorMessage as string}</p>
            )}
          </div>
          {isBulkEditEnabled ? (
            <div className="h-min">
              <TextField
                defaultValue={defaultBulkEditKeyValuePairValues}
                helperText={
                  bulkEditKeyValuePairErrorMessage ?? intl.formatMessage(KeyValuePairFormMessages.bulkEditHelperText)
                }
                invalid={isKeyValuePairInvalid}
                isExtraHeight
                isFullWidth
                isTextarea
                onChange={(value: string | number) => setKeyValuePairBulkEditValues(value.toString())}
                type="text"
              />
            </div>
          ) : (
            <>
              {fields.length === 0 && (
                <div className="inline-flex w-full justify-between gap-2">
                  <div className="mb-4 w-5/12">
                    <TextField
                      hasNoDefaultHeight
                      hasNoDefaultMarginBottom
                      isFullWidth
                      label={keyLabel ?? intl.formatMessage(KeyValuePairFormMessages.keyLabel)}
                      onChange={(value) => append({ [keyLabelLowercase]: value, value: '' })}
                      type="text"
                    />
                  </div>
                  <div className="w-5/12">
                    <TextField
                      hasNoDefaultHeight
                      hasNoDefaultMarginBottom
                      isFullWidth
                      label={valueLabel ?? intl.formatMessage(KeyValuePairFormMessages.valueLabel)}
                      onChange={(value) =>
                        append({ [keyLabelLowercase]: '', value: value }, { focusName: `${controllerName}.0.value` })
                      }
                      type="text"
                    />
                  </div>
                  <div className="mt-[30px] w-1/12">
                    <Button iconClassName="fa-solid fa-trash" variant="red-outlined" isDisabled type="button" />
                  </div>
                </div>
              )}

              {fields.map((_item, index) => {
                return (
                  <div key={index}>
                    <div
                      className={classNames('mb-4 inline-flex w-full justify-between gap-2', {
                        '-mt-8': index === 0,
                        '-mt-24': index !== 0,
                      })}
                    >
                      <div className="w-5/12">
                        <Controller
                          name={`${controllerName}.${index}.${keyLabelLowercase}`}
                          control={control}
                          render={({ field, fieldState }) => {
                            return (
                              <TextField
                                {...field}
                                {...fieldState}
                                defaultValue={keyValuePairValues?.[index]?.[keyLabelLowercase]}
                                hasNoDefaultHeight
                                hasNoDefaultMarginBottom
                                helperText={fieldState.error?.message}
                                isFullWidth
                                label={
                                  index === 0
                                    ? (keyLabel ?? intl.formatMessage(KeyValuePairFormMessages.keyLabel))
                                    : undefined
                                }
                                onChange={(value) => {
                                  field.onChange(value)
                                  handleResetPageToDefaultState(value as string, index, keyLabelLowercase)
                                }}
                                type="text"
                              />
                            )
                          }}
                        />
                      </div>

                      <div className="w-5/12">
                        <Controller
                          name={`${controllerName}.${index}.value`}
                          control={control}
                          render={({ field, fieldState }) => {
                            return (
                              <TextField
                                {...field}
                                {...fieldState}
                                defaultValue={keyValuePairValues?.[index]?.value}
                                hasNoDefaultHeight
                                hasNoDefaultMarginBottom
                                helperText={fieldState.error?.message}
                                isFullWidth
                                label={
                                  index === 0
                                    ? (valueLabel ?? intl.formatMessage(KeyValuePairFormMessages.valueLabel))
                                    : undefined
                                }
                                onChange={(value) => {
                                  field.onChange(value)
                                  handleResetPageToDefaultState(value as string, index, 'value')
                                }}
                                type="text"
                              />
                            )
                          }}
                        />
                      </div>
                      <div
                        className={classNames('w-1/12 align-bottom', {
                          'mt-[30px]': index === 0,
                          'mt-[2px]': index !== 0,
                        })}
                      >
                        <Button
                          iconClassName="fa-solid fa-trash"
                          onClick={
                            index === 0 && fields?.length === 0 ? () => remove() : () => handleRemoveKeyValuePair(index)
                          }
                          type="button"
                          variant="red-outlined"
                        />
                      </div>
                    </div>
                  </div>
                )
              })}
            </>
          )}
          {!isBulkEditEnabled && (
            <div className="inline-flex gap-2">
              <Button
                isDisabled={fields?.length === 100}
                onClick={() => append({ [keyLabelLowercase]: '', value: '' })}
                type="button"
                variant="green-filled-light"
              >
                {addButtonLabel}
              </Button>
            </div>
          )}
        </div>
      </form>
    </SidePanelModal>
  )
}
