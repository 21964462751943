import { defineMessages } from 'react-intl'

export const ContainerNoLongerExistsErrorContentMessages = defineMessages({
  body: {
    defaultMessage: 'The logs for the container you are trying to view are no longer available.',
    id: 'T/VD6DsQ',
    description: 'The description shown in a toast notification message shown when the container no longer exists.',
  },
  title: {
    defaultMessage: 'Container No Longer Exists',
    id: 'SxvLYZWn',
    description: 'The toast notification title shown when the container no longer exists.',
  },
})
