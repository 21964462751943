import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { deleteContainerGroup } from '../containerGroupDetail/containerGroupDetailSlice'
import { containerGroupsAddedToOrganization } from '../containerGroups/containerGroupsSlice'
import type { JobQueueTableConnections } from './models'
import { configuresJobQueueConnectionsTableEntityId } from './utils'

interface JobQueueConnectionsTableProps {
  organizationName: string
  projectName: string
  jobQueueName: string
  connections: JobQueueTableConnections
}

export const jobQueueConnectionsTableAdapter = createEntityAdapter<JobQueueConnectionsTableProps>({
  selectId: (item) =>
    configuresJobQueueConnectionsTableEntityId(item.organizationName, item.projectName, item.jobQueueName),
  sortComparer: (a, b) => a.organizationName.localeCompare(b.organizationName),
})

export const jobQueueConnectionsTableSlice = createSlice({
  name: 'jobQueueConnectionsTable',
  initialState: jobQueueConnectionsTableAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(containerGroupsAddedToOrganization, (state, action) => {
      const { containerGroups, organizationName, projectName } = action.payload
      containerGroups.forEach((containerGroup) => {
        if (!containerGroup.queueConnection) return
        const jobQueueName = containerGroup.queueConnection.queueName

        const currentConnections =
          state.entities[configuresJobQueueConnectionsTableEntityId(organizationName, projectName, jobQueueName)]
            ?.connections || []

        if (currentConnections.some((connection) => connection.containerGroupName === containerGroup.name)) return

        jobQueueConnectionsTableAdapter.upsertOne(state, {
          organizationName,
          projectName,
          jobQueueName,
          connections: [
            ...currentConnections,
            {
              containerGroupName: containerGroup.name,
              containerGroupDisplayName: containerGroup.displayName,
              isDeleteConnectionRequestPending: false,
              isDeleteConnectionRequestSuccessful: false,
              path: containerGroup.queueConnection.path,
              port: containerGroup.queueConnection.port,
            },
          ],
        })
      })
    })
    builder.addCase(deleteContainerGroup, (state, action) => {
      const { containerGroupName, jobQueueName, organizationName, projectName } = action.payload
      if (!jobQueueName) return
      const jobQueueConnectionsTableEntityId = configuresJobQueueConnectionsTableEntityId(
        organizationName,
        projectName,
        jobQueueName,
      )
      const jobQueueConnectionsTableEntity = state.entities[jobQueueConnectionsTableEntityId]

      if (jobQueueConnectionsTableEntity) {
        jobQueueConnectionsTableEntity.connections = jobQueueConnectionsTableEntity.connections.filter(
          (connection) => connection.containerGroupName !== containerGroupName,
        )
        jobQueueConnectionsTableAdapter.upsertOne(state, jobQueueConnectionsTableEntity)
      }
    })
  },
})
