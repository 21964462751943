import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { Card } from '../../../../components/base'
import { OutboundRequestsMessages } from '../../messages'

export const OutboundRequests: FunctionComponent = () => {
  const intl = useIntl()

  return (
    <div className="mb-10">
      <h2 className="text-base font-bold">{intl.formatMessage(OutboundRequestsMessages.outboundRequestsTitle)}</h2>
      <h3 className="mb-2">{intl.formatMessage(OutboundRequestsMessages.outboundRequestsDescription)}</h3>
      <Card>
        <p>{intl.formatMessage(OutboundRequestsMessages.outboundRequestsCardText)}</p>
      </Card>
    </div>
  )
}
