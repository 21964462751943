import isEqual from 'lodash/isEqual'
import { concat, distinctUntilChanged, from, NEVER, Observable, of } from 'rxjs'
import type { IToggle, IVariant } from 'unleash-proxy-client'
import { EVENTS, UnleashClient } from 'unleash-proxy-client'

export interface UnleashImpression {
  readonly enabled: boolean
  readonly eventId: string
  readonly eventType: 'isEnabled' | 'getVariant'
  readonly featureName: string
  readonly impressionData?: boolean | undefined
  readonly variant?: string | undefined
}

/** The Unleash service options. */
export interface UnleashServiceOptions {
  /**
   * The Unleash application name.
   *
   * @example `saladcloud-portal`
   */
  readonly appName: string

  /**
   * The Unleash client key or `undefined` to permanently disable Unleash.
   *
   * @example `3hV0ZDadGDx7I4soIsojIBMoSNEixliH`
   */
  readonly clientKey: string | undefined

  /**
   * The collection of static development toggles resolvable by Unleash. When defined, this disables fetching feature
   * flags from the Unleash proxy API. This should be `undefined` to enable fetching feature flags from the Unleash
   * proxy API.
   */
  readonly developmentToggles: IToggle[] | undefined

  /**
   * The Unleash environment.
   *
   * @example `production`
   */
  readonly environment: string

  /** An optional callback function to invoke when a feature flag or variant is resolved. */
  readonly onImpression?: ((impression: UnleashImpression) => void) | undefined

  /**
   * The Unleash proxy API URL or `undefined` to permanently disable Unleash.
   *
   * @example `https://features.salad.com/proxy`
   */
  readonly proxyUrl: string | undefined

  /** The refresh interval in seconds. */
  readonly refreshInterval: number
}

/** The Unleash service. */
export class UnleashService {
  /**
   * Asynchronously creates the Unleash service.
   *
   * @param options The service options.
   * @returns A promise that represents the asynchronous operation. The promise result contains the Unleash service.
   */
  public static create(options: UnleashServiceOptions): Promise<UnleashService> {
    const clientKey = options.clientKey
    const proxyUrl = options.proxyUrl
    if (clientKey === undefined || proxyUrl === undefined) {
      return Promise.resolve(new UnleashService(undefined, Promise.resolve(), true))
    }

    return new Promise<UnleashService>((resolve) => {
      let resolveReady: () => void
      const ready = new Promise<void>((resolve) => {
        resolveReady = resolve
      })
      const onStartup = (): void => {
        client.off(EVENTS.INIT, onStartup).off(EVENTS.ERROR, onStartup)
        resolve(new UnleashService(client, ready, options.developmentToggles !== undefined))
      }
      const client = new UnleashClient({
        appName: options.appName,
        bootstrap: options.developmentToggles,
        bootstrapOverride: options.developmentToggles !== undefined,
        clientKey,
        disableMetrics: options.developmentToggles !== undefined,
        disableRefresh: options.developmentToggles !== undefined,
        environment: options.environment,
        refreshInterval: options.refreshInterval,
        url: proxyUrl,
      })
        .once(EVENTS.INIT, onStartup)
        .once(EVENTS.ERROR, onStartup)
        .once(EVENTS.READY, resolveReady!)
      if (options.onImpression !== undefined) {
        client.on(EVENTS.IMPRESSION, options.onImpression)
      }
    })
  }

  /** The last task started to fetch feature flags. */
  private fetchTask: Promise<void> | undefined

  /**
   * Creates an instance of `UnleashService`.
   *
   * @param client The Unleash client.
   */
  private constructor(
    private readonly client: UnleashClient | undefined,
    private readonly ready: Promise<void>,
    private readonly disableFetch: boolean,
  ) {}

  /**
   * Sets the user context.
   *
   * @param id The user identifier.
   */
  public authenticate(userId: string): void {
    const client = this.client
    if (client === undefined) {
      return
    }

    if (this.fetchTask === undefined) {
      this.fetchTask = this.disableFetch ? Promise.resolve() : client.start()
    }

    this.fetchTask = this.fetchTask.then(() => client.updateContext({ userId }))
  }

  /** Resets the user context. */
  public deauthenticate(): void {
    const client = this.client
    if (client === undefined) {
      return
    }

    if (this.fetchTask === undefined) {
      this.fetchTask = this.disableFetch ? Promise.resolve() : client.start()
    }

    this.fetchTask = this.fetchTask.then(() => client.updateContext({}))
  }

  /**
   * Gets an observable that emits the feature flag whenever it changes.
   *
   * @param name The feature flag name.
   * @param waitForReady A value indicating whether to wait for the Unleash client to complete its initial fetch.
   * @returns An observable that emits the feature flag whenever it changes.
   */
  public getStream(name: string, waitForReady: boolean = false): Observable<IVariant> {
    const client = this.client
    if (client === undefined) {
      return concat(of({ enabled: false, name: 'disabled' }), NEVER)
    }

    const stream = new Observable<IVariant>((subscriber) => {
      subscriber.next(client.getVariant(name))

      const listener = () => {
        subscriber.next(client.getVariant(name))
      }
      client.on(EVENTS.UPDATE, listener)

      return () => {
        client.off(EVENTS.UPDATE, listener)
      }
    }).pipe(distinctUntilChanged(isEqual))
    return waitForReady ? concat(from(this.ready), stream) : stream
  }
}
