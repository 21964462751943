import { InferenceEndpoint } from '@saladtechnologies/openapi-cloud-portal-browser'
import type { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { SaladVerified } from '../SaladVerified'

export interface InferenceEndpointCardProps {
  /** The inference endpoint item to display. */
  inferenceEndpoint: InferenceEndpoint
  /** The callback called when the reci;pe card is clicked. */
  path: string
}

export const InferenceEndpointCard: FunctionComponent<InferenceEndpointCardProps> = ({ inferenceEndpoint, path }) => {
  const { description, displayName, iconImage, name } = inferenceEndpoint
  const inferenceEndpointName = displayName || name
  return (
    <Link
      className="flex w-full flex-row flex-nowrap overflow-hidden rounded-lg shadow-md hover:shadow-green-30 focus-visible:shadow-green-30 focus-visible:outline-none"
      to={path}
    >
      <img src={iconImage} alt={inferenceEndpointName} className="size-64 object-cover" />
      <div className="flex w-full flex-col p-4">
        <div className="flex items-center justify-between space-x-2">
          <p className="text-lg font-bold">{inferenceEndpointName}</p>
          <SaladVerified />
        </div>
        <p className="mt-2 text-sm">{description}</p>
      </div>
    </Link>
  )
}
