import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { ValuePropositionMessages } from '../messages'
import { ValuePropositionItem } from './ValuePropositionItem'

export const ValuePropositionList: FunctionComponent = () => {
  const intl = useIntl()

  return (
    <div className="max-w-xl pl-8">
      <ul className="flex flex-col gap-10">
        <ValuePropositionItem
          iconClassName="fa-solid fa-piggy-bank"
          title={intl.formatMessage(ValuePropositionMessages.reduceBudgetTitle)}
          description={intl.formatMessage(ValuePropositionMessages.reduceBudgetDescription)}
        />
        <ValuePropositionItem
          iconClassName="fa-solid fa-microchip"
          title={intl.formatMessage(ValuePropositionMessages.gpuDrivenProcessingTitle)}
          description={intl.formatMessage(ValuePropositionMessages.gpuDrivenProcessingDescription)}
        />
        <ValuePropositionItem
          iconClassName="fa-solid fa-chart-line"
          title={intl.formatMessage(ValuePropositionMessages.onDemandElasticityTitle)}
          description={intl.formatMessage(ValuePropositionMessages.onDemandElasticityDescription)}
        />
      </ul>
    </div>
  )
}
