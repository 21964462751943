import type { FunctionComponent } from 'react'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { Button } from '../../../../components/Button'
import { AccountSettingsMainContentMessages } from '../../messages'
import { AccountCard } from '../AccountCard'
import { DeleteAccountModal } from '../DeleteAccountModal'

interface AccountSettingsMainContentProps {
  /** The date the account was created. */
  createdDate: Date
  /** The user's email. */
  email: string
  /** The flag indicating that the request to delete the account is pending. */
  isDeleteAccountPending: boolean
  /** The callback executed when the user clicks the `Delete` button. */
  onDeleteAccount: (password: string) => void
}

export const AccountSettingsMainContent: FunctionComponent<AccountSettingsMainContentProps> = ({
  createdDate,
  email,
  isDeleteAccountPending,
  onDeleteAccount,
}) => {
  const intl = useIntl()
  const [isDeleteAccountModalOpen, setIsDeleteAccountModalOpen] = useState<boolean>(false)

  const handleShowDeleteAccountModal = () => {
    setIsDeleteAccountModalOpen(true)
  }

  const handleCloseDeleteAccountModal = () => {
    setIsDeleteAccountModalOpen(false)
  }

  return (
    <div className="w-full max-w-2xl">
      <h1 className="mb-12 text-3xl font-bold">{intl.formatMessage(AccountSettingsMainContentMessages.title)}</h1>

      <div className="mb-16">
        <h2 className="mb-4 text-xl font-bold">
          {intl.formatMessage(AccountSettingsMainContentMessages.yourAccountHeader)}
        </h2>
        <AccountCard createdDate={createdDate} email={email} />
      </div>

      <h2 className="mb-4 text-xl font-bold">
        {intl.formatMessage(AccountSettingsMainContentMessages.deleteAccountHeader)}
      </h2>
      <Button variant="red-outlined" onClick={handleShowDeleteAccountModal}>
        {intl.formatMessage(AccountSettingsMainContentMessages.deleteAccountButtonLabel)}
      </Button>

      {isDeleteAccountModalOpen && (
        <DeleteAccountModal
          email={email}
          isDeleteAccountPending={isDeleteAccountPending}
          onCloseWindow={handleCloseDeleteAccountModal}
          onDeleteAccount={onDeleteAccount}
        />
      )}
    </div>
  )
}
