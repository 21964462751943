import { defineMessages } from 'react-intl'

export const RestartConfirmationModalMessages = defineMessages({
  title: {
    defaultMessage: 'Restart the container',
    id: '7cSGPehl',
    description: 'Title of the restart instance modal',
  },
  body: {
    defaultMessage:
      'Restarts the container. Least destructive and quickest action. Use this when there is a problem with the process running the image.',
    id: '1p6zxk5S',
    description: 'Description of the restart instance modal',
  },
  cancel: {
    defaultMessage: 'Cancel',
    id: 'hHNj31G9',
    description: 'Cancel button text',
  },
  delete: {
    defaultMessage: 'Restart',
    id: 'DQZWveFl',
    description: 'Restart button text',
  },
})
