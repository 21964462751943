import { defineMessages } from 'react-intl'

export const EditImageSourceInputMessages = defineMessages({
  dockerManifestHelperText: {
    defaultMessage: 'Only Docker Manifests are supported. {learn_more_here_link}.',
    id: 'D+SSw+Vo',
    description: 'The helper text for the image source fields detailing information about the docker manifest support.',
  },
  dockerManifestHelperTextLinkText: {
    defaultMessage: 'Learn more here',
    id: 'NPgN2Gm7',
    description: 'The link text for the docker manifest helper text.',
  },
  imageSourceHelperText: {
    defaultMessage: 'The name of the container image to deploy. {learn_more}',
    id: 'c9EBQ+C9',
    description: 'The helper text for the image source URL field.',
  },
  imageSourceHelperLearnMoreLinkText: {
    defaultMessage: 'Learn more',
    id: '776T26wB',
    description: 'The helper text for the link learn more about image source URL field.',
  },
  imageSourceInvalidText: {
    defaultMessage: 'Please provide a valid URL to the image',
    id: 'rMpqeUv/',
    description: 'The error text that shows for the container group image source field if it is not a valid entry.',
  },
  imageSourceLabel: {
    defaultMessage: 'URL',
    id: '67qGgh21',
    description: 'The label for the container group image source name field.',
  },
  imageSourcePlaceholderText: {
    defaultMessage: 'optional-domain/example-org/image:tag',
    id: 'zofR1Gfk',
    description: 'The placeholder text for the container group image source field.',
  },
  imageSourceRequiredText: {
    defaultMessage: 'Image Name is required',
    id: 'tjQ348v8',
    description: 'The error text that shows for the container group image source name field if one is not provided.',
  },
  imageSourceSidePanelTitle: {
    defaultMessage: 'Image Source',
    id: '+D62j91D',
    description:
      'The title for the dialog box that opens when a user wants to edit image source fields on the create container group page.',
  },
})
