export enum TableColumnWidth {
  /* 8.333333% */
  OneTwelfth = 'w-1/12',
  /* 16.666667% */
  TwoTwelfths = 'w-2/12',
  /* 25% */
  ThreeTwelfths = 'w-3/12',
  /* 33.333333% */
  FourTwelfths = 'w-4/12',
  /* 41.666667% */
  FiveTwelfths = 'w-5/12',
  /* 50% */
  Half = 'w-6/12',
  /* 58.333333% */
  SevenTwelfths = 'w-7/12',
  /* 66.666667% */
  EightTwelfths = 'w-8/12',
  /* 75% */
  NineTwelfths = 'w-9/12',
  /* 83.333333% */
  TenTwelfths = 'w-10/12',
  /* 91.666667% */
  ElevenTwelfths = 'w-11/12',
  /* 100% */
  Full = 'w-12/12',
}
