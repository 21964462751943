import type { JobQueueTableConnections } from '../../../../features/jobQueueConnectionsTable/models'

/**
 * Returns a string of the connected container groups separated by a comma.
 *
 * @param connections The Job Queue connections.
 */
export const getConnectedContainerGroupList = (connections: JobQueueTableConnections): string => {
  return connections.length === 1 && connections[0]
    ? connections[0].containerGroupDisplayName
    : connections.map((connections) => connections.containerGroupDisplayName).join(', ')
}
