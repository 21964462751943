import { defineMessages } from 'react-intl'

export const OrganizationCardMessages = defineMessages({
  createdDate: {
    defaultMessage: 'Created on {created_date}',
    id: 'fBXvD5Wx',
    description: 'The label for the created date.',
  },
  deleteButtonLabel: {
    defaultMessage: 'Delete',
    id: 'n0HIoN4X',
    description: 'The label for the delete button.',
  },
  editDisplayNameButtonLabel: {
    defaultMessage: 'Edit Display Name',
    id: 'O00YD83G',
    description: 'The label for the edit display name button.',
  },
  manageButtonLabel: {
    defaultMessage: 'Manage Team',
    id: '8WlOj38n',
    description: 'The label for the manage team button.',
  },
  memberCountPill: {
    defaultMessage: '{organization_member_count} members',
    id: 'fnrjiRky',
    description: 'The label for the members pill.',
  },
  oneMemberCountPill: {
    defaultMessage: '{organization_member_count} member',
    id: 'Jq9nxi+y',
    description: 'The label for the members pill.',
  },
  oneProjectCountPill: {
    defaultMessage: '{organization_project_count} project',
    id: 'wPk43P5F',
    description: 'The label for the projects pill.',
  },
  projectCountPill: {
    defaultMessage: '{organization_project_count} projects',
    id: 'hTgXHwc7',
    description: 'The label for the projects pill.',
  },
  saladOrgIconAlt: {
    defaultMessage: 'Salad Org Icon',
    id: 'ZcHrYJqW',
    description: 'The alt text for the salad org icon.',
  },
  switchButtonLabel: {
    defaultMessage: 'Switch to this Organization',
    id: 'pjZdS7fh',
    description: 'The label for the switch button.',
  },
})
