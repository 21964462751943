import { defineMessages } from 'react-intl'

export const WorkloadErrorsTableMessages = defineMessages({
  allocatedAtHeader: {
    id: 'N9DDfu3R',
    defaultMessage: 'Allocated At',
    description: 'The workload errors table allocated at header',
  },
  detailsHeader: {
    id: 'v1cCKiC7',
    defaultMessage: 'Details',
    description: 'The workload errors table details header',
  },
  failedAtHeader: {
    id: 'm4DEh+4F',
    defaultMessage: 'Failed At',
    description: 'The workload errors table failed at header',
  },
  machineIdHeader: {
    id: 'deKPF+YM',
    defaultMessage: 'Machine ID',
    description: 'The workload errors table machine ID header',
  },
  startedAtHeader: {
    id: 'so5h/DPw',
    defaultMessage: 'Started At',
    description: 'The workload errors table started at header',
  },
})
