import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { Button } from '../../../../components/Button'
import { ButtonMessages } from '../../../../components/Button/messages'
import { Modal } from '../../../../components/block/Modal'
import { DeleteRecipeDeploymentModalMessages } from '../../messages'

export interface DeleteRecipeDeploymentModalProps {
  /** The flag indicating that the request to delete the recipe deployment is pending. */
  isDeleteRecipeDeploymentPending: boolean
  /** The callback executed when the user clicks the `Cancel` button. */
  onCancel: () => void
  /** The callback executed when the user clicks the `Delete Recipe Deployment` button. */
  onDeleteRecipeDeployment: () => void
  /** The display name of the recipe deployment the user wants to delete. */
  recipeDeploymentDisplayName: string
}

export const DeleteRecipeDeploymentModal: FunctionComponent<DeleteRecipeDeploymentModalProps> = ({
  isDeleteRecipeDeploymentPending,
  onCancel,
  onDeleteRecipeDeployment,
  recipeDeploymentDisplayName,
}) => {
  const intl = useIntl()

  return (
    <Modal
      onClose={onCancel}
      title={intl.formatMessage(DeleteRecipeDeploymentModalMessages.title, {
        recipe_deployment_display_name: recipeDeploymentDisplayName,
      })}
    >
      <h1 className="mb-8 text-3xl font-bold">
        {intl.formatMessage(DeleteRecipeDeploymentModalMessages.title, {
          recipe_deployment_display_name: recipeDeploymentDisplayName,
        })}
      </h1>
      <p className="mb-8">{intl.formatMessage(DeleteRecipeDeploymentModalMessages.description)}</p>
      <div className="flex max-w-md gap-6">
        <Button variant="green-outlined" onClick={onCancel} isFullWidth>
          {intl.formatMessage(DeleteRecipeDeploymentModalMessages.cancelButtonLabel)}
        </Button>
        <Button
          variant="red-filled"
          onClick={onDeleteRecipeDeployment}
          isLoading={isDeleteRecipeDeploymentPending}
          isFullWidth
          countdownSeconds={3}
        >
          {intl.formatMessage(ButtonMessages.deleteButtonLabel)}
        </Button>
      </div>
    </Modal>
  )
}
