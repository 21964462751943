import { defineMessages } from 'react-intl'

export const ContainerGroupInstanceTerminalNodeNotScheduledErrorMessages = defineMessages({
  title: {
    defaultMessage: 'Unable to connect to terminal',
    id: 'Jk/8keEa',
    description:
      'The toast notification title shown when we are unable to connect to the terminal because the node is not scheduled',
  },
  body: {
    defaultMessage: 'We were unable to connect to the terminal because your node is not scheduled',
    id: 'LHxdDKMw',
    description:
      'The toast notification description shown when we are unable to connect to the terminal because the node is not scheduled',
  },
})
