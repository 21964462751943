import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { Button } from '../../Button'
import { ButtonLink } from '../../base/ButtonLink'
import SaladConfettiLogo from './assets/SaladConfettiLogo.svg'
import { EmptyResultMessages } from './messages'

interface EmptyResultProps {
  /** A Font Awesome icon class name (e.g. `fa-envelope`) that will show to the left of the button label. */
  buttonIconClassName?: string
  /** The label for the button. */
  buttonLabel: string
  /** The aria role for the button. */
  buttonRole?: React.AriaRole | undefined
  /** Clicking the button will lead users to this path. */
  buttonPath?: string
  /** The bolded message that the user sees when there are no results to display on the page. */
  mainMessage: string
  /** The button click handler. */
  onButtonClick?: () => void
  /** The message that users see below the main message when there are no results to display on the page. */
  subMessage?: string
}

export const EmptyResult: FunctionComponent<EmptyResultProps> = ({
  buttonIconClassName,
  buttonLabel,
  buttonPath,
  buttonRole,
  mainMessage,
  onButtonClick,
  subMessage,
}) => {
  const intl = useIntl()
  return (
    <div className="mt-28 flex w-full flex-col items-center">
      <img className="mb-4" src={SaladConfettiLogo} alt={intl.formatMessage(EmptyResultMessages.logoAlt)} />
      <h1
        className={classNames('mb-3 max-w-2xl text-center text-3xl font-bold', {
          'mb-4': !subMessage,
        })}
      >
        {mainMessage}
      </h1>
      {subMessage && <p className="mb-4 text-center">{subMessage}</p>}
      <div className="w-full max-w-xs">
        {buttonPath ? (
          <ButtonLink
            url={buttonPath}
            iconClassName={buttonIconClassName}
            isFullWidth
            variant="green-filled-light"
            role={buttonRole}
          >
            {buttonLabel}
          </ButtonLink>
        ) : (
          <Button
            onClick={onButtonClick}
            iconClassName={buttonIconClassName}
            isFullWidth
            variant="green-filled-light"
            role={buttonRole}
          >
            {buttonLabel}
          </Button>
        )}
      </div>
    </div>
  )
}
