import { defineMessages } from 'react-intl'

export const CreateContainerGroupInvalidRegistryErrorContentMessages = defineMessages({
  body: {
    defaultMessage: 'The image source provided does not seem to be valid, please provide a valid image source URL.',
    id: 'Hhy2eAE7',
    description:
      'The description shown in a toast notification message shown when we encounter an error on their request to create a container group with an invalid registry',
  },
  title: {
    defaultMessage: 'Invalid Image',
    id: 'OcGJ1ga9',
    description:
      'The toast notification title shown when we encounter an error on their request to create a container group with an invalid registry.',
  },
})
