import type { FunctionComponent } from 'react'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { DeleteProjectModal } from '../../pages/Projects/components/DeleteProjectModal/DeleteProjectModal'
import { getCreateProjectPagePath, getEditProjectPagePath } from '../../routes/routes-utils'
import { Button } from '../Button'
import { SaladLogo } from '../SaladLogo'
import { ButtonLink } from '../base/ButtonLink'
import { Select } from '../forms/Select'
import { MenuItems, StorybookMenuItems } from './components'
import { LeftNavigationBarMessages } from './messages'
import type { ProjectSelectOptions } from './models'

export interface LeftNavigationBarProps {
  /** The current organization that the user is in. */
  currentOrganization?: { name: string; displayName: string }
  /** The current project that the user is in. */
  currentProject?: { name: string; displayName: string }
  /** The callback executed when the user clicks the contact support button. */
  isDeleteProjectPending?: boolean
  /** The flag indicating that the request to delete the project is successful. */
  isDeleteProjectSuccessful?: boolean
  /** A flag indicating the projects select placeholder option is selectable */
  isProjectSelectPlaceholderOptionSelectable?: boolean
  /** The flag indicating that the request to delete the project is pending. */
  onDeleteProject?: (projectName: string) => void
  /**
   * The handler for when something new is selected in the project dropdown. If the current active project is selected,
   * nothing will happen.
   */
  onProjectChange?: (projectName: string) => void
  /** An array of the current available projects. If empty, we will default to no select bar being displayed */
  projects?: ProjectSelectOptions
}

export const LeftNavigationBar: FunctionComponent<LeftNavigationBarProps> = ({
  currentOrganization,
  currentProject,
  isDeleteProjectPending,
  isDeleteProjectSuccessful,
  isProjectSelectPlaceholderOptionSelectable,
  onDeleteProject,
  onProjectChange,
  projects,
}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const [isDeleteProjectModalOpen, setIsDeleteProjectModalOpen] = useState(false)
  const [selectedProject, setSelectedProject] = useState<string | undefined>(currentProject?.name)
  let isStorybook = false
  if (window.location.port === '6006' || window.location.host === 'preview-portal-ui.salad.com') {
    isStorybook = true
  }

  const handleOpenDeleteProjectModal = () => {
    setIsDeleteProjectModalOpen(true)
  }
  const handleCancelDeleteProject = () => {
    setIsDeleteProjectModalOpen(false)
  }
  const handleDeleteProject = () => {
    if (selectedProject && onDeleteProject) {
      onDeleteProject(selectedProject)
    }
  }
  const handleNavigateToCreateProjectPage = () => {
    if (currentOrganization !== undefined) {
      navigate(getCreateProjectPagePath(currentOrganization.name))
    }
  }

  const createProjectLabel = intl.formatMessage(LeftNavigationBarMessages.createProjectLabel)

  const handleProjectChange = (projectName?: string) => {
    setSelectedProject(projectName)
    if (onProjectChange && projectName) {
      projectName === createProjectLabel ? handleNavigateToCreateProjectPage() : onProjectChange(projectName)
    }
  }

  const createProjectOption = {
    displayName: createProjectLabel,
    name: createProjectLabel,
    selected: false,
  }

  const projectsWithCreateProjectOption = projects && [...projects, createProjectOption]

  useEffect(() => {
    setIsDeleteProjectModalOpen(false)
  }, [isDeleteProjectSuccessful])

  return (
    <>
      {isDeleteProjectModalOpen && currentProject && (
        <DeleteProjectModal
          isDeleteProjectPending={!!isDeleteProjectPending}
          projectName={currentProject.name}
          onCancel={handleCancelDeleteProject}
          onDeleteProject={handleDeleteProject}
        />
      )}
      <nav className="flex max-w-sm flex-col gap-4">
        <div className="mb-2 mt-8 px-6">
          <Link className="sm:hidden" to="/organizations">
            <SaladLogo isDark className="mb-6 w-24" height="100%" width={96} />
          </Link>
          {currentOrganization?.displayName && (
            <p className="text-xs font-medium text-neutral-100">{currentOrganization.displayName}</p>
          )}
        </div>

        {currentOrganization === undefined ? null : (
          <div className="flex flex-col gap-1.5 px-6">
            {projectsWithCreateProjectOption ? (
              <div className="flex flex-col">
                <Select
                  hideLabelText
                  isFullWidth
                  isPlaceholderOptionDisabled={!isProjectSelectPlaceholderOptionSelectable}
                  labelText={intl.formatMessage(LeftNavigationBarMessages.selectProjectDropdownLabel)}
                  defaultSelectedValue={currentProject?.name}
                  options={projectsWithCreateProjectOption.map((project) => ({
                    label: project.displayName,
                    value: project.name,
                  }))}
                  onChange={handleProjectChange}
                />
                <div className="mt-2 flex gap-2">
                  <ButtonLink
                    iconClassName="fa-solid fa-edit"
                    isDisabled={selectedProject === undefined}
                    isFullWidth
                    url={getEditProjectPagePath(currentOrganization.name, selectedProject ?? '')}
                    variant="blue-filled-light"
                  >
                    {intl.formatMessage(LeftNavigationBarMessages.editProjectLabel)}
                  </ButtonLink>
                  <Button
                    iconClassName="fa-solid fa-trash"
                    isDisabled={selectedProject === undefined}
                    isFullWidth
                    onClick={handleOpenDeleteProjectModal}
                    variant="red-filled-light"
                  >
                    {intl.formatMessage(LeftNavigationBarMessages.deleteProjectLabel)}
                  </Button>
                </div>
              </div>
            ) : (
              <ButtonLink
                isFullWidth
                url={getCreateProjectPagePath(currentOrganization.name)}
                variant="green-filled-light"
              >
                {createProjectLabel}
              </ButtonLink>
            )}
          </div>
        )}
        {isStorybook ? (
          <StorybookMenuItems organizationName={currentOrganization?.name} projectName={currentProject?.name} />
        ) : (
          <MenuItems />
        )}
      </nav>
    </>
  )
}
