import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { RecipeDeployment } from '@saladtechnologies/openapi-cloud-portal-browser'

export interface RecipeDeploymentsState {
  deployedRecipes: RecipeDeployment[]
}

const initialState: RecipeDeploymentsState = {
  deployedRecipes: [],
}

export const recipeDeploymentsSlice = createSlice({
  name: 'recipeDeployments',
  initialState,
  reducers: {
    getDeployedRecipesPageData(
      _state,
      _action: PayloadAction<{ organizationName: string; projectName: string; firstRequest?: boolean }>,
    ) {},
    setDeployedRecipes(state, action: PayloadAction<{ deployedRecipes: RecipeDeployment[] }>) {
      state.deployedRecipes = action.payload.deployedRecipes
    },
  },
})

export const { getDeployedRecipesPageData, setDeployedRecipes } = recipeDeploymentsSlice.actions
