import { defineMessages } from 'react-intl'

export const DatePickerRangeMessages = defineMessages({
  datePlaceholder: {
    defaultMessage: 'Select A Date',
    id: 'VE89REQ7',
    description: 'The default placeholder text for the start date',
  },
  startDateLabel: {
    defaultMessage: 'Start Date',
    id: 'a47U91fc',
    description: 'The default label for the start date',
  },
  endDateLabel: {
    defaultMessage: 'End Date',
    id: '8kehYoNC',
    description: 'The default label text for the end date',
  },
  button5minutes: {
    defaultMessage: '5 min',
    id: '2aehx6TY',
    description: 'Label for the button to select a 5-minute time range',
  },
  button30minutes: {
    defaultMessage: '30 min',
    id: 'EEcSAfRe',
    description: 'Label for the button to select a 30-minute time range',
  },
  button1hour: {
    defaultMessage: '1 hour',
    id: '/GDz/v7O',
    description: 'Label for the button to select a 1-hour time range',
  },
  button1day: {
    defaultMessage: '1 day',
    id: 'hCp0WLLI',
    description: 'Label for the button to select a 1-day time range',
  },
  button3days: {
    defaultMessage: '3 days',
    id: 'akkB+G0r',
    description: 'Label for the button to select a 3-day time range',
  },
  button7days: {
    defaultMessage: '7 days',
    id: 'XjBMC8Bk',
    description: 'Label for the button to select a 7-day time range',
  },
  buttonCustom: {
    defaultMessage: 'Custom',
    id: 'XdKuNLo2',
    description: 'Label for the button to select a custom range',
  },
})
