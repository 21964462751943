import { CreateQueueProblemType, ResponseError } from '@saladtechnologies/openapi-cloud-portal-browser'
import { catchError, concat, delay, filter, from, mergeMap, of } from 'rxjs'
import { QueuesAPI, assertUnreachable } from '../apiMethods'
import { createJobQueue } from '../features/jobQueues/jobQueuesSlice'
import { showToastNotification } from '../features/notifications/notificationsSlice'
import { setRequestStatus } from '../features/requestStatus/requestStatusSlice'
import {
  getCreateJobQueueErrorContent,
  getCreateJobQueueLimitErrorContent,
  getCreateJobQueueNameConflictErrorContent,
} from '../notifications/clientToastNotificationContent/jobQueues'
import { getJobQueueDetailsPagePath } from '../routes/routes-utils'
import type { AppEpic } from '../store'
import { navigateTo } from './navigationEpic'

export const onCreateJobQueue: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(createJobQueue.match),
    mergeMap(({ payload: { createQueue, organizationName, projectName } }) =>
      concat(
        of(setRequestStatus({ request: 'createJobQueue', status: 'pending' })),
        from(
          QueuesAPI.createQueue({
            organizationName,
            projectName,
            createQueue,
          }),
        ).pipe(
          mergeMap(() => {
            return concat(
              of(
                setRequestStatus({ request: 'createJobQueue', status: 'succeeded' }),
                navigateTo({ path: getJobQueueDetailsPagePath(organizationName, projectName, createQueue.name) }),
              ),
              of(setRequestStatus({ request: 'createJobQueue', status: 'idle' })).pipe(delay(1)),
            )
          }),
          catchError((error: unknown) => {
            return error instanceof ResponseError && error.response.status === 400
              ? from(error.response.json()).pipe(
                  mergeMap((errorResponse) => {
                    const errorType = errorResponse.type as CreateQueueProblemType | null
                    if (errorType != null && errorType !== CreateQueueProblemType.Null) {
                      switch (errorType) {
                        case CreateQueueProblemType.QueueNameConflict:
                          return concat(
                            of(
                              showToastNotification(getCreateJobQueueNameConflictErrorContent(intl)),
                              setRequestStatus({ request: 'createContainerGroup', status: 'failed' }),
                            ),
                            of(setRequestStatus({ request: 'createContainerGroup', status: 'idle' })).pipe(delay(1)),
                          )
                        case CreateQueueProblemType.QueueLimitExceeded:
                          return concat(
                            of(
                              showToastNotification(getCreateJobQueueLimitErrorContent(intl)),
                              setRequestStatus({ request: 'createJobQueue', status: 'failed' }),
                            ),
                            of(setRequestStatus({ request: 'createJobQueue', status: 'idle' })).pipe(delay(1)),
                          )
                        default:
                          assertUnreachable(errorType)
                      }
                    } else {
                      return concat(
                        of(
                          setRequestStatus({ request: 'createJobQueue', status: 'failed' }),
                          showToastNotification(getCreateJobQueueErrorContent(intl)),
                        ),
                        of(setRequestStatus({ request: 'editJobQueue', status: 'idle' })).pipe(delay(1)),
                      )
                    }
                  }),
                )
              : concat(
                  of(
                    setRequestStatus({ request: 'createJobQueue', status: 'failed' }),
                    showToastNotification(getCreateJobQueueErrorContent(intl)),
                  ),
                  of(setRequestStatus({ request: 'editJobQueue', status: 'idle' })).pipe(delay(1)),
                )
          }),
        ),
      ),
    ),
  )
