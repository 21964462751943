import { QueryContainerGroupLogsProblemType, ResponseError } from '@saladtechnologies/openapi-cloud-portal-browser'
import { catchError, concat, delay, filter, from, mergeMap, of, switchMap } from 'rxjs'
import { ContainerGroupsAPI } from '../apiMethods'
import {
  getContainerGroupAndInstances,
  setContainerGroup,
} from '../features/containerGroupDetail/containerGroupDetailSlice'
import { containerGroupInstancesAdded } from '../features/containerGroupInstances/containerGroupInstancesSlice'
import { containerGroupLogsAdded, getContainerGroupLogs } from '../features/containerGroupLogs/containerGroupLogsSlice'
import { showToastNotification } from '../features/notifications/notificationsSlice'
import { setRequestStatus } from '../features/requestStatus/requestStatusSlice'
import { getUnableToGetContainerGroupAndInstancesErrorContent } from '../notifications/clientToastNotificationContent/containerGroupDetails'
import {
  GetContainerGroupLogsAxiomTimeouErrorContent,
  getContainerGroupLogsConnectionTimeoutErrorContent,
  getContainerGroupLogsGeneralErrorContent,
  getContainerGroupLogsNotFoundErrorContent,
  getContainerGroupLogsOrganizationNotFoundErrorContent,
} from '../notifications/clientToastNotificationContent/containerGroupLogs'
import { getContainerGroupsPagePath, getOrganizationsPagePath } from '../routes/routes-utils'
import type { AppEpic } from '../store'
import { navigateTo } from './navigationEpic'

export const onGetContainerGroupAndInstances: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(getContainerGroupAndInstances.match),
    switchMap(({ payload: { containerGroupName, organizationName, projectName } }) =>
      concat(
        of(setRequestStatus({ request: 'getContainerGroupAndInstances', status: 'pending' })),
        from(
          Promise.all([
            ContainerGroupsAPI.getContainerGroup({
              containerGroupName,
              organizationName,
              projectName,
            }),
            ContainerGroupsAPI.listContainerGroupInstances({
              containerGroupName,
              organizationName,
              projectName,
            }),
          ]),
        ).pipe(
          mergeMap(([containerGroupResponse, ContainerGroupInstancesResponse]) =>
            concat(
              of(
                setContainerGroup({ containerGroup: containerGroupResponse }),
                containerGroupInstancesAdded({
                  containerGroupName,
                  organizationName,
                  projectName,
                  instances: ContainerGroupInstancesResponse.instances,
                }),
                setRequestStatus({ request: 'getContainerGroupAndInstances', status: 'succeeded' }),
              ),
              of(setRequestStatus({ request: 'getContainerGroupAndInstances', status: 'idle' })).pipe(delay(1)),
            ),
          ),
          catchError(() =>
            concat(
              of(
                showToastNotification(getUnableToGetContainerGroupAndInstancesErrorContent(intl)),
                setRequestStatus({ request: 'getContainerGroupAndInstances', status: 'failed' }),
              ),
              of(setRequestStatus({ request: 'getContainerGroupAndInstances', status: 'idle' })).pipe(delay(1)),
            ),
          ),
        ),
      ),
    ),
  )

export const onGetContainerGroupLogs: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(getContainerGroupLogs.match),
    switchMap(({ payload: { containerGroupLogsQuery, containerGroupName, organizationName, projectName } }) =>
      concat(
        of(setRequestStatus({ request: 'getContainerGroupLogs', status: 'pending' })),
        from(
          ContainerGroupsAPI.queryContainerGroupLogs({
            containerGroupName,
            organizationName,
            projectName,
            containerGroupLogsQuery,
          }),
        ).pipe(
          mergeMap((containerGroupLogsResponse) =>
            concat(
              of(
                containerGroupLogsAdded({
                  containerGroupName,
                  organizationName,
                  projectName,
                  containerGroupLogs: containerGroupLogsResponse.items,
                }),
                setRequestStatus({ request: 'getContainerGroupLogs', status: 'succeeded' }),
              ),
              of(setRequestStatus({ request: 'getContainerGroupLogs', status: 'idle' })).pipe(delay(1)),
            ),
          ),
          catchError((error: unknown) =>
            concat(
              error instanceof ResponseError
                ? from(error.response.json()).pipe(
                    mergeMap((errorResponse) => {
                      const errorType = errorResponse.type as QueryContainerGroupLogsProblemType | null
                      switch (errorType) {
                        case QueryContainerGroupLogsProblemType.ConnectionTimeout:
                          return concat(
                            of(
                              showToastNotification(getContainerGroupLogsConnectionTimeoutErrorContent(intl)),
                              setRequestStatus({ request: 'getContainerGroupLogs', status: 'failed' }),
                            ),
                            of(setRequestStatus({ request: 'getContainerGroupLogs', status: 'idle' })).pipe(delay(1)),
                          )
                        case QueryContainerGroupLogsProblemType.LogApiFailed:
                          return concat(
                            of(
                              showToastNotification(GetContainerGroupLogsAxiomTimeouErrorContent(intl)),
                              setRequestStatus({ request: 'getContainerGroupLogs', status: 'failed' }),
                            ),
                            of(setRequestStatus({ request: 'getContainerGroupLogs', status: 'idle' })).pipe(delay(1)),
                          )
                        case QueryContainerGroupLogsProblemType.OrganizationNotFound:
                          return concat(
                            of(
                              showToastNotification(getContainerGroupLogsOrganizationNotFoundErrorContent(intl)),
                              setRequestStatus({ request: 'getContainerGroupLogs', status: 'failed' }),
                            ),
                            of(setRequestStatus({ request: 'getContainerGroupLogs', status: 'idle' })).pipe(delay(1)),
                            of(
                              navigateTo({
                                path: getOrganizationsPagePath(),
                              }),
                            ),
                          )
                        case QueryContainerGroupLogsProblemType.ContainerGroupNotFound:
                          return concat(
                            of(
                              showToastNotification(getContainerGroupLogsNotFoundErrorContent(intl)),
                              setRequestStatus({ request: 'getContainerGroupLogs', status: 'failed' }),
                            ),
                            of(setRequestStatus({ request: 'getContainerGroupLogs', status: 'idle' })).pipe(delay(1)),
                            of(
                              navigateTo({
                                path: getContainerGroupsPagePath(organizationName, projectName),
                              }),
                            ),
                          )
                        default:
                          return concat(
                            of(
                              showToastNotification(getContainerGroupLogsGeneralErrorContent(intl)),
                              setRequestStatus({ request: 'getContainerGroupLogs', status: 'failed' }),
                            ),
                            of(setRequestStatus({ request: 'getContainerGroupLogs', status: 'idle' })).pipe(delay(1)),
                          )
                      }
                    }),
                  )
                : concat(
                    of(
                      showToastNotification(getContainerGroupLogsGeneralErrorContent(intl)),
                      setRequestStatus({ request: 'getContainerGroupLogs', status: 'failed' }),
                    ),
                    of(setRequestStatus({ request: 'getContainerGroupLogs', status: 'idle' })).pipe(delay(1)),
                  ),
            ),
          ),
        ),
      ),
    ),
  )
