import { Fragment, type FunctionComponent } from 'react'
import { Tag } from '../../Tag'

interface BreadcrumbProps {
  /** The breadcrumbs7. */
  breadcrumbs: string[]
}

const breadcrumbSeparator = '>'

export const Breadcrumbs: FunctionComponent<BreadcrumbProps> = ({ breadcrumbs }) => {
  return (
    <div className="flex flex-wrap items-center gap-2">
      {breadcrumbs.map((breadcrumb, index) => (
        <Fragment key={breadcrumb}>
          <Tag color="white">{breadcrumb}</Tag>
          {index < breadcrumbs.length - 1 && <span>{breadcrumbSeparator}</span>}
        </Fragment>
      ))}
    </div>
  )
}
