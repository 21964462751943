import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { Button } from '../../../../components/Button'
import { Modal } from '../../../../components/block/Modal'
import { ChangeAPIKeyModalMessages } from '../../messages'

export interface ChangeAPIKeyModalProps {
  /** The flag indicating that the request to change a user's API Key is pending. */
  isChangeAPIKeyPending: boolean
  /** The callback executed when the user clicks the `Cancel` button. */
  onCancel: () => void
  /** The callback executed when the user clicks the `Change API Key` button. */
  onChangeAPIKey: () => void
}

export const ChangeAPIKeyModal: FunctionComponent<ChangeAPIKeyModalProps> = ({
  isChangeAPIKeyPending,
  onCancel,
  onChangeAPIKey,
}) => {
  const intl = useIntl()

  return (
    <Modal onClose={onCancel} title={intl.formatMessage(ChangeAPIKeyModalMessages.title)}>
      <h1 className="mb-8 text-2xl font-bold">{intl.formatMessage(ChangeAPIKeyModalMessages.title)}</h1>
      <p className="mb-8">{intl.formatMessage(ChangeAPIKeyModalMessages.description)}</p>
      <div className="flex max-w-md gap-6">
        <Button variant="green-outlined" onClick={onCancel} isFullWidth>
          {intl.formatMessage(ChangeAPIKeyModalMessages.cancelButtonLabel)}
        </Button>
        <Button
          variant="green-filled"
          onClick={onChangeAPIKey}
          isDisabled={isChangeAPIKeyPending}
          isLoading={isChangeAPIKeyPending}
          isFullWidth
        >
          {intl.formatMessage(ChangeAPIKeyModalMessages.changeAPIKeyButtonLabel)}
        </Button>
      </div>
    </Modal>
  )
}
