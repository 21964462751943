import classNames from 'classnames'
import type { FunctionComponent, ReactNode } from 'react'

interface CardProps {
  /** The children that will show as the content inside the `Card`. */
  children: ReactNode
  /** The extra classes to apply to the `Card`. */
  classes?: string
}

export const Card: FunctionComponent<CardProps> = ({ children, classes }) => {
  return (
    <div className={classNames('flex w-full flex-col rounded-lg bg-neutral-10 px-6 py-4 shadow-md', classes)}>
      {children}
    </div>
  )
}
