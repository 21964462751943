import type { FunctionComponent } from 'react'
import { useCallback } from 'react'
import { setAuthenticationPage } from '../../features/authentication/authenticationSlice'
import { AuthenticationPage } from '../../features/authentication/models/AuthenticationPage'
import { registerAccount } from '../../features/registration/registrationSlice'
import { selectRequestStatusIsPending } from '../../features/requestStatus/requestStatusSelectors'
import { useAppDispatch, useAppSelector } from '../../store'
import { CreateAccount } from './components/CreateAccount'

export const ConnectedCreateAccountForm: FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const registerAccountRequestIsPending = useAppSelector((state) =>
    selectRequestStatusIsPending(state, 'registerAccount'),
  )

  const onRegisterAccount = useCallback(
    (email: string, password: string) => {
      dispatch(registerAccount({ email, password }))
    },
    [dispatch],
  )

  const onViewLoginPage = useCallback(() => {
    dispatch(setAuthenticationPage({ page: AuthenticationPage.Login }))
  }, [dispatch])

  const onViewEarn = () => {
    window.open('https://salad.com/download', '_blank')
  }

  return (
    <CreateAccount
      isSubmitPending={registerAccountRequestIsPending}
      onSubmit={({ email, password }) => onRegisterAccount(email, password)}
      onViewLoginPage={onViewLoginPage}
      onViewEarn={onViewEarn}
    />
  )
}
