import { defineMessages } from 'react-intl'

export const BillingInformationMessages = defineMessages({
  addPaymentMethodTitle: {
    defaultMessage: 'Add a Payment Method to increase your deployments quotas on Salad ',
    id: 'kLa+q+iQ',
    description: 'The title for the payment method block.',
  },
  addPaymentMethodDescription: {
    defaultMessage: 'All payment and billing are securely handled through Stripe',
    id: 'bFmcyu4/',
    description: 'The description for the payment method block.',
  },
  addPaymentMethodButtonLabel: {
    defaultMessage: 'Add Payment Method',
    id: 'dPwY4N27',
    description: 'The button label for the add payment method button',
  },
  manageBillingTitle: {
    defaultMessage: 'Manage Billing and View Your Invoices',
    id: 'dTRk9+MX',
    description: 'The title for the manage billing block.',
  },
  manageBillingButtonLabel: {
    defaultMessage: 'Manage Billing',
    id: 'z33A60D1',
    description: 'The button label for the manage billing button.',
  },
  generalStripeFormError: {
    defaultMessage:
      'Something went wrong with loading of Stripe Form.\nPlease reload the page or press the "Reload" button.',
    id: 'STiDfwtn',
    description: 'The Stripe form load error message label.',
  },
  reloadButton: {
    defaultMessage: 'Reload',
    id: 'wPmaxNEp',
    description: 'The reload button label when the Stripe form load error hits.',
  },
})
