import { defineMessages } from 'react-intl'

export const InvalidCredentialsToastContentMessages = defineMessages({
  body: {
    defaultMessage: 'The email and password you provided does not exist or is invalid.',
    id: '13ASuP4O',
    description:
      'The description shown in the Invalid Credentials toast notification explaining why they received the Invalid Credentials error.',
  },
  title: {
    defaultMessage: 'Invalid Credentials',
    id: 'dtiQ0a8/',
    description: 'The toast notification title for when the user provides invalid credentials.',
  },
})
