import { defineMessages } from 'react-intl'

export const ContainerGroupInstanceNotFoundErrorMessages = defineMessages({
  body: {
    defaultMessage:
      'We’re unable to retrieve the container group instance, this likely occurred because the instance was already removed.',
    id: 'heQuxBBu',
    description:
      'The description shown in a toast notification message shown when we are unable to find the container group instance.',
  },
  title: {
    defaultMessage: 'Container group instance not found',
    id: 'uJ4eTeg7',
    description:
      'The toast notification title shown when we encounter an error and are unable to find the container group instance.',
  },
})
