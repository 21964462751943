import { defineMessages } from 'react-intl'

export const TimeFrameBasedEstimatedCostMessages = defineMessages({
  estimatedCostPerHour: {
    defaultMessage: '{estimated_cost} per hour',
    id: '07jlJAGC',
    description: 'The value for the estimated cost per hour.',
  },
  estimatedCostPerMonth: {
    defaultMessage: '{estimated_cost} per month',
    id: 'v9qOALPG',
    description: 'The value for the estimated cost per month.',
  },
  hourlyTabLabel: {
    defaultMessage: 'hourly',
    id: 'DUEp35cd',
    description: 'The label for the hourly tab',
  },
  monthlyTabLabel: {
    defaultMessage: 'monthly',
    id: '0jQThSk9',
    description: 'The label for the monthly tab',
  },
  perReplicaTag: {
    defaultMessage: 'per replica',
    id: 'Zzdn2Bp5',
    description: 'The label for the per replica tag',
  },
  upToPriceTag: {
    defaultMessage: 'Up to ',
    id: 'nKlBUkdg',
    description: 'The prepend label for the up to price tag',
  },
})
