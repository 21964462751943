import classNames from 'classnames'
import type { FunctionComponent, ReactNode } from 'react'

interface AllocatedResourceCardProps {
  /** The children that will show as the content inside the `Allocated Resource Card`. */
  children: ReactNode
  /** Indicates whether to align center horizontally the content inside the `Allocated Resource Card`. */
  justifyCenter?: boolean
}

// TODO: This card is used in multiple pages, this should be moved to a higher level component folder
export const AllocatedResourceCard: FunctionComponent<AllocatedResourceCardProps> = ({
  children,
  justifyCenter = false,
}) => (
  <div
    className={classNames(
      'flex w-32 min-w-fit items-center justify-start rounded bg-neutral-10 py-2.5 pr-2 text-neutral-70 shadow-md',
      {
        'justify-start': !justifyCenter,
        'justify-center': justifyCenter,
      },
    )}
  >
    {children}
  </div>
)
