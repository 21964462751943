import { defineMessages } from 'react-intl'

export const OrganizationQuotasNotDefinedErrorMessages = defineMessages({
  body: {
    defaultMessage:
      'We’re having trouble retrieving quotas for this organization. If the problem persists, {contact_support}.',
    id: 'F2YlWwHm',
    description:
      'The description shown in a toast notification message shown when we are unable to retrieve the quotas.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: '54OfIsZC',
    description: 'The text shown for the contact support link in the create container group error toast notification.',
  },
  title: {
    defaultMessage: 'Error retrieving quotas',
    id: 'ar18Lb5x',
    description: 'The toast notification title shown when we encounter an error and are unable to retrieve the quotas.',
  },
})
