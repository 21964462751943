import { TableSortingOrder } from '../../../../components/block/Table/models'
import type { JobQueueTableConnections } from '../../../../features/jobQueueConnectionsTable/models'

/**
 * Sorts the job queue connections based on the sorting method requested.
 *
 * @param connections The job queue connections.
 */
export const getSortedJobQueueTable = (
  connections: JobQueueTableConnections,
  sortingOrder: TableSortingOrder,
): JobQueueTableConnections => {
  const connectionsCopy = [...connections]
  return connectionsCopy.sort((a, b) => {
    if (sortingOrder === TableSortingOrder.Ascending) {
      return a.containerGroupDisplayName.localeCompare(b.containerGroupDisplayName)
    } else {
      return b.containerGroupDisplayName.localeCompare(a.containerGroupDisplayName)
    }
  })
}
