import type { RecipeDeployment } from '@saladtechnologies/openapi-cloud-portal-browser'
import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { RecipeDeploymentsPageMainContentMessages } from '../../messages'
import { NoRecipesDeployed } from '../NoRecipesDeployed/NoRecipesDeployed'
import { RecipesDeployed } from '../RecipesDeployed/RecipesDeployed'

interface RecipeDeploymentsPageMainContentProps {
  /** The callback executed when the user clicks the Learn More button */
  onLearnMore: () => void
  /** The name of the current organization */
  organizationName: string
  /** The name of the current project */
  projectName: string
  /** The display name of the project */
  projectDisplayName: string
  /** The list of recipe deployments for the current project */
  recipeDeploymentsList: RecipeDeployment[]
  /** The path to the recipe marketplace */
  viewRecipeMarketplacePath: string
}

export const RecipeDeploymentsPageMainContent: FunctionComponent<RecipeDeploymentsPageMainContentProps> = ({
  onLearnMore,
  organizationName,
  projectDisplayName,
  projectName,
  recipeDeploymentsList,
  viewRecipeMarketplacePath,
}) => {
  const intl = useIntl()
  const hasRecipeDeployments = recipeDeploymentsList.length > 0

  return (
    <>
      <h1 className="mb-4 text-3xl font-bold">
        {intl.formatMessage(RecipeDeploymentsPageMainContentMessages.title, {
          project_display_name: projectDisplayName,
        })}
      </h1>
      <p className={`${hasRecipeDeployments ? 'mb-4' : 'mb-16'} text-base`}>
        {intl.formatMessage(RecipeDeploymentsPageMainContentMessages.description)}
      </p>
      {hasRecipeDeployments ? (
        <RecipesDeployed
          onLearnMore={onLearnMore}
          organizationName={organizationName}
          projectName={projectName}
          viewRecipeMarketplacePath={viewRecipeMarketplacePath}
          recipeDeploymentsList={recipeDeploymentsList}
        />
      ) : (
        <NoRecipesDeployed onLearnMore={onLearnMore} viewRecipeMarketplacePath={viewRecipeMarketplacePath} />
      )}
    </>
  )
}
