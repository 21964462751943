import { defineMessages } from 'react-intl'

export const ForgotPasswordMessages = defineMessages({
  title: {
    defaultMessage: 'Forgot Your Password?',
    id: 'XCnLCMIj',
    description: 'The title for the forgot password form.',
  },
  subTitle: {
    defaultMessage: 'Please enter your email address. We will send you an email to reset your password.',
    id: 'L2nZxO1q',
    description: 'The subtitle for the forgot password form.',
  },
  emailLabel: {
    defaultMessage: 'Email Address',
    id: 'ouh/i5yE',
    description: 'The label for the email address field.',
  },
  emailPlaceholder: {
    defaultMessage: 'Email',
    id: 'w1g6SHO6',
    description: 'The placeholder for the email address field.',
  },
  emailRequired: {
    defaultMessage: 'An email address is required.',
    id: '4PeHXlzA',
    description: 'The error message when the email address is not provided.',
  },
  emailInvalid: {
    defaultMessage: 'The email is invalid.',
    id: '3g/stt9j',
    description: 'The error message when email is invalid.',
  },
  submit: {
    defaultMessage: 'Submit',
    id: 'WQaRlFVM',
    description: 'Submit button label',
  },
})
