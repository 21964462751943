import classNames from 'classnames'
import { useState, type FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { Spinner } from '../../../../components/Spinner'
import { BillingInvoicesMessages } from '../../messages'

export interface BillingInvoicesProps {
  /** The invoice dashboard embed url. */
  billingInvoiceEmbedUrl: string | undefined
  /** The organization display name. */
  organizationDisplayName: string
}

export const BillingInvoices: FunctionComponent<BillingInvoicesProps> = ({
  billingInvoiceEmbedUrl,
  organizationDisplayName,
}) => {
  const intl = useIntl()
  const [isIframeLoaded, setIsIframeLoaded] = useState(false)
  const isShowingLoadingSpinner = billingInvoiceEmbedUrl === undefined || !isIframeLoaded

  const handleSetIframeLoaded = () => {
    setTimeout(() => setIsIframeLoaded(true), 700)
  }

  return (
    <>
      {isShowingLoadingSpinner && (
        <div className="flex size-full flex-col items-center pt-12">
          <Spinner size="lg" />
        </div>
      )}

      <iframe
        className={classNames('mt-5 aspect-video h-full w-full', {
          hidden: isShowingLoadingSpinner,
        })}
        onLoad={handleSetIframeLoaded}
        src={billingInvoiceEmbedUrl}
        title={intl.formatMessage(BillingInvoicesMessages.invoiceIFrameTitle, {
          organization_display_name: organizationDisplayName,
        })}
      />
    </>
  )
}
