import type { ResponseError } from '@saladtechnologies/openapi-cloud-portal-browser'
import { catchError, concat, delay, filter, forkJoin, from, mergeMap, of } from 'rxjs'
import { InvitationsAPI, MembersAPI, OrganizationsAPI, ProjectsAPI } from '../apiMethods'
import { showToastNotification } from '../features/notifications/notificationsSlice'
import { organizationAdded } from '../features/organizations/organizationsSlice'
import { selectProfileEmail } from '../features/profile/profileSelectors'
import { projectsAddedToOrganization } from '../features/projects/projectsSlice'
import { setRequestStatus } from '../features/requestStatus/requestStatusSlice'
import {
  getOrganizationMembersAndInvitations,
  removeMemberFromOrganization,
  resendInvitation,
  revokeInvitation,
  setOrganizationMembersAndInvitations,
  setRemoveMemberFromOrganizationRequestStatus,
  setResendInvitationRequestStatus,
  setRevokeInvitationRequestStatus,
} from '../features/team/teamSlice'
import { RequestStatus } from '../models'
import {
  getGeneralErrorLeavingTeamContent,
  getGeneralErrorLoadingTeamInvitationsAndMembersContent,
  getGeneralErrorReinvitingTeamMemberContent,
  getGeneralErrorRemovingTeamMemberContent,
  getGeneralErrorRevokingTeamMemberInvitationContent,
  getLeftTeamSucceededContent,
  getRemovedTeamMemberSucceededContent,
  getRevokedTeamMemberInvitationSucceededContent,
} from '../notifications/clientToastNotificationContent/team'
import { getReinvitedTeamMemberSucceededContent } from '../notifications/clientToastNotificationContent/team/getReinvitedTeamMemberSucceededContent'
import type { AppEpic } from '../store'
import { navigateTo } from './navigationEpic'

export const onGetOrganizationWithMembersAndInvitations: AppEpic = (action$, state$, { intl }) =>
  action$.pipe(
    filter(getOrganizationMembersAndInvitations.match),
    mergeMap(({ payload: { organizationName } }) =>
      concat(
        of(setRequestStatus({ request: 'getMyOrganizationMembersAndInvitations', status: 'pending' })),
        forkJoin([
          OrganizationsAPI.getOrganization({
            organizationName: organizationName,
          }),
          ProjectsAPI.listProjects({
            organizationName,
          }),
          InvitationsAPI.listInvitations({
            organizationName: organizationName,
          }),
          MembersAPI.listMembers({
            organizationName: organizationName,
          }),
        ])
          .pipe(
            mergeMap(([organizationResponse, projectsListResponse, invitationListResponse, membersListResponse]) => {
              return concat(
                of(
                  organizationAdded(organizationResponse),
                  setOrganizationMembersAndInvitations({
                    organizationWithMembersAndInvitations: {
                      organization: organizationResponse,
                      members: membersListResponse.items,
                      invitations: invitationListResponse.items,
                    },
                    currentUserEmail: selectProfileEmail(state$.value),
                  }),
                  projectsAddedToOrganization({ organizationName, projects: projectsListResponse.items }),
                  setRequestStatus({ request: 'getMyOrganizationMembersAndInvitations', status: 'succeeded' }),
                ),
                of(setRequestStatus({ request: 'getMyOrganizationMembersAndInvitations', status: 'idle' })).pipe(
                  delay(1),
                ),
              )
            }),
          )
          .pipe(
            catchError((error: ResponseError) => {
              return error.response.status === 404
                ? concat(
                    of(setRequestStatus({ request: 'getMyOrganizationMembersAndInvitations', status: 'failed' })),
                    of(setRequestStatus({ request: 'getMyOrganizationMembersAndInvitations', status: 'idle' })).pipe(
                      delay(1),
                    ),
                    of(navigateTo({ path: '/organizations' })),
                  )
                : concat(
                    of(setRequestStatus({ request: 'getMyOrganizationMembersAndInvitations', status: 'failed' })),
                    of(setRequestStatus({ request: 'getMyOrganizationMembersAndInvitations', status: 'idle' })).pipe(
                      delay(1),
                    ),
                    of(
                      showToastNotification(getGeneralErrorLoadingTeamInvitationsAndMembersContent(intl)),
                      navigateTo({ path: '/organizations' }),
                    ),
                  )
            }),
          ),
      ),
    ),
  )

export const onResendInvitation: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(resendInvitation.match),
    mergeMap((action) =>
      concat(
        of(
          setResendInvitationRequestStatus({
            invitationId: action.payload.invitationId,
            status: RequestStatus.Loading,
          }),
        ),
        from(
          InvitationsAPI.createInvitation({
            createInvitation: {
              email: action.payload.email,
            },
            organizationName: action.payload.organizationName,
          }),
        ).pipe(
          mergeMap(() =>
            concat(
              of(
                setResendInvitationRequestStatus({
                  invitationId: action.payload.invitationId,
                  status: RequestStatus.Succeeded,
                }),
                showToastNotification(
                  getReinvitedTeamMemberSucceededContent(intl, action.payload.email, action.payload.organizationName),
                ),
                getOrganizationMembersAndInvitations({ organizationName: action.payload.organizationName }),
              ),
              of(
                setResendInvitationRequestStatus({
                  invitationId: action.payload.invitationId,
                  status: RequestStatus.Idle,
                }),
              ).pipe(delay(1)),
            ),
          ),
          catchError(() =>
            concat(
              of(
                setResendInvitationRequestStatus({
                  invitationId: action.payload.invitationId,
                  status: RequestStatus.Failed,
                }),
              ),
              of(
                setResendInvitationRequestStatus({
                  invitationId: action.payload.invitationId,
                  status: RequestStatus.Idle,
                }),
              ).pipe(delay(1)),
              of(showToastNotification(getGeneralErrorReinvitingTeamMemberContent(intl, action.payload.email))),
            ),
          ),
        ),
      ),
    ),
  )

export const onRevokeInvitation: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(revokeInvitation.match),
    mergeMap((action) =>
      concat(
        of(
          setRevokeInvitationRequestStatus({
            invitationId: action.payload.invitationId,
            status: RequestStatus.Loading,
          }),
        ),
        from(
          InvitationsAPI.updateInvitation({
            updateInvitation: {
              status: 'revoked',
            },
            organizationName: action.payload.organizationName,
            invitationId: action.payload.invitationId,
          }),
        ).pipe(
          mergeMap(() =>
            concat(
              of(
                setRevokeInvitationRequestStatus({
                  invitationId: action.payload.invitationId,
                  status: RequestStatus.Succeeded,
                }),
                showToastNotification(getRevokedTeamMemberInvitationSucceededContent(intl, action.payload.email)),
              ),
              of(
                setRevokeInvitationRequestStatus({
                  invitationId: action.payload.invitationId,
                  status: RequestStatus.Idle,
                }),
              ).pipe(delay(1)),
            ),
          ),
          catchError(() => {
            return concat(
              of(
                setRevokeInvitationRequestStatus({
                  invitationId: action.payload.invitationId,
                  status: RequestStatus.Failed,
                }),
              ),
              of(
                setRevokeInvitationRequestStatus({
                  invitationId: action.payload.invitationId,
                  status: RequestStatus.Idle,
                }),
              ).pipe(delay(1)),
              of(showToastNotification(getGeneralErrorRevokingTeamMemberInvitationContent(intl, action.payload.email))),
            )
          }),
        ),
      ),
    ),
  )

export const onRemoveMemberFromOrganization: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(removeMemberFromOrganization.match),
    mergeMap((action) =>
      concat(
        of(
          setRemoveMemberFromOrganizationRequestStatus({
            memberId: action.payload.memberId,
            status: RequestStatus.Loading,
          }),
        ),
        from(
          MembersAPI.deleteMember({
            memberId: action.payload.memberId,
            organizationName: action.payload.organizationName,
          }),
        ).pipe(
          mergeMap(() => {
            return action.payload.isCurrentMember
              ? concat(
                  of(
                    setRemoveMemberFromOrganizationRequestStatus({
                      memberId: action.payload.memberId,
                      status: RequestStatus.Succeeded,
                    }),
                    showToastNotification(getLeftTeamSucceededContent(intl, action.payload.organizationName)),
                  ),
                  of(
                    setRemoveMemberFromOrganizationRequestStatus({
                      memberId: action.payload.memberId,
                      status: RequestStatus.Idle,
                    }),
                  ).pipe(delay(1)),
                  of(navigateTo({ path: '/organizations' })),
                )
              : concat(
                  of(
                    setRemoveMemberFromOrganizationRequestStatus({
                      memberId: action.payload.memberId,
                      status: RequestStatus.Succeeded,
                    }),
                    showToastNotification(
                      getRemovedTeamMemberSucceededContent(intl, action.payload.email, action.payload.organizationName),
                    ),
                  ),
                  of(
                    setRemoveMemberFromOrganizationRequestStatus({
                      memberId: action.payload.memberId,
                      status: RequestStatus.Idle,
                    }),
                  ).pipe(delay(1)),
                )
          }),
          catchError(() => {
            return concat(
              of(
                setRemoveMemberFromOrganizationRequestStatus({
                  memberId: action.payload.memberId,
                  status: RequestStatus.Failed,
                }),
              ),
              of(
                setRemoveMemberFromOrganizationRequestStatus({
                  memberId: action.payload.memberId,
                  status: RequestStatus.Idle,
                }),
              ).pipe(delay(1)),
              of(
                showToastNotification(
                  action.payload.isCurrentMember
                    ? getGeneralErrorLeavingTeamContent(intl)
                    : getGeneralErrorRemovingTeamMemberContent(intl, action.payload.email),
                ),
              ),
            )
          }),
        ),
      ),
    ),
  )
