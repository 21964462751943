import { defineMessages } from 'react-intl'

export const EditContainerGatewayFieldsMessages = defineMessages({
  description: {
    defaultMessage:
      'Allow containers to receive external requests. Requests must be made over HTTPS, and your container must accept incoming IPv6 requests. For help enabling IPv6 support, see our {documentation_link}.',
    id: 'F3H7oBdY',
    description: 'The description for the container group Container Gateway fields.',
  },
  descriptionDocumentationLinkText: {
    defaultMessage: 'documentation here',
    id: 'bsQHkznd',
    description: 'The description documentation link text for the container group Container Gateway fields.',
  },
  enableContainerGatewayLabel: {
    defaultMessage: 'Enable Container Gateway. I’ve set up my container to support IPv6.',
    id: 'kstBbEUj',
    description: 'The label for the enable Container Gateway field.',
  },
  containerGatewayAuthenticationLabel: {
    defaultMessage: 'Use Authentication?',
    id: 'WY6Nn72K',
    description: 'The label for the Container Gateway authentication field.',
  },
  containerGatewayAuthenticationHelperText: {
    defaultMessage: 'When on, requests to containers must be authenticated using your API key',
    id: 'Z+PaTzt6',
    description: 'The helper text for the Container Gateway authentication field.',
  },
  containerGatewayDisabledLabel: {
    defaultMessage: 'Container Gateway is disabled',
    id: 'zwspkCpG',
    description: 'The label for the container group Container Gateway fields indicating that it is disabled.',
  },
  containerGatewayEnabledAuthenticationRequiredLabel: {
    defaultMessage: 'Container Gateway is enabled targeting port {port}. Authentication is required.',
    id: 'hnHm04ov',
    description:
      'The label for the container group Container Gateway fields indicating that it is enabled and authentication is required.',
  },
  containerGatewayEnabledAuthenticationNotRequiredLabel: {
    defaultMessage: 'Container Gateway is enabled targeting port {port}. Authentication is not required.',
    id: 'klLs0nnq',
    description:
      'The label for the container group Container Gateway fields indicating that it is enabled but authentication is not required.',
  },
  containerGatewayFieldsLabel: {
    defaultMessage: 'Container Gateway',
    id: 'fGEB10In',
    description: 'The label for the container group Container Gateway fields.',
  },
  noLabel: {
    defaultMessage: 'No',
    id: 'XbmUeqha',
    description: 'The label for the no option in the Container Gateway authentication field.',
  },
  portHelperText: {
    defaultMessage: 'Must be a number between 1 to 65535',
    id: 'HXDTVVJn',
    description: 'The helper text for the neworking port input field.',
  },
  portLabel: {
    defaultMessage: 'Port',
    id: 'H1yLuYoS',
    description: 'The label for the neworking port input field.',
  },
  sectionTitle: {
    defaultMessage: 'Container Gateway',
    id: '6Sh4dxaS',
    description:
      'The title for the dialog box that opens when a user wants to edit Container Gateway fields on the create container group page.',
  },
  yesLabel: {
    defaultMessage: 'Yes',
    id: 'SJO9UZqf',
    description: 'The label for the yes option in the Container Gateway authentication field.',
  },
})
