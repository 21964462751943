import { defineMessages } from 'react-intl'

export const UnableToExecuteActionToInstanceLimitExceededErrorContentMessages = defineMessages({
  body: {
    defaultMessage: 'You will need to wait before you {action} additional instances',
    id: 'aAV6EYEV',
    description:
      'The description shown in a toast notification message shown when we are not able to {action} the instance because we have reached the rate limit.',
  },
  title: {
    defaultMessage: 'Your limit to {action} has been exceeded',
    id: 'XEWUv62l',
    description: 'The toast notification title shown when {action} action limit exceeded.',
  },
})
