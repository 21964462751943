import type { Queue } from '@saladtechnologies/openapi-cloud-portal-browser'
import type { FunctionComponent } from 'react'
import { LeftNavigationBar } from '../../components/LeftNavigationBar'
import type { ProjectSelectOptions } from '../../components/LeftNavigationBar/models'
import type { JobQueueTableConnections } from '../../features/jobQueueConnectionsTable/models'
import { ThreeColumnLayout } from '../../layouts/threeColumnLayout'
import { JobQueueDetailsMainContent } from './components/JobQueueDetailsMainContent'

export interface JobQueueDetailsPageProps {
  /** The job queue connections */
  connections: JobQueueTableConnections
  /** The current organization that the user is in. */
  currentOrganization: { name: string; displayName: string }
  /** The current project that the user is in. */
  currentProject: { name: string; displayName: string }
  /** The edit job queue path */
  editJobQueuePath: string
  /** The flag indicating that the request to delete the job queue is pending. */
  isDeleteJobQueuePending: boolean
  /** The flag indicating that the request to delete the project is pending. */
  isDeleteProjectPending: boolean
  /** The flag indicating that the request to delete the project is successful. */
  isDeleteProjectSuccessful: boolean
  /** The flag indicating if the left column is showing */
  isLeftColumnOpen: boolean
  /** The job queue */
  jobQueue: Queue
  /** The job queue path */
  jobQueuesPath: string
  /** The callback executed when the user closes the left drawer */
  onCloseLeftDrawer: () => void
  /** The callback that deletes a job queue connection */
  onDeleteConnection: (containerGroupName: string) => void
  /** A callback that when executed deletes a job queue. */
  onDeleteJobQueue: (name: string) => void
  /** The callback called when the user deletes the project. */
  onDeleteProject: (projectName: string) => void
  /**
   * The handler for when something new is selected in the project dropdown. If the current active project is selected,
   * nothing will happen.
   */
  onProjectChange: (projectName: string) => void
  /** An array of the current available projects. If empty, we will default to no select bar being displayed */
  projects: ProjectSelectOptions
}

export const JobQueueDetailsPage: FunctionComponent<JobQueueDetailsPageProps> = ({
  connections,
  currentOrganization,
  currentProject,
  editJobQueuePath,
  isDeleteJobQueuePending,
  isDeleteProjectPending,
  isDeleteProjectSuccessful,
  isLeftColumnOpen,
  jobQueue,
  jobQueuesPath,
  onCloseLeftDrawer,
  onDeleteConnection,
  onDeleteJobQueue,
  onDeleteProject,
  onProjectChange,
  projects,
}) => (
  <ThreeColumnLayout
    isLeftColumnOpen={isLeftColumnOpen}
    LeftColumn={
      <LeftNavigationBar
        currentOrganization={currentOrganization}
        currentProject={currentProject}
        isDeleteProjectPending={isDeleteProjectPending}
        isDeleteProjectSuccessful={isDeleteProjectSuccessful}
        onDeleteProject={onDeleteProject}
        onProjectChange={onProjectChange}
        projects={projects}
      />
    }
    MainColumn={
      <JobQueueDetailsMainContent
        connections={connections}
        editJobQueuePath={editJobQueuePath}
        isDeleteJobQueuePending={isDeleteJobQueuePending}
        onDeleteConnection={onDeleteConnection}
        onDeleteJobQueue={onDeleteJobQueue}
        jobQueue={jobQueue}
        jobQueuesPath={jobQueuesPath}
      />
    }
    onCloseLeftDrawer={onCloseLeftDrawer}
  />
)
