import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { CreateContainerGroup } from '@saladtechnologies/openapi-cloud-portal-browser'
import type { RequiredActionModal } from './models/RequiredActionModal'

export interface CreateContainerGroupState {
  isBillingInformationMissingModalShowing: boolean
  isContainerGroupInstancesQuotaExceededModalShowing: boolean
  isContainerGroupDeploymentQuotaExceededModalShowing: boolean
  organizationDisplayName: string | undefined
  projectDisplayName: string | undefined
}

const initialState: CreateContainerGroupState = {
  isBillingInformationMissingModalShowing: false,
  isContainerGroupInstancesQuotaExceededModalShowing: false,
  isContainerGroupDeploymentQuotaExceededModalShowing: false,
  organizationDisplayName: undefined,
  projectDisplayName: undefined,
}

export const createContainerGroupSlice = createSlice({
  name: 'createContainerGroup',
  initialState,
  reducers: {
    createContainerGroup(
      _state,
      _action: PayloadAction<{
        organizationName: string
        projectName: string
        createContainerGroup: CreateContainerGroup
      }>,
    ) {},
    getCreateContainerGroupWizardData(
      _state,
      _action: PayloadAction<{ organizationName: string; projectName: string }>,
    ) {},
    resetRequiredActionModalShowingState(state) {
      state.isBillingInformationMissingModalShowing = false
      state.isContainerGroupInstancesQuotaExceededModalShowing = false
      state.isContainerGroupDeploymentQuotaExceededModalShowing = false
    },
    setCreateContainerGroupWizardData(
      state,
      action: PayloadAction<{
        organizationDisplayName: string | undefined
        projectDisplayName: string | undefined
      }>,
    ) {
      const { organizationDisplayName, projectDisplayName } = action.payload
      state.organizationDisplayName = organizationDisplayName
      state.projectDisplayName = projectDisplayName
    },
    setRequiredActionModalShowingState(
      state,
      action: PayloadAction<{ requiredAction: RequiredActionModal; showing: boolean }>,
    ) {
      const { requiredAction, showing } = action.payload
      if (requiredAction === 'billing') {
        state.isBillingInformationMissingModalShowing = showing
      }

      if (requiredAction === 'instancesQuota') {
        state.isContainerGroupInstancesQuotaExceededModalShowing = showing
      }

      if (requiredAction === 'deploymentsQuota') {
        state.isContainerGroupDeploymentQuotaExceededModalShowing = showing
      }
    },
  },
})

export const {
  createContainerGroup,
  getCreateContainerGroupWizardData,
  resetRequiredActionModalShowingState,
  setCreateContainerGroupWizardData,
  setRequiredActionModalShowingState,
} = createContainerGroupSlice.actions
