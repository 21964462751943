import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import { useRef } from 'react'
import type { Control, FieldValues, UseFormClearErrors, UseFormResetField, UseFormTrigger } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../../../components/Button'
import { ContainerConfigurationMessages } from '../../messages'
import {
  CreateContainerGroupField,
  CreateContainerGroupFormSectionIdAttributes,
  CreateContainerGroupValues,
} from '../../models'
import { CommandFields } from '../CommandFields'
import { EnvironmentVariablesFields } from '../EnvironmentVariablesFields'
import { ImageSourceFields } from '../ImageSourceFields'
import { NameInput } from '../NameInput'
import { ReplicaCountInput } from '../ReplicaCountInput'

export interface ContainerConfigurationProps {
  /** The react hook form method that clears errors for specified fields. */
  clearErrors: UseFormClearErrors<FieldValues>
  /** The control for the create container group react hook form. */
  control: Control<FieldValues, CreateContainerGroupValues>
  /** The link to the typeform for requesting an increase in the container group instance quota limit. */
  linkToRequestIncreaseForContainerGroupInstancesQuotas: string
  /** The flag indicating that the request to create a container group is pending. */
  isSubmitPending: boolean
  /** The maximum replica count allowed for the organization. */
  maxReplicaCount: number
  /** The function to handle navigation to the previous page */
  onBackButton: () => void
  /** The function to handle navigation to the next page */
  onNextStep: (fieldsToValidate: string[]) => void
  /** The react hook form method that provides the ability to reset a specified field value. */
  resetField: UseFormResetField<FieldValues>
  /** The react hook form method that provides the ability to set a specified field value. */
  setValue: any
  /** The react hook form method that triggers validation for specified fields. */
  trigger: UseFormTrigger<FieldValues>
}

export const ContainerConfiguration: FunctionComponent<ContainerConfigurationProps> = ({
  clearErrors,
  control,
  isSubmitPending,
  linkToRequestIncreaseForContainerGroupInstancesQuotas,
  maxReplicaCount,
  onBackButton,
  onNextStep,
  resetField,
  trigger,
}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const containerGroupNameRef = useRef<HTMLDivElement>(null)
  const imageSourceRef = useRef<HTMLDivElement>(null)
  const environmentVariablesRef = useRef<HTMLDivElement>(null)
  const commandRef = useRef<HTMLDivElement>(null)

  const containerConfigurationFields = [
    CreateContainerGroupField.NAME,
    CreateContainerGroupField.IMAGE_SOURCE,
    CreateContainerGroupField.REPLICA_COUNT,
    CreateContainerGroupField.ENVIRONMENT_VARIABLES,
    CreateContainerGroupField.COMMAND,
  ]

  return (
    <div className="relative h-screen w-full pb-36">
      <div className="h-full overflow-auto px-6 pt-8">
        <div className="mb-36 w-full max-w-2xl">
          <div className="mb-4">
            <button className="text-blue-90 underline" onClick={() => navigate(-1)}>
              <span className={classNames('fa-solid fa-arrow-left mr-2')} />
              {intl.formatMessage(ContainerConfigurationMessages.backLinkText)}
            </button>
          </div>
          <h1 className="mb-3 text-3xl font-bold">
            {intl.formatMessage(ContainerConfigurationMessages.containerConfigurationTitle)}
          </h1>
          <h3 className="mb-10 text-lg">
            {intl.formatMessage(ContainerConfigurationMessages.containerConfigurationSubtitle)}
          </h3>

          <NameInput
            control={control}
            id={CreateContainerGroupFormSectionIdAttributes.CONTAINER_GROUP_NAME}
            // @ts-ignore - This works, I just don't have the correct types for the ref
            ref={containerGroupNameRef}
          />

          <ImageSourceFields
            control={control}
            id={CreateContainerGroupFormSectionIdAttributes.IMAGE_SOURCE}
            // @ts-ignore - This works, I just don't have the correct types for the ref
            ref={imageSourceRef}
            resetField={resetField}
            trigger={trigger}
          />

          <ReplicaCountInput
            control={control}
            id={CreateContainerGroupFormSectionIdAttributes.REPLICA_COUNT}
            max={maxReplicaCount}
            requestIncreaseLimitLink={linkToRequestIncreaseForContainerGroupInstancesQuotas}
          />

          <EnvironmentVariablesFields
            clearErrors={clearErrors}
            control={control}
            id={CreateContainerGroupFormSectionIdAttributes.ENVIRONMENT_VARIABLES}
            // @ts-ignore - This works, I just don't have the correct types for the ref
            ref={environmentVariablesRef}
            trigger={trigger}
          />

          <CommandFields
            clearErrors={clearErrors}
            control={control}
            id={CreateContainerGroupFormSectionIdAttributes.COMMAND}
            // @ts-ignore - This works, I just don't have the correct types for the ref
            ref={commandRef}
            trigger={trigger}
          />
        </div>
      </div>

      <div className="absolute bottom-16 left-0 mt-10 flex w-full flex-col justify-items-end bg-neutral-10 px-6 py-5 align-middle shadow">
        <div className="flex w-full justify-end gap-2">
          <Button
            variant="green-outlined"
            type="button"
            isLoading={isSubmitPending}
            isDisabled={isSubmitPending}
            onClick={onBackButton}
          >
            {intl.formatMessage(ContainerConfigurationMessages.previousButtonLabel)}
          </Button>
          <Button
            variant="green-filled"
            type="button"
            isLoading={isSubmitPending}
            isDisabled={isSubmitPending}
            onClick={() => onNextStep(containerConfigurationFields)}
          >
            {intl.formatMessage(ContainerConfigurationMessages.nextStepButtonLabel)}
          </Button>
        </div>
      </div>
    </div>
  )
}
