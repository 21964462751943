import { defineMessages } from 'react-intl'

export const StartContainerGroupGeneralErrorContentMessages = defineMessages({
  body: {
    defaultMessage:
      'We were unable to start your container group at this time. Please try again and if this issue persists {contact_support}.',
    id: 'ZKWWP96s',
    description:
      'The description shown in a toast notification message shown when we encounter an error on their request to start a container group.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'h/lxt9Pe',
    description: 'The text shown for the contact support link in the start a container group error toast notification.',
  },
  title: {
    defaultMessage: 'Unable to start container group',
    id: '/wkxr5cr',
    description:
      'The toast notification title shown when we encounter an error on their request to start a container group.',
  },
})
