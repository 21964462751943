import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import type { ObjectSchema } from 'yup'
import { object, string } from 'yup'
import type { ForgotPasswordValues } from './ForgotPassword'
import { ForgotPasswordMessages } from './ForgotPasswordMessages'

export const useForgotPasswordFormValidation = (): ObjectSchema<ForgotPasswordValues> => {
  const intl = useIntl()
  const validationScheme = useMemo(
    () =>
      object({
        email: string()
          .required(intl.formatMessage(ForgotPasswordMessages.emailRequired))
          .email(intl.formatMessage(ForgotPasswordMessages.emailInvalid)),
      }),
    [intl],
  )
  return validationScheme
}
