import { defineMessages } from 'react-intl'

export const EditOrganizationSucceededContentMessages = defineMessages({
  body: {
    defaultMessage: 'The display name will be shown in the Portal. For API calls use {organization_name}.',
    id: '6bt37y4n',
    description:
      'The description shown in a toast notification message shown when the user successfully edited their organization details.',
  },
  title: {
    defaultMessage: 'The display name for {organization_name} is now {organization_display_name}',
    id: 'w9fskAju',
    description: 'The toast notification title shown when the user successfully edited their organization details.',
  },
})
