import { defineMessages } from 'react-intl'

export const LoadInvoiceDashboardNotInitializedErrorContentMessages = defineMessages({
  body: {
    defaultMessage:
      'We’re still setting up your organization. This should take less than a minute. If this issue persists {contact_support}.',
    id: 'XjiK3I8s',
    description:
      'The description shown in a toast notification message shown when we encounter a not initialized error loading the billing invoice dashboard.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'B9Tzu0um',
    description: 'The text shown for the contact support link in the load invoice dashboard error toast notification.',
  },
  title: {
    defaultMessage: 'Error loading invoice dashboard',
    id: 'A0WZBAur',
    description:
      'The toast notification title shown when we encounter a not initialized error loading the billing invoice dashboard.',
  },
})
