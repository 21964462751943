import type { FieldErrors, FieldValues } from 'react-hook-form'
import { EditContainerGroupField } from '../../models'

export const checkIfJobQueueFieldsHaveErrors = (errors: FieldErrors<FieldValues>): boolean => {
  return (
    (errors[EditContainerGroupField.JOB_QUEUE_PATH] || errors[EditContainerGroupField.JOB_QUEUE_PORT]) !== undefined
  )
}

export const getJobQueueDisplayName = (
  queues: { label: string; value: string }[],
  selectedQueueName: string,
): string => {
  return queues.find((queue) => queue.value === selectedQueueName)?.label ?? ''
}

export const jobQueueFieldsList: string[] = [
  EditContainerGroupField.JOB_QUEUE,
  EditContainerGroupField.JOB_QUEUE_PATH,
  EditContainerGroupField.JOB_QUEUE_PORT,
]
