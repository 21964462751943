import { defineMessages } from 'react-intl'

export const DeploymentInstanceStatusMessages = defineMessages({
  allocating: {
    id: 'sCFw04aw',
    defaultMessage: 'Allocating',
    description: "The deployment instance's allocating status",
  },
  creating: {
    id: 'z/eyqBpq',
    defaultMessage: 'Creating',
    description: "The deployment instance's creating status",
  },
  downloading: {
    id: '1zNOsJSh',
    defaultMessage: 'Downloading',
    description: "The deployment instance's downloading status",
  },
  running: {
    id: '9tNAlFSX',
    defaultMessage: 'Running',
    description: "The deployment instance's running status",
  },
  starting: {
    id: 'QH5u6XJZ',
    defaultMessage: 'Starting',
    description: "The deployment instance's starting status",
  },
  stopping: {
    id: 'vNLMKHYu',
    defaultMessage: 'Stopping',
    description: "The deployment instance's stopping status",
  },
})
