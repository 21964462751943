import type { FunctionComponent } from 'react'
import type { Control, FieldValues } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { RadioCards } from '../../../../components/forms/RadioCards'
import { EditVCPUInputMessages } from '../../messages'
import type { EditContainerGroupFormSectionIdAttributes, EditContainerGroupValues } from '../../models'
import { EditContainerGroupField } from '../../models'
import { generateVCPUOptionCards } from './utils'

interface EditVCPUInputProps {
  /** The control for the react hook form. */
  control: Control<FieldValues, EditContainerGroupValues>
  /** The id attribute for the field. */
  id: EditContainerGroupFormSectionIdAttributes
}

export const EditVCPUInput: FunctionComponent<EditVCPUInputProps> = ({ control, id }) => {
  const intl = useIntl()

  return (
    <div id={id}>
      <Controller
        name={EditContainerGroupField.VCPUS}
        control={control}
        render={({ field, fieldState }) => (
          <RadioCards
            {...field}
            {...fieldState}
            defaultCheckedValue={field.value}
            cards={generateVCPUOptionCards(intl)}
            helperText={intl.formatMessage(EditVCPUInputMessages.helperText)}
            label={intl.formatMessage(EditVCPUInputMessages.label)}
            name={EditContainerGroupField.VCPUS}
          />
        )}
      />
    </div>
  )
}
