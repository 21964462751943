import { defineMessages } from 'react-intl'

export const DeleteAccountModalMessages = defineMessages({
  body: {
    defaultMessage:
      'Are you absolutely sure? This will permanently delete {email} and remove all your associations to projects and organizations. You cannot undo this.',
    id: 'UL+x2IFq',
    description: 'The body for the modal that shows when a user wants to delete their account.',
  },
  cancelButtonLabel: {
    defaultMessage: 'Cancel',
    id: 'IunJBEJf',
    description: 'The label for the cancel button on the modal that shows when a user wants to delete their account.',
  },
  passwordInputTitle: {
    defaultMessage: 'Please type your account password to confirm deletion',
    id: 'VIvMtDyX',
    description: 'The title for the password input on the modal that shows when a user wants to delete their account.',
  },
  title: {
    defaultMessage: 'Delete your account?',
    id: 'usISRu7k',
    description: 'The title for the modal that shows when a user wants to delete their account.',
  },
})
