import classNames from 'classnames'
import type { FunctionComponent, ReactElement } from 'react'
import { ButtonLink } from '../../../../components/base/ButtonLink'

export interface ProductCardProps {
  /** A product card submit button label. */
  buttonLabel: string
  /** A product card description. */
  description: string
  /** The Font Awesome icon class name */
  iconImageClassName: string
  /** A product card button click handler. */
  onClick?: () => void
  /** The product path. */
  productPath: string
  /** A product card tag component. */
  tagJSX: ReactElement
  /** A product card title. */
  title: string
}

export const ProductCard: FunctionComponent<ProductCardProps> = ({
  buttonLabel,
  description,
  iconImageClassName,
  onClick,
  productPath,
  tagJSX,
  title,
}) => {
  return (
    <div className="flex w-full items-start gap-6 rounded p-6 shadow">
      <i className={classNames(iconImageClassName, 'text-6xl')} />
      <div>
        {tagJSX}
        <h2 className="my-4 text-xl font-bold">{title}</h2>
        <p className="mb-4 text-base">{description}</p>
        <ButtonLink onClick={onClick} url={productPath} isFullWidth>
          {buttonLabel}
        </ButtonLink>
      </div>
    </div>
  )
}
