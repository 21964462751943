import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import { forwardRef } from 'react'
import { useIntl } from 'react-intl'
import { InputHelperText } from '../../InputHelperText'
import { InputLabel } from '../../InputLabel'
import { SelectMessages } from './messages'

type SelectOptionValue = any
type SelectOptions = { label: string; value: SelectOptionValue; isDisabled?: boolean }[]

export interface SelectProps {
  /** The default selected field shown for the Select */
  defaultSelectedValue?: SelectOptionValue
  /** The helper text to be shown under the Select. */
  helperText?: string
  /**
   * The flag indicating whether or not to hide the label shown above the select field. The label will still be used for
   * the aria-label and other identification purposes.
   */
  hideLabelText?: boolean
  /** A flag indicating the Select error state */
  invalid?: boolean
  /** A flag indicating the Select is disabled */
  isDisabled?: boolean
  /** A flag indicatiing if 100% of parent element width should be occupied */
  isFullWidth?: boolean
  /** A flag indicating the placeholder option disabled state */
  isPlaceholderOptionDisabled?: boolean
  /** The Select label (displayed over the Select). */
  labelText: string
  /** The handler to be invoked once the value has changed */
  onChange: (value?: string) => void
  /**
   * Available options to select. There can only be one option that is selected and that option will be brought to the
   * front of the array. If no option is selected, the placeholderText or `- Select an Option -` will be selected.
   */
  options: SelectOptions
  /**
   * If no option is selected, this will be selected. If placeholderText is not specified this will default to `- Select
   * an Option -`
   */
  placeholderText?: string
}

export const Select: FunctionComponent<SelectProps> = forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      defaultSelectedValue,
      helperText,
      hideLabelText,
      invalid,
      isDisabled,
      isFullWidth,
      isPlaceholderOptionDisabled,
      labelText,
      onChange,
      options,
      placeholderText,
    },
    ref,
  ) => {
    const intl = useIntl()

    const placeholderTextShown = placeholderText ?? intl.formatMessage(SelectMessages.defaultPlaceholder)
    const optionsWithPlaceholderText: SelectOptions = [
      { label: placeholderTextShown, value: placeholderTextShown },
      ...options,
    ]

    return (
      <div className={classNames('relative flex w-80 flex-col leading-3', { 'w-full': isFullWidth })}>
        {!hideLabelText && <InputLabel hasError={invalid} label={labelText} />}
        <div className="relative">
          <select
            aria-label={labelText}
            id={labelText}
            ref={ref}
            name={labelText}
            disabled={isDisabled}
            className={classNames(
              'placeholderText:text-neutral-40 w-full flex-1 resize-y appearance-none overflow-hidden rounded-md px-4 py-2 text-sm outline-none',
              {
                'border border-neutral-40 hover:border-green-30 focus:border-green-40 focus:shadow focus:shadow-green-40 focus-visible:border-green-40':
                  !isDisabled && !invalid,
                'border border-neutral-40 bg-neutral-20 text-neutral-40': isDisabled,
                'border border-red-70 shadow-sm shadow-red-50': invalid,
              },
            )}
            onChange={(event) =>
              event.target.value === placeholderTextShown ? onChange(undefined) : onChange(event.target.value)
            }
            value={defaultSelectedValue}
          >
            {optionsWithPlaceholderText.map((option) => (
              <option
                key={option.label}
                value={option.value}
                disabled={(option.value === placeholderTextShown && isPlaceholderOptionDisabled) || option.isDisabled}
              >
                {option.label}
              </option>
            ))}
          </select>
          <div
            className={classNames(
              'fa-solid fa-chevron-down pointer-events-none absolute right-3 top-3 text-sm text-neutral-100',
              {
                'text-neutral-40': isDisabled,
              },
            )}
          />
        </div>
        {helperText && <InputHelperText helperText={helperText} hasError={invalid} />}
      </div>
    )
  },
)

Select.displayName = 'Select'

Select.defaultProps = {
  defaultSelectedValue: undefined,
  labelText: undefined,
  helperText: undefined,
  hideLabelText: false,
  placeholderText: undefined,
  isDisabled: false,
  invalid: false,
  isFullWidth: false,
}
