import { defineMessages } from 'react-intl'

export const EditProjectsDataGeneralErrorContentMessages = defineMessages({
  body: {
    defaultMessage:
      'We were unable to retrieve the project you wished to edit at this time. Try again and if this issue persists contact support.',
    id: 'EW6M0Ht8',
    description:
      'The description shown in a toast notification message shown when we encounter an error on request to get edit projects data.',
  },
  title: {
    defaultMessage: 'Unable to edit project',
    id: 'RswuAXDA',
    description: 'The toast notification title shown when we encounter an error on request to get edit projects data.',
  },
})
