import type { FunctionComponent } from 'react'
import { ThreeColumnLayout } from '../../layouts/threeColumnLayout'
import { CreateJobQueueForm } from './components/CreateJobQueueForm'
import { CreateJobQueueSteps } from './components/CreateJobQueueSteps'

export interface CreateJobQueuePageProps {
  /** The flag indicating that the request to create a job queue is pending. */
  isCreateJobQueuePending: boolean
  /** The flag indicating if the left column is showing */
  isLeftColumnOpen: boolean
  /** The path for the Job Queues Page. */
  jobQueuesPath: string
  /** The callback executed when the user closes the left drawer */
  onCloseLeftDrawer: () => void
  /** A callback that when executed creates a job queue. */
  onCreateJobQueue: (name: string, description?: string) => void
}

export const CreateJobQueuePage: FunctionComponent<CreateJobQueuePageProps> = ({
  isCreateJobQueuePending,
  isLeftColumnOpen,
  jobQueuesPath,
  onCloseLeftDrawer,
  onCreateJobQueue,
}) => (
  <ThreeColumnLayout
    isLeftColumnOpen={isLeftColumnOpen}
    isMainColumnWithoutLayoutStyles
    LeftColumn={<CreateJobQueueSteps />}
    MainColumn={
      <CreateJobQueueForm
        isCreateJobQueuePending={isCreateJobQueuePending}
        jobQueuesPath={jobQueuesPath}
        onCreateJobQueue={onCreateJobQueue}
      />
    }
    onCloseLeftDrawer={onCloseLeftDrawer}
  />
)
