import type { Queue } from '@saladtechnologies/openapi-cloud-portal-browser'
import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { Button } from '../../../../components/Button'
import { Tag } from '../../../../components/Tag'
import { ButtonLink, Link } from '../../../../components/base'
import type { JobQueueTableConnections } from '../../../../features/jobQueueConnectionsTable/models'
import { JobQueueDetailsMainContentMessages } from '../../messages'
import { DeleteJobQueueModal } from '../DeleteJobQueueModal'
import { JobQueueTable } from '../JobQueueTable'

interface JobQueueDetailsMainContentProps {
  /** The job queue connections */
  connections: JobQueueTableConnections
  /** The edit job queue path */
  editJobQueuePath: string
  /** The flag indicating that the request to delete the job queue is pending. */
  isDeleteJobQueuePending: boolean
  /** The job queue */
  jobQueue: Queue
  /** The job queue path */
  jobQueuesPath: string
  /** The callback that deletes a job queue connection */
  onDeleteConnection: (containerGroupName: string) => void
  /** A callback that when executed deletes the job queue */
  onDeleteJobQueue: (name: string) => void
}

export const JobQueueDetailsMainContent: FunctionComponent<JobQueueDetailsMainContentProps> = ({
  connections,
  editJobQueuePath,
  isDeleteJobQueuePending,
  jobQueue,
  jobQueuesPath,
  onDeleteConnection,
  onDeleteJobQueue,
}) => {
  const intl = useIntl()
  const [isDeleteJobQueueModalOpen, setDeleteJobQueueModalOpen] = useState<boolean>(false)

  return (
    <div className="w-full">
      <div className="max-w-3xl">
        <div className="flex flex-col gap-8">
          <Link url={jobQueuesPath}>
            <span className={classNames('fa-solid fa-arrow-left mr-2')} />
            {intl.formatMessage(JobQueueDetailsMainContentMessages.backToJobQueuesLinkText)}
          </Link>

          <div>
            <h1 className="mb-1 text-2xl font-bold">{jobQueue.displayName || jobQueue.name}</h1>
            <div className="flex flex-wrap gap-4">
              <Tag color="gray">
                {intl.formatMessage(JobQueueDetailsMainContentMessages.connectedContainerGroups, {
                  container_group_count: connections.length,
                })}
              </Tag>
              <Tag color="gray">
                {intl.formatMessage(JobQueueDetailsMainContentMessages.createdDateAndTime, {
                  date: intl.formatDate(jobQueue.createTime, {
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric',
                  }),
                  time: intl.formatDate(jobQueue.createTime, {
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true,
                  }),
                })}
              </Tag>
            </div>
            <div className="mt-4">
              <p className="text-sm">{jobQueue.description}</p>
            </div>
          </div>

          <div className="mb-6 flex flex-wrap gap-1">
            <ButtonLink url={editJobQueuePath} iconClassName="fa-solid fa-pen-to-square" variant="blue-filled-light">
              {intl.formatMessage(JobQueueDetailsMainContentMessages.editButtonLabel)}
            </ButtonLink>
            <Button
              iconClassName="fa-solid fa-trash"
              onClick={() => setDeleteJobQueueModalOpen(true)}
              variant="red-filled-light"
            >
              {intl.formatMessage(JobQueueDetailsMainContentMessages.deleteButtonLabel)}
            </Button>
          </div>
        </div>
      </div>

      <JobQueueTable
        connections={connections}
        displayName={jobQueue.displayName || jobQueue.name}
        onDeleteConnection={onDeleteConnection}
      />

      {isDeleteJobQueueModalOpen && (
        <DeleteJobQueueModal
          connections={connections}
          displayName={jobQueue.displayName || jobQueue.name}
          onCancel={() => setDeleteJobQueueModalOpen(false)}
          isDeleteJobQueuePending={isDeleteJobQueuePending}
          onDeleteJobQueue={() => onDeleteJobQueue(jobQueue.name)}
        />
      )}
    </div>
  )
}
