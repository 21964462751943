import classNames from 'classnames'
import flattenDeep from 'lodash/flattenDeep'
import type { FunctionComponent, ReactNode } from 'react'
import { forwardRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { CheckboxCardsMessages } from './messages'
import type { CheckboxCardOptions } from './models'

interface CheckboxCardsProps {
  /** The card options the user has to select from. */
  cards: CheckboxCardOptions
  /** A custom label that would override the default `None Selected` option. */
  customNoneSelectedLabel?: string
  /** A custom label that would override the default `Select All` option. */
  customSelectAllLabel?: string
  /** The default selected value. If none is provided, the option `None Selected` will be set as selected on render. */
  defaultCheckedValue?: any
  /** Additional styles that will be applied to the default option checkbox cards */
  defaultOptionStyles?: string
  /** The filter component to be rendered below the helper text. */
  Filter?: ReactNode
  /** The text to be shown under the label. */
  helperText?: string | ReactNode
  /** A value indicating the Radio Cards error state */
  invalid?: boolean
  /** The label for the radio cards input. */
  label: string
  /** The onChange handler */
  onChange: (value: string[]) => void
  /** The name for the radio cards input. */
  name: string
  /** The value for the checkbox cards . */
  value?: string[]
}

const selectAllValue = 'select_all'
const noneSelectedValue = 'none_selected'

export const CheckboxCards: FunctionComponent<CheckboxCardsProps> = forwardRef<HTMLInputElement, CheckboxCardsProps>(
  (
    {
      cards,
      customNoneSelectedLabel,
      customSelectAllLabel,
      defaultCheckedValue,
      defaultOptionStyles,
      Filter,
      helperText,
      invalid,
      label,
      name,
      onChange,
      value,
    },
    ref,
  ) => {
    const initialValueSelected =
      value?.length === cards.length
        ? [selectAllValue, ...value]
        : value || [defaultCheckedValue ? defaultCheckedValue : noneSelectedValue]
    const [selectedOptions, setSelectedOptions] = useState<string[]>(initialValueSelected)
    const intl = useIntl()

    const defaultOptions: CheckboxCardOptions = [
      {
        value: noneSelectedValue,
        content: customNoneSelectedLabel
          ? customNoneSelectedLabel
          : intl.formatMessage(CheckboxCardsMessages.noneSelectedLabel),
      },
      {
        value: selectAllValue,
        content: customSelectAllLabel ? customSelectAllLabel : intl.formatMessage(CheckboxCardsMessages.selectAllLabel),
      },
    ]
    const cardsWithDefaultOptions = flattenDeep([defaultOptions, ...cards])
    const cardsWithoutDisabledOptions = cards.filter((card) => !card.disabled)

    return (
      <fieldset className="mb-10 w-full">
        <legend
          className={classNames('mb-1 text-base font-bold', {
            'text-red-70': invalid,
          })}
        >
          {label}
        </legend>
        <p
          className={classNames('text-sm', {
            'text-red-70': invalid,
          })}
        >
          {helperText}
        </p>
        {Filter && <div className="mt-4">{Filter}</div>}
        <div className="mt-4 flex flex-wrap gap-5">
          {cardsWithDefaultOptions.map((card, index) => (
            <div className="flex" key={index}>
              <div className="relative w-80">
                <input
                  onChange={(e) => {
                    if (card.value === selectAllValue) {
                      const allValues = cardsWithoutDisabledOptions.map((card) => card.value)
                      onChange(allValues)
                      const uiValues = [selectAllValue, ...allValues]
                      setSelectedOptions(uiValues)
                      return
                    }

                    if (card.value === noneSelectedValue) {
                      onChange([])
                      const uiValues = [noneSelectedValue]
                      setSelectedOptions(uiValues)
                      return
                    }

                    const selectedOptionsCopy = [...selectedOptions]
                    const selectedOptionsWithoutDefaults = selectedOptionsCopy.filter(
                      (option) => option !== selectAllValue && option !== noneSelectedValue,
                    )

                    if (e.target.checked) {
                      selectedOptionsWithoutDefaults.push(card.value)
                      if (selectedOptionsCopy.includes(noneSelectedValue)) {
                        selectedOptionsCopy.splice(selectedOptionsCopy.indexOf(noneSelectedValue), 1)
                      }

                      onChange(selectedOptionsWithoutDefaults)
                      setSelectedOptions([card.value, ...selectedOptionsCopy])
                    } else {
                      if (selectedOptionsWithoutDefaults.length === 1) {
                        onChange([])
                        setSelectedOptions([noneSelectedValue])
                        return
                      }

                      selectedOptionsWithoutDefaults.splice(selectedOptionsWithoutDefaults.indexOf(card.value), 1)
                      selectedOptionsCopy.splice(selectedOptionsCopy.indexOf(card.value), 1)
                      if (selectedOptionsCopy.includes(selectAllValue)) {
                        selectedOptionsCopy.splice(selectedOptionsCopy.indexOf(selectAllValue), 1)
                      }

                      onChange(selectedOptionsWithoutDefaults)
                      setSelectedOptions(selectedOptionsCopy)
                    }
                  }}
                  className="peer absolute block size-full cursor-pointer opacity-0"
                  checked={selectedOptions.includes(card.value)}
                  disabled={card.disabled}
                  id={`${card.value + index}`}
                  name={name}
                  ref={ref}
                  type="checkbox"
                  value={card.value}
                />
                <label
                  // TODO: Have better svg selector that doesn't depend on knowing the structure of the component in the children prop
                  className="flex size-full flex-col justify-center rounded-lg border border-transparent bg-neutral-10 p-2 shadow-md peer-checked:border-green-50 peer-checked:text-green-70 peer-hover:border-green-50 peer-focus:border-neutral-90 peer-disabled:border-transparent peer-disabled:opacity-50 peer-checked:[&>div>svg]:fill-green-70"
                  htmlFor={`${card.value + index}`}
                >
                  {card.value === selectAllValue || card.value === noneSelectedValue ? (
                    <p className={defaultOptionStyles}>{card.content}</p>
                  ) : (
                    card.content
                  )}
                </label>
              </div>
            </div>
          ))}
        </div>
      </fieldset>
    )
  },
)
