import { defineMessages } from 'react-intl'

export const DeleteContainerGroupModalMessages = defineMessages({
  title: {
    defaultMessage: 'Delete {container_group_display_name}',
    id: 'VJFWTbMm',
    description: 'Title of the delete container group modal',
  },
  body: {
    defaultMessage: 'Deleting a container group is permanent. Are you sure you’d like to delete this container group?',
    id: 'OU+A6moK',
    description: 'Description of the delete container group modal',
  },
  cancel: {
    defaultMessage: 'Cancel',
    id: 'hHNj31G9',
    description: 'Cancel button text',
  },
  delete: {
    defaultMessage: 'Delete',
    id: '5qRFq/S5',
    description: 'Delete button text',
  },
})
