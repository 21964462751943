import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import type { ObjectSchema } from 'yup'
import { object, string } from 'yup'
import { EditProjectMainContentMessages } from '../../messages'
import type { EditProjectValues } from './EditProjectMainContent'

export const useEditProjectFormValidation = (): ObjectSchema<EditProjectValues> => {
  const intl = useIntl()
  const validationScheme = useMemo(
    () =>
      object({
        displayName: string()
          .required(intl.formatMessage(EditProjectMainContentMessages.displayNameRequired))
          .min(2, intl.formatMessage(EditProjectMainContentMessages.projectNameMinMaxErrorMessage))
          .max(63, intl.formatMessage(EditProjectMainContentMessages.projectNameMinMaxErrorMessage)),
      }),
    [intl],
  )
  return validationScheme
}
