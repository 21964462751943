import { defineMessages } from 'react-intl'

export const RecipeDeploymentInstancesGeneralErrorContentMessages = defineMessages({
  body: {
    defaultMessage:
      'The recipe deployment instances you are trying to view are not available. Try again and if this issue persists {contact_support}',
    id: 'wH/ajZ8G',
    description:
      'The description shown in a toast notification message shown when we encounter an error on their request to get recipe deployment instances.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'WZf/krMu',
    description:
      'The text shown for the contact support link in the get recipe deployment instances error toast notification.',
  },
  title: {
    defaultMessage: 'Recipe deployment instances are not available',
    id: 'jukKeBDq',
    description:
      'The toast notification title shown when we encounter an error on their request to to get recipe deployment instances.',
  },
})
