import { defineMessages } from 'react-intl'

export const DeleteContainerGroupGeneralErrorContentMessages = defineMessages({
  body: {
    defaultMessage:
      'We were unable to delete your container group. Try again and if this issue persists {contact_support}.',
    id: 'P5Mc0w8E',
    description:
      'The description shown in a toast notification message shown when we encounter an error on their request to delete a container group.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'GaVxzbZR',
    description: 'The text shown for the contact support link in the create organization error toast notification.',
  },
  title: {
    defaultMessage: 'Error deleting container group',
    id: 'i5mcboqG',
    description:
      'The toast notification title shown when we encounter an error on their request to delete a container group.',
  },
})
