import { defineMessages } from 'react-intl'

export const GitHubInputFieldsMessages = defineMessages({
  needHelpText: {
    defaultMessage: 'Need help with setting up on GitHub?',
    id: 'AnV0WvqF',
    description: 'The help text shown below the GitHub input fields.',
  },
  personalAccessTokenLabel: {
    defaultMessage: 'Personal Access Token',
    id: 'LGF55l0A',
    description: 'The label for the GitHub personal access token input field.',
  },
  personalAccessTokenPlaceholderText: {
    defaultMessage: 'Your Personal Access Token',
    id: 'aH9bPXkR',
    description: 'The placeholder text for the GitHub personal access token input field.',
  },
  personalAccessTokenRequiredText: {
    defaultMessage: 'Personal Access Token is required',
    id: 'u72HjNCg',
    description: 'The required text for the GitHub personal access token input field.',
  },
  usernameLabel: {
    defaultMessage: 'Username',
    id: 'UCMsgwnK',
    description: 'The label for the GitHub username input field.',
  },
  usernamePlaceholderText: {
    defaultMessage: 'Your Username',
    id: 'hv8sLUUj',
    description: 'The placeholder text for the GitHub username input field.',
  },
  usernameRequiredText: {
    defaultMessage: 'Username is required',
    id: 'vjORwj8D',
    description: 'The required text for the GitHub username input field.',
  },
})
