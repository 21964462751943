import type { Queue } from '@saladtechnologies/openapi-cloud-portal-browser'
import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { Link, useParams } from 'react-router-dom'
import { Tag } from '../../../../components/Tag'
import { ButtonLink } from '../../../../components/base/ButtonLink'
import { Card } from '../../../../components/base/Card'
import { FeaturePreviewInlineNotification } from '../../../../components/featurePreview/FeaturePreviewInlineNotification'
import { getJobQueueDetailsPagePath } from '../../../../routes/routes-utils'
import { JobQueuesMainContentMessages } from '../../messages'

interface JobQueuesMainContentProps {
  /** The create job queue path */
  createJobQueuePath: string
  /** The job queue */
  jobQueues: Queue[]
}

export const JobQueuesMainContent: FunctionComponent<JobQueuesMainContentProps> = ({
  createJobQueuePath,
  jobQueues,
}) => {
  const intl = useIntl()
  const { organizationName = '', projectName = '' } = useParams()

  const isJobQueuesContainerGroupsReady = false

  return (
    <div className="w-full">
      <div className="max-w-3xl">
        <div className="mt-10 flex flex-col gap-4">
          <h1 className="mb-1 text-3xl font-bold">{intl.formatMessage(JobQueuesMainContentMessages.title)}</h1>
          <FeaturePreviewInlineNotification />
          <p>{intl.formatMessage(JobQueuesMainContentMessages.subtitle)}</p>
          <div className="mb-6 flex flex-wrap gap-3">
            <ButtonLink url={createJobQueuePath} iconClassName="fa-solid fa-folder-plus" variant="green-filled-light">
              {intl.formatMessage(JobQueuesMainContentMessages.createJobQueueButtonLabel)}
            </ButtonLink>
            <ButtonLink
              iconClassName="fa-solid fa-book"
              url="https://docs.salad.com/container-engine/job-queues"
              variant="blue-filled-light"
            >
              {intl.formatMessage(JobQueuesMainContentMessages.learnMoreButtonLabel)}
            </ButtonLink>
          </div>
        </div>

        {jobQueues.length > 0 && (
          <div className="flex flex-col gap-4">
            {jobQueues.map((jobQueue, index) => (
              <Link key={jobQueue.name} to={getJobQueueDetailsPagePath(organizationName, projectName, jobQueue.name)}>
                <Card key={index}>
                  <p className="mb-2 text-xl font-bold">{jobQueue.displayName || jobQueue.name}</p>
                  <div className="flex flex-wrap gap-4">
                    {isJobQueuesContainerGroupsReady && (
                      <Tag color="gray">
                        {intl.formatMessage(JobQueuesMainContentMessages.connectedContainerGroups, {
                          container_group_count: jobQueue.containerGroups.length,
                        })}
                      </Tag>
                    )}
                    <Tag color="gray">
                      {intl.formatMessage(JobQueuesMainContentMessages.createdDateAndTime, {
                        date: intl.formatDate(jobQueue.createTime, {
                          month: 'short',
                          day: 'numeric',
                          year: 'numeric',
                        }),
                        time: intl.formatDate(jobQueue.createTime, {
                          hour: 'numeric',
                          minute: 'numeric',
                          hour12: true,
                        }),
                      })}
                    </Tag>
                  </div>
                </Card>
              </Link>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
