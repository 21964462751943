import type { ReactNode } from 'react'
import type { ToastNotificationType } from '../models'
import { InvalidCredentialsToastContentMessages } from './messages'

/**
 * Retrieves the content needed for an error `ToastNotification` that is shown when the user provides invalid
 * credentials.
 */
export const getInvalidCredentialsContent = (): {
  body: ReactNode
  type: ToastNotificationType
  title: string
} => {
  return {
    body: InvalidCredentialsToastContentMessages.body.defaultMessage,
    type: 'error',
    title: InvalidCredentialsToastContentMessages.title.defaultMessage,
  }
}
