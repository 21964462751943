import { EMPTY, concat, delay, filter, from, mergeMap, of } from 'rxjs'
import {
  BillingAPI,
  OrganizationDataAPI,
  OrganizationOptionsAPI,
  OrganizationsAPI,
  ProjectsAPI,
  QuotasAPI,
  RecipesAPI,
} from '../apiMethods'
import { setIsRecipeInstancesQuotaExceeded } from '../features/editRecipeDeployment/editRecipeDeploymentSlice'
import { showToastNotification } from '../features/notifications/notificationsSlice'
import { organizationAdded } from '../features/organizations/organizationsSlice'
import { paymentMethodAdded, paymentMethodRemoved } from '../features/paymentMethod/paymentMethodSlice'
import { projectsAddedToOrganization } from '../features/projects/projectsSlice'
import { quotasAddedToOrganization, quotasRemovedFromOrganization } from '../features/quotas/quotasSlice'
import { ramOptionsReceived } from '../features/ramOptions/ramOptionsSlice'
import { getRecipeDetails, setRecipe } from '../features/recipeDetails/recipeDetailsSlice'
import { setRequestStatus } from '../features/requestStatus/requestStatusSlice'
import {
  getRecipeDetailsGeneralErrorContent,
  getRecipeDetailsNotAvailableErrorContent,
} from '../notifications/clientToastNotificationContent/recipes'
import { getRecipeMarketplacePagePath } from '../routes/routes-utils'
import type { AppEpic } from '../store'
import { navigateTo } from './navigationEpic'

export const onGetRecipeDetails: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(getRecipeDetails.match),
    mergeMap((action) =>
      concat(
        of(
          setRequestStatus({ request: 'getRecipeDetails', status: 'pending' }),
          setIsRecipeInstancesQuotaExceeded({ exceeded: false }),
        ),
        from(
          Promise.allSettled([
            BillingAPI.getPaymentMethod({
              organizationName: action.payload.organizationName,
            }),
            RecipesAPI.getRecipe({
              organizationName: action.payload.organizationName,
              recipeName: action.payload.recipeName,
            }),
            QuotasAPI.getQuotas({
              organizationName: action.payload.organizationName,
            }),
            OrganizationOptionsAPI.listRamOptions({
              organizationName: action.payload.organizationName,
            }),
            OrganizationsAPI.getOrganization({
              organizationName: action.payload.organizationName,
            }),
            ProjectsAPI.listProjects({
              organizationName: action.payload.organizationName,
            }),
          ]),
        ).pipe(
          mergeMap(
            ([
              paymentMethodResponse,
              recipeResponse,
              quotasResponse,
              ramOptionsResponse,
              organizationResponse,
              projectsResponse,
            ]) => {
              const hasAnyRequiredDataRequestsFailed =
                recipeResponse.status === 'rejected' ||
                organizationResponse.status === 'rejected' ||
                projectsResponse.status === 'rejected' ||
                ramOptionsResponse.status === 'rejected'
              if (hasAnyRequiredDataRequestsFailed) {
                return concat(
                  recipeResponse.status === 'rejected'
                    ? of(showToastNotification(getRecipeDetailsNotAvailableErrorContent(intl)))
                    : of(showToastNotification(getRecipeDetailsGeneralErrorContent(intl))),
                  of(
                    setRequestStatus({ request: 'getRecipeDetails', status: 'failed' }),
                    navigateTo({
                      path: getRecipeMarketplacePagePath(action.payload.organizationName, action.payload.projectName),
                    }),
                  ),
                  of(setRequestStatus({ request: 'getRecipeDetails', status: 'idle' })).pipe(delay(1)),
                )
              }
              const gpuClassIds = recipeResponse.value.resources.gpuClasses

              if (gpuClassIds) {
                return from(
                  OrganizationDataAPI.listGpuClasses({
                    organizationName: action.payload.organizationName,
                  }),
                ).pipe(
                  mergeMap((response) => {
                    const gpuClasses = response.items.filter((gpuClass) => gpuClassIds.includes(gpuClass.id))
                    const gpuClassesTotalPrice = gpuClasses.reduce(
                      (acc, gpuClass) => acc + parseFloat(gpuClass.price),
                      0,
                    )
                    return concat(
                      of(
                        setRequestStatus({ request: 'getRecipeDetails', status: 'succeeded' }),
                        setRecipe({
                          recipe: recipeResponse.value,
                          gpuPrice: gpuClassesTotalPrice,
                          ramOptions: ramOptionsResponse.value.items,
                        }),
                        organizationAdded(organizationResponse.value),
                        projectsAddedToOrganization({
                          organizationName: organizationResponse.value.name,
                          projects: projectsResponse.value.items,
                        }),
                      ),
                      ramOptionsResponse.status === 'fulfilled'
                        ? of(ramOptionsReceived({ ramOptions: ramOptionsResponse.value.items }))
                        : EMPTY,
                      quotasResponse.status === 'fulfilled'
                        ? of(
                            quotasAddedToOrganization({
                              organizationName: action.payload.organizationName,
                              quotas: quotasResponse.value,
                            }),
                            setIsRecipeInstancesQuotaExceeded({
                              exceeded: quotasResponse.value.recipesQuotas.recipeInstanceQuota === 0,
                            }),
                          )
                        : of(quotasRemovedFromOrganization(action.payload.organizationName)),
                      paymentMethodResponse.status === 'fulfilled'
                        ? of(paymentMethodAdded(paymentMethodResponse.value))
                        : of(paymentMethodRemoved(action.payload.organizationName)),
                      of(setRequestStatus({ request: 'getRecipeDetails', status: 'idle' })).pipe(delay(1)),
                    )
                  }),
                )
              }
              return concat(
                of(
                  setRequestStatus({ request: 'getRecipeDetails', status: 'succeeded' }),
                  setRecipe({
                    recipe: recipeResponse.value,
                    gpuPrice: undefined,
                    ramOptions: ramOptionsResponse.value.items,
                  }),
                  organizationAdded(organizationResponse.value),
                  projectsAddedToOrganization({
                    organizationName: organizationResponse.value.name,
                    projects: projectsResponse.value.items,
                  }),
                ),
                quotasResponse.status === 'fulfilled'
                  ? of(
                      quotasAddedToOrganization({
                        organizationName: action.payload.organizationName,
                        quotas: quotasResponse.value,
                      }),
                      setIsRecipeInstancesQuotaExceeded({
                        exceeded: quotasResponse.value.recipesQuotas.recipeInstanceQuota === 0,
                      }),
                    )
                  : of(quotasRemovedFromOrganization(action.payload.organizationName)),
                paymentMethodResponse.status === 'fulfilled'
                  ? of(paymentMethodAdded(paymentMethodResponse.value))
                  : of(paymentMethodRemoved(action.payload.organizationName)),
                of(setRequestStatus({ request: 'getRecipeDetails', status: 'idle' })).pipe(delay(1)),
              )
            },
          ),
        ),
      ),
    ),
  )
