import { defineMessages } from 'react-intl'

export const DeploymentStatusTagMessages = defineMessages({
  deployingLabel: {
    defaultMessage: 'DEPLOYING',
    id: 'VWINbdB2',
    description: 'The deploying status label',
  },
  failedLabel: {
    defaultMessage: 'FAILED',
    id: 'Xz2eSrrd',
    description: 'The failed status label',
  },
  failedToPullImageLabel: {
    defaultMessage: 'Failed to Pull Image',
    id: '9FVqAgF1',
    description: 'The failed to pull image status label',
  },
  preparingLabel: {
    defaultMessage: 'PREPARING',
    id: 'XQ+zgC/0',
    description: 'The preparing status label',
  },
  runningLabel: {
    defaultMessage: 'RUNNING',
    id: 'yAyYah/5',
    description: 'The running status label',
  },
  stoppedLabel: {
    defaultMessage: 'STOPPED',
    id: 'ooZjf5EQ',
    description: 'The stopped status label',
  },
})
