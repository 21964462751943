import { defineMessages } from 'react-intl'

export const EditRecipeDeploymentMainContentMessages = defineMessages({
  recipeNameDescription: {
    defaultMessage:
      'This is the permanent name for your deployment used in API calls. You can change the name used in the Portal by editing the display name below.',
    id: '8wE+pDl3',
    description: 'The description about the recipe deployment name.',
  },
  recipeNameTitle: {
    defaultMessage: 'Recipe Deployment Name',
    id: 'C5V/fAF0',
    description: 'The header for the recipe deployment name overview section.',
  },
  returnToRecipeDeploymentPageLinkText: {
    defaultMessage: 'Back to {recipe_deployment_display_name}',
    id: 'U0iFdLzl',
    description: 'The text for the link that that returns the member back to the recipe deployment page.',
  },
  title: {
    defaultMessage: 'Edit {recipe_display_name}',
    id: 'lPqII2nX',
    description: 'The title of for the Edit Recipe Deployment page.',
  },
})
