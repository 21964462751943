import { useMemo } from 'react'
import type { ObjectSchema } from 'yup'
import { object, string } from 'yup'
import type { CreateProjectFormValues } from './CreateProjectForm'

export const useCreateProjectFormValidation = (): ObjectSchema<CreateProjectFormValues> => {
  const validationScheme = useMemo(
    () =>
      object({
        projectName: string()
          .required()
          .matches(/^[a-z][a-z0-9-]{0,61}[a-z0-9]$/),
      }),
    [],
  )
  return validationScheme
}
