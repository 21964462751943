import { createSelector } from '@reduxjs/toolkit'
import { AppState } from '../../store'
import { containerGroupInstancesAdapter } from './containerGroupInstancesSlice'

const containerGroupInstancesSelectors = containerGroupInstancesAdapter.getSelectors()

export const selectContainerGroupInstances = createSelector(
  containerGroupInstancesSelectors.selectById,
  (containerGroups) => {
    return containerGroups?.instances
  },
)

export const selectContainerGroupInstance = (state: AppState, entityId: string, instanceId: string) => {
  const containerGroupInstances = state.containerGroupInstances.entities[entityId]?.instances ?? []

  if (containerGroupInstances.length > 0) {
    return containerGroupInstances.find((instance) => instance.instanceId === instanceId)
  }

  return undefined
}
