import { InferenceEndpointJob } from '@saladtechnologies/openapi-cloud-portal-browser'

/**
 * Configures the Inference Endpoint Jobs entity ID based on the organization name and project name.
 *
 * @param organizationName The organization name
 * @param inferenceEndpointName The inference endpoint name
 */
export const configuresInferenceEndpointJobsEntityId = (organizationName: string, inferenceEndpointName: string) =>
  `${organizationName}:${inferenceEndpointName}`

/**
 * Finds the latest inference endpoint job from the list of inference endpoint jobs.
 *
 * @param inferenceEndpointJobs The list of inference endpoint jobs
 * @returns The latest inference endpoint job
 */
export const findLatestInferenceEndpointJob = (inferenceEndpointJobs: InferenceEndpointJob[]) => {
  if (inferenceEndpointJobs.length === 0) {
    return undefined
  }
  return inferenceEndpointJobs.reduce((latestJob, currentJob) => {
    return new Date(latestJob.createTime) > new Date(currentJob.createTime) ? latestJob : currentJob
  })
}
