import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { EmptyResult } from '../../../../components/page/EmptyResult'
import { FailedToLoadRecipeDeploymentsMessages } from '../../messages'

interface FailedToLoadRecipeDeploymentsProps {
  /** The callback executed when the user clicks the try again button. */
  onTryAgain: () => void
}

export const FailedToLoadRecipeDeployments: FunctionComponent<FailedToLoadRecipeDeploymentsProps> = ({
  onTryAgain,
}) => {
  const intl = useIntl()
  return (
    <EmptyResult
      buttonLabel={intl.formatMessage(FailedToLoadRecipeDeploymentsMessages.tryAgainButtonLabel)}
      mainMessage={intl.formatMessage(FailedToLoadRecipeDeploymentsMessages.message)}
      onButtonClick={onTryAgain}
    />
  )
}
