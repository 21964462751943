import type { FunctionComponent } from 'react'
import { LeftNavigationBar } from '../../components/LeftNavigationBar/LeftNavigationBar'
import type { ProjectSelectOptions } from '../../components/LeftNavigationBar/models'
import { ThreeColumnLayout } from '../../layouts/threeColumnLayout/ThreeColumnLayout'
import { TeamPageMainContent } from './components/TeamPageMainContent'
import type { TeamPageMemberCardList } from './models'

export interface TeamPageProps {
  /**
   * The current organization that the user is in.
   *
   * @param name The organization slug name,
   * @param displayName The organization display name.
   */
  currentOrganization: { name: string; displayName: string }
  /** The current project that the user is in. */
  currentProject?: { name: string; displayName: string }
  /** The user's email address. */
  email: string
  /** The path for the Invite Team Member Page. */
  inviteTeamMemberPath: string
  /** A value indicating whether the Left Column is open. */
  isLeftColumnOpen: boolean
  /**
   * The list of team members for the current organization being viewed. This list is shaped to match the information
   * needed to create a `Team Page Member Card`.
   */
  memberList: TeamPageMemberCardList
  /** The callback executed when the user closes the left drawer */
  onCloseLeftDrawer: () => void
  /**
   * The handler for when something new is selected in the project dropdown. If the current active project is selected,
   * nothing will happen.
   *
   * @param projectName The selected project name.
   */
  onProjectChange: (projectName: string) => void
  /**
   * The callback executed when the user clicks the contact support button.
   *
   * @param memberId The unique identifier of the invitation.
   * @param email The email address of the member that is being reinvited.
   */
  onRemoveMember: (memberId: string, email: string) => void
  /**
   * A callback that when executed resends the invitation to the team member.
   *
   * @param invitationId The unique identifier of the invitation.
   * @param email The email address of the member that is being reinvited.
   */
  onResendInvitation: (invitationId: string, email: string) => void
  /**
   * A callback that when executed revokes the invitation sent for to the team member.
   *
   * @param invitationId The unique identifier of the invitation.
   * @param email The email address of the member that is being reinvited.
   */
  onRevokeInvitation: (invitationId: string, email: string) => void
  /** An array of the current available projects. If empty, we will default to no select bar being displayed */
  projects?: ProjectSelectOptions
}

export const TeamPage: FunctionComponent<TeamPageProps> = ({
  currentOrganization,
  currentProject,
  email,
  inviteTeamMemberPath,
  isLeftColumnOpen,
  memberList,
  onCloseLeftDrawer,
  onProjectChange,
  onRemoveMember,
  onResendInvitation,
  onRevokeInvitation,
  projects,
}) => {
  return (
    <>
      <ThreeColumnLayout
        isLeftColumnOpen={isLeftColumnOpen}
        LeftColumn={
          <LeftNavigationBar
            currentOrganization={currentOrganization}
            currentProject={currentProject}
            isProjectSelectPlaceholderOptionSelectable={true}
            onProjectChange={onProjectChange}
            projects={projects}
          />
        }
        onCloseLeftDrawer={onCloseLeftDrawer}
        MainColumn={
          <TeamPageMainContent
            currentUserEmail={email}
            inviteTeamMemberPath={inviteTeamMemberPath}
            memberList={memberList}
            onRemoveMember={onRemoveMember}
            onResendInvitation={onResendInvitation}
            onRevokeInvitation={onRevokeInvitation}
            organizationDisplayName={currentOrganization.displayName}
          />
        }
      />
    </>
  )
}
