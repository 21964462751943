import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { Profile } from '@saladtechnologies/openapi-cloud-portal-browser'
import { onAuthenticated } from '../../services'

export interface ProfileState {
  getProfileErrorMessage: string | undefined
  getProfileIsPending: boolean
  profile: Profile | undefined
}

const initialState: ProfileState = {
  getProfileErrorMessage: undefined,
  getProfileIsPending: false,
  profile: undefined,
}

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    getProfile() {},
    setGetProfileErrorMessage(state, action: PayloadAction<{ errorMessage: string | undefined }>) {
      state.getProfileErrorMessage = action.payload.errorMessage
    },
    setGetProfileIsPending(state, action: PayloadAction<{ pending: boolean }>) {
      state.getProfileIsPending = action.payload.pending
    },
    setProfile(state, action: PayloadAction<{ profile: Profile }>) {
      state.profile = action.payload.profile
      if (action.payload.profile != null) {
        onAuthenticated(action.payload.profile.id, action.payload.profile.email)
      }
    },
  },
})

export const { getProfile, setGetProfileErrorMessage, setGetProfileIsPending, setProfile } = profileSlice.actions
