import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import type { ObjectSchema } from 'yup'
import { object, ref, string } from 'yup'
import { isValidPassword } from '../../../utils/formValidation'
import type { UpdatePasswordFormValues } from './UpdatePassword'
import { UpdatePasswordMessages } from './UpdatePasswordMessages'

export const useUpdatePasswordFormValidation = (): ObjectSchema<UpdatePasswordFormValues> => {
  const intl = useIntl()
  const validationScheme = useMemo(
    () =>
      object({
        password: string()
          .required()
          .test((value) => isValidPassword(value)),
        verifyPassword: string()
          .required('')
          .oneOf([ref('password')], intl.formatMessage(UpdatePasswordMessages.verifyPasswordDoesNotMatch)),
      }),
    [intl],
  )
  return validationScheme
}
