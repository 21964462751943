import { defineMessages } from 'react-intl'

export const EditContainerGroupSucceedContentMessages = defineMessages({
  title: {
    defaultMessage: 'Container Group Updated!',
    id: 'J5yu0S3s',
    description:
      'The toast notification title shown when the user has saved the edited container group details successfully.',
  },
  body: {
    defaultMessage: 'This container group has been edited to match your preferences',
    id: 'lGqYTM5K',
    description:
      'The toast notification description shown when the user has saved the edited container group details successfully.',
  },
})
