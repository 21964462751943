import { SortConfigType } from './Table'
import { TableItem, TableSortingOrder } from './models'

export const statusOrder = ['stopping', 'running', 'creating', 'downloading', 'allocating']

export const sortItems = (items: TableItem[], sortConfig: SortConfigType | null): TableItem[] => {
  if (!sortConfig) {
    return items
  }

  const sortByStatus = (a: TableItem, b: TableItem): number => {
    const statusPriorityA = statusOrder.indexOf(a[sortConfig.key]?.sortWith as string)
    const statusPriorityB = statusOrder.indexOf(b[sortConfig.key]?.sortWith as string)

    if (statusPriorityA < statusPriorityB) {
      return sortConfig.direction === TableSortingOrder.Ascending ? -1 : 1
    }
    if (statusPriorityA > statusPriorityB) {
      return sortConfig.direction === TableSortingOrder.Ascending ? 1 : -1
    }
    return 0
  }

  return [...items].sort((a, b) => {
    const aVal = a[sortConfig.key]?.sortWith ?? a[sortConfig.key]?.value
    const bVal = b[sortConfig.key]?.sortWith ?? b[sortConfig.key]?.value

    if (aVal instanceof Date && bVal instanceof Date) {
      return sortConfig.direction === TableSortingOrder.Ascending
        ? aVal.getTime() - bVal.getTime()
        : bVal.getTime() - aVal.getTime()
    }

    if (
      typeof aVal === 'string' &&
      typeof bVal === 'string' &&
      statusOrder.includes(aVal) &&
      statusOrder.includes(bVal)
    ) {
      return sortByStatus(a, b)
    }

    if (typeof aVal === 'string' && typeof bVal === 'string') {
      return sortConfig.direction === TableSortingOrder.Ascending ? aVal.localeCompare(bVal) : bVal.localeCompare(aVal)
    }

    if (typeof aVal === 'number' && typeof bVal === 'number') {
      return sortConfig.direction === TableSortingOrder.Ascending ? aVal - bVal : bVal - aVal
    }

    return 0
  })
}
