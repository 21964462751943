import { AttachAddon } from '@xterm/addon-attach'
import { FitAddon } from '@xterm/addon-fit'
import { Unicode11Addon } from '@xterm/addon-unicode11'
import { WebLinksAddon } from '@xterm/addon-web-links'
import { WebglAddon } from '@xterm/addon-webgl'
import { Terminal } from '@xterm/xterm'
import '@xterm/xterm/css/xterm.css'
import classNames from 'classnames'
import { useCallback, useEffect, useMemo, useRef, useState, type FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { useWindowSize } from '../../../../../hooks'
import { FeaturePreviewPill } from '../../../../featurePreview/FeaturePreviewPill'
import { XTerminalMessages } from './messages'

export interface XTerminalProps {
  /** The terminal websocket url */
  terminalWebSocketUrl?: string
}

export const XTerminal: FunctionComponent<XTerminalProps> = ({ terminalWebSocketUrl }) => {
  const intl = useIntl()
  const size = useWindowSize()
  const terminalRef = useRef<HTMLDivElement | null>(null)
  const [term, setTerm] = useState<Terminal | null>(null)
  const [isTerminalConnected, onSetIsTerminalConnected] = useState<boolean | null>(null)
  const [hasTerminalError, onSetHasTerminalError] = useState<boolean>(false)
  const fitAddon = useMemo(() => new FitAddon(), [])
  let newWebSocket = useRef<WebSocket | null>(null)

  useEffect(() => {
    const terminal = new Terminal({
      allowProposedApi: true,
      cursorBlink: true,
    })
    setTerm(terminal)
    return () => {
      if (terminal) {
        terminal.dispose()
      }
      if (newWebSocket.current) {
        newWebSocket.current.close()
      }
    }
  }, [])

  useEffect(() => {
    if (term) {
      if (terminalRef.current) {
        term.open(terminalRef.current)
        setTerm(term)
        term.loadAddon(fitAddon)
        term.loadAddon(new WebLinksAddon())
        term.loadAddon(new WebglAddon())
        term.loadAddon(new Unicode11Addon())
        fitAddon.activate(term)
        fitAddon.fit()
      }
    }

    return () => {
      term?.dispose()
    }
  }, [fitAddon, term, terminalRef])

  useEffect(() => {
    fitAddon.fit()
  }, [term, size, fitAddon])

  const handleConnectToWebSocket = useCallback(() => {
    if (terminalWebSocketUrl) {
      newWebSocket.current = new WebSocket(terminalWebSocketUrl)
      const attachAddon = new AttachAddon(newWebSocket.current)
      newWebSocket.current.onclose = () => {
        onSetIsTerminalConnected(false)
      }
      newWebSocket.current.onerror = () => {
        onSetHasTerminalError(true)
      }
      newWebSocket.current.onopen = () => {
        onSetIsTerminalConnected(true)
        onSetHasTerminalError(false)
      }
      term?.loadAddon(attachAddon)
    }
  }, [onSetHasTerminalError, onSetIsTerminalConnected, term, terminalWebSocketUrl])

  useEffect(() => {
    handleConnectToWebSocket()
  }, [handleConnectToWebSocket, term, terminalWebSocketUrl])

  const offsetTerminalHeight = 450
  const terminalHeight = size.height - offsetTerminalHeight

  const terminalStatusMessage = useMemo(() => {
    return isTerminalConnected === null
      ? null
      : isTerminalConnected
        ? intl.formatMessage(XTerminalMessages.terminalIsConnected)
        : hasTerminalError
          ? intl.formatMessage(XTerminalMessages.terminalHasError)
          : intl.formatMessage(XTerminalMessages.terminalConnectionHasClosed)
  }, [hasTerminalError, intl, isTerminalConnected])

  return (
    <div className="mt-4">
      <div className="min-h-16">
        <div className="flex gap-2">
          <h2 className="mb-2 font-medium">{intl.formatMessage(XTerminalMessages.terminalTitle)}</h2>
          <FeaturePreviewPill />
        </div>
        <p>{terminalStatusMessage}</p>
      </div>
      {/* I had to use style here because tailwind can not handle arbitrary values computed dynamically */}
      <div style={{ height: `${terminalHeight}px` }} className={classNames(`max-w-6xl`)} ref={terminalRef} />
    </div>
  )
}
