import type { Queue } from '@saladtechnologies/openapi-cloud-portal-browser'
import type { FunctionComponent } from 'react'
import { LeftNavigationBar } from '../../components/LeftNavigationBar'
import type { ProjectSelectOptions } from '../../components/LeftNavigationBar/models'
import { ThreeColumnLayout } from '../../layouts/threeColumnLayout'
import { JobQueuesMainContent } from './components/JobQueuesMainContent'

export interface JobQueuesPageProps {
  /** The create job queue path */
  createJobQueuePath: string
  /** The current organization that the user is in. */
  currentOrganization: { name: string; displayName: string }
  /** The current project that the user is in. */
  currentProject: { name: string; displayName: string }
  /** The flag indicating that the request to delete the project is pending. */
  isDeleteProjectPending: boolean
  /** The flag indicating that the request to delete the project is successful. */
  isDeleteProjectSuccessful: boolean
  /** The flag indicating if the left column is showing */
  isLeftColumnOpen: boolean
  /** The job queue */
  jobQueues: Queue[]
  /** The callback executed when the user closes the left drawer */
  onCloseLeftDrawer: () => void
  /** The callback called when the user deletes the project. */
  onDeleteProject: (projectName: string) => void
  /**
   * The handler for when something new is selected in the project dropdown. If the current active project is selected,
   * nothing will happen.
   */
  onProjectChange: (projectName: string) => void
  /** An array of the current available projects. If empty, we will default to no select bar being displayed */
  projects: ProjectSelectOptions
}

export const JobQueuesPage: FunctionComponent<JobQueuesPageProps> = ({
  createJobQueuePath,
  currentOrganization,
  currentProject,
  isDeleteProjectPending,
  isDeleteProjectSuccessful,
  isLeftColumnOpen,
  jobQueues,
  onCloseLeftDrawer,
  onDeleteProject,
  onProjectChange,
  projects,
}) => (
  <ThreeColumnLayout
    isLeftColumnOpen={isLeftColumnOpen}
    LeftColumn={
      <LeftNavigationBar
        currentOrganization={currentOrganization}
        currentProject={currentProject}
        isDeleteProjectPending={isDeleteProjectPending}
        isDeleteProjectSuccessful={isDeleteProjectSuccessful}
        onDeleteProject={onDeleteProject}
        onProjectChange={onProjectChange}
        projects={projects}
      />
    }
    MainColumn={<JobQueuesMainContent createJobQueuePath={createJobQueuePath} jobQueues={jobQueues} />}
    onCloseLeftDrawer={onCloseLeftDrawer}
  />
)
