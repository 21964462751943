import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { Button } from '../../../../components/Button'
import { ButtonLink } from '../../../../components/base'
import { Modal } from '../../../../components/block/Modal'
import { ContainerGroupQuotaExceededMessages } from '../../messages/ContainerGroupQuotaExceededModalMessages'

interface ContainerGroupQuotaExceededModalProps {
  /** The link for users to request an increase to their container groups quota */
  linkToRequestIncreaseForContainerGroupQuotas: string
  /** The callback executed when the user clicks the `Cancel` button. */
  onCloseWindow: () => void
}

export const ContainerGroupQuotaExceededModal: FunctionComponent<ContainerGroupQuotaExceededModalProps> = ({
  linkToRequestIncreaseForContainerGroupQuotas,
  onCloseWindow,
}) => {
  const intl = useIntl()

  return (
    <Modal onClose={onCloseWindow} title={intl.formatMessage(ContainerGroupQuotaExceededMessages.title)}>
      <h1 className="mb-8 text-2xl font-bold">{intl.formatMessage(ContainerGroupQuotaExceededMessages.title)}</h1>
      <p className="mb-8">{intl.formatMessage(ContainerGroupQuotaExceededMessages.body1)}</p>
      <p className="mb-8">{intl.formatMessage(ContainerGroupQuotaExceededMessages.body2)}</p>
      <div className="flex max-w-md gap-6">
        <Button variant="green-outlined" onClick={onCloseWindow} isFullWidth>
          {intl.formatMessage(ContainerGroupQuotaExceededMessages.cancelButtonLabel)}
        </Button>
        <ButtonLink isFullWidth url={linkToRequestIncreaseForContainerGroupQuotas}>
          {intl.formatMessage(ContainerGroupQuotaExceededMessages.requestIncreaseButtonLabel)}
        </ButtonLink>
      </div>
    </Modal>
  )
}
