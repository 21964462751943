import type {
  ContainerGroupInstance,
  ContainerGroupInstanceStateEnum,
  RecipeDeploymentInstancesInstancesInner,
} from '@saladtechnologies/openapi-cloud-portal-browser'
import type { DeploymentInstance, DeploymentStatus } from './models'

/**
 * Given the count provided, returns an array of Deployment Instances with the default values for an `allocating`
 * instance.
 *
 * @param startTime The Date value of when the Container Group was started.
 * @param count The number of allocated instances requested.
 */
export const getAllocatedInstances = (startTime: Date, count: number): DeploymentInstance[] => {
  const currentTime = new Date()
  const differenceInSeconds = (currentTime.getTime() - startTime.getTime()) / 1000
  const allocatingTakingLongerThanExpected = differenceInSeconds > 300

  return Array.from({ length: count }, () => ({
    allocatingTakingLongerThanExpected,
    creatingProgress: undefined,
    downloadingProgress: undefined,
    hasStarted: false,
    isReady: false,
    isReallocatePending: false,
    isRecreatePending: false,
    isRestartPending: false,
    machineId: undefined,
    instanceId: undefined,
    startTime: null,
    status: 'allocating',
    updateTime: null,
  }))
}

interface ProgressAndStatusValues {
  allocatingTakingLongerThanExpected: boolean
  creatingProgress: number | undefined
  downloadingProgress: number | undefined
  status: DeploymentStatus
}

/**
 * Returns the progress and status values for a given Container Group Instance.
 *
 * @param containerImageSize The size of the container.
 * @param startTime The Date value of when the Container Group was started.
 * @param state The Container Group Instance State provided by the API.
 */
const getProgressAndStatus = (
  containerImageSize: number,
  startTime: Date,
  state: ContainerGroupInstanceStateEnum,
): ProgressAndStatusValues => {
  const currentTime = new Date()
  const secondsElapsedSinceStartTime = Math.floor((currentTime.getTime() - startTime.getTime()) / 1000)
  const secondsToCompleteDownloadingStep = 240 + Math.ceil(containerImageSize * 0.6)
  const secondsToCompleteCreatingStep = 240

  switch (state) {
    case 'downloading':
      const downloadingProgress = Math.min(
        Math.floor((secondsElapsedSinceStartTime / secondsToCompleteDownloadingStep) * 100),
        99,
      )
      return {
        allocatingTakingLongerThanExpected: false,
        downloadingProgress,
        creatingProgress: undefined,
        status: 'downloading',
      }
    case 'creating':
      const creatingProgress = Math.min(
        Math.floor((secondsElapsedSinceStartTime / secondsToCompleteCreatingStep) * 100),
        99,
      )
      return {
        allocatingTakingLongerThanExpected: false,
        downloadingProgress: 100,
        creatingProgress,
        status: 'creating',
      }
    case 'running':
      return {
        allocatingTakingLongerThanExpected: false,
        downloadingProgress: 100,
        creatingProgress: 100,
        status: 'running',
      }
    default:
      return {
        allocatingTakingLongerThanExpected: false,
        downloadingProgress: undefined,
        creatingProgress: undefined,
        status: state,
      }
  }
}

/* Helper function to shape the deployment instance data */
const configureDeploymentInstance = (
  instance: {
    machineId: string | undefined
    instanceId: string | undefined
    state: ContainerGroupInstanceStateEnum
    updateTime: Date
    started?: boolean
    ready?: boolean
    version?: number
  },
  imageSize: number,
): DeploymentInstance => {
  const { instanceId, machineId, ready, started, state, updateTime, version } = instance
  const percentageAndStatus = getProgressAndStatus(imageSize, updateTime, state)

  return {
    allocatingTakingLongerThanExpected: percentageAndStatus.allocatingTakingLongerThanExpected,
    creatingProgress: percentageAndStatus.creatingProgress,
    downloadingProgress: percentageAndStatus.downloadingProgress,
    hasStarted: started || false,
    isReady: ready || false,
    isReallocatePending: false,
    isRecreatePending: false,
    isRestartPending: false,
    machineId: machineId,
    instanceId: instanceId,
    status: percentageAndStatus.status,
    updateTime,
    version,
  }
}

/**
 * Configures the container group instances into the shape needed to display in the DeploymentInstanceTable.
 *
 * @param containerGroupInstances The container group instances retrieved from the API.
 * @param containerImageSize The size of the container.
 */
export const configureContainerGroupDeploymentInstances = (
  containerGroupInstances: ContainerGroupInstance[],
  containerGroupImageSize: number,
): DeploymentInstance[] => {
  return containerGroupInstances.map((instance) => configureDeploymentInstance(instance, containerGroupImageSize))
}

/**
 * Configures the recipe deployment instances into the shape needed to display in the DeploymentInstanceTable.
 *
 * @param recipeDeploymentInstances The recipe deployment instances retrieved from the API.
 * @param recipeDeploymentImageSize The size of the recipe deployment.
 */
export const configureRecipeDeploymentInstances = (
  recipeDeploymentInstances: RecipeDeploymentInstancesInstancesInner[],
  recipeDeploymentImageSize: number,
): DeploymentInstance[] => {
  return recipeDeploymentInstances.map((instance) => configureDeploymentInstance(instance, recipeDeploymentImageSize))
}

/**
 * Configures the container group instances into the shape needed to display in the DeploymentInstanceTable.
 *
 * @param instances The deployment instances.
 * @param instanceId The instance ID.
 * @param isPending The flag indicating if the request is pending.
 * @param requestType Indicates which `isPending` value should be updated for the ('recreate' | 'restart' |
 *   'reallocate') requests.
 */
export const updateDeploymentInstanceRequestPending = (
  instances: DeploymentInstance[],
  instanceId: string,
  isPending: boolean,
  requestType: 'reallocate' | 'recreate' | 'restart',
): DeploymentInstance[] => {
  return instances.map((instance) => {
    if (instance.instanceId !== instanceId) {
      return instance
    }

    const updatedInstance = { ...instance }

    switch (requestType) {
      case 'reallocate':
        updatedInstance.isReallocatePending = isPending
        break
      case 'recreate':
        updatedInstance.isRecreatePending = isPending
        break
      case 'restart':
        updatedInstance.isRestartPending = isPending
        break
    }

    return updatedInstance
  })
}

/**
 * Converts the container image size provided by the API to MB. If no image size is provided, the average container
 * image size in MB will be returned.
 *
 * @param containerImageSize The size of the container.
 */
export const convertContainerGroupImageSizeToMB = (containerGroupImageSize?: number): number => {
  const averageContainerImageSizeInMb = 10240
  return containerGroupImageSize ? containerGroupImageSize / 1024 / 1024 : averageContainerImageSizeInMb
}
