import classNames from 'classnames'
import { forwardRef, type ChangeEvent, type FunctionComponent } from 'react'

export interface SearchInputProps {
  /** The default value of the Search Input. */
  defaultValue?: string
  /** The flag indicating if the Search Input should occupy all available width provided by the parent element. */
  isFullWidth?: boolean
  /** The handler to be invoked once the Search Input value has changed. */
  onChange: (value: string) => void
  /** The Search Input placeholder. */
  placeholder: string
}

export const SearchInput: FunctionComponent<SearchInputProps> = forwardRef<HTMLLabelElement, SearchInputProps>(
  ({ defaultValue, isFullWidth, onChange, placeholder }, ref) => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value)
    }

    return (
      <label
        className={classNames('relative flex flex-col leading-3', {
          'w-96': !isFullWidth,
          'w-full': isFullWidth,
        })}
        htmlFor={placeholder}
        aria-label={placeholder}
        ref={ref}
      >
        <input
          id={placeholder}
          name={placeholder}
          className="w-full flex-1 resize-y overflow-hidden rounded-md border border-neutral-40 py-2 pl-11 text-base outline-none placeholder:text-neutral-40 hover:border-green-30 focus:border-green-40 focus:shadow focus:shadow-green-40 focus-visible:border-green-40"
          placeholder={placeholder}
          defaultValue={defaultValue}
          onChange={handleChange}
        />
        <div className={classNames('fa fa-search', 'absolute left-4 top-3 text-neutral-60')} />
      </label>
    )
  },
)

SearchInput.defaultProps = {
  isFullWidth: false,
}
