import { defineMessages } from 'react-intl'

export const NavigationBarMessages = defineMessages({
  accountLabel: {
    defaultMessage: 'Account Settings',
    id: 'GY13FFPC',
    description: 'The label for the Account Settings option.',
  },
  alertButtonLabel: {
    defaultMessage: 'Alerts',
    id: 'a3qgVyMW',
    description: 'The label for alert button',
  },
  apiLabel: {
    defaultMessage: 'API Access',
    id: 'eKH4xzr6',
    description: 'The label for the API Access option.',
  },
  avatarAlt: {
    defaultMessage: 'The avatar for your user.',
    id: 'xWMWIeG3',
    description: "The description of the avatar showing for the user's account in the navigation bar.",
  },
  hamburgerMenuButtonAriaLabel: {
    defaultMessage: 'A Hamburger icon that opens the left hand navigation menu',
    id: '39WGwGST',
    description: 'The aria label for the hamburger menu icon on the left side of the navigation bar in the header.',
  },
  leftSideNavigationAriaLabel: {
    defaultMessage: 'Main Navigation - Left Side',
    id: 'ThAgHq/l',
    description: 'The aria label for the menu items on the left side of the navigation bar in the header.',
  },
  logoutLabel: {
    defaultMessage: 'Log Out',
    id: 'egia2psS',
    description: 'The label for the log out option.',
  },
  orgLabel: {
    defaultMessage: 'Your Organizations',
    id: 'cU3GazR6',
    description: 'The label for the Organizations option.',
  },
  rightSideNavigationAriaLabel: {
    defaultMessage: 'Main Navigation - Right Side',
    id: 'orHeRm6r',
    description: 'The aria label for the menu items on the right side of the navigation bar in the header.',
  },
})
