import type { ReactNode } from 'react'
import type { ToastOptions } from 'react-toastify'
import { toast } from 'react-toastify'
import { ToastBody } from './ToastBody'
import type { ToastNotificationType } from './models'

export interface CreateToastProps {
  /**
   * The auto close functionality of the toast notification. A number provided is interpreted as the amount of time the
   * notification will show before closing in milliseconds.
   */
  autoClose?: false | number
  /** The content shown below the title of the toast notification. */
  body: string | ReactNode
  /** The callback executed when the notification disappears. */
  onClose?: () => void
  /** The title for the toast notification. */
  title: string
  /** The type of toast notification style that should be displayed. */
  type: ToastNotificationType
}

/** Creates a toast notification utilizing the `toast` method provided by react-toastify. */
export const createToastNotification = ({ autoClose, body, onClose, title, type }: CreateToastProps) => {
  const toastOptions: ToastOptions = {
    autoClose,
    closeOnClick: true,
    onClose,
    hideProgressBar: true,
    position: 'top-center',
    theme: 'colored',
  }

  switch (type) {
    case 'error':
      return toast.error(<ToastBody body={body} title={title} />, toastOptions)
    case 'info':
      return toast.info(<ToastBody body={body} title={title} />, toastOptions)
    case 'success':
      return toast.success(<ToastBody body={body} title={title} />, toastOptions)
  }
}
