import { defineMessages } from 'react-intl'

export const ReallocateConfirmationModalMessages = defineMessages({
  title: {
    defaultMessage: 'Reallocate the container',
    id: '98x7mN4Q',
    description: 'Title of the reallocate instance modal',
  },
  body: {
    defaultMessage:
      'Reallocates this instance to another node. Most destructive and slowest action. Use this when there seems to be a problem with the node itself.',
    id: 'DvBFI4N1',
    description: 'Description of the reallocate instance modal',
  },
  cancel: {
    defaultMessage: 'Cancel',
    id: 'hHNj31G9',
    description: 'Cancel button text',
  },
  delete: {
    defaultMessage: 'Reallocate',
    id: 'fpQp0G8a',
    description: 'Reallocate button text',
  },
})
