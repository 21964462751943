import type { ReactNode } from 'react'
import type { IntlShape } from 'react-intl'
import type { ToastNotificationType } from '../../models'
import { CreateContainerGroupProbeInvalidErrorContentMessages } from './messages'

/**
 * Retrieves the content needed for an error `ToastNotification` that is shown when the user encounters an error when
 * attempting to create a container group where the probe is invalid.
 *
 * @param intl The `IntlShape` object needed to format the messages.
 * @param probeType The type of probe that was invalid.
 */
export const getCreateContainerGroupProbeInvalidErrorContent = (
  intl: IntlShape,
  probeType: string,
): {
  body: ReactNode
  type: ToastNotificationType
  title: string
} => {
  return {
    body: intl.formatMessage(CreateContainerGroupProbeInvalidErrorContentMessages.body, {
      probe_type: probeType,
    }),
    type: 'error',
    title: intl.formatMessage(CreateContainerGroupProbeInvalidErrorContentMessages.title, {
      probe_type: probeType,
    }),
  }
}
