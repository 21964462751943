import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { ForgotPasswordPage } from './models'

export interface ForgotPasswordState {
  email: string
  currentPage: ForgotPasswordPage
}

const initialState: ForgotPasswordState = {
  email: '',
  currentPage: ForgotPasswordPage.ForgotYourPassword,
}

export const forgotPasswordSlice = createSlice({
  name: 'forgotPassword',
  initialState,
  reducers: {
    sendResetPasswordEmail(state, action: PayloadAction<{ email: string; resending?: boolean }>) {
      state.email = action.payload.email
    },
    setForgotPasswordPage(state, action: PayloadAction<{ page: ForgotPasswordPage }>) {
      state.currentPage = action.payload.page
    },
  },
})

export const { sendResetPasswordEmail, setForgotPasswordPage } = forgotPasswordSlice.actions
