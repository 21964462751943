import { defineMessages } from 'react-intl'

export const InferenceEndpointDetailsPlaygroundMessages = defineMessages({
  pending: {
    defaultMessage: 'Pending',
    id: 'i2+SDaQH',
    description: 'Inference endpoint job status',
  },
  running: {
    defaultMessage: 'Running',
    id: 'LxtBChqp',
    description: 'Inference endpoint job status',
  },
  succeeded: {
    defaultMessage: 'Succeeded',
    id: 'iPjMynEr',
    description: 'Inference endpoint job status',
  },
  failed: {
    defaultMessage: 'Failed',
    id: 'junnvr4/',
    description: 'Inference endpoint job status',
  },
  cancelled: {
    defaultMessage: 'Cancelled',
    id: 'ZJltk9sG',
    description: 'Inference endpoint job status',
  },
})
