import type { IntlShape } from 'react-intl'

/**
 * Takes the cost per hour value provided and formats it to a string with a minimum of `2` and maximum of `3` decimal
 * places.
 *
 * @param intl The IntlShape.
 * @param costPerHour The cost per hour.
 */
export const formatCostPerHour = (intl: IntlShape, costPerHour: number): string => {
  return intl.formatNumber(costPerHour, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 3,
  })
}

/**
 * Based on whether there are GPU classes selected, this function will return the cost per hour value, display value,
 * and if the cost is a range to be used in the Estimated Cost component.
 *
 * @param intl The IntlShape.
 * @param gpuClasses The selected GPU Classes.
 */
export const getGpuCostPerHourValues = (
  intl: IntlShape,
  gpuClasses: { class: string; costPerHour: number }[] | undefined,
): { costPerHour: number | string; displayValue: string | number; hasCostRange: boolean } | undefined => {
  if (gpuClasses && gpuClasses.length > 0) {
    let gpuCostPerHour: string | number = 0
    let gpuCostPerHourDisplayValue: string | number = 0
    if (gpuClasses.length === 1 && gpuClasses[0]) {
      const formattedCostPerHour = formatCostPerHour(intl, gpuClasses[0].costPerHour)
      gpuCostPerHour = formattedCostPerHour
      gpuCostPerHourDisplayValue = formattedCostPerHour
    } else {
      const sortedGpuClasses = gpuClasses.sort((a, b) => (a.costPerHour < b.costPerHour ? -1 : 1))
      const cheapestSelectedGpuClass = sortedGpuClasses[0]?.costPerHour
      const mostExpensiveSelectedGpuClass = sortedGpuClasses[sortedGpuClasses.length - 1]?.costPerHour

      if (cheapestSelectedGpuClass && mostExpensiveSelectedGpuClass) {
        const cheapestSelectedGpuClassFormatted = formatCostPerHour(intl, cheapestSelectedGpuClass)
        const mostExpensiveSelectedGpuClassFormatted = formatCostPerHour(intl, mostExpensiveSelectedGpuClass)
        gpuCostPerHour = mostExpensiveSelectedGpuClass || 0
        gpuCostPerHourDisplayValue = `${cheapestSelectedGpuClassFormatted} - ${mostExpensiveSelectedGpuClassFormatted}`
      }
    }

    return {
      costPerHour: gpuCostPerHour,
      displayValue: gpuCostPerHourDisplayValue,
      hasCostRange: gpuClasses.length > 1,
    }
  }

  return undefined
}
