import { defineMessages } from 'react-intl'

export const GeneralErrorDecliningTeamInvitationContentMessages = defineMessages({
  body: {
    defaultMessage:
      'We were unable to complete your request to decline the invitation to join {organization_display_name}. Try again and if this issue persists {contact_support}.',

    id: 'BofW1MbK',
    description: 'The body for the error Team Member Invite Decline notification.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'pIQEUfA+',
    description: 'The text shown for the contact support link.',
  },
  title: {
    defaultMessage: ' Unable to decline team invitation',
    id: 'h5R4BqCT',
    description: 'The title for the error Team Member Invite Decline notification.',
  },
})
