import { defineMessages } from 'react-intl'

export const JobQueueDetailsMainContentMessages = defineMessages({
  backToJobQueuesLinkText: {
    defaultMessage: 'Back to Job Queues',
    id: 'NZdJAUCG',
    description: 'The label for the link that takes the user back to the Job Queues page.',
  },
  connectedContainerGroups: {
    defaultMessage:
      'Connected to {container_group_count, plural, =1 {1 Container Group} other {{container_group_count} Container Groups}}',
    id: 'JoRNoIX4',
    description: 'The details about how many container groups are connected to the job queue.',
  },
  createdDateAndTime: {
    defaultMessage: 'Created on {date} at {time}',
    id: '2DGNLou+',
    description: 'The date the job queue was created on.',
  },
  deleteButtonLabel: {
    defaultMessage: 'Delete',
    id: 'mECxtvdq',
    description: 'The label for the delete button on the Job Queue details page.',
  },
  editButtonLabel: {
    defaultMessage: 'Edit',
    id: 'nv1X8Vdj',
    description: 'The label for the edit button on the Job Queue details page.',
  },
})
