import type { RamOption, RecipeResources } from '@saladtechnologies/openapi-cloud-portal-browser'
import { getSelectedRamOption } from './ramOptions'
import { getCPUCoreCostPerHour, minimumFractionDigits } from './retrieveContainerGroupOptions'
import { roundByDecimalPlace } from './roundByDecimalPlace'

export const getRecipeCostPerHour = (
  resources: RecipeResources,
  gpuPrice: number | undefined,
  ramOptions: RamOption[],
): number => {
  const selectedRamOption = getSelectedRamOption(ramOptions, resources.ram)
  const ramCostPerHour = selectedRamOption?.costPerHour ? selectedRamOption.costPerHour : 0
  const vCPUandMemorySum = getCPUCoreCostPerHour(resources.cpu) + ramCostPerHour + (gpuPrice ?? 0)
  return roundByDecimalPlace(vCPUandMemorySum, minimumFractionDigits + 1)
}
