import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import { Spinner } from '../../../../components/Spinner'

interface InferenceEndpointDetailsPlaygroundStatusItemProps {
  /* The icon of the status item */
  icon: string
  /* The flag indicating if the status item is completed */
  isCompleted: boolean
  /* The flag indicating if the status item is an error status */
  isErrorStatus?: boolean
  /* The flag indicating if the status item is in progress */
  isLoading: boolean
  /* The label of the status item */
  label: string
  /* The flag indicating if there is a status */
  hasStatus: boolean
}

export const InferenceEndpointDetailsPlaygroundStatusItem: FunctionComponent<
  InferenceEndpointDetailsPlaygroundStatusItemProps
> = ({ hasStatus, icon, isCompleted, isErrorStatus, isLoading, label }) => {
  return (
    <div className="flex w-full flex-col items-center">
      <div className="flex w-full items-center justify-center">
        <div className="relative">
          <div
            className={classNames('ml-1 mr-1 flex items-center justify-center rounded-full', {
              'text-neutral-40': !hasStatus,
            })}
          >
            <i className={classNames(`fa-solid ${icon}`, { 'text-neutral-40': !hasStatus })}></i>
          </div>
        </div>
        <span className={classNames('font-bold', { 'text-neutral-40': !hasStatus })}>{label}</span>
      </div>
      <div className="mt-2 flex w-full items-center justify-center">
        <div
          className={classNames(
            'size-6 rounded-full text-xs font-medium',
            isErrorStatus
              ? 'bg-red-20 text-red-80'
              : isCompleted
                ? 'bg-green-20 text-green-100'
                : isLoading
                  ? 'bg-green-20 text-green-100'
                  : 'bg-neutral-10 text-neutral-50',
            { 'text-neutral-40': !hasStatus },
          )}
        >
          <div className="flex size-full items-center justify-center">
            {isErrorStatus ? (
              <i className={classNames('fa-solid fa-x')} />
            ) : isCompleted ? (
              <i className={classNames('fa-solid fa-check')} />
            ) : isLoading ? (
              <Spinner color="darkGreen" size="sm" />
            ) : (
              <div className="size-4 rounded-full border-2 border-neutral-50" />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
