import { defineMessages } from 'react-intl'

export const RecipeMarketplaceMainContentMessages = defineMessages({
  searchPlaceholder: {
    defaultMessage: 'Find a recipe',
    id: 'tcIUkj6v',
    description: 'The placeholder for the search input.',
  },
  title: {
    defaultMessage: 'Recipe Marketplace',
    id: 'lEZMfUeB',
    description: 'The title for the recipe marketplace page.',
  },
})
