import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

interface inviteTeamMemberState {}

const initialState: inviteTeamMemberState = {}

export const inviteTeamMemberSlice = createSlice({
  name: 'inviteTeamMember',
  initialState,
  reducers: {
    getTeamMemberPageData(_state, _action: PayloadAction<{ organizationName: string }>) {},
    inviteTeamMember(_state, _action: PayloadAction<{ organizationName: string; email: string }>) {},
  },
})

export const { getTeamMemberPageData, inviteTeamMember } = inviteTeamMemberSlice.actions
