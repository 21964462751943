import type { FunctionComponent } from 'react'
import { useNavigate } from 'react-router-dom'
import { APP_PATHS } from '../../routes/models/AppPaths'
import { ResetPasswordSucceeded } from './components/ResetPasswordSucceeded'

export const ConnectedResetPasswordSucceeded: FunctionComponent = () => {
  const navigate = useNavigate()

  return <ResetPasswordSucceeded onViewLoginPage={() => navigate(APP_PATHS.LOGIN)} />
}
