import type { Recipe } from '@saladtechnologies/openapi-cloud-portal-browser'
import type { FunctionComponent } from 'react'
import { useCallback, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import { RecipeCard } from '../../../../components/recipes/RecipeCard/RecipeCard'
import { SearchWithFilters } from '../../../../components/search'
import { SearchWithFilterContentMessages } from '../../../../components/search/SearchWithFilters/messages'
import { getRecipeDetailsPagePath } from '../../../../routes/routes-utils'
import { RecipeMarketplaceMainContentMessages } from '../../messages'

interface RecipeMarketplaceMainContentProps {
  /** The name of the current organization */
  organizationName: string
  /** The name of the current project */
  projectName: string
  /** The list of recipe deployments for the current project */
  recipeList: Recipe[]
}

export const RecipeMarketplaceMainContent: FunctionComponent<RecipeMarketplaceMainContentProps> = ({
  organizationName,
  projectName,
  recipeList,
}) => {
  const intl = useIntl()

  const [query, setQuery] = useState<{ query: string; filter: { [key: string]: string | undefined } }>({
    query: '',
    filter: {},
  })

  const sortByLabel = intl.formatMessage(SearchWithFilterContentMessages.searchSortBy)
  const sortByAToZOption = intl.formatMessage(SearchWithFilterContentMessages.searchSortByAToZOption)
  const sortByZToAOption = intl.formatMessage(SearchWithFilterContentMessages.searchSortByZToAOption)

  const updateRecipeListBasedOnSort = useCallback(
    (query: string, filter: { [key: string]: string | undefined }) => {
      let recipeListOrder = [...recipeList]

      recipeListOrder = recipeList.filter((recipe) => {
        const filterByQuery = query.length > 0 ? recipe.name.toUpperCase().includes(query.toUpperCase()) : true

        return filterByQuery
      })

      const selectedSortByOption =
        filter[sortByLabel] !== undefined && filter[sortByLabel] !== sortByLabel ? filter[sortByLabel] : undefined

      switch (selectedSortByOption) {
        case sortByAToZOption:
          recipeListOrder = recipeListOrder.sort((recipeA, recipeB) => recipeA.name.localeCompare(recipeB.name))
          break

        case sortByZToAOption:
          recipeListOrder = recipeListOrder
            .sort((recipeA, recipeB) => recipeA.name.localeCompare(recipeB.name))
            .reverse()
          break
      }

      return recipeListOrder
    },
    [recipeList, sortByAToZOption, sortByLabel, sortByZToAOption],
  )

  const recipeListBasedOnSort = useMemo(() => {
    return updateRecipeListBasedOnSort(query.query, query.filter)
  }, [query, updateRecipeListBasedOnSort])

  return (
    <div className="flex flex-col">
      <div className="mb-4">
        <SearchWithFilters
          filters={[
            {
              label: sortByLabel,
              options: [sortByAToZOption, sortByZToAOption],
            },
          ]}
          isFullWidth
          onChange={({ filter, query }) => setQuery({ query, filter })}
          placeholder={intl.formatMessage(RecipeMarketplaceMainContentMessages.searchPlaceholder)}
        />
      </div>

      <div className="grid gap-4">
        {recipeListBasedOnSort.map((recipe) => (
          <RecipeCard
            recipeName={recipe.name}
            key={`${recipe.id}`}
            path={getRecipeDetailsPagePath(organizationName, projectName, recipe.name)}
          />
        ))}
      </div>
    </div>
  )
}
