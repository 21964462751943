import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import { useEffect, useRef, useState } from 'react'

export type VerticalProgressBarStepStatus = 'incomplete' | 'current' | 'completed'
export interface VerticalProgressBarStep {
  label: string
  status: VerticalProgressBarStepStatus
}
export interface VerticalProgressBarWithClickProps {
  /** Vertical Progress Bar Steps to be displayed */
  steps: VerticalProgressBarStep[]
  /** The title that is displayed at the top of the steps */
  stepsTitle: string
  /** The function to update steps */
  onUpdateSteps?: (step: VerticalProgressBarStep) => void
}

export const VerticalProgressBarWithClick: FunctionComponent<VerticalProgressBarWithClickProps> = ({
  onUpdateSteps,
  steps,
  stepsTitle,
}) => {
  const containerElementRef = useRef<HTMLDivElement | null>(null)
  const currentStepLabelElementRef = useRef<HTMLSpanElement | null>(null)
  const [progressLineHeight, setProgressLineHeight] = useState(0)
  const completedStepsAmount = steps.filter((step) => step.status === 'completed').length
  const areAllStepsCompleted = completedStepsAmount === steps.length

  useEffect(() => {
    if (containerElementRef.current && currentStepLabelElementRef.current) {
      const currentStepLabelBottomPosition =
        currentStepLabelElementRef.current.getBoundingClientRect().bottom -
        containerElementRef.current.getBoundingClientRect().top

      const isCurrentStepIsTheFirst = steps[0]?.status === 'current'

      const shiftPositionForTheFirstStep = 8
      const progressLinePosition = isCurrentStepIsTheFirst
        ? currentStepLabelBottomPosition + shiftPositionForTheFirstStep
        : currentStepLabelBottomPosition

      setProgressLineHeight(progressLinePosition)
    }
  }, [steps])

  return (
    <div ref={containerElementRef} className="relative flex h-full flex-row">
      {/* ProgressLine */}
      <div className="pr-8">
        <div className="relative size-full">
          <div className="absolute left-0 top-0 h-full w-2 rounded-full bg-neutral-30 shadow-inner" />
          <div
            style={{
              height: areAllStepsCompleted ? '100%' : `${progressLineHeight}px`,
              transition: 'height 0.5s ease-in-out',
            }}
            className="absolute left-0 top-0 w-2 rounded-full bg-gradient-to-tr from-green-70 to-green-40"
          />
        </div>
      </div>
      {/* StepLabels */}
      <div className="flex h-full flex-col justify-between">
        <span className="text-sm font-medium text-neutral-100">{stepsTitle}</span>
        {steps.map((step) => {
          const isCurrentStep = step.status === 'current'

          return (
            <span
              ref={isCurrentStep ? currentStepLabelElementRef : null}
              key={step.label}
              className={classNames('pl-4 text-xs font-medium text-blue-90', {
                'cursor-pointer underline': onUpdateSteps,
              })}
              onClick={onUpdateSteps ? () => onUpdateSteps(step) : undefined}
            >
              {step.label}
            </span>
          )
        })}
      </div>
    </div>
  )
}
