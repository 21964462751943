/**
 * Scrolls to the element with the given id.
 *
 * @param id The id.
 */
export const scrollToElement = (id: string) => {
  const element = document.getElementById(id)
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' })
  }
}
