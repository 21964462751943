import { defineMessages } from 'react-intl'

export const SplunkInputFieldsMessages = defineMessages({
  hostLabel: {
    defaultMessage: 'Host',
    id: 'PYiB+pTi',
    description: 'The label for the Splunk host input field.',
  },
  hostRequiredText: {
    defaultMessage: 'Host is required for Splunk',
    id: 'm3p0icX+',
    description: 'The required text for the Splunk host input field.',
  },
  hostMaxCharactersText: {
    defaultMessage: 'Host for Splunk can be no longer than 1000 characters',
    id: 'AX8D+UFw',
    description: 'The max characters text for the Splunk host input field.',
  },
  tokenLabel: {
    defaultMessage: 'Token',
    id: '3jzYUu33',
    description: 'The label for the Splunk token input field.',
  },
  tokenRequiredText: {
    defaultMessage: 'Token is required for Splunk',
    id: 'p03C3gxk',
    description: 'The required text for the Splunk token input field.',
  },
  tokenMaxCharactersText: {
    defaultMessage: 'Token for Splunk can be no longer than 1000 characters',
    id: 'tIMMv8HS',
    description: 'The max characters text for the Splunk token input field.',
  },
})
