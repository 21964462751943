import { defineMessages } from 'react-intl'

export const FailedToLoadRecipeDeploymentsMessages = defineMessages({
  message: {
    defaultMessage: 'Seems like we had trouble loading recipe deployments',
    id: 'jk/RT+E/',
    description: 'The message shown to the user when we were unable to load recipe deployments.',
  },
  tryAgainButtonLabel: {
    defaultMessage: 'Try Again',
    id: 'lVdmCs1p',
    description: 'The label for the try again button.',
  },
})
