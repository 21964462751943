import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import type { ContainerGroup } from '@saladtechnologies/openapi-cloud-portal-browser'

interface DuplicateContainerGroupProps {
  organizationName: string
  containerGroup: ContainerGroup
}

export const duplicateContainerGroupAdapter = createEntityAdapter<DuplicateContainerGroupProps>({
  selectId: (item) => item.organizationName,
  sortComparer: (a, b) => a.organizationName.localeCompare(b.organizationName),
})

export const duplicateContainerGroupSlice = createSlice({
  name: 'duplicateContainerGroup',
  initialState: duplicateContainerGroupAdapter.getInitialState(),
  reducers: {
    duplicateContainerGroupAdded: duplicateContainerGroupAdapter.upsertOne,
    duplicateContainerGroupRemoved: duplicateContainerGroupAdapter.removeOne,
  },
})

export const { duplicateContainerGroupAdded, duplicateContainerGroupRemoved } = duplicateContainerGroupSlice.actions
