import { defineMessages } from 'react-intl'

export const EditHealthProbeComponentMessages = defineMessages({
  failureThresholdErrorText: {
    defaultMessage: 'Must be a number between {min} and {max}, inclusive.',
    id: 'Aj9zQFEA',
    description: 'The failure threshold error text for the health probe.',
  },
  failureThresholdHelperText: {
    defaultMessage: 'Number of consecutive failures of the probe before the container is considered not ready',
    id: '7dfnSxsO',
    description: 'The failure threshold helper text for the health probe.',
  },
  failureThresholdLabel: {
    defaultMessage: 'Failure Threshold',
    id: 'eHh0cAgb',
    description: 'The failure threshold label for the health probe.',
  },
  initialDelaySecondsErrorText: {
    defaultMessage: 'Must be a number between {min} and {max}, inclusive.',
    id: 'Aj9zQFEA',
    description: 'The failure threshold error text for the health probe.',
  },
  initialDelaySecondsHelperText: {
    defaultMessage: 'Number of seconds after the container has started before probes are initiated',
    id: 'Yhbxaqre',
    description: 'The initial delay seconds helper text for the health probe.',
  },
  initialDelaySecondsLabel: {
    defaultMessage: 'Initial Delay Seconds',
    id: 'npIT6brF',
    description: 'The initial delay seconds label for the health probe.',
  },
  pathInvalidMessage: {
    defaultMessage: 'Path must start with a /',
    id: 'IJVMExm7',
    description: 'The error text shown when the path field does start with /.',
  },
  pathLabel: {
    defaultMessage: 'Path',
    id: 'XflE2Rd/',
    description: 'The path label for the health probe.',
  },
  pathHelperText: {
    defaultMessage: 'Must be a valid path',
    id: 'afElWXqd',
    description: 'The path helper text for the health probe.',
  },
  pathRequiredText: {
    defaultMessage: 'Path is a required field',
    id: 'l/qB9k96',
    description: 'The error text shown when the path field is not completed.',
  },
  periodSecondsErrorText: {
    defaultMessage: 'Must be a number between {min} and {max}, inclusive.',
    id: 'Aj9zQFEA',
    description: 'The failure threshold error text for the health probe.',
  },
  periodSecondsHelperText: {
    defaultMessage: 'How often (in seconds) to perform the probe',
    id: 'IhSiM+GQ',
    description: 'The period seconds helper text for the health probe.',
  },
  periodSecondsLabel: {
    defaultMessage: 'Period Seconds',
    id: '6PcCvgJF',
    description: 'The period seconds label for the health probe.',
  },
  portLabel: {
    defaultMessage: 'Port',
    id: 'xoUa8Wwq',
    description: 'The port label for the health probe.',
  },
  portHelperText: {
    defaultMessage: 'Must be a number between {min} to {max}',
    id: 'N9uxY2Gh',
    description: 'The port helper text for the health probe.',
  },
  serviceErrorText: {
    defaultMessage: 'This field is required and must be between 1 and {max_characters} characters.',
    id: 'FUuMt8ck',
    description: 'The service error text for the health probe.',
  },
  serviceLabel: {
    defaultMessage: 'Service',
    id: '8G/sxs8U',
    description: 'The service text for the health probe.',
  },
  successThresholdErrorText: {
    defaultMessage: 'Must be a number between {min} and {max}, inclusive.',
    id: 'Aj9zQFEA',
    description: 'The failure threshold error text for the health probe.',
  },
  successThresholdHelperText: {
    defaultMessage: 'Minimum consecutive successes for the probe to be considered successful',
    id: 'F69W+h74',
    description: 'The success threshold helper text for the health probe.',
  },
  successThresholdLabel: {
    defaultMessage: 'Success Threshold',
    id: 'gmwV9Lhp',
    description: 'The success threshold label for the health probe.',
  },
  timeoutSecondsErrorText: {
    defaultMessage: 'Must be a number between {min} and {max}, inclusive.',
    id: 'Aj9zQFEA',
    description: 'The failure threshold error text for the health probe.',
  },
  timeoutSecondsHelperText: {
    defaultMessage: 'Number of seconds after which the probe times out',
    id: 'qBlD8GAu',
    description: 'The timeout seconds helper text for the health probe.',
  },
  timeoutSecondsLabel: {
    defaultMessage: 'Timeout Seconds',
    id: 'NhMTvsuT',
    description: 'The timeout seconds label for the health probe.',
  },
})
