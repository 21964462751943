import { defineMessages } from 'react-intl'

export const InviteTeamMemberMainContentMessages = defineMessages({
  description: {
    defaultMessage: 'Invited users will receive access to all projects owned by this organization.',
    id: '5CH+r03a',
    description: 'The description about what inviting a new team members means for the organization.',
  },
  emailRequired: {
    defaultMessage: 'An email address is required.',
    id: '4PeHXlzA',
    description: 'The error message when the email address is not provided.',
  },
  emailInvalid: {
    defaultMessage: 'Must be a valid email',
    id: '0a+oaz68',
    description: 'The email is invalid.',
  },
  inviteTeamMemberButtonLabel: {
    defaultMessage: 'Invite',
    id: '4iGjFHjX',
    description: 'The button label for the the form submit button used to invite a new team member.',
  },
  inviteTeamMemberEmailFieldLabel: {
    defaultMessage: 'Team Member Email',
    id: 'EWx3aeDT',
    description: 'The label for the email address field on the Invite Team Member Page.',
  },
  inviteTeamMemberEmailFieldPlaceholder: {
    defaultMessage: 'lettuce@salad.com',
    id: 'foaAY0ku',
    description: 'The placeholder text for the email address field on the Invite Team Member Page.',
  },
  returnToTeamPageLinkText: {
    defaultMessage: 'Return to {organization_display_name} Team',
    id: 'OT99DWjS',
    description: 'The text for the link that that returns the member back to the Team Page.',
  },
  title: {
    defaultMessage: 'Invite New Team Members',
    id: 'F4/RqtG2',
    description: 'The title of for the Invite New Team Members page.',
  },
})
