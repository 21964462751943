import { defineMessages } from 'react-intl'

export const DeleteRecipeDeploymentGeneralErrorContentMessages = defineMessages({
  body: {
    defaultMessage:
      'We were unable to delete your recipe deployment. Try again and if this issue persists {contact_support}.',
    id: '3Pain3Ac',
    description:
      'The description shown in a toast notification message shown when we encounter an error on their request to delete their recipe deployment.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'xCKACF3h',
    description:
      'The text shown for the contact support link in the delete recipe deployment error toast notification.',
  },
  title: {
    defaultMessage: 'Error deleting recipe deployment',
    id: 'VRk9boB7',
    description:
      'The toast notification title shown when we encounter an error on their request to delete their recipe deployment.',
  },
})
