import type { ReactNode } from 'react'
import type { IntlShape } from 'react-intl'
import type { ToastNotificationType } from '../../models'
import { CreateOrganizationWithNonUnqiueNameContentMessages } from './messages'

/**
 * Retrieves the content needed for an error `ToastNotification` that is shown when the user attempted to create an
 * organization with a name that has already been taken.
 *
 * @param intl The `IntlShape` object needed to format the messages.
 * @param organizationName The organization name.
 */
export const getCreateOrganizationWithNonUniqueNameContent = (
  intl: IntlShape,
  organizationName: string,
): {
  body: ReactNode
  type: ToastNotificationType
  title: string
} => {
  return {
    body: intl.formatMessage(CreateOrganizationWithNonUnqiueNameContentMessages.body, {
      organization_name: organizationName,
    }),
    type: 'error',
    title: intl.formatMessage(CreateOrganizationWithNonUnqiueNameContentMessages.title),
  }
}
