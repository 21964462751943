import { defineMessages } from 'react-intl'

export const CreateProjectStepsMessages = defineMessages({
  setupProjectStep: {
    defaultMessage: 'Set Up Your Project',
    id: '36iJBljm',
    description: 'The set up your project step for creating project.',
  },
  selectProductStep: {
    defaultMessage: 'Select Product',
    id: 'jafmsowR',
    description: 'The select product step for creating project.',
  },
  configureAndDeployStep: {
    defaultMessage: 'Configure & Deploy',
    id: '8lAffCAJ',
    description: 'The configure & deploy step for creating project.',
  },
  header: {
    defaultMessage: 'SETTING UP SALAD FOR',
    id: 'khwkC4fX',
    description: 'The header for the steps the user needs to take to create project.',
  },
})
