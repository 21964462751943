import { defineMessages } from 'react-intl'

export const apiUrlCopiedToKeyboardSucceededMessages = defineMessages({
  body: {
    defaultMessage: 'API URL has been copied to your clipboard',
    id: 'cIOcaQnO',
    description:
      'The description shown in a toast notification message shown when the user successfully copied the API URL to their clipboard.',
  },
  title: {
    defaultMessage: 'API URL Copied',
    id: 's25g57t+',
    description: 'The toast notification title shown when the user successfully copied the API URL to their clipboard.',
  },
})
