import { defineMessages } from 'react-intl'

export const DeleteOrganizationSucceededContentMessages = defineMessages({
  body: {
    defaultMessage: 'Projects and deployments for {organization_name} will no longer be accessible',
    id: 'tvrIKdkj',
    description:
      'The description shown in a toast notification message shown when the user successfully deletes their organization.',
  },
  title: {
    defaultMessage: 'The organization {organization_name} has been deleted',
    id: 'TbCj+pBX',
    description: 'The toast notification title shown when the user successfully deletes their organization.',
  },
})
