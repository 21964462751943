import { defineMessages } from 'react-intl'

export const SuccessfullySentEmailToastContentMessages = defineMessages({
  body: {
    defaultMessage: 'Please check your email for next steps.',
    id: '6/UeclmY',
    description:
      'The description shown in a toast notification that guides the user on what to do after we sent them an email.',
  },
  title: {
    defaultMessage: 'Email Resent',
    id: 'aO0jKb+0',
    description: 'The toast notification title for when we successfully sent an email to the user.',
  },
})
