import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import type { ObjectSchema } from 'yup'
import { object, string } from 'yup'
import { EditOrganizationMainContentMessages } from '../../messages'
import type { EditOrganizationValues } from './EditOrganizationMainContent'

export const useEditOrganizationFormValidation = (): ObjectSchema<EditOrganizationValues> => {
  const intl = useIntl()
  const validationScheme = useMemo(
    () =>
      object({
        displayName: string()
          .required(intl.formatMessage(EditOrganizationMainContentMessages.displayNameRequired))
          .min(2, intl.formatMessage(EditOrganizationMainContentMessages.displayNameMinMaxErrorMessage))
          .max(63, intl.formatMessage(EditOrganizationMainContentMessages.displayNameMinMaxErrorMessage)),
      }),
    [intl],
  )
  return validationScheme
}
