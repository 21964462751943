import { defineMessages } from 'react-intl'

export const CreateOrganizationMainContentMessages = defineMessages({
  createOrganizationButtonLabel: {
    defaultMessage: 'Create',
    id: 'hjOf53+Z',
    description: 'The button label for the the form submit button used to create an organization.',
  },
  description: {
    defaultMessage:
      'Please type in a {permanent_name} for your organization and project. This will be used in API calls. You can change display names shown in the Portal later.',
    id: 'OD+ln1bk',
    description: 'The description about what creating an organization and project means.',
  },
  descriptionPermanentNameText: {
    defaultMessage: 'permanent name',
    id: '5Hk63+uH',
    description: 'The text for the permanent name.',
  },
  inviteCodeFieldLabel: {
    defaultMessage: 'AppSumo Code (Optional)',
    id: 'h8RK/Uc8',
    description: 'The label for the Promo code field on the Create Organization Page.',
  },
  inviteCodeFieldHelperText: {
    defaultMessage: `If you have more than one AppSumo code, you can stack them in Billing & Usage`,
    id: 'GKrB1Pxr',
    description: 'The helper text for the Promo code field on the Create Organization Page.',
  },
  inviteCodeFieldMailToLinkText: {
    defaultMessage: 'cloud@salad.com',
    id: 'sk9juoqM',
    description: 'The text for the mailto link in the Promo code field helper text.',
  },
  organizationDetailsHeader: {
    defaultMessage: 'Organization Details',
    id: 'PtnUM/ae',
    description: 'The header for the organization input fields section of the form on the Create Organization Page.',
  },
  organizationDetailsDescription: {
    defaultMessage: 'Organizations allow you to share accesss to projects with other users.',
    id: 'XLXBcVBH',
    description:
      'The description for the organization input fields section of the form on the Create Organization Page.',
  },
  organizationNameFieldLabel: {
    defaultMessage: 'Organization Slug Name',
    id: '9W1/XXRL',
    description: 'The label for the organization name field on the Create Organization Page.',
  },
  organizationNameFieldPlaceholder: {
    defaultMessage: 'your-organization',
    id: 'tej5DY6q',
    description: 'The placeholder text for the organization name field on the Create Organization Page.',
  },
  organizationNameHelperText: {
    defaultMessage:
      'Must be 2-63 lowercase letters, numbers, or hyphens. Cannot start with a number, or start or end with a hyphen',
    id: 'Nj9mWfYL',
    description: 'The helper text for filling out the organization name input field.',
  },
  projectDetailsHeader: {
    defaultMessage: 'Project Details',
    id: '67HLzsiJ',
    description: 'The header for the project input field section of the form on the Create Organization Page.',
  },
  projectDetailsDescription: {
    defaultMessage: 'Projects contain resources like containers and are shared with all members of your organization.',
    id: 'mG+LxPaM',
    description: 'The description for the project input field section of the form on the Create Organization Page.',
  },
  projectNameFieldLabel: {
    defaultMessage: 'Project Slug Name',
    id: 'Qjm0DLUl',
    description: 'The label for the project name field on the Create Organization Page.',
  },
  projectNameFieldPlaceholder: {
    defaultMessage: 'hello-world',
    id: 'yYKxVmHP',
    description: 'The placeholder text for the project name field on the Create Organization Page.',
  },
  projectNameHelperText: {
    defaultMessage:
      'Must be 2-63 lowercase letters, numbers, or hyphens. Cannot start with a number, or start or end with a hyphen',
    id: 'tOFxaoGI',
    description: 'The text shown when a user incorrectly fills out the project name input field.',
  },
  returnToOrganizationsLinkText: {
    defaultMessage: 'Return to Your Organizations',
    id: 'mkPBRaIP',
    description: 'The text for the link that that returns the user back to the Organizations Page.',
  },
  title: {
    defaultMessage: 'Create a New Organization',
    id: 'JX5WNXOt',
    description: 'The title of for the Create Organization page.',
  },
})
