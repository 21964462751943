import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { VerifyAccountResult } from './models'

export interface VerifyAccountState {
  result: VerifyAccountResult
}

const initialState: VerifyAccountState = {
  result: undefined,
}

export const verifyAccountSlice = createSlice({
  name: 'verifyAccount',
  initialState,
  reducers: {
    confirmRegistration(_state, _action: PayloadAction<{ token: string }>) {},
    setVerifyAccountResult(state, action: PayloadAction<{ result: VerifyAccountResult }>) {
      state.result = action.payload.result
    },
  },
})

export const { confirmRegistration, setVerifyAccountResult } = verifyAccountSlice.actions
