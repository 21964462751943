import { defineMessages } from 'react-intl'

export const LoadStripeComponentsErrorMessages = defineMessages({
  body: {
    defaultMessage:
      'We were not able to load payment pages. Please try again and if this issue persists please {contact_support}.',
    id: 'pBEEvRIh',
    description:
      'The description in a toast notification message shown when we encounter an error loading the payment page.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'HVqk80Rr',
    description:
      'The text shown for the contact support link in the loading the payment page error toast notification.',
  },
  title: {
    defaultMessage: 'Unable to load payment page',
    id: 'EhuHv4bP',
    description: 'The toast notification title shown when we encounter an error loading the payment page.',
  },
})
