import { defineMessages } from 'react-intl'

export const SuccessfullyReinvitedTeamMemberContentMessages = defineMessages({
  body: {
    defaultMessage: 'Another invite was sent to {user_email}',
    id: 'XcatI1w3',
    description: 'The body for the Team Member Invite Resent notification.',
  },
  title: {
    defaultMessage: 'Invite Resent',
    id: 'l4iFnR89',
    description: 'The title for the Team Member Invite Resent notification.',
  },
})
