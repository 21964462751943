import type { GPUOptions } from '../features/gpuClasses/models'
import CPUCoreOptions from './CPUCoreOptions.json'

export const minimumFractionDigits = 3

export const getCPUCoreOptions = () => {
  return CPUCoreOptions.options
}

export const getFirstCPUCoreOption = () => {
  return CPUCoreOptions.options[0]
}

export const getCPUCoreCostPerHour = (cores: number) => {
  return CPUCoreOptions.options.find((option) => option.cores === cores)?.costPerHour || 0
}

export const getGpuClassCostPerHour = (gpuClassNameOrId?: string | null, gpuClassList?: GPUOptions) => {
  return (
    gpuClassList?.find((gpuClass) => gpuClass.name === gpuClassNameOrId || gpuClass.id === gpuClassNameOrId)?.price || 0
  )
}
