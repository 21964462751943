import type { ReactNode } from 'react'
import type { IntlShape } from 'react-intl'
import { HelpScoutSupportLink } from '../../../components/block/HelpScoutSupportLink'
import type { InstanceActionType } from '../../../features/containerGroupInstances/models'
import type { ToastNotificationType } from '../../models'
import { UnableToExecuteActionToContainerGroupInstanceGeneralErrorMessages } from './messages'

/**
 * Retrieves the content needed for an error `ToastNotification` that is shown when the user encounters an error when
 * trying to get the container group instance details.
 *
 * @param intl The `IntlShape` object needed to format the messages.
 */
export const getUnableToExecuteActionToContainerGroupInstanceGeneralErrorContent = (
  intl: IntlShape,
  action: InstanceActionType,
): {
  body: ReactNode
  type: ToastNotificationType
  title: string
} => {
  return {
    body: intl.formatMessage(UnableToExecuteActionToContainerGroupInstanceGeneralErrorMessages.body, {
      action,
      contact_support: (
        <HelpScoutSupportLink
          linkedText={intl.formatMessage(
            UnableToExecuteActionToContainerGroupInstanceGeneralErrorMessages.contactSupportLinkText,
          )}
          classNames="text-red-70 underline"
        />
      ),
    }),
    type: 'error',
    title: intl.formatMessage(UnableToExecuteActionToContainerGroupInstanceGeneralErrorMessages.title, { action }),
  }
}
