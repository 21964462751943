import { defineMessages } from 'react-intl'

export const CreateProjectFormMessages = defineMessages({
  createProjectButtonLabel: {
    defaultMessage: 'Create',
    id: 'vwmh7z5G',
    description: 'The button label for the the form submit button used to create an project.',
  },
  description: {
    defaultMessage: 'Please type in a permanent name for your project. This will be used in API calls.',
    id: 'tbj96KKj',
    description: 'The description about what creating project means.',
  },
  projectDetailsHeader: {
    defaultMessage: 'Project Details',
    id: '+lGW4fO8',
    description: 'The header for the project input fields section of the form on the Create Project Page.',
  },
  projectDetailsDescription: {
    defaultMessage: 'Projects contain resources like containers and are shared with all members of your organization.',
    id: 'xhQEXT73',
    description: 'The description for the project input fields section of the form on the Create Project Page.',
  },
  projectNameFieldLabel: {
    defaultMessage: 'Project Slug Name',
    id: 'bcjd28Fj',
    description: 'The label for the project name field on the Create Project Page.',
  },
  projectNameFieldPlaceholder: {
    defaultMessage: 'your-project-name',
    id: '1EXOgTAN',
    description: 'The placeholder text for the project name field on the Create Project Page.',
  },
  projectNameHelperText: {
    defaultMessage:
      'Must be 2-63 lowercase letters, numbers, or hyphens. Cannot start with a number, or start or end with a hyphen',
    id: 'uQXPO8Ia',
    description: 'The helper text for filling out the project name input field.',
  },
  title: {
    defaultMessage: 'Set Up Your Project',
    id: '1EO7Yozx',
    description: 'The title of the Create Project page.',
  },
})
