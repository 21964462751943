import { filter, ignoreElements, tap } from 'rxjs'
import { router } from '../routes/Routes'
import type { AppEpic } from '../store'

import { createAction } from '@reduxjs/toolkit'

export const navigateTo = createAction<{ path?: string; previousPage?: boolean }>('navigation')

export const onNavigate: AppEpic = (action$, _state$) =>
  action$.pipe(
    filter(navigateTo.match),
    tap(({ payload: { path, previousPage } }) => {
      if (previousPage) {
        router.navigate(-1)
        return
      }

      if (path) {
        router.navigate(path)
      }
    }),
    ignoreElements(),
  )
