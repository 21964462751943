import type { FunctionComponent, ReactNode } from 'react'
import { useIntl } from 'react-intl'
import { TabItem } from './TabItem'
import { tabsMessages } from './TabsMessages'

export interface Tab {
  content?: ReactNode
  hasError?: boolean
  label?: ReactNode
  labelIconClassName?: string
  onSelectTab?: () => void
}
export interface TabsProps {
  /** On select tab handler */
  onSelectedTabIndexChange: (tabIndex: number) => void
  /** Selected tab index, starting 0, ... */
  selectedTabIndex?: number
  /** Tabs label and content. */
  tabs: Tab[]
}

export const Tabs: FunctionComponent<TabsProps> = ({ onSelectedTabIndexChange, selectedTabIndex, tabs }) => {
  const intl = useIntl()
  const getTabSelectHandler = (tabIndex: number) => {
    onSelectedTabIndexChange(tabIndex)
  }
  const getTabId = (tabIndex: number): string => `${intl.formatMessage(tabsMessages.tabItemLabel)}-${tabIndex}`
  const selectedTabContent = tabs[selectedTabIndex ?? 0]?.content

  return (
    <>
      {/* TabItems */}
      <div className="flex flex-row" role="tablist">
        {tabs.map(({ hasError, label, labelIconClassName, onSelectTab }, index) => {
          const isTabSelected = selectedTabIndex === index
          const tabId = getTabId(index)

          const handleOnSelectTab = () => {
            getTabSelectHandler(index)
            onSelectTab?.()
          }

          return (
            <TabItem
              id={tabId}
              key={tabId}
              // Leave ability to focus only for active tab links
              // https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/tab_role#best_practices
              tabItemIndex={isTabSelected ? 0 : -1}
              isSelected={isTabSelected}
              hasError={hasError}
              onSelectTab={handleOnSelectTab}
              iconClassName={labelIconClassName}
            >
              {label}
            </TabItem>
          )
        })}
      </div>

      {/* TabContent */}
      <div role="tabpanel" aria-labelledby={getTabId(selectedTabIndex ?? 0)}>
        {selectedTabContent}
      </div>
    </>
  )
}

Tabs.defaultProps = {
  selectedTabIndex: 0,
}
