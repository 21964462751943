import type { RamOption } from '@saladtechnologies/openapi-cloud-portal-browser'

/**
 * Returns the `Ram Option` that the user has selected. If the `ramValue` is not found in the `ramOptions` list, then a
 * new `Ram Option` is created to account for one most likely created via the Public API.
 *
 * @param ramOptions The `Ram Options` that Salad Provides users to select from.
 * @param ramValue The `Ram Option` value that the user has listed in their resources.
 */
export const getSelectedRamOption = (ramOptions: RamOption[] | undefined, ramValue: number) => {
  if (ramOptions === undefined) {
    return undefined
  }

  const ramOption = ramOptions.find((ramOption) => ramOption.value === ramValue)

  if (ramOption !== undefined) {
    return ramOption
  }

  let name = ''
  const roundedRamValue = Math.round(ramValue)
  if (ramValue < 1024) {
    name = `${roundedRamValue} MB`
  } else {
    const ramValueInGB = roundedRamValue / 1024
    const roundedRamValueInGB = Math.round(ramValueInGB)
    name = `${roundedRamValueInGB} GB`
  }
  const option: RamOption = {
    id: `${ramValue}-id`,
    enabled: true,
    name,
    value: roundedRamValue,
    isHighDemand: false,
  }
  return option
}
