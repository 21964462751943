import { defineMessages } from 'react-intl'

export const CreateJobQueueStepsMessages = defineMessages({
  createJobQueueStepLabel: {
    defaultMessage: 'Create a New Job Queue',
    id: '3SQJhUZa',
    description: 'The label for the create new job queue step.',
  },
  header: {
    defaultMessage: 'Job Queues',
    id: 'VIFXsTiB',
    description: 'The header for the steps the user needs to take to create a job queue.',
  },
})
