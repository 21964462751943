import { defineMessages } from 'react-intl'

export const LearnMoreInstanceModalMessages = defineMessages({
  closeWindowButtonLabel: {
    defaultMessage: 'Close Window',
    id: 'a8eOnc+O',
    description: 'The label for the Close Window button.',
  },
  readDocumentationButtonLabel: {
    defaultMessage: 'Read Documentation',
    id: 'JvSxmFmQ',
    description: 'The label for the Read Documentation button.',
  },
  restartActionTitle: {
    defaultMessage: 'Restart: ',
    id: 'OB+5OnD9',
    description: 'The title for the restart action.',
  },
  restartActionDescription: {
    defaultMessage:
      'Restarts the container. Least destructive and quickest action. Use this when there is a problem with the process running the image.',
    id: 'AnOIS7O3',
    description: 'The description for the restart action.',
  },
  recreateActionTitle: {
    defaultMessage: 'Recreate: ',
    id: 'fsru72ny',
    description: 'The title for the recreate action.',
  },
  recreateActionDescription: {
    defaultMessage:
      'Recreates the container from scratch. Not as fast as restart and not as destructive as reallocate. Use this when there is a problem with the image running.',
    id: 'djJpysvJ',
    description: 'The description for the recreate action.',
  },
  reallocateActionTitle: {
    defaultMessage: 'Reallocate: ',
    id: 'YZxgR981',
    description: 'The title for the reallocate action.',
  },
  reallocateActionDescription: {
    defaultMessage:
      'Reallocates this instance to another node. Most destructive and slowest action. Use this when there seems to be a problem with the node itself.',
    id: 'mCNma+rh',
    description: 'The description for the reallocate action.',
  },
  title: {
    defaultMessage: 'How Do Container Actions Work?',
    id: 'SjWWR6ro',
    description: 'The title for the modal that shows how do container actions work.',
  },
})
