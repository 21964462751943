import type { FunctionComponent } from 'react'
import { useState } from 'react'
import type { Control, FieldValues, UseFormTrigger } from 'react-hook-form'
import { Controller, useFormState, useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Button } from '../../../../components/Button'
import { Checkbox } from '../../../../components/Checkbox'
import { TextField } from '../../../../components/TextField'
import { Link } from '../../../../components/base'
import { SidePanelModal } from '../../../../components/block/SidePanelModal'
import { FormStateCard } from '../../../../components/forms/FormStateCard'
import { Select } from '../../../../components/forms/Select'
import { ContainerGatewayFieldsMessages, CreateContainerGroupMainContentMessages } from '../../messages'
import type { CreateContainerGroupFormSectionIdAttributes, CreateContainerGroupValues } from '../../models'
import { CreateContainerGroupField } from '../../models'
import { checkIfIContainerGatewayFieldsHaveErrors, containerGatewayFieldsList } from './utils'

interface ContainerGatewayFieldsProps {
  /** The control for the create container group react hook form. */
  control: Control<FieldValues, CreateContainerGroupValues>
  /** The id attribute for the fields. */
  id: CreateContainerGroupFormSectionIdAttributes
  /** The flag indicating if the Container Gateway side panel is open. */
  isContainerGatewaySidePanelOpen: boolean
  /** The function that sets the state of the isContainerGatewaySidePanelOpen state. */
  setIsContainerGatewaySidePanelOpen: (isContainerGatewaySidePanelOpen: boolean) => void
  /** The react hook form method that triggers validation for specified fields. */
  trigger: UseFormTrigger<FieldValues>
}

export const ContainerGatewayFields: FunctionComponent<ContainerGatewayFieldsProps> = ({
  control,
  id,
  isContainerGatewaySidePanelOpen,
  setIsContainerGatewaySidePanelOpen,
  trigger,
}) => {
  const intl = useIntl()
  const { errors } = useFormState({ control })
  const isContainerGatewayAuthenticationRequired = useWatch({
    control,
    name: CreateContainerGroupField.CONTAINER_GATEWAY_REQUIRES_AUTHENTICATION,
  })
  const containerGatewayEnabled = useWatch({ control, name: CreateContainerGroupField.CONTAINER_GATEWAY_ENABLED })
  const isJobQueueSelected = useWatch({ control, name: CreateContainerGroupField.JOB_QUEUE })
  const containerGatewayPort = useWatch({ control, name: CreateContainerGroupField.CONTAINER_GATEWAY_PORT })
  const containerGatewayFieldsHaveErrors = checkIfIContainerGatewayFieldsHaveErrors(errors)
  const [isContainerGatewayAuthenticated, setIsContainerGatewayAuthenticated] = useState<boolean>(
    isContainerGatewayAuthenticationRequired,
  )

  const handleValidateFieldsBeforeClose = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    await trigger(containerGatewayFieldsList).then((isValid) => {
      if (isValid) {
        setIsContainerGatewaySidePanelOpen(false)
      }
    })
  }

  return (
    <div className="mb-10" id={id}>
      <FormStateCard
        hasError={containerGatewayFieldsHaveErrors}
        onEditForm={containerGatewayEnabled ? () => setIsContainerGatewaySidePanelOpen(true) : undefined}
        isDisabled={isJobQueueSelected}
        title={intl.formatMessage(ContainerGatewayFieldsMessages.containerGatewayFieldsLabel)}
      >
        <p>
          {isJobQueueSelected ? (
            intl.formatMessage(ContainerGatewayFieldsMessages.containerGatewayDisabledDueToJobQueueLabel)
          ) : containerGatewayEnabled ? (
            <div className="font-medium">
              <p>{intl.formatMessage(ContainerGatewayFieldsMessages.enabledLabel)}</p>
              <p className="truncate">
                {intl.formatMessage(ContainerGatewayFieldsMessages.containerGatewayFieldsPortLabel)}
                {containerGatewayPort ?? 1}
              </p>
              <p className="truncate">
                {intl.formatMessage(ContainerGatewayFieldsMessages.containerGatewayFieldsAuthenticationLabel)}
                {isContainerGatewayAuthenticated
                  ? intl.formatMessage(ContainerGatewayFieldsMessages.enabledLabel)
                  : intl.formatMessage(ContainerGatewayFieldsMessages.disabledLabel)}
              </p>
            </div>
          ) : (
            <Button variant="green-filled-light" onClick={() => setIsContainerGatewaySidePanelOpen(true)}>
              {intl.formatMessage(ContainerGatewayFieldsMessages.addContainerGatewayLabel)}
            </Button>
          )}
        </p>
      </FormStateCard>

      <SidePanelModal
        CustomButton={
          <Button variant="green-filled" form="updateContainerGatewayFieldsForm" isFullWidth type="submit">
            {intl.formatMessage(CreateContainerGroupMainContentMessages.configureButtonLabel)}
          </Button>
        }
        isShown={isContainerGatewaySidePanelOpen}
        onClose={() => setIsContainerGatewaySidePanelOpen(false)}
        title={intl.formatMessage(ContainerGatewayFieldsMessages.sectionTitle)}
      >
        <form onSubmit={handleValidateFieldsBeforeClose} id="updateContainerGatewayFieldsForm">
          <div className="mt-12 px-10">
            <h2 className="mb-2 text-3xl font-bold">
              {intl.formatMessage(ContainerGatewayFieldsMessages.sectionTitle)}
            </h2>

            <p className="mb-10">
              {intl.formatMessage(ContainerGatewayFieldsMessages.description, {
                documentation_link: (
                  <Link url="https://docs.salad.com/container-engine/gateway/enabling-ipv6">
                    {intl.formatMessage(ContainerGatewayFieldsMessages.descriptionDocumentationLinkText)}
                  </Link>
                ),
              })}
            </p>

            <div className="mb-6">
              <Controller
                name={CreateContainerGroupField.CONTAINER_GATEWAY_ENABLED}
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <Checkbox
                      {...field}
                      {...fieldState}
                      isDisabled={isJobQueueSelected}
                      isChecked={field.value as boolean}
                      id="containerGateway-enabled-checkbox"
                      label={intl.formatMessage(ContainerGatewayFieldsMessages.enableContainerGatewayLabel)}
                    />
                  )
                }}
              />
            </div>

            <div className="mb-6">
              <Controller
                name={CreateContainerGroupField.CONTAINER_GATEWAY_PORT}
                control={control}
                render={({ field, fieldState }) => {
                  return (
                    <TextField
                      {...field}
                      {...fieldState}
                      defaultValue={field.value}
                      helperText={intl.formatMessage(ContainerGatewayFieldsMessages.portHelperText)}
                      label={intl.formatMessage(ContainerGatewayFieldsMessages.portLabel)}
                      isDisabled={!containerGatewayEnabled}
                      isFullWidth
                      min={1}
                      max={65535}
                      type="number"
                    />
                  )
                }}
              />
            </div>

            <Controller
              name={CreateContainerGroupField.CONTAINER_GATEWAY_REQUIRES_AUTHENTICATION}
              control={control}
              defaultValue={isContainerGatewayAuthenticated}
              render={({ field, fieldState }) => {
                return (
                  <Select
                    {...field}
                    {...fieldState}
                    defaultSelectedValue={field.value}
                    helperText={intl.formatMessage(
                      ContainerGatewayFieldsMessages.containerGatewayAuthenticationHelperText,
                    )}
                    labelText={intl.formatMessage(ContainerGatewayFieldsMessages.containerGatewayAuthenticationLabel)}
                    isDisabled={!containerGatewayEnabled}
                    isFullWidth
                    isPlaceholderOptionDisabled
                    onChange={(value) => {
                      const booleanValue = value === 'true'
                      field.onChange(booleanValue)
                      // This is needed to trigger the UI updating for the protocol field
                      setIsContainerGatewayAuthenticated(booleanValue)
                    }}
                    options={[
                      {
                        label: intl.formatMessage(ContainerGatewayFieldsMessages.yesLabel),
                        value: true,
                      },
                      {
                        label: intl.formatMessage(ContainerGatewayFieldsMessages.noLabel),
                        value: false,
                      },
                    ]}
                  />
                )
              }}
            />
          </div>
        </form>
      </SidePanelModal>
    </div>
  )
}
