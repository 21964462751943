import classNames from 'classnames'
import type { FunctionComponent } from 'react'

interface ValuePropositionItemProps {
  iconClassName: string
  title: string
  description: string
}

export const ValuePropositionItem: FunctionComponent<ValuePropositionItemProps> = ({
  description,
  iconClassName,
  title,
}) => (
  <li className="flex gap-8">
    <div>
      <div className="flex size-12 items-center justify-center rounded bg-green-10">
        <i className={classNames(iconClassName, 'fa-xl', 'text-green-70')}></i>
      </div>
    </div>
    <div>
      <h2 className="mb-2 font-bold text-neutral-10">{title}</h2>
      <p className="w-80 text-neutral-10 md:w-full">{description}</p>
    </div>
  </li>
)
