import { defineMessages } from 'react-intl'

export const GeneralErrorRevokingTeamMemberInvitationContentMessages = defineMessages({
  body: {
    defaultMessage:
      'We were unable to complete your request to revoke the invitation to {user_email}. Try again and if this issue persists {contact_support}.',

    id: 'W3Zp8I3Y',
    description: 'The body for the error Team Member Invite Revoked notification.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'pIQEUfA+',
    description: 'The text shown for the contact support link.',
  },
  title: {
    defaultMessage: ' Unable to revoke invitation',
    id: 'nzOK8vJ6',
    description: 'The title for the error Team Member Invite Revoked notification.',
  },
})
