import type { GpuClassesList } from '@saladtechnologies/openapi-cloud-portal-browser'

/**
 * Used to configure the GPU Classes response to be used in our GPU Classes redux store.
 *
 * @param organizationName The current organization name
 * @param gpuClassesResponse The response body that comes from the OrganizationDataAPI.listGpuClasses api call
 */
export const configureGpuClassesResponse = (
  organizationName: string,
  gpuClassesResponse: GpuClassesList | undefined,
) => {
  return {
    organizationName,
    gpuClasses: gpuClassesResponse
      ? gpuClassesResponse.items.map((gpuClass) => {
          return {
            id: gpuClass.id,
            name: gpuClass.name,
            price: parseFloat(gpuClass.price),
            isHighDemand: gpuClass.isHighDemand,
          }
        })
      : undefined,
  }
}
