import { catchError, concat, delay, filter, forkJoin, of, switchMap } from 'rxjs'
import { ContainerGroupsAPI, OrganizationsAPI, ProjectsAPI, QueuesAPI } from '../apiMethods'
import { containerGroupsAddedToOrganization } from '../features/containerGroups/containerGroupsSlice'
import { getJobQueueDetailsPageData, jobQueueAdded } from '../features/jobQueues/jobQueuesSlice'
import { showToastNotification } from '../features/notifications/notificationsSlice'
import { organizationAdded } from '../features/organizations/organizationsSlice'
import { projectsAddedToOrganization } from '../features/projects/projectsSlice'
import { setRequestStatus } from '../features/requestStatus/requestStatusSlice'
import { getJobQueueDetailsPageDataErrorContent } from '../notifications/clientToastNotificationContent/jobQueues'
import { getJobQueuesPagePath } from '../routes/routes-utils'
import type { AppEpic } from '../store'
import { navigateTo } from './navigationEpic'

export const onGetJobQueueDetailsPageData: AppEpic = (action$, _state$, { intl }) => {
  return action$.pipe(
    filter(getJobQueueDetailsPageData.match),
    switchMap(({ payload: { jobQueueName, organizationName, projectName } }) =>
      concat(
        of(setRequestStatus({ request: 'getJobQueueDetailsPageData', status: 'pending' })),
        forkJoin([
          QueuesAPI.getQueue({
            organizationName,
            projectName,
            queueName: jobQueueName,
          }),
          OrganizationsAPI.getOrganization({
            organizationName,
          }),
          ProjectsAPI.listProjects({
            organizationName,
          }),
          ContainerGroupsAPI.listContainerGroups({
            organizationName,
            projectName,
          }),
        ]).pipe(
          switchMap(([jobQueueResponse, organizationResponse, projectsResponse, containerGroupsResponse]) => {
            return concat(
              of(
                jobQueueAdded({
                  organizationName,
                  projectName,
                  jobQueue: jobQueueResponse,
                }),
                organizationAdded(organizationResponse),
                projectsAddedToOrganization({
                  organizationName: organizationResponse.name,
                  projects: projectsResponse.items,
                }),
                containerGroupsAddedToOrganization({
                  organizationName,
                  projectName,
                  containerGroups: containerGroupsResponse.items,
                }),
                setRequestStatus({ request: 'getJobQueueDetailsPageData', status: 'succeeded' }),
              ),
              of(setRequestStatus({ request: 'getJobQueueDetailsPageData', status: 'idle' })).pipe(delay(1)),
            )
          }),
          catchError(() =>
            concat(
              of(
                setRequestStatus({ request: 'getJobQueueDetailsPageData', status: 'failed' }),
                showToastNotification(getJobQueueDetailsPageDataErrorContent(intl)),
                navigateTo({ path: getJobQueuesPagePath(organizationName, projectName) }),
              ),
              of(setRequestStatus({ request: 'getJobQueueDetailsPageData', status: 'idle' })).pipe(delay(1)),
            ),
          ),
        ),
      ),
    ),
  )
}
