import { defineMessages } from 'react-intl'

export const CreateJobQueueFormMessages = defineMessages({
  backLinkText: {
    defaultMessage: 'Back to Job Queues',
    id: 'tXiBFsNA',
    description: 'The link text for the the link used to navigate to the job queues page.',
  },
  createJobQueueButtonLabel: {
    defaultMessage: 'Create',
    id: 'YwNwW6YZ',
    description: 'The button label for the the form submit button used to create a job queue.',
  },
  descriptionFieldLabel: {
    defaultMessage: 'Description',
    id: 'Zf5fAE1U',
    description: 'The label for the description field on the Create Job Queue Page.',
  },
  descriptionFieldPlaceholder: {
    defaultMessage: 'Enter an optional description for your job queue',
    id: 'M94LnKKv',
    description: 'The placeholder text for the description field on the Create Job Queue Page.',
  },
  descriptionHelperText: {
    defaultMessage: 'Optional',
    id: '8U3xqxe2',
    description: 'The helper text for filling out the description input field.',
  },
  descriptionMaxCharacterErrorMessage: {
    defaultMessage: 'Description cannot exceed 500 characters',
    id: 'ft5zRgZK',
    description: 'The error message for when the description exceeds 500 characters.',
  },
  nameFieldLabel: {
    defaultMessage: 'Name',
    id: '+7CkAGZm',
    description: 'The label for the name field on the Create Job Queue Page.',
  },
  nameFieldPlaceholder: {
    defaultMessage: 'Enter a name for your job queue',
    id: 'sYvO9CgH',
    description: 'The placeholder text for the name field on the Create Job Queue Page.',
  },
  nameHelperText: {
    defaultMessage:
      'Must be 2-63 lowercase letters, numbers, or hyphens. Cannot start with a number, or start or end with a hyphen',
    id: '2Cvrny6/',
    description: 'The helper text for filling out the  name input field.',
  },
  serviceLimitationsHeader: {
    defaultMessage: 'Service Limitations',
    id: 'qoYC8pa6',
    description: 'The header for the service limitations section of the form on the Create Job Queue Page.',
  },
  serviceLimitationNote1: {
    defaultMessage: 'Request and response sizes are limited to 10MB',
    id: 'dSoIIS69',
    description: 'The first service limitations note for creating a job queue.',
  },
  serviceLimitationNote2: {
    defaultMessage: 'Jobs will only be saved for 24 hrs after reaching a terminal state (completed or failed)',
    id: 'x6nW+7xV',
    description: 'The second service limitations note for creating a job queue.',
  },
  title: {
    defaultMessage: 'Create A New Job Queue',
    id: 'oicZW/wL',
    description: 'The title of the Create Job Queue page.',
  },
})
