import type { ContainerGroup, ContainerGroupLogsQuery } from '@saladtechnologies/openapi-cloud-portal-browser'
import type { FunctionComponent } from 'react'
import { useCallback, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { LoadingPage } from '../../components/page/LoadingPage'
import {
  trackMixpanelElementClickedEvent,
  trackMixpanelEvent,
  trackMixpanelPageViewedEvent,
} from '../../features/analytics/analyticsSlice'
import {
  selectContainerGroup,
  selectDefaultContainerGroupLogsFormValues,
  selectDefaultTabIndex,
} from '../../features/containerGroupDetail/containerGroupDetailSelectors'
import {
  deleteContainerGroup,
  getContainerGroupAndInstances,
  getContainerGroupDetailsPageData,
  setDefaultContainerGroupFormValues,
  startContainerGroup,
  stopContainerGroup,
  stopPollingForContainerGroup,
} from '../../features/containerGroupDetail/containerGroupDetailSlice'
import { GetContainerGroupRequestType } from '../../features/containerGroupDetail/models'
import { selectContainerGroupInstances } from '../../features/containerGroupInstanceTable/containerGroupInstanceTableSelectors'
import { getUpToDateInstances } from '../../features/containerGroupInstanceTable/containerGroupInstanceTableSlice'
import {
  reallocateContainerGroupInstance,
  recreateContainerGroupInstance,
  restartContainerGroupInstance,
} from '../../features/containerGroupInstances/containerGroupInstancesSlice'
import { selectContainerGroupLogs } from '../../features/containerGroupLogs/containerGroupLogsSelectors'
import { getContainerGroupLogs } from '../../features/containerGroupLogs/containerGroupLogsSlice'
import { configuresContainerGroupLogsEntityId } from '../../features/containerGroupLogs/utils'
import { selectContainerGroupWorkloadErrors } from '../../features/containerGroupWorkloadErrors/containerGroupWorkloadErrorsSelectors'
import { configuresContainerGroupWorkloadsErrorsEntityId } from '../../features/containerGroupWorkloadErrors/utils'
import {
  selectIsBillingInformationMissingModalShowing,
  selectIsContainerGroupDeploymentQuotaExceededModalShowing,
} from '../../features/createContainerGroup/createContainerGroupSelectors'
import { setRequiredActionModalShowingState } from '../../features/createContainerGroup/createContainerGroupSlice'
import { RequiredActionModal } from '../../features/createContainerGroup/models'
import { duplicateContainerGroupAdded } from '../../features/duplicateContainerGroup/duplicateContainerGroupSlice'
import { selectGpuClassesById } from '../../features/gpuClasses/gpuClassesSelectors'
import { selectIsLeftColumnShowing } from '../../features/navigationBar/navigationBarSelectors'
import { setLeftColumnShowing } from '../../features/navigationBar/navigationBarSlice'
import { selectOrganizationDisplayName } from '../../features/organizations/organizationsSelectors'
import { selectProfileEmail } from '../../features/profile/profileSelectors'
import { selectProjectDisplayName, selectProjects } from '../../features/projects/projectsSelectors'
import { deleteProject } from '../../features/projects/projectsSlice'
import { selectRamOptions } from '../../features/ramOptions/ramOptionsSelectors'
import { selectRequestStatus, selectRequestStatusIsPending } from '../../features/requestStatus/requestStatusSelectors'
import { selectStorageOptions } from '../../features/storageOptions/storageOptionsSelectors'
import { containerGroupDetailsPageRoutePath } from '../../routes/routePaths'
import {
  getContainerGroupDetailsPagePath,
  getContainerGroupsPagePath,
  getCreateContainerGroupPagePath,
  getEditContainerGroupPagePath,
  getOrganizationBillingPagePath,
} from '../../routes/routes-utils'
import { useAppDispatch, useAppSelector } from '../../store'
import { getRequestContainerGroupQuotaIncreaseLink } from '../../utils'
import { ContainerGroupDetailsPage } from './ContainerGroupDetailsPage'
import { ContainerGroupLogsFormValues } from './models'

export const ConnectedContainerGroupDetailsPage: FunctionComponent = () => {
  const { containerGroupName = '', organizationName = '', projectName = '' } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const containerGroup = useAppSelector(selectContainerGroup)
  const email = useAppSelector(selectProfileEmail)
  const jobQueueName = containerGroup?.queueConnection?.queueName

  const containerGroupDisplayName = containerGroup?.displayName ?? containerGroupName
  const containerGroupStatus = containerGroup?.currentState.status
  const gpuClassIds = containerGroup?.container.resources.gpuClasses || undefined
  const gpuClasses = useAppSelector((state) => selectGpuClassesById(state, organizationName, gpuClassIds))
  const ramOptions = useAppSelector((state) => selectRamOptions(state.ramOptions))
  const storageOptions = useAppSelector((state) => selectStorageOptions(state.storageOptions))
  const containerGroupInstances = useAppSelector(selectContainerGroupInstances)
  const currentProjectDisplayName = useAppSelector((state) =>
    selectProjectDisplayName(state, organizationName, projectName),
  )
  const workloadErrors = useAppSelector((state) =>
    selectContainerGroupWorkloadErrors(
      state.workLoadErrors,
      configuresContainerGroupWorkloadsErrorsEntityId(containerGroupName, organizationName, projectName),
    ),
  )
  const containerGroupLogs = useAppSelector((state) =>
    selectContainerGroupLogs(
      state.containerGroupLogs,
      configuresContainerGroupLogsEntityId(containerGroupName, organizationName, projectName),
    ),
  )
  const defaultContainerGroupLogsFormValues = useAppSelector(selectDefaultContainerGroupLogsFormValues)
  const defaultTabIndex = useAppSelector(selectDefaultTabIndex)
  const deleteProjectRequestStatus = useAppSelector((state) => selectRequestStatus(state, 'deleteProject'))
  const getContainerGroupLogsRequestStatus = useAppSelector((state) =>
    selectRequestStatus(state, 'getContainerGroupLogs'),
  )
  const isLeftColumnOpen = useAppSelector(selectIsLeftColumnShowing)
  const isBillingInformationMissingModalShowing = useAppSelector(selectIsBillingInformationMissingModalShowing)
  const isContainerGroupDeploymentQuotaExceededModalShowing = useAppSelector(
    selectIsContainerGroupDeploymentQuotaExceededModalShowing,
  )
  const linkToRequestIncreaseForContainerGroupQuotas = getRequestContainerGroupQuotaIncreaseLink(
    email as string,
    organizationName,
  )
  const isGetContainerGroupAndInstancesRequestPending = useAppSelector((state) =>
    selectRequestStatusIsPending(state, 'getContainerGroupAndInstances'),
  )
  const isDeleteContainerGroupRequestPending = useAppSelector((state) =>
    selectRequestStatusIsPending(state, 'deleteContainerGroup'),
  )
  const isStartContainerGroupRequestPending = useAppSelector((state) =>
    selectRequestStatusIsPending(state, 'startContainerGroup'),
  )
  const isStopContainerGroupRequestPending = useAppSelector((state) =>
    selectRequestStatusIsPending(state, 'stopContainerGroup'),
  )
  const organizationDisplayName = useAppSelector((state) => selectOrganizationDisplayName(state, organizationName))
  const projects = useAppSelector((state) => selectProjects(state, organizationName))?.projects || []

  const projectSelectOptions = projects.map((project) => {
    return {
      ...project,
      selected: project.name === projectName,
    }
  })

  const handleProjectChange = useCallback(
    (projectName: string) => {
      navigate(getContainerGroupsPagePath(organizationName, projectName))
    },
    [navigate, organizationName],
  )

  const handleCloseLeftNavBar = useCallback(() => {
    dispatch(setLeftColumnShowing({ showing: false }))
  }, [dispatch])

  const handleGetContainerGroupDetailsPageData = useCallback(() => {
    dispatch(
      getContainerGroupDetailsPageData({
        containerGroupName,
        organizationName,
        projectName,
        requestType: GetContainerGroupRequestType.LOAD,
      }),
    )
  }, [dispatch, containerGroupName, organizationName, projectName])

  const handleDeleteProject = useCallback(
    (projectName: string) => {
      dispatch(deleteProject({ organizationName, projectName }))
    },
    [dispatch, organizationName],
  )

  const handleDuplicationContainerGroup = useCallback(() => {
    dispatch(duplicateContainerGroupAdded({ organizationName, containerGroup: containerGroup as ContainerGroup }))
  }, [dispatch, containerGroup, organizationName])

  const handleStartContainerGroup = useCallback(() => {
    dispatch(
      startContainerGroup({
        containerGroupName,
        organizationName,
        projectName,
      }),
    )
  }, [dispatch, containerGroupName, organizationName, projectName])

  const handleStopContainerGroup = useCallback(() => {
    dispatch(
      stopContainerGroup({
        containerGroupName,
        organizationName,
        projectName,
      }),
    )
  }, [dispatch, containerGroupName, organizationName, projectName])

  const handleDeleteContainerGroup = useCallback(() => {
    dispatch(
      deleteContainerGroup({
        containerGroupName,
        containerGroupDisplayName,
        organizationName,
        projectName,
        jobQueueName,
      }),
    )
  }, [dispatch, containerGroupName, containerGroupDisplayName, organizationName, projectName, jobQueueName])

  const handleSetModalShowingState = useCallback(
    (modal: RequiredActionModal, showing: boolean) => {
      dispatch(setRequiredActionModalShowingState({ requiredAction: modal, showing: showing }))
    },
    [dispatch],
  )

  const onReallocateContainerGroupInstance = useCallback(
    (instanceId: string) => {
      dispatch(
        reallocateContainerGroupInstance({
          organizationName: organizationName,
          projectName: projectName,
          containerGroupName: containerGroupName,
          instanceId,
        }),
      )
    },
    [containerGroupName, dispatch, organizationName, projectName],
  )

  const onRecreateContainerGroupInstance = useCallback(
    (instanceId: string) => {
      dispatch(
        recreateContainerGroupInstance({
          organizationName: organizationName,
          projectName: projectName,
          containerGroupName: containerGroupName,
          instanceId,
        }),
      )
    },
    [containerGroupName, dispatch, organizationName, projectName],
  )

  const onRestartContainerGroupInstance = useCallback(
    (instanceId: string) => {
      dispatch(
        restartContainerGroupInstance({
          organizationName: organizationName,
          projectName: projectName,
          containerGroupName: containerGroupName,
          instanceId,
        }),
      )
    },
    [containerGroupName, dispatch, organizationName, projectName],
  )

  const handleGetContainerGroupAndInstances = useCallback(() => {
    dispatch(
      getContainerGroupAndInstances({
        containerGroupName,
        organizationName,
        projectName,
      }),
    )
  }, [dispatch, containerGroupName, organizationName, projectName])

  const handleSetDefaultContainerLogsFormValues = useCallback(
    (containerGroupLogsFormValues: Partial<ContainerGroupLogsFormValues>) => {
      dispatch(setDefaultContainerGroupFormValues({ formValues: containerGroupLogsFormValues }))
    },
    [dispatch],
  )

  const handleGetContainerGroupLogs = useCallback(
    (containerGroupLogsQuery: ContainerGroupLogsQuery) => {
      dispatch(
        getContainerGroupLogs({
          containerGroupName,
          organizationName,
          projectName,
          containerGroupLogsQuery,
        }),
      )

      dispatch(
        trackMixpanelElementClickedEvent({
          label: 'Container Group Logs Query',
          path: containerGroupDetailsPageRoutePath,
          organizationName,
          projectName,
          resourceName: containerGroupName,
          containerGroupLogsQuery,
        }),
      )
    },
    [dispatch, containerGroupName, organizationName, projectName],
  )

  const handleMixpanelElementEvent = useCallback(
    (label: string, machineId?: string) => {
      dispatch(
        trackMixpanelEvent({
          event: 'Event', // TODO: Change this to a more descriptive event name
          properties: {
            label,
            path: containerGroupDetailsPageRoutePath,
            organizationName,
            projectName,
            resourceName: containerGroupName,
            machineId,
          },
        }),
      )
    },
    [containerGroupName, dispatch, organizationName, projectName],
  )

  useEffect(() => {
    handleGetContainerGroupDetailsPageData()
    dispatch(
      trackMixpanelPageViewedEvent({
        path: containerGroupDetailsPageRoutePath,
        organizationName,
        projectName,
        resourceName: containerGroupName,
      }),
    )
  }, [containerGroupName, dispatch, handleGetContainerGroupDetailsPageData, organizationName, projectName])

  useEffect(() => {
    if (containerGroupStatus === 'running') {
      const interval = setInterval(() => {
        dispatch(getUpToDateInstances())
      }, 1000)

      return () => clearInterval(interval)
    }

    return () => {}
  }, [containerGroupStatus, dispatch])

  useEffect(() => {
    return () => {
      dispatch(stopPollingForContainerGroup())
      dispatch(setDefaultContainerGroupFormValues({ formValues: undefined, tabValue: 0 }))
    }
  }, [dispatch])

  return containerGroup ? (
    <ContainerGroupDetailsPage
      containerGroup={containerGroup}
      containerGroupDetailsPagePath={getContainerGroupDetailsPagePath(
        organizationName,
        projectName,
        containerGroupName,
      )}
      containerGroupInstances={containerGroupInstances}
      containerGroupLogs={containerGroupLogs}
      containerGroupsPagePath={getContainerGroupsPagePath(organizationName, projectName)}
      createContainerGroupPagePath={getCreateContainerGroupPagePath(organizationName, projectName)}
      currentOrganization={{ name: organizationName, displayName: organizationDisplayName ?? organizationName }}
      currentProject={{ name: projectName, displayName: currentProjectDisplayName ?? projectName }}
      defaultContainerGroupLogsFormValues={defaultContainerGroupLogsFormValues}
      defaultTabIndex={defaultTabIndex}
      editContainerGroupsPagePath={getEditContainerGroupPagePath(organizationName, projectName, containerGroupName)}
      gpuClasses={gpuClasses}
      isBillingInformationMissingModalShowing={isBillingInformationMissingModalShowing}
      isContainerGroupDeploymentQuotaExceededModalShowing={isContainerGroupDeploymentQuotaExceededModalShowing}
      isDeleteProjectPending={deleteProjectRequestStatus === 'pending'}
      isDeleteProjectSuccessful={deleteProjectRequestStatus === 'succeeded'}
      isDeletingContainerGroupRequestPending={isDeleteContainerGroupRequestPending}
      isGetContainerGroupLogsPending={getContainerGroupLogsRequestStatus === 'pending'}
      isLeftColumnOpen={isLeftColumnOpen}
      isRefreshButtonLoading={isGetContainerGroupAndInstancesRequestPending}
      isStartContainerGroupRequestPending={isStartContainerGroupRequestPending}
      isStopContainerGroupRequestPending={isStopContainerGroupRequestPending}
      linkToRequestIncreaseForContainerGroupQuotas={linkToRequestIncreaseForContainerGroupQuotas}
      onCloseLeftDrawer={handleCloseLeftNavBar}
      onDeleteContainerGroup={handleDeleteContainerGroup}
      onDeleteProject={handleDeleteProject}
      onDuplicateContainerGroup={handleDuplicationContainerGroup}
      onEnterBillingInformation={() => navigate(getOrganizationBillingPagePath(organizationName))}
      onProjectChange={handleProjectChange}
      onQueryContainerGroupLogs={handleGetContainerGroupLogs}
      onReallocateContainerGroupInstance={onReallocateContainerGroupInstance}
      onRecreateContainerGroupInstance={onRecreateContainerGroupInstance}
      onRefreshContainerGroup={handleGetContainerGroupAndInstances}
      onRestartContainerGroupInstance={onRestartContainerGroupInstance}
      onSetBillingInformationMissingModalShowingState={(showing) => handleSetModalShowingState('billing', showing)}
      onSetContainerGroupDeploymentsQuotaExceededModalShowingState={(showing) =>
        handleSetModalShowingState('deploymentsQuota', showing)
      }
      onSetDefaultContainerLogsFormValues={handleSetDefaultContainerLogsFormValues}
      onStartContainerGroup={handleStartContainerGroup}
      onStopContainerGroup={handleStopContainerGroup}
      onTrackMixpanelEvent={handleMixpanelElementEvent}
      projects={projectSelectOptions}
      ramOptions={ramOptions}
      storageOptions={storageOptions}
      workloadErrors={workloadErrors}
    />
  ) : (
    <LoadingPage />
  )
}
