import { yupResolver } from '@hookform/resolvers/yup'
import type { FunctionComponent } from 'react'
import type { FieldValues } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Button } from '../../../components/Button'
import { TextField } from '../../../components/TextField'
import { useFixedForm } from '../../../hooks'
import { VerificationLinkExpiredMessages } from '../messages'
import { useVerificationLinkExpiredFormValidation } from './useVerificationLinkExpiredFormValidation'

export interface VerificationLinkExpiredFormValues {
  /** The email address. */
  email: string
}

export interface VerificationLinkExpiredProps {
  /** The flag indicating the form is submitting. */
  isResendEmailVerificationLinkPending: boolean
  /** The submit handler. */
  onResendEmailVerificationLink: (values: FieldValues) => void
}

export const VerificationLinkExpired: FunctionComponent<VerificationLinkExpiredProps> = ({
  isResendEmailVerificationLinkPending,
  onResendEmailVerificationLink,
}) => {
  const intl = useIntl()
  const verificationFormValidationScheme = useVerificationLinkExpiredFormValidation()

  const { control, handleSubmit } = useFixedForm({
    resolver: yupResolver(verificationFormValidationScheme),
    onSubmit: onResendEmailVerificationLink,
  })

  return (
    <form className="flex flex-col" onSubmit={handleSubmit}>
      <h1 className="pb-8 text-2xl font-bold">{intl.formatMessage(VerificationLinkExpiredMessages.title)}</h1>
      <p className="pb-8 text-base">{intl.formatMessage(VerificationLinkExpiredMessages.description)}</p>
      <Controller
        name="email"
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            {...fieldState}
            helperText={fieldState.error?.message}
            placeholder={intl.formatMessage(VerificationLinkExpiredMessages.emailPlaceholder)}
            leftIconClassName="fa-solid fa-envelope"
            label={intl.formatMessage(VerificationLinkExpiredMessages.emailLabel)}
            isFullWidth
          />
        )}
      />
      <div className="flex flex-row justify-start gap-3">
        <Button
          isFullWidth
          isDisabled={isResendEmailVerificationLinkPending}
          isLoading={isResendEmailVerificationLinkPending}
          type="submit"
        >
          {intl.formatMessage(VerificationLinkExpiredMessages.resendEmailButtonLabel)}
        </Button>
        <a
          className="flex w-full items-center justify-center rounded-sm border border-green-90 bg-transparent p-2 px-3 text-green-90"
          href="mailto:cloud@salad.com?subject=SaladCloud Support - Account Verification Expired"
        >
          {intl.formatMessage(VerificationLinkExpiredMessages.contactSupportLabel)}
        </a>
      </div>
    </form>
  )
}
