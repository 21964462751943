import { defineMessages } from 'react-intl'

export const CreateRecipeDeploymentMainContentMessages = defineMessages({
  backButtonLabel: {
    defaultMessage: 'Back to {recipe_name}',
    id: 'HzEhQcJf',
    description: 'The back button label that navigates users to the recipe marketplace',
  },
  changeRecipeLabel: {
    defaultMessage: 'Change Recipe',
    id: 'Zr79DfzF',
    description: 'The change recipe label that navigates users to the recipe marketplace',
  },
  contactUs: {
    defaultMessage: 'contact us',
    id: '7lfledC5',
    description: 'The contact us text for the main content of the create recipe deployment page.',
  },
  deployButtonLabel: {
    defaultMessage: 'Deploy',
    id: 'PEqvJGcz',
    description: 'The deploy button label for the main content of the create recipe deployment page.',
  },
  deploymentNameInputLabel: {
    defaultMessage: 'Deployment Name',
    id: 'V4AqG+lU',
    description: 'The deployment name input label for the main content of the create recipe deployment page.',
  },
  deploymentNameInputHelperText: {
    defaultMessage:
      'Must be 2-63 lowercase letters, numbers, or hyphens. Cannot start with a number, or start or end with a hyphen',
    id: 'IKySrWAx',
    description: 'The deployment name input helper text for the main content of the create recipe deployment page.',
  },
  deploymentNameInputPlaceholderText: {
    defaultMessage: 'your-recipe',
    id: 'A497z4Rn',
    description:
      'The deployment name input placeholder text for the main content of the create recipe deployment page.',
  },
  description: {
    defaultMessage: `Please type in a {permanent_name} for your deployment. This will be used in API calls. You can change display names shown in the Portal later.`,
    id: 'N8v0CReU',
    description: 'The description for the main content of the create recipe deployment page.',
  },
  permanentName: {
    defaultMessage: 'permanent name',
    id: 'fr+lS1vz',
    description:
      'The permanent name text in the description for the main content of the create recipe deployment page.',
  },
  replicaCountInputLabel: {
    defaultMessage: 'Replica Count',
    id: 'rXB2q9Tm',
    description: 'The replica count input label for the main content of the create recipe deployment page.',
  },
  replicaCountInputHelperText: {
    defaultMessage:
      'Deploy with 2+ replicas to ensure the uptime of your deployment. Need to increase your replica count limit? please {contact_us}',
    id: 'EWrQw2KV',
    description: 'The replica count input helper text for the main content of the create recipe deployment page.',
  },
  selectedRecipeLabel: {
    defaultMessage: 'Selected Recipe',
    id: 'za/3xyel',
    description: 'The selected recipe label for the main content of the create recipe deployment page.',
  },
  title: {
    defaultMessage: 'Configure & Deploy a Recipe',
    id: '+u1XiHia',
    description: 'The title for the main content of the create recipe deployment page.',
  },
})
