import type { PayloadAction } from '@reduxjs/toolkit'
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { unsubscribeFromBilling } from '../billing/billingSlice'

interface BillingDashboardsProps {
  creditsEmbedUrl?: string
  hasSeenInformationNotReadyErrorNotification?: boolean
  invoiceEmbedUrl?: string
  organizationName: string
  usageEmbedUrl?: string
}

export const billingDashboardsAdapter = createEntityAdapter<BillingDashboardsProps>({
  selectId: (item) => item.organizationName,
  sortComparer: (a, b) => a.organizationName.localeCompare(b.organizationName),
})

export const billingDashboardsSlice = createSlice({
  name: 'billingDashboards',
  initialState: billingDashboardsAdapter.getInitialState(),
  reducers: {
    billingDashboardsEmbedUrlAdded: (state, action: PayloadAction<BillingDashboardsProps>) => {
      const { creditsEmbedUrl, invoiceEmbedUrl, organizationName, usageEmbedUrl } = action.payload
      const existingBillingDashboard = state.entities[organizationName]
      if (existingBillingDashboard) {
        existingBillingDashboard.creditsEmbedUrl = existingBillingDashboard.creditsEmbedUrl ?? creditsEmbedUrl
        existingBillingDashboard.invoiceEmbedUrl = existingBillingDashboard.invoiceEmbedUrl ?? invoiceEmbedUrl
        existingBillingDashboard.usageEmbedUrl = existingBillingDashboard.usageEmbedUrl ?? usageEmbedUrl
      } else {
        billingDashboardsAdapter.addOne(state, action.payload)
      }
    },
    getBillingCreditsDashboardEmbedUrl(_state, _action: PayloadAction<{ organizationName: string }>) {},
    getBillingInvoiceDashboardEmbedUrl(_state, _action: PayloadAction<{ organizationName: string }>) {},
    getBillingUsageDashboardEmbedUrl(_state, _action: PayloadAction<{ organizationName: string }>) {},
  },
  extraReducers(builder) {
    builder.addCase(unsubscribeFromBilling, (state, action) => {
      billingDashboardsAdapter.removeOne(state, action.payload.organizationName)
    })
  },
})

export const {
  billingDashboardsEmbedUrlAdded,
  getBillingCreditsDashboardEmbedUrl,
  getBillingInvoiceDashboardEmbedUrl,
  getBillingUsageDashboardEmbedUrl,
} = billingDashboardsSlice.actions
