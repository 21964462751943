export enum InferenceEndpointName {
  TRANSCRIBE = 'transcribe',
  WHISPER_LARGE_V3 = 'whisper-large-v3',
}

export interface TranscribePayload {
  url: string
  language_code: string
  word_level_timestamps: boolean
  diarization: boolean
  srt: boolean
}

export interface WhisperLargeV3Payload {
  url: string
}

export type InferenceEndpointJobsPayload = TranscribePayload | WhisperLargeV3Payload
