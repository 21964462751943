import { defineMessages } from 'react-intl'

export const APIKeyCopiedToClipboardSucceededContentMessages = defineMessages({
  body: {
    defaultMessage: 'Your API Key has been copied to your clipboard',
    id: 'PSGJ8U81',
    description:
      'The description shown in a toast notification message shown when the user successfully copied the API Key to their clipboard.',
  },
  title: {
    defaultMessage: 'API Key Copied',
    id: 'M9zy4sYm',
    description: 'The toast notification title shown when the user successfully copied the API Key to their clipboard.',
  },
})
