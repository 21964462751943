import { defineMessages } from 'react-intl'

export const EditImageTypeInputMessages = defineMessages({
  label: {
    defaultMessage: 'Is it in a public or private registry?',
    id: 'gvUNNRQT',
    description: 'The label for the image type selection field.',
  },
  privateRegistrylabel: {
    defaultMessage: 'Private Registry',
    id: 'BsaeypQa',
    description: 'The label for the private registry option.',
  },
  publicRegistrylabel: {
    defaultMessage: 'Public Registry',
    id: 'lR+Wy0Dx',
    description: 'The label for the public registry option.',
  },
})
