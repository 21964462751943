import type { ReactNode } from 'react'
import type { ToastNotificationType } from '../models'
import { SuccessfullySentEmailToastContentMessages } from './messages'

/**
 * Retrieves the content needed for a success `ToastNotification` that is shown when we have successfully sent an email
 * to the user.
 */
export const getSuccessfullySentEmailToastContent = (): {
  body: ReactNode
  type: ToastNotificationType
  title: string
} => {
  return {
    body: SuccessfullySentEmailToastContentMessages.body.defaultMessage,
    type: 'success',
    title: SuccessfullySentEmailToastContentMessages.title.defaultMessage,
  }
}
