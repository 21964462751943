import { defineMessages } from 'react-intl'

export const CreateRecipeDeploymentNonUniqueNameContentMessages = defineMessages({
  body: {
    defaultMessage: 'Recipe deployment names must be unique. {recipe_deployment_name} has been used in this project.',
    id: '3rxIbN6w',
    description:
      'The description shown in a toast notification message shown when we encounter an error on their request to create the project with the name that has already been taken.',
  },
  title: {
    defaultMessage: 'Please choose another deployment name',
    id: '3T/TjuIr',
    description:
      'The toast notification title shown when we encounter an error on their request to create the project with the name that has already been taken.',
  },
})
