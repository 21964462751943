import { defineMessages } from 'react-intl'

export const GeneralErrorReinvitingTeamMemberContentMessages = defineMessages({
  body: {
    defaultMessage:
      'We were unable to complete your request to resend the invitation to {user_email}. Try again and if this issue persists {contact_support}.',
    id: 'ZjUcuc0y',
    description: 'The body for the error Team Member Invite Resent notification.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'pIQEUfA+',
    description: 'The text shown for the contact support link.',
  },
  title: {
    defaultMessage: 'Unable to resend invitation',
    id: 'uS9/EO6H',
    description: 'The title for the error Team Member Invite Resent notification.',
  },
})
