import { yupResolver } from '@hookform/resolvers/yup'
import type { FunctionComponent } from 'react'
import { useState } from 'react'
import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Button } from '../../../../components/Button'
import { ButtonMessages } from '../../../../components/Button/messages'
import { TextField } from '../../../../components/TextField'
import { Modal } from '../../../../components/block/Modal'
import { useFixedForm } from '../../../../hooks'
import { DeleteProjectModalMessages } from '../../messages/DeleteProjectModalMessages'
import { useDeleteProjectFormValidation } from './useDeleteProjectFormValidation'

export interface DeleteProjectValues {
  /** The project name. */
  projectName: string
}

export interface DeleteProjectModalProps {
  /** The flag indicating that the request to delete the project is pending. */
  isDeleteProjectPending: boolean
  /** The callback executed when the user clicks the `Cancel` button. */
  onCancel: () => void
  /** The callback executed when the user clicks the `Delete` button. */
  onDeleteProject: (projectName: string) => void
  /** The project name. */
  projectName: string
}

export const DeleteProjectModal: FunctionComponent<DeleteProjectModalProps> = ({
  isDeleteProjectPending,
  onCancel,
  onDeleteProject,
  projectName,
}) => {
  const intl = useIntl()
  const [isAbleToDeleteProject, toggleIsAbleToDeleteProject] = useState<boolean>(false)
  const deleteProjectValidationScheme = useDeleteProjectFormValidation(projectName)

  const { control, getValues, handleSubmit, register } = useFixedForm<DeleteProjectValues>({
    resolver: yupResolver(deleteProjectValidationScheme),
    onSubmit: () => onDeleteProject(projectName),
  })

  const handleOnChange = () => {
    const textFieldMatchesProject = getValues('projectName') === projectName
    toggleIsAbleToDeleteProject(textFieldMatchesProject)
  }
  return (
    <Modal
      onClose={onCancel}
      title={intl.formatMessage(DeleteProjectModalMessages.title, {
        project_name: projectName,
      })}
    >
      <h1 className="mb-8 text-2xl font-bold">
        {intl.formatMessage(DeleteProjectModalMessages.title, {
          project_name: projectName,
        })}
      </h1>
      <p className="mb-8">
        {intl.formatMessage(DeleteProjectModalMessages.description, {
          project_name: <span className="text-green-80">{projectName}</span>,
        })}
      </p>
      <form onSubmit={handleSubmit}>
        <Controller
          name="projectName"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...register('projectName', { onChange: handleOnChange })}
              {...field}
              {...fieldState}
              placeholder={projectName}
              label={intl.formatMessage(DeleteProjectModalMessages.projectInputLabel)}
              type="text"
              isFullWidth
            />
          )}
        />
        <div className="flex max-w-md gap-6">
          <Button type="button" variant="green-outlined" onClick={onCancel} isFullWidth>
            {intl.formatMessage(DeleteProjectModalMessages.cancelButtonLabel)}
          </Button>
          <Button
            variant="red-filled"
            type="submit"
            isDisabled={!isAbleToDeleteProject || isDeleteProjectPending}
            isLoading={isDeleteProjectPending}
            isFullWidth
          >
            {intl.formatMessage(ButtonMessages.deleteButtonLabel)}
          </Button>
        </div>
      </form>
    </Modal>
  )
}
