import { defineMessages } from 'react-intl'

export const CreateOrganizationWithNonUnqiueProjectNameContentMessages = defineMessages({
  body: {
    defaultMessage: 'Project names must be unique. {project_name} has been used in your organization.',
    id: 'Y0MgmFvh',
    description:
      'The description shown in a toast notification message shown when we encounter an error on their request to create an organization with a project name that has already been taken.',
  },
  title: {
    defaultMessage: 'Please choose another project name',
    id: 'xYcapKAf',
    description:
      'The toast notification title shown when we encounter an error on their request to create an organization with a project name that has already been taken.',
  },
})
