import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import { memo, useState } from 'react'
import { useIntl } from 'react-intl'
import { getAppSumoInviteCodeLink } from '../../../utils/requestIncreaseLinks'
import { Spinner } from '../../Spinner'
import { Modal } from '../../block/Modal'
import { LeftNavigationBarMessages } from '../messages'

export interface AppSumoCodeModalProps {
  /** The callback executed when the user closes the modal. */
  onCloseWindow: () => void
  /** The organization name */
  organizationName?: string
  /** The user email */
  email?: string
}

export const AppSumoCodeModal: FunctionComponent<AppSumoCodeModalProps> = memo(
  ({ email, onCloseWindow, organizationName }) => {
    const intl = useIntl()
    const [isIframeLoaded, setIsIframeLoaded] = useState(false)
    const isShowingLoadingSpinner = !isIframeLoaded

    const linkToAppSumoInviteCode = getAppSumoInviteCodeLink(email as string, organizationName as string)

    const handleSetIframeLoaded = () => {
      setTimeout(() => setIsIframeLoaded(true), 700)
    }

    const title = intl.formatMessage(LeftNavigationBarMessages.appSumoCodeNavigationLabel)

    return (
      <Modal onClose={onCloseWindow} title={title} customModalTailwindSizeClasses="w-4/5 h-4/5">
        <div className="relative size-full">
          {isShowingLoadingSpinner && (
            <div className="absolute inset-0 flex items-center justify-center bg-opacity-75">
              <Spinner size="lg" />
            </div>
          )}
          <iframe
            src={linkToAppSumoInviteCode}
            title={title}
            className={classNames('h-full w-full border-0', {
              hidden: isShowingLoadingSpinner,
            })}
            onLoad={handleSetIframeLoaded}
          ></iframe>
        </div>
      </Modal>
    )
  },
)
