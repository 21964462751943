import classNames from 'classnames'
import type { FunctionComponent, ReactNode } from 'react'
import { useIntl } from 'react-intl'
import { InputHelperText } from '../../InputHelperText'
import { Card } from '../../base/Card'
import { FeaturePreviewPill } from '../../featurePreview/FeaturePreviewPill'
import { FormStateCardMessages } from './messages'

interface FormStateCardProps {
  /** The children that will show as the content inside the `Card`. */
  children: ReactNode
  /** The flag indicating if the form fields have errors. */
  hasError: boolean
  /** The helper text shown for the form fields. */
  helperText?: ReactNode | string
  /** The flag indicating if the form fields are disabled. */
  isDisabled?: boolean
  /** The flag indicating if the form fields are part of a feature preview. */
  isFeaturePreview?: boolean
  /** The callback executed when the user click the edit link. */
  onEditForm?: () => void
  /** The title of the form. */
  title: string
}

export const FormStateCard: FunctionComponent<FormStateCardProps> = ({
  children,
  hasError,
  helperText,
  isDisabled,
  isFeaturePreview,
  onEditForm,
  title,
}) => {
  const intl = useIntl()

  return (
    <>
      <div className="mb-2 flex justify-between">
        <div className="flex gap-2">
          <h2
            className={classNames('text-base font-bold', {
              'text-red-70': hasError,
            })}
          >
            {title}
          </h2>
          {isFeaturePreview && <FeaturePreviewPill />}
        </div>
        {!isDisabled && onEditForm && (
          <p className="cursor-pointer text-blue-90 underline" onClick={onEditForm}>
            {intl.formatMessage(FormStateCardMessages.editLinkText)}
          </p>
        )}
      </div>
      <Card>
        <div
          className={classNames('text-neutral-80', {
            'text-red-70': hasError,
            'opacity-20': isDisabled,
          })}
        >
          {children}
        </div>
      </Card>
      {helperText && (
        <div className="mt-1">
          <InputHelperText hasError={hasError} helperText={helperText} isDisabled={isDisabled} />
        </div>
      )}
    </>
  )
}
