import { defineMessages } from 'react-intl'

export const EditContainerGroupGeneralErrorContentMessages = defineMessages({
  title: {
    defaultMessage: 'Error editing container group',
    id: 'A+BbiPvk',
    description:
      'The toast notification title shown when we encounter an error on their request to save a container group.',
  },
  body: {
    defaultMessage:
      'We were unable to edit your container group. Try again and if this issue persists {contact_support}',
    id: '18EMl79E',
    description:
      'The description shown in a toast notification message shown when we encounter an error on their request to save a container group.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'GaVxzbZR',
    description: 'The text shown for the contact support link in the create organization error toast notification.',
  },
})
