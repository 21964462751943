import { defineMessages } from 'react-intl'

export const HealthProbeFieldsMessages = defineMessages({
  commandHelperText: {
    defaultMessage: 'Required, please {read_documentation_link} for proper entry of command',
    id: '3G3uZilZ',
    description: 'The helper text for the command field.',
  },
  commandLabel: {
    defaultMessage: 'Command',
    id: 'wAJFaiYe',
    description: 'The command label for the health probe form.',
  },
  commandPlaceholder: {
    defaultMessage: 'None Configured',
    id: 'mHEYbyzb',
    description: 'The command placeholder for the health probe.',
  },
  commandReadDocumentationLinkText: {
    defaultMessage: 'read documentation',
    id: 'crMquUGa',
    description: 'The text for the command read documentation command helper text for the health probe.',
  },
  configureButtonHelperText: {
    defaultMessage: `This probe is configured to fail if the container hasn't started within {calculated_time} seconds. If that's too short, increase the parameters above.`,
    id: '9uvuc6RZ',
    description: 'The configure button helper text for the health probe.',
  },
  configureButtonLabel: {
    defaultMessage: 'Configure',
    id: '1vBqgsza',
    description: 'The configure button label for the health probe.',
  },
  enableProbeLabel: {
    defaultMessage: 'Enable {health_probe_type} Probe',
    id: 'tSGXnaz+',
    description: 'The enable health probe label for the health probe.',
  },
  headersLabel: {
    defaultMessage: 'Headers',
    id: '5+dMt9Yh',
    description: 'The headers label for the health probe form.',
  },
  headersPlaceholder: {
    defaultMessage: 'None Configured',
    id: 'Sg47Kacs',
    description: 'The headers placeholder for the health probe.',
  },
  learMoreLinkText: {
    defaultMessage: 'Learn More',
    id: 'osGvsXP3',
    description: 'The learn more link text used for the health probe docs.',
  },
  livenessSubtitle: {
    defaultMessage:
      'Runs when startup probe completes (if configured). Often used to detect that application is running correctly. {learn_more_link_text}',
    id: 'lmhxlPdY',
    description: 'The subtitle for the liveness probe.',
  },
  portSummaryLabel: {
    defaultMessage: 'Port: {port}',
    id: 'eFa8zH+T',
    description: 'The port summary label for the health probe.',
  },
  protocolLabel: {
    defaultMessage: 'Protocol',
    id: 'y7HjHLJL',
    description: 'The protocol label for the health probe.',
  },
  readDocumentationLinkText: {
    defaultMessage: 'read documentation',
    id: 'vtlp2eDS',
    description: 'The read documentation link text for the health probe.',
  },
  serviceSummaryLabel: {
    defaultMessage: 'Service: {service}',
    id: 'zkW9GqNV',
    description: 'The service summary label for the health probe.',
  },
  startupSubtitle: {
    defaultMessage:
      'Runs when container starts, usually to detect that application started. Often used alongside liveness probe. {learn_more_link_text}',
    id: 'iTnxqcXI',
    description: 'The subtitle for the startup probe.',
  },
  title: {
    defaultMessage: '{health_probe_type} Probe',
    id: 'ZdJqjKum',
    description: 'The title for the health probe.',
  },
  subTitle: {
    defaultMessage:
      'A {health_probe_type} probe is used to evaluate whether your container is ready to accept traffic from either the container gateway or job queues. ',
    id: '20dyNaYA',
    description: 'The subtitle for the health probe.',
  },
})
