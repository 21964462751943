import { defineMessages } from 'react-intl'

export const ChangePasswordGeneralErrorContentMessages = defineMessages({
  body: {
    defaultMessage: 'We were unable to change your password. Try again and if this issue persists {contact_support}.',
    id: '3dsssZtw',
    description:
      'The description shown in a toast notification message shown when the user encounters an error changing their password.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'mJWhKEEC',
    description: 'The text shown for the contact support link in the change password error toast notification.',
  },
  title: {
    defaultMessage: 'Error changing password',
    id: '2UYe4j9U',
    description: 'The toast notification title shown when the user encounters an error changing their password.',
  },
})
