import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import type { DeploymentStatus } from '../../../../../../features/containerGroupInstanceTable/models'
import { ProgressPill } from '../../../../../block/ProgressPill'
import { DeploymentInstanceStatusMessages } from '../../../../messages'

interface CreatingStatusProps {
  /** The deployment status */
  deploymentStatus: DeploymentStatus
  /** The callback executed when the user clicks the status */
  onClick: () => void
  /** The percentage */
  percentage: number | undefined
}

export const CreatingStatus: FunctionComponent<CreatingStatusProps> = ({ deploymentStatus, onClick, percentage }) => {
  const intl = useIntl()
  const isCreatingComplete = deploymentStatus === 'running'
  const isActive = deploymentStatus === 'creating'

  return (
    <ProgressPill
      icon={<i className={classNames('fa-solid fa-clock')} />}
      label={intl.formatMessage(DeploymentInstanceStatusMessages.creating)}
      onClick={onClick}
      percentage={isCreatingComplete ? 100 : isActive ? percentage : undefined}
      width={135}
    />
  )
}
