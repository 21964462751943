import { defineMessages } from 'react-intl'

export const EnvironmentVariablesListMessages = defineMessages({
  hide: {
    defaultMessage: 'Hide',
    id: '3skgnhMt',
    description: 'Hide button text',
  },
  show: {
    defaultMessage: 'Show',
    id: 'AdGinxhj',
    description: 'Show button text',
  },
  title: {
    defaultMessage: 'Environment Variables',
    id: 'ReHWONnx',
    description: 'Environment Variables title',
  },
})
