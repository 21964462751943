import type { FunctionComponent } from 'react'
import { forwardRef } from 'react'
import type { Control, FieldValues } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { TextField } from '../../../../components/TextField'
import { EditDisplayNameInputMessages } from '../../messages'
import type { EditContainerGroupFormSectionIdAttributes, EditContainerGroupValues } from '../../models'
import { EditContainerGroupField } from '../../models'

interface EditDisplayNameInputProps {
  /** The control for the react hook form. */
  control: Control<FieldValues, EditContainerGroupValues>
  /** The id attribute for the field. */
  id: EditContainerGroupFormSectionIdAttributes
}

export const EditDisplayNameInput: FunctionComponent<EditDisplayNameInputProps> = forwardRef<
  HTMLDivElement,
  EditDisplayNameInputProps
>(({ control, id }, ref) => {
  const intl = useIntl()

  return (
    <div id={id} ref={ref}>
      <Controller
        name={EditContainerGroupField.DISPLAY_NAME}
        control={control}
        render={({ field, fieldState }) => {
          return (
            <TextField
              {...field}
              {...fieldState}
              defaultValue={field.value}
              helperText={fieldState.error?.message}
              label={intl.formatMessage(EditDisplayNameInputMessages.label)}
              isFullWidth
              type="text"
            />
          )
        }}
      />
    </div>
  )
})
