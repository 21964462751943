import type { WorkloadError } from '@saladtechnologies/openapi-cloud-portal-browser'
import { type IntlShape } from 'react-intl'
import { createToastNotification } from '../../../notifications'
import {
  getWorkloadErrorsDownloadErrorContent,
  getWorkloadErrorsDownloadSuccessfullyStartedContent,
} from '../../../notifications/clientToastNotificationContent/workloadErrors'
import type { TableHeaders } from '../../block/Table/models'
import { TableSortingOrder } from '../../block/Table/models'
import { WorkloadErrorsTableMessages } from './messages/WorkloadErrorsTableMessages'
import type { WorkloadErrorsTableColumn, WorkloadErrorsTableSortingMethod } from './models'

/**
 * Sorts the workload errors according to the preference specified.
 *
 * @param workloadErrors The array of workload errors.
 * @param sortBy How to sort the array of workload errors.
 */
export const getSortedWorkloadErrors = (
  workloadErrors: WorkloadError[],
  sortBy: {
    column: WorkloadErrorsTableColumn
    allocatedAtAscendingOrder: boolean
    detailAscendingOrder: boolean
    failedAtAscendingOrder: boolean
    machineIdAscendingOrder: boolean
    startedAtAscendingOrder: boolean
  },
): WorkloadError[] => {
  const workloadErrorsCopy = [...workloadErrors]
  const defaultDetailOrder: string[] = [
    'RunFailure',
    'StartFailure',
    'StartupProbeFailure',
    'LivenessProbeFailure',
    'Exited',
  ]

  switch (sortBy.column) {
    case 'allocatedAt': {
      return workloadErrorsCopy.sort((a, b) => {
        const lastAllocatedAtA = a.allocatedAt === undefined ? 0 : a.allocatedAt.getTime()
        const lastAllocatedAtB = b.allocatedAt === undefined ? 0 : b.allocatedAt.getTime()

        if (sortBy.allocatedAtAscendingOrder) {
          return lastAllocatedAtA < lastAllocatedAtB ? -1 : lastAllocatedAtA > lastAllocatedAtB ? 1 : 0
        } else {
          return lastAllocatedAtA > lastAllocatedAtB ? -1 : lastAllocatedAtA < lastAllocatedAtB ? 1 : 0
        }
      })
    }

    case 'detail': {
      const detailOrder = sortBy.detailAscendingOrder ? defaultDetailOrder : [...defaultDetailOrder].reverse()

      const sortedWorkloadErrors = detailOrder
        .map((detail) => workloadErrors.filter((workloadError) => workloadError.detail.includes(detail)))
        .flat()

      const remainingWorkloadErrors = workloadErrors.filter(
        (workloadError) => !detailOrder.some((detail) => workloadError.detail.includes(detail)),
      )

      return sortBy.detailAscendingOrder
        ? [...sortedWorkloadErrors, ...remainingWorkloadErrors]
        : [...remainingWorkloadErrors, ...sortedWorkloadErrors]
    }

    case 'failedAt': {
      return workloadErrorsCopy.sort((a, b) => {
        const lastFailedAtA = a.failedAt.getTime()
        const lastFailedAtB = b.failedAt.getTime()

        if (sortBy.failedAtAscendingOrder) {
          return lastFailedAtA < lastFailedAtB ? -1 : lastFailedAtA > lastFailedAtB ? 1 : 0
        } else {
          return lastFailedAtA > lastFailedAtB ? -1 : lastFailedAtA < lastFailedAtB ? 1 : 0
        }
      })
    }

    case 'machineId': {
      return workloadErrorsCopy.sort((a, b) => {
        const machineIdA = a.machineId
        const machineIdB = b.machineId

        if (sortBy.machineIdAscendingOrder) {
          return machineIdA < machineIdB ? -1 : machineIdA > machineIdB ? 1 : 0
        } else {
          return machineIdA > machineIdB ? -1 : machineIdA < machineIdB ? 1 : 0
        }
      })
    }

    case 'startedAt': {
      return workloadErrorsCopy.sort((a, b) => {
        const lastStartedAtA = a.startedAt === null || a.startedAt === undefined ? 0 : a.startedAt.getTime()
        const lastStartedAtB = b.startedAt === null || b.startedAt === undefined ? 0 : b.startedAt.getTime()

        if (sortBy.startedAtAscendingOrder) {
          return lastStartedAtA < lastStartedAtB ? -1 : lastStartedAtA > lastStartedAtB ? 1 : 0
        } else {
          return lastStartedAtA > lastStartedAtB ? -1 : lastStartedAtA < lastStartedAtB ? 1 : 0
        }
      })
    }

    default: {
      return workloadErrors
    }
  }
}

/**
 * Configures the Workload Errors Table Headers with their labels, and styles that occur when individual headers are
 * clicked.
 *
 * @param intl The Intl Shape.
 * @param sortingMethod The method in which the order is sorted.
 * @param onUpdateSortingMethod The callback that when executed updates the sorting method.
 */
export const configureWorkloadErrorsTableHeader = (
  intl: IntlShape,
  sortingMethod: WorkloadErrorsTableSortingMethod,
  onUpdateSortingMethod: (updatedSortingState: WorkloadErrorsTableSortingMethod) => void,
): TableHeaders => {
  const workloadErrorsTableHeader = [
    {
      label: intl.formatMessage(WorkloadErrorsTableMessages.detailsHeader),
      sortingOrder: sortingMethod.detailAscendingOrder ? TableSortingOrder.Ascending : TableSortingOrder.Descending,
      onClick: () =>
        onUpdateSortingMethod({
          allocatedAtAscendingOrder: true,
          column: 'detail',
          detailAscendingOrder: !sortingMethod.detailAscendingOrder,
          failedAtAscendingOrder: true,
          machineIdAscendingOrder: true,
          startedAtAscendingOrder: true,
        }),
    },
    {
      label: intl.formatMessage(WorkloadErrorsTableMessages.machineIdHeader),
      sortingOrder: sortingMethod.machineIdAscendingOrder ? TableSortingOrder.Ascending : TableSortingOrder.Descending,
      onClick: () =>
        onUpdateSortingMethod({
          allocatedAtAscendingOrder: true,
          column: 'machineId',
          detailAscendingOrder: true,
          failedAtAscendingOrder: true,
          machineIdAscendingOrder: !sortingMethod.machineIdAscendingOrder,
          startedAtAscendingOrder: true,
        }),
    },
    {
      label: intl.formatMessage(WorkloadErrorsTableMessages.failedAtHeader),
      sortingOrder: sortingMethod.failedAtAscendingOrder ? TableSortingOrder.Ascending : TableSortingOrder.Descending,
      onClick: () =>
        onUpdateSortingMethod({
          allocatedAtAscendingOrder: true,
          column: 'failedAt',
          detailAscendingOrder: true,
          failedAtAscendingOrder: !sortingMethod.failedAtAscendingOrder,
          machineIdAscendingOrder: true,
          startedAtAscendingOrder: true,
        }),
    },
    {
      label: intl.formatMessage(WorkloadErrorsTableMessages.allocatedAtHeader),
      sortingOrder: sortingMethod.allocatedAtAscendingOrder
        ? TableSortingOrder.Ascending
        : TableSortingOrder.Descending,
      onClick: () =>
        onUpdateSortingMethod({
          allocatedAtAscendingOrder: !sortingMethod.allocatedAtAscendingOrder,
          column: 'allocatedAt',
          detailAscendingOrder: true,
          failedAtAscendingOrder: true,
          machineIdAscendingOrder: true,
          startedAtAscendingOrder: true,
        }),
    },
    {
      label: intl.formatMessage(WorkloadErrorsTableMessages.startedAtHeader),
      sortingOrder: sortingMethod.startedAtAscendingOrder ? TableSortingOrder.Ascending : TableSortingOrder.Descending,
      onClick: () =>
        onUpdateSortingMethod({
          allocatedAtAscendingOrder: true,
          column: 'startedAt',
          detailAscendingOrder: true,
          failedAtAscendingOrder: true,
          machineIdAscendingOrder: true,
          startedAtAscendingOrder: !sortingMethod.startedAtAscendingOrder,
        }),
    },
  ]

  return workloadErrorsTableHeader
}

export const workloadErrorsTableHeaderStrings: (keyof WorkloadError)[] = [
  'detail',
  'machineId',
  'failedAt',
  'allocatedAt',
  'startedAt',
]

export const isWorkloadErrorPillVisible = (replicas: number, workloadErrorCount?: number) =>
  workloadErrorCount !== undefined && workloadErrorCount > Math.ceil(replicas / 5)

/**
 * The function used to download Workload Errors as a CSV file.
 *
 * @param workloadErrors The data that is downloaded
 * @param containerGroupDisplayName The container group name that is used in the download file
 * @param onToggleLoading Toggles the loading state of the download button
 * @param intl The Intl Shape
 */
export const onDownloadWorkloadErrors = (
  workloadErrors: WorkloadError[],
  containerGroupDisplayName: string,
  onToggleLoading: (isLoading: boolean) => void,
  intl: IntlShape,
) => {
  const csvRows = workloadErrors.map((workloadError) => {
    const { allocatedAt, detail, failedAt, machineId, startedAt } = workloadError
    return [detail, machineId, failedAt, allocatedAt ? allocatedAt : '', startedAt ? startedAt : '']
  })
  const headers = workloadErrorsTableHeaderStrings.join(',')
  const csvContent = csvRows.map((row) => row.join(',')).join('\n')

  const blob = new Blob([headers + '\n' + csvContent], { type: 'text/csv;charset=utf-8;' })
  const url = URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.setAttribute('href', url)
  link.setAttribute(
    'download',
    `${containerGroupDisplayName}-failures-${intl.formatDate(Date.now(), {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    })}.csv`,
  )
  link.style.visibility = 'hidden'
  document.body.appendChild(link)
  try {
    onToggleLoading(true)
    link.click()
    createToastNotification(getWorkloadErrorsDownloadSuccessfullyStartedContent(intl))
  } catch (error) {
    createToastNotification(getWorkloadErrorsDownloadErrorContent(intl))
  } finally {
    onToggleLoading(false)
  }
  document.body.removeChild(link)
}
