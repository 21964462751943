import { defineMessages } from 'react-intl'

export const RecipeDetailsMainContentMessages = defineMessages({
  backToRecipeMarketplaceButtonLabel: {
    defaultMessage: 'Back to Recipe Marketplace',
    id: 'gw8NmlJB',
    description: 'The label for the Back to Recipe Marketplace button.',
  },
  configureDeployButtonLabel: {
    defaultMessage: 'Configure & Deploy',
    id: '5Amq71Gv',
    description: 'The label for the Configure & Deploy button.',
  },
})
