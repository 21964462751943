import { defineMessages } from 'react-intl'

export const RecipeDetailsGeneralErrorContentMessages = defineMessages({
  body: {
    defaultMessage: 'We were unable to retrieve this recipe. Try again and if this issue persists {contact_support}.',
    id: 'War39LzF',
    description:
      'The description shown in a toast notification message shown when we encounter an error on their request to get their recipe details.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'u5mDUVxG',
    description: 'The text shown for the contact support link in the get recipe details error toast notification.',
  },
  title: {
    defaultMessage: 'Unable to retrieve recipe',
    id: 'ubhRP7ob',
    description:
      'The toast notification title shown when we encounter an error on their request to get their recipe details.',
  },
})
