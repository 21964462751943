import { defineMessages } from 'react-intl'

export const DeployedRecipeCardMessages = defineMessages({
  authRequired: {
    defaultMessage: 'Authentication Required',
    id: 'FZT4OlFK',
    description: 'The label of the tag used to show that authentication is required.',
  },
  containerGatewayEnabled: {
    defaultMessage: 'Container Gateway',
    id: 'C91L5vBS',
    description: 'The label of the tag used to show that Container Gateway is enabled.',
  },
  recipeSource: {
    defaultMessage: 'From {recipe_name}',
    id: '2W/T5dgv',
    description: 'The message used to show the recipe source.',
  },
  replicaCount: {
    defaultMessage: '{replica_count} Replicas',
    id: 'koj8WOqK',
    description: 'The label of the tag used to show the replica count.',
  },
})
