import { yupResolver } from '@hookform/resolvers/yup'
import type { FunctionComponent } from 'react'
import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Button } from '../../../../components/Button'
import { TextField } from '../../../../components/TextField'
import { useFixedForm } from '../../../../hooks'
import { loginMessages } from './LoginMessages'
import { useLoginFormValidation } from './useLoginFormValidation'

export interface LoginFormValues {
  /** The email address. */
  email: string

  /** The password. */
  password: string
}

export type SubmitErrorVariant = 'credentials' | 'request' | undefined

export interface LoginProps {
  /** The flag indicating the isSubmitPending request is pending */
  isSubmitPending: boolean
  /** The submit handler. */
  onSubmit: (values: LoginFormValues) => void
  /** The callback exected when the user clicks "Create Account". */
  onViewCreateAccount: () => void
  /** The callback exected when the user clicks "Forgot Password". */
  onViewForgotPassword: () => void
  /** The callback exected when the user clicks "Looking to earn". */
  onViewEarn: () => void
}

export const Login: FunctionComponent<LoginProps> = ({
  isSubmitPending,
  onSubmit,
  onViewCreateAccount,
  onViewEarn,
  onViewForgotPassword,
}) => {
  const intl = useIntl()
  const loginFormValidationScheme = useLoginFormValidation()

  const { control, handleSubmit } = useFixedForm<LoginFormValues>({
    resolver: yupResolver(loginFormValidationScheme),
    onSubmit,
  })

  return (
    <form className="flex w-96 flex-col bg-neutral-10 p-8 text-neutral-100" onSubmit={handleSubmit}>
      <h1 className="pb-8 text-2xl font-bold">{intl.formatMessage(loginMessages.title)}</h1>
      <p className="pb-8">
        {intl.formatMessage(loginMessages.haveAccount)}&nbsp;
        <button
          className="cursor-pointer text-blue-90 underline"
          onClick={onViewCreateAccount}
          role="link"
          type="button"
        >
          {intl.formatMessage(loginMessages.createAccount)}
        </button>
      </p>

      <Controller
        name="email"
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            {...fieldState}
            isFullWidth
            helperText={fieldState.error?.message}
            leftIconClassName="fa-solid fa-envelope"
            isDisabled={isSubmitPending}
            label={intl.formatMessage(loginMessages.emailLabel)}
            placeholder={intl.formatMessage(loginMessages.emailPlaceholder)}
          />
        )}
      />
      <Controller
        name="password"
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            {...fieldState}
            isFullWidth
            helperText={fieldState.error?.message}
            leftIconClassName="fa-solid fa-lock"
            isDisabled={isSubmitPending}
            label={intl.formatMessage(loginMessages.passwordLabel)}
            placeholder={intl.formatMessage(loginMessages.passwordPlaceholder)}
            type="password"
          />
        )}
      />
      <p className="pb-8">
        {intl.formatMessage(loginMessages.forgotPasswordPt1)}&nbsp;
        <button
          className="cursor-pointer text-blue-90 underline"
          onClick={onViewForgotPassword}
          role="link"
          type="button"
        >
          {intl.formatMessage(loginMessages.forgotPasswordPt2)}
        </button>
      </p>
      <Button type="submit" isDisabled={isSubmitPending} isLoading={isSubmitPending}>
        {intl.formatMessage(loginMessages.login)}
      </Button>
      <p className="pt-8">
        {intl.formatMessage(loginMessages.earn)}&nbsp;
        <button className="cursor-pointer text-blue-90 underline" onClick={onViewEarn} role="link" type="button">
          {intl.formatMessage(loginMessages.earnDownload)}
        </button>
      </p>
    </form>
  )
}
