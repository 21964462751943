import { defineMessages } from 'react-intl'

export const LoadAPIKeyFailedContentMessages = defineMessages({
  body: {
    defaultMessage: 'An error occurred while trying to load your API key. Please try reloading the page.',
    id: '+KwNtNJu',
    description: 'The description shown in a toast notification message shown when we are unable to load the API Key.',
  },
  title: {
    defaultMessage: 'Error loading API key',
    id: 'ybPgGzIq',
    description: 'The toast notification title shown when we are unable to load the API Key.',
  },
})
