import classNames from 'classnames'
import type { FunctionComponent, ReactNode } from 'react'
import { SaladLogo } from '../../components/SaladLogo'
import { TriangleBackgroundImages } from './components'

export interface ModalLayoutProps {
  /** The jsx that will be rendered inside the Modal. */
  children: ReactNode
  /** The type of modal box styles that will be applied. */
  type: 'narrow' | 'wide'
}

/** A layout component that renders content in the form of a modal. */
export const ModalLayout: FunctionComponent<ModalLayoutProps> = ({ children, type }) => (
  <main className="relative h-screen w-screen overflow-auto bg-neutral-100">
    <TriangleBackgroundImages />

    <div className="relative flex size-full flex-col items-center justify-center">
      <SaladLogo className="mb-6 w-52" height="100%" width={208} />
      <div
        className={classNames('flex flex-col gap-8 rounded bg-neutral-10 p-8 text-neutral-100', {
          'max-w-lg sm:max-w-2xl': type === 'wide',
          'w-96': type === 'narrow',
        })}
      >
        {children}
      </div>
    </div>
  </main>
)
