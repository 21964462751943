import type { FunctionComponent } from 'react'
import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { createJobQueue } from '../../features/jobQueues/jobQueuesSlice'
import { selectIsLeftColumnShowing } from '../../features/navigationBar/navigationBarSelectors'
import { setLeftColumnShowing } from '../../features/navigationBar/navigationBarSlice'
import { selectRequestStatusIsPending } from '../../features/requestStatus/requestStatusSelectors'
import { getJobQueuesPagePath } from '../../routes/routes-utils'
import { useAppDispatch, useAppSelector } from '../../store'
import { CreateJobQueuePage } from './CreateJobQueuePage'

export const ConnectedCreateJobQueuePage: FunctionComponent = () => {
  const { organizationName = '', projectName = '' } = useParams()
  const dispatch = useAppDispatch()
  const isLeftColumnOpen = useAppSelector(selectIsLeftColumnShowing)
  const isCreateJobQueueRequestPending = useAppSelector((state) =>
    selectRequestStatusIsPending(state, 'createJobQueue'),
  )

  const handleCloseLeftNavBar = useCallback(() => {
    dispatch(setLeftColumnShowing({ showing: false }))
  }, [dispatch])

  const onCreateJobQueue = useCallback(
    (name: string, description?: string) => {
      if (organizationName !== undefined) {
        dispatch(createJobQueue({ organizationName, projectName, createQueue: { name, description } }))
      }
    },
    [dispatch, organizationName, projectName],
  )

  return (
    <CreateJobQueuePage
      isLeftColumnOpen={isLeftColumnOpen}
      isCreateJobQueuePending={isCreateJobQueueRequestPending}
      jobQueuesPath={getJobQueuesPagePath(organizationName, projectName)}
      onCloseLeftDrawer={handleCloseLeftNavBar}
      onCreateJobQueue={onCreateJobQueue}
    />
  )
}
