import { defineMessages } from 'react-intl'

export const DockerHubInputFieldsMessages = defineMessages({
  needHelpText: {
    defaultMessage: 'Need help with setting up on DockerHub?',
    id: 'O647+CPo',
    description: 'The help text shown below the docker hub input fields.',
  },
  personalAccessTokenLabel: {
    defaultMessage: 'Personal Access Token',
    id: 'gM0pphms',
    description: 'The label for the Docker Hub personal access token input field.',
  },
  personalAccessTokenPlaceholderText: {
    defaultMessage: 'Your Personal Access Token',
    id: '6JLawgPl',
    description: 'The placeholder text for the Docker Hub personal access token input field.',
  },
  personalAccessTokenRequiredText: {
    defaultMessage: 'Personal Access Token is required',
    id: '/u2T7g7F',
    description: 'The required text for the Docker Hub personal access token input field.',
  },
  usernameLabel: {
    defaultMessage: 'Username',
    id: 'rOSf4Pru',
    description: 'The label for the Docker Hub username input field.',
  },
  usernamePlaceholderText: {
    defaultMessage: 'Your Username',
    id: 'jWKhcfze',
    description: 'The placeholder text for the Docker Hub username input field.',
  },
  usernameRequiredText: {
    defaultMessage: 'Username is required',
    id: '/zPAM62e',
    description: 'The required text for the Docker Hub username input field.',
  },
})
