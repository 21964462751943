import { defineMessages } from 'react-intl'

export const createAccountMessages = defineMessages({
  title: {
    defaultMessage: 'Create an Account',
    id: 'gmajnhjK',
    description: 'The title for the create an account form.',
  },
  createAccountButtonLabel: {
    defaultMessage: 'Create Account',
    id: '/BF85r7M',
    description: 'The label for the create account button.',
  },
  emailLabel: {
    defaultMessage: 'Email Address',
    id: 'ouh/i5yE',
    description: 'The label for the email address field.',
  },
  emailPlaceholder: {
    defaultMessage: 'Email',
    id: 'w1g6SHO6',
    description: 'The placeholder for the email address field.',
  },
  emailRequired: {
    defaultMessage: 'An email address is required.',
    id: '4PeHXlzA',
    description: 'The error message when the email address is not provided.',
  },
  emailInvalid: {
    defaultMessage: 'Must be a valid email',
    id: '0a+oaz68',
    description: 'The email is invalid.',
  },
  gpuDrivenProcessingTitle: {
    defaultMessage: 'GPU Driven Processing',
    id: 'WjGAT9Jd',
    description: 'The title for the GPU driven processing benefit.',
  },
  gpuDrivenProcessingDescription: {
    defaultMessage:
      'Distribute data batch jobs, HPC workloads, and rendering queues to thousands of 3D-accelerated GPUs.',
    id: 'Bxmr3qR5',
    description: 'The description for the GPU driven processing benefit.',
  },
  haveAccount: {
    defaultMessage: 'Already have an account?',
    id: 'sqdM8Oba',
    description: 'The text for the log in.',
  },
  logIn: {
    defaultMessage: 'Log-in',
    id: 'gQn+u5JM',
    description: 'The text for the log in link.',
  },
  onDemandElasticityTitle: {
    defaultMessage: 'On-Demand Elasticity',
    id: '/6HKeFy5',
    description: 'The title for the on-demand elasticity benefit.',
  },
  onDemandElasticityDescription: {
    defaultMessage:
      'Scale to 10,000 nodes on demand using our toolkits, or build a custom K8s integration with our simple API.',
    id: 'qCrfQ7Ju',
    description: 'The description for the on-demand elasticity benefit.',
  },
  passwordLabel: {
    defaultMessage: 'Password',
    id: 'AU2UIiz8',
    description: 'The label for the password field.',
  },
  passwordPlaceholder: {
    defaultMessage: 'Password',
    id: 'zeHclMGl',
    description: 'The placeholder for the password field.',
  },
  passwordHelperText: {
    defaultMessage:
      'Passwords must be at least 8 characters and contain at least one uppercase letter, one lowercase letter, one non-alphanumeric character, and one digit.',
    id: 'TLPqQRx9',
    description: 'The helper text for the password field.',
  },
  passwordRequired: {
    defaultMessage: 'A password is required.',
    id: 'l7YrdeZY',
    description: 'The error message when the password is not provided.',
  },
  reduceBudgetTitle: {
    defaultMessage: 'Reduce Budget Bloat',
    id: 'LMknDV6b',
    description: 'The title for the reduce budget benefit.',
  },
  reduceBudgetDescription: {
    defaultMessage:
      'Save up to 80% on orchestration services from big-box providers plus discounts on recurring plans.',
    id: 'xHiQnekN',
    description: 'The description for the reduce budget benefit.',
  },
  saladLogoAlt: {
    defaultMessage: 'Salad logo',
    id: 'VRehwVPZ',
    description: 'The alternate text for salad logo image',
  },
  termsOfServiceCopy: {
    defaultMessage: 'I accept the {terms_of_service_link}',
    id: '43I6C8vK',
    description: 'The copy for our Terms of Service excluding the link.',
  },
  termsOfServiceLinkCopy: {
    defaultMessage: 'Terms of Service',
    id: 'JKqHI0EZ',
    description: 'The copy for the Terms of Service Link.',
  },
  verifyPasswordLabel: {
    defaultMessage: 'Verify Password',
    id: '5vhdTf2M',
    description: 'The label for the repeated password field.',
  },
  verifyPasswordPlaceholder: {
    defaultMessage: 'Password',
    id: 'jXUJgd//',
    description: 'The placeholder for the repeated password field.',
  },
  verifyPasswordDoesNotMatch: {
    defaultMessage: 'Password does not match.',
    id: 'yjeL0qUB',
    description: 'Repeated password does not match with password',
  },
  earn: {
    defaultMessage: 'Looking to earn with your PC?',
    id: '69MJorbU',
    description: 'The title for the login form.',
  },
  earnDownload: {
    defaultMessage: 'Download Salad & Earn',
    id: 'bx3OBXJI',
    description: 'The title for the login form.',
  },
})
