import { defineMessages } from 'react-intl'

export const CommandDetailsMessages = defineMessages({
  commandWithLabel: {
    defaultMessage: 'Command: {command}',
    id: 'RK0LWUC1',
    description: 'The label for the command properties.',
  },
  noneConfiguredLabel: {
    defaultMessage: 'None Configured',
    id: 'ZnuC5FIj',
    description: 'The label for the command details when the command has not been configured.',
  },
})
