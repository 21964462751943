import { useCallback, useState, type FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { useLocation, useMatches, useParams } from 'react-router'
import { trackMixpanelElementClickedEvent, trackMixpanelEvent } from '../../../features/analytics/analyticsSlice'
import { selectLastViewedResourceByOrganization } from '../../../features/lastResourceViewed/lastResourceViewedSelectors'
import { selectProfileEmail } from '../../../features/profile/profileSelectors'
import { selectLatestProject } from '../../../features/projects/projectsSelectors'
import {
  getContainerGroupsPagePath,
  getInferenceEndpointDetailsPagePath,
  getInferenceEndpointsMarketplacePagePath,
  getJobQueuesPagePath,
  getOrganizationTeamPagePath,
  getRecipeDeploymentsPagePath,
} from '../../../routes/routes-utils'
import { useAppDispatch, useAppSelector } from '../../../store'
import { contactSupportViaHelpScout } from '../../../utils'
import { MenuItem } from '../../block/MenuItem'
import { LeftNavigationBarMessages } from '../messages/LeftNavigationBarMessages'
import { AppSumoCodeModal } from './AppSumoCodeModal'

export const MenuItems: FunctionComponent = () => {
  const intl = useIntl()
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()
  const matches = useMatches()
  const {
    containerGroupName,
    inferenceEndpointName,
    jobQueueName,
    organizationName,
    projectName,
    recipeDeploymentName,
    recipeName,
  } = useParams()
  const [isAppSumoCodeModalOpen, setIsAppSumoCodeModalOpen] = useState(false)
  let isInferenceEndpointsPage = false
  const email = useAppSelector(selectProfileEmail)

  if (
    (organizationName && pathname === getInferenceEndpointsMarketplacePagePath(organizationName)) ||
    (organizationName &&
      inferenceEndpointName &&
      pathname === getInferenceEndpointDetailsPagePath(organizationName, inferenceEndpointName))
  ) {
    isInferenceEndpointsPage = true
  }

  const lastViewedProjectName = useAppSelector((state) =>
    selectLastViewedResourceByOrganization(state, organizationName || ''),
  )?.projectName
  const lastCreatedProjectName = useAppSelector((state) => selectLatestProject(state, organizationName || ''))?.name
  const defaultProjectName = lastViewedProjectName ?? lastCreatedProjectName
  const projName = isInferenceEndpointsPage ? defaultProjectName : projectName

  const pageRoutePath = matches?.[0]?.id

  const onRecordMixpanelElementClickedEvent = useCallback(
    (label: string) => {
      pageRoutePath &&
        dispatch(
          trackMixpanelElementClickedEvent({
            label,
            path: pageRoutePath,
            organizationName,
            projectName,
            recipeName,
            resourceName: containerGroupName ?? recipeDeploymentName,
            jobQueueName,
          }),
        )
    },
    [
      containerGroupName,
      dispatch,
      jobQueueName,
      organizationName,
      pageRoutePath,
      projectName,
      recipeDeploymentName,
      recipeName,
    ],
  )

  const handleHelpScoutFailedToOpenMixpanelEventTrack = useCallback(() => {
    pageRoutePath &&
      dispatch(
        trackMixpanelEvent({
          event: 'Event', // TODO: Change this to a more descriptive event name
          properties: {
            label: LeftNavigationBarMessages.helpScoutWidgetFailedMixpanelEventLabeL.defaultMessage,
            path: pageRoutePath,
            organizationName,
            projectName,
            recipeName,
            resourceName: containerGroupName ?? recipeDeploymentName,
            jobQueueName,
          },
        }),
      )
  }, [
    containerGroupName,
    dispatch,
    jobQueueName,
    organizationName,
    pageRoutePath,
    projectName,
    recipeDeploymentName,
    recipeName,
  ])

  const handleContactSupportClick = () => {
    onRecordMixpanelElementClickedEvent(LeftNavigationBarMessages.contactSupportButtonLabel.defaultMessage)
    contactSupportViaHelpScout(handleHelpScoutFailedToOpenMixpanelEventTrack)
  }

  const handleDocumentationClick = () => {
    onRecordMixpanelElementClickedEvent(LeftNavigationBarMessages.documentationNavigationLabel.defaultMessage)
  }

  const handleOpenAppSumoModal = () => {
    onRecordMixpanelElementClickedEvent(LeftNavigationBarMessages.appSumoCodeNavigationLabel.defaultMessage)
    setIsAppSumoCodeModalOpen(true)
  }

  return (
    <>
      <ul>
        {organizationName === undefined && projName === undefined && (
          <>
            <li>
              <MenuItem
                iconClassName="fa-solid fa-layer-group"
                label={intl.formatMessage(LeftNavigationBarMessages.organizationsNavigationLabel)}
                path="/organizations"
                variant="left-nav"
              />
            </li>
            <li>
              <MenuItem
                iconClassName="fa-solid fa-cog"
                label={intl.formatMessage(LeftNavigationBarMessages.accountSettingsNavigationLabel)}
                path="/account"
                variant="left-nav"
              />
            </li>
            <li>
              <MenuItem
                iconClassName="fa-solid fa-code"
                label={intl.formatMessage(LeftNavigationBarMessages.apiAccessNavigationLabel)}
                path="/api-key"
                variant="left-nav"
              />
            </li>
          </>
        )}

        {organizationName && projName && (
          <>
            <li>
              <MenuItem
                iconClassName="fa-solid fa-code"
                label={intl.formatMessage(LeftNavigationBarMessages.inferenceEndpointsNavigationLabel)}
                path={getInferenceEndpointsMarketplacePagePath(organizationName)}
                variant="left-nav"
              />
            </li>
            <li>
              <MenuItem
                iconClassName="fa-solid fa-boxes"
                label={intl.formatMessage(LeftNavigationBarMessages.containerGroupsNavigationLabel)}
                path={getContainerGroupsPagePath(organizationName, projName)}
                variant="left-nav"
              />
            </li>
            <li>
              <MenuItem
                iconClassName="fa-solid fa-window-restore"
                isFeaturePreview
                label={intl.formatMessage(LeftNavigationBarMessages.jobQueuesNavigationLabel)}
                path={getJobQueuesPagePath(organizationName, projName)}
                variant="left-nav"
              />
            </li>
            <li>
              <MenuItem
                iconClassName="fa-solid fa-utensils"
                label={intl.formatMessage(LeftNavigationBarMessages.recipeDeploymentsNavigationLabel)}
                path={getRecipeDeploymentsPagePath(organizationName, projName)}
                variant="left-nav"
              />
            </li>
          </>
        )}

        {organizationName && (
          <>
            <li>
              <MenuItem
                iconClassName="fa-solid fa-users"
                label={intl.formatMessage(LeftNavigationBarMessages.teamNavigationLabel)}
                path={getOrganizationTeamPagePath(organizationName)}
                variant="left-nav"
              />
            </li>
            <li>
              <MenuItem
                iconClassName="fa-solid fa-credit-card"
                label={intl.formatMessage(LeftNavigationBarMessages.billingAndUsageNavigationLabel)}
                path={`/organizations/${organizationName}/billing`}
                variant="left-nav"
              />
            </li>
          </>
        )}

        <li>
          <MenuItem
            iconClassName="fa-solid fa-book"
            label={intl.formatMessage(LeftNavigationBarMessages.documentationNavigationLabel)}
            externalLink="https://docs.salad.com/introduction"
            onClick={handleDocumentationClick}
            variant="left-nav"
          />
        </li>
        <li>
          <MenuItem
            iconClassName="fa-solid fa-headset"
            label={intl.formatMessage(LeftNavigationBarMessages.contactSupportButtonLabel)}
            onClick={handleContactSupportClick}
            variant="left-nav"
          />
        </li>
        {organizationName && projectName === undefined && (
          <li>
            <MenuItem
              iconClassName="fa-solid fa-ticket"
              label={intl.formatMessage(LeftNavigationBarMessages.appSumoCodeNavigationLabel)}
              onClick={handleOpenAppSumoModal}
              variant="left-nav"
            />
          </li>
        )}
      </ul>
      {isAppSumoCodeModalOpen && (
        <AppSumoCodeModal
          onCloseWindow={() => setIsAppSumoCodeModalOpen(false)}
          organizationName={organizationName}
          email={email}
        />
      )}
    </>
  )
}
