import type {
  BillingPaymentMethod,
  BillingPaymentMethodSetupIntentStatusEnum,
} from '@saladtechnologies/openapi-cloud-portal-browser'
import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import React, { Suspense, useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router'
import type { Tab } from '../../../../components/Tabs'
import { Tabs } from '../../../../components/Tabs'
import { LoadingPage } from '../../../../components/page/LoadingPage'
import { BillingMainContentMessages } from '../../messages'
import { BillingCredits } from '../BillingCredits'
import { BillingInvoices } from '../BillingInvoices'
import { BillingUsage } from '../BillingUsage'

interface BillingMainContentProps {
  /** The billing credits dashboard embed url. */
  billingCreditsEmbedUrl: string | undefined
  /** The invoice dashboard embed url. */
  billingInvoiceEmbedUrl: string | undefined
  /** The billing status. */
  billingStatus: BillingPaymentMethodSetupIntentStatusEnum
  /** The billing usage dashboard embed url. */
  billingUsageEmbedUrl: string | undefined
  /** The flag indicating if the organization has not had a valid payment before. */
  hasNotHadValidPayment: boolean
  /** The flag indicating if the request to create payment method session is pending. */
  isCreatePaymentSessionInProgress: boolean
  /** The flag indicating if the request to load billing customer portal is pending. */
  isLoadBillingCustomerPortalPending: boolean
  /** The flag indicating if the request to verify payment method is pending. */
  isPaymentMethodVerificationInProgress: boolean
  /** The flag indicating if the request to remove payment method is pending. */
  isRemovePaymentMethodPending: boolean
  /** The flag indicating if the request to remove payment method was successful. */
  isRemovePaymentMethodSuccessful: boolean
  /** The link to the form to request unrestricted access. */
  linkToRequestIncreaseForDeploymentQuotas: string
  /** The callback executed when the user opens the form to add or update payment method. */
  onManagePaymentMethod: () => void
  /**
   * A callback that when executed fetches a link that directs the user to their Metronome account where they could view
   * their statements.
   */
  onManageBilling: () => void
  /**
   * A callback that when executed records a mixpanel event for the element that was clicked.
   *
   * @param label The label of the element that was clicked
   */
  onRecordMixpanelElementClickedEvent: (label: string) => void
  /** The callback executed when the user clicks the remove payment method button. */
  onRemovePaymentMethod: () => void
  /** The callback handler for when the Stripe submit form hits an error. */
  onSubmitFormError: (message?: string, status?: string) => void
  /** The callback handler for when the Stripe submit form succeeded. */
  onSubmitFormSucceeded: () => void
  /** The organization display name. */
  organizationDisplayName: string
  /** The payment card last four digits. */
  paymentCardLastFourDigits: string
  /** The billing payment method. */
  paymentMethod: BillingPaymentMethod | undefined
  /** The billing payment method. */
  paymentMethodBillingClientSecret: string | undefined
}

const BillingInformation = React.lazy(() =>
  import('../BillingInformation').then((module) => ({ default: module.BillingInformation })),
)

export const BillingMainContent: FunctionComponent<BillingMainContentProps> = ({
  billingCreditsEmbedUrl,
  billingInvoiceEmbedUrl,
  billingStatus,
  billingUsageEmbedUrl,
  hasNotHadValidPayment,
  isCreatePaymentSessionInProgress,
  isLoadBillingCustomerPortalPending,
  isPaymentMethodVerificationInProgress,
  isRemovePaymentMethodPending,
  isRemovePaymentMethodSuccessful,
  linkToRequestIncreaseForDeploymentQuotas,
  onManageBilling,
  onManagePaymentMethod,
  onRecordMixpanelElementClickedEvent,
  onRemovePaymentMethod,
  onSubmitFormError,
  onSubmitFormSucceeded,
  organizationDisplayName,
  paymentCardLastFourDigits,
  paymentMethod,
  paymentMethodBillingClientSecret,
}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)

  let tabs: Tab[] = [
    {
      label: intl.formatMessage(BillingMainContentMessages.billingInformationTabLabel),
      content: (
        <Suspense fallback={<LoadingPage />}>
          <BillingInformation
            billingStatus={billingStatus}
            hasNotHadValidPayment={hasNotHadValidPayment}
            isLoadBillingCustomerPortalPending={isLoadBillingCustomerPortalPending}
            isRemovePaymentMethodPending={isRemovePaymentMethodPending}
            isRemovePaymentMethodSuccessful={isRemovePaymentMethodSuccessful}
            isPaymentMethodVerificationInProgress={isPaymentMethodVerificationInProgress}
            isCreatePaymentSessionInProgress={isCreatePaymentSessionInProgress}
            linkToRequestIncreaseForDeploymentQuotas={linkToRequestIncreaseForDeploymentQuotas}
            onManagePaymentMethod={onManagePaymentMethod}
            onManageBilling={onManageBilling}
            onRecordMixpanelElementClickedEvent={onRecordMixpanelElementClickedEvent}
            onRemovePaymentMethod={onRemovePaymentMethod}
            onSubmitFormError={onSubmitFormError}
            onSubmitFormSucceeded={onSubmitFormSucceeded}
            paymentCardLastFourDigits={paymentCardLastFourDigits}
            paymentMethod={paymentMethod}
            paymentMethodBillingClientSecret={paymentMethodBillingClientSecret}
          />
        </Suspense>
      ),
      onSelectTab: () => onRecordMixpanelElementClickedEvent('Billing Information tab'),
    },
    {
      label: intl.formatMessage(BillingMainContentMessages.invoicesTabLabel),
      content: (
        <BillingInvoices
          billingInvoiceEmbedUrl={billingInvoiceEmbedUrl}
          organizationDisplayName={organizationDisplayName}
        />
      ),
      onSelectTab: () => onRecordMixpanelElementClickedEvent('Invoices tab'),
    },
    {
      label: intl.formatMessage(BillingMainContentMessages.usageTabLabel),
      content: <BillingUsage embedUrl={billingUsageEmbedUrl} organizationDisplayName={organizationDisplayName} />,
      onSelectTab: () => onRecordMixpanelElementClickedEvent('Usage tab'),
    },
    {
      label: intl.formatMessage(BillingMainContentMessages.creditsTabLabel),
      content: (
        <BillingCredits
          billingCreditEmbedUrl={billingCreditsEmbedUrl}
          organizationDisplayName={organizationDisplayName}
        />
      ),
      onSelectTab: () => onRecordMixpanelElementClickedEvent('Credits tab'),
    },
  ]

  return (
    <div className="w-full">
      <div className="mb-5">
        <button onClick={() => navigate(-1)} className="text-blue-90 underline">
          <span className={classNames('fa-solid fa-arrow-left mr-2')} />
          {intl.formatMessage(BillingMainContentMessages.returnToPreviousPageLinkText)}
        </button>
      </div>
      <h1 className="mb-3 text-3xl font-bold text-neutral-100">
        {intl.formatMessage(BillingMainContentMessages.title, {
          organization_display_name: organizationDisplayName,
        })}
      </h1>
      <p className="mb-3 text-base text-neutral-100">{intl.formatMessage(BillingMainContentMessages.description)}</p>
      <Tabs tabs={tabs} selectedTabIndex={selectedTabIndex} onSelectedTabIndexChange={setSelectedTabIndex} />
    </div>
  )
}
