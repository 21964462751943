import { defineMessages } from 'react-intl'

export const DeleteJobQueueModalMessages = defineMessages({
  cancelButtonLabel: {
    defaultMessage: 'Cancel',
    id: 'jX9yDOx9',
    description: 'The label for the Cancel button on the modal that shows when a user wants to delete a job queue.',
  },
  description: {
    defaultMessage: "Deleting a job queue is permanent. Are you sure you'd like to delete this job queue?",
    id: 'C2D2Qhki',
    description: 'The description for the modal that shows when a user wants to delete a job queue.',
  },
  descriptionWithConnectedContainerGroups: {
    defaultMessage:
      "Deleting a job queue is permanent. This job queue is connected to the following container groups: {connected_container_groups}. Upon deleting the job queue, the container groups will continue to run but will be inaccessible from any job queues. Are you sure you'd like to delete this deployment?",
    id: 'QN+tSSEH',
    description: 'The description for the modal that shows when a user wants to delete a job queue.',
  },
  title: {
    defaultMessage: 'Delete {display_name}',
    id: 'n8jcDFqw',
    description: 'The title for the modal that shows when a user wants to delete a job queue.',
  },
})
