import type { FunctionComponent } from 'react'
import { ResetPasswordPage } from '../../features/resetPassword/models'
import { selectResetPasswordCurrentPage } from '../../features/resetPassword/resetPasswordSelectors'
import { useAppSelector } from '../../store'
import { ConnectedResetPasswordLinkExpiredPage } from './ConnectedResetPasswordLinkExpiredPage'
import { ConnectedResetPasswordSucceeded } from './ConnectedResetPasswordSucceeded'
import { ConnectedUpdatePasswordPage } from './ConnectedUpdatePasswordPage'

const renderPage = (currentPage: ResetPasswordPage) => {
  switch (currentPage) {
    case ResetPasswordPage.ResetPassword:
      return <ConnectedUpdatePasswordPage />
    case ResetPasswordPage.ResetPasswordSuccessful:
      return <ConnectedResetPasswordSucceeded />
    case ResetPasswordPage.ResetPasswordLinkExpired:
      return <ConnectedResetPasswordLinkExpiredPage />
    default:
      return null
  }
}

export const ResetPasswordPageContainer: FunctionComponent = () => {
  const currentPage = useAppSelector(selectResetPasswordCurrentPage)

  return renderPage(currentPage)
}
