import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { Button } from '../../../../components/Button'
import { Modal } from '../../../../components/block/Modal'
import { BillingInformationModalMissingMessages } from '../../messages/BillingInformationMissingModalMessages'

interface BillingInformationMissingModalProps {
  /** The callback executed when the user clicks the `Cancel` button. */
  onCloseWindow: () => void
  /** The callback executed when the user clicks the `Enter Billing Information` button. */
  onEnterBillingInformation: () => void
}

export const BillingInformationMissingModal: FunctionComponent<BillingInformationMissingModalProps> = ({
  onCloseWindow,
  onEnterBillingInformation,
}) => {
  const intl = useIntl()

  return (
    <Modal onClose={onCloseWindow} title={intl.formatMessage(BillingInformationModalMissingMessages.title)}>
      <h1 className="mb-8 text-2xl font-bold">{intl.formatMessage(BillingInformationModalMissingMessages.title)}</h1>
      <p className="mb-8">{intl.formatMessage(BillingInformationModalMissingMessages.body)}</p>
      <div className="flex max-w-lg gap-6">
        <Button variant="green-outlined" onClick={onCloseWindow} isFullWidth>
          {intl.formatMessage(BillingInformationModalMissingMessages.cancelButtonLabel)}
        </Button>
        <Button variant="green-filled" onClick={onEnterBillingInformation} isFullWidth>
          {intl.formatMessage(BillingInformationModalMissingMessages.enterBillingInformationButtonLabel)}
        </Button>
      </div>
    </Modal>
  )
}
