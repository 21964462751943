import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { InlineNotification } from '../../block/InlineNotification'
import { FeaturePreviewInlineNotificationMessages } from '../messages'

export const FeaturePreviewInlineNotification: FunctionComponent = () => {
  const intl = useIntl()
  return (
    <InlineNotification
      body={intl.formatMessage(FeaturePreviewInlineNotificationMessages.body)}
      title={intl.formatMessage(FeaturePreviewInlineNotificationMessages.title)}
      type="info"
    />
  )
}
