import type { ReactNode } from 'react'
import type { IntlShape } from 'react-intl'
import { HelpScoutSupportLink } from '../../../components/block/HelpScoutSupportLink'
import type { ToastNotificationType } from '../../models'
import { EditOrganizationGeneralErrorContentMessages } from './messages'

/**
 * Retrieves the content needed for an error `ToastNotification` that is shown when the user encounters an error when
 * attempting to edit their organization details.
 *
 * @param intl The `IntlShape` object needed to format the messages.
 */
export const getEditOrganizationGeneralErrorContent = (
  intl: IntlShape,
): {
  body: ReactNode
  type: ToastNotificationType
  title: string
} => {
  return {
    body: intl.formatMessage(EditOrganizationGeneralErrorContentMessages.body, {
      contact_support: (
        <HelpScoutSupportLink
          linkedText={intl.formatMessage(EditOrganizationGeneralErrorContentMessages.contactSupportLinkText)}
          classNames="text-red-70 underline"
        />
      ),
    }),
    type: 'error',
    title: intl.formatMessage(EditOrganizationGeneralErrorContentMessages.title),
  }
}
