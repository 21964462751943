import { defineMessages } from 'react-intl'

export const GetJobQueuesErrorMessages = defineMessages({
  body: {
    defaultMessage:
      'We’re unable to retrieve the job queues at this time. Try again and if this issue persists {contact_support}.',
    id: 'Xa2gBQXA',
    description:
      'The description shown in a toast notification message shown when we are unable to find the job queues.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: '1RwPFKxe',
    description: 'The text shown for the contact support link in the get job queues error toast notification.',
  },
  title: {
    defaultMessage: 'Unable to Retrieve Job Queues',
    id: '0RxvWZFq',
    description:
      'The toast notification title shown when we encounter an error and are unable to retrieve the job queues.',
  },
})
