import { defineMessages } from 'react-intl'

export const DeleteAccountRequestFailedToastContentMessages = defineMessages({
  body: {
    defaultMessage: 'We were unable to delete your account. Try again and if this issue persists {contact_support}.',
    id: 'g/9b4jhg',
    description: `The body of the error message for when we are unable to delete a user's account.`,
  },
  contactSupportLinkCopy: {
    defaultMessage: 'contact support',
    id: 'A60Ut0pC',
    description: 'A copy shown for the link to contact support in the delete notification body when the request fails.',
  },
  title: {
    defaultMessage: 'Error deleting account',
    id: 'fJFmXnsr',
    description: `The title shown in the error toast notification for when we are unable to delete a user's account.`,
  },
})
