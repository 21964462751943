import { defineMessages } from 'react-intl'

export const DeleteProjectSucceededContentMessages = defineMessages({
  body: {
    defaultMessage: 'Projects and deployments for {project_name} will no longer be accessible',
    id: 'JXkJwERU',
    description:
      'The description shown in a toast notification message shown when the user successfully deletes their project.',
  },
  title: {
    defaultMessage: 'The project {project_name} has been deleted',
    id: 'PUUQ5hhM',
    description: 'The toast notification title shown when the user successfully deletes their project.',
  },
})
