import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { CommandDetailsMessages } from '../messages'
import { configureCommand } from './utils'

interface CommandDetailsProps {
  /** The command for the container group. */
  command?: string
  /** The command arguments for the container group. */
  args?: { argument?: string }[]
  /** The click event that is executed when clicking the command. */
  onClick?: () => void
}

export const CommandDetails: FunctionComponent<CommandDetailsProps> = ({ args, command, onClick }) => {
  const intl = useIntl()
  const commandText = configureCommand(command, args)

  return (
    <>
      {commandText ? (
        <div className="flex">
          {onClick ? (
            <button className="truncate text-blue-90 underline" onClick={onClick}>
              {commandText}
            </button>
          ) : (
            <p className="truncate font-medium">
              {intl.formatMessage(CommandDetailsMessages.commandWithLabel, {
                command: <span className="font-normal">{commandText}</span>,
              })}
            </p>
          )}
        </div>
      ) : (
        <p>{intl.formatMessage(CommandDetailsMessages.noneConfiguredLabel)}</p>
      )}
    </>
  )
}
