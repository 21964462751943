import type { FieldErrors, FieldValues } from 'react-hook-form'
import { CreateContainerGroupField } from '../../models'

export const checkIfIContainerGatewayFieldsHaveErrors = (errors: FieldErrors<FieldValues>): boolean => {
  return errors[CreateContainerGroupField.CONTAINER_GATEWAY_PORT] !== undefined
}

export const containerGatewayFieldsList: string[] = [
  CreateContainerGroupField.CONTAINER_GATEWAY_ENABLED,
  CreateContainerGroupField.CONTAINER_GATEWAY_PORT,
  CreateContainerGroupField.CONTAINER_GATEWAY_REQUIRES_AUTHENTICATION,
]
