import { defineMessages } from 'react-intl'

export const NoOrganizationsAvailableMessages = defineMessages({
  createNewOrganizationButtonLabel: {
    defaultMessage: 'Create a New Organization',
    id: '+E7Zojl2',
    description: 'The label for the create organization button.',
  },
  subMessage: {
    defaultMessage: 'Start one to configure your own projects and containers',
    id: 'MXrMZECn',
    description: 'The  description for the no organizations page.',
  },
  mainMessage: {
    defaultMessage: `Seems like you're not part of an organization`,
    id: 'odBxq92E',
    description: 'The main text for the no organizations page.',
  },
})
