import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { EmptyResult } from '../../../../components/page/EmptyResult'
import { FailedToLoadInferenceEndpointsMarketplaceMessages } from '../../messages'

interface FailedToLoadInferenceEndpointsMarketplaceProps {
  /** The callback executed when the user clicks the try again button. */
  onReloadPage: () => void
}

export const FailedToLoadInferenceEndpointsMarketplace: FunctionComponent<
  FailedToLoadInferenceEndpointsMarketplaceProps
> = ({ onReloadPage }) => {
  const intl = useIntl()
  return (
    <EmptyResult
      buttonLabel={intl.formatMessage(FailedToLoadInferenceEndpointsMarketplaceMessages.tryAgainButtonLabel)}
      mainMessage={intl.formatMessage(FailedToLoadInferenceEndpointsMarketplaceMessages.message)}
      onButtonClick={onReloadPage}
    />
  )
}
