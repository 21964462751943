import { defineMessages } from 'react-intl'

export const UnableToRetrieveContainerGroupsContentMessages = defineMessages({
  body: {
    defaultMessage: 'We were unable to retrieve your container groups. If this issue persists {contact_support}.',
    id: '/RlAmRri',
    description:
      'The description shown in a toast notification message shown when we encounter an error retireving container groups.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'CR0uwWxm',
    description:
      'The text shown for the contact support link in the unable to load container groups error toast notification.',
  },
  title: {
    defaultMessage: 'Unable to retrieve container groups',
    id: 'x/Xs4zrB',
    description: 'The toast notification title shown when we encounter an error retireving container groups.',
  },
})
