import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { CreateRecipeDeployment, Recipe } from '@saladtechnologies/openapi-cloud-portal-browser'
import { setRecipe } from '../../features/recipeDetails/recipeDetailsSlice'
import { RequestStatus } from '../../models'
import { getRecipeCostPerHour } from '../../utils'

export interface createRecipeDeploymentState {
  costPerHour: number
  estimatedCostPerHour: number
  isRecipeQuotaExceeded: boolean
  recipe: Recipe | undefined
  requestStatus: RequestStatus
}

const initialState: createRecipeDeploymentState = {
  costPerHour: 0,
  estimatedCostPerHour: 0,
  isRecipeQuotaExceeded: false,
  recipe: undefined,
  requestStatus: RequestStatus.Idle,
}

export const createRecipeDeploymentSlice = createSlice({
  name: 'createRecipeDeployment',
  initialState,
  reducers: {
    createRecipeDeployment(
      _state,
      _action: PayloadAction<{
        organizationName: string
        projectName: string
        createRecipeDeployment: CreateRecipeDeployment
      }>,
    ) {},
    setCostPerHour(state, action: PayloadAction<{ replicas: number }>) {
      state.estimatedCostPerHour = state.costPerHour * action.payload.replicas
    },
    setCreateRecipeDeploymentRequestStatus(state, action: PayloadAction<{ status: RequestStatus }>) {
      state.requestStatus = action.payload.status
    },
    setIsRecipeQuotaExceeded(state, action: PayloadAction<{ exceeded: boolean }>) {
      state.isRecipeQuotaExceeded = action.payload.exceeded
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setRecipe, (state, action) => {
      state.recipe = action.payload.recipe
      state.costPerHour = getRecipeCostPerHour(
        action.payload.recipe.resources,
        action.payload.gpuPrice,
        action.payload.ramOptions,
      )
      state.estimatedCostPerHour = getRecipeCostPerHour(
        action.payload.recipe.resources,
        action.payload.gpuPrice,
        action.payload.ramOptions,
      )
    })
  },
})

export const {
  createRecipeDeployment,
  setCostPerHour,
  setCreateRecipeDeploymentRequestStatus,
  setIsRecipeQuotaExceeded,
} = createRecipeDeploymentSlice.actions
