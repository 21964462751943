import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { Button } from '../../../../components/Button'
import { ButtonLink } from '../../../../components/base'
import { Modal } from '../../../../components/block/Modal'
import { RecipeQuotaExceededModalMessages } from '../../messages'

export interface RecipeQuotaExceededModalProps {
  /** The link for users to request an increase to their recipe quota */
  linkToRequestIncreaseForRecipeDeploymentQuotas: string
  /** The callback executed when the user clicks the `Cancel` button. */
  onCancel: () => void
}

export const RecipeQuotaExceededModal: FunctionComponent<RecipeQuotaExceededModalProps> = ({
  linkToRequestIncreaseForRecipeDeploymentQuotas,
  onCancel,
}) => {
  const intl = useIntl()

  return (
    <Modal onClose={onCancel} title={intl.formatMessage(RecipeQuotaExceededModalMessages.title)}>
      <h1 className="mb-8 text-3xl font-bold">{intl.formatMessage(RecipeQuotaExceededModalMessages.title)}</h1>
      <p className="mb-8">{intl.formatMessage(RecipeQuotaExceededModalMessages.description1)}</p>
      <p className="mb-8">{intl.formatMessage(RecipeQuotaExceededModalMessages.description2)}</p>
      <div className="flex max-w-md gap-6">
        <Button variant="green-outlined" onClick={onCancel} isFullWidth>
          {intl.formatMessage(RecipeQuotaExceededModalMessages.cancelButtonLabel)}
        </Button>
        <ButtonLink isFullWidth url={linkToRequestIncreaseForRecipeDeploymentQuotas}>
          {intl.formatMessage(RecipeQuotaExceededModalMessages.requestIncreaseButtonLabel)}
        </ButtonLink>
      </div>
    </Modal>
  )
}
