import type { Recipe } from '@saladtechnologies/openapi-cloud-portal-browser'
import type { FunctionComponent } from 'react'
import { LeftNavigationBar } from '../../components/LeftNavigationBar'
import type { ProjectSelectOptions } from '../../components/LeftNavigationBar/models'
import { ThreeColumnLayout } from '../../layouts/threeColumnLayout'
import { FailedToLoadRecipeMarketplace } from './components/FailedToLoadRecipeMarketplace/FailedToLoadRecipeMarketplace'
import { RecipeMarketplaceMainContent } from './components/RecipeMarketplaceMainContent/RecipeMarketplaceMainContent'

export interface RecipeMarketplacePageProps {
  /** The current organization that the user is in. */
  currentOrganization: { name: string; displayName: string }
  /** The current project that the user is in. */
  currentProject: { name: string; displayName: string }
  /** The callback executed when the user clicks the contact support button. */
  isDeleteProjectPending: boolean
  /** The flag indicating that the request to delete the project is successful. */
  isDeleteProjectSuccessful: boolean
  /** The flag indicating if there was an error loading the recipe marketplace. */
  isErrorLoadingRecipeMarketplace: boolean
  /** The flag indicating if the left column is showing. */
  isLeftColumnOpen: boolean
  /** The callback executed when the user closes the left drawer */
  onCloseLeftDrawer: () => void
  /** The callback called when the user deletes the project. */
  onDeleteProject: (projectName: string) => void
  /**
   * The handler for when something new is selected in the project dropdown. If the current active project is selected,
   * nothing will happen.
   */
  onProjectChange: (projectName: string) => void
  /** The callback that loads the page again */
  onReloadPage: () => void
  /** An array of the current available projects. If empty, we will default to no select bar being displayed */
  projects: ProjectSelectOptions
  /** The list of recipes available in the marketplace */
  recipeList: Recipe[]
}

export const RecipeMarketplacePage: FunctionComponent<RecipeMarketplacePageProps> = ({
  currentOrganization,
  currentProject,
  isDeleteProjectPending,
  isDeleteProjectSuccessful,
  isErrorLoadingRecipeMarketplace,
  isLeftColumnOpen,
  onCloseLeftDrawer,
  onDeleteProject,
  onProjectChange,
  onReloadPage,
  projects,
  recipeList,
}) => {
  return (
    <ThreeColumnLayout
      isLeftColumnOpen={isLeftColumnOpen}
      LeftColumn={
        <LeftNavigationBar
          currentOrganization={currentOrganization}
          currentProject={currentProject}
          isDeleteProjectPending={isDeleteProjectPending}
          isDeleteProjectSuccessful={isDeleteProjectSuccessful}
          onDeleteProject={onDeleteProject}
          onProjectChange={onProjectChange}
          projects={projects}
        />
      }
      onCloseLeftDrawer={onCloseLeftDrawer}
      MainColumn={
        isErrorLoadingRecipeMarketplace ? (
          <FailedToLoadRecipeMarketplace onReloadPage={onReloadPage} />
        ) : (
          <RecipeMarketplaceMainContent
            recipeList={recipeList}
            organizationName={currentOrganization.name}
            projectName={currentProject?.name}
          />
        )
      }
    />
  )
}
