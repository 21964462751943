import { yupResolver } from '@hookform/resolvers/yup'
import classNames from 'classnames'
import { useState, type FunctionComponent } from 'react'
import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Button } from '../../../../components/Button'
import { TextField } from '../../../../components/TextField'
import { Link } from '../../../../components/base'
import { useFixedForm } from '../../../../hooks'
import { EditJobQueueFormMessages } from '../../messages'
import { useEditJobQueueFormValidation } from './useEditJobQueueFormValidation'

export interface EditJobQueueFormValues {
  /** The job queue description. */
  description?: string
  /** The job queue display name. */
  displayName: string
}

interface EditJobQueueFormProps {
  /** The job queue description. */
  description?: string | null
  /** The job queue display name. */
  displayName: string
  /** The flag indicating that the request to edit a job queue is pending. */
  isEditJobQueuePending: boolean
  /** The path for the Job Queue Details Page. */
  jobQueueDetailsPath: string
  /** A callback that when executed edits a job queue. */
  onEditJobQueue: (displayName: string, description?: string) => void
  /** The job queue name. */
  name: string
}

export const EditJobQueueForm: FunctionComponent<EditJobQueueFormProps> = ({
  description,
  displayName,
  isEditJobQueuePending,
  jobQueueDetailsPath,
  name,
  onEditJobQueue,
}) => {
  const intl = useIntl()
  const editJobQueueValidationScheme = useEditJobQueueFormValidation()
  const { control, handleSubmit, register } = useFixedForm<EditJobQueueFormValues>({
    resolver: yupResolver(editJobQueueValidationScheme),
    onSubmit: ({ description, displayName }) => onEditJobQueue(displayName.trim(), description?.trim()),
  })

  const [isDifferentDisplayName, toggleIsDifferentDisplayName] = useState<boolean>(false)
  const [isDifferentDescription, toggleIsDifferentDescription] = useState<boolean>(false)

  const handleDisplayNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const displayNameChanged = event.target.value.trim() !== displayName
    toggleIsDifferentDisplayName(displayNameChanged)
  }
  const handleDescriptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const descriptionChanged = event.target.value.trim() !== description
    toggleIsDifferentDescription(descriptionChanged)
  }

  return (
    <div className="w-full max-w-xl">
      <Link url={jobQueueDetailsPath}>
        <span className={classNames('fa-solid fa-arrow-left mr-2')} />
        {intl.formatMessage(EditJobQueueFormMessages.backToJobQueueDetails, {
          job_queue_display_name: displayName,
        })}
      </Link>

      <h1 className="mb-10 mt-5 text-3xl font-bold">{intl.formatMessage(EditJobQueueFormMessages.title)}</h1>

      <h2 className="mb-2 text-base font-bold">{intl.formatMessage(EditJobQueueFormMessages.slugNameHeader)}</h2>
      <p className="mb-2 text-xl font-bold text-green-80">{name}</p>
      <p className="mb-10 text-base">{intl.formatMessage(EditJobQueueFormMessages.slugNameDescription)}</p>

      <form onSubmit={handleSubmit}>
        <Controller
          defaultValue={displayName}
          name="displayName"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...register('displayName', { onChange: handleDisplayNameChange })}
              {...field}
              {...fieldState}
              defaultValue={field.value}
              helperText={intl.formatMessage(EditJobQueueFormMessages.displayNameHelperText)}
              placeholder={intl.formatMessage(EditJobQueueFormMessages.displayNameFieldPlaceholder)}
              label={intl.formatMessage(EditJobQueueFormMessages.displayNameFieldLabel)}
              isFullWidth
            />
          )}
        />

        <Controller
          defaultValue={description}
          name="description"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...register('description', { onChange: handleDescriptionChange })}
              {...field}
              {...fieldState}
              defaultValue={field.value}
              helperText={intl.formatMessage(EditJobQueueFormMessages.descriptionHelperText)}
              placeholder={intl.formatMessage(EditJobQueueFormMessages.descriptionFieldPlaceholder)}
              label={intl.formatMessage(EditJobQueueFormMessages.descriptionFieldLabel)}
              isFullWidth
            />
          )}
        />
        <Button
          type="submit"
          isLoading={isEditJobQueuePending}
          isDisabled={(!isDifferentDisplayName && !isDifferentDescription) || isEditJobQueuePending}
          isFullWidth
        >
          {intl.formatMessage(EditJobQueueFormMessages.saveJobQueueButtonLabel)}
        </Button>
      </form>
    </div>
  )
}
