import { defineMessages } from 'react-intl'

export const SuccessfullyRecreatedDeploymentInstanceMessages = defineMessages({
  title: {
    defaultMessage: 'Instance Recreated',
    id: 'qQtRMeeI',
    description: 'The toast notification title shown when the deployment instance is successfully recreated',
  },
  body: {
    defaultMessage: 'The instance has successfully been recreated',
    id: '/B9c/CRU',
    description: 'The toast notification description shown when the deployment instance is successfully recreated',
  },
})
