import { defineMessages } from 'react-intl'

export const GenericStripeFormErrorMessages = defineMessages({
  body: {
    defaultMessage: '{error_message}',
    id: 'qyvYw1BZ',
    description:
      'The description in a toast notification message shown when we encounter Stripe form error with adding new payment method.',
  },
  title: {
    defaultMessage: 'Payment method error',
    id: 'ONv5V9LO',
    description:
      'The toast notification title shown when we encounter Stripe form error with adding new payment method.',
  },
})
