import type { FunctionComponent, ReactElement } from 'react'
import { Children, cloneElement } from 'react'

interface SvgIconProps {
  /** The SVG icon. */
  children: ReactElement
  /** The size that will represent both the height and width of the SVG icon. */
  size: number
}

// TODO: Add ability to change color of SVG icon via a classname
export const SvgIcon: FunctionComponent<SvgIconProps> = ({ children, size }) => {
  return <SvgIconClone size={size}>{children}</SvgIconClone>
}

interface SvgIconCloneProps {
  children: ReactElement
  size?: number
}

const SvgIconClone: FunctionComponent<SvgIconCloneProps> = ({ children, size }) => {
  return cloneElement(Children.only(children), {
    width: size,
    height: size,
  })
}
