import type { FunctionComponent } from 'react'
import type { Control, FieldValues } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { TextField } from '../../../../../../components/TextField'
import { ButtonLink } from '../../../../../../components/base'
import { CreateContainerGroupMainContentMessages, GitHubInputFieldsMessages } from '../../../../messages'
import type { CreateContainerGroupValues } from '../../../../models'
import { CreateContainerGroupField } from '../../../../models'

interface GitHubInputFieldsProps {
  /** The control for the create container group react hook form. */
  control: Control<FieldValues, CreateContainerGroupValues>
}

export const GitHubInputFields: FunctionComponent<GitHubInputFieldsProps> = ({ control }) => {
  const intl = useIntl()

  return (
    <>
      <Controller
        name={CreateContainerGroupField.GIT_HUB_USERNAME}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            {...fieldState}
            helperText={fieldState.error?.message}
            label={intl.formatMessage(GitHubInputFieldsMessages.usernameLabel)}
            placeholder={intl.formatMessage(GitHubInputFieldsMessages.usernamePlaceholderText)}
            type="text"
            isFullWidth
          />
        )}
      />
      <Controller
        name={CreateContainerGroupField.GIT_HUB_PERSONAL_ACCESS_TOKEN}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            {...fieldState}
            helperText={fieldState.error?.message}
            label={intl.formatMessage(GitHubInputFieldsMessages.personalAccessTokenLabel)}
            placeholder={intl.formatMessage(GitHubInputFieldsMessages.personalAccessTokenPlaceholderText)}
            type="password"
            isFullWidth
          />
        )}
      />

      <div className="pb-16">
        <p className="mb-2">{intl.formatMessage(GitHubInputFieldsMessages.needHelpText)}</p>
        <ButtonLink url="https://docs.salad.com/container-engine/registries/github-ghcr" variant="blue-filled-light">
          {intl.formatMessage(CreateContainerGroupMainContentMessages.learnMoreButtonLabel)}
        </ButtonLink>
      </div>
    </>
  )
}
