import { yupResolver } from '@hookform/resolvers/yup'
import type { FunctionComponent } from 'react'
import { useState } from 'react'
import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Button } from '../../../../components/Button'
import { ButtonMessages } from '../../../../components/Button/messages'
import { TextField } from '../../../../components/TextField'
import { Modal } from '../../../../components/block/Modal'
import { useFixedForm } from '../../../../hooks'
import { DeleteOrganizationModalMessages } from '../../messages/DeleteOrganizationModalMessages'
import { useDeleteOrganizationFormValidation } from './useDeleteOrganizationFormValidation'

export interface DeleteOrganizationValues {
  /** The organization name. */
  organizationName: string
}

export interface DeleteOrganizationModalProps {
  /** The organization name. */
  organizationName: string
  /** The flag indicating that the request to delete the organization is pending. */
  isDeleteOrganizationPending: boolean
  /** The callback executed when the user clicks the `Cancel` button. */
  onCancel: () => void
  /**
   * The callback executed when the user clicks the `Delete` button.
   *
   * @param organizationName The name of the organization that is being deleted.
   */
  onDeleteOrganization: (organizationName: string) => void
}

export const DeleteOrganizationModal: FunctionComponent<DeleteOrganizationModalProps> = ({
  isDeleteOrganizationPending,
  onCancel,
  onDeleteOrganization,
  organizationName,
}) => {
  const intl = useIntl()
  const [isAbleToDeleteOrganization, toggleIsAbleToDeleteOrganization] = useState<boolean>(false)
  const deleteOrganizationValidationScheme = useDeleteOrganizationFormValidation(organizationName)

  const { control, getValues, handleSubmit, register } = useFixedForm<DeleteOrganizationValues>({
    resolver: yupResolver(deleteOrganizationValidationScheme),
    onSubmit: () => onDeleteOrganization(organizationName),
  })

  const handleOnChange = () => {
    const textFieldMatchesorganization = getValues('organizationName') === organizationName
    toggleIsAbleToDeleteOrganization(textFieldMatchesorganization)
  }
  return (
    <Modal
      onClose={onCancel}
      title={intl.formatMessage(DeleteOrganizationModalMessages.title, {
        organization_name: organizationName,
      })}
    >
      <h1 className="mb-8 text-2xl font-bold">
        {intl.formatMessage(DeleteOrganizationModalMessages.title, {
          organization_name: organizationName,
        })}
      </h1>
      <p className="mb-8">
        {intl.formatMessage(DeleteOrganizationModalMessages.description, {
          organization_name: <span className="text-green-80">{organizationName}</span>,
        })}
      </p>
      <form onSubmit={handleSubmit}>
        <Controller
          name="organizationName"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...register('organizationName', { onChange: handleOnChange })}
              {...field}
              {...fieldState}
              placeholder={organizationName}
              label={intl.formatMessage(DeleteOrganizationModalMessages.organizationInputLabel)}
              type="text"
              isFullWidth
            />
          )}
        />
        <div className="flex max-w-md gap-6">
          <Button type="button" variant="green-outlined" onClick={onCancel} isFullWidth>
            {intl.formatMessage(DeleteOrganizationModalMessages.cancelButtonLabel)}
          </Button>
          <Button
            variant="red-filled"
            type="submit"
            isDisabled={!isAbleToDeleteOrganization || isDeleteOrganizationPending}
            isLoading={isDeleteOrganizationPending}
            isFullWidth
          >
            {intl.formatMessage(ButtonMessages.deleteButtonLabel)}
          </Button>
        </div>
      </form>
    </Modal>
  )
}
