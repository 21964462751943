import classNames from 'classnames'
import type { FunctionComponent, MouseEvent } from 'react'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import type { SpinnerColor } from '../Spinner'
import { Spinner } from '../Spinner'
import { ButtonMessages } from './messages/ButtonMessages'

export type ButtonVariant =
  | 'green-filled'
  | 'green-filled-light'
  | 'green-outlined'
  | 'green-text'
  | 'blue-filled'
  | 'blue-filled-light'
  | 'blue-outlined'
  | 'blue-text'
  | 'red-filled'
  | 'red-filled-light'
  | 'red-outlined'
  | 'red-text'
  | 'neutral-filled'
  | 'neutral-outlined'
  | 'neutral-text'

interface ButtonProps {
  /** The aria label for the button. */
  ariaLabel?: React.AriaAttributes['aria-label']
  /** The label for the button. */
  children?: React.ReactNode
  /** A value indicating whether the button is a countdown button. */
  countdownSeconds?: number
  /** An optinal form button identifier. */
  form?: React.ButtonHTMLAttributes<HTMLButtonElement>['form']
  /** A Font Awesome icon class name (e.g. `fa-envelope`) that will show to the left of the button label. */
  iconClassName?: string
  /** A value indicating whether the padding is compact. */
  isCompact?: boolean
  /** The flag indicating that the button is disabled. */
  isDisabled?: boolean
  /** The flag indicating if the Text Field should occupy all available width provided by the parent element. */
  isFullWidth?: boolean
  /** The flag indicating the button is associated with some ongoing processing. */
  isLoading?: boolean
  /** The button click handler. */
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void
  /** The aria role for the button. */
  role?: React.AriaRole | undefined
  /** The button type. */
  type?: 'button' | 'reset' | 'submit' | undefined
  /** A value indicating which styles to apply to the button. */
  variant?: ButtonVariant
}

export const getButtonTextColor = (variant: ButtonVariant) => {
  switch (variant) {
    case 'green-filled':
      return 'text-green-20'

    case 'green-filled-light':
    case 'green-outlined':
    case 'green-text':
      return 'text-green-90'

    case 'blue-filled':
      return 'text-blue-20'

    case 'blue-filled-light':
    case 'blue-outlined':
    case 'blue-text':
      return 'text-blue-90'

    case 'red-filled':
      return 'text-red-20'

    case 'red-filled-light':
    case 'red-outlined':
    case 'red-text':
      return 'text-red-90'

    case 'neutral-filled':
    case 'neutral-outlined':
    case 'neutral-text':
      return 'text-neutral-40'
  }
}

export const buttonVariantStyles: Record<ButtonVariant, string> = {
  'green-filled': `bg-green-90 ${getButtonTextColor('green-filled')}`,
  'green-filled-light': `bg-green-10 ${getButtonTextColor('green-filled-light')}`,
  'green-outlined': `border border-green-90 bg-transparent ${getButtonTextColor('green-outlined')}`,
  'green-text': `bg-transparent ${getButtonTextColor('green-text')}`,
  'blue-filled': `bg-blue-90 ${getButtonTextColor('blue-filled')}`,
  'blue-filled-light': `bg-blue-20 ${getButtonTextColor('blue-filled-light')}`,
  'blue-outlined': `border border-blue-90 bg-transparent ${getButtonTextColor('blue-outlined')}`,
  'blue-text': `bg-transparent ${getButtonTextColor('blue-text')}`,
  'red-filled': `bg-red-90 ${getButtonTextColor('red-filled')}`,
  'red-filled-light': `bg-red-20 ${getButtonTextColor('red-filled-light')}`,
  'red-outlined': `border border-red-90 bg-transparent ${getButtonTextColor('red-outlined')}`,
  'red-text': `bg-transparent ${getButtonTextColor('red-text')}`,
  'neutral-filled': `bg-neutral-20 ${getButtonTextColor('neutral-filled')}`,
  'neutral-outlined': `border border-neutral-40 bg-transparent ${getButtonTextColor('neutral-outlined')}`,
  'neutral-text': `bg-transparent ${getButtonTextColor('neutral-outlined')}`,
}

const getButtonDisabledVariant = (variant: ButtonVariant) => {
  if (variant.includes('text')) return 'neutral-text'
  if (variant.includes('outlined')) return 'neutral-outlined'
  return 'neutral-filled'
}

const getButtonSpinnerColor = (variant: ButtonVariant): SpinnerColor => {
  if (variant.includes('green')) return 'green'
  if (variant.includes('blue')) return 'blue'
  if (variant.includes('red')) return 'red'
  if (variant.includes('neutral')) return 'neutral'
  return 'green'
}

export const Button: FunctionComponent<ButtonProps> = ({
  ariaLabel,
  children,
  countdownSeconds,
  form,
  iconClassName,
  isCompact,
  isDisabled,
  isFullWidth,
  isLoading,
  onClick,
  role,
  type,
  variant,
}) => {
  const [secondsRemaining, setSecondsRemaining] = useState<number>(countdownSeconds || 0)
  const intl = useIntl()

  useEffect(() => {
    const interval =
      secondsRemaining !== 0
        ? setInterval(() => {
            setSecondsRemaining(secondsRemaining - 1)
            if (secondsRemaining === 0) {
              clearInterval(interval)
            }
          }, 1000)
        : undefined

    return () => clearInterval(interval)
  }, [secondsRemaining])

  const isButtonDisabledDueToCountdown = secondsRemaining !== 0
  const buttonVariant =
    isDisabled || isButtonDisabledDueToCountdown
      ? getButtonDisabledVariant(variant ?? 'green-filled')
      : (variant ?? 'green-filled')

  return (
    <button
      aria-label={ariaLabel}
      form={form}
      role={role}
      type={type}
      disabled={(isButtonDisabledDueToCountdown || isDisabled) ?? isLoading}
      className={classNames(
        'relative flex items-center justify-center rounded-sm p-2',
        buttonVariantStyles[buttonVariant],
        {
          'w-full': isFullWidth,
          'h-8 px-2': isCompact,
          'px-3': !isCompact,
        },
      )}
      onClick={onClick}
    >
      {iconClassName && (
        <div
          className={classNames(iconClassName, {
            'mr-2': children || countdownSeconds,
            'opacity-0': isLoading,
          })}
        />
      )}
      {(children || countdownSeconds) && (
        <div
          className={classNames({
            'text-xs': isCompact,
            'text-base': !isCompact,
            underline: variant?.includes('text'),
            'opacity-0': isLoading,
          })}
        >
          {isButtonDisabledDueToCountdown && countdownSeconds ? (
            <p>
              <span className="mr-1">{children}</span>
              <span>
                {intl.formatMessage(ButtonMessages.countdownButtonLabel, {
                  seconds_remaining: secondsRemaining,
                })}
              </span>
            </p>
          ) : (
            children
          )}
        </div>
      )}
      {isLoading && (
        <div className="absolute flex items-center justify-center">
          <Spinner color={getButtonSpinnerColor(variant ?? 'green-filled')} size={isCompact ? 'sm' : 'md'} />
        </div>
      )}
    </button>
  )
}

Button.defaultProps = {
  children: undefined,
  iconClassName: undefined,
  isCompact: false,
  isFullWidth: false,
  isDisabled: false,
  isLoading: false,
  onClick: undefined,
  role: undefined,
  type: undefined,
  variant: 'green-filled',
}
