import { defineMessages } from 'react-intl'

export const CreateContainerGroupOrganizationNotFoundErrorContentMessages = defineMessages({
  body: {
    defaultMessage: 'We were unable to create your container group because the organization could not be found.',
    id: 'hbnCfGk9',
    description:
      'The description shown in a toast notification message shown when we can not find the organization to create a container group.',
  },
  title: {
    defaultMessage: 'Unable to create container group',
    id: '1UF5jH9X',
    description:
      'The toast notification title shown when we can not find the organization to create a container group.',
  },
})
