import { CreateProjectProblemType, ResponseError } from '@saladtechnologies/openapi-cloud-portal-browser'
import { catchError, concat, delay, filter, from, mergeMap, of, withLatestFrom } from 'rxjs'
import { ProjectsAPI, assertUnreachable } from '../apiMethods'
import { createNewProject, setCreateNewProjectRequestStatus } from '../features/createNewProject/createNewProjectSlice'
import {
  editProject,
  getProjectToEdit,
  setEditProjectRequestStatus,
  setGetProjectToEditRequestStatus,
  setProjectToEdit,
} from '../features/editProject/editProjectSlice'
import { showToastNotification } from '../features/notifications/notificationsSlice'
import { RequestStatus } from '../models'
import {
  getCreateProjectGeneralErrorContent,
  getCreateProjectWithNonUniqueNameContent,
  getEditProjectGeneralErrorContent,
  getEditProjectSucceededContent,
} from '../notifications/clientToastNotificationContent/projects'
import { getSelectProductPagePath } from '../routes/routes-utils'
import type { AppEpic } from '../store'
import { navigateTo } from './navigationEpic'

export const onCreateNewProject: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(createNewProject.match),
    mergeMap((action) =>
      concat(
        of(setCreateNewProjectRequestStatus({ status: RequestStatus.Loading })),
        from(
          ProjectsAPI.createProject({
            organizationName: action.payload.organizationName,
            createProject: {
              name: action.payload.projectName,
            },
          }),
        ).pipe(
          mergeMap((createProjectResponse) =>
            concat(
              of(
                setCreateNewProjectRequestStatus({ status: RequestStatus.Succeeded }),
                navigateTo({
                  path: getSelectProductPagePath(action.payload.organizationName, createProjectResponse.name),
                }),
              ),
              of(setCreateNewProjectRequestStatus({ status: RequestStatus.Idle })).pipe(delay(1)),
            ),
          ),
          catchError((error: unknown) =>
            error instanceof ResponseError && error.response.status === 400
              ? from(error.response.json()).pipe(
                  mergeMap((errorResponse) => {
                    const errorType = errorResponse.type as CreateProjectProblemType | null
                    if (errorType != null && errorType !== CreateProjectProblemType.Null) {
                      switch (errorType) {
                        case CreateProjectProblemType.NameConflict:
                          return concat(
                            of(
                              showToastNotification(
                                getCreateProjectWithNonUniqueNameContent(intl, action.payload.projectName),
                              ),
                              setCreateNewProjectRequestStatus({ status: RequestStatus.Failed }),
                            ),
                            of(setCreateNewProjectRequestStatus({ status: RequestStatus.Idle })).pipe(delay(1)),
                          )
                        default:
                          assertUnreachable(errorType)
                      }
                    } else {
                      return concat(
                        of(
                          showToastNotification(getCreateProjectGeneralErrorContent(intl)),
                          setCreateNewProjectRequestStatus({ status: RequestStatus.Failed }),
                        ),
                        of(setCreateNewProjectRequestStatus({ status: RequestStatus.Idle })).pipe(delay(1)),
                      )
                    }
                  }),
                )
              : concat(
                  of(
                    showToastNotification(getCreateProjectGeneralErrorContent(intl)),
                    setCreateNewProjectRequestStatus({ status: RequestStatus.Failed }),
                  ),
                  of(setCreateNewProjectRequestStatus({ status: RequestStatus.Idle })).pipe(delay(1)),
                ),
          ),
        ),
      ),
    ),
  )

export const onEditProject: AppEpic = (action$, state$, { intl }) =>
  action$.pipe(
    filter(editProject.match),
    mergeMap((action) =>
      concat(
        of(setEditProjectRequestStatus({ status: RequestStatus.Loading })),
        from(
          ProjectsAPI.updateProject({
            organizationName: action.payload.organizationName,
            projectName: action.payload.projectName,
            updateProject: {
              displayName: action.payload.newProjectDisplayName,
            },
          }),
        ).pipe(
          withLatestFrom(state$),
          mergeMap(() =>
            concat(
              of(
                showToastNotification(
                  getEditProjectSucceededContent(
                    intl,
                    action.payload.projectName,
                    action.payload.newProjectDisplayName,
                  ),
                ),
                navigateTo({ previousPage: true }),
                setEditProjectRequestStatus({ status: RequestStatus.Succeeded }),
              ),
              of(setEditProjectRequestStatus({ status: RequestStatus.Idle })).pipe(delay(1)),
            ),
          ),
          catchError(() => {
            return concat(
              of(
                setEditProjectRequestStatus({ status: RequestStatus.Failed }),
                showToastNotification(getEditProjectGeneralErrorContent(intl)),
              ),
              of(setEditProjectRequestStatus({ status: RequestStatus.Idle })).pipe(delay(1)),
            )
          }),
        ),
      ),
    ),
  )

export const onGetProjectToEdit: AppEpic = (action$, _state$) =>
  action$.pipe(
    filter(getProjectToEdit.match),
    mergeMap((action) =>
      concat(
        of(setGetProjectToEditRequestStatus({ status: RequestStatus.Loading })),
        from(
          ProjectsAPI.getProject({
            organizationName: action.payload.organizationName,
            projectName: action.payload.projectName,
          }),
        ).pipe(
          mergeMap((projectResponse) =>
            concat(
              of(
                setGetProjectToEditRequestStatus({ status: RequestStatus.Succeeded }),
                setProjectToEdit({ project: projectResponse }),
              ),
              of(setGetProjectToEditRequestStatus({ status: RequestStatus.Idle })).pipe(delay(1)),
            ),
          ),
          catchError((error: unknown) => {
            const genericGetProjectErrorMessage =
              'We were unable to retrieve this project at this time. Please try again.'
            return error instanceof ResponseError && error.response.status === 404
              ? concat(
                  of(
                    setGetProjectToEditRequestStatus({ status: RequestStatus.Failed }),
                    navigateTo({ previousPage: true }),
                  ),
                  of(setGetProjectToEditRequestStatus({ status: RequestStatus.Idle })).pipe(delay(1)),
                )
              : concat(
                  of(
                    setGetProjectToEditRequestStatus({ status: RequestStatus.Failed }),
                    showToastNotification({
                      body: genericGetProjectErrorMessage,
                      title: 'Unable to retrieve project',
                      type: 'error',
                    }),
                    navigateTo({ previousPage: true }),
                  ),
                  of(setGetProjectToEditRequestStatus({ status: RequestStatus.Idle })).pipe(delay(1)),
                )
          }),
        ),
      ),
    ),
  )
