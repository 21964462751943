import type { RamOption, StorageOption } from '@saladtechnologies/openapi-cloud-portal-browser'
import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import { useRef } from 'react'
import type { Control, FieldValues } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router'
import { Button } from '../../../../components/Button'
import { GPUOptions } from '../../../../features/gpuClasses/models'
import { MachineHardwareMessages } from '../../messages'
import { CreateContainerGroupFormSectionIdAttributes, CreateContainerGroupValues } from '../../models'
import { DiskSpaceInput } from '../DiskSpaceInput'
import { GPUInput } from '../GPUInput'
import { MemoryInput } from '../MemoryInput'
import { VCPUInput } from '../VCPUInput'

export interface MachineHardwareProps {
  /** The control for the create container group react hook form. */
  control: Control<FieldValues, CreateContainerGroupValues>
  /** The list of available GPU options to choose from. */
  gpuOptions: GPUOptions
  /** The flag indicating that the request to create a container group is pending. */
  isSubmitPending: boolean
  /** The function to handle navigation to the previous page */
  onBackButton: () => void
  /** The function to handle navigation to the next page */
  onNextStep: (fieldsToValidate: string[]) => void
  /** The list of available RAM options to choose from. */
  ramOptions: RamOption[] | undefined
  /** The list of available storage options to choose from. */
  storageOptions: StorageOption[] | undefined
}

export const MachineHardware: FunctionComponent<MachineHardwareProps> = ({
  control,
  gpuOptions,
  isSubmitPending,
  onBackButton,
  onNextStep,
  ramOptions,
  storageOptions,
}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const vcpuRef = useRef<HTMLDivElement>(null)
  const memoryRef = useRef<HTMLDivElement>(null)
  const gpuRef = useRef<HTMLDivElement>(null)
  const diskSpaceRef = useRef<HTMLDivElement>(null)

  const machineHardwareFields = [
    CreateContainerGroupFormSectionIdAttributes.VCPU,
    CreateContainerGroupFormSectionIdAttributes.MEMORY,
    CreateContainerGroupFormSectionIdAttributes.GPU,
    CreateContainerGroupFormSectionIdAttributes.DISK_SPACE,
  ]

  return (
    <div className="relative h-screen w-full pb-36">
      <div className="h-full overflow-auto px-6 pt-8">
        <div className="mb-36 w-full max-w-2xl">
          <div className="mb-4">
            <button className="text-blue-90 underline" onClick={() => navigate(-1)}>
              <span className={classNames('fa-solid fa-arrow-left mr-2')} />
              {intl.formatMessage(MachineHardwareMessages.backLinkText)}
            </button>
          </div>
          <h1 className="mb-3 text-3xl font-bold">
            {intl.formatMessage(MachineHardwareMessages.machineHardwareTitle)}
          </h1>
          <h3 className="mb-10 text-lg">{intl.formatMessage(MachineHardwareMessages.machineHardwareSubtitle)}</h3>

          <VCPUInput
            control={control}
            id={CreateContainerGroupFormSectionIdAttributes.VCPU}
            // @ts-ignore - This works, I just don't have the correct types for the ref
            ref={vcpuRef}
          />

          <MemoryInput
            control={control}
            id={CreateContainerGroupFormSectionIdAttributes.MEMORY}
            ramOptions={ramOptions}
            // @ts-ignore - This works, I just don't have the correct types for the ref
            ref={memoryRef}
          />

          <GPUInput
            control={control}
            gpuOptions={gpuOptions}
            id={CreateContainerGroupFormSectionIdAttributes.GPU}
            // @ts-ignore - This works, I just don't have the correct types for the ref
            ref={gpuRef}
          />

          <DiskSpaceInput
            control={control}
            id={CreateContainerGroupFormSectionIdAttributes.DISK_SPACE}
            storageOptions={storageOptions}
            // @ts-ignore - This works, I just don't have the correct types for the ref
            ref={diskSpaceRef}
          />
        </div>
      </div>

      <div className="absolute bottom-16 left-0 mt-10 flex w-full flex-col justify-items-end bg-neutral-10 px-6 py-5 align-middle shadow">
        <div className="flex w-full justify-end gap-2">
          <Button
            variant="green-outlined"
            type="button"
            isLoading={isSubmitPending}
            isDisabled={isSubmitPending}
            onClick={onBackButton}
          >
            {intl.formatMessage(MachineHardwareMessages.previousButtonLabel)}
          </Button>
          <Button
            variant="green-filled"
            type="button"
            isLoading={isSubmitPending}
            isDisabled={isSubmitPending}
            onClick={() => onNextStep(machineHardwareFields)}
          >
            {intl.formatMessage(MachineHardwareMessages.nextStepButtonLabel)}
          </Button>
        </div>
      </div>
    </div>
  )
}
