import { defineMessages } from 'react-intl'

export const ContainerGroupDetailsMainContentMessages = defineMessages({
  accessDomainNameDescription: {
    defaultMessage: 'Access your deployment through this domain name. For more information, ',
    id: '16ePMyIu',
    description: 'Access Domain Name description',
  },
  accessDomainNameOpenTitle: {
    defaultMessage: 'Access Domain Name (Open)',
    id: 'GLKSA7QI',
    description: 'Access Domain Name Open title',
  },
  accessDomainNameProtectedTitle: {
    defaultMessage: 'Access Domain Name (Protected)',
    id: 'yZcMyu73',
    description: 'Access Domain Name Protected title',
  },
  backToContainersGroup: {
    defaultMessage: 'Back to Container Groups',
    id: 'Tf3XjyxC',
    description: 'Back to Container Groups text',
  },
  containerGroupAutostartLabel: {
    defaultMessage: 'Container Group will start once image has been pulled',
    id: 'r9JxcRyV',
    description: 'Autostart label',
  },
  delete: {
    defaultMessage: 'Delete',
    id: '5qRFq/S5',
    description: 'Delete button text',
  },
  deployedOn: {
    defaultMessage: 'Deployed on {date}',
    id: '32SEFxvu',
    description: 'Date of the container group deployment',
  },
  deploymentDetails: {
    defaultMessage: 'Deployment Details',
    id: 'SH/J1GWC',
    description: 'Deployment Details tab name',
  },
  containerGroupLogsTabLabel: {
    defaultMessage: 'Container Logs',
    id: 'OKZJx+Tl',
    description: 'Container Logs tab name',
  },
  duplicateButtonLabel: {
    defaultMessage: 'Duplicate',
    id: '1TLOFBqM',
    description: 'The label for the duplicate button',
  },
  edit: {
    defaultMessage: 'Edit',
    id: 'hcDY47vS',
    description: 'Edit button text',
  },
  failedToPullImageDescription: {
    defaultMessage:
      'Failed to pull image. Ensure the image URL is correct, and that it uses a Docker manifest v2. {learn_more_here_link}.',
    id: '3OPz9sby',
    description: 'The description of why the container group failed to pull the image',
  },
  highDemandHardwareSelectedNotificationBody: {
    defaultMessage:
      'It may take longer than usual to allocate nodes that meet your hardware specifications. In rare cases, we may be unable to reach the desired replica count.',
    id: 'T421AZfb',
    description: 'The description for the high demand hardware selected notification.',
  },
  highDemandHardwareSelectedNotificationTitle: {
    defaultMessage: `You’ve selected hardware that is in high demand`,
    id: 'Y4Q2CE/7',
    description: 'The title for the high demand hardware selected notification.',
  },
  instances: {
    defaultMessage: 'Instances',
    id: 'XJ5QcEzj',
    description: 'Instances tab name',
  },
  learnMoreHereLinkText: {
    defaultMessage: 'Learn more here',
    id: 'C3fmAr9o',
    description: 'Learn more here link text used for the failed to pull image description',
  },
  open: {
    defaultMessage: ' (Open)',
    id: 'APlWqr2L',
    description: 'Container Gateway is open',
  },
  protected: {
    defaultMessage: ' (Protected)',
    id: 'j1Upd7Jj',
    description: 'Container Gateway is protected',
  },
  readMoreHere: {
    defaultMessage: 'read more here',
    id: '2292ESdd',
    description: 'Read more link',
  },
  recentErrorsLabel: {
    defaultMessage: 'Recent Errors',
    id: 'n/pQfy3u',
    description: 'Recent Errors label',
  },
  refresh: {
    defaultMessage: 'Refresh',
    id: 'daW9tTAl',
    description: 'Refresh button text',
  },
  start: {
    defaultMessage: 'Start',
    id: 'N0uHaH68',
    description: 'Start button text',
  },
  stop: {
    defaultMessage: 'Stop',
    id: 'd+5xNlJX',
    description: 'Stop button text',
  },
})
