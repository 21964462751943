import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import type { ObjectSchema } from 'yup'
import { object, string } from 'yup'
import { CreateJobQueueFormMessages } from '../../messages'
import type { CreateJobQueueFormValues } from './CreateJobQueueForm'

export const useCreateJobQueueFormValidation = (): ObjectSchema<CreateJobQueueFormValues> => {
  const intl = useIntl()

  const validationScheme = useMemo(
    () =>
      object({
        name: string()
          .required()
          .matches(/^[a-z][a-z0-9-]{0,61}[a-z0-9]$/),
        description: string()
          .optional()
          .max(500, intl.formatMessage(CreateJobQueueFormMessages.descriptionMaxCharacterErrorMessage)),
      }),
    [intl],
  )
  return validationScheme
}
