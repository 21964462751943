import type { HttpHeadersInner } from '@saladtechnologies/openapi-cloud-portal-browser'
import type { FieldErrors, FieldValues } from 'react-hook-form'

/**
 * Checks if the form has any errors.
 *
 * @param errors The react-hook-form error object for the form.
 * @param fields The list of fields for the form.
 */
export const checkIfHealthProbeFormHasErrors = (errors: FieldErrors<FieldValues>, fields: string[]): boolean => {
  return fields.some((field) => errors[field] !== undefined)
}

/**
 * A function that handles taking the values from the health probe command and arguments fields and configuring them to
 * be one string.
 *
 * @param command The health probe command.
 * @param args The optional arguments for the health probe command.
 */
export const configureHealthProbeCommand = (command: string, args?: { argument: string }[]) => {
  if (!args || args.length === 0) return command

  return command + ' ' + args.map((argument) => argument.argument).join(' ')
}

/**
 * A function that handles taking the values from the health probe headers fields and configuring them to be one string.
 *
 * @param headers The headers for the health probe.
 */
export const configureHealthProbeHeaders = (headers: HttpHeadersInner[]): string | undefined => {
  const hasHeaders = headers && headers.length > 0 && headers[0] && headers[0].name !== '' && headers[0].value !== ''
  if (hasHeaders) {
    return headers
      .map((header, index) => {
        const isLastItem = index === headers.length - 1
        return `${header.name}:${header.value}${isLastItem ? '' : ', '}`
      })
      .join('')
  }

  return undefined
}
