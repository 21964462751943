import { defineMessages } from 'react-intl'

export const UnableToExecuteActionToContainerGroupInstanceGeneralErrorMessages = defineMessages({
  body: {
    defaultMessage:
      'We were unable to {action} your container at this time. If this issue persists please {contact_support}.',
    id: '59shmU9Y',
    description:
      'The description shown in a toast notification message shown when we are not able to {action} container.',
  },
  title: {
    defaultMessage: 'Unable to {action} container',
    id: 'xfgNxhG7',
    description: 'The toast notification title shown when we are not able to {action} container.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'HNO1ImU8',
    description: 'The text shown for the contact support link in the unable to {action} container.',
  },
})
