import type { RamOption } from '@saladtechnologies/openapi-cloud-portal-browser'
import type { FunctionComponent } from 'react'
import type { Control, FieldValues } from 'react-hook-form'
import { Controller, useWatch } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { InlineNotification } from '../../../../components/block/InlineNotification'
import { RadioCards } from '../../../../components/forms/RadioCards'
import { getRamOptionIsHighDemand } from '../../../../utils/ramOptions'
import { MemoryInputMessages } from '../../messages'
import type { CreateContainerGroupFormSectionIdAttributes, CreateContainerGroupValues } from '../../models'
import { CreateContainerGroupField } from '../../models'
import { generateMemoryOptionCards } from './utils'

interface MemoryInputProps {
  /** The control for the react hook form. */
  control: Control<FieldValues, CreateContainerGroupValues>
  /** The id attribute for the field. */
  id: CreateContainerGroupFormSectionIdAttributes
  /** The list of available ram options to choose from. */
  ramOptions: RamOption[] | undefined
}

export const MemoryInput: FunctionComponent<MemoryInputProps> = ({ control, id, ramOptions }) => {
  const intl = useIntl()
  const selectedRamOption: number = useWatch({ control, name: CreateContainerGroupField.MEMORY })
  const ramOptionSelectedIsHighDemand = getRamOptionIsHighDemand(ramOptions, selectedRamOption)

  return (
    <div id={id}>
      <Controller
        name={CreateContainerGroupField.MEMORY}
        control={control}
        render={({ field, fieldState }) => (
          <RadioCards
            {...field}
            {...fieldState}
            defaultCheckedValue={field.value}
            cards={generateMemoryOptionCards(ramOptions)}
            helperText={intl.formatMessage(MemoryInputMessages.helperText)}
            label={intl.formatMessage(MemoryInputMessages.label)}
            name={CreateContainerGroupField.MEMORY}
          />
        )}
      />
      {ramOptionSelectedIsHighDemand && (
        <InlineNotification
          body={intl.formatMessage(MemoryInputMessages.highDemandMemoryNotificationBody)}
          title={intl.formatMessage(MemoryInputMessages.highDemandMemoryNotificationTitle)}
          type="info"
        />
      )}
    </div>
  )
}
