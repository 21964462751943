import type { AppState } from '../../store'
import { organizationsAdapter } from './organizationsSlice'

const organizationsSelectors = organizationsAdapter.getSelectors()

export const selectOrganization = (state: AppState, organizationName: string) =>
  organizationsSelectors.selectById(state.organizations, organizationName)

export const selectOrganizationDisplayName = (state: AppState, organizationName: string) =>
  organizationsSelectors.selectById(state.organizations, organizationName)?.displayName

export const selectOrganizationHasHadValidPayment = (state: AppState, organizationName: string) =>
  organizationsSelectors.selectById(state.organizations, organizationName)?.hasHadValidPayment || false

export const selectOrganizationId = (state: AppState, organizationName: string) =>
  organizationsSelectors.selectById(state.organizations, organizationName)?.id
