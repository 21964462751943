import { defineMessages } from 'react-intl'

export const ExternalLoggingDetailsMessages = defineMessages({
  apiKeyLabel: {
    defaultMessage: 'API Key',
    id: '5Kb8pKkl',
    description: 'The label for the Axiom API key input field.',
  },
  axiomSelectedLabel: {
    defaultMessage: 'Using Axiom',
    id: '/9zE9XCt',
    description:
      'The text shown in the external logging service card on the create container group page when Axiom is selected.',
  },
  compressionLabel: {
    defaultMessage: 'Compression',
    id: '8eHs2Xaj',
    description: 'The label for the HTTP compression input field.',
  },
  datadogSelectedLabel: {
    defaultMessage: 'Using Datadog',
    id: 'f8x3a7/N',
    description:
      'The text shown in the external logging service card on the create container group page when datadog is selected.',
  },
  datasetNameLabel: {
    defaultMessage: 'Dataset Name',
    id: 'EPU7SVda',
    description: 'The label for the Axiom dataset name input field.',
  },
  formatLabel: {
    defaultMessage: 'Format',
    id: 'qWfea6Go',
    description: 'The label for the HTTP format input field.',
  },
  headersLabel: {
    defaultMessage: 'Headers',
    id: '5PoJanc6',
    description: 'The label for the HTTP Headers input field.',
  },
  hostLabel: {
    defaultMessage: 'Host',
    id: 'jaiUX/nn',
    description: 'The label for the Axiom host input field.',
  },
  httpSelectedLabel: {
    defaultMessage: 'Using HTTP',
    id: 'aWWMXZp4',
    description:
      'The text shown in the external logging service card on the create container group page when HTTP is selected.',
  },
  ingestionKeyLabel: {
    defaultMessage: 'Ingestion Key',
    id: 'DJPETbEH',
    description: 'The label for the ingestion key value for the external logging service card.',
  },
  loggingDisabledText: {
    defaultMessage: 'External logging is disabled',
    id: '1fQausRN',
    description:
      'The text shown in the external logging service card on the container group detail page when logging is disabled.',
  },
  newRelicSelectedLabel: {
    defaultMessage: 'Using New Relic',
    id: 'TA9aGZq5',
    description:
      'The text shown in the external logging service card on the container group detail page when new relic is selected.',
  },
  passwordLabel: {
    defaultMessage: 'Password',
    id: 'MBf1oNgS',
    description: 'The label for the Azure password input field.',
  },
  pathLabel: {
    defaultMessage: 'Path',
    id: 'fjKr6b59',
    description: 'The label for the HTTP path input field.',
  },
  portLabel: {
    defaultMessage: 'Port',
    id: 'pYwOB+nc',
    description: 'The label for the HTTP port input field.',
  },
  splunkSelectedLabel: {
    defaultMessage: 'Using Splunk',
    id: 'sDp+gjjP',
    description:
      'The text shown in the external logging service card on the container group detail page when Splunk is selected.',
  },
  tagsLabel: {
    defaultMessage: 'Tags',
    id: 'CrLNpgPE',
    description: 'The label for the Datadog tags input field.',
  },
  tcpSelectedLabel: {
    defaultMessage: 'Using TCP',
    id: '5qabn8rQ',
    description:
      'The text shown in the external logging service card on the container group detail page when TCP is selected.',
  },
  tokenLabel: {
    defaultMessage: 'Token',
    id: 'NAYsCx1g',
    description: 'The label for the token value for the external logging service card.',
  },
  userLabel: {
    defaultMessage: 'User',
    id: 'zfewTorx',
    description: 'The label for the HTTP user input field.',
  },
})
