import { defineMessages } from 'react-intl'

export const SelectExternalLoggingServiceMessages = defineMessages({
  axiomLabel: {
    defaultMessage: 'Axiom',
    id: 'uZDrzoMW',
    description: 'The label for the external logging service select field option axiom.',
  },
  axiomMixpanelEventClickLabel: {
    defaultMessage: 'Axiom Link',
    id: 'i9oiF82X',
    description: 'The label for the axiom docs mixpanel event click label.',
  },
  datadogLabel: {
    defaultMessage: 'Datadog',
    id: '9freQn9Y',
    description: 'The label for the external logging service select field option datadog.',
  },
  httpLabel: {
    defaultMessage: 'HTTP',
    id: 'x9GnnVZA',
    description: 'The label for the external logging service select field option HTTP.',
  },
  label: {
    defaultMessage: 'Select a Container Logging Service',
    id: 'D9FyHI8r',
    description: 'The label for the external logging service select field.',
  },
  newRelicLabel: {
    defaultMessage: 'New Relic',
    id: '373MwH2u',
    description: 'The label for the external logging service select field option new relic.',
  },
  noExternalLoggingServiceSelectedPt1: {
    defaultMessage: `Need an external logging provider?`,
    id: 'jpXUxJg+',
    description: 'The first part of the description when no external logging service is selected.',
  },
  noExternalLoggingServiceSelectedPt2: {
    defaultMessage:
      '{axiom_link} After you set up your service with Axiom, simply choose the Axiom option in the dropdown above and fill in the required information.',
    id: 'PQjTSZt7',
    description: 'The second part of the message when no external logging service is selected.',
  },
  signUpWithAxiom: {
    defaultMessage: 'Sign up with our preferred vendor, Axiom.',
    id: '77THURiB',
    description: 'The text for the axiom link',
  },
  splunkLabel: {
    defaultMessage: 'Splunk',
    id: 'B10NQdP5',
    description: 'The label for the external logging service select field option splunk.',
  },
  tcpLabel: {
    defaultMessage: 'TCP',
    id: 'NKuOuGpp',
    description: 'The label for the external logging service select field option TCP.',
  },
  placeholderText: {
    defaultMessage: 'None',
    id: 'ke4muBDa',
    description: 'The placeholder text for the external logging service select field.',
  },
})
