import { defineMessages } from 'react-intl'

export const InferenceEndpointsMarketplaceFailedErrorContentMessages = defineMessages({
  body: {
    defaultMessage:
      'We are unable to retrieve inference endpoints for the inference endpoints marketplace at this time. Try again and if this issue persists {contact_support}.',
    id: 'mJoDnwwO',
    description:
      'The description shown in a toast notification message shown when we encounter an error on their request to load the inference endpoints marketplace.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'Xok0pJ4U',
    description:
      'The text shown for the contact support link in the failed to load the inference endpoints marketplace error toast notification.',
  },
  title: {
    defaultMessage: 'Unable to retrieve inference endpoints',
    id: '6dZoPHtt',
    description:
      'The toast notification title shown when we encounter an error on their request to load the inference endpoints for the inference endpoints marketplace.',
  },
})
