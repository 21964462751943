import type { FunctionComponent } from 'react'
import { useCallback, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { LoadingPage } from '../../components/page/LoadingPage'
import { trackMixpanelPageViewedEvent } from '../../features/analytics/analyticsSlice'
import {
  selectEditProjectRequestPending,
  selectGetEditProjectRequestStatusIsPending,
} from '../../features/editProject/editProjectSelectors'
import { editProject, getEditProjectsPageData } from '../../features/editProject/editProjectSlice'
import { selectIsLeftColumnShowing } from '../../features/navigationBar/navigationBarSelectors'
import { setLeftColumnShowing } from '../../features/navigationBar/navigationBarSlice'
import { selectOrganizationDisplayName } from '../../features/organizations/organizationsSelectors'
import { selectProjectDisplayName, selectProjects } from '../../features/projects/projectsSelectors'
import { deleteProject } from '../../features/projects/projectsSlice'
import { selectRequestStatus } from '../../features/requestStatus/requestStatusSelectors'
import { editProjectPageRoutePath } from '../../routes/routePaths'
import { getEditProjectPagePath } from '../../routes/routes-utils'
import { useAppDispatch, useAppSelector } from '../../store'
import { EditProjectPage } from './EditProjectPage'

export const ConnectedEditProjectPage: FunctionComponent = () => {
  const { organizationName = '', projectName = '' } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const deleteProjectRequestStatus = useAppSelector((state) => selectRequestStatus(state, 'deleteProject'))
  const editProjectRequestPending = useAppSelector(selectEditProjectRequestPending)
  const getEditProjectsPageDataIsLoading = useAppSelector(selectGetEditProjectRequestStatusIsPending)
  const isLeftColumnOpen = useAppSelector(selectIsLeftColumnShowing)
  const organizationDisplayName = useAppSelector((state) => selectOrganizationDisplayName(state, organizationName))

  const projects = useAppSelector((state) => selectProjects(state, organizationName))?.projects || []
  const currentProjectDisplayName = useAppSelector((state) =>
    selectProjectDisplayName(state, organizationName, projectName),
  )

  const handleCloseLeftNavBar = useCallback(() => {
    dispatch(setLeftColumnShowing({ showing: false }))
  }, [dispatch])

  const onEditProject = useCallback(
    (newProjectDisplayName: string) => {
      if (organizationName !== undefined && projectName !== undefined) {
        dispatch(editProject({ organizationName, projectName, newProjectDisplayName }))
      }
    },
    [dispatch, organizationName, projectName],
  )

  const onRemoveProject = useCallback(
    (projectName: string) => {
      if (organizationName !== undefined && projectName !== undefined) {
        dispatch(deleteProject({ organizationName, projectName }))
      }
    },
    [dispatch, organizationName],
  )

  const getEditProjectsPageDataMemoized = useCallback(() => {
    if (organizationName) {
      dispatch(
        getEditProjectsPageData({
          organizationName,
        }),
      )
    }
  }, [dispatch, organizationName])

  const handleProjectChange = useCallback(
    (projectName: string) => {
      navigate(getEditProjectPagePath(organizationName, projectName))
    },
    [navigate, organizationName],
  )

  useEffect(() => {
    getEditProjectsPageDataMemoized()
    dispatch(trackMixpanelPageViewedEvent({ path: editProjectPageRoutePath, organizationName, projectName }))
  }, [dispatch, getEditProjectsPageDataMemoized, organizationName, projectName])

  const projectsList = projects.map((item) => ({
    displayName: item.displayName,
    name: item.name,
    selected: item.name === projectName,
  }))

  return getEditProjectsPageDataIsLoading ? (
    <LoadingPage />
  ) : (
    <EditProjectPage
      currentProject={{ name: projectName, displayName: currentProjectDisplayName ?? projectName }}
      currentOrganization={{ name: organizationName, displayName: organizationDisplayName ?? organizationName }}
      isDeleteProjectPending={deleteProjectRequestStatus === 'pending'}
      isDeleteProjectSuccessful={deleteProjectRequestStatus === 'succeeded'}
      isEditProjectPending={editProjectRequestPending}
      isLeftColumnOpen={isLeftColumnOpen}
      onCloseLeftDrawer={handleCloseLeftNavBar}
      onDeleteProject={onRemoveProject}
      onEditProject={onEditProject}
      onProjectChange={handleProjectChange}
      projects={projectsList}
    />
  )
}
