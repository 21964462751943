import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { Button } from '../../../../Button'
import { ButtonLink } from '../../../../base'
import { Modal } from '../../../../block/Modal'
import { LearnMoreInstanceModalMessages } from './LearnMoreInstanceModalMessages'

export interface LearnMoreInstanceModalProps {
  /** The callback executed when the user clicks the `Cancel` button. */
  onCancel: () => void
}

export const LearnMoreInstanceModal: FunctionComponent<LearnMoreInstanceModalProps> = ({ onCancel }) => {
  const intl = useIntl()

  return (
    <Modal onClose={onCancel} title={intl.formatMessage(LearnMoreInstanceModalMessages.title)}>
      <h1 className="mb-8 text-2xl font-bold">{intl.formatMessage(LearnMoreInstanceModalMessages.title)}</h1>
      <p className="mb-8 pr-6">
        <span className="font-bold"> {intl.formatMessage(LearnMoreInstanceModalMessages.restartActionTitle)}</span>
        {intl.formatMessage(LearnMoreInstanceModalMessages.restartActionDescription)}
      </p>
      <p className="mb-8 pr-6">
        <span className="font-bold"> {intl.formatMessage(LearnMoreInstanceModalMessages.recreateActionTitle)}</span>
        {intl.formatMessage(LearnMoreInstanceModalMessages.recreateActionDescription)}
      </p>
      <p className="mb-12 pr-6">
        <span className="font-bold"> {intl.formatMessage(LearnMoreInstanceModalMessages.reallocateActionTitle)}</span>
        {intl.formatMessage(LearnMoreInstanceModalMessages.reallocateActionDescription)}
      </p>
      <div className="flex max-w-md gap-6">
        <Button variant="green-outlined" onClick={onCancel} isFullWidth>
          {intl.formatMessage(LearnMoreInstanceModalMessages.closeWindowButtonLabel)}
        </Button>
        <ButtonLink url="https://docs.salad.com/introduction" isFullWidth>
          {intl.formatMessage(LearnMoreInstanceModalMessages.readDocumentationButtonLabel)}
        </ButtonLink>
      </div>
    </Modal>
  )
}
