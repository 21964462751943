import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { ReactNode } from 'react'
import type { ToastNotificationType } from '../../notifications'

export interface NotificationsState {}

const initialState: NotificationsState = {}

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    showToastNotification(
      _state,
      _action: PayloadAction<{
        body: ReactNode
        title: string
        type: ToastNotificationType
      }>,
    ) {},
  },
})

export const { showToastNotification } = notificationsSlice.actions
