import { defineMessages } from 'react-intl'

export const EstimatedCostMessages = defineMessages({
  description: {
    defaultMessage: 'Cost is estimated based on your usage. Payments are collected at the end of the month.',
    id: 'geQUReND',
    description: 'The description on how the estimated cost is calculated.',
  },
  replicaCount: {
    defaultMessage: 'x {replica_count} instance',
    id: 'ZKKV3ZBJ',
    description: 'The replica count.',
  },
  replicaCountPlural: {
    defaultMessage: 'x {replica_count} instances',
    id: 'CbpW33qM',
    description: 'The replica count pluralized.',
  },
  replicaCountLabel: {
    defaultMessage: 'Replica Count',
    id: 'O3fIdhOB',
    description: 'The label for the replica count line item.',
  },
  selectedRecipeCost: {
    // eslint-disable-next-line no-template-curly-in-string -- need dollar sign for cost_per_hour
    defaultMessage: '${cost_per_hour} per hour',
    id: 'ujKrwLPh',
    description: 'The cost per hour for the selected recipe.',
  },
  selectedRecipeCostLabel: {
    defaultMessage: 'Selected Recipe Cost',
    id: 'Ab7Jd+XI',
    description: 'The label for the selected recipe cost line item.',
  },
  title: {
    defaultMessage: 'Estimated Cost',
    id: '+Msh/wAx',
    description: 'The title of for the estimated cost section.',
  },
})
