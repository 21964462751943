import { defineMessages } from 'react-intl'

export const ChangeAPIKeyModalMessages = defineMessages({
  changeAPIKeyButtonLabel: {
    defaultMessage: 'Change API Key',
    id: 'ETEjR26G',
    description: `The label for the button that changes a user's API key.`,
  },
  cancelButtonLabel: {
    defaultMessage: 'Cancel',
    id: 'm74ALD3C',
    description: `The label for the button that closes the Change API Key Modal`,
  },
  description: {
    defaultMessage: 'You cannot undo this, and your old key will immediately stop working.',
    id: 'MQs1dRPT',
    description: 'The description for the modal that shows when a user wants to change their API key.',
  },
  title: {
    defaultMessage: 'Are you sure you want to change your API Key?',
    id: 'zfzjObPA',
    description: 'The title for the modal that shows when a user wants to change their API key.',
  },
})
