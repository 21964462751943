import { defineMessages } from 'react-intl'

export const FeaturePreviewInlineNotificationMessages = defineMessages({
  body: {
    id: '9wgcLBaX',
    defaultMessage:
      'Preview features are made available so you can get early access and provide feedback. Preview features pricing and functionality are subject to change.',
    description: 'The body of the Feature Preview Inline Notification component',
  },
  title: {
    id: '5ZfLmpTG',
    defaultMessage: 'This Feature is in Preview',
    description: 'The title of the Feature Preview Inline Notification component',
  },
})
