import type { FunctionComponent } from 'react'
import type { Control, FieldValues } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { RadioCards } from '../../../../components/forms/RadioCards'
import { VCPUInputMessages } from '../../messages'
import type { CreateContainerGroupFormSectionIdAttributes, CreateContainerGroupValues } from '../../models'
import { CreateContainerGroupField } from '../../models'
import { generateVCPUOptionCards } from './utils'

interface VCPUInputProps {
  /** The control for the react hook form. */
  control: Control<FieldValues, CreateContainerGroupValues>
  /** The id attribute for the field. */
  id: CreateContainerGroupFormSectionIdAttributes
}

export const VCPUInput: FunctionComponent<VCPUInputProps> = ({ control, id }) => {
  const intl = useIntl()

  return (
    <div id={id}>
      <Controller
        name={CreateContainerGroupField.VCPUS}
        control={control}
        render={({ field, fieldState }) => (
          <RadioCards
            {...field}
            {...fieldState}
            defaultCheckedValue={field.value}
            cards={generateVCPUOptionCards(intl)}
            helperText={intl.formatMessage(VCPUInputMessages.helperText)}
            label={intl.formatMessage(VCPUInputMessages.label)}
            name={CreateContainerGroupField.VCPUS}
          />
        )}
      />
    </div>
  )
}
