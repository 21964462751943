import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { ProtocolOptions } from '../../../pages/CreateContainerGroup/models'
import { HealthProbeDetailsMessages } from '../messages'

interface HealthProbeDetailsProps {
  /** The command for the health probe. */
  command?: string
  /** The failure threshold for the health probe. */
  failureThreshold?: number | null
  /** The header for the health probe. */
  headers?: string
  /** The initial delay seconds for the health probe. */
  initialDelaySeconds?: number | null
  /** The flag indicating whether or not the health probe has been enabled. */
  isEnabled: boolean
  /** The path for the health probe. */
  path?: string
  /** The period seconds for the health probe. */
  periodSeconds?: number | null
  /** The port for the health probe. */
  port?: number
  /** The protocol for the health probe. */
  protocol: ProtocolOptions
  /** The service for the health probe. */
  service?: string
  /** The success threshold for the health probe. */
  successThreshold?: number | null
  /** The timeout seconds for the health probe. */
  timeoutSeconds?: number | null
}

export const HealthProbeDetails: FunctionComponent<HealthProbeDetailsProps> = ({
  command,
  failureThreshold,
  headers,
  initialDelaySeconds,
  isEnabled,
  path,
  periodSeconds,
  port,
  protocol,
  service,
  successThreshold,
  timeoutSeconds,
}) => {
  const intl = useIntl()
  const portDetails = intl.formatMessage(HealthProbeDetailsMessages.portSummaryLabel, {
    port: <span className="font-normal">{port}</span>,
  })

  return (
    <>
      {isEnabled ? (
        <div className="font-medium">
          <p>{intl.formatMessage(HealthProbeDetailsMessages.enabledSummaryLabel)}</p>
          <p>
            {intl.formatMessage(HealthProbeDetailsMessages.protocolSummaryLabel, {
              protocol: <span className="font-normal">{protocol}</span>,
            })}
          </p>

          {protocol === ProtocolOptions.EXEC ? (
            <p className="truncate">
              {intl.formatMessage(HealthProbeDetailsMessages.commandSummaryLabel, {
                command: <span className="font-normal">{command}</span>,
              })}
            </p>
          ) : protocol === ProtocolOptions.GRPC ? (
            <>
              <p className="truncate">
                {intl.formatMessage(HealthProbeDetailsMessages.serviceSummaryLabel, {
                  service: <span className="font-normal">{service}</span>,
                })}
              </p>
              <p>{portDetails}</p>
            </>
          ) : protocol === ProtocolOptions.HTTP1X ? (
            <>
              <p className="truncate">
                {intl.formatMessage(HealthProbeDetailsMessages.pathSummaryLabel, {
                  path: <span className="font-normal">{path}</span>,
                })}
              </p>
              {headers && (
                <p className="truncate">
                  {intl.formatMessage(HealthProbeDetailsMessages.headersSummaryLabel, {
                    headers: <span className="font-normal">{headers}</span>,
                  })}
                </p>
              )}
              <p>{portDetails}</p>
            </>
          ) : (
            <p>{portDetails}</p>
          )}

          {/* Seconds and Threshold Values */}
          {initialDelaySeconds !== null && (
            <p>
              {intl.formatMessage(HealthProbeDetailsMessages.initialDelaySecondsSummaryLabel, {
                seconds: <span className="font-normal">{initialDelaySeconds}</span>,
              })}
            </p>
          )}
          {periodSeconds !== null && (
            <p>
              {intl.formatMessage(HealthProbeDetailsMessages.periodSecondsSummaryLabel, {
                seconds: <span className="font-normal">{periodSeconds}</span>,
              })}
            </p>
          )}
          {timeoutSeconds !== null && (
            <p>
              {intl.formatMessage(HealthProbeDetailsMessages.timeoutSecondsSummaryLabel, {
                seconds: <span className="font-normal">{timeoutSeconds}</span>,
              })}
            </p>
          )}
          {successThreshold !== null && (
            <p>
              {intl.formatMessage(HealthProbeDetailsMessages.successThresholdSummaryLabel, {
                seconds: <span className="font-normal">{successThreshold}</span>,
              })}
            </p>
          )}
          {failureThreshold !== null && (
            <p>
              {intl.formatMessage(HealthProbeDetailsMessages.failureThresholdSummaryLabel, {
                seconds: <span className="font-normal">{failureThreshold}</span>,
              })}
            </p>
          )}
        </div>
      ) : (
        intl.formatMessage(HealthProbeDetailsMessages.disabledLabel)
      )}
    </>
  )
}
