import { defineMessages } from 'react-intl'

export const GeneralErrorRemovingTeamMemberContentMessages = defineMessages({
  body: {
    defaultMessage:
      'We were unable to complete your request to remove {user_email}. Try again and if this issue persists {contact_support}.',
    id: 'imjx1fQZ',
    description: 'The body for the error Team Member Removed notification.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'pIQEUfA+',
    description: 'The text shown for the contact support link.',
  },
  title: {
    defaultMessage: 'Unable to remove team member',
    id: 'TXiXUyTW',
    description: 'The title for the error Team Member Removed notification.',
  },
})
