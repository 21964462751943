import { defineMessages } from 'react-intl'

export const MemoryInputMessages = defineMessages({
  helperText: {
    defaultMessage: "Additional options available through Salad's API",
    id: 'd8LzMukT',
    description: 'The helper text for the container group memory input field.',
  },
  highDemandMemoryNotificationBody: {
    defaultMessage:
      'It may take longer than usual to allocate nodes that meet your RAM specifications. In rare cases, we may be unable to reach the desired replica count.',
    id: 'xsbSPgEF',
    description: 'The description for the high demand Memory selected notification.',
  },
  highDemandMemoryNotificationTitle: {
    defaultMessage: `You’ve selected RAM that is in high demand`,
    id: 'FNnteNrB',
    description: 'The title for the high demand Memory selected notification.',
  },
  label: {
    defaultMessage: 'How much memory would you like to allocate?',
    id: 'FA/XTCFS',
    description: 'The label for the container group memory input field.',
  },
  optionLabel: {
    defaultMessage: '{gb} GB',
    id: 'mF4Y3BKw',
    description: 'The option label for the container group memory input field.',
  },
})
