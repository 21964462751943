/**
 * Configures the job queues connections table entity ID based on the organization name, project name, and job queue
 * name.
 *
 * @param organizationName The organization name
 * @param projectName The project name
 * @param jobQueueName The job queue name
 */
export const configuresJobQueueConnectionsTableEntityId = (
  organizationName: string,
  projectName: string,
  jobQueueName: string,
) => `${organizationName}:${projectName}:${jobQueueName}`
