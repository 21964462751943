import { defineMessages } from 'react-intl'

export const CreateContainerGroupProjectNotFoundErrorContentMessages = defineMessages({
  body: {
    defaultMessage: 'We were unable to create your container group because the project could not be found.',
    id: '9ppuqlzj',
    description:
      'The description shown in a toast notification message shown when we can not find the project to create a container group.',
  },
  title: {
    defaultMessage: 'Unable to create container group',
    id: 'UR4743pq',
    description: 'The toast notification title shown when we can not find the project to create a container group.',
  },
})
