import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { onDeauthenticated } from '../../services'
import { AuthenticationPage } from './models/AuthenticationPage'

export interface AuthenticationState {
  currentPage: AuthenticationPage
  authenticated: null | boolean
}

const initialState: AuthenticationState = {
  currentPage: AuthenticationPage.Login,
  authenticated: null,
}

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    setAuthenticationPage(state, action: PayloadAction<{ page: AuthenticationPage }>) {
      state.currentPage = action.payload.page
    },
    setAuthenticatedState(state, action: PayloadAction<{ authenticated: null | boolean }>) {
      state.authenticated = action.payload.authenticated
      if (action.payload.authenticated !== true) {
        onDeauthenticated()
      }
    },
  },
})

export const { setAuthenticatedState, setAuthenticationPage } = authenticationSlice.actions
