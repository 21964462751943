import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type {
  RecipeDeployment,
  RecipeDeploymentInstancesInstancesInner,
} from '@saladtechnologies/openapi-cloud-portal-browser'
import { RequestStatus } from '../../models'
import type { RecipeDeploymentInstance } from './models'
import type { GetRecipeDeploymentRequestType } from './models/GetRecipeDeploymentRequestType'

export interface RecipeDeploymentDetailsState {
  deleteRecipeDeploymentRequestStatus: RequestStatus
  getRecipeDeploymentRequestStatus: RequestStatus
  recipeDeployment: RecipeDeployment | undefined
  recipeDeploymentInstances: RecipeDeploymentInstance[]
  startRecipeDeploymentRequestStatus: RequestStatus
  stopRecipeDeploymentRequestStatus: RequestStatus
}

const initialState: RecipeDeploymentDetailsState = {
  deleteRecipeDeploymentRequestStatus: RequestStatus.Idle,
  getRecipeDeploymentRequestStatus: RequestStatus.Idle,
  recipeDeployment: undefined,
  recipeDeploymentInstances: [],
  startRecipeDeploymentRequestStatus: RequestStatus.Idle,
  stopRecipeDeploymentRequestStatus: RequestStatus.Idle,
}

export const recipeDeploymentDetailsSlice = createSlice({
  name: 'recipeDeploymentDetails',
  initialState,
  reducers: {
    deleteRecipeDeployment(
      _state,
      _action: PayloadAction<{
        organizationName: string
        projectName: string
        recipeDeploymentName: string
      }>,
    ) {},
    pollRecipeDeployment(
      _state,
      _action: PayloadAction<{
        organizationName: string
        projectName: string
        recipeDeploymentName: string
        requestType: GetRecipeDeploymentRequestType
      }>,
    ) {},
    getRecipeDeploymentDetailsPageData(
      _state,
      _action: PayloadAction<{
        organizationName: string
        projectName: string
        recipeDeploymentName: string
        requestType: GetRecipeDeploymentRequestType
      }>,
    ) {},
    getRecipeDeploymentInstances(
      _state,
      _action: PayloadAction<{
        organizationName: string
        projectName: string
        recipeName: string
      }>,
    ) {},
    setDeleteRecipeDeploymentRequestStatus(state, action: PayloadAction<{ status: RequestStatus }>) {
      state.deleteRecipeDeploymentRequestStatus = action.payload.status
    },
    setGetRecipeDeploymentRequestStatus(state, action: PayloadAction<{ status: RequestStatus }>) {
      state.getRecipeDeploymentRequestStatus = action.payload.status
    },
    setRecipeDeployment(state, action: PayloadAction<{ recipeDeployment: RecipeDeployment }>) {
      state.recipeDeployment = action.payload.recipeDeployment
    },
    setRecipeDeploymentInstances(
      state,
      action: PayloadAction<{ recipeDeploymentInstances: RecipeDeploymentInstancesInstancesInner[] }>,
    ) {
      const recipeDeploymentInstances = action.payload.recipeDeploymentInstances
      const recipeDeploymentInstancesArray = recipeDeploymentInstances.map(
        (recipeDeploymentInstance: RecipeDeploymentInstancesInstancesInner) => {
          const instance: RecipeDeploymentInstance = {
            isReallocatePending: false,
            isRecreatePending: false,
            isRestartPending: false,
            machineId: recipeDeploymentInstance.machineId,
            state: recipeDeploymentInstance.state,
            updateTime: recipeDeploymentInstance.updateTime,
          }
          return instance
        },
      )
      state.recipeDeploymentInstances = recipeDeploymentInstancesArray
    },
    setStartRecipeDeploymentRequestStatus(state, action: PayloadAction<{ status: RequestStatus }>) {
      state.startRecipeDeploymentRequestStatus = action.payload.status
    },
    setStopRecipeDeploymentRequestStatus(state, action: PayloadAction<{ status: RequestStatus }>) {
      state.stopRecipeDeploymentRequestStatus = action.payload.status
    },
    startRecipeDeployment(
      _state,
      _action: PayloadAction<{ organizationName: string; projectName: string; recipeDeploymentName: string }>,
    ) {},
    stopPollingForRecipeDeployment(state) {
      state.deleteRecipeDeploymentRequestStatus = RequestStatus.Idle
      state.getRecipeDeploymentRequestStatus = RequestStatus.Idle
      state.recipeDeployment = undefined
      state.startRecipeDeploymentRequestStatus = RequestStatus.Idle
      state.stopRecipeDeploymentRequestStatus = RequestStatus.Idle
    },
    stopRecipeDeployment(
      _state,
      _action: PayloadAction<{ organizationName: string; projectName: string; recipeDeploymentName: string }>,
    ) {},
  },
})

export const {
  deleteRecipeDeployment,
  getRecipeDeploymentDetailsPageData,
  getRecipeDeploymentInstances,
  pollRecipeDeployment,
  setDeleteRecipeDeploymentRequestStatus,
  setGetRecipeDeploymentRequestStatus,
  setRecipeDeployment,
  setRecipeDeploymentInstances,
  setStartRecipeDeploymentRequestStatus,
  setStopRecipeDeploymentRequestStatus,
  startRecipeDeployment,
  stopPollingForRecipeDeployment,
  stopRecipeDeployment,
} = recipeDeploymentDetailsSlice.actions
