/**
 * Configures the container group work loads entity ID based on the organization name, project name, and container group
 * name.
 *
 * @param containerGroupName The container group name
 * @param organizationName The organization name
 * @param projectName The project name
 */
export const configuresContainerGroupWorkloadsErrorsEntityId = (
  containerGroupName: string,
  organizationName: string,
  projectName: string,
) => `${organizationName}:${projectName}:${containerGroupName}`
