import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { CreateJobQueueStepsMessages } from '../../messages'

export const CreateJobQueueSteps: FunctionComponent = () => {
  const intl = useIntl()

  return (
    <div className="mx-5 mt-8 flex flex-col text-neutral-100">
      <p className="mb-4 text-xs uppercase">{intl.formatMessage(CreateJobQueueStepsMessages.header)}</p>

      <div className="flex items-center gap-8">
        <div className="h-7 w-2 rounded-full bg-gradient-to-r from-green-60 to-green-40" />
        <p className="text-sm font-medium">{intl.formatMessage(CreateJobQueueStepsMessages.createJobQueueStepLabel)}</p>
      </div>
    </div>
  )
}
