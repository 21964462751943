import { yupResolver } from '@hookform/resolvers/yup'
import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Button } from '../../../../components/Button'
import { TextField } from '../../../../components/TextField'
import { Link } from '../../../../components/base'
import { FeaturePreviewInlineNotification } from '../../../../components/featurePreview/FeaturePreviewInlineNotification'
import { useFixedForm } from '../../../../hooks'
import { CreateJobQueueFormMessages } from '../../messages'
import { useCreateJobQueueFormValidation } from './useCreateJobQueueFormValidation'

export interface CreateJobQueueFormValues {
  /** The job queue name. */
  name: string
  /** The job queue description. */
  description?: string
}

interface CreateJobQueueFormProps {
  /** The flag indicating that the request to create a job queue is pending. */
  isCreateJobQueuePending: boolean
  /** The path for the Job Queues Page. */
  jobQueuesPath: string
  /** A callback that when executed creates a job queue. */
  onCreateJobQueue: (name: string, description?: string) => void
}

export const CreateJobQueueForm: FunctionComponent<CreateJobQueueFormProps> = ({
  isCreateJobQueuePending,
  jobQueuesPath,
  onCreateJobQueue,
}) => {
  const intl = useIntl()
  const createJobQueueValidationScheme = useCreateJobQueueFormValidation()
  const { control, handleSubmit } = useFixedForm<CreateJobQueueFormValues>({
    resolver: yupResolver(createJobQueueValidationScheme),
    onSubmit: ({ description, name }) => {
      onCreateJobQueue(name, description)
    },
  })

  return (
    <div className="relative h-screen w-full pb-36">
      <div className="h-full overflow-auto px-6 pt-8">
        <div className="mb-8 mt-6 flex max-w-2xl flex-col gap-4">
          <Link url={jobQueuesPath}>
            <span className={classNames('fa-solid fa-arrow-left mr-2')} />
            {intl.formatMessage(CreateJobQueueFormMessages.backLinkText)}
          </Link>
          <h1 className="text-3xl font-bold">{intl.formatMessage(CreateJobQueueFormMessages.title)}</h1>

          <FeaturePreviewInlineNotification />

          <form onSubmit={handleSubmit}>
            <Controller
              name="name"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  {...fieldState}
                  helperText={intl.formatMessage(CreateJobQueueFormMessages.nameHelperText)}
                  placeholder={intl.formatMessage(CreateJobQueueFormMessages.nameFieldPlaceholder)}
                  label={intl.formatMessage(CreateJobQueueFormMessages.nameFieldLabel)}
                  isFullWidth
                />
              )}
            />
            <Controller
              name="description"
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  {...fieldState}
                  helperText={
                    fieldState.error?.message || intl.formatMessage(CreateJobQueueFormMessages.descriptionHelperText)
                  }
                  placeholder={intl.formatMessage(CreateJobQueueFormMessages.descriptionFieldPlaceholder)}
                  label={intl.formatMessage(CreateJobQueueFormMessages.descriptionFieldLabel)}
                  isFullWidth
                />
              )}
            />
          </form>

          <div>
            <h2 className="mb-2 text-base font-bold">
              {intl.formatMessage(CreateJobQueueFormMessages.serviceLimitationsHeader)}
            </h2>
            <ul className="ml-7 list-disc">
              <li>{intl.formatMessage(CreateJobQueueFormMessages.serviceLimitationNote1)}</li>
              <li>{intl.formatMessage(CreateJobQueueFormMessages.serviceLimitationNote2)}</li>
            </ul>
          </div>
        </div>

        <div className="absolute bottom-12 left-0 mt-10 flex w-full flex-col justify-center bg-neutral-10 px-14 py-7 align-middle shadow">
          <Button
            type="button"
            onClick={handleSubmit}
            isLoading={isCreateJobQueuePending}
            isDisabled={isCreateJobQueuePending}
            isFullWidth
          >
            {intl.formatMessage(CreateJobQueueFormMessages.createJobQueueButtonLabel)}
          </Button>
        </div>
      </div>
    </div>
  )
}
