import { defineMessages } from 'react-intl'

export const PaymentMethodVerificationInProgressErrorMessages = defineMessages({
  body: {
    defaultMessage:
      'Verification is in progress for a payment method in your organization. Once that verification is completed you may update payment methods.',
    id: '4f/r1MnN',
    description:
      'The description in a toast notification message shown when we encounter an error adding new payment method while verification of existing one is already in progress.',
  },
  title: {
    defaultMessage: 'Verification in Progress',
    id: 't/OWpclH',
    description:
      'The toast notification title shown when we encounter an error adding new payment method while verification of existing one is already in progress.',
  },
})
