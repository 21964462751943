import { defineMessages } from 'react-intl'

export const inferenceEndpointJobDownloadErrorContentMessages = defineMessages({
  body: {
    defaultMessage: `Please try again. If the issue persists, {contact_support}.`,
    id: 'zKlIkMhq',
    description:
      'The description shown in a toast notification message shown when we encounter an error downloading the inference endpoint job output.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'Tj48Q4p4',
    description: 'The link text shown in a toast notification message.',
  },
  title: {
    defaultMessage: 'Unable to retrieve job output',
    id: 'Ei1ExUab',
    description:
      'The toast notification title shown when we encounter an error downloading the inference endpoint job output.',
  },
})
