import { defineMessages } from 'react-intl'

export const DeploymentInstanceTableActionsButtonMessages = defineMessages({
  viewContainerLogsLabel: {
    defaultMessage: 'View Container Logs',
    id: 'Ci2quE5W',
    description: 'The label for viewing container logs',
  },
  reallocateLabel: {
    defaultMessage: 'Reallocate',
    id: 'AAfvDtom',
    description: 'The label for reallocate action',
  },
  restartLabel: {
    defaultMessage: 'Restart',
    id: 'bUjSBKAI',
    description: 'The label for restart action',
  },
  recreateLabel: {
    defaultMessage: 'Recreate',
    id: 'XrGWdgA3',
    description: 'The label for recreate action',
  },
})
