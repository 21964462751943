import { defineMessages } from 'react-intl'

export const GoogleInputFieldsMessages = defineMessages({
  jsonKeyLabel: {
    defaultMessage: 'Upload Your JSON Key',
    id: 'u8jrbU5m',
    description: 'The label for the Google JSON Key input field.',
  },
  needHelpText: {
    defaultMessage: 'Need help with setting up on Google Cloud?',
    id: 'UIsfvX7U',
    description: 'The help text shown below the Google Cloud input fields.',
  },
})
