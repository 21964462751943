import { defineMessages } from 'react-intl'

export const NoRecipesDeployedMessages = defineMessages({
  deployRecipeButtonLabel: {
    defaultMessage: 'Deploy a New Recipe',
    id: 'gxAp8PXP',
    description: 'The label for the deploy recipe button.',
  },
  learnMoreButtonLabel: {
    defaultMessage: 'Learn More',
    id: '0hhqHlKm',
    description: 'The label for the learn more button.',
  },
  recipesImageAlt: {
    defaultMessage: 'Salad Recipes Image',
    id: 'o6szuXAh',
    description: 'The alt text for the recipes image.',
  },
  noRecipe: {
    defaultMessage: "You haven't deployed a recipe yet",
    id: 'aGZBTWn3',
    description: 'The copy displayed when you have not deployed a recipe.',
  },
})
