import type { FunctionComponent } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { LoadingPage } from '../../components/page/LoadingPage'
import { trackMixpanelPageViewedEvent } from '../../features/analytics/analyticsSlice'
import {
  selectEditRecipeDeploymentCostPerHour,
  selectEditRecipeDeploymentIsPending,
  selectIsRecipeInstancesQuotaExceeded,
  selectRecipeDeploymentToEdit,
  selectRecipeDeploymentToEditIsPending,
} from '../../features/editRecipeDeployment/editRecipeDeploymentSelectors'
import {
  editRecipeDeployment,
  getRecipeDeploymentToEdit,
} from '../../features/editRecipeDeployment/editRecipeDeploymentSlice'
import { selectIsLeftColumnShowing } from '../../features/navigationBar/navigationBarSelectors'
import { setLeftColumnShowing } from '../../features/navigationBar/navigationBarSlice'
import { selectOrganizationDisplayName } from '../../features/organizations/organizationsSelectors'
import { selectProfileEmail } from '../../features/profile/profileSelectors'
import { selectProjectDisplayName, selectProjects } from '../../features/projects/projectsSelectors'
import { deleteProject } from '../../features/projects/projectsSlice'
import { selectRecipeDeploymentInstanceQuota } from '../../features/quotas/quotasSelectors'
import { selectRequestStatus } from '../../features/requestStatus/requestStatusSelectors'
import { editRecipeDeploymentPageRoutePath } from '../../routes/routePaths'
import { getContainerGroupsPagePath, getRecipeDeploymentDetailsPagePath } from '../../routes/routes-utils'
import { useAppDispatch, useAppSelector } from '../../store'
import { getRequestRecipeDeploymentInstanceQuotaIncreaseLink } from '../../utils'
import { EditRecipeDeploymentPage } from './EditRecipeDeploymentPage'

export const ConnectedEditRecipeDeploymentPage: FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { organizationName = '', projectName = '', recipeDeploymentName = '' } = useParams()
  const [pendingReplicaCount, setPendingReplicaCount] = useState<number>(1)

  const costPerHour = useAppSelector(selectEditRecipeDeploymentCostPerHour)
  const deleteProjectRequestStatus = useAppSelector((state) => selectRequestStatus(state, 'deleteProject'))
  const isLeftColumnOpen = useAppSelector(selectIsLeftColumnShowing)
  const replicaCountLimit = useAppSelector((state) => selectRecipeDeploymentInstanceQuota(state, organizationName))
  const editRecipeDeploymentIsPending = useAppSelector(selectEditRecipeDeploymentIsPending)
  const getRecipeDeploymentToEditIsPending = useAppSelector(selectRecipeDeploymentToEditIsPending)
  const isRecipeInstancesQuotaExceeded = useAppSelector(selectIsRecipeInstancesQuotaExceeded)
  const recipeDeployment = useAppSelector(selectRecipeDeploymentToEdit)
  const organizationDisplayName = useAppSelector((state) => selectOrganizationDisplayName(state, organizationName))
  const email = useAppSelector(selectProfileEmail)

  const projects = useAppSelector((state) => selectProjects(state, organizationName))?.projects || []
  const currentProjectDisplayName = useAppSelector((state) =>
    selectProjectDisplayName(state, organizationName, projectName),
  )

  const linkToRequestIncreaseForRecipeDeploymentInstancesQuotas = getRequestRecipeDeploymentInstanceQuotaIncreaseLink(
    email as string,
    organizationName,
  )

  const projectSelectOptions = projects.map((project) => {
    return {
      ...project,
      selected: project.name === projectName,
    }
  })

  const handleCloseLeftNavBar = useCallback(() => {
    dispatch(setLeftColumnShowing({ showing: false }))
  }, [dispatch])

  const onEditRecipeDeployment = useCallback(
    (displayName: string, replicaCount: number) => {
      dispatch(editRecipeDeployment({ organizationName, projectName, recipeDeploymentName, displayName, replicaCount }))
    },
    [dispatch, organizationName, projectName, recipeDeploymentName],
  )

  const onUpdateReplicaCount = useCallback((replicas: number) => {
    setPendingReplicaCount(replicas)
  }, [])

  const handleDeleteProject = useCallback(
    (projectName: string) => {
      dispatch(deleteProject({ organizationName, projectName }))
    },
    [dispatch, organizationName],
  )

  const handleProjectChange = useCallback(
    (projectName: string) => {
      navigate(getContainerGroupsPagePath(organizationName, projectName))
    },
    [navigate, organizationName],
  )

  useEffect(() => {
    dispatch(getRecipeDeploymentToEdit({ organizationName, projectName, recipeDeploymentName }))
    dispatch(
      trackMixpanelPageViewedEvent({
        path: editRecipeDeploymentPageRoutePath,
        organizationName,
        projectName,
        resourceName: recipeDeploymentName,
      }),
    )
  }, [dispatch, organizationName, projectName, recipeDeploymentName])

  useEffect(() => {
    if (recipeDeployment) {
      setPendingReplicaCount(recipeDeployment.replicas)
    }
  }, [recipeDeployment])

  return getRecipeDeploymentToEditIsPending ? (
    <LoadingPage />
  ) : (
    <EditRecipeDeploymentPage
      currentOrganization={{ name: organizationName, displayName: organizationDisplayName ?? organizationName }}
      currentProject={{ name: projectName, displayName: currentProjectDisplayName ?? projectName }}
      isDeleteProjectPending={deleteProjectRequestStatus === 'pending'}
      isDeleteProjectSuccessful={deleteProjectRequestStatus === 'succeeded'}
      isEditRecipeDeploymentPending={editRecipeDeploymentIsPending}
      isLeftColumnOpen={isLeftColumnOpen}
      isRecipeInstancesQuotaExceeded={isRecipeInstancesQuotaExceeded}
      linkToRequestIncreaseForRecipeDeploymentInstancesQuotas={linkToRequestIncreaseForRecipeDeploymentInstancesQuotas}
      maxReplicaCount={replicaCountLimit}
      onCloseLeftDrawer={handleCloseLeftNavBar}
      onDeleteProject={handleDeleteProject}
      onEditRecipeDeployment={onEditRecipeDeployment}
      onProjectChange={handleProjectChange}
      onUpdateReplicaCount={onUpdateReplicaCount}
      pendingReplicaCount={pendingReplicaCount}
      projects={projectSelectOptions}
      recipeCostPerHour={costPerHour}
      recipeDeploymentPath={getRecipeDeploymentDetailsPagePath(organizationName, projectName, recipeDeploymentName)}
      recipeDisplayName={recipeDeployment?.displayName as string}
      recipeName={recipeDeploymentName}
      replicaCount={recipeDeployment?.replicas as number}
    />
  )
}
