import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { Breadcrumbs } from '../../../../components/block'
import { CreateRecipeDeploymentStepsMessages } from '../../messages'

interface CreateRecipeDeploymentStepsProps {
  /** The organization display name. */
  organizationDisplayName: string
  /** The project display name. */
  projectDisplayName: string
}

export const CreateRecipeDeploymentSteps: FunctionComponent<CreateRecipeDeploymentStepsProps> = ({
  organizationDisplayName,
  projectDisplayName,
}) => {
  const intl = useIntl()

  return (
    <div className="mx-5 mt-8 flex flex-col text-neutral-100">
      <p className="mb-2 text-xs">{intl.formatMessage(CreateRecipeDeploymentStepsMessages.header)}</p>
      <div className="mb-4">
        <Breadcrumbs breadcrumbs={[organizationDisplayName, projectDisplayName]} />
      </div>

      <div className="flex items-center gap-8">
        <div className="h-7 w-2 rounded-full bg-gradient-to-r from-green-60 to-green-40" />
        <p className="text-sm font-medium">
          {intl.formatMessage(CreateRecipeDeploymentStepsMessages.configureAndDeployStep)}
        </p>
      </div>
    </div>
  )
}
