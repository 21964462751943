import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import type { ObjectSchema } from 'yup'
import { object, string } from 'yup'
import { VerificationLinkExpiredMessages } from '../messages'
import type { VerificationLinkExpiredFormValues } from './VerificationLinkExpired'

export const useVerificationLinkExpiredFormValidation = (): ObjectSchema<VerificationLinkExpiredFormValues> => {
  const intl = useIntl()
  const validationScheme = useMemo(
    () =>
      object({
        email: string()
          .required(intl.formatMessage(VerificationLinkExpiredMessages.emailRequired))
          .email(intl.formatMessage(VerificationLinkExpiredMessages.emailInvalid)),
      }),
    [intl],
  )
  return validationScheme
}
