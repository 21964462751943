import classNames from 'classnames'
import type { FunctionComponent, ReactNode } from 'react'
import { useMemo } from 'react'
import ReactDOM from 'react-dom'

export interface ModalProps {
  /** The children that will show as the content inside the `Modal`. */
  children: ReactNode
  /** Additional classes to apply custom size */
  customModalTailwindSizeClasses?: string
  /** A callback function that will be called when the user clicks anywhere outside of the `Modal`. */
  onClose: () => void
  /** The title describing the `Modal`. This will be used to properly label the dialog for screen readers. */
  title: string
}

export const Modal: FunctionComponent<ModalProps> = ({ children, customModalTailwindSizeClasses, onClose, title }) => {
  const Portal = useMemo(() => {
    return ({ children }: { children: React.ReactNode }) => ReactDOM.createPortal(children, document.body)
  }, [])

  return (
    <Portal>
      <div className="fixed inset-0 z-50 flex min-h-screen w-full items-center justify-center">
        <div
          className="fixed z-50 min-h-screen w-full bg-neutral-100 bg-opacity-50"
          onClick={onClose}
          aria-hidden="true"
        />
        <section
          className={classNames(
            'z-50 flex flex-col overflow-auto bg-neutral-10 p-8 shadow-md',
            customModalTailwindSizeClasses || 'max-h-96 max-w-2xl sm:max-h-full',
          )}
          role="dialog"
          title={title}
        >
          {children}
        </section>
      </div>
    </Portal>
  )
}
