import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { EmptyResult } from '../../../../components/page/EmptyResult'
import { FailedToLoadRecipeMarketplaceMessages } from '../../messages'

interface FailedToLoadRecipeMarketplaceProps {
  /** The callback executed when the user clicks the try again button. */
  onReloadPage: () => void
}

export const FailedToLoadRecipeMarketplace: FunctionComponent<FailedToLoadRecipeMarketplaceProps> = ({
  onReloadPage,
}) => {
  const intl = useIntl()
  return (
    <EmptyResult
      buttonLabel={intl.formatMessage(FailedToLoadRecipeMarketplaceMessages.tryAgainButtonLabel)}
      mainMessage={intl.formatMessage(FailedToLoadRecipeMarketplaceMessages.message)}
      onButtonClick={onReloadPage}
    />
  )
}
