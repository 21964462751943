import { createSelector } from '@reduxjs/toolkit'
import type { AppState } from '../../store'
import { jobQueuesAdapter } from './jobQueuesSlice'

const jobQueuesSelectors = jobQueuesAdapter.getSelectors()

export const selectJobQueues = createSelector(jobQueuesSelectors.selectById, (jobQueues) => {
  return jobQueues?.jobQueues ?? []
})

export const selectJobQueue = (state: AppState, entityId: string, name: string) => {
  const queues = state.jobQueues.entities[entityId]?.jobQueues ?? []

  if (queues.length > 0) {
    return queues.find((queue) => queue.name === name)
  }

  return undefined
}
