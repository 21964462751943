import { defineMessages } from 'react-intl'

export const ContainerConnectionsMessages = defineMessages({
  backLinkText: {
    defaultMessage: 'Exit to Container Groups',
    id: 'hqaT1hGe',
    description: 'The text for the back link button.',
  },
  containerConnectionsTitle: {
    defaultMessage: 'Container Connections',
    id: 'Dp+iCQZH',
    description: 'The title for the container connections page.',
  },
  containerConnectionsSubtitle: {
    defaultMessage: 'Configure how you will access your containers',
    id: 'dtL8QdQD',
    description: 'The subtitle for the container connections page.',
  },
  nextStepButtonLabel: {
    defaultMessage: 'Next Step',
    id: 'xQLpHFdn',
    description: 'The label for the next step button in the bottom bar.',
  },
  previousButtonLabel: {
    defaultMessage: 'Previous Step',
    description: 'The text for the back link.',
    id: 'XhE96M+U',
  },
})
