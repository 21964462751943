import { defineMessages } from 'react-intl'

export const SearchWithFilterContentMessages = defineMessages({
  searchSortBy: {
    defaultMessage: 'Sort by',
    id: '+KThaCLr',
    description: 'The sort label for the search input.',
  },
  searchSortByAToZOption: {
    defaultMessage: 'A-Z',
    id: 'c/zGbu+T',
    description: 'The A-Z sort option for the search input.',
  },
  searchSortByZToAOption: {
    defaultMessage: 'Z-A',
    id: 'Jvqpe8sY',
    description: 'The Z-A sort option for the search input.',
  },
})
