import { defineMessages } from 'react-intl'

export const BillingInformationModalMissingMessages = defineMessages({
  body: {
    defaultMessage: 'Billing is required to be entered for your organization before you can deploy a container group.',
    id: 'scm0C03X',
    description: 'The body for the modal that shows when a user has their billing information missing.',
  },
  cancelButtonLabel: {
    defaultMessage: 'Cancel',
    id: 'RvPmw4lv',
    description: 'The label for the cancel button on the modal that shows when a user is missing billing information.',
  },
  enterBillingInformationButtonLabel: {
    defaultMessage: 'Enter Billing Information',
    id: 'cczDDay2',
    description:
      'The label for the enter billing information button on the modal that shows when a user is missing billing information.',
  },
  title: {
    defaultMessage: 'Billing Information Missing',
    id: 'd2Sdf935',
    description: 'The title for the modal that shows when a user is missing billing information.',
  },
})
