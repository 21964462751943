import { defineMessages } from 'react-intl'

export const DeleteProjectModalMessages = defineMessages({
  cancelButtonLabel: {
    defaultMessage: 'Cancel',
    id: 'xaZeTsZ4',
    description: 'The label for the Cancel button on the modal that shows when a user wants to delete a project.',
  },
  description: {
    defaultMessage:
      'Are you absolutely sure? This will permanently delete {project_name} and remove all your associations to products under it. You cannot undo this.',
    id: '6iSZUkxA',
    description: 'The description for the modal that shows when a user wants to delete a project.',
  },
  projectInputLabel: {
    defaultMessage: `Please type the project name to confirm deletion`,
    id: '4VJTA0fH',
    description: 'The title for the input on the modal that shows when a user wants to delete an project.',
  },
  title: {
    defaultMessage: 'Delete the project {project_name}?',
    id: 'TTCmeo3P',
    description: 'The title for the modal that shows when a user wants to delete a project.',
  },
})
