import type { FunctionComponent } from 'react'
import type { Control, FieldValues } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { TextField } from '../../../../../../components/TextField'
import { ButtonLink } from '../../../../../../components/base'
import { CreateContainerGroupMainContentMessages, SelfHostedInputFieldsMessages } from '../../../../messages'
import type { CreateContainerGroupValues } from '../../../../models'
import { CreateContainerGroupField } from '../../../../models'

interface SelfHostedInputFieldsProps {
  /** The control for the create container group react hook form. */
  control: Control<FieldValues, CreateContainerGroupValues>
}

export const SelfHostedInputFields: FunctionComponent<SelfHostedInputFieldsProps> = ({ control }) => {
  const intl = useIntl()

  return (
    <>
      <Controller
        name={CreateContainerGroupField.SELF_HOSTED_USERNAME}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            {...fieldState}
            helperText={fieldState.error?.message}
            label={intl.formatMessage(SelfHostedInputFieldsMessages.usernameLabel)}
            placeholder={intl.formatMessage(SelfHostedInputFieldsMessages.usernamePlaceholderText)}
            type="text"
            isFullWidth
          />
        )}
      />
      <Controller
        name={CreateContainerGroupField.SELF_HOSTED_PASSWORD}
        control={control}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            {...fieldState}
            helperText={fieldState.error?.message}
            label={intl.formatMessage(SelfHostedInputFieldsMessages.passwordLabel)}
            placeholder={intl.formatMessage(SelfHostedInputFieldsMessages.passwordPlaceholderText)}
            type="password"
            isFullWidth
          />
        )}
      />

      <div className="pb-16">
        <p className="mb-2">{intl.formatMessage(SelfHostedInputFieldsMessages.needHelpText)}</p>
        <ButtonLink url="https://docs.salad.com/container-engine/container-registries" variant="blue-filled-light">
          {intl.formatMessage(CreateContainerGroupMainContentMessages.learnMoreButtonLabel)}
        </ButtonLink>
      </div>
    </>
  )
}
