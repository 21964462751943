import type { FunctionComponent } from 'react'
import { useCallback } from 'react'
import { selectRegisterAccountEmail } from '../../features/registration/registrationSelectors'
import { resendRegistrationEmail } from '../../features/registration/registrationSlice'
import { selectRequestStatusIsPending } from '../../features/requestStatus/requestStatusSelectors'
import { useAppDispatch, useAppSelector } from '../../store'
import { VerificationEmailSent } from './components/VerificationEmailSent'

export const ConnectedVerificationEmailSentPage: FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const email = useAppSelector(selectRegisterAccountEmail)
  const isResendingRegistrationEmail = useAppSelector((state) =>
    selectRequestStatusIsPending(state, 'resendRegistrationEmail'),
  )

  const onResendRegistrationEmail = useCallback(() => {
    dispatch(resendRegistrationEmail({ email }))
  }, [dispatch, email])

  return (
    <VerificationEmailSent
      email={email}
      isResendEmailPending={isResendingRegistrationEmail}
      onResendEmail={onResendRegistrationEmail}
    />
  )
}
