import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import { useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { useDetectClickOutsideElement } from '../../hooks'
import { Button } from '../Button'
import { SaladLogo } from '../SaladLogo'
import { DropdownMenuItem } from '../base/DropdownMenuItem/DropdownMenuItem'
import { NavigationBarMessages } from './messages'

interface NavigationBarProps {
  /** The user's avatar url. */
  avatarUrl: string | undefined
  /** The user's email */
  email: string
  /** On alert button click handler TODO: Update this to required once Alerts is ready */
  onAlertsClick?: () => void
  /** The handler for opening and closing the left colummn */
  onLeftMenuClick: () => void
  /** The handler for logging out */
  onLogOut: () => void
  /** Indicates whether logout process is in progress. */
  isLogoutPending: boolean
  /** The display name of the user's organization. */
  organizationDisplayName?: string
}

export const NavigationBar: FunctionComponent<NavigationBarProps> = ({
  avatarUrl,
  email,
  isLogoutPending,
  onAlertsClick,
  onLeftMenuClick,
  onLogOut,
  organizationDisplayName,
}) => {
  const intl = useIntl()
  const [isNavBarDropdownOpen, setIsNavBarDropdownOpen] = useState(false)
  const navBarButtonRef = useRef<HTMLDivElement>(null)
  useDetectClickOutsideElement(navBarButtonRef, () => setIsNavBarDropdownOpen(false))

  const isAlertFeatureReady = false
  return (
    <header className="sticky top-0 z-50 flex h-16 w-full items-center justify-between bg-neutral-100 px-6">
      {/* LeftNavigationContent */}
      <nav aria-label={intl.formatMessage(NavigationBarMessages.leftSideNavigationAriaLabel)}>
        <Link to="/organizations" className="max-sm:hidden">
          <SaladLogo className="w-24" height="100%" width={96} />
        </Link>
        <div className="sm:hidden">
          <Button
            iconClassName="fa-solid fa-bars"
            variant="neutral-text"
            onClick={onLeftMenuClick}
            ariaLabel={intl.formatMessage(NavigationBarMessages.hamburgerMenuButtonAriaLabel)}
          />
        </div>
      </nav>

      {/* RightNavigationContent */}
      <nav
        className="flex items-center gap-x-9"
        aria-label={intl.formatMessage(NavigationBarMessages.rightSideNavigationAriaLabel)}
      >
        {isAlertFeatureReady && (
          <div className="sm:hidden">
            <Button iconClassName="fa-solid fa-bell" variant="neutral-text" onClick={onAlertsClick}>
              {intl.formatMessage(NavigationBarMessages.alertButtonLabel)}
            </Button>
          </div>
        )}

        <div ref={navBarButtonRef}>
          <button
            className="flex cursor-pointer items-center hover:text-green-60 focus:text-green-80 active:text-green-80"
            onClick={() => setIsNavBarDropdownOpen(!isNavBarDropdownOpen)}
          >
            <figure className="mr-3">
              <img
                className="size-8 rounded-full"
                src={avatarUrl || 'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y'}
                alt={intl.formatMessage(NavigationBarMessages.avatarAlt)}
              />
            </figure>
            <div className="mr-4 text-start">
              <p className="text-sm font-bold text-neutral-10">{email}</p>
              {organizationDisplayName && <p className="text-xs text-neutral-60">{organizationDisplayName}</p>}
            </div>
            <div
              className={classNames('text-neutral-60', {
                'fa-solid fa-chevron-down': !isNavBarDropdownOpen,
                'fa-solid fa-chevron-up': isNavBarDropdownOpen,
              })}
            />
          </button>

          {isNavBarDropdownOpen && (
            <ul
              className="absolute right-0 top-16 z-10 w-52 overflow-hidden rounded-md border border-neutral-50 bg-neutral-10 shadow-lg ring-1"
              onClick={() => setIsNavBarDropdownOpen(false)}
            >
              <DropdownMenuItem path="/organizations" label={intl.formatMessage(NavigationBarMessages.orgLabel)} />
              <DropdownMenuItem path="/account" label={intl.formatMessage(NavigationBarMessages.accountLabel)} />
              <DropdownMenuItem path="/api-key" label={intl.formatMessage(NavigationBarMessages.apiLabel)} hasDivider />
              <DropdownMenuItem
                isLoading={isLogoutPending}
                onClick={onLogOut}
                label={intl.formatMessage(NavigationBarMessages.logoutLabel)}
                isDanger
              />
            </ul>
          )}
        </div>
      </nav>
    </header>
  )
}
