import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { Tag } from '../../Tag'
import { FeaturePreviewPillMessages } from '../messages'

export const FeaturePreviewPill: FunctionComponent = () => {
  const intl = useIntl()
  return (
    <Tag color="blue">
      <span className="uppercase">{intl.formatMessage(FeaturePreviewPillMessages.label)}</span>
    </Tag>
  )
}
