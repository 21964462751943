import { defineMessages } from 'react-intl'

export const CreateContainerGroupGeneralErrorContentMessages = defineMessages({
  body: {
    defaultMessage:
      'We were unable to create your container group deployment. Try again and if this issue persists {contact_support}.',
    id: 'pZzByiAb',
    description:
      'The description shown in a toast notification message shown when we encounter an error on their request to create a container group.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: '54OfIsZC',
    description: 'The text shown for the contact support link in the create container group error toast notification.',
  },
  title: {
    defaultMessage: 'Error creating container group deployment',
    id: 'Xd/p8y7h',
    description:
      'The toast notification title shown when we encounter an error on their request to create a container group.',
  },
})
