import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { Button } from '../../../../components/Button'
import { ModalLayout } from '../../../../layouts/authentication'
import { verificationEmailSentMessages } from './VerificationEmailSentMessages'

export interface VerificationEmailSentProps {
  /*
   email where the link was sent
  */
  email: string
  /*
   The flag indicating the request to resend the verification
   email is being sent
  */
  isResendEmailPending: boolean
  /*
   The "Resend Email" button handler
  */
  onResendEmail: () => void
}

export const VerificationEmailSent: FunctionComponent<VerificationEmailSentProps> = ({
  email,
  isResendEmailPending,
  onResendEmail,
}) => {
  const intl = useIntl()

  const isEmailLong = email.length > 30

  return (
    <ModalLayout type="wide">
      <h1 className="text-2xl font-bold">
        {intl.formatMessage(verificationEmailSentMessages.VerificationEmailSentTitle)}
      </h1>
      <h2
        className={classNames('overflow-hidden text-ellipsis font-bold text-green-70', {
          'text-xl': isEmailLong,
          'text-2xl': !isEmailLong,
        })}
      >
        {email}
      </h2>
      <p className="text-base">{intl.formatMessage(verificationEmailSentMessages.VerificationEmailSentDescription)}</p>
      <div className="flex max-w-md gap-6">
        <Button isFullWidth onClick={onResendEmail} isLoading={isResendEmailPending} isDisabled={isResendEmailPending}>
          {intl.formatMessage(verificationEmailSentMessages.resendEmail)}
        </Button>
        <a
          className="flex w-full items-center justify-center rounded-sm border border-green-90 bg-transparent p-2 px-3 text-green-90"
          href="mailto:cloud@salad.com?subject=SaladCloud Support - Verification Email"
        >
          {intl.formatMessage(verificationEmailSentMessages.contactSupport)}
        </a>
      </div>
    </ModalLayout>
  )
}
