import { RequestStatus } from '../../models'
import type { AppState } from '../../store'

export const selectDeleteRecipeDeploymentIsPending = (state: AppState) =>
  state.recipeDeploymentDetails.deleteRecipeDeploymentRequestStatus === RequestStatus.Loading

export const selectRecipeDeployment = (state: AppState) => state.recipeDeploymentDetails.recipeDeployment

export const selectRecipeDeploymentInstances = (state: AppState) =>
  state.recipeDeploymentDetails.recipeDeploymentInstances

export const selectStartRecipeDeploymentIsPending = (state: AppState) =>
  state.recipeDeploymentDetails.startRecipeDeploymentRequestStatus === RequestStatus.Loading

export const selectStopRecipeDeploymentIsPending = (state: AppState) =>
  state.recipeDeploymentDetails.stopRecipeDeploymentRequestStatus === RequestStatus.Loading

export const selectGetRecipeDeploymentIsPending = (state: AppState) =>
  state.recipeDeploymentDetails.getRecipeDeploymentRequestStatus === RequestStatus.Loading
