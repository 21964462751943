import { RequestStatus } from '../../models'
import type { AppState } from '../../store'

export const selectCreateRecipeDeploymentSubmitting = (state: AppState) =>
  state.createRecipeDeployment.requestStatus === RequestStatus.Loading

export const selectEstimatedRecipeDeploymentCostPerHour = (state: AppState) =>
  state.createRecipeDeployment.estimatedCostPerHour

export const selectRecipeUsedToCreateDeployment = (state: AppState) => state.createRecipeDeployment.recipe

export const selectIsRecipeQuotaExceeded = (state: AppState) => state.createRecipeDeployment.isRecipeQuotaExceeded
