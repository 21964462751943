import type { FunctionComponent } from 'react'
import { Navigate } from 'react-router-dom'
import { LoadingPage } from '../../components/page/LoadingPage'
import {
  selectAuthenticatedState,
  selectCurrentAuthenticationPage,
} from '../../features/authentication/authenticationSelectors'
import { AuthenticationPage } from '../../features/authentication/models/AuthenticationPage'
import { ValuePropositionFormLayout } from '../../layouts/authentication'
import { useAppSelector } from '../../store'
import { ConnectedCreateAccountForm } from './ConnectedCreateAccountForm'
import { ConnectedLoginForm } from './ConnectedLoginForm'
import { ConnectedVerificationEmailSentPage } from './ConnectedVerificationEmailSentPage'

const renderPage = (currentPage: AuthenticationPage) => {
  switch (currentPage) {
    case AuthenticationPage.Login:
      return <ValuePropositionFormLayout Form={<ConnectedLoginForm />} />
    case AuthenticationPage.CreateAccount:
      return <ValuePropositionFormLayout Form={<ConnectedCreateAccountForm />} />
    case AuthenticationPage.VerificationEmailSent:
      return <ConnectedVerificationEmailSentPage />

    default:
      return null
  }
}

export const AuthenticationPageContainer: FunctionComponent = () => {
  const currentPage = useAppSelector(selectCurrentAuthenticationPage)
  const authenticated = useAppSelector(selectAuthenticatedState)

  if (authenticated === null) {
    return <LoadingPage />
  }

  if (authenticated === true) {
    return <Navigate to="/organizations" />
  }

  return renderPage(currentPage)
}
