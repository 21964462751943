import type { FunctionComponent } from 'react'
import { ThreeColumnLayout } from '../../layouts/threeColumnLayout'
import {
  getContainerGroupsPagePath,
  getCreateContainerGroupPagePath,
  getInferenceEndpointsMarketplacePagePath,
  getRecipeMarketplacePagePath,
} from '../../routes/routes-utils'
import { CreateProjectSteps } from '../CreateProject/components/CreateProjectSteps'
import { SelectProductMainContent } from './components/SelectProductMainContent'

export interface SelectProductPageProps {
  /** The flag indicating if the left column is showing */
  isLeftColumnOpen: boolean
  /** The callback executed when the user closes the left drawer */
  onCloseLeftDrawer: () => void
  /**
   * A callback that when executed records a mixpanel event for the element that was clicked.
   *
   * @param label The label of the element that was clicked
   */
  onRecordMixpanelElementClickedEvent: (label: string) => void
  /** The organization name. */
  organizationName: string
  /** The project name. */
  projectName: string
}

export const SelectProductPage: FunctionComponent<SelectProductPageProps> = ({
  isLeftColumnOpen,
  onCloseLeftDrawer,
  onRecordMixpanelElementClickedEvent,
  organizationName,
  projectName,
}) => (
  <ThreeColumnLayout
    isLeftColumnOpen={isLeftColumnOpen}
    LeftColumn={
      <CreateProjectSteps
        currentStep="selectProduct"
        organizationDisplayName={organizationName}
        projectDisplayName={projectName}
      />
    }
    onCloseLeftDrawer={onCloseLeftDrawer}
    MainColumn={
      <SelectProductMainContent
        containerGroupsPath={getContainerGroupsPagePath(organizationName, projectName)}
        createContainerGroupPath={getCreateContainerGroupPagePath(organizationName, projectName)}
        inferenceEndpointsMarketplacePath={getInferenceEndpointsMarketplacePagePath(organizationName)}
        onRecordMixpanelElementClickedEvent={onRecordMixpanelElementClickedEvent}
        recipesPath={getRecipeMarketplacePagePath(organizationName, projectName)}
      />
    }
  />
)
