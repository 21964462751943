/**
 * A function that determines if the password being checked meets the requirements of having at least one uppercase
 * letter, lowercase letter, one non-alphanumeric character, and one digit.
 */
export const isValidPassword = (password: string): boolean => {
  if (password.length === 0 || /^\s+$/.test(password)) {
    return false
  }

  if (password.length < 8) {
    return false
  }

  let hasSymbol = false
  for (let i = 0; i < password.length; i++) {
    if (!isLetterOrDigit(password.charCodeAt(i))) {
      hasSymbol = true
      break
    }
  }

  if (!hasSymbol) {
    return false
  }

  let hasDigit = false
  for (let i = 0; i < password.length; i++) {
    if (isDigit(password.charCodeAt(i))) {
      hasDigit = true
      break
    }
  }

  if (!hasDigit) {
    return false
  }

  let hasLower = false
  for (let i = 0; i < password.length; i++) {
    if (isLower(password.charCodeAt(i))) {
      hasLower = true
      break
    }
  }

  if (!hasLower) {
    return false
  }

  let hasUpper = false
  for (let i = 0; i < password.length; i++) {
    if (isUpper(password.charCodeAt(i))) {
      hasUpper = true
      break
    }
  }

  if (!hasUpper) {
    return false
  }

  return true
}

function isLetterOrDigit(c: number): boolean {
  return isUpper(c) || isLower(c) || isDigit(c)
}

function isDigit(c: number): boolean {
  return c >= 48 && c <= 57
}

function isLower(c: number): boolean {
  return c >= 97 && c <= 122
}

function isUpper(c: number): boolean {
  return c >= 65 && c <= 90
}
