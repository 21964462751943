import { HttpHeadersInner } from '@saladtechnologies/openapi-cloud-portal-browser'
import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { ExternalLoggingDetailsMessages } from '../../messages'

interface DatadogExternalLoggingDetailsProps {
  /** The datadog api key value. */
  datadogApiKey: string | undefined
  /** The datadog host value. */
  datadogHost: string | undefined
  /** The datadog tags value. */
  datadogTags: HttpHeadersInner[] | null | undefined
}

const colon = ':'
const comma = ','

export const DatadogExternalLoggingDetails: FunctionComponent<DatadogExternalLoggingDetailsProps> = ({
  datadogApiKey,
  datadogHost,
  datadogTags,
}) => {
  const intl = useIntl()

  return (
    <div className="text-base font-medium">
      <p>{intl.formatMessage(ExternalLoggingDetailsMessages.datadogSelectedLabel)}</p>
      <p className="truncate">
        {intl.formatMessage(ExternalLoggingDetailsMessages.hostLabel)}
        {colon}
        &nbsp;
        <span className="font-normal">{datadogHost ?? ''}</span>
      </p>
      <p className="truncate">
        {intl.formatMessage(ExternalLoggingDetailsMessages.apiKeyLabel)}
        {colon}
        &nbsp;
        <span className="font-normal">{datadogApiKey ?? ''}</span>
      </p>
      {datadogTags && datadogTags.length > 0 && (
        <p className="truncate">
          {intl.formatMessage(ExternalLoggingDetailsMessages.tagsLabel)}
          {colon}
          &nbsp;
          <span className="font-normal">
            {datadogTags.map((tag, index) => {
              if (index === datadogTags.length - 1) {
                return `${tag.name}${colon}${tag.value}`
              }

              return `${tag.name}${colon}${tag.value}${comma} `
            })}
          </span>
        </p>
      )}
    </div>
  )
}
