import classNames from 'classnames'
import { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { Link } from '../../../../components/base'
import { PresetContainerMessages } from '../../messages'
import { CreateContainerGroupValues, UbuntuPresetValues } from '../../models'
import { applyPresetConfig } from '../../utils'
import { PresetContainerCard } from '../PresetContainerCard/PresetContainerCard'

export interface PresetContainerProps {
  /** The function to handle card click event. */
  onCardClick: (values: Partial<CreateContainerGroupValues>) => void
}

export const PresetContainer: FunctionComponent<PresetContainerProps> = ({ onCardClick }) => {
  const intl = useIntl()
  const navigate = useNavigate()

  // const handleOnCardClickDocker = () => {
  //   applyPresetConfig(onCardClick)
  // }

  const handleOnCardClickUbuntu = () => {
    applyPresetConfig(onCardClick, UbuntuPresetValues)
  }

  const handleOnCardClickCustom = () => {
    applyPresetConfig(onCardClick)
  }

  return (
    <div className="relative h-screen w-full pb-36">
      <div className="h-full overflow-auto px-6 pt-8">
        <div className="mb-36 w-full max-w-4xl">
          <div className="mb-4">
            <button className="text-blue-90 underline" onClick={() => navigate(-1)}>
              <span className={classNames('fa-solid fa-arrow-left mr-2')} />
              {intl.formatMessage(PresetContainerMessages.backLinkText)}
            </button>
          </div>
          <h1 className="mb-3 text-3xl font-bold">
            {intl.formatMessage(PresetContainerMessages.presetContainerTitle)}
          </h1>
          <h3 className="mb-10 text-lg">{intl.formatMessage(PresetContainerMessages.presetContainerSubtitle)}</h3>
          <h3 className="mb-4 text-lg">
            <strong>{intl.formatMessage(PresetContainerMessages.featuredText)}</strong>
          </h3>
          <div className="mb-6 grid grid-cols-2 gap-4 sm:grid-cols-3">
            <div className="h-64 w-72">
              <PresetContainerCard
                buttonLabel={intl.formatMessage(PresetContainerMessages.getStartedButtonLabel)}
                title={intl.formatMessage(PresetContainerMessages.ubuntuCardTitle)}
                description={intl.formatMessage(PresetContainerMessages.ubuntuCardDescription, {
                  learn_more: (
                    <Link url="https://docs.salad.com/container-engine/guides/ubuntu-image">
                      {intl.formatMessage(PresetContainerMessages.learnMoreText)}
                    </Link>
                  ),
                })}
                onClick={handleOnCardClickUbuntu}
              />
            </div>
            {/* Commented out until ready to go
            <div className="h-64 w-72">
              <PresetContainerCard
                buttonLabel={intl.formatMessage(PresetContainerMessages.getStartedButtonLabel)}
                description={intl.formatMessage(PresetContainerMessages.dockerDescription)}
                onClick={handleOnCardClickDocker}
                title={intl.formatMessage(PresetContainerMessages.dockerTitle)}
              />
            </div> */}
          </div>
          <hr className="mb-6 border-neutral-30" />
          <PresetContainerCard
            buttonLabel={intl.formatMessage(PresetContainerMessages.getStartedButtonLabel)}
            title={intl.formatMessage(PresetContainerMessages.customContainerGroupCardTitle)}
            description={intl.formatMessage(PresetContainerMessages.customContainerGroupCardDescription, {
              learn_more: (
                <Link url="https://docs.salad.com/container-engine/quickstart">
                  {intl.formatMessage(PresetContainerMessages.learnMoreText)}
                </Link>
              ),
            })}
            buttonVariant="green-filled"
            onClick={handleOnCardClickCustom}
          />
        </div>
      </div>
    </div>
  )
}
