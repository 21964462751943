import type { ReactNode } from 'react'
import type { IntlShape } from 'react-intl'
import type { ToastNotificationType } from '../../models'
import { DeleteOrganizationSucceededContentMessages } from './messages'

/**
 * Retrieves the content needed for a success `ToastNotification` that is shown when the user deletes their
 * organization.
 *
 * @param intl The `IntlShape` object needed to format the messages.
 * @param organizationName The organization name.
 */
export const getDeleteOrganizationSucceededContent = (
  intl: IntlShape,
  organizationName: string,
): {
  body: ReactNode
  type: ToastNotificationType
  title: string
} => {
  return {
    body: intl.formatMessage(DeleteOrganizationSucceededContentMessages.body, {
      organization_name: organizationName,
    }),
    type: 'success',
    title: intl.formatMessage(DeleteOrganizationSucceededContentMessages.title, {
      organization_name: organizationName,
    }),
  }
}
