import type { FunctionComponent } from 'react'
import { useCallback } from 'react'
import { sendResetPasswordEmail } from '../../features/forgotPassword/forgotPasswordSlice'
import { selectRequestStatusIsPending } from '../../features/requestStatus/requestStatusSelectors'
import { useAppDispatch, useAppSelector } from '../../store'
import { ForgotPassword } from './components/ForgotPassword'

export const ConnectedForgotPasswordPage: FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const sendResetPasswordEmailIsPending = useAppSelector((state) =>
    selectRequestStatusIsPending(state, 'sendResetPasswordEmail'),
  )

  const onSendResetPasswordEmail = useCallback(
    (email: string) => {
      dispatch(sendResetPasswordEmail({ email }))
    },
    [dispatch],
  )

  return (
    <ForgotPassword
      isSubmitPending={sendResetPasswordEmailIsPending}
      onSubmit={({ email }) => onSendResetPasswordEmail(email)}
    />
  )
}
