import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export interface EditContainerGroupState {
  isContainerGroupInstancesQuotaExceededModalShowing: boolean
}

const initialState: EditContainerGroupState = {
  isContainerGroupInstancesQuotaExceededModalShowing: false,
}

export const editContainerGroupSlice = createSlice({
  name: 'editContainerGroup',
  initialState,
  reducers: {
    resetEditContainerGroupState(state) {
      state.isContainerGroupInstancesQuotaExceededModalShowing = false
    },
    setIsContainerGroupInstancesQuotaExceededModalShowing(state, action: PayloadAction<{ showing: boolean }>) {
      state.isContainerGroupInstancesQuotaExceededModalShowing = action.payload.showing
    },
  },
})

export const { resetEditContainerGroupState, setIsContainerGroupInstancesQuotaExceededModalShowing } =
  editContainerGroupSlice.actions
