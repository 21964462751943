import { defineMessages } from 'react-intl'

export const LoadCreditDashboardGeneralErrorContentMessages = defineMessages({
  body: {
    defaultMessage:
      'We were unable to load the credits dashboard for your organization. If this issue persists {contact_support}.',
    id: '1WYDWvX2',
    description:
      'The description shown in a toast notification message shown when we encounter an error loading the billing credits dashboard.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'sLlWLCpB',
    description: 'The text shown for the contact support link in the load credits dashboard error toast notification.',
  },
  title: {
    defaultMessage: 'Error loading credits dashboard',
    id: 'Htyblty1',
    description: 'The toast notification title shown when we encounter an error loading the billing credits dashboard.',
  },
})
