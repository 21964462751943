import { defineMessages } from 'react-intl'

export const CreateProjectGeneralErrorContentMessages = defineMessages({
  body: {
    defaultMessage:
      'We were unable to create your new project. Try again and if this issue persists {contact_support}.',
    id: 'YwIwa4I4',
    description:
      'The description shown in a toast notification message shown when we encounter an error on their request to create the project.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'S53Tuil9',
    description: 'The text shown for the contact support link in the create project error toast notification.',
  },
  title: {
    defaultMessage: 'Error creating project',
    id: 'jSQRk/yn',
    description:
      'The toast notification title shown when we encounter an error on their request to create the project.',
  },
})
