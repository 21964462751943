import { defineMessages } from 'react-intl'

export const BillingMainContentMessages = defineMessages({
  billingInformationTabLabel: {
    defaultMessage: 'Billing Information',
    id: 'WHVYREor',
    description: 'The label for the billing information tab.',
  },
  creditsTabLabel: {
    defaultMessage: 'Credits',
    id: 'Kek9ujyL',
    description: 'The label for the credits tab.',
  },
  description: {
    defaultMessage: 'Review previous invoice and manage your payment profiles',
    id: 'Jl6CmV1f',
    description: 'The description for the Billing page.',
  },
  invoicesTabLabel: {
    defaultMessage: 'Invoices',
    id: 'pbgv+Z8t',
    description: 'The label for the invoices tab.',
  },
  returnToPreviousPageLinkText: {
    defaultMessage: 'Return to previous page',
    id: 'BQAF8CIV',
    description: 'The text for the link that returns the user back to the previous page they were viewing.',
  },
  title: {
    defaultMessage: '{organization_display_name} Billing',
    id: '4QWdjfQQ',
    description: 'The title of for the Billing page.',
  },
  usageTabLabel: {
    defaultMessage: 'Usage',
    id: 'DBpRd/4g',
    description: 'The label for the usage tab.',
  },
})
