import { yupResolver } from '@hookform/resolvers/yup'
import classNames from 'classnames'
import { useEffect, type FunctionComponent } from 'react'
import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Button } from '../../../../components/Button'
import { TextField } from '../../../../components/TextField'
import { Link } from '../../../../components/base'
import { useFixedForm } from '../../../../hooks'
import { InviteTeamMemberMainContentMessages } from '../../messages'
import { useInviteTeamMemberFormValidation } from './useInviteTeamMemberFormValidation'

export interface InviteTeamMemberValues {
  /** The email address. */
  email: string
}

interface InviteTeamMemberPageMainContentProps {
  /** The flag indicating that the request to invite a team member is pending. */
  isInviteTeamMemberPending: boolean
  /** The flag indicating that the request to invite a team member is successful. */
  isInviteTeamMemberSuccessful: boolean
  /** A callback that when executed revokes the invitation sent */
  onInviteTeamMember: (email: string) => void
  /** The organization display name. */
  organizationDisplayName: string
  /** The path for the Team Page. */
  teamPagePath: string
}

export const InviteTeamMemberPageMainContent: FunctionComponent<InviteTeamMemberPageMainContentProps> = ({
  isInviteTeamMemberPending,
  isInviteTeamMemberSuccessful,
  onInviteTeamMember,
  organizationDisplayName,
  teamPagePath,
}) => {
  const intl = useIntl()
  const inviteTeamMemberValidationScheme = useInviteTeamMemberFormValidation()
  const emailFieldName = 'email'
  const { control, handleSubmit, reset } = useFixedForm<InviteTeamMemberValues>({
    defaultValues: { [emailFieldName]: '' },
    resolver: yupResolver(inviteTeamMemberValidationScheme),
    onSubmit: ({ email }) => onInviteTeamMember(email),
  })

  useEffect(() => {
    if (isInviteTeamMemberSuccessful) {
      reset()
    }
  }, [isInviteTeamMemberSuccessful, reset])

  return (
    <div className="w-full max-w-md">
      <div className="mb-4">
        <Link url={teamPagePath}>
          <span className={classNames('fa-solid fa-arrow-left mr-2')} />
          {intl.formatMessage(InviteTeamMemberMainContentMessages.returnToTeamPageLinkText, {
            organization_display_name: organizationDisplayName,
          })}
        </Link>
      </div>
      <h1 className="mb-3 text-3xl font-bold">{intl.formatMessage(InviteTeamMemberMainContentMessages.title)}</h1>
      <p className="mb-10 text-base">{intl.formatMessage(InviteTeamMemberMainContentMessages.description)}</p>
      <form onSubmit={handleSubmit}>
        <Controller
          name={emailFieldName}
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              {...fieldState}
              defaultValue={field.value}
              helperText={fieldState.error?.message}
              placeholder={intl.formatMessage(
                InviteTeamMemberMainContentMessages.inviteTeamMemberEmailFieldPlaceholder,
              )}
              label={intl.formatMessage(InviteTeamMemberMainContentMessages.inviteTeamMemberEmailFieldLabel)}
              isFullWidth
            />
          )}
        />
        <Button type="submit" isLoading={isInviteTeamMemberPending} isDisabled={isInviteTeamMemberPending} isFullWidth>
          {intl.formatMessage(InviteTeamMemberMainContentMessages.inviteTeamMemberButtonLabel)}
        </Button>
      </form>
    </div>
  )
}
