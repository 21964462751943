import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { Breadcrumbs } from '../../../../components/block'
import { VerticalProgressBar } from '../../../../components/block/VerticalProgressBar'
import { CreateProjectStepsMessages } from '../../messages'

type CreateProjectStep = 'setupYourProject' | 'selectProduct' | 'configureAndDeploy'

export interface CreateProjectStepsProps {
  /** The create project step. */
  currentStep: CreateProjectStep
  /** The organization display name. */
  organizationDisplayName: string
  /** The project display name. */
  projectDisplayName: string
}

export const CreateProjectSteps: FunctionComponent<CreateProjectStepsProps> = ({
  currentStep,
  organizationDisplayName,
  projectDisplayName,
}) => {
  const intl = useIntl()

  return (
    <div className="mx-5 mt-8 flex flex-col text-neutral-100">
      <p className="mb-2 text-xs">{intl.formatMessage(CreateProjectStepsMessages.header)}</p>
      <div className="mb-4 mt-2">
        <Breadcrumbs breadcrumbs={[organizationDisplayName, projectDisplayName]} />
      </div>

      <div className="flex h-32 items-center gap-8">
        <VerticalProgressBar
          steps={[
            {
              label: intl.formatMessage(CreateProjectStepsMessages.setupProjectStep),
              status: currentStep === 'setupYourProject' ? 'current' : 'completed',
            },
            {
              label: intl.formatMessage(CreateProjectStepsMessages.selectProductStep),
              status:
                currentStep === 'setupYourProject'
                  ? 'incomplete'
                  : currentStep === 'selectProduct'
                    ? 'current'
                    : 'completed',
            },
            {
              label: intl.formatMessage(CreateProjectStepsMessages.configureAndDeployStep),
              status:
                currentStep === 'setupYourProject' || currentStep === 'selectProduct'
                  ? 'incomplete'
                  : currentStep === 'configureAndDeploy'
                    ? 'current'
                    : 'completed',
            },
          ]}
        />
      </div>
    </div>
  )
}
