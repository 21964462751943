import { defineMessages } from 'react-intl'

export const XTerminalMessages = defineMessages({
  terminalConnectionHasClosed: {
    defaultMessage: 'The Terminal connection has closed. Please refresh if you would like to connect again.',
    id: 'n86yq6Od',
    description: 'The terminal closed message',
  },
  terminalHasError: {
    defaultMessage: 'If the terminal does not connect in 5 seconds, refresh your screen.',
    id: 'mamAbUMC',
    description: 'The terminal error message',
  },
  terminalIsConnected: {
    defaultMessage: 'Terminal has successfully connected.',
    id: 'xn/0lEYl',
    description: 'The terminal connected message',
  },
  terminalTitle: {
    defaultMessage: 'Terminal',
    id: 'xj2E1IoD',
    description: 'The terminal title',
  },
})
