import type { AppState } from '../../store'

export const selectContainerGroupInstances = (state: AppState) => state.containerGroupInstanceTable.instances

// TODO: All selectors below be moved to their own slice when dealing with calls from the instance details page
export const selectIsReallocateContainerGroupInstanceStatusPending = (state: AppState, machineId: string) => {
  return (
    state.containerGroupInstanceTable.instances.find((instance) => instance.machineId === machineId)
      ?.isReallocatePending ?? false
  )
}

export const selectIsRecreateContainerGroupInstanceStatusPending = (state: AppState, machineId: string) => {
  return (
    state.containerGroupInstanceTable.instances.find((instance) => instance.machineId === machineId)
      ?.isRecreatePending ?? false
  )
}

export const selectIsRestartContainerGroupInstanceStatusPending = (state: AppState, machineId: string) => {
  return (
    state.containerGroupInstanceTable.instances.find((instance) => instance.machineId === machineId)
      ?.isRestartPending ?? false
  )
}
