import { createSlice } from '@reduxjs/toolkit'
import type { ContainerGroupStatus } from '@saladtechnologies/openapi-cloud-portal-browser'
import {
  deleteContainerGroup,
  setContainerGroup,
  setIsReallocateContainerGroupInstancePending,
  setIsRecreateContainerGroupInstancePending,
  setIsRestartContainerGroupInstancePending,
  stopContainerGroup,
} from '../containerGroupDetail/containerGroupDetailSlice'
import { containerGroupInstancesAdded } from '../containerGroupInstances/containerGroupInstancesSlice'
import type { DeploymentInstance } from './models'
import {
  configureContainerGroupDeploymentInstances,
  convertContainerGroupImageSizeToMB,
  getAllocatedInstances,
  updateDeploymentInstanceRequestPending,
} from './utils'

interface ContainerGroupInstanceTableState {
  containerImageSize: number | undefined
  containerGroupReplicaCount: number
  containerGroupReplicasRunning: number
  containerGroupStartTime: Date | null
  containerGroupStatus: ContainerGroupStatus | undefined
  instances: DeploymentInstance[]
}

const initialState: ContainerGroupInstanceTableState = {
  containerImageSize: undefined,
  containerGroupReplicaCount: 1,
  containerGroupReplicasRunning: 0,
  containerGroupStartTime: null,
  containerGroupStatus: undefined,
  instances: [],
}

export const containerGroupInstanceTableSlice = createSlice({
  name: 'containerGroupInstanceTable',
  initialState,
  reducers: {
    getUpToDateInstances: (state) => {
      if (state.containerGroupStatus === 'running') {
        state.instances = state.instances.map((instance) => {
          return {
            ...instance,
            updateCount: instance.updateCount ? instance.updateCount + 1 : 1,
          }
        })
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(containerGroupInstancesAdded, (state, action) => {
      const containerGroupInstances = action.payload.instances
      const containerGroupStartTime = state.containerGroupStartTime || new Date()
      const containerGroupReplicaCount = state.containerGroupReplicaCount
      const containerGroupReplicasRunning = state.containerGroupReplicasRunning
      const containerGroupStatus = state.containerGroupStatus

      if (containerGroupStatus === 'pending' || containerGroupStatus === undefined) {
        state.instances = []
        return
      }

      if (containerGroupStatus === 'stopped') {
        if (containerGroupReplicasRunning === 0) {
          state.instances = []
          return
        }

        state.instances = state.instances.map((instance) => {
          return {
            ...instance,
            status: 'stopping',
          }
        })
      }

      if (state.instances.length === 0 && containerGroupInstances.length === 0) {
        state.instances = getAllocatedInstances(containerGroupStartTime, containerGroupReplicaCount)
        return
      }

      const allocatedInstancesNeeded = containerGroupReplicaCount - containerGroupInstances.length
      const allocatedInstances = getAllocatedInstances(containerGroupStartTime, allocatedInstancesNeeded)
      const containerImageSizeInMB = convertContainerGroupImageSizeToMB(state.containerImageSize)
      const deploymentInstances = configureContainerGroupDeploymentInstances(
        containerGroupInstances,
        containerImageSizeInMB,
      )

      state.instances = [...deploymentInstances, ...allocatedInstances]
    })

    builder.addCase(setContainerGroup, (state, action) => {
      state.containerGroupReplicaCount = action.payload.containerGroup.replicas
      state.containerGroupStartTime = action.payload.containerGroup.currentState.startTime
      state.containerImageSize = action.payload.containerGroup.container.size
      state.containerGroupStatus = action.payload.containerGroup.currentState.status
      state.containerGroupReplicasRunning = action.payload.containerGroup.currentState.instanceStatusCounts.runningCount
    })

    builder.addCase(setIsReallocateContainerGroupInstancePending, (state, action) => {
      const { instanceId, isPending } = action.payload
      const instanceToUpdate = state.instances.find((instance) => instance.instanceId === instanceId)

      if (instanceToUpdate) {
        state.instances = updateDeploymentInstanceRequestPending(state.instances, instanceId, isPending, 'reallocate')
      }
    })

    builder.addCase(setIsRecreateContainerGroupInstancePending, (state, action) => {
      const { instanceId, isPending } = action.payload
      const instanceToUpdate = state.instances.find((instance) => instance.instanceId === instanceId)

      if (instanceToUpdate) {
        state.instances = updateDeploymentInstanceRequestPending(state.instances, instanceId, isPending, 'recreate')
      }
    })

    builder.addCase(setIsRestartContainerGroupInstancePending, (state, action) => {
      const { instanceId, isPending } = action.payload
      const instanceToUpdate = state.instances.find((instance) => instance.instanceId === instanceId)

      if (instanceToUpdate) {
        state.instances = updateDeploymentInstanceRequestPending(state.instances, instanceId, isPending, 'restart')
      }
    })

    builder.addCase(stopContainerGroup, (state) => {
      const deploymentInstancesWithoutAllocating = state.instances.filter(
        (instance) => instance.status !== 'allocating',
      )
      state.instances = deploymentInstancesWithoutAllocating.map((instance) => {
        return {
          ...instance,
          status: 'stopping',
        }
      })

      state.containerGroupStartTime = null
    })

    builder.addCase(deleteContainerGroup, (state) => {
      state.containerGroupReplicaCount = 1
      state.containerGroupStartTime = null
      state.instances = []
      state.containerImageSize = undefined
      state.containerGroupStatus = undefined
    })
  },
})

export const { getUpToDateInstances } = containerGroupInstanceTableSlice.actions
