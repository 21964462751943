import { yupResolver } from '@hookform/resolvers/yup'
import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import { useState } from 'react'
import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Button } from '../../../../components/Button'
import { TextField } from '../../../../components/TextField'
import { Link } from '../../../../components/base'
import { useFixedForm } from '../../../../hooks'
import { EditOrganizationMainContentMessages } from '../../messages'
import { useEditOrganizationFormValidation } from './useEditOrganizationFormValidation'

export interface EditOrganizationValues {
  /** The organization display name. */
  displayName: string
}

interface EditOrganizationPageMainContentProps {
  /** The flag indicating that the request to edit the organization is pending. */
  isSubmitPending: boolean
  /**
   * A callback that is executed when the user submits the form to edit the organization.
   *
   * @param displayName The organization display name.
   */
  onEditOrganization: (displayName: string) => void
  /** The organization display name. */
  organizationDisplayName: string
  /** The organization name. */
  organizationName: string
}

export const EditOrganizationMainContent: FunctionComponent<EditOrganizationPageMainContentProps> = ({
  isSubmitPending,
  onEditOrganization,
  organizationDisplayName,
  organizationName,
}) => {
  const intl = useIntl()
  const editOrganizationValidationScheme = useEditOrganizationFormValidation()
  const { control, handleSubmit, register } = useFixedForm<EditOrganizationValues>({
    resolver: yupResolver(editOrganizationValidationScheme),
    onSubmit: ({ displayName }) => onEditOrganization(displayName),
  })
  const [isDifferentDisplayName, toggleIsDifferentDisplayName] = useState<boolean>(false)

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const displayNameChanged = event.target.value !== organizationDisplayName
    toggleIsDifferentDisplayName(displayNameChanged)
  }

  return (
    <div className="w-full max-w-xl">
      <div className="mb-6">
        <Link url="/organizations">
          <span className={classNames('fa-solid fa-arrow-left mr-2')} />
          {intl.formatMessage(EditOrganizationMainContentMessages.returnToOrganizationsLinkText)}
        </Link>
      </div>

      <h1 className="mb-10 text-3xl font-bold">{intl.formatMessage(EditOrganizationMainContentMessages.title)}</h1>

      <h2 className="mb-3 text-base font-bold">
        {intl.formatMessage(EditOrganizationMainContentMessages.organizationNameHeader)}
      </h2>
      <p className="mb-3 text-xl font-bold text-green-80">{organizationName}</p>
      <p className="mb-10 text-base">
        {intl.formatMessage(EditOrganizationMainContentMessages.organizationNameDescription)}
      </p>

      <form onSubmit={handleSubmit}>
        <Controller
          name="displayName"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...register('displayName', { onChange: handleOnChange })}
              {...field}
              {...fieldState}
              defaultValue={organizationDisplayName}
              helperText={fieldState.error?.message}
              label={intl.formatMessage(EditOrganizationMainContentMessages.displayNameFieldLabel)}
              isFullWidth
            />
          )}
        />
        <Button
          type="submit"
          isLoading={isSubmitPending}
          isDisabled={!isDifferentDisplayName || isSubmitPending}
          isFullWidth
        >
          {intl.formatMessage(EditOrganizationMainContentMessages.editOrganizationButtonLabel)}
        </Button>
      </form>
    </div>
  )
}
