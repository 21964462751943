import { defineMessages } from 'react-intl'

export const CreateContainerGroupMainContentMessages = defineMessages({
  backLinkText: {
    defaultMessage: 'Back',
    id: '1Y9tiwor',
    description: 'The text used for the back button link.',
  },
  configureButtonLabel: {
    defaultMessage: 'Configure',
    id: 'n6dx4XwL',
    description:
      'The button label for the create container group side panel modal that is shown when updating a field.',
  },
  deployButtonLabel: {
    defaultMessage: 'Deploy',
    id: '/xjPuiFr',
    description: 'The button label for the create container group wizard form.',
  },
  editInputText: {
    defaultMessage: 'Edit',
    id: 'mvmccOpV',
    description:
      'The text used for any input field on the create container group page that takes the user to update the input field.',
  },
  hardwareResourceCostPerHourLabel: {
    defaultMessage: '{cost_per_hour} per hour',
    id: 'nTA6A1Np',
    description:
      'The hardware resource cost per hour label shown for allocated resource option input fields on the create container group page.',
  },
  healthCheckProbesSectionHeader: {
    defaultMessage: 'Health Check Probes',
    id: 'X0AHsN8k',
    description: 'The header for the Health Check Probes section of the Create Container Group page.',
  },
  highDemandLabel: {
    defaultMessage: 'High Demand',
    id: 'NJGPUnsB',
    description: 'The label for the high demand option for the GPU input field.',
  },
  imageSourceSectionHeader: {
    defaultMessage: 'Image Source',
    id: 'G01iPaxU',
    description: 'The header for the Image Source section of the Create Container Group page.',
  },
  learnMoreButtonLabel: {
    defaultMessage: 'Learn More',
    id: 'W95KE7iV',
    description:
      'The button label for any learn more button shown below input fields for the create container group form.',
  },
  loggingSectionHeader: {
    defaultMessage: 'External Logging Services',
    id: '0midEqSb',
    description: 'The header for the External Logging Services section of the Create Container Group page.',
  },
  optionalSettingsSectionHeader: {
    defaultMessage: 'Optional Settings',
    id: 'BB92X7nf',
    description: 'The header for the Optional Settings section of the Create Container Group page.',
  },
  permanentNameText: {
    defaultMessage: 'permanent name',
    id: 'fCuOGbde',
    description: 'The text used for the permanent name text in the subtitle that will be bolded.',
  },
  resourceAllocationSectionHeader: {
    defaultMessage: 'Resource Allocation',
    id: 'H6p2CZz/',
    description: 'The header for the Resource Allocation section of the Create Container Group page.',
  },
  subtitle: {
    defaultMessage:
      'Please type in a {permanent_name} for your deployment. This will be used in API calls. You can change display names shown in the Portal later.',
    id: '0xD/ZlER',
    description: 'The subtitle for the Create Container Group page.',
  },
  title: {
    defaultMessage: 'Configure & Deploy a Container Group',
    id: 'Zgo9Zpfu',
    description: 'The title for the Create Container Group page.',
  },
})
