import type { FunctionComponent } from 'react'
import type { Control, FieldValues } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Checkbox } from '../../../../components/Checkbox'
import { AutostartInputMessages } from '../../messages'
import type { CreateContainerGroupValues } from '../../models'
import { CreateContainerGroupField } from '../../models'

interface AutostartInputProps {
  /** The control for the create container group react hook form. */
  control: Control<FieldValues, CreateContainerGroupValues>
}

export const AutostartInput: FunctionComponent<AutostartInputProps> = ({ control }) => {
  const intl = useIntl()

  return (
    <Controller
      name={CreateContainerGroupField.AUTOSTART_POLICY}
      control={control}
      render={({ field, fieldState }) => {
        return (
          <Checkbox
            {...field}
            {...fieldState}
            isChecked={field.value as boolean}
            id="autostart-policy-checkbox"
            label={intl.formatMessage(AutostartInputMessages.autostartDescription)}
          />
        )
      }}
    />
  )
}
