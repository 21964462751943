import { defineMessages } from 'react-intl'

export const LoadPaymentMethodErrorMessages = defineMessages({
  body: {
    defaultMessage: 'Failed to retrieve payment and billing information. Please try again later.',
    id: 'lpu1yFaR',
    description:
      'The description shown in a toast notification message shown when we encounter an error loading the payment method.',
  },
  title: {
    defaultMessage: 'Failed to load payment method',
    id: 'Q5X5ZIUC',
    description: 'The toast notification title shown when we encounter an error loading the payment method.',
  },
})
