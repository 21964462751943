import type { FunctionComponent } from 'react'
import { useCallback, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { LoadingPage } from '../../components/page/LoadingPage'
import { trackMixpanelElementClickedEvent, trackMixpanelPageViewedEvent } from '../../features/analytics/analyticsSlice'
import {
  selectPaymentMethodBillingClientSecret,
  selectPaymentMethodBillingStatus,
} from '../../features/billing/billingSelectors'
import {
  createPaymentMethodSetupIntent,
  getBillingCustomerPortal,
  getBillingPageData,
  getPaymentMethodSetupStatus,
  removePaymentMethod,
  showStripeFormError,
  unsubscribeFromBilling,
} from '../../features/billing/billingSlice'
import {
  selectBillingCreditsDashboardEmbedUrl,
  selectBillingInvoiceDashboardEmbedUrl,
  selectBillingUsageDashboardEmbedUrl,
} from '../../features/billingDashboards/billingDashboardsSelectors'
import { selectIsLeftColumnShowing } from '../../features/navigationBar/navigationBarSelectors'
import { setLeftColumnShowing } from '../../features/navigationBar/navigationBarSlice'
import {
  selectOrganizationDisplayName,
  selectOrganizationHasHadValidPayment,
} from '../../features/organizations/organizationsSelectors'
import { selectPaymentMethod } from '../../features/paymentMethod/paymentMethodSelectors'
import { selectProfileEmail } from '../../features/profile/profileSelectors'
import { selectProjects } from '../../features/projects/projectsSelectors'
import {
  selectRequestStatusIsPending,
  selectRequestStatusIsSucceeded,
} from '../../features/requestStatus/requestStatusSelectors'
import { organizationBillingPageRoutePath } from '../../routes/routePaths'
import { getContainerGroupsPagePath, getOrganizationBillingPagePath } from '../../routes/routes-utils'
import { useAppDispatch, useAppSelector } from '../../store'
import { getRequestDeploymentQuotaIncreaseLink } from '../../utils'
import { BillingPage } from './BillingPage'

export const ConnectedBillingPage: FunctionComponent = () => {
  const { organizationName = '' } = useParams()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const isLeftColumnOpen = useAppSelector(selectIsLeftColumnShowing)
  const organizationDisplayName = useAppSelector((state) => selectOrganizationDisplayName(state, organizationName))
  const projects = useAppSelector((state) => selectProjects(state, organizationName))?.projects || []
  const hasNotHadValidPayment =
    useAppSelector((state) => selectOrganizationHasHadValidPayment(state, organizationName)) === false
  const billingCreditsEmbedUrl = useAppSelector((state) =>
    selectBillingCreditsDashboardEmbedUrl(state, organizationName),
  )
  const billingInvoiceEmbedUrl = useAppSelector((state) =>
    selectBillingInvoiceDashboardEmbedUrl(state, organizationName),
  )
  const billingUsageEmbedUrl = useAppSelector((state) => selectBillingUsageDashboardEmbedUrl(state, organizationName))
  const paymentMethod = useAppSelector((state) => selectPaymentMethod(state, organizationName))
  const paymentMethodBillingStatus = useAppSelector(selectPaymentMethodBillingStatus)
  const paymentMethodBillingClientSecret = useAppSelector(selectPaymentMethodBillingClientSecret)
  const isLoadBillingCustomerPortalPending = useAppSelector((state) =>
    selectRequestStatusIsPending(state, 'getBillingCustomerPortal'),
  )
  const isRemovePaymentMethodPending = useAppSelector((state) =>
    selectRequestStatusIsPending(state, 'removePaymentMethod'),
  )
  const isRemovePaymentMethodRequestStatusSucceeded = useAppSelector((state) =>
    selectRequestStatusIsSucceeded(state, 'removePaymentMethod'),
  )
  const isGetPaymentMethodSetupIntentStatusPending = useAppSelector((state) =>
    selectRequestStatusIsPending(state, 'getPaymentMethodSetupIntent'),
  )
  const isGetBillingPageDataRequestStatusPending = useAppSelector((state) =>
    selectRequestStatusIsPending(state, 'getBillingPageData'),
  )
  const isCreatePaymentMethodSetupIntentStatusPending = useAppSelector((state) =>
    selectRequestStatusIsPending(state, 'createPaymentMethodSetupIntent'),
  )

  const email = useAppSelector(selectProfileEmail)

  const linkToRequestIncreaseForDeploymentQuotas = getRequestDeploymentQuotaIncreaseLink(
    email as string,
    organizationName,
  )

  const projectSelectOptions = projects.map((project) => {
    return {
      ...project,
      selected: false,
    }
  })

  const handleCloseLeftNavBar = useCallback(() => {
    dispatch(setLeftColumnShowing({ showing: false }))
  }, [dispatch])

  const handleProjectChange = useCallback(
    (projectName: string) => {
      navigate(getContainerGroupsPagePath(organizationName, projectName))
    },
    [navigate, organizationName],
  )

  const handleManagePaymentMethod = useCallback(() => {
    dispatch(createPaymentMethodSetupIntent({ organizationName }))
  }, [dispatch, organizationName])

  const handleGetBillingCustomerPortal = useCallback(() => {
    if (organizationName) {
      dispatch(
        getBillingCustomerPortal({ organizationName, redirectPath: getOrganizationBillingPagePath(organizationName) }),
      )
    }
  }, [dispatch, organizationName])

  const handleRemovePaymentMethod = useCallback(() => {
    if (organizationName) {
      dispatch(removePaymentMethod({ organizationName }))
    }
  }, [dispatch, organizationName])

  const handleSubmitBillingFormSucceeded = useCallback(() => {
    if (organizationName) {
      dispatch(getPaymentMethodSetupStatus({ organizationName }))
    }
  }, [dispatch, organizationName])

  const handleShowStripeFormError = useCallback(
    (message?: string, status?: string) => {
      dispatch(showStripeFormError({ message, status }))
    },
    [dispatch],
  )

  const onRecordMixpanelElementClickedEvent = useCallback(
    (label: string) => {
      dispatch(
        trackMixpanelElementClickedEvent({
          label,
          path: organizationBillingPageRoutePath,
          organizationName,
        }),
      )
    },
    [dispatch, organizationName],
  )

  useEffect(() => {
    if (organizationName) {
      dispatch(getBillingPageData({ organizationName }))
    }

    return () => {
      dispatch(unsubscribeFromBilling({ organizationName }))
    }
  }, [dispatch, organizationName])

  useEffect(() => {
    dispatch(trackMixpanelPageViewedEvent({ path: organizationBillingPageRoutePath, organizationName }))
  }, [dispatch, organizationName])

  return isGetBillingPageDataRequestStatusPending ? (
    <LoadingPage />
  ) : (
    <BillingPage
      billingCreditsEmbedUrl={billingCreditsEmbedUrl}
      billingInvoiceEmbedUrl={billingInvoiceEmbedUrl}
      billingStatus={paymentMethodBillingStatus}
      billingUsageEmbedUrl={billingUsageEmbedUrl}
      currentOrganization={{ name: organizationName, displayName: organizationDisplayName ?? organizationName }}
      hasNotHadValidPayment={hasNotHadValidPayment}
      isCreatePaymentSessionInProgress={isCreatePaymentMethodSetupIntentStatusPending}
      isLeftColumnOpen={isLeftColumnOpen}
      isLoadBillingCustomerPortalPending={isLoadBillingCustomerPortalPending}
      isPaymentMethodVerificationInProgress={isGetPaymentMethodSetupIntentStatusPending}
      isRemovePaymentMethodPending={isRemovePaymentMethodPending}
      isRemovePaymentMethodSuccessful={isRemovePaymentMethodRequestStatusSucceeded}
      linkToRequestIncreaseForDeploymentQuotas={linkToRequestIncreaseForDeploymentQuotas}
      onCloseLeftDrawer={handleCloseLeftNavBar}
      onManageBilling={handleGetBillingCustomerPortal}
      onManagePaymentMethod={handleManagePaymentMethod}
      onProjectChange={handleProjectChange}
      onRecordMixpanelElementClickedEvent={onRecordMixpanelElementClickedEvent}
      onRemovePaymentMethod={handleRemovePaymentMethod}
      onSubmitFormError={handleShowStripeFormError}
      onSubmitFormSucceeded={handleSubmitBillingFormSucceeded}
      paymentCardLastFourDigits={paymentMethod?.lastFour as string}
      paymentMethod={paymentMethod}
      paymentMethodBillingClientSecret={paymentMethodBillingClientSecret}
      projects={projectSelectOptions}
    />
  )
}
