import { defineMessages } from 'react-intl'

export const RecipeDeploymentsPageMainContentMessages = defineMessages({
  description: {
    defaultMessage:
      'Deploy pre-configured applications on Salad with 1-Click. Recipes are ready for production, development or testing, allowing you to focus on building great products.',
    id: 'kTv+bSm1',
    description: 'The description for the recipe deployments page.',
  },
  title: {
    defaultMessage: 'Recipe Deployments for {project_display_name}',
    id: '6rIX0fgv',
    description: 'The title for the recipe deployments page.',
  },
})
