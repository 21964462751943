import type { FunctionComponent } from 'react'
import React from 'react'
import { Button, ButtonVariant } from '../../../../components/Button'
import { Card } from '../../../../components/base'

export interface PresetContainerCardProps {
  /** The label of the button. */
  buttonLabel: string
  /** The variant color of the button. */
  buttonVariant?: ButtonVariant
  /** The description of the preset. */
  description: React.ReactNode
  /** Function to handle button click. */
  onClick: () => void
  /** The title of the preset. */
  title: string
}

export const PresetContainerCard: FunctionComponent<PresetContainerCardProps> = ({
  buttonLabel,
  buttonVariant,
  description,
  onClick,
  title,
}) => {
  return (
    <Card classes="h-full">
      <div className="flex h-full flex-col justify-between">
        <div>
          <h1 className="mb-2 text-lg font-bold">{title}</h1>
          <h2 className="mb-8">{description}</h2>
        </div>
        <div className="mt-auto">
          <Button isFullWidth onClick={onClick} variant={buttonVariant ?? 'green-filled-light'}>
            {buttonLabel}
          </Button>
        </div>
      </div>
    </Card>
  )
}
