import type { FieldErrors, FieldValues } from 'react-hook-form'
import { CreateContainerGroupField } from '../../models'

export const checkIfImageSourceFieldsHaveErrors = (errors: FieldErrors<FieldValues>): boolean => {
  return (
    (errors[CreateContainerGroupField.IMAGE_SOURCE] ||
      errors[CreateContainerGroupField.DOCKER_HUB_USERNAME] ||
      errors[CreateContainerGroupField.DOCKER_HUB_PERSONAL_ACCESS_TOKEN] ||
      errors[CreateContainerGroupField.GIT_HUB_PERSONAL_ACCESS_TOKEN] ||
      errors[CreateContainerGroupField.GIT_HUB_USERNAME] ||
      errors[CreateContainerGroupField.QUAY_PASSWORD] ||
      errors[CreateContainerGroupField.QUAY_USERNAME] ||
      errors[CreateContainerGroupField.AWS_ELASTIC_ACCESS_KEY_ID] ||
      errors[CreateContainerGroupField.AWS_ELASTIC_SECRET_ACCESS_KEY] ||
      errors[CreateContainerGroupField.SELF_HOSTED_PASSWORD] ||
      errors[CreateContainerGroupField.SELF_HOSTED_USERNAME] ||
      errors[CreateContainerGroupField.AZURE_PASSWORD] ||
      errors[CreateContainerGroupField.AZURE_USERNAME] ||
      errors[CreateContainerGroupField.GOOGLE_ARTIFACT_JSON_KEY] ||
      errors[CreateContainerGroupField.GOOGLE_CONTAINER_JSON_KEY]) !== undefined
  )
}

export const imageSourceFieldsList: string[] = [
  CreateContainerGroupField.AWS_ELASTIC_ACCESS_KEY_ID,
  CreateContainerGroupField.AWS_ELASTIC_SECRET_ACCESS_KEY,
  CreateContainerGroupField.DOCKER_HUB_PERSONAL_ACCESS_TOKEN,
  CreateContainerGroupField.DOCKER_HUB_USERNAME,
  CreateContainerGroupField.GIT_HUB_PERSONAL_ACCESS_TOKEN,
  CreateContainerGroupField.GIT_HUB_USERNAME,
  CreateContainerGroupField.IMAGE_SOURCE,
  CreateContainerGroupField.SELF_HOSTED_PASSWORD,
  CreateContainerGroupField.SELF_HOSTED_USERNAME,
  CreateContainerGroupField.QUAY_PASSWORD,
  CreateContainerGroupField.QUAY_USERNAME,
  CreateContainerGroupField.AZURE_PASSWORD,
  CreateContainerGroupField.AZURE_USERNAME,
  CreateContainerGroupField.GOOGLE_ARTIFACT_JSON_KEY,
  CreateContainerGroupField.GOOGLE_CONTAINER_JSON_KEY,
]

const imageSourceFieldsListCopy = imageSourceFieldsList.slice()
const indexOfImageSource = imageSourceFieldsListCopy.indexOf(CreateContainerGroupField.IMAGE_SOURCE)
imageSourceFieldsListCopy.splice(indexOfImageSource, 1)

export const privateRegistryFieldsList: string[] = imageSourceFieldsListCopy
