import { defineMessages } from 'react-intl'

export const loginMessages = defineMessages({
  createAccount: {
    defaultMessage: 'Create Account',
    id: 'YIoH1BCg',
    description: 'The text for the Create Account link.',
  },
  emailInvalid: {
    defaultMessage: 'The email is invalid.',
    id: 'zKa51EG9',
    description: 'The error message when the email address is invalid.',
  },
  emailLabel: {
    defaultMessage: 'Email Address',
    id: 'ouh/i5yE',
    description: 'The label for the email address field.',
  },
  emailPlaceholder: {
    defaultMessage: 'Email',
    id: 'w1g6SHO6',
    description: 'The placeholder for the email address field.',
  },
  emailRequired: {
    defaultMessage: 'An email address is required.',
    id: '4PeHXlzA',
    description: 'The error message when the email address is not provided.',
  },
  forgotPasswordPt1: {
    defaultMessage: 'Having trouble?',
    id: 'O2B1tlHN',
    description: 'The first part of the text for the forgot password copy.',
  },
  forgotPasswordPt2: {
    defaultMessage: 'Forgot Password',
    id: 'Lp/dWWXf',
    description: 'The text for the forgot password link.',
  },
  login: {
    defaultMessage: 'Login',
    id: 'qMsI+r89',
    description: 'The label for the login button.',
  },
  invalidCredentialsTitle: {
    defaultMessage: 'Invalid Credentials',
    id: 'I6wsYWgi',
    description: 'The notification error title when the credentials are invalid.',
  },
  invalidCredentialsMessage: {
    defaultMessage: 'The email and password you provided does not exist or is invalid.',
    id: 'cNsnhKVc',
    description: 'The notification error message when the credentials are invalid.',
  },
  requestFailedTitle: {
    defaultMessage: 'Request Failed',
    id: 'ogGRBpRb',
    description: 'The notification error title when request failed.',
  },
  requestFailedMessage: {
    defaultMessage: 'If the issue persists, please contact support at support@salad.com.',
    id: 'h97WccPX',
    description: 'The notification error message when request failed.',
  },
  passwordInvalid: {
    defaultMessage: 'The password is invalid.',
    id: 'TZlMqCjH',
    description: 'The error message when the password is invalid.',
  },
  passwordLengthInvalid: {
    defaultMessage: 'The password must be between 8 and 64 characters.',
    id: 'o/G4KNMW',
    description: 'The error message when the password length is invalid.',
  },
  passwordRequired: {
    defaultMessage: 'A password is required.',
    id: 'l7YrdeZY',
    description: 'The error message when the password is not provided.',
  },
  passwordLabel: {
    defaultMessage: 'Password',
    id: 'AU2UIiz8',
    description: 'The label for the password field.',
  },
  passwordPlaceholder: {
    defaultMessage: 'Password',
    id: 'zeHclMGl',
    description: 'The placeholder for the password field.',
  },
  haveAccount: {
    defaultMessage: "Don't have an account?",
    id: 'X5/s6+Yf',
    description: 'The text for the sign up.',
  },
  title: {
    defaultMessage: 'Log in to SaladCloud',
    id: 'OikI6d7D',
    description: 'The title for the login form.',
  },
  earn: {
    defaultMessage: 'Looking to earn with your PC?',
    id: '69MJorbU',
    description: 'The title for the login form.',
  },
  earnDownload: {
    defaultMessage: 'Download Salad & Earn',
    id: 'bx3OBXJI',
    description: 'The title for the login form.',
  },
})
