import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import type { DeploymentStatus } from '../../../../../../features/containerGroupInstanceTable/models'
import { Tag } from '../../../../../Tag'
import { ProgressPill } from '../../../../../block/ProgressPill'
import { DeploymentInstanceStatusMessages } from '../../../../messages'

interface AllocatingStatusProps {
  /** The deployment status */
  deploymentStatus: DeploymentStatus
  /** The flag indicating if allocating has taken longer than expected and we should show warning UI styles */
  hasTakenLongerThanExpected: boolean
  /** The callback executed when the user clicks the status */
  onClick: () => void
}

export const AllocatingStatus: FunctionComponent<AllocatingStatusProps> = ({
  deploymentStatus,
  hasTakenLongerThanExpected,
  onClick,
}) => {
  const intl = useIntl()

  return (
    <>
      {deploymentStatus !== 'allocating' ? (
        <ProgressPill label="" icon="" onClick={onClick} percentage={100} />
      ) : (
        <Tag color={hasTakenLongerThanExpected ? 'yellow' : 'green'} onClick={onClick} hasHelpCursor>
          <i className={classNames('mr-2 animate-spin', 'fa-solid fa-spinner')} />
          {intl.formatMessage(DeploymentInstanceStatusMessages.allocating).toUpperCase()}
        </Tag>
      )}
    </>
  )
}
