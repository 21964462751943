import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { Button } from '../../../../components/Button'
import { ButtonLink } from '../../../../components/base/ButtonLink'
import NoContainerGroups from '../../assets/NoContainerGroups.svg'
import { ContainerGroupsPageMainContentMessages, NoContainerGroupsDeployedMessages } from '../../messages'

interface NoContainerGroupsDeployedProps {
  /** The callback called when the user clicks the "Learn More" button. */
  onLearnMore: () => void
  /** The path for the "Deploy a Container Group" button. */
  deployNewContainerGroupPath: string
}

export const NoContainerGroupsDeployed: FunctionComponent<NoContainerGroupsDeployedProps> = ({
  deployNewContainerGroupPath,
  onLearnMore,
}) => {
  const intl = useIntl()

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="mb-4 mt-12">
        <img
          src={NoContainerGroups}
          alt={intl.formatMessage(NoContainerGroupsDeployedMessages.noContainerGroupsImageAlt)}
        />
      </div>
      <div className="mb-4">
        <h2 className="text-3xl font-bold text-neutral-100">
          {intl.formatMessage(NoContainerGroupsDeployedMessages.title)}
        </h2>
      </div>
      <div className="flex gap-2">
        <ButtonLink
          iconClassName="fa-solid fa-folder-plus"
          role="navigation"
          url={deployNewContainerGroupPath}
          variant="green-filled-light"
        >
          {intl.formatMessage(ContainerGroupsPageMainContentMessages.deployContainerGroupButtonLabel)}
        </ButtonLink>
        <Button
          iconClassName="fa-solid fa-book"
          onClick={onLearnMore}
          role="navigation"
          type="button"
          variant="blue-filled-light"
        >
          {intl.formatMessage(ContainerGroupsPageMainContentMessages.learnMoreButtonLabel)}
        </Button>
      </div>
    </div>
  )
}
