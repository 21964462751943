import type { FunctionComponent } from 'react'
import { memo } from 'react'
import { useIntl } from 'react-intl'
import { Button } from '../../../../components/Button'
import { Modal } from '../../../../components/block/Modal'
import { DeleteContainerGroupModalMessages } from './DeleteContainerGroupModalMessages'

export interface DeleteContainerGroupModalProps {
  /** The container group name. */
  containerGroupDisplayName: string
  /** The value indicates whether delete request is in progress (waiting for response) */
  isDeletingContainerGroupRequestPending: boolean
  /** The callback executed when the user clicks the `Cancel` button. */
  onCloseWindow: () => void
  /** The callback executed when the user clicks the `Delete` button. */
  onDeleteContainerGroup: () => void
}

export const DeleteContainerGroupModal: FunctionComponent<DeleteContainerGroupModalProps> = memo(
  ({ containerGroupDisplayName, isDeletingContainerGroupRequestPending, onCloseWindow, onDeleteContainerGroup }) => {
    const intl = useIntl()

    const title = intl.formatMessage(DeleteContainerGroupModalMessages.title, {
      container_group_display_name: containerGroupDisplayName,
    })

    return (
      <Modal onClose={onCloseWindow} title={title}>
        <h1 className="mb-8 text-3xl font-bold">{title}</h1>
        <p className="mb-8 font-normal">{intl.formatMessage(DeleteContainerGroupModalMessages.body)}</p>
        <div className="flex max-w-md gap-6">
          <Button
            type="button"
            variant="blue-outlined"
            onClick={onCloseWindow}
            isFullWidth
            isDisabled={isDeletingContainerGroupRequestPending}
          >
            {intl.formatMessage(DeleteContainerGroupModalMessages.cancel)}
          </Button>
          <Button
            variant="red-filled"
            type="submit"
            onClick={onDeleteContainerGroup}
            isFullWidth
            isLoading={isDeletingContainerGroupRequestPending}
            countdownSeconds={3}
          >
            {intl.formatMessage(DeleteContainerGroupModalMessages.delete)}
          </Button>
        </div>
      </Modal>
    )
  },
)
