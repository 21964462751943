import { ContainerGroupInstance } from '@saladtechnologies/openapi-cloud-portal-browser'
import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import { useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../Button'
import { InstanceDetailsMainContentMessages } from './InstanceDetailsMainContentMessages'
import { InstanceStatusTag } from './components/InstanceStatusTag'
import { LearnMoreInstanceModal } from './components/LearnMoreInstanceModal'
import { ReadyStatus } from './components/ReadyStatus'
import { ReallocateConfirmationModal } from './components/ReallocateConfirmationModal'
import { RecreateConfirmationModal } from './components/RecreateConfirmationModal'
import { RestartConfirmationModal } from './components/RestartConfirmationModal'
import { XTerminal } from './components/XTerminal/XTerminal'

export interface InstanceDetailsMainContentProps {
  /** The container group instance */
  instance?: ContainerGroupInstance
  /** The unique identifier of the container group instance. */
  instanceId: string
  /** The display name of the container group or recipe deployment */
  deploymentDisplayName: string
  /** The flag indicating if the Reallocate button is disabled */
  isReallocateInstanceDisabled?: boolean
  /** The flag indicating if the Reallocate button is submitting */
  isReallocateInstancePending: boolean
  /** The flag indicating if the Recreate button is disabled. */
  isRecreateInstanceDisabled?: boolean
  /** The flag indicating if the Recreate button is submitting */
  isRecreateInstancePending: boolean
  /** The flag indicating if the Restart button is disabled. */
  isRestartInstanceDisabled?: boolean
  /** The flag indicating if the Restart button is submitting */
  isRestartInstancePending: boolean
  /*
   The "Reallocate" button handler
  */
  onReallocate: () => void
  /*
   The "Recreate" button handler
  */
  onRecreate: () => void
  /*
   The "Restart" button handler
  */
  onRestart: () => void
  /* The handler for viewing the container group logs for the current instance */
  onViewContainerGroupLogs?: () => void
  /** The terminal websocket url */
  terminalWebSocketUrl?: string
}

export const InstanceDetailsMainContent: FunctionComponent<InstanceDetailsMainContentProps> = ({
  deploymentDisplayName,
  instance,
  instanceId,
  isReallocateInstanceDisabled,
  isReallocateInstancePending,
  isRecreateInstanceDisabled,
  isRecreateInstancePending,
  isRestartInstanceDisabled,
  isRestartInstancePending,
  onReallocate,
  onRecreate,
  onRestart,
  onViewContainerGroupLogs,
  terminalWebSocketUrl,
}) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const { ready, started, state } = instance || {}
  const [isReallocateConfirmationModalShowing, setReallocateConfirmationModalOpen] = useState(false)
  const [isRecreateConfirmationModalShowing, setRecreateConfirmationModalOpen] = useState(false)
  const [isRestartConfirmationModalShowing, setRestartConfirmationModalOpen] = useState(false)
  const [isLearnMoreModalShowing, setLearnMoreModalOpen] = useState(false)

  return (
    <div className="w-full">
      <div className="mb-6">
        <button onClick={() => navigate(-1)} className="text-blue-90 underline">
          <span className={classNames('fa-solid fa-arrow-left mr-2')} />
          {intl.formatMessage(InstanceDetailsMainContentMessages.backButtonLabel, {
            deployment_display_name: deploymentDisplayName,
          })}
        </button>
      </div>
      <div>
        <h1 className="font-medium">{intl.formatMessage(InstanceDetailsMainContentMessages.title)}</h1>
        <div className="text-3xl font-bold text-neutral-100">
          {intl.formatMessage(InstanceDetailsMainContentMessages.machineIdLabel, {
            machine_id: instanceId,
          })}
        </div>
        <div className={'flex flex-row gap-2'}>
          <InstanceStatusTag deploymentStatus={state} hasStarted={started ?? false} />
          {instance && <ReadyStatus isReady={ready ?? false} />}
        </div>
      </div>
      <div className="mt-6">
        <h2 className="mb-2 font-medium">{intl.formatMessage(InstanceDetailsMainContentMessages.actionsTitle)}</h2>
        <div className="flex flex-row gap-2">
          <Button
            iconClassName="fa-solid fa-power-off"
            isDisabled={isRestartInstanceDisabled || !instance}
            onClick={() => setRestartConfirmationModalOpen(true)}
          >
            {intl.formatMessage(InstanceDetailsMainContentMessages.restartButtonLabel)}
          </Button>
          <Button
            iconClassName="fa-solid fa-sharp fa-window-restore"
            isDisabled={isRecreateInstanceDisabled || !instance}
            onClick={() => setRecreateConfirmationModalOpen(true)}
            variant="blue-filled"
          >
            {intl.formatMessage(InstanceDetailsMainContentMessages.recreateButtonLabel)}
          </Button>
          <Button
            iconClassName="fa-solid fa-rotate-right"
            isDisabled={isReallocateInstanceDisabled || !instance}
            isLoading={isReallocateInstancePending}
            onClick={() => setReallocateConfirmationModalOpen(true)}
            variant="green-filled-light"
          >
            {intl.formatMessage(InstanceDetailsMainContentMessages.reallocateButtonLabel)}
          </Button>
          {onViewContainerGroupLogs && (
            <Button
              iconClassName="fa-regular fa-file-text"
              onClick={onViewContainerGroupLogs}
              variant="green-filled-light"
            >
              {intl.formatMessage(InstanceDetailsMainContentMessages.viewLogsButtonLabel)}
            </Button>
          )}
          <Button
            iconClassName="fa-regular fa-circle-question"
            onClick={() => setLearnMoreModalOpen(true)}
            variant="blue-filled-light"
          >
            {intl.formatMessage(InstanceDetailsMainContentMessages.learnMoreButtonLabel)}
          </Button>
        </div>
      </div>
      <XTerminal terminalWebSocketUrl={terminalWebSocketUrl} />
      {isReallocateConfirmationModalShowing && (
        <ReallocateConfirmationModal
          isReallocateInstancePending={isReallocateInstancePending}
          onCancel={() => setReallocateConfirmationModalOpen(false)}
          onReallocateInstance={onReallocate}
        />
      )}
      {isRecreateConfirmationModalShowing && (
        <RecreateConfirmationModal
          isRecreateInstancePending={isRecreateInstancePending}
          onCancel={() => setRecreateConfirmationModalOpen(false)}
          onRecreateInstance={onRecreate}
        />
      )}
      {isRestartConfirmationModalShowing && (
        <RestartConfirmationModal
          isRestartInstancePending={isRestartInstancePending}
          onCancel={() => setRestartConfirmationModalOpen(false)}
          onRestartInstance={onRestart}
        />
      )}
      {isLearnMoreModalShowing && <LearnMoreInstanceModal onCancel={() => setLearnMoreModalOpen(false)} />}
    </div>
  )
}
