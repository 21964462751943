import type { AppState } from '../../store'
import { selectOrganizationHasHadValidPayment } from '../organizations/organizationsSelectors'
import { selectPaymentMethod } from '../paymentMethod/paymentMethodSelectors'
import { selectContainerGroupInstanceQuota, selectMaxCreatedContainerGroupQuota } from '../quotas/quotasSelectors'
import type { RequiredActionModal } from './models'

export const selectOrganizationDisplayName = (state: AppState) => state.createContainerGroup.organizationDisplayName

export const selectProjectDisplayName = (state: AppState) => state.createContainerGroup.projectDisplayName

export const selectRequiredActionModalToShowOnPageLoad = (
  state: AppState,
  organizationName: string,
): RequiredActionModal | undefined => {
  let modalToShow: RequiredActionModal | undefined = undefined

  const hasZeroDeploymentQuota = selectMaxCreatedContainerGroupQuota(state, organizationName) === 0
  const billingInformationMissing = selectPaymentMethod(state, organizationName) === undefined
  const hasNotHadValidPayment = selectOrganizationHasHadValidPayment(state, organizationName) === false

  if (hasZeroDeploymentQuota) {
    if (billingInformationMissing && hasNotHadValidPayment) {
      modalToShow = 'billing'
    } else {
      modalToShow = 'deploymentsQuota'
    }

    return modalToShow
  }

  const hasZeroInstanceQuota = selectContainerGroupInstanceQuota(state, organizationName) === 0
  if (hasZeroInstanceQuota) {
    if (billingInformationMissing && hasNotHadValidPayment) {
      modalToShow = 'billing'
    } else {
      modalToShow = 'instancesQuota'
    }
  }

  return modalToShow
}

export const selectIsBillingInformationMissingModalShowing = (state: AppState) =>
  state.createContainerGroup.isBillingInformationMissingModalShowing

export const selectIsContainerGroupDeploymentQuotaExceededModalShowing = (state: AppState) =>
  state.createContainerGroup.isContainerGroupDeploymentQuotaExceededModalShowing

export const selectIsContainerGroupInstancesQuotaExceededModalShowing = (state: AppState) =>
  state.createContainerGroup.isContainerGroupInstancesQuotaExceededModalShowing
