import { catchError, concat, delay, filter, from, mergeMap, of } from 'rxjs'
import { InvitationsAPI } from '../apiMethods'
import {
  acceptInvitation,
  declineInvitation,
  getOrganizationsAndOrganizationInvitations,
  setRejectInvitationRequestStatus,
} from '../features/myOrganizations/myOrganizationSlice'
import { showToastNotification } from '../features/notifications/notificationsSlice'
import { setRequestStatus } from '../features/requestStatus/requestStatusSlice'
import { RequestStatus } from '../models'
import {
  getGeneralErrorAcceptingTeamInvitationContent,
  getGeneralErrorDecliningTeamInvitationContentMessages,
  getSuccessfullyAcceptedTeamInvitationContent,
  getSuccessfullyDeclinedTeamMemberInvitationContent,
} from '../notifications/clientToastNotificationContent/team'
import type { AppEpic } from '../store'

export const onAcceptInvitation: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(acceptInvitation.match),
    mergeMap((action) =>
      concat(
        of(setRequestStatus({ request: 'acceptTeamInvitation', status: 'pending' })),
        from(
          InvitationsAPI.updateUserInvitation({
            updateInvitation: {
              status: 'accepted',
            },
            invitationId: action.payload.invitationId,
          }),
        ).pipe(
          mergeMap(() =>
            concat(
              of(
                setRequestStatus({ request: 'acceptTeamInvitation', status: 'succeeded' }),
                showToastNotification(
                  getSuccessfullyAcceptedTeamInvitationContent(intl, action.payload.organizationDisplayName),
                ),
                getOrganizationsAndOrganizationInvitations(),
              ),
              of(setRequestStatus({ request: 'acceptTeamInvitation', status: 'idle' })).pipe(delay(1)),
            ),
          ),
          catchError(() => {
            return concat(
              of(setRequestStatus({ request: 'acceptTeamInvitation', status: 'failed' })),
              of(setRequestStatus({ request: 'acceptTeamInvitation', status: 'idle' })).pipe(delay(1)),
              of(
                showToastNotification(
                  getGeneralErrorAcceptingTeamInvitationContent(intl, action.payload.organizationDisplayName),
                ),
              ),
            )
          }),
        ),
      ),
    ),
  )

export const onDeclineInvitation: AppEpic = (action$, _state$, { intl }) =>
  action$.pipe(
    filter(declineInvitation.match),
    mergeMap((action) =>
      concat(
        of(setRequestStatus({ request: 'declineTeamInvitation', status: 'pending' })),
        from(
          InvitationsAPI.updateUserInvitation({
            updateInvitation: {
              status: 'declined',
            },
            invitationId: action.payload.invitationId,
          }),
        ).pipe(
          mergeMap(() =>
            concat(
              of(
                setRequestStatus({ request: 'declineTeamInvitation', status: 'succeeded' }),
                showToastNotification(
                  getSuccessfullyDeclinedTeamMemberInvitationContent(intl, action.payload.organizationDisplayName),
                ),
                getOrganizationsAndOrganizationInvitations(),
              ),
              of(setRequestStatus({ request: 'declineTeamInvitation', status: 'idle' })).pipe(delay(1)),
            ),
          ),
          catchError(() => {
            return concat(
              of(
                setRejectInvitationRequestStatus({
                  invitationId: action.payload.invitationId,
                  status: RequestStatus.Failed,
                }),
              ),
              of(
                setRejectInvitationRequestStatus({
                  invitationId: action.payload.invitationId,
                  status: RequestStatus.Idle,
                }),
              ).pipe(delay(1)),
              of(
                showToastNotification(
                  getGeneralErrorDecliningTeamInvitationContentMessages(intl, action.payload.organizationDisplayName),
                ),
              ),
            )
          }),
        ),
      ),
    ),
  )
