/**
 * Returns the Storage Option value in Bytes we get from the API to GB. The storage value can be either in base 10 or
 * base 2.
 *
 * @param storageValue The `Storage Option` value that the user has listed in their resources.
 */
export const convertStorageOptionToGB = (storageValue: number | null | undefined): number | undefined => {
  return storageValue
    ? storageValue % 1000000000 === 0
      ? storageValue / 1000000000
      : Math.ceil(storageValue / 1024 / 1024 / 1024)
    : undefined
}
