import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { Tag } from '../../Tag'
import { ContainerGroupVersionTagMessages } from '../messages'

export interface ContainerGroupVersionTagProps {
  /** The container group version number */
  version: number
}

export const ContainerGroupVersionTag: FunctionComponent<ContainerGroupVersionTagProps> = ({ version }) => {
  const intl = useIntl()

  return (
    <div className="flex justify-center">
      <Tag color="white">
        {intl.formatMessage(ContainerGroupVersionTagMessages.versionLabel, {
          version,
        })}
      </Tag>
    </div>
  )
}
