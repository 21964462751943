import { defineMessages } from 'react-intl'

export const EditRecipeDeploymentGeneralErrorContentMessages = defineMessages({
  body: {
    defaultMessage:
      'We were unable to edit your recipe deployment. Try again and if this issue persists {contact_support}.',
    id: '1z3hFHIB',
    description:
      'The description shown in a toast notification message shown when we encounter an error on their request to edit their recipe deployment.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'An0gqQzq',
    description: 'The text shown for the contact support link in the edit recipe deployment error toast notification.',
  },
  title: {
    defaultMessage: 'Error editing recipe deployment',
    id: 'x90AF6rK',
    description:
      'The toast notification title shown when we encounter an error on their request to edit their recipe deployment.',
  },
})
