import { defineMessages } from 'react-intl'

export const ChangePasswordInvalidCurrentPasswordErrorContentMessages = defineMessages({
  body: {
    defaultMessage: 'The current password you entered was incorrect',
    id: 'dSZi+VFL',
    description:
      'The description shown in a toast notification message shown when the user encounters an error changing their password.',
  },
  title: {
    defaultMessage: 'Wrong Password',
    id: 'Lij1mRCD',
    description:
      'The toast notification title shown when the user encounters an error changing their password because incorrect current password typed.',
  },
})
