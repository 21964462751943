import { defineMessages } from 'react-intl'

export const LoadRecipeDeploymentsGeneralErrorContentMessages = defineMessages({
  body: {
    defaultMessage:
      'We are unable to get your recipe deployments. Try again and if this issue persists {contact_support}.',
    id: 'PO2VsOj+',
    description:
      'The description shown in a toast notification message shown when we encounter an error on their request to load their recipe deployments.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'VsPLdNKf',
    description: 'The text shown for the contact support link in the load recipe deployments error toast notification.',
  },
  title: {
    defaultMessage: 'Unable to retrieve recipe deployments',
    id: 'QW8YrLQs',
    description:
      'The toast notification title shown when we encounter an error on their request to load their recipe deployments.',
  },
})
