import classNames from 'classnames'
import type { FunctionComponent, ReactNode } from 'react'
import { forwardRef } from 'react'
import { useIntl } from 'react-intl'
import { RadioCardsMessages } from './messages'
import type { RadioCardOptions } from './models'

interface RadioCardsProps {
  /** The card options the user has to select from. */
  cards: RadioCardOptions
  /** The default selected value. */
  defaultCheckedValue?: any
  /** The flag indicating that "None Selected" should be an option. */
  hasNoneSelectedOption?: boolean
  /** The text to be shown under the label. */
  helperText?: string | ReactNode
  /** A value indicating the Radio Cards error state */
  invalid?: boolean
  /** The label for the radio cards input. */
  label: string
  /** The onChange handler */
  onChange: (value: string | undefined) => void
  /** The name for the radio cards input. */
  name: string
}

export const RadioCards: FunctionComponent<RadioCardsProps> = forwardRef<HTMLInputElement, RadioCardsProps>(
  ({ cards, defaultCheckedValue, hasNoneSelectedOption, helperText, invalid, label, name, onChange }, ref) => {
    const intl = useIntl()
    const cardOptions: RadioCardOptions =
      hasNoneSelectedOption && cards.length > 0
        ? [
            {
              content: (
                <p className="text-base font-medium">{intl.formatMessage(RadioCardsMessages.noneSelectedLabel)}</p>
              ),
              value: undefined,
            },
            ...cards,
          ]
        : cards

    return (
      <fieldset className="mb-10 w-full">
        <legend
          className={classNames('mb-1 text-base font-bold', {
            'text-red-70': invalid,
          })}
        >
          {label}
        </legend>
        <p
          className={classNames('text-sm', {
            'text-red-70': invalid,
          })}
        >
          {helperText}
        </p>
        <div className="mt-4 flex flex-wrap gap-5">
          {cardOptions.map((card, index) => (
            <div className="flex" key={index}>
              <div className="relative w-80">
                <input
                  onChange={(event) => (event.target.checked ? onChange(card.value) : undefined)}
                  className="peer absolute block size-full cursor-pointer opacity-0"
                  defaultChecked={defaultCheckedValue === card.value ?? index === 0}
                  disabled={card.disabled}
                  id={`${card.value + index}`}
                  name={name}
                  ref={ref}
                  type="radio"
                  value={card.value}
                />
                <label
                  // TODO: Have better svg selector that doesn't depend on knowing the structure of the component in the children prop
                  className="flex size-full flex-col justify-center rounded-lg border border-transparent bg-neutral-10 p-2 shadow-md peer-checked:border-green-50 peer-checked:text-green-70 peer-hover:border-green-50 peer-focus:border-neutral-90 peer-disabled:border-transparent peer-disabled:opacity-50 peer-checked:[&>div>svg]:fill-green-70"
                  htmlFor={`${card.value + index}`}
                >
                  {card.content}
                </label>
              </div>
            </div>
          ))}
        </div>
      </fieldset>
    )
  },
)
