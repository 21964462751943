import { defineMessages } from 'react-intl'

export const EditEnvironmentVariablesFieldsMessages = defineMessages({
  addEnvironmentVariableButtonLabel: {
    defaultMessage: 'Add Environment Variable',
    id: 'n3wyAr0r',
    description: 'The label for the Add Environment Variable button for the create container group page.',
  },
  environmentVariablesFieldsLabel: {
    defaultMessage: 'Environment Variables',
    id: 'F8IfFY7K',
    description: 'The label for the container group environment variables fields.',
  },
  environmentVariablesNotSetLabel: {
    defaultMessage: 'No environment variables set',
    id: 'laiwIJ1X',
    description: 'The label for the container group environment variables fields are not set.',
  },
  learMoreLinkText: {
    defaultMessage: 'Learn More',
    id: 'IURxls7s',
    description: 'The learn more link text used for the environment variables docs.',
  },
  keyInvalid: {
    defaultMessage: 'Keys can include only letters, digits, and underscores, and cannot begin with a digit.',
    id: 'FrMgOike',
    description: 'The error text for the key input field of the environment variables when it is deemed invalid.',
  },
  keyLabel: {
    defaultMessage: 'Key',
    id: 'SYncPUzW',
    description: 'The label for the key input of the environment variables field on the create container group page.',
  },
  keyMustBeUnique: {
    defaultMessage: 'All keys provided must be unique.',
    id: '1u9qg01W',
    description: 'The error text for the key input field of the environment variables when it is not unique.',
  },
  keyRequiredText: {
    defaultMessage: 'Key is required',
    id: 'dyJtYe43',
    description: 'The required text for the key input field of the environment variables.',
  },
  removeVariableButtonLabel: {
    defaultMessage: 'Remove Variable',
    id: 'BzYU7194',
    description: 'The label for the Remove Variable button for the create container group page.',
  },
  sectionTitle: {
    defaultMessage: 'Environment Variables',
    id: 'xZHt2fer',
    description:
      'The title for the dialog box that opens when a user wants to edit environment variables fields on the create container group page.',
  },
  subtitle: {
    defaultMessage: 'Pass environment variables to your container. {learn_more_link_text}',
    id: 'XRbOm1Sl',
    description: 'The subtitle for the environment variables.',
  },
  valueLabel: {
    defaultMessage: 'Value',
    id: 'cAAEM11z',
    description: 'The label for the value input of the environment variables field on the create container group page.',
  },
  variableInputsLabel: {
    defaultMessage: 'Variable {count}',
    id: 'b0rvqgxZ',
    description: 'The label for the container group environment variables key value pain input card.',
  },
  viewEnvironmentVariablesLinkText: {
    defaultMessage: 'View {count, plural, one {# Environment Variable} other {# Environment Variables}} ',
    id: 'BOZF2GTX',
    description: 'The text for the link that opens the container group environment variables side panel.',
  },
})
