import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import type { ObjectSchema } from 'yup'
import { object, ref, string } from 'yup'
import { isValidPassword } from '../../../../utils/formValidation'
import { ChangePasswordMainContentMessages } from '../../messages'
import type { ChangePasswordValues } from './ChangePasswordMainContent'

export const useChangePasswordFormValidation = (): ObjectSchema<ChangePasswordValues> => {
  const intl = useIntl()
  const validationScheme = useMemo(
    () =>
      object({
        currentPassword: string().required(
          intl.formatMessage(ChangePasswordMainContentMessages.currentPasswordRequired),
        ),
        newPassword: string()
          .required()
          .test((value) => isValidPassword(value)),
        verifyPassword: string()
          .required('')
          .oneOf(
            [ref('newPassword')],
            intl.formatMessage(ChangePasswordMainContentMessages.verifyPasswordDoesNotMatch),
          ),
      }),
    [intl],
  )
  return validationScheme
}
