import * as Sentry from '@sentry/react'

/** The Sentry service options. */
export interface SentryServiceOptions {
  /**
   * The Sentry DSN or `undefined` to permanently disable Sentry.
   *
   * @example `https://789782fe97f34e458e201e71d9f265ef@o236248.ingest.sentry.io/4504330844372992`
   */
  dsn: string | undefined

  /** A value indicating whether logging is enabled by default. */
  enabled: boolean

  /**
   * The Sentry environment.
   *
   * @example `production`
   */
  environment: string

  /** The error sample rate (a number between 0 and 1, inclusive). */
  errorSampleRate: number

  /**
   * The Sentry release identifier or `undefined` to not associate the current runtime with uploaded source maps.
   *
   * @example `da39a3ee5e6b4b0d3255bfef95601890afd80709`
   */
  release: string | undefined

  /** The transaction sample rate (a number between 0 and 1, inclusive). */
  transactionSampleRate: number
}

/** The Sentry service. */
export class SentryService {
  /** A value indicating whether logging is enabled. */
  private enabled: boolean

  /**
   * Creates an instance of `SentryService`.
   *
   * @param options The Sentry service options.
   */
  public constructor(options: SentryServiceOptions) {
    this.enabled = options.enabled

    if (options.dsn === undefined) {
      return
    }

    let errorSampleRate = Math.round((options.errorSampleRate + Number.EPSILON) * 100) / 100
    if (errorSampleRate < 0) {
      errorSampleRate = 0
    } else if (errorSampleRate > 1) {
      errorSampleRate = 1
    }

    let transactionSampleRate = Math.round((options.transactionSampleRate + Number.EPSILON) * 100) / 100
    if (transactionSampleRate < 0) {
      transactionSampleRate = 0
    } else if (transactionSampleRate > 1) {
      transactionSampleRate = 1
    }

    Sentry.init({
      beforeSend: this.beforeSend,
      beforeSendTransaction: this.beforeSendTransaction,
      dsn: options.dsn,
      environment: options.environment,
      integrations: [Sentry.browserTracingIntegration()],
      release: options.release,
      sampleRate: errorSampleRate,
      tracesSampleRate: transactionSampleRate,
    })
  }

  /**
   * Sets the user context.
   *
   * @param userId The user identifier.
   * @param email The user email.
   * @see https://docs.sentry.io/platforms/javascript/enriching-events/identify-user/
   */
  public authenticate = (userId: string, email: string): void => {
    Sentry.setUser({
      email,
      id: userId,
    })
  }

  /**
   * Resets the user context.
   *
   * @see https://docs.sentry.io/platforms/javascript/enriching-events/identify-user/
   */
  public deauthenticate = (): void => {
    Sentry.setUser(null)
  }

  /** Disables logging. */
  public disable = (): void => {
    this.enabled = false
  }

  /** Enables logging. */
  public enable = (): void => {
    this.enabled = true
  }

  /**
   * A filter used to ignore events when logging is disabled.
   *
   * @param event The event.
   * @returns The event or `null` to ignore the event.
   * @see https://docs.sentry.io/platforms/javascript/configuration/filtering/#using-platformidentifier-namebefore-send-
   */
  private beforeSend: NonNullable<Sentry.BrowserOptions['beforeSend']> = (event) => {
    if (!this.enabled) {
      // Ignore events when disabled.
      return null
    }

    return event
  }

  /**
   * A filter used to ignore transactions when logging is disabled.
   *
   * @param transaction The transaction.
   * @returns The transaction or `null` to ignore the transaction.
   * @see https://docs.sentry.io/platforms/javascript/configuration/filtering/#using-platformidentifier-namebefore-send-transaction-
   */
  private beforeSendTransaction: NonNullable<Sentry.BrowserOptions['beforeSendTransaction']> = (transaction) => {
    if (!this.enabled) {
      // Ignore transactions when disabled.
      return null
    }

    return transaction
  }
}
