import { defineMessages } from 'react-intl'

export const UnableToGetContainerGroupAndInstancesErrorMessages = defineMessages({
  body: {
    defaultMessage: 'We were unable to get updated container group and instances at this time. Please try again.',
    id: 'nqpBiPhc',
    description:
      'The description shown in a toast notification message shown when we are unable to get the container group and instances.',
  },
  title: {
    defaultMessage: 'Unable to get updated container group and instances',
    id: 'mssq/hRp',
    description: 'The toast notification title shown when we are unable to get the container group and instances.',
  },
})
