import type { ReactNode } from 'react'
import type { IntlShape } from 'react-intl'
import type { ToastNotificationType } from '../../models'
import { CreateContainerGroupOrganizationNotFoundErrorContentMessages } from './messages'

/**
 * Retrieves the content needed for an error `ToastNotification` that is shown when the user encounters an error when
 * attempting to create a container group where the organization name can't be found.
 *
 * @param intl The `IntlShape` object needed to format the messages.
 */
export const getCreateContainerGroupOrganizationNotFoundErrorContent = (
  intl: IntlShape,
): {
  body: ReactNode
  type: ToastNotificationType
  title: string
} => {
  return {
    body: intl.formatMessage(CreateContainerGroupOrganizationNotFoundErrorContentMessages.body),
    type: 'error',
    title: intl.formatMessage(CreateContainerGroupOrganizationNotFoundErrorContentMessages.title),
  }
}
