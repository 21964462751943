import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { Button } from '../../../../components/Button'
import { Card, Link, Markdown } from '../../../../components/base'
import { TimeFrameBasedEstimatedCost } from '../../../../components/recipes/TimeFrameBasedEstimatedCost'
import { TimeFrameBasedEstimatedCostMessages } from '../../../../components/recipes/TimeFrameBasedEstimatedCost/messages'
import { RecipeDetailsMainContentMessages } from '../../messages'

interface RecipeDetailsMainContentProps {
  /** The callback to deploy the recipe. */
  onDeployRecipe: () => void
  /** The recipe cost per hour of one replica. */
  recipeCostPerHour: number
  /** The markdown of the recipe. */
  recipeMarkdown: string
  /** The path back to the recipe marketplace. */
  recipeMarketplacePath: string
  /** The name of the recipe. */
  recipeName: string
}

export const RecipeDetailsMainContent: FunctionComponent<RecipeDetailsMainContentProps> = ({
  onDeployRecipe,
  recipeCostPerHour,
  recipeMarkdown,
  recipeMarketplacePath,
  recipeName,
}) => {
  const intl = useIntl()

  return (
    <div className="w-full">
      <div className="mb-8">
        <Link url={recipeMarketplacePath}>
          <span className={classNames('fa-solid fa-arrow-left mr-2')} />
          {intl.formatMessage(RecipeDetailsMainContentMessages.backToRecipeMarketplaceButtonLabel)}
        </Link>
      </div>
      <div className="flex justify-between">
        <div>
          <h1 className="mb-12 text-3xl font-bold text-neutral-100">{recipeName}</h1>
          <Button onClick={onDeployRecipe} variant="green-filled" iconClassName="fa-solid fa-code">
            {intl.formatMessage(RecipeDetailsMainContentMessages.configureDeployButtonLabel)}
          </Button>
        </div>
        <div className="mb-10 mt-16">
          <TimeFrameBasedEstimatedCost
            costPerHour={recipeCostPerHour}
            disclaimer={intl.formatMessage(TimeFrameBasedEstimatedCostMessages.perReplicaTag)}
          />
        </div>
      </div>
      <div>
        <Card>
          <Markdown markdown={recipeMarkdown} />
        </Card>
      </div>
    </div>
  )
}
