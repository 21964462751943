import type { PayloadAction } from '@reduxjs/toolkit'
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import type { RamOption } from '@saladtechnologies/openapi-cloud-portal-browser'

export const ramOptionsAdapter = createEntityAdapter<RamOption>({
  selectId: (item) => item.name,
  sortComparer: (a, b) => a.value - b.value,
})

export const ramOptionsSlice = createSlice({
  name: 'ramOptions',
  initialState: ramOptionsAdapter.getInitialState(),
  reducers: {
    ramOptionsReceived(state, action: PayloadAction<{ ramOptions: RamOption[] }>) {
      ramOptionsAdapter.setAll(state, action.payload.ramOptions)
    },
  },
})

export const { ramOptionsReceived } = ramOptionsSlice.actions
