export enum KeyboardEventKey {
  SpaceSymbol = ' ',
  Space = 'Space',
  Enter = 'Enter',
  ArrowUp = 'ArrowUp',
  ArrowDown = 'ArrowDown',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
  Escape = 'Escape',
  Home = 'Home',
  End = 'End',
}
