import { DateTime } from 'luxon'

/**
 * Takes a date time and configures the date in a readable format.
 *
 * @param updateTime The update time.
 */
export const configureAgeLabel = (updateTime: Date | null) => {
  if (!updateTime) {
    return ''
  }

  const now = DateTime.local()
  const updateDateTime = DateTime.fromJSDate(updateTime).setZone(now.zoneName)

  const isUpdateTimeInTheFuture = now.diff(updateDateTime).milliseconds < 0

  return DateTime.fromJSDate(isUpdateTimeInTheFuture ? new Date(now.toMillis() - 1) : updateDateTime.toJSDate())
    .toRelative({ base: now, style: 'narrow' })
    ?.replace('.', '')
    ?.replace(' ago', '')
}
