import type { FunctionComponent } from 'react'
import { memo } from 'react'
import { useIntl } from 'react-intl'
import { Button } from '../../../../Button'
import { Modal } from '../../../../block/Modal'
import { RestartConfirmationModalMessages } from './RestartConfirmationModalMessages'

export interface RestartConfirmationModalProps {
  /** The value indicates whether restart request is in progress (waiting for response) */
  isRestartInstancePending: boolean
  /** The callback executed when the user clicks the `Cancel` button. */
  onCancel: () => void
  /** The callback executed when the user clicks the `Restart` button. */
  onRestartInstance: () => void
}

export const RestartConfirmationModal: FunctionComponent<RestartConfirmationModalProps> = memo(
  ({ isRestartInstancePending, onCancel, onRestartInstance }) => {
    const intl = useIntl()

    return (
      <Modal onClose={onCancel} title={intl.formatMessage(RestartConfirmationModalMessages.title)}>
        <h1 className="mb-8 text-3xl font-bold">{intl.formatMessage(RestartConfirmationModalMessages.title)}</h1>
        <p className="mb-8 font-normal">{intl.formatMessage(RestartConfirmationModalMessages.body)}</p>
        <div className="flex max-w-md gap-6">
          <Button
            type="button"
            variant="blue-outlined"
            onClick={onCancel}
            isFullWidth
            isDisabled={isRestartInstancePending}
          >
            {intl.formatMessage(RestartConfirmationModalMessages.cancel)}
          </Button>
          <Button variant="green-filled" onClick={onRestartInstance} isFullWidth isLoading={isRestartInstancePending}>
            {intl.formatMessage(RestartConfirmationModalMessages.delete)}
          </Button>
        </div>
      </Modal>
    )
  },
)
