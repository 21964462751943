import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import { forwardRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { InputLabel } from '../../InputLabel'
import { FileUploadMessages } from './messages'

export interface FileUploadProps {
  /** A ID for File Upload input field - Should be the same as the `name` value passed to the react hook form controller */
  id: string
  /** A flag indicating the File Upload error state */
  invalid?: boolean
  /** The label shown above the File Upload input. */
  label: string
  /** The handler to be invoked once the value has changed */
  onChange: (value?: FileList | null) => void
}

export const FileUpload: FunctionComponent<FileUploadProps> = forwardRef<HTMLInputElement, FileUploadProps>(
  ({ id, invalid, label, onChange }, ref) => {
    const [fileName, setFileName] = useState<string | undefined>()
    const intl = useIntl()

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value ? event.target.files : undefined)
      const formattedFileName = event.target.value.slice().split('\\').pop()
      setFileName(
        formattedFileName && formattedFileName.length > 25
          ? formattedFileName?.substring(0, 25) + '...'
          : formattedFileName,
      )
    }

    return (
      <div className="mb-16">
        <InputLabel hasError={invalid} label={label} />
        <div className="relative">
          <label
            htmlFor={id}
            className="absolute flex w-fit cursor-pointer items-center justify-center rounded-sm bg-green-10 p-2 text-green-90"
          >
            <div className={classNames('fa-solid fa-upload mr-2')} />
            {intl.formatMessage(FileUploadMessages.buttonLabel)}
          </label>
          {fileName && <p className="absolute left-36 top-2">{fileName}</p>}
          <input className="hidden" type="file" id={id} onChange={handleChange} ref={ref} />
        </div>
      </div>
    )
  },
)
