import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { Button } from '../../Button'
import { JobQueueDetailsMessages } from '../messages'

interface JobQueueDetailsProps {
  /** The name for the job queue. */
  name?: string
  /** The callback executed when the user clicks the add job queue button. */
  onClickAddJobQueueButton?: () => void
  /** The path for the job queue. */
  path?: string
  /** The port number for the job queue. */
  port?: number
}

export const JobQueueDetails: FunctionComponent<JobQueueDetailsProps> = ({
  name,
  onClickAddJobQueueButton,
  path,
  port,
}) => {
  const intl = useIntl()

  return (
    <>
      {name ? (
        <div className="flex flex-col">
          <p className="truncate">
            {intl.formatMessage(JobQueueDetailsMessages.queueNameLabel, {
              queue: name || '',
            })}
          </p>
          <p>
            {intl.formatMessage(JobQueueDetailsMessages.queuePortLabel, {
              port: port || '',
            })}
          </p>
          <p className="truncate">
            {intl.formatMessage(JobQueueDetailsMessages.queuePathLabel, {
              path: path || '',
            })}
          </p>
        </div>
      ) : onClickAddJobQueueButton ? (
        <Button variant="green-filled-light" onClick={onClickAddJobQueueButton}>
          {intl.formatMessage(JobQueueDetailsMessages.addJobQueueLabel)}
        </Button>
      ) : (
        <p>{intl.formatMessage(JobQueueDetailsMessages.disabledLabel)}</p>
      )}
    </>
  )
}
