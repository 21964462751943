import { RequestStatus } from '../../models'
import type { AppState } from '../../store'

export const selectEditProjectRequestPending = (state: AppState) =>
  state.editProject.editProjectRequestStatus === RequestStatus.Loading

export const selectCurrentProjectBeingEdited = (state: AppState) => state.editProject.project

export const selectGetProjectRequestStatusIsPending = (state: AppState) =>
  state.editProject.getProjectRequestStatus === RequestStatus.Loading

export const selectGetEditProjectRequestStatusIsPending = (state: AppState) =>
  state.editProject.getEditProjectsDataRequestStatus === RequestStatus.Loading

export const selectGetProjectRequestStatusHasFailed = (state: AppState) =>
  state.editProject.getProjectRequestStatus === RequestStatus.Failed
