import { defineMessages } from 'react-intl'

export const KeyValuePairFormMessages = defineMessages({
  bulkEditLabel: {
    defaultMessage: 'Bulk Edit',
    id: 'ptvu80Wu',
    description: 'The text for the bulk edit label in the key value pair form',
  },
  bulkEditHelperText: {
    defaultMessage: 'Rows are separated by new lines. Key value pairs are separated by an equal sign.',
    id: 'JLrt7eoW',
    description: 'The text for the bulk edit helper text in the key value pair form',
  },
  keyLabel: {
    defaultMessage: 'Key',
    id: 'YmaAlLr/',
    description: 'The text for the key label in the key value pair form',
  },
  keyValueEditLabel: {
    defaultMessage: 'Key Value Edit',
    id: '8ddHiCmY',
    description: 'The text for the key value edit label in the key value pair form',
  },
  valueLabel: {
    defaultMessage: 'Value',
    id: '2/WEUTdv',
    description: 'The text for the value label in the key value pair form',
  },
})
