import { defineMessages } from 'react-intl'

export const PresetContainerMessages = defineMessages({
  backLinkText: {
    defaultMessage: 'Back',
    id: 'DIEhxLoS',
    description: 'The text for the back link.',
  },
  customContainerGroupCardDescription: {
    defaultMessage: 'Fully customize the container group deployment. {learn_more}',
    id: 'S+9rTNYq',
    description: 'The description for the custom container group card.',
  },
  customContainerGroupCardTitle: {
    defaultMessage: 'Custom Container Group',
    id: '/gpNcLog',
    description: 'The title for the custom container group card.',
  },
  dockerDescription: {
    defaultMessage: 'Deploy a container using a docker run command',
    id: 'a6q9d08f',
    description: 'The description for the Docker card.',
  },
  dockerTitle: {
    defaultMessage: 'Docker Run',
    id: 'MsFRRDlv',
    description: 'The title for the Docker card.',
  },
  featuredText: {
    defaultMessage: 'Featured',
    id: '0M6Qbv9u',
    description: 'The text for the featured section.',
  },
  getStartedButtonLabel: {
    defaultMessage: 'Get Started',
    id: '0mRJJZJm',
    description: 'The label for the get started button.',
  },
  learnMoreText: {
    defaultMessage: 'Learn More',
    id: 'ySYJyzac',
    description: 'The text for the learn more link.',
  },
  presetContainerSubtitle: {
    defaultMessage:
      'Deploy your own custom containerized applications on Salad. Containers give you full control over your deployment, allowing deeper integrations with your existing applications and pipelines.',
    id: 'F2Y4Wrw3',
    description: 'The subtitle for the preset container page.',
  },
  presetContainerTitle: {
    defaultMessage: 'Create a New Container Group',
    id: 'TEB9Yr3w',
    description: 'The title for the preset container page.',
  },
  startingPointText: {
    defaultMessage: 'Starting Point',
    id: 'ni2tr/J2',
    description: 'The text for the starting point section.',
  },
  ubuntuCardDescription: {
    defaultMessage: 'Deploy a basic Ubuntu environment with terminal access. {learn_more}',
    id: 'RWVZ5+8r',
    description: 'The description for the Ubuntu card.',
  },
  ubuntuCardTitle: {
    defaultMessage: 'Ubuntu',
    id: 'oPlPEPND',
    description: 'The title for the Ubuntu card.',
  },
})
