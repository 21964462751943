import { defineMessages } from 'react-intl'

export const AddPaymentMethodGenericValidationErrorMessages = defineMessages({
  body: {
    defaultMessage:
      'There was an error adding your payment method. Please check your payment method information and try again, or use a different payment method.',
    id: 'Toz/HVyW',
    description:
      'The description in a toast notification message shown when we encounter an error adding new payment method.',
  },
  title: {
    defaultMessage: 'Payment Method Error',
    id: 'Kv6h2fKo',
    description: 'The toast notification title shown when we encounter an error adding new payment method.',
  },
})
