import type { FunctionComponent } from 'react'
import { useCallback } from 'react'
import { selectForgotPasswordEmail } from '../../features/forgotPassword/forgotPasswordSelectors'
import { sendResetPasswordEmail } from '../../features/forgotPassword/forgotPasswordSlice'
import { selectRequestStatusIsPending } from '../../features/requestStatus/requestStatusSelectors'
import { useAppDispatch, useAppSelector } from '../../store'
import { ResetPasswordEmailSent } from './components/ResetPasswordEmailSent'

export const ConnectedResetPasswordEmailSentPage: FunctionComponent = () => {
  const email = useAppSelector(selectForgotPasswordEmail)
  const dispatch = useAppDispatch()
  const sendResetPasswordEmailIsPending = useAppSelector((state) =>
    selectRequestStatusIsPending(state, 'sendResetPasswordEmail'),
  )

  const onResendEmail = useCallback(() => {
    dispatch(sendResetPasswordEmail({ email, resending: true }))
  }, [dispatch, email])

  return (
    <ResetPasswordEmailSent
      email={email}
      onResendEmail={onResendEmail}
      isResendEmailPending={sendResetPasswordEmailIsPending}
    />
  )
}
