import type { FunctionComponent } from 'react'
import type { Control, FieldValues } from 'react-hook-form'
import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { ButtonLink } from '../../../../../../components/base'
import { FileUpload } from '../../../../../../components/forms/FileUpload'
import { CreateContainerGroupMainContentMessages, GoogleInputFieldsMessages } from '../../../../messages'
import type { CreateContainerGroupValues } from '../../../../models'
import { CreateContainerGroupField } from '../../../../models'

interface GoogleArtifactRegistryInputFieldsProps {
  /** The control for the create container group react hook form. */
  control: Control<FieldValues, CreateContainerGroupValues>
}

export const GoogleArtifactRegistryInputFields: FunctionComponent<GoogleArtifactRegistryInputFieldsProps> = ({
  control,
}) => {
  const intl = useIntl()

  return (
    <>
      <Controller
        name={CreateContainerGroupField.GOOGLE_ARTIFACT_JSON_KEY}
        control={control}
        render={({ field, fieldState }) => {
          return (
            <FileUpload
              {...field}
              {...fieldState}
              id={CreateContainerGroupField.GOOGLE_ARTIFACT_JSON_KEY}
              label={intl.formatMessage(GoogleInputFieldsMessages.jsonKeyLabel)}
            />
          )
        }}
      />

      <div className="pb-16">
        <p className="mb-2">{intl.formatMessage(GoogleInputFieldsMessages.needHelpText)}</p>
        <ButtonLink url="https://docs.salad.com/container-engine/registries/gcp-gcr" variant="blue-filled-light">
          {intl.formatMessage(CreateContainerGroupMainContentMessages.learnMoreButtonLabel)}
        </ButtonLink>
      </div>
    </>
  )
}
