import type { StorageOption } from '@saladtechnologies/openapi-cloud-portal-browser'

/**
 * Returns the flag indicating if the `Storage Option` is in high demand.
 *
 * @param storageOptions The `Storage Options` that Salad Provides users to select from.
 * @param storageValue The `Storage Option` value that the user has listed in their resources.
 */
export const getStorageOptionIsHighDemand = (
  storageOptions: StorageOption[] | undefined,
  storageValue: number | null | undefined,
): boolean => {
  if (storageValue === undefined || storageOptions === undefined) return false

  return storageOptions.find((storageOption) => storageOption.value === storageValue)?.isHighDemand ?? false
}
