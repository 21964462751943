import { defineMessages } from 'react-intl'

export const EditProjectSucceededContentMessages = defineMessages({
  body: {
    defaultMessage: 'The display name will be shown in the Portal. For API calls use {project_name}.',
    id: 'XaKy7c2N',
    description:
      'The description shown in a toast notification message shown when the user successfully edited their project details.',
  },
  title: {
    defaultMessage: 'The display name of "{project_name}" was changed to "{project_display_name}"',
    id: 'zhd7KXOW',
    description: 'The toast notification title shown when the user successfully edited their project details.',
  },
})
