import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { ButtonLink } from '../../../../components/base/ButtonLink'
import { Card } from '../../../../components/base/Card'
import { AccountCardMessages } from '../../messages'

interface AccountCardProps {
  /** The date the account was created. */
  createdDate: Date
  /** The user's email. */
  email: string
}

export const AccountCard: FunctionComponent<AccountCardProps> = ({ createdDate, email }) => {
  const intl = useIntl()
  return (
    <Card>
      <div className="flex flex-wrap items-center justify-between gap-2">
        <div>
          <p className="mb-2 text-base font-medium">{email}</p>
          <p>
            {intl.formatMessage(AccountCardMessages.accountCreatedOn, {
              date: intl.formatDate(createdDate, { dateStyle: 'long' }),
            })}
          </p>
        </div>
        <ButtonLink url="/account/change-password" variant="green-filled-light">
          {intl.formatMessage(AccountCardMessages.changePasswordButtonLabel)}
        </ButtonLink>
      </div>
    </Card>
  )
}
