import { defineMessages } from 'react-intl'

export const SelectProductMainContentMessages = defineMessages({
  advancedProductTag: {
    defaultMessage: 'ADVANCED',
    id: 'Q1V76JWC',
    description: 'The advanced product tag.',
  },
  backToProjectsLinkText: {
    defaultMessage: 'Back to Projects',
    id: '+QYJQbda',
    description: 'The link text for the back button.',
  },
  containerGroupsProductButtonLabel: {
    defaultMessage: 'Create Container Group',
    id: '3Pr85B3e',
    description: 'The button label for the create container groups product button.',
  },
  containerGroupsProductDescription: {
    defaultMessage:
      'Custom containerized applications on Salad. Containers give you full control over your deployment, allowing deeper integrations with your existing applications and pipelines.',
    id: 'YAUyIznd',
    description: 'The description for the container groups product.',
  },
  containerGroupsProductHeader: {
    defaultMessage: 'Container Groups',
    id: 'D7xVjYL6',
    description: 'The header for the container groups product.',
  },
  containerGroupsProductIconAlt: {
    defaultMessage: 'containerGroups Product Icon',
    id: 'nOAjHo/b',
    description: 'The alt text for the container groups product icon.',
  },
  description: {
    defaultMessage:
      'Make a container group to manage and distribute HPC workloads, and rendering queues to thousands of 3D-accelerated GPUs.',
    id: 'RBt11IW/',
    description: 'The description about what selecting specific project product means.',
  },
  inferenceEndpointsProductButtonLabel: {
    defaultMessage: 'Select Inference Endpoints',
    id: 'XW5xbOqi',
    description: 'The button label for the select inference endpoints product button.',
  },
  inferenceEndpointsProductDescription: {
    defaultMessage:
      'With these easy to use APIs for Inference, you don’t have to worry about creating or maintaining any deployments. Salad hosts the models and you pay only for what you use.',
    id: 'Uda3o8Fj',
    description: 'The description for the inference endpoints product.',
  },
  inferenceEndpointsProductIconAlt: {
    defaultMessage: 'Inference Endpoints Product Icon',
    id: '6GQK8Ybh',
    description: 'The alt text for the inference endpoints product icon.',
  },
  inferenceEndpointsProductHeader: {
    defaultMessage: 'Inference Endpoints',
    id: 'mz5S0uwI',
    description: 'The header for the inference endpoints product.',
  },
  quickStartProductTag: {
    defaultMessage: 'QUICKSTART',
    id: 'xwzj67q4',
    description: 'The QUICKSTART product tag.',
  },
  recipesProductButtonLabel: {
    defaultMessage: 'Select Recipes',
    id: '0/Nu8sug',
    description: 'The button label for the select recipes product button.',
  },
  recipesProductDescription: {
    defaultMessage:
      'Pre-configured applications on Salad. Recipes are ready for production, development or testing, allowing you to focus on building great products.',
    id: 'snhxSN2P',
    description: 'The description for the recipes product.',
  },
  recipesProductHeader: {
    defaultMessage: 'Quick Deploy with Recipes',
    id: '1aFX/MAE',
    description: 'The header for the recipes product.',
  },
  recipesProductIconAlt: {
    defaultMessage: 'Recipes Product Icon',
    id: 'LJwVPlUK',
    description: 'The alt text for the recipes product icon.',
  },
  templateProductTag: {
    defaultMessage: 'TEMPLATE',
    id: 'IOZTbm5e',
    description: 'The template product tag.',
  },
  title: {
    defaultMessage: 'Select Product',
    id: '5Hrxuluk',
    description: 'The title of the Select Product page.',
  },
})
