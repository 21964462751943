import { defineMessages } from 'react-intl'

export const WorkloadErrorsMessages = defineMessages({
  documentationLinkText: {
    defaultMessage: 'our documentation here',
    id: 'l2S+Xuco',
    description: 'The documentation link text',
  },
  downloadButtonLabel: {
    defaultMessage: 'Download',
    id: 'zCo8JC3p',
    description: 'The download button label',
  },
  recentWorkloadErrorsDescription: {
    defaultMessage: 'Learn more about these errors and how to address them in {documentation_link}.',
    id: 'qUd6QHkr',
    description: 'The recent errors description',
  },
  recentWorkloadErrorsTitle: {
    defaultMessage: 'Recent Workload Errors',
    id: 'Q/qlDf0p',
    description: 'The recent errors title',
  },
})
