import type { FunctionComponent } from 'react'
import { useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { trackMixpanelPageViewedEvent } from '../../features/analytics/analyticsSlice'
import { selectCreateNewProjectRequestStatus } from '../../features/createNewProject/createNewProjectSelectors'
import { createNewProject } from '../../features/createNewProject/createNewProjectSlice'
import { selectIsLeftColumnShowing } from '../../features/navigationBar/navigationBarSelectors'
import { setLeftColumnShowing } from '../../features/navigationBar/navigationBarSlice'
import { RequestStatus } from '../../models'
import { createProjectPageRoutePath } from '../../routes/routePaths'
import { useAppDispatch, useAppSelector } from '../../store'
import { CreateProjectPage } from './CreateProjectPage'

export const ConnectedCreateProjectPage: FunctionComponent = () => {
  const { organizationName = '', projectName } = useParams()
  const dispatch = useAppDispatch()
  const isLeftColumnOpen = useAppSelector(selectIsLeftColumnShowing)
  const requestStatus = useAppSelector(selectCreateNewProjectRequestStatus)

  const handleCloseLeftNavBar = useCallback(() => {
    dispatch(setLeftColumnShowing({ showing: false }))
  }, [dispatch])

  const onCreateProject = useCallback(
    (projectName: string) => {
      if (organizationName !== undefined) {
        dispatch(createNewProject({ organizationName, projectName }))
      }
    },
    [dispatch, organizationName],
  )

  useEffect(() => {
    dispatch(trackMixpanelPageViewedEvent({ path: createProjectPageRoutePath, organizationName }))
  }, [dispatch, organizationName])

  return (
    <CreateProjectPage
      projectName={projectName as string}
      organizationDisplayName={organizationName as string}
      isLeftColumnOpen={isLeftColumnOpen}
      isCreateProjectPending={requestStatus === RequestStatus.Loading}
      onCloseLeftDrawer={handleCloseLeftNavBar}
      onCreateProject={onCreateProject}
    />
  )
}
