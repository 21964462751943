import { yupResolver } from '@hookform/resolvers/yup'
import type { FunctionComponent } from 'react'
import { Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Button } from '../../../../components/Button'
import { ButtonMessages } from '../../../../components/Button/messages'
import { TextField } from '../../../../components/TextField'
import { Modal } from '../../../../components/block/Modal'
import { useFixedForm } from '../../../../hooks'
import { DeleteAccountModalMessages } from '../../messages/DeleteAccountModalMessages'
import { useDeleteAccountFormValidation } from './useDeleteAccountFormValidation'

export interface DeleteAccountValues {
  /** The account password. */
  password: string
}

export interface DeleteAccountModalProps {
  /** The user's email address. */
  email: string
  /** The flag indicating that the request to delete the account is pending. */
  isDeleteAccountPending: boolean
  /** The callback executed when the user clicks the `Cancel` button. */
  onCloseWindow: () => void
  /** The callback executed when the user clicks the `Delete` button. */
  onDeleteAccount: (password: string) => void
}

export const DeleteAccountModal: FunctionComponent<DeleteAccountModalProps> = ({
  email,
  isDeleteAccountPending,
  onCloseWindow,
  onDeleteAccount,
}) => {
  const intl = useIntl()
  const deleteAccountValidationScheme = useDeleteAccountFormValidation()

  const { control, formState, handleSubmit, register } = useFixedForm<DeleteAccountValues>({
    resolver: yupResolver(deleteAccountValidationScheme),
    onSubmit: ({ password }) => {
      onDeleteAccount(password)
    },
  })

  return (
    <Modal onClose={onCloseWindow} title={intl.formatMessage(DeleteAccountModalMessages.title)}>
      <h1 className="mb-8 text-2xl font-bold">{intl.formatMessage(DeleteAccountModalMessages.title)}</h1>
      <p className="mb-8">
        {intl.formatMessage(DeleteAccountModalMessages.body, {
          email: <span className="text-green-80">{email}</span>,
        })}
      </p>
      <form onSubmit={handleSubmit}>
        <Controller
          name="password"
          control={control}
          render={({ field, fieldState }) => (
            <TextField
              {...register('password')}
              {...field}
              {...fieldState}
              label={intl.formatMessage(DeleteAccountModalMessages.passwordInputTitle)}
              type="password"
              isFullWidth
            />
          )}
        />
        <div className="flex max-w-md gap-6">
          <Button type="button" variant="green-outlined" onClick={onCloseWindow} isFullWidth>
            {intl.formatMessage(DeleteAccountModalMessages.cancelButtonLabel)}
          </Button>
          <Button
            variant="red-filled"
            type="submit"
            isLoading={isDeleteAccountPending}
            isFullWidth
            isDisabled={!formState.isDirty}
          >
            {intl.formatMessage(ButtonMessages.deleteButtonLabel)}
          </Button>
        </div>
      </form>
    </Modal>
  )
}
