import classNames from 'classnames'
import { ToastContainer as ReactToastifyToastContainer } from 'react-toastify'
import { useWindowSize } from '../hooks'
import { Breakpoints } from '../utils'

/**
 * A customized `ToastContainer` from `react-toastify` with tailwind styles needed to be set in the application in order
 * to have toast notifications show.
 */
export const ToastContainer = () => {
  const windowSize = useWindowSize()
  const toastWidth =
    windowSize.width < Breakpoints.sm
      ? false
      : windowSize.width >= Breakpoints.sm && windowSize.width < Breakpoints.md
        ? 576
        : 827

  return (
    <ReactToastifyToastContainer
      toastClassName={(values) =>
        classNames('flex flex-row items-start justify-between rounded-md border-2 bg-neutral-10 shadow-md mb-4', {
          'border-blue-20': values?.type === 'info',
          'border-green-20': values?.type === 'success',
          'border-red-20': values?.type === 'error',
        })
      }
      icon={(values) => (
        <div
          className={classNames('rounded text-2xl', {
            'bg-blue-10 text-blue-90': values.type === 'info',
            'bg-green-10 text-green-70': values.type === 'success',
            'bg-red-10 text-red-60': values.type === 'error',
          })}
        >
          <span className="flex size-12 items-center justify-center">
            <i
              className={classNames('fas', {
                'fa-info': values.type === 'info',
                'fa-check': values.type === 'success',
                'fa-exclamation-triangle': values.type === 'error',
              })}
            />
          </span>
        </div>
      )}
      bodyClassName={() => 'py-5 pl-5 flex flex-row items-start gap-8'}
      closeButton={() => (
        <i
          className={classNames('fas fa-circle-xmark', 'mr-2 mt-2 cursor-pointer rounded-lg text-xl text-neutral-40')}
        />
      )}
      style={toastWidth ? { width: toastWidth } : undefined}
    />
  )
}
