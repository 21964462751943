import { defineMessages } from 'react-intl'

export const AccountCardMessages = defineMessages({
  accountCreatedOn: {
    defaultMessage: 'Joined on {date}',
    id: 'TBMUlM4W',
    description: 'The text displaying the date the user created a Salad Cloud account.',
  },
  changePasswordButtonLabel: {
    defaultMessage: 'Change Password',
    id: 'UAsVU+KP',
    description: 'The button label for the change password link on the account settings page.',
  },
})
