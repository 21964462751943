import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    login(_state, _action: PayloadAction<{ email: string; password: string }>) {},
  },
})

export const { login } = loginSlice.actions
