import { defineMessages } from 'react-intl'

export const InstanceStatusTagMessages = defineMessages({
  inactiveStatusLabel: {
    defaultMessage: 'Inactive',
    id: 'M5uzLQOu',
    description: 'The label for the Inactive status.',
  },
  startedStatusLabel: {
    defaultMessage: 'Started',
    id: 'UUIYCdIq',
    description: 'The label for the Started status.',
  },
})
