/**
 * A function that handles taking the values from the command fields and configuring them to be one string.
 *
 * @param command The command.
 * @param args The optional command arguments for the container group.
 */
export const configureCommand = (command?: string, args?: { argument?: string }[]) => {
  if (!command) return ''
  if (!args || args.length === 0) return command

  return command + ' ' + args.map((argument) => argument.argument).join(' ')
}
