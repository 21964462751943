import { defineMessages } from 'react-intl'

export const DrawerMessages = defineMessages({
  asideLeftAriaLabel: {
    defaultMessage: 'The left sidebar content',
    id: 'pVHD6sF/',
    description: 'The aria label for the left sidebar.',
  },
  asideRightAriaLabel: {
    defaultMessage: 'The right sidebar content',
    id: 'Pd/cDLKT',
    description: 'The aria label for the right sidebar.',
  },
})
