import { defineMessages } from 'react-intl'

export const EditJobQueueNameConflictErrorContentMessages = defineMessages({
  body: {
    defaultMessage: 'That Job Queue Name has been used before. You must use a new Job Queue Name.',
    id: '0jv543dP',
    description:
      'The description shown in a toast notification message shown when we encounter an error on their request to edit a job queue with a name that has already been used.',
  },
  title: {
    defaultMessage: 'You must use a unique Job Queue Name',
    id: '8r8qAJd4',
    description:
      'The toast notification title shown when we encounter an error on their request to edit a job queue with a name that has already been used.',
  },
})
