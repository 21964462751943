import { defineMessages } from 'react-intl'

export const GeneralErrorInvitingTeamMemberContentMessage = defineMessages({
  body: {
    defaultMessage: 'We were unable to invite {user_email}. Try again and if this issue persists {contact_support}.',
    id: 'XCNtsvQN',
    description: 'The body for the invite team member error notification.',
  },
  contactSupportLinkText: {
    defaultMessage: 'contact support',
    id: 'pIQEUfA+',
    description: 'The text shown for the contact support link.',
  },
  title: {
    defaultMessage: 'Error inviting email address',
    id: '0OZSU1/e',
    description: 'The title for the invite team member error notification.',
  },
})
