import { defineMessages } from 'react-intl'

export const EditSelfHostedInputFieldsMessages = defineMessages({
  needHelpText: {
    defaultMessage: 'Need help with setting up?',
    id: 'W+9OemIM',
    description: 'The help text shown below the Self Hosted (Basic Auth) input fields.',
  },
  passwordLabel: {
    defaultMessage: 'Password',
    id: 'rQaAe/xJ',
    description: 'The label for the Self Hosted (Basic Auth) password input field.',
  },
  passwordPlaceholderText: {
    defaultMessage: 'Your Password',
    id: 'TgErowmv',
    description: 'The placeholder text for the Self Hosted (Basic Auth) password input field.',
  },
  passwordRequiredText: {
    defaultMessage: 'Password is required',
    id: 'nQ8VQrks',
    description: 'The required text for the Self Hosted (Basic Auth) password input field.',
  },
  usernameLabel: {
    defaultMessage: 'Username',
    id: 'GDTLfjhs',
    description: 'The label for the Self Hosted (Basic Auth) username input field.',
  },
  usernamePlaceholderText: {
    defaultMessage: 'Your Username',
    id: '0ZXFkfd0',
    description: 'The placeholder text for the Self Hosted (Basic Auth) username input field.',
  },
  usernameRequiredText: {
    defaultMessage: 'Username is required',
    id: 'TDElRFb6',
    description: 'The required text for the Self Hosted (Basic Auth) username input field.',
  },
})
