import type { ContainerGroupStatus } from '@saladtechnologies/openapi-cloud-portal-browser'
import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import { Tag } from '../../Tag'
import { DeploymentStatusTagMessages } from './messages'

export interface DeploymentStatusTagProps {
  /** The flag indicating the failure to pull the image. */
  hasFailedToPullImage?: boolean
  /** The status of tag. */
  status: ContainerGroupStatus
}

export const DeploymentStatusTag: FunctionComponent<DeploymentStatusTagProps> = ({ hasFailedToPullImage, status }) => {
  const intl = useIntl()

  switch (status) {
    case 'failed': {
      return (
        <Tag color="red">
          <i className={classNames('mr-2', 'fa-solid fa-triangle-exclamation')} />
          <span>
            {intl.formatMessage(
              hasFailedToPullImage
                ? DeploymentStatusTagMessages.failedToPullImageLabel
                : DeploymentStatusTagMessages.failedLabel,
            )}
          </span>
        </Tag>
      )
    }
    // TODO: change to preparing once it has been updated in the api
    case 'pending': {
      return (
        <Tag color="yellow">
          <i className={classNames('mr-2 animate-spin', 'fa-solid fa-spinner')} />
          <span>{intl.formatMessage(DeploymentStatusTagMessages.preparingLabel)}</span>
        </Tag>
      )
    }
    case 'running': {
      return (
        <Tag color="green">
          <i className={classNames('mr-2', 'fa-solid fa-play')} />
          <span>{intl.formatMessage(DeploymentStatusTagMessages.runningLabel)}</span>
        </Tag>
      )
    }
    case 'stopped': {
      return (
        <Tag color="white">
          <i className={classNames('mr-2', 'fa-solid fa-stop')} />
          <span>{intl.formatMessage(DeploymentStatusTagMessages.stoppedLabel)}</span>
        </Tag>
      )
    }
    case 'deploying': {
      return (
        <Tag color="blue">
          <i className={classNames('mr-2', 'fa-solid fa-circle-nodes')} />
          <span>{intl.formatMessage(DeploymentStatusTagMessages.deployingLabel)}</span>
        </Tag>
      )
    }
    default: {
      return null
    }
  }
}
