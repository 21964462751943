import { defineMessages } from 'react-intl'

export const EditQuayInputFieldsMessages = defineMessages({
  needHelpText: {
    defaultMessage: 'Need help with setting up on Quay.io?',
    id: 'SaXxPTEC',
    description: 'The help text shown below the Quay.io input fields.',
  },
  passwordLabel: {
    defaultMessage: 'Password',
    id: 'sFDFi0kW',
    description: 'The label for the Quay.io password input field.',
  },
  passwordPlaceholderText: {
    defaultMessage: 'Your Password',
    id: '2FMic5qk',
    description: 'The placeholder text for the Quay.io password input field.',
  },
  passwordRequiredText: {
    defaultMessage: 'Password is required',
    id: '8odDIYFX',
    description: 'The required text for the Quay.io password input field.',
  },
  usernameLabel: {
    defaultMessage: 'Username',
    id: '8hMDj6CA',
    description: 'The label for the Quay.io username input field.',
  },
  usernamePlaceholderText: {
    defaultMessage: 'Your Username',
    id: '/DIMdKbO',
    description: 'The placeholder text for the Quay.io username input field.',
  },
  usernameRequiredText: {
    defaultMessage: 'Username is required',
    id: 'MkHbdvpH',
    description: 'The required text for the Quay.io username input field.',
  },
})
