import { defineMessages } from 'react-intl'

export const CommandSidePanelModalMessages = defineMessages({
  addArgumentButtonLabel: {
    defaultMessage: 'Add Argument',
    id: '0vwgL456',
    description: 'The label for the Add Argument button.',
  },
  argumentInputPlaceholderText: {
    defaultMessage: '/etc/hostname',
    id: '/OmSpA0B',
    description: 'The placeholder text for the command input.',
  },
  argumentInputTitle: {
    defaultMessage: 'Argument {count}',
    id: 'g56N9uNn',
    description: 'The title for the Argument input card.',
  },
  backButtonLabel: {
    defaultMessage: 'Back',
    id: '2awDgewe',
    description: 'The label for the Back button.',
  },
  commandFieldsLabel: {
    defaultMessage: 'String',
    id: 'bsViKxc7',
    description: 'The helper text shown for both the Command input fields.',
  },
  commandInputPlaceholderText: {
    defaultMessage: 'cat',
    id: '9cXM5/Be',
    description: 'The placeholder text for the command input.',
  },
  commandInputTitle: {
    defaultMessage: 'Command',
    id: 'm2iHGhpl',
    description: 'The title for the Command input card.',
  },
  description: {
    defaultMessage:
      'The command and arguments executed by the probe. An exit code of zero represents a successful probe. All other exit codes represent a failure. {learn_more}',
    id: '5X53ZDGK',
    description: 'The description for the command input fields for the Health Probes.',
  },
  learMoreLinkText: {
    defaultMessage: 'Learn More',
    id: '/uh3swte',
    description: 'The text shown for the learn more link for the command inputs of the Heatlh Probe forms.',
  },
  removeButtonLabel: {
    defaultMessage: 'Remove',
    id: 'nsWp0jY5',
    description: 'The label for the Remove button.',
  },
  title: {
    defaultMessage: 'Command for {health_probe}',
    id: '++I4TQwt',
    description: 'The title for the command side panel modal form.',
  },
})
