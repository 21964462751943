import type { FunctionComponent } from 'react'
import { useIntl } from 'react-intl'
import SaladVerifiedSrc from './assets/SaladVerified.svg'
import { SaladVerifiedMessages } from './messages'

export const SaladVerified: FunctionComponent = () => {
  const intl = useIntl()

  return (
    <span className="flex">
      <img
        height={16}
        width={13.79}
        src={SaladVerifiedSrc}
        alt={intl.formatMessage(SaladVerifiedMessages.SaladVerifiedAltTag)}
      />
      <p className="ml-2 font-medium text-green-80">{intl.formatMessage(SaladVerifiedMessages.SaladVerifiedLabel)}</p>
    </span>
  )
}
