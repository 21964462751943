import classNames from 'classnames'
import type { FunctionComponent } from 'react'
import { ToastBody } from '../../../notifications/ToastBody'

interface InlineNotificationProps {
  body: string | React.ReactNode
  title: string
  type: 'info' | 'success' | 'error'
}

export const InlineNotification: FunctionComponent<InlineNotificationProps> = ({ body, title, type }) => {
  return (
    <div
      className={classNames('mb-4 flex w-full flex-row items-start rounded-md border-2 bg-neutral-10 p-5 shadow-md', {
        'border-blue-20': type === 'info',
        'border-green-20': type === 'success',
        'border-red-20': type === 'error',
      })}
    >
      <div
        className={classNames('rounded text-2xl', {
          'bg-blue-10 text-blue-90': type === 'info',
          'bg-green-10 text-green-70': type === 'success',
          'bg-red-10 text-red-60': type === 'error',
        })}
      >
        <span className="flex size-12 items-center justify-center">
          <i
            className={classNames('fas', {
              'fa-info': type === 'info',
              'fa-check': type === 'success',
              'fa-exclamation-triangle': type === 'error',
            })}
          />
        </span>
      </div>
      <div className="pl-4">
        <ToastBody body={body} title={title} />
      </div>
    </div>
  )
}
