import { defineMessages } from 'react-intl'

export const CreateContainerGroupImageNameInvalidErrorContentMessages = defineMessages({
  body: {
    defaultMessage: 'Please make sure the image name you have provided is valid.',
    id: 'UyRmcfP2',
    description:
      'This is the body of the toast notification that is displayed when a user tries to create a container group with an invalid image name.',
  },
  title: {
    defaultMessage: 'Invalid Image Name',
    id: 'CCqQ92sK',
    description:
      'This is the title of the toast notification that is displayed when a user tries to create a container group with an invalid image name.',
  },
})
