import type { FunctionComponent } from 'react'
import { Spinner } from '../../../components/Spinner'
import { ModalLayout } from '../../../layouts/authentication'
import type { VerificationErrorProps } from './VerificationError'
import { VerificationError } from './VerificationError'
import type { VerificationLinkExpiredProps } from './VerificationLinkExpired'
import { VerificationLinkExpired } from './VerificationLinkExpired'
import type { VerificationSuccessProps } from './VerificationSuccess'
import { VerificationSuccess } from './VerificationSuccess'

type CombinedVerificationComponentProps = VerificationErrorProps &
  VerificationLinkExpiredProps &
  VerificationSuccessProps

export interface VerifyAccountProps extends CombinedVerificationComponentProps {
  /*
   The type of UI state we should display based on the
   result from attempting to verify the account. If the
   result is undefined, then a loading spinner will show
   in the modal.
  */
  verificationResult: 'error' | 'expired' | 'success' | undefined
}

export const VerifyAccount: FunctionComponent<VerifyAccountProps> = ({
  isResendEmailVerificationLinkPending,
  onResendEmailVerificationLink,
  onViewLoginPage,
  verificationResult,
}) => {
  return (
    <ModalLayout type="narrow">
      {verificationResult === undefined ? (
        <div className="flex h-28 flex-col items-center justify-center">
          <Spinner color="neutral" size="lg" />
        </div>
      ) : (
        <>
          {verificationResult === 'error' ? (
            <VerificationError onViewLoginPage={onViewLoginPage} />
          ) : verificationResult === 'expired' ? (
            <VerificationLinkExpired
              onResendEmailVerificationLink={onResendEmailVerificationLink}
              isResendEmailVerificationLinkPending={isResendEmailVerificationLinkPending}
            />
          ) : (
            <VerificationSuccess onViewLoginPage={onViewLoginPage} />
          )}
        </>
      )}
    </ModalLayout>
  )
}
