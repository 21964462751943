import { defineMessages } from 'react-intl'

export const EditTCPInputFieldsMessages = defineMessages({
  hostLabel: {
    defaultMessage: 'Host',
    id: 'ZdmfNulO',
    description: 'The label for the TCP host input field.',
  },
  hostRequiredText: {
    defaultMessage: 'Host is required for TCP',
    id: 'JXQj62Jk',
    description: 'The required text for the TCP host input field.',
  },
  hostMaxCharactersText: {
    defaultMessage: 'Host for TCP can be no longer than 1000 characters',
    id: '8MQWVT6t',
    description: 'The max characters text for the TCP host input field.',
  },
  portLabel: {
    defaultMessage: 'Port',
    id: 'ryzdj/dA',
    description: 'The label for the TCP port input field.',
  },
  portHelperText: {
    defaultMessage: 'Must be a number between 1 to 65535',
    id: 'iplcMZAw',
    description: 'The helper text for the TCP port input field.',
  },
})
