/** A function that checks if a nonrequired image source is valid */
export const isNonrequiredImageSourceValid = (imageSource: string | undefined): boolean => {
  if (!imageSource) return true
  if (
    imageSource.startsWith('/') ||
    imageSource.includes(':/') ||
    imageSource.includes('/:') ||
    !/[a-z0-9]$/i.test(imageSource) ||
    !/^[a-z0-9.\-_/:@]*$/i.test(imageSource)
  ) {
    return false
  }

  const elements = imageSource.split(/[.\-_/:]/)
  for (const element of elements) {
    if (/^[A-Z]/.test(element) || (element.match(/^[a-z0-9]+$/g) || []).length > 1) {
      return false
    }
  }

  return true
}
